export const filtersToUploads = {
  "masterData": {
    limitLabeledFamilyOptions: 5,
    useBusinessUnit: true,
    useLabeledFamilies: true,
    useSuppliers: true
  },
  "planningBottles": {
    isBottles: true,
    useBusinessUnit: true,
    useDateRange: true,
    useLabeledFamilies: true,
    useSuppliers: true
  }
}
import styled from 'styled-components'

const SelectStyled = styled.div`

  .Select:not(.is-searchable) {
    .Select-arrow-zone {
       box-shadow: inset 0 1px 1px 0 rgba(149, 163, 183, 0.8);
    }

    &:not(.is-disabled) {
      .Select-control:hover {
        .Select-arrow-zone {
          background-color: #f4f5f8;
          box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #2870b2;
        }
      }
    }

    .is-focused > .Select-control {
     .Select-arrow-zone {
       background-color: #f4f5f8;
       box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #2870b2;
     }
    }
  }

  label {
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #3b495e;
  }

  .option-item {
    height: 44px;
    display: flex;
    align-items: center;
    padding-left: 20px;

    &:hover {
      background-color: #e5edf4;
      cursor: pointer;
    }

    span {
      font-weight: 600;
      font-size: 14px;
      color: #3b495e;
    }
  }

  .text-hightlight {
    background-color: #2870b2;
    color: white;
  }

  .Select {
    position: relative;
  }
  .Select,
  .Select div,
  .Select input,
  .Select span {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .Select.is-disabled > .Select-control,
  .Select.is-disabled > .Select-control:hover {
    box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #cdd6e1;
  }

  .Select.is-disabled > .Select-control {
    background-color: #e5edf4;
  }

  .Select.is-disabled .Select-arrow-zone {
    cursor: default;
    pointer-events: none;
    opacity: 0.35;
    box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #cdd6e1;
  }
  .Select-control {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #afcef3;
    color: #333;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 40px;
    outline: none;
    overflow: hidden;
    position: relative;
    cursor: pointer;
  }
  .Select-control:hover {
    box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #2870b2;

    #carret {
      fill: #2870b2;
    }
  }
  .Select-control .Select-input:focus {
    outline: none;
  }
  .is-searchable.is-open > .Select-control {
    cursor: text;
  }
  .is-open > .Select-control {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: #fff;
    border-color: #b3b3b3 #ccc #d9d9d9;
  }
  .is-open > .Select-control .Select-arrow {
    top: -2px;
    border-color: transparent transparent #999;
    border-width: 0 5px 5px;
  }
  .is-searchable.is-focused:not(.is-open) > .Select-control {
    cursor: text;
  }

  .is-focused > .Select-control {
    box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #2870b2;

    #carret {
      fill: #2870b2;
    }
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    bottom: 0;
    color: #aaa;
    left: 0;
    line-height: 40px;
    padding-left: 18px;
    padding-right: 10px;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 20px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .Select--single > .Select-control .Select-value {
    width: calc(100% - 40px);
  }

  .has-value.Select--single > .Select-control .Select-value .Select-value-label,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
    color: #333;
  }

  .has-value.Select--single > .Select-control .Select-value a.Select-value-label,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
    cursor: pointer;
    text-decoration: none;
  }

  .has-value.Select--single > .Select-control .Select-value a.Select-value-label:hover,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:hover,
  .has-value.Select--single > .Select-control .Select-value a.Select-value-label:focus,
  .has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label:focus {
    color: #007eff;
    outline: none;
    text-decoration: underline;
  }

  .Select-input {
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    vertical-align: middle;
  }

  /* .Select-input > input { */
  .react-multi-email > input {
    width: 100%;
    background: none transparent;
    border: 0 none;
    box-shadow: none;
    cursor: default;
    display: inline-block;
    font-family: inherit;
    font-size: inherit;
    margin: 0;
    outline: none;
    line-height: 16px;
    padding: 10px;
    color: #3b495e;
  }

  .is-focused .Select-input > input {
    cursor: text;
  }

  .has-value.is-pseudo-focused .Select-input {
    opacity: 0;
  }

  .Select-control:not(.is-searchable) > .Select-input {
    outline: none;
  }

  .Select-loading-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 16px;
  }

  .Select-loading {
    -webkit-animation: Select-animation-spin 400ms infinite linear;
    -o-animation: Select-animation-spin 400ms infinite linear;
    animation: Select-animation-spin 400ms infinite linear;
    width: 16px;
    height: 16px;
    box-sizing: border-box;
    border-radius: 50%;
    border: 2px solid #ccc;
    border-right-color: #333;
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }

  .Select-clear-zone {
    -webkit-animation: Select-animation-fadeIn 200ms;
    -o-animation: Select-animation-fadeIn 200ms;
    animation: Select-animation-fadeIn 200ms;
    color: #999;
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 10px;
    top: 2px;
  }

  .Select-clear-zone:hover {
    color: #D0021B;
  }

  .Select-clear {
    display: inline-block;
    font-size: 18px;

  }

  .Select--multi .Select-clear-zone {
    width: 17px;
  }

  .Select-arrow-zone {
    cursor: pointer;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: middle;
    width: 40px;
  }

  .Select-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
  }

  .is-open .Select-arrow,
  .Select-arrow-zone:hover > .Select-arrow {
    border-top-color: #666;
  }

  .Select--multi .Select-multi-value-wrapper {
    display: inline-flex;
    flex-wrap: wrap;
  }

  .Select .Select-aria-only {
    display: inline-block;
    height: 1px;
    width: 1px;
    margin: -1px;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    float: left;
  }

  .Select-menu-outer {
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(51, 60, 72, 0.3);
    border: solid 1px #c5d0e1;
    background-color: #ffffff;
    box-sizing: border-box;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    max-height: 146px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    overflow: auto;
  }

  .Select-menu {
    max-height: 198px;
  }

  .Select-option {
    box-sizing: border-box;
    background-color: #fff;
    color: #666666;
    cursor: pointer;
    display: block;
    white-space: normal;

    &.is-focused {
      background-color: #ebf5ff;
      /* Fallback color for IE 8 */
      background-color: rgba(0, 126, 255, 0.08);
      color: #333;
    }
  }

  .Select-noresults {
    box-sizing: border-box;
    color: #999999;
    cursor: default;
    display: block;
    padding: 8px 10px;
  }

  .Select--multi .Select-input {
    vertical-align: middle;
    margin-left: 10px;
    padding: 0;
  }

  .Select--multi.has-value .Select-input {
    margin-left: 5px;
  }

  .Select--multi .Select-value {
    background-color: #ebf5ff;
    border-radius: 2px;
    border: 1px solid #c2e0ff;
    color: #007eff;
    display: inline-block;
    font-size: 0.9em;
    line-height: 1.4;
    margin-left: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    vertical-align: top;
  }

  .Select--multi .Select-value-icon,
  .Select--multi .Select-value-label {
    display: inline-block;
    vertical-align: middle;
  }
  .Select--multi .Select-value-label {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
    cursor: default;
    padding: 2px 5px;
  }

  .Select--multi a.Select-value-label {
    color: #007eff;
    cursor: pointer;
    text-decoration: none;
  }

  .Select--multi a.Select-value-label:hover {
    text-decoration: underline;
  }

  .Select--multi .Select-value-icon {
    cursor: pointer;
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-right: 1px solid #c2e0ff;
    padding: 1px 5px 3px;
  }

  .Select--multi .Select-value-icon:hover,
  .Select--multi .Select-value-icon:focus {
    background-color: #d8eafd;
    color: #0071e6;
  }

  .Select--multi .Select-value-icon:active {
    background-color: #c2e0ff;
  }

  .Select--multi.is-disabled .Select-value {
    background-color: #fcfcfc;
    border: 1px solid #e3e3e3;
    color: #333;
  }

  .Select--multi.is-disabled .Select-value-icon {
    cursor: not-allowed;
    border-right: 1px solid #e3e3e3;
  }

  .Select--multi.is-disabled .Select-value-icon:hover,
  .Select--multi.is-disabled .Select-value-icon:focus,
  .Select--multi.is-disabled .Select-value-icon:active {
    background-color: #fcfcfc;
  }
`

export default SelectStyled

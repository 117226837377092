import React from 'react'
import ComposicaoCargaForm from 'pages/composicao-carga/form'
import Session from 'utils/user-storage'
import { useGlobalContext } from 'hooks/useGlobalContext'

const LoadCompositionModal = ({ idComposicao, readMode, open, close }) => {
  const { showFeedback } = useGlobalContext()

  return (
    open &&
    <ComposicaoCargaForm
      idEdit={idComposicao}
      idPedido={0}
      open={open}
      idUser={Session.get().Id}
      handleShowFeedback={showFeedback}
      handleRefresh={close}
      handleClose={close}
      modoLeitura={readMode}
    />
  )
}

export default LoadCompositionModal

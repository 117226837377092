import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  top: 22px;
  left: 10px;
`

export const Content = styled.div`
  min-width: 260px;
  overflow: auto;
  border-radius: 4px;
  background: var(--color-neutral-400);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);

  position: absolute;
  right: 0;
  z-index: 999;
  padding: 10px;

  .heading {
    font-weight: var(--font-weight-bold);
  }
`

export const TextWhite = styled.div`
  color: var(--color-contrast-white);
  font-size: 14px;
  margin-left: 8px;
`

export const ColorCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const DisplayContent = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 2px 5px 8px;
  margin-left: 4px;

  .danger {
    border-color:  var(--color-feedback-danger-600);
    background: var(--color-feedback-danger-100);
  }
  .positive {
    border-color:  var(--color-feedback-positive-600);
    background: var(--color-feedback-positive-100);
  }

  .warning {
    border-color:  var(--color-feedback-warning-600);
    background: var(--color-feedback-warning-100);
  }

  .brand {
    border-color:  var(--color-brand-600);
    background: var(--color-brand-100);
  }

  .neutral {
    border-color:  var(--color-neutral-600);
    background: var(--color-neutral-100);
  }
`

export const Legend = styled.span`
  width: 15px;  
  height: 15px;
  border-radius: 3px;
  border: 1px solid;
`

export const IconsWrapper = styled.div``
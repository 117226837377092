import styled from 'styled-components'

const StyledHintList = styled.div`

  &:not(.notHover)${props => props.applyHover ?':hover' : '' } {
    border-radius: 15px 15px 0 0 ;
    
    .hintListComponent {
      width: 100%;
      padding: 10px 15px;
    }
    .hintListItensComponent {
        opacity: 1;
        z-index: 9999;
        height: auto;
    }
  }

  .hintListComponent {
    position: relative;
    text-align: center;
    background-color: #e7f0f7;
    padding: 5px 10px;
    font-weight: bold;
    border-radius: 15px; 
    color: #2870b2;
    
    width: 90%;
    margin: 0 auto;
    
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
  }
  
  .hintListLabelComponent {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hintListItensComponent {
    opacity: 0;
    position: absolute;
    top: 25px;
    height: 0;
    left: 0;
    right: 0;
    background: #e7f0f7;
    padding: 15px 15px;
    border-radius: 0 0 15px 15px;
    font-weight: bold;
    z-index: -1;
    
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
    
    div {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: block;
    }
  }
`

export default StyledHintList

import React from 'react'
import { DropdownGroup } from './dropdown-group'
import * as S from './styled'

export const Dropdown = ({ options, action, className }) => {
  return (
    <S.Dropdown className={className}>
      {options.map(group =>
        <DropdownGroup
          disabled={group.disabled}
          key={group.label}
          handleClick={() => action(group.action)}
          group={group}
        />
      )}
    </S.Dropdown>
  )
}


import React, { Component } from "react";
import { Row, Col } from "react-flexbox-grid";
import { Button } from "@hbsis.uikit/react";
import PropTypes from "prop-types";
import OrderListItem from "./order-list-item";
import SuggestionsListItem from "./suggestions-list-item";
import ShelfLifeListItem from "./shelf-life-list-item";
import SuggestionsListHeader from "./suggestions-list-header";
import StyledSuggestionsList from "./suggestions-list.styled";
import intl from "react-intl-universal";
import IconTrash from "images/icn-trash-bin-white.svg";
import IconAddSmall from "images/icn-add-small-blue.svg";
import { getListCodeHeaderAbbreviated, usesJustificationValidationAcceptingSuggestion } from "utils/validations-general-settings";

class SuggestionsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemsChecked: false,
      itemsShelflifeChecked: false,
      suggestionsCheckedData: [],
      shelflifeCheckedData: []
    };

    this.tableListBodyElement = null;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.itemsChecked !== undefined &&
      nextProps.itemsChecked !== this.props.itemsChecked
    ) {
      this.setState({
        itemsChecked: nextProps.itemsChecked,
      });
    }

    if (
      nextProps.itemsShelflifeChecked !== undefined &&
      nextProps.itemsShelflifeChecked !== this.props.itemsShelflifeChecked
    ) {
      this.setState({
        itemsShelflifeChecked: nextProps.itemsShelflifeChecked,
      });
    }
  }

  checkItem = (item, value) => {
    if (value) {
      this.setState(
        (state) => ({
          suggestionsCheckedData: [...state.suggestionsCheckedData, item.Id],
        }),
        () => this.props.checkItem(this.state.suggestionsCheckedData)
      );
    } else {
      this.setState(
        (state) => ({
          suggestionsCheckedData: state.suggestionsCheckedData.filter(
            (idMaterial) => idMaterial !== item.Id
          ),
        }),
        () => this.props.checkItem(this.state.suggestionsCheckedData)
      );
    }
  };

  checkShelfLife = (item, value) => {
    if (value) {
      this.setState(
        (state) => ({
          shelflifeCheckedData: [...state.shelflifeCheckedData, item],
        }),
        () => this.props.checkShelflifeItem(this.state.shelflifeCheckedData)
      );
    } else {
      this.setState(
        (state) => ({
          shelflifeCheckedData: state.shelflifeCheckedData.filter(
            (idMaterial) => idMaterial !== item.Id
          ),
        }),
        () => this.props.checkShelflifeItem(this.state.shelflifeCheckedData)
      );
    }
  };

  checkAllItems = () => {
    let suggestionsCheckedData = [...this.state.suggestionsCheckedData];
    if (!this.state.itemsChecked) {
      suggestionsCheckedData = this.props.data
        .filter((m) => m.Coleta && m.DataEntregaDe && m.naoSalvo !== true)
        .map((m) => m.Id);
    } else {
      suggestionsCheckedData = [];
    }

    this.setState(
      {
        itemsChecked: !this.state.itemsChecked,
        suggestionsCheckedData,
      },
      () => {
        this.props.checkAllItems(suggestionsCheckedData);
      }
    );
  };

  checkAllItemsShelfLife = () => {
    let shelflifeCheckedData = [...this.state.shelflifeCheckedData];
    if (!this.state.itemsShelflifeChecked) {
      shelflifeCheckedData = this.props.data.map((m) => m);
    } else {
      shelflifeCheckedData = [];
    }

    this.setState(
      {
        itemsShelflifeChecked: !this.state.itemsShelflifeChecked,
        shelflifeCheckedData,
      },
      () => {
        this.props.checkAllItems(shelflifeCheckedData);
      }
    );
  };

  expandItem = (...params) => {
    this.props.expandItem(...params, this.tableListBodyElement);
  };

  excluirSugestoes = () => {
    const materialsChecked = [...this.state.suggestionsCheckedData];

    if (!materialsChecked.length) {
      this.props.handleFeedback(intl.get("feedbacks.oneSuggestionDelete"));
    } else {
      this.props.excluirSugestao(materialsChecked);
      this.setState({
        unCheckItems: true,
        suggestionsCheckedData: [],
      });
    }
  };

  aceitarSugestoes = () => {
    const listaSugestoes = this.props.data;
    const materialsChecked = [...this.state.suggestionsCheckedData];

    if (
      !this.validarJustificativaSugestaoManualOuAlterada(
        materialsChecked,
        listaSugestoes
      ) &&
      usesJustificationValidationAcceptingSuggestion()
    ) {
      this.props.handleFeedback(intl.get("feedbacks.mandatoryJustification"));
    }

    if (
      !this.validarSomenteSugestoesManuaisSelecionadas(
        materialsChecked,
        listaSugestoes
      )
    ) {
      this.props.handleFeedback(intl.get("feedbacks.manualSuggestion"));
    } else {
      this.setState(
        {
          unCheckItems: true,
          suggestionsCheckedData: [],
        },
        () => {
          this.setState({ unCheckItems: false }, () => {
            this.props.verificarInconsistenciasDaSugestao(materialsChecked);
            this.props.checkItem([]);
          });
        }
      );
    }
  };

  validarJustificativaSugestaoManualOuAlterada = (
    materialsChecked,
    listaSugestoes
  ) => {
    let isValid = true;
    materialsChecked.map((item, index) => {
      listaSugestoes.forEach((sugestao) => {
        if (sugestao.Id === item) {
          if (sugestao.StatusSigla === "SM" || sugestao.StatusSigla === "SA") {
            if (!sugestao.TipoJustificativaSugestaoManual) {
              isValid = false;
            }
          }
        }
      });
    });
    return isValid;
  };

  validarSomenteSugestoesManuaisSelecionadas = (
    materialsChecked,
    listaSugestoes
  ) => {
    let possuiSugestaoAutomaticaPedidoCompraChecada = false;
    let possuiSugestaoManualPedidoCompraChecada = false;
    let pedidoDeTransferencia = false;
    const possuiSugestaoAutomatica = listaSugestoes.some(
      (sugestao) =>
        sugestao.StatusSigla === "SA" || sugestao.StatusSigla === "SG"
    );

    materialsChecked.map((item, index) => {
      listaSugestoes.forEach((sugestao) => {
        if (sugestao.Id === item) {
          if (sugestao.Tipo === "PT") pedidoDeTransferencia = true;

          if (
            sugestao.Tipo === "PC" &&
            (sugestao.StatusSigla === "SA" || sugestao.StatusSigla === "SG")
          )
            possuiSugestaoAutomaticaPedidoCompraChecada = true;

          if (sugestao.Tipo === "PC" && sugestao.StatusSigla === "SM")
            possuiSugestaoManualPedidoCompraChecada = true;
        }
      });
    });

    if (pedidoDeTransferencia) {
      return true;
    }

    return !(
      possuiSugestaoAutomatica &&
      possuiSugestaoManualPedidoCompraChecada &&
      !possuiSugestaoAutomaticaPedidoCompraChecada
    )
  };

  isHighlighted = (id) => {
    const { itemHighlighted } = this.props;
    return itemHighlighted > 0 && itemHighlighted == id;
  };

  createSuggestionShelfLife = () => {
    const materialsChecked = [...this.state.shelflifeCheckedData];

    this.props.acceptSuggestionsShelfLife(materialsChecked);
    this.setState(
      {
        unCheckShelflifeItems: true,
        shelflifeCheckedData: [],
      },
      () => {
        this.setState({ unCheckShelflifeItems: false }, () => {
          this.props.checkShelflifeItem([]);
        });
      }
    );
  };

  processHeaderRender = () => {
    const { data, isOrder, isOrderBulk, isShelfLife } = this.props;

    const { itemsChecked, itemsShelflifeChecked } = this.state;

    if (data && !!data.length && !isShelfLife) {
      return (
        <SuggestionsListHeader
          checked={itemsChecked}
          checkItem={this.checkAllItems}
          idCheckbox="header-checkbox"
          isOrder={isOrder || isOrderBulk}
        >
          <Row middle="xs">
            <Col xs={isOrder ? 1 : 2}>
              <div className="header-item">
                {isOrder || isOrderBulk
                  ? intl.get("requestOrders.step")
                  : intl.get("commons.status")}
              </div>
            </Col>
            <Col xs={isOrder ? 1 : 2}>
              <div className="header-item">
                {intl.get("master-data.general-configuration.type")}
              </div>
            </Col>
            <Col xs={2}>
              <div className="header-item">{intl.get("commons.collect")}</div>
            </Col>
            <Col xs={2} >
              <div className="header-item">{intl.get("commons.delivery")}</div>
            </Col>
            <Col xs={2}>
              <div className="header-item">
                {intl.get("stocks.stockDetail.qtde")}
              </div>
            </Col>
            <Col xs={2}>
              <div className="header-item">
                {isOrder
                  ? intl.get("commons.code")
                  : isOrderBulk
                    ? intl.get("stocks.stockDetail.volumnPortal")
                    : "UN"}
              </div>
            </Col>
            {
              isOrder &&
              <Col xs={2}>
                <div className="header-item">
                  {getListCodeHeaderAbbreviated()}
                </div>
              </Col>
            }
          </Row>
        </SuggestionsListHeader>
      );
    } else if (data && !!data.length && isShelfLife) {
      return (
        <SuggestionsListHeader
          checked={itemsShelflifeChecked}
          checkItem={this.checkAllItemsShelfLife}
          idCheckbox="header-checkbox-shelf-life"
          isOrder={false}
        >
          <Row middle="xs">
            <Col xs={2}>
              <div className="header-item">{intl.get("commons.source")}</div>
            </Col>
            <Col xs={3}>
              <div className="header-item">{intl.get("commons.lot")}</div>
            </Col>
            <Col xs={2}>
              <div className="header-item">
                {intl.get("stocks.stockDetail.qtde")}
              </div>
            </Col>
            <Col xs={2}>
              <div className="header-item">
                {intl.get("stocks.stockDetail.venc")}
              </div>
            </Col>
            <Col xs={3}>
              <div className="header-item">
                {intl.get("stocks.stockDetail.descont")}
              </div>
            </Col>
          </Row>
        </SuggestionsListHeader>
      );
    }
    return (
      <div />
    )
  };

  processListRender = (item, index) => {
    const {
      changeValueSugestao,
      obterListaJustificativasSugestaoManual,
      listaJustificativasSugestaoManual,
      changeValueDeadline,
      salvarSugestao,
      excluirSugestao,
      cancelarSaldoPedidoVolume,
      showCancelConfirm,
      showFormOrder,
      isOrder,
      isOrderBulk,
      isShelfLife,
      isTransfer,
      itemExpanded,
      suggestionsExpandedList,
      shelflifeExpandedList,
      handleFeedback,
      changeValueOrder,
      saveOrder,
      changeHoraEntrega,
      isNewFlow,
      documentTypes,
      doors     
    } = this.props;

    const {
      itemsChecked,
      unCheckItems,
      unCheckShelflifeItems,
      itemsShelflifeChecked,
    } = this.state;

    if (!isOrder && !isOrderBulk && !isShelfLife) {
      return (
        <SuggestionsListItem
          key={item.Id}
          item={item}
          hasIcon
          itemIndex={index}
          checked={itemsChecked}
          unCheckItem={unCheckItems}
          checkItem={this.checkItem}
          expandItem={this.expandItem}
          expanded={
            suggestionsExpandedList &&
            suggestionsExpandedList.findIndex((sugg) => sugg.Id === item.Id)
          }
          changeValueSugestao={changeValueSugestao}
          changeHoraEntrega={changeHoraEntrega}
          obterListaJustificativasSugestaoManual={
            obterListaJustificativasSugestaoManual
          }
          listaJustificativasSugestaoManual={listaJustificativasSugestaoManual}
          salvarSugestao={salvarSugestao}
          excluirSugestao={excluirSugestao}
          cancelarSaldoPedidoVolume={cancelarSaldoPedidoVolume}
          showCancelConfirm={showCancelConfirm}
          showFormOrder={showFormOrder}
          changeValueDeadline={changeValueDeadline}
          highlighted={this.isHighlighted(item.Id)}
          handleFeedback={handleFeedback}
          itemDimmed={item.StatusSigla === "AG" ? true : false}
          centro={this.props.centro}
          idUnidadeNegocio={this.props.idUnidadeNegocio}
          linhaProducaoCentro={this.props.linhaProducaoCentro}
          documentTypes={documentTypes}
          doors={doors}
        />
      );
    } else if (isOrder || isOrderBulk) {
      return (
        <OrderListItem
          key={item.Id}
          item={item}
          hasIcon
          isOrder={isOrder}
          isOrderBulk={isOrderBulk}
          isTransfer={isTransfer}
          itemIndex={index}
          checked={itemsChecked}
          unCheckItem={unCheckItems}
          checkItem={this.checkItem}
          expandItem={this.expandItem}
          expanded={
            itemExpanded &&
            (isOrder || isOrderBulk) &&
            item.Id === itemExpanded.Id
          }
          changeValueOrder={changeValueOrder}
          salvarSugestao={salvarSugestao}
          excluirSugestao={excluirSugestao}
          cancelarSaldoPedidoVolume={cancelarSaldoPedidoVolume}
          showCancelConfirm={showCancelConfirm}
          showFormOrder={showFormOrder}
          changeValueDeadline={changeValueDeadline}
          highlighted={this.isHighlighted(item.Id)}
          itemDimmed={item.StatusSigla === "AG" ? true : false}
          saveOrder={saveOrder}
          usuarioComPermissaoParaCancelarPedidos={
            this.props.usuarioComPermissaoParaCancelarPedidos
          }
          itemExpanded={false}
          isNewFlow={isNewFlow}
        />
      );
    } else {
      return (
        <ShelfLifeListItem
          key={index}
          item={item}
          hasIcon
          isShelfLife={isShelfLife}
          itemIndex={index}
          checked={itemsShelflifeChecked}
          unCheckItem={unCheckShelflifeItems}
          expandItem={this.expandItem}
          checkShelflifeItem={this.checkShelfLife}
          expanded={
            shelflifeExpandedList &&
            shelflifeExpandedList.findIndex((sugg) => sugg.Id === item.Id)
          }
        />
      );
    }
  };

  processNoData = () => {
    const { isOrder, isOrderBulk, isTransfer, isShelfLife } = this.props;

    if (isTransfer)
      return intl.get("stocks.emptyList.outgoingTransferProgress");
    else if (isOrder) return intl.get("stocks.emptyList.orderProgress");
    else if (isOrderBulk) return intl.get("stocks.emptyList.volume");
    else if (isShelfLife) return intl.get("stocks.emptyList.transfer");
    else return intl.get("stocks.emptyList.suggestion");
  };

  processFooter = () => {
    const { formValid, isOrder, isOrderBulk, isShelfLife } = this.props;

    if (!isOrder && !isOrderBulk && !isShelfLife) {
      return (
        <div className="table-list-footer">
          <div title={intl.get("stocks.stockDetail.deleteSuggestion")}>
            <Button
              icon={IconTrash}
              width="50px"
              value=""
              type="danger"
              onClick={this.excluirSugestoes}
            />
          </div>
          <div title={intl.get("stocks.stockDetail.newSuggestion")}>
            <Button
              width="50px"
              icon={IconAddSmall}
              value=""
              type="secondary"
              onClick={this.props.createSuggestionManual}
            />
          </div>
          <div title={intl.get("stocks.stockDetail.accSuggestion")}>
            <Button
              width="200px"
              value={intl.get("stocks.stockDetail.accSuggestion")}
              type="primary"
              disabled={!formValid}
              onClick={this.aceitarSugestoes}
            />
          </div>
        </div>
      );
    } else if (isShelfLife) {
      return (
        <div className="table-list-footer">
          <div title={intl.get("stocks.stockDetail.addSuggestion")}>
            <Button
              width="200px"
              value={intl.get("stocks.stockDetail.addSuggestion")}
              type="primary"
              onClick={this.createSuggestionShelfLife}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    const { data } = this.props;
    return (
      <StyledSuggestionsList>
        {this.processHeaderRender()}

        <div
          ref={(ref) => (this.tableListBodyElement = ref)}
          className="table-list-body"
        >
          {data && data.length ? (
            data.map((item, index) => this.processListRender(item, index))
          ) : (
            <div className="no-data">
              <p>{this.processNoData()}</p>
            </div>
          )}
        </div>
        {this.processFooter()}
      </StyledSuggestionsList>
    );
  }
}

SuggestionsList.propTypes = {
  data: PropTypes.array.isRequired,
  itemsChecked: PropTypes.bool,
  formValid: PropTypes.bool,
  expandItem: PropTypes.func,
  checkAllItems: PropTypes.func,
  createSuggestionManual: PropTypes.func,
  verificarInconsistenciasDaSugestao: PropTypes.func,
  salvarSugestao: PropTypes.func,
  excluirSugestao: PropTypes.func,
  showCancelConfirm: PropTypes.func,
  showFormOrder: PropTypes.func,
  changeValueSugestao: PropTypes.func,
  obterListaJustificativasSugestaoManual: PropTypes.func,
  changeValueDeadline: PropTypes.func,
  cancelarSaldoPedidoVolume: PropTypes.func,
  checkItem: PropTypes.func,
  isOrder: PropTypes.bool,
  isOrderBulk: PropTypes.bool,
  isShelfLife: PropTypes.bool,
  itemExpanded: PropTypes.object,
  suggestionsExpandedList: PropTypes.array,
  usuarioComPermissaoParaCancelarPedidos: PropTypes.func,
  checkShelfLifeItem: PropTypes.func,
  changeHoraEntrega: PropTypes.func,
};

export default SuggestionsList;

import moment from 'moment';
import { ISO_DATE_FORMAT } from 'utils/format-date';

const getDefaultDate = () => ({
  dataDe: moment().format(ISO_DATE_FORMAT),
  dataAte: moment().add(2, 'weeks').format(ISO_DATE_FORMAT),
})

export const loadFilter = () => {
  const filtro = localStorage.getItem("filterChart");
  if (filtro) {
    const _filtro = JSON.parse(filtro);
    if (moment(_filtro.dataDe, 'YYYY/MM/DD').format(ISO_DATE_FORMAT) < getDefaultDate().dataDe) {
      return getDefaultDate();
    }

    return JSON.parse(filtro);
  }
  else {
    return getDefaultDate();
  }
}

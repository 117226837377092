import React from 'react'
import { Button } from '@hbsis.uikit/react'
import intl from 'react-intl-universal'
import * as S from './styled'

const CancelDialog = ({ title, close, open, message, confirm }) => {
  return (
    <S.CancelDialogWrapper
      title={title}
      contentStyle={{ width: '400px' }}
      open={open}
      onRequestClose={close}
      actionsContainerClassName="container-action-buttons__deleteModal"
      actions={
        [
          <Button
            value={intl.get('geral.modalConfirm.goBack')}
            data-testid="buttonCancel"
            type='default'
            onClick={close}
            className='container-action-buttons__deleteModal--buttons'
          />,
          <Button
            value={intl.get('geral.modalConfirm.yes')}
            type='danger'
            onClick={confirm}
            className='container-action-buttons__deleteModal--buttons'
          />
        ]
      } >
      <div className='container-message'>
        {message}
      </div>
    </S.CancelDialogWrapper>
  )
}
export default CancelDialog


import moment from 'moment'

export const getCountByType = (data, prop) => {
  const propData = data[0][prop]
  return propData ? propData.length : 0
}

export const getKeysByType = (type) => type === 'supplier' ?
  {
    key: 'outgoingData',
    prop: 'BusinessUnitPlant'
  } :
  {
    key: 'entry',
    prop: 'SupplierName'
  }

export const validateEditRow = (key, isAdvancedMode) => isAdvancedMode && !noEditableFields.includes(key)
const noEditableFields = ["BreweryFinalStock", "SupplierFinalStock", 'BreweryMerchandiseEntry', 'OutgoingMerchandiseSupplier']

export const stageOfWeek = (date) => {
  const diaAtual = new Date(date)
  const mesAtual = diaAtual.getMonth()

  const firstDayOfWeek = moment(diaAtual).startOf('isoWeek').toDate()
  const lastDayOfWeek = moment(diaAtual).endOf('isoWeek').toDate()

  const arr = getDatesBetweenAndMonth(firstDayOfWeek, lastDayOfWeek, mesAtual)
  const calc = arr.length % 2 === 0 ? 0 : Math.round(arr.length / 2)
  const position = arr.indexOf(diaAtual.toLocaleDateString())

  return { arr, calc, position }
}

export const getDatesBetweenAndMonth = (startDate, endDate, month) => {
  const dates = []
  let currentDate = new Date(startDate)

  while (currentDate <= endDate) {
    dates.push(new Date(currentDate))
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dates
    .filter((date) => date.getMonth() === month)
    .map((_) => _.toLocaleDateString())
}

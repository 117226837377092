import Session from "utils/user-storage";
import axios from "axios";
import { getApimSubscriptionKey, getHost } from "./config-environment";
import { authProvider } from "../providers/authProvider";
import { tokenAuthorization } from "../providers/tokenProvider";

const HOST = getHost();
export const Host = () => HOST;

const instance = axios.create({
  baseURL: HOST,
  timeout: 1000000000000,
});

const getLanguage = (languageCode) => {
  let language;

  switch (languageCode) {
    case 1:
      language = "pt-BR"
      break;
    case 3:
      language = "es-ES";
      break;
    case 2:
    default:
      language = "en-US";
      break;
  }

  return language;
}

instance.interceptors.request.use(
  async (config) => {
    try {
      const token = await authProvider().getAccessToken();
      config.headers.Authorization = `Bearer ${token.accessToken}`;
      config.headers['Ocp-Apim-Subscription-Key'] = getApimSubscriptionKey();
    } catch (err) {
      console.log("Error token: ", err);
    }

    const isUrlAuthenticationEmail =
      config.url === "usuario/emailAuthentication/";
    const isUrlGetTokenAuthorization =
      config.url === "/users:validation-token";

    config.headers["Accept-Language"] = Session.get() && getLanguage(Session.get().Idioma);
    config.headers["Accept"] = "application/json";

    if (!(isUrlAuthenticationEmail || isUrlGetTokenAuthorization)) {
      config.headers["ValidationToken"] = await tokenAuthorization();
    }

    return config;
  },
  (error) => Promise.reject(error)
);

function Fetch() {
  return {
    get: (url) => instance.get(url),
    post: (url, data) => instance.post(url, data),
    put: (url, data) => instance.put(url, data),
    delete: (url, data) => instance.delete(url, { data }),
    patch: (url, data) => instance.patch(url, data)
  };
}

export default Fetch();

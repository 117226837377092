import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import intl from 'react-intl-universal'
import InformationOrderStyled from './information-orders.styled'
import { formatDeliveryTime } from 'utils/delivery-time'
import { formatNumber } from 'utils/format'

const InformationOrder = ({ pedido }) => (
  <InformationOrderStyled>
    <Row>
      <Col xs={6}>
        <span className='span'>
          {intl.get('stocks.portalCode')}
          <strong className='content'>
            {pedido.NumeroPedido}
          </strong>
        </span>
      </Col>

      <Col xs={6}>
        <span className='span'>
          {intl.get('stocks.timeline.sapOrder')}
          <strong className='content'>
            {!pedido.NumeroPedidoSap ? '' : `${parseInt(pedido.NumeroPedidoSap)} - ${parseInt(pedido.NumeroItemPedidoSap)}`}
          </strong>
        </span>
      </Col>
    </Row>

    <Row>
      <Col xs={6}>
        <span className='span'>
          {intl.get('stocks.currentCollection')}
          <strong className='content'>
            {pedido.DataColeta}
          </strong>
        </span>
      </Col>

      <Col xs={6}>
        <span className='span'>
          {intl.get('stocks.currentDelivery')}
          <strong className='content'>
            {`${pedido.DataEntrega} ${formatDeliveryTime(pedido.HoraEntrega)}`}
          </strong>
        </span>
      </Col>
    </Row>

    <Row>
      <Col xs={6}>
        <span className='span'>
          {intl.get('stocks.material')}
          <strong className='content'>
            {pedido.Material}
          </strong>
        </span>
      </Col>
      <Col xs={6}>
        <span className='span'>
          {intl.get('stocks.currentQuantity')}
          <strong className='content'> {`${formatNumber(pedido.Quantidade)} ${pedido.UnidadeMedida ?? ''}`}</strong>
        </span>
      </Col>
    </Row>
  </InformationOrderStyled>
)

export default InformationOrder

import styled from 'styled-components'
import { Button as ButtonMUI } from 'components/uikit-adapter/index'

export const ButtonBack = styled(ButtonMUI)`
  align-self: center;
  width: 200px;
  background-color: #E7F0F7;
  margin: 10px;
`
export const Button = styled(ButtonMUI)`
  align-self: center;
  width: 200px;
  background-color: #E7F0F7;
  margin: 10px;
`
export const WrapperButtons = styled.div`
  display: flex;
  justify-content: space-between;
`

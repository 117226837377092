import React from 'react'
import PropTypes from 'prop-types'

import HeaderContainer from './styledHeaderContainer'
import TitleHeader from 'components/title-header'

const Header = ({ title, children, icon, onClickIcon, titleWidth }) => (
  <HeaderContainer>
    {icon &&
      <h3 className="icone" onClick={onClickIcon} data-testid="master-data-menu-button">
        {icon}
      </h3>
    }
    <TitleHeader className="titleHeader" width={titleWidth}>{title}</TitleHeader>
    {children}
  </HeaderContainer>
)

Header.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.any,
  onClickIcon: PropTypes.func,
  titleWidth: PropTypes.number,
}

export default Header

import Fetch from 'utils/fetch';

export const getMaterialDownloadFile = () => {
  return Fetch.get(`familiaRotuladaCentroMaterial/export/familiaRotuladaCentroMaterial`).then(({ data }) => data)
}

export const getFamily = ({ quantityPerPage, page }) => {
  return Fetch.get(`/familia/${quantityPerPage}/${page}`).then((response) => response.data)
}

export const getCriticalItemsByFamilyId = (id) => {
  return Fetch.get(`/configuracaoitenscritico/obterConfiguracaoItensCritico/${id}`).then((response) => response.data)
}

export const save = (data) => {
  return Fetch.post(`/configuracaoitenscritico/salvar`, data)
}
import styled from 'styled-components'

export const Wrapper = styled.div`
${p => p.type === 'default' && renderStyledDefault()}
${p => p.disabled && `
  input{
    background-color: #e5edf4;
    box-shadow: inset 0 2px 0 0 rgb(149 163 183 / 30%);
  }
`}
`
export const Label = styled.label`
  line-height: 1.8rem;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #3b495e;
`

const renderStyledDefault = () => `input{
    background-color: #ffffff;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    font-size: 14px;
    color: #3b495e;
    height: 40px;
    outline: 0;
    border: 1px;
    border-radius: 2px;
    box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #afcef3;
    -webkit-transition: .25s box-shadow;
    transition: .25s box-shadow;
  }
`

import styled from "styled-components";

export const StyledDatePicker = styled.div`
    label {
        line-height: 1.8rem;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #3b495e;
    }
    .picker-container {
        position: relative;
    }
    .react-datepicker-wrapper {
        width: 100%;
    }
    .date-picker {
        background-color: ${props => props.disabled ? '#E6EDF4' : '#ffffff'};
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        box-sizing: border-box;
        font-size: 14px;
        color: #3b495e;
        height: 40px;
        outline: 0;
        border: 1px;
        border-radius: 2px;
        box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 ${props => props.disabled ? '#c5d0e1' : '#afcef3'};
        -webkit-transition: .25s box-shadow;
        transition: .25s box-shadow;
    }

    ${p => p.lastInput && '.react-datepicker { left: -40px; }'}
`;


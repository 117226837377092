import { useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import Feature from 'components/feature';
import intl from 'react-intl-universal'
import * as S from '../navbar-user-actions/styled'
import MenuItem from "@material-ui/core/MenuItem";
import LogoffIcon from "images/icn-user-profile.svg";
import { DialogsUserSettings } from '../navbar-user-settings/dialogs-user-settings';
import { dataNavbarUserSettings } from '../../data/user-settings-data';

export const NavbarUserSettings = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);
  const refs = useRef(null)

  const handleCloseMenu = () => setOpenMenu(null);
  const handleCloseDialog = () => setOpenDialog(null);

  const handleOpenDialog = (option) => {
    setOpenDialog(option);
    handleCloseMenu()
  }

  const openWiki = () => {
    handleCloseMenu();
    window.open('https://wiki.ambevtech.com.br/home/insumos', "blank", "noopener");
  };

  return (
    <>
      <S.ContainerButton>
        <S.MenuList>
          <Button
            title={intl.get("master-data.menus.userAction")}
            onClick={() => setOpenMenu("actions")}
            ref={_ref => refs.current = _ref}
          >
            <img alt="Logout" src={LogoffIcon} />
          </Button>
          <S.Menu
            anchorEl={refs.current}
            open={openMenu === 'actions'}
            onClose={handleCloseMenu}
            keepMounted
          >
            {dataNavbarUserSettings(openWiki, handleOpenDialog).map(item => (
              <Feature validation={item.visible}>
                <MenuItem onClick={item.handleClick}>
                  {item.label}
                </MenuItem>
              </Feature>
            ))}
          </S.Menu>
        </S.MenuList>
      </S.ContainerButton>
      <DialogsUserSettings open={openDialog} handleClose={handleCloseDialog} />
    </>
  )
}

import React, { useState } from 'react'
import { Vector } from './vector'
import { DropdownItem } from './dropdown-item'
import VectorNext from 'images/Vector-Right.png'
import * as S from './styled'

export const DropdownGroup = ({ group, handleClick, disabled }) => {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <>
      <S.DropdownGroup
        onClick={(event) => !group.isGroup ? handleClick(event.target.innerText): undefined}
        disabled={disabled}
        onMouseEnter={() => setIsVisible(!isVisible)}
        onMouseLeave={() => setIsVisible(!isVisible)}
      >
        <S.DropdownGroupLabel >
          {group.label}{group.isGroup && <Vector style={{ marginLeft: "3px" }} vector={VectorNext} />}
        </S.DropdownGroupLabel>
        {
          (group.isGroup && isVisible) &&
          <S.DropdownItemWrapper>
            {group.options.map(option =>
              <DropdownItem
                onClick={() => handleClick(option.label)}
                key={option.label}
                label={option.label}
              />)}
          </S.DropdownItemWrapper>
        }
      </S.DropdownGroup>
    </>
  )
}


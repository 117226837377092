import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 250px;
  .styledCenterLoading__StyledCenterLoading-hwbsbr-0{
    position: absolute;
    max-height: 400px;
    top: 0;
  }
`

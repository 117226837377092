import React from 'react'
import { TIPOS_TAREFA } from 'pages/tasks-vision'
import icnTaskComplete from 'images/task-complete.svg'
import { Button } from '@hbsis.uikit/react'
import intl from 'react-intl-universal';
import ActionsInCancellation from '../../../timeline/em-cancelamento/actionsInCancellation';
import * as S from './styled'
import WaitingCheckRefusedButton from '../../../timeline/em-cancelamento/waitingCheckRefusedButton';
import { Link } from 'react-router-dom'

const PERFIL_MASTER_DATA_SOLUTIONS = 2

const ActionsCard = ({
  user,
  task,
  completarTarefa,
  startLoading,
  stopLoading,
  refresh,
  handleConfirmCancellationAwaitingCheck,
  handleDetailingCargoComposition
}) => {
  const renderAction = (tipoTarefa) => {
    switch (tipoTarefa) {
      case TIPOS_TAREFA.TAREFA_CANCELAMENTO_RECUSADO_AGUARDANDO_CIENCIA:
        return <WaitingCheckRefusedButton
          stopLoading={stopLoading}
          startLoading={startLoading}
          refresh={refresh}
          idPedido={task.IdOrigem}
        />
      case TIPOS_TAREFA.TAREFA_CANCELAMENTO_AGUARDANDO_CIENCIA:
        return <Button
          type="primary"
          value="OK"
          onClick={handleConfirmCancellationAwaitingCheck}
          className='btn-check-cancellation'
        />
      case TIPOS_TAREFA.TAREFA_AGUARDANDO_ANALISE_CANCELAMENTO:
        return <S.WaitingAnalysisButtom>
          <ActionsInCancellation
            stopLoading={stopLoading}
            startLoading={startLoading}
            refresh={refresh}
            idPedido={task.IdOrigem}
          />
        </S.WaitingAnalysisButtom>
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_CARGA_AGUARDANDO_REVISAO:
        return <Button
          type="primary"
          value={intl.get('tasks.review')}
          onClick={handleDetailingCargoComposition}
          className='btn-review-load-composition'
        />
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_NEGOCIACAO:
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_RENEGOCIACAO_EMERGENCIAL:
        return <Link
          style={{ textDecoration: 'none' }}
          to={`/loadComposition?code=${task.IdOrigem}`}
        >
          <Button
            type="primary"
            value={intl.get('tasks.review')}
            className='btn-review-load-composition'
          />
        </Link>
      default:
        return user.PerfilAcesso === PERFIL_MASTER_DATA_SOLUTIONS && <Button
          type="default"
          icon={icnTaskComplete}
          onClick={completarTarefa}
          className="btn-completar-tarefa"
        />
    }
  }

  return (
    <div className="button-card" title={intl.get('tasks.completeTask') + task.Id}>
      {renderAction(task.TipoTarefa)}
    </div>
  )
}

export default ActionsCard;

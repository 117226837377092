import React from 'react'
import PropTypes from 'prop-types'
import { State } from 'react-powerplug'
import styled from 'styled-components'

import TableLabel from './TableLabel'
import TableRow from './TableRow'

import { COLUMN_WIDTH } from './constants'

const StyledTable = styled.div`
  * { box-sizing: border-box; }

  .etb-row-group:first-child {
    .etb-row-labels {
      .etb-values {
        .etb-column-optimization {
          border-top: none;
        }
      }
    }
  }

  .etb-row-labels {
    font-size: 0.7rem;
    color: #9ba2ac;
    background: #FFF;
    padding: 10px 0;
    border-bottom: 1px dashed #c5d0e1;
    padding-left: 4px;
    box-shadow: inset 4px 0 #afcef3;
  }

  .content-expandable-table-optimization {
    height: calc(100vh - 220px);
    overflow-x: auto;
    background: #f2faff;
  }

  .content-expandable-table-optimization .etb-children {
    background: #fff;
  }

  .etb-column {
    display: flex;
    align-items: center;
    flex: 1 0 ${COLUMN_WIDTH}px;
    padding: 10px 4px;
    box-sizing: border-box;
    font-size: 14px;
  }
`;

const handleExpandItem = (items, setState) => code => {
  if (items.includes(code)) {
    setState(prevState => ({
      expandedItems: prevState.expandedItems.filter(c => c !== code)
    }));
  } else {
    setState(prevState => ({
      expandedItems: [...prevState.expandedItems, code]
    }));
  }
};

const Table = ({
  data,
  labelKey,
  labelsList,
  childrenKey,
  onClickItem,
  onSelectItem,
  allChecked,
  onSelectAllItens,
  showCheckbox,
  resolveValue,
  resolveTitle
}) => (
    <State initial={{ expandedItems: [] }}>
      {({ state, setState }) => (
        <StyledTable>
          <TableLabel
            labelsList={labelsList}
            allChecked={allChecked}
            onSelectAllItens={onSelectAllItens}
            showCheckbox={showCheckbox}
          />
          <div className='content-expandable-table-optimization'>
            {data.map((volume, index) => (
              <TableRow
                key={`${index}`}
                code={`${volume.Id}`}
                childrenKey={childrenKey}
                label={volume[labelKey]}
                item={volume}
                labelsList={labelsList}
                expandedItems={state.expandedItems}
                children={volume[childrenKey]}
                resolveValue={resolveValue}
                resolveTitle={resolveTitle}
                showCheckbox={showCheckbox}
                onClick={onClickItem}
                onSelectItem={onSelectItem}
                onExpandItem={handleExpandItem(state.expandedItems, setState)}
              />
            ))}
          </div>
        </StyledTable>
      )}
    </State>
  );

Table.propTypes = {
  data: PropTypes.array.isRequired,
  labelKey: PropTypes.string,
  childrenKey: PropTypes.string,
  labelsList: PropTypes.array,
  expandedItems: PropTypes.array,
  resolveValue: PropTypes.func,
  resolveTitle: PropTypes.func,
  showCheckbox: PropTypes.func,
  renderCustomAlert: PropTypes.func,
  onClickItem: PropTypes.func,
  onSelectItem: PropTypes.func,
  allChecked: PropTypes.bool,
  onSelectAllItens: PropTypes.func,
  onExpandItem: PropTypes.func,
};

export default Table;

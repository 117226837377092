import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import intl from 'react-intl-universal'
import { Textarea, Input, Button } from '@hbsis.uikit/react'
import { SelectBox } from 'components/uikit-adapter/index'
import DeadlineControl from 'components/deadline-control'
import { formatNumber } from 'utils/format'
import IconTrash from 'images/icn-trash-bin-white.svg'
import IconSaveBlue from 'images/icn-save-blue.svg'
import * as S from './styled'
import Loading from 'components/center-loading'
import { getActionLabel, getActionLabel2 } from './utils'
import InputNumber from '../../../../components/input-number'
import OptionGroup from 'components/option-group'
import { isPurchaseOrderFlow, isLoadCompositionFlowBrewerySide } from 'utils/validations-general-settings'
import StoInformationRow from './components/sto-information-row'
import { OrderType } from 'models/pedidos/orderType'
import { getDoorObjectByName, getDoorsOptions, isDoorsDisabled } from 'utils/doors'

const isDisableSupplier = (identifier, isEditMode, isSupplierDisable) => {
  return identifier === 'IdPedido' || (!isEditMode && isSupplierDisable);
}

const isDisableOrderType = (isEditMode, isSupplierDisable) => {
  return !isEditMode && isSupplierDisable;
}

const isDisableCollectDeliveryDate = (identifier, isEditMode, isOthersDisable) => {
  return identifier === 'IdPedido' || (!isEditMode && isOthersDisable);
}

const isDisableDeliveryTime = (idComposition, isEditMode, isOthersDisable) => {
  return idComposition || (!isEditMode && isOthersDisable);
}

const isDisableIncoterm = (isEditMode, isOthersDisable) => {
  return !isEditMode && isOthersDisable;
}

const isDisableQuantity = (isEditMode, isOthersDisable) => {
  return !isEditMode && isOthersDisable;
}

const isDisableDoor = (identifier, isEditMode, isOthersDisable, doors, doorName) => {
  return identifier === 'IdPedido' || (!isEditMode && isOthersDisable) || isDoorsDisabled(doors, doorName);
}

const isDisableProductionLine = (identifier, isEditMode, isOthersDisable) => {
  return identifier === 'IdPedido' || (!isEditMode && isOthersDisable);
}

const isDisableComments = (identifier, isEditMode, isOthersDisable) => {
  return identifier === 'IdPedido' || (!isEditMode && isOthersDisable);
}

const isDisableNote = (idComposition, isEditMode, isOthersDisable) => {
  return idComposition || (!isEditMode && isOthersDisable);
}

const isDisableStoFields = (identifier, isEditMode, isOthersDisable) => {
  return identifier === 'IdPedido' || (!isEditMode && isOthersDisable);
}

const showOrderType = () => isLoadCompositionFlowBrewerySide() && isPurchaseOrderFlow();

const Render = ({ changeItem, editMode, item, materials, suppliers, productionLines,
  deleteSuggestion, handleClose, saveSuggestion, isLoading, incotermOptions, changeDates,
  identificador, edit, orderTypeOptions, updateOrderTypeOptions, doors }) => {

  const getSuppliers = () => {
    let orderTypeSelected = OrderType.PurchaseOrder;

    if (showOrderType()) orderTypeSelected = orderTypeOptions.optionOne.isChecked ? OrderType.PurchaseOrder : OrderType.TransferenceOrder;

    return suppliers?.filter((supplier) => supplier.SupplierRegisterType == orderTypeSelected);
  }

  const showStoInformationRow = () => {
    const validated = showOrderType()

    if(item.Fornecedor.SupplierRegisterType === 2) {
      return validated && item.Fornecedor.OrderType === OrderType.TransferenceOrder
    }

    if (item.Fornecedor.SupplierRegisterType === 1) {
      return validated && item.Fornecedor.BusinessUnitType === 2
    }

  };

  const handleSave = () => !editMode ? saveSuggestion(showStoInformationRow()) : edit(showStoInformationRow())

  return (
    <S.Wrapper>
      <Row>
        <Col xs={12}>
          <SelectBox
            required
            name='material'
            label={intl.get("commons.material")}
            placeholder={intl.get('commons.material')}
            value={item.Material}
            options={materials}
            onChange={value => changeItem('Material', value)}
            valueKey='Id'
            labelKey='Description'
            searchable
            openOnFocus
            disabled={identificador == 'IdPedido'}
            data-testid="material"
          />
        </Col>
      </Row>
      {showOrderType() &&
        <Row>
          <Col xs={12}>
            <OptionGroup
              title={intl.get('commons.supplier')}
              handleSelect={event => updateOrderTypeOptions(event.target.value)}
              options={orderTypeOptions}
              dataTestId="optionGroup-origin"
              disabled={isDisableOrderType(editMode, item.IsSupplierDisable)}
            />
          </Col>
        </Row>
      }
      <Row>
        <Col xs={12}>
          <SelectBox
            name='fornecedor'
            label={showOrderType() ? '' : intl.get('commons.supplier')}
            placeholder={intl.get('stocks.suggestionsListItem.supplier')}
            value={item.Fornecedor}
            options={getSuppliers()}
            onChange={value => changeItem('Fornecedor', value)}
            valueKey='Id'
            labelKey='Description'
            searchable
            openOnFocus
            disabled={isDisableSupplier(identificador, editMode, item.IsSupplierDisable)}
            data-testid="fornecedor"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <DeadlineControl
            TituloColeta={intl.get('stocks.suggestionsListItem.collect')}
            TituloEntrega={intl.get('stocks.suggestionsListItem.issue')}
            OnChange={(coleta, entrega) => changeDates(coleta, entrega)}
            TransitTime={item.Fornecedor.TransitTime}
            Coleta={item.Coleta}
            DataEntregaDe={item.DataEntregaDe}
            Disabled={isDisableCollectDeliveryDate(identificador, editMode, item.IsOthersDisable)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <Input
            required
            label={intl.get('commons.deliveryTime')}
            name='HoraEntrega'
            htmlType='time'
            value={item.HoraEntrega}
            onChange={event => changeItem('HoraEntrega', event.target.value)}
            data-testid="hora-entrega"
            disabled={isDisableDeliveryTime(item.IdComposicao, editMode, item.IsOthersDisable)}
          />
        </Col>
        <Col xs={6}>
          <SelectBox
            required
            label={intl.get('stocks.suggestionsListItem.incoterm')}
            placeholder={intl.get('stocks.suggestionsListItem.incoterm')}
            name='Incoterm'
            value={item.ResponsavelEntrega}
            valueKey='Key'
            labelKey='Value'
            onChange={value => changeItem('ResponsavelEntrega', value.Key)}
            openOnFocus={true}
            options={incotermOptions}
            width="100%"
            data-testid="responsavelEntrega"
            disabled={isDisableIncoterm(editMode, item.IsOthersDisable)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Input
            label={intl.get('stocks.suggestionsListItem.lotSize')}
            name='tamLote'
            htmlType='text'
            value={formatNumber(item.QuantidadeLote)}
            disabled
          />
        </Col>
        <Col xs={3}>
          <InputNumber
            type="default"
            label={intl.get('stocks.suggestionsListItem.quantity')}
            value={item.Quantidade}
            onChange={value => changeItem('Quantidade', value)}
            data-testid="quantidade"
            disabled={isDisableQuantity(editMode, item.IsOthersDisable)}
            allowDecimals
            decimalsLimit={3}
          />
        </Col>
        <Col xs={3}>
          <InputNumber
            type='default'
            label={intl.get('stocks.suggestionsListItem.cars')}
            name='carros'
            value={item.QuantidadeCarros}
            allowDecimals
            decimalsLimit={3}
            disabled
          />
        </Col>
        <Col xs={3}>
          <InputNumber
            type="default"
            label={intl.get('stocks.suggestionsListItem.total')}
            name='total'
            value={item.Total}
            data-testid="total-quantity"
            onChange={value => changeItem('Total', value)}
            disabled={isDisableQuantity(editMode, item.IsOthersDisable)}
            allowDecimals
            decimalsLimit={3}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <SelectBox
            label={intl.get("commons.door")}
            name="door"
            value={getDoorObjectByName(doors, item.PortaWMS)}
            valueKey="Id"
            labelKey="Name"
            options={getDoorsOptions(doors)}
            className="input-door-wms"
            onChange={event => changeItem("PortaWMS", event.Name)}
            data-testid="PortaWMS"
            disabled={isDisableDoor(identificador, editMode, item.IsOthersDisable, doors, item.PortaWMS)}
          />
        </Col>
        <Col xs={4}>
          <SelectBox
            label={intl.get("orders.productionLine")}
            placeholder={intl.get("orders.productionLine")}
            valueKey='key'
            labelKey='productionLine'
            value={item.LinhaDeProducao}
            options={productionLines}
            onChange={(linha) => changeItem("LinhaDeProducao", linha)}
            clearable
            disabled={isDisableProductionLine(identificador, editMode, item.IsOthersDisable)}
          />
        </Col>
        <Col xs={4}>
          <Input
            label={intl.get('orders.comments')}
            name="comments"
            value={item.Comentarios}
            onChange={event => changeItem("Comentarios", event.target.value)}
            maxLength='40'
            data-testid="Comentarios"
            disabled={isDisableComments(identificador, editMode, item.IsOthersDisable)}
          />
        </Col>
      </Row>
      {showStoInformationRow() &&
        <StoInformationRow item={item} changeItem={changeItem} isDisableStoFields={isDisableStoFields(identificador, editMode, item.isOthersDisable)} />
      }
      <Row>
        <Col xs={12}>
          <Textarea
            label={intl.get('stocks.suggestionsListItem.observation')}
            value={item.Observacao || ''}
            onChange={event => changeItem('Observacao', event.target.value)}
            data-testid="Observacao"
            disabled={isDisableNote(item.IdComposicao, editMode, item.IsOthersDisable)}
          />
        </Col>
      </Row>
      <Row end='xs'>
        <Col xs={12} className='table-list-footer'>
          <div
            style={{ marginRight: '8px' }}
            title={getActionLabel2(identificador)}
            data-testid='wrapper-btn-delete-suggestion-item'
          >
            <Button
              icon={IconTrash}
              width='50px'
              value=''
              type='danger'
              onClick={() => editMode ? deleteSuggestion(identificador) : handleClose()}
            />
          </div>
          <div title={getActionLabel(identificador)} data-testid='wrapper-btn-save-suggestion-item'>
            <Button
              icon={IconSaveBlue}
              width='50px'
              value=''
              type='secondary'
              onClick={handleSave}
            />
          </div>
        </Col>
      </Row>
      <Loading isLoading={isLoading} fullHeightParent />
    </S.Wrapper>
  )
}

export default Render


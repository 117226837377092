import React, { useState, useEffect } from 'react'
import Loading from 'components/center-loading'
import { DataUploadItem } from 'components/icons/icn-index.icon'
import intl from 'react-intl-universal';
import HeaderIcon from 'components/header-icon'
import {
  Container,
  HeaderContainer
} from './styled'
import DataUploadList from './list';
import ImportExport from 'components/import-export'
import { downloadFile } from 'utils/custom-functions'
import { downloadData, downloadDataWithFilters, getLastUpdates } from './data-upload.service'
import moment from 'moment'
import { formatErrorMessage } from 'utils/handle-error';
import { FilterDataUpload } from './components/filter-data-upload'
import { isPurchaseOrderFlow } from 'utils/validations-general-settings';
import { validationExports } from './validations';
import { filtersToUploads } from './filters-data';
import { useGlobalContext } from 'hooks/useGlobalContext';

const uploadTypes = {
  Contracts: 1,
  Suppliers: 2,
  Materials: 3,
  ProductionSchedule: 4,
  Bottles: 5,
  Wsnp: 6,
  RealProduction: 7,
  MasterData: 8,
  PlanningBottles: 10,
  WbsFamily: 11,
  PurchaseOrder: 12,
}

const DataUpload = () => {
  const uninformed = intl.get('master-data.data-upload.data.uninformed');
  const [lastUpdateData, setLastUpdateData] = useState([]);
  const { showFeedback } = useGlobalContext();

  const searchData = async () => {
    const dataFetch = await getLastUpdates();
    setLastUpdateData(dataFetch)
  }

  useEffect(() => {
    searchData();
  }, [])

  const searchLastUpdateDate = (type) => {
    try {
      return moment(lastUpdateData.find(x => x.UploadType === type).LastUpdate).format("L LT")
    }
    catch (e) {
      return "---"
    }
  }

  const searchLastUpdateUser = (type) => {
    try {
      return lastUpdateData.find(x => x.UploadType === type).User
    }
    catch (e) {
      return uninformed
    }
  }

  const data = [
    {
      id: 1,
      description: intl.get('master-data.data-upload.data.contracts'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.Contracts),
      userUpdate: searchLastUpdateUser(uploadTypes.Contracts),
      title: intl.get(`exportImportModal.contracts.title`),
      contextModal: 'contracts',
      exportUrl: "ContratoFornecedor/export",
      downloadModelUrl: "ContratoFornecedor/download/contractsModel",
      importUrl: "ContratoFornecedor/import",
      hiddenButtonExport: false
    },
    {
      id: 2,
      description: intl.get('master-data.data-upload.data.suppliers'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.Suppliers),
      userUpdate: searchLastUpdateUser(uploadTypes.Suppliers),
      title: intl.get(`exportImportModal.suppliers.title`),
      contextModal: 'suppliers',
      exportUrl: "fornecedor/export",
      downloadModelUrl: "fornecedor/download/suppliersModel",
      importUrl: "fornecedor/import",
      hiddenButtonExport: false
    },
    {
      id: 3,
      description: intl.get('master-data.data-upload.data.materials'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.Materials),
      userUpdate: searchLastUpdateUser(uploadTypes.Materials),
      title: intl.get(`exportImportModal.materials.title`),
      contextModal: 'materials',
      exportUrl: "material/export",
      downloadModelUrl: "material/download/materialsModel",
      importUrl: "material/import",
      hiddenButtonExport: false
    },
    {
      id: 4,
      description: intl.get('master-data.data-upload.data.productionSchedule'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.ProductionSchedule),
      userUpdate: searchLastUpdateUser(uploadTypes.ProductionSchedule),
      title: intl.get(`exportImportModal.productionSchedule.title`),
      contextModal: 'productionSchedule',
      exportUrl: "productionSchedule/export/productionSchedule",
      downloadModelUrl: "productionSchedule/download/productionScheduleModel",
      importUrl: "productionSchedule/import/productionSchedule",
      hiddenButtonExport: false
    },
    {
      id: 5,
      description: intl.get('exportImportModal.bottles.title'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.Bottles),
      userUpdate: searchLastUpdateUser(uploadTypes.Bottles),
      title: intl.get(`exportImportModal.bottles.title`),
      contextModal: 'bottles',
      exportUrl: "cadenciafornecedor/exportar",
      downloadModelUrl: "cadenciafornecedor/exportarModelo",
      importUrl: "cadenciafornecedor/importar",
      hiddenButtonExport: false
    },
    {
      id: 6,
      description: intl.get('exportImportModal.wsnp.title'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.Wsnp),
      userUpdate: searchLastUpdateUser(uploadTypes.Wsnp),
      title: intl.get(`exportImportModal.wsnp.title`),
      contextModal: 'wsnp',
      downloadModelUrl: "malha/exportarModelo",
      importUrl: "malha/importar",
      hiddenButtonExport: true
    },
    {
      id: 7,
      description: intl.get('exportImportModal.realProduction.title'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.RealProduction),
      userUpdate: searchLastUpdateUser(uploadTypes.RealProduction),
      title: intl.get(`exportImportModal.realProduction.title`),
      contextModal: 'realProduction',
      downloadModelUrl: "envase/exportarModelo",
      importUrl: "envase/importar",
      hiddenButtonExport: true
    },
    {
      id: 8,
      description: intl.get('exportImportModal.masterData.title'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.MasterData),
      userUpdate: searchLastUpdateUser(uploadTypes.MasterData),
      title: intl.get(`exportImportModal.masterData.title`),
      contextModal: 'masterData',
      exportUrl: "masterdata:export",
      downloadModelUrl: "masterdata/exportarModelo",
      importUrl: "masterdata/importar",
      hiddenButtonExport: false,
      hasFilter: true
    },
    {
      id: 10,
      description: intl.get('exportImportModal.planningBottles.title'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.PlanningBottles),
      userUpdate: searchLastUpdateUser(uploadTypes.PlanningBottles),
      title: intl.get('exportImportModal.planningBottles.title'),
      contextModal: 'planningBottles',
      exportUrl: "bottles-plan/export-model",
      downloadModelUrl: "bottles-plan/export-model",
      importUrl: "bottles-plan/import",
      hiddenButtonExport: false,
      hasFilter: true
    },
    {
      id: 11,
      description: intl.get('exportImportModal.WbsFamily.title'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.WbsFamily),
      userUpdate: searchLastUpdateUser(uploadTypes.WbsFamily),
      title: intl.get('exportImportModal.WbsFamily.title'),
      contextModal: 'masterData',
      exportUrl: "masterdata/export-wbsfamily-data",
      downloadModelUrl: "masterdata/export-wbsfamily-model",
      importUrl: "masterdata/process-wbsfamily",
      hiddenButtonExport: false
    },
    {
      id: 12,
      description: intl.get('commons.purchaseOrder'),
      lastUpdateDate: searchLastUpdateDate(uploadTypes.PurchaseOrder),
      userUpdate: searchLastUpdateUser(uploadTypes.PurchaseOrder),
      title: intl.get('commons.purchaseOrder'),
      contextModal: 'purchaseOrder',
      exportUrl: "orders:export-model",
      downloadModelUrl: "orders:export-model",
      importUrl: "orders:import",
      hiddenButtonExport: true,
      hide: !isPurchaseOrderFlow()
    }
  ]

  const [isFetching, setIsFetching] = useState(false);
  const [dataUpload, setDataUpload] = useState({
    isOpen: false,
    data: null
  });

  const openFetching = () => {
    setIsFetching(true);
  }

  const closeFetching = () => {
    setIsFetching(false);
  }

  const openDataUpload = (dataId) => {
    openFetching();

    const upload = data.find(d => d.id === dataId);
    const component = () => <FilterDataUpload
      onClick={(filters) => exportDataWithFilters(filters, { data: upload })}
      {...filtersToUploads[upload.contextModal]}
    />

    setDataUpload({
      isOpen: true,
      data: upload,
      component: upload.hasFilter && component()
    });
    closeFetching();
  }

  const closeUploadData = () => {
    setDataUpload({
      isOpen: false,
      data: null
    });
    searchData();
  }

  const proccessErro = (exception) => {
    closeFetching();
    const messageError = formatErrorMessage(exception);
    showFeedback(messageError)
  }

  const exportDataWithFilters = async (filters, upload) => {
    const validation = validationExports(upload.data.contextModal, filters);
    if (validation) {
      showFeedback(validation);
      return Promise.reject();
    }
    return downloadDataWithFilters(filters, upload.data.exportUrl)
      .then((response) => {
        const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
        downloadFile(`${datafile}_${upload.data.contextModal}.csv`, 'csv', response.data)
      })
      .catch(e => proccessErro(e));
  }

  const exportData = (isModel, upload) => {
    return downloadData(isModel ? upload.data.downloadModelUrl : upload.data.exportUrl)
      .then((response) => {
        const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
        downloadFile(isModel ? `${upload.data.contextModal}_model.csv` : `${datafile}_${upload.data.contextModal}.csv`, 'csv', response.data)
      })
      .catch(e => proccessErro(e));
  }

  return (
    <Container>
      <Loading isLoading={isFetching} />
      <HeaderContainer>
        <HeaderIcon
          title={intl.get('master-data.menus.submenus.data-upload')}
          subtitle={intl.get('master-data.data-upload.subtitle')}
          icon={<DataUploadItem width='40px' height='40px' />}
        />
      </HeaderContainer>
      <DataUploadList
        data={data}
        openDataUpload={openDataUpload}
      />
      {dataUpload.isOpen &&
        <ImportExport
          title={dataUpload.data.title}
          contextModal={dataUpload.data.contextModal}
          open={dataUpload.isOpen}
          handleClose={closeUploadData}
          downloadModelo={() => exportData(true, dataUpload)}
          importArquivo={dataUpload.data.importUrl}
          downloadArquivo={() => exportData(false, dataUpload)}
          type='CSV'
          stepSelected={dataUpload.data.hiddenButtonExport ? 1 : 0}
          hiddenButtonExport={dataUpload.data.hiddenButtonExport}
          content={{
            hasFilter: dataUpload.data.hasFilter,
            component: dataUpload.component
          }}
        />
      }
    </Container>
  )
}

export default DataUpload

import React, { useState, useEffect } from 'react'
import intl from 'react-intl-universal';
import { Dialog } from '@material-ui/core'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Button, Input,  SelectBox } from "components/uikit-adapter/index";
import {
  Container,
  Footer,
  Title,
  ContainerConfiguration,
  LineConfigStyled,
  ContainerConfigPeriod,
  LabelContainer,
  ConfigAutoPilot,
  Agrupador,
  useStyles
} from './auto-pilot-styled'
import { Row, Col } from "react-flexbox-grid";
import { Checkbox } from "@hbsis.uikit/react";
import icnAutoPilot from 'images/icn-auto-pilot-with-back.svg'
import * as services from '../../general-master-data.service'
import Loading from 'components/center-loading'
import OptionGroup from 'components/option-group'
import moment from 'moment'
import {
  isSemanalByDays,
  normalizeDaysOfWeek,
  validaAtivarSelectBoxSemanal,
  validarParaSalvar,
  reverseDataOfWeekDays,
  retornaRecorrenciaCorteValor,
  retornaSemanalParaSelectBox,
  validaValorPorCampo,
  validaValorParaAutoPilot,
  retornaValorOpcaoRecorrencia,
  retornaValorCortePedido,
  retornaNumberDaysAdvanceForSuggestionGeneration
} from "./utils"
import { Switcher } from 'components/toggle';

const AutoPilotPeriod = {
  AllDay: {
    name: 'allDay',
    value: 0
  },
  Scheduled: {
    name: 'scheduled',
    value: 1
  }
}

const daysOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
}

const AutoPilot = ({ isOpen, handleClose, idLabeledFamilyCenter, saveAutoPiloteSchedule, showFeedback }) => {
  const classes = useStyles()
  const [isFetching, setIsFetching] = useState(false)
  const [isAutoPilot, setIsAutoPilot] = useState(false);
  const [automaticAdjustmentDelivery, setAutomaticAdjustmentDelivery] = useState(false);
  const [showConfigAutoPilot, setShowConfigAutoPilot] = useState(false);
  const [daysOfWeekEnable, setDaysWeekEnable] = useState([])
  const [recorrenciaCorteValor, setRecorrenciaCorteValor] = useState({})
  const [cuttingOrdersInOver, setCuttingOrdersInOver] = useState({
    value: 0,
    isChecked: false
  });
  const [numberDaysAdvanceForSuggestionGeneration, setNumberDaysAdvanceForSuggestionGeneration] = useState({
    value: 0,
  });
  const [autoPilotPeriod, setAutoPilotPeriod] = useState([]);
  const [opcoesDaysOfWeekEnable] = useState([
    {
      Id: 0,
      name: intl.get("weekdays.sunday"),
      isSelected: false
    },
    {
      Id: 1,
      name: intl.get("weekdays.monday"),
      isSelected: false
    },
    {
      Id: 2,
      name: intl.get("weekdays.tuesday"),
      isSelected: false
    },
    {
      Id: 3,
      name: intl.get("weekdays.wednesday"),
      isSelected: false
    },
    {
      Id: 4,
      name: intl.get("weekdays.thursday"),
      isSelected: false
    },
    {
      Id: 5,
      name: intl.get("weekdays.friday"),
      isSelected: false
    },
    {
      Id: 6,
      name: intl.get("weekdays.saturday"),
      isSelected: false
    }
  ])
  const [isSemanal, setIsSemanal] = useState(false)
  const [opcoesRecorrenciaCorteOverPedido] = useState([
    {
      Id: 0,
      name: intl.get("weekdays.daily")
    },
    {
      Id: 1,
      name: intl.get("weekdays.weekly")
    }
  ])
  const [optionsRecurrencePattern, setOptionsRecurrencePattern] = useState({
    optionOne: {
      name: intl.get('master-data.general.auto-pilot.general'),
      value: 0,
      isChecked: true
    },
    optionTwo: {
      name: intl.get('master-data.general.auto-pilot.custom'),
      value: 1,
      isChecked: false
    }
  })
  const [daysWeek, setDaysWeek] = useState([
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: daysOfWeek.Sunday,
      Selected: false,
      NameDayOfWeek: 'Sunday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriod.AllDay.value
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: daysOfWeek.Monday,
      Selected: false,
      NameDayOfWeek: 'Monday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriod.AllDay.value
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: daysOfWeek.Tuesday,
      Selected: false,
      NameDayOfWeek: 'Tuesday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriod.AllDay.value
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: daysOfWeek.Wednesday,
      Selected: false,
      NameDayOfWeek: 'Wednesday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriod.AllDay.value
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: daysOfWeek.Thursday,
      Selected: false,
      NameDayOfWeek: 'Thursday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriod.AllDay.value
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: daysOfWeek.Friday,
      Selected: false,
      NameDayOfWeek: 'Friday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriod.AllDay.value
    },
    {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      DayOfWeek: daysOfWeek.Saturday,
      Selected: false,
      NameDayOfWeek: 'Saturday',
      StartTime: "00:00",
      FinishTime: "12:00",
      AutoPilotPeriod: AutoPilotPeriod.AllDay.value
    },
  ])

  useEffect(() => {
    normalizeData();
    normalizeAutoPilotPeriod();
  }, [])

  useEffect(() => {
    if (optionsRecurrencePattern.optionOne.isChecked) {
      let normalize = []
      daysWeek.forEach(x => {
        const value = {
          ...x,
          Selected: false,
          StartTime: "00:00",
          FinishTime: "12:00",
          AutoPilotPeriod: AutoPilotPeriod.AllDay.value
        }
        normalize.push(value)
      });
      setDaysWeek([...normalize]);
    }
  }, [optionsRecurrencePattern])

  const updateRecurrencePattern = (newValue) => {

    setOptionsRecurrencePattern(prevState => ({
      optionOne: {
        ...prevState.optionOne,
        isChecked: prevState.optionOne.value == newValue
      },
      optionTwo: {
        ...prevState.optionTwo,
        isChecked: prevState.optionTwo.value == newValue
      }
    }))
  }

  const updateValue = (index, prop, value) => {
    let oldValues = daysWeek;
    oldValues[index][prop] = value;

    setDaysWeek([...oldValues]);
  }

  const updateValueSelectBoxDiarioSemanal = (valor) => {
    setRecorrenciaCorteValor(valor)
    setIsSemanal(retornaSemanalParaSelectBox(valor.Id))
  }

  const updateValueSelectBoxDiasDaSemana = (valor) => {
    const novoValor = valor.map((v) => {
      return {
        ...v,
        isSelected: true
      }
    })
    setDaysWeekEnable(novoValor)
  }

  const normalizeData = async () => {
    try {
      setIsFetching(true);
      const data = await services.getSchedulingAutoPilot(idLabeledFamilyCenter);
      let normalizeScheduleDays = [];

      data.SchedulingAutoPilots.forEach(x => {
        let startTime = new Date();
        startTime.setHours(x.StartHour)
        startTime.setMinutes(x.StartMinute)

        let finishTime = new Date();
        finishTime.setHours(x.FinishHour);
        finishTime.setMinutes(x.FinishMinute)

        normalizeScheduleDays.push({
          ...x,
          StartTime: moment(startTime).format('HH:mm'),
          FinishTime: moment(finishTime).format('HH:mm')
        })
      });

      const oldData = daysWeek.filter(x => !data.SchedulingAutoPilots.some(d => d.DayOfWeek === x.DayOfWeek))

      normalizeScheduleDays = [
        ...normalizeScheduleDays,
        ...oldData
      ]

      const compareDate = (a, b) => a.DayOfWeek.toString().localeCompare(b.DayOfWeek.toString())

      const newData = [...normalizeScheduleDays].sort((a, b) => compareDate(a, b));

      const semanal = isSemanalByDays(data.DaysOfWeekEnable)
      setDaysWeekEnable(reverseDataOfWeekDays(data.DaysOfWeekEnable, opcoesDaysOfWeekEnable))
      setRecorrenciaCorteValor(opcoesRecorrenciaCorteOverPedido[retornaRecorrenciaCorteValor(semanal)])
      setIsSemanal(semanal)
      setIsAutoPilot(data.AutoPilot)
      setAutomaticAdjustmentDelivery(data.AutomaticDeliveryAdjustment)
     
      const typeRecurrence = data.SchedulingAutoPilots.length > 0 ? 1 : data.TypeOfAutoPilotScheduling
     
      updateRecurrencePattern(typeRecurrence)
      setDaysWeek(newData)
      setCuttingOrdersInOver({ isChecked: data.OverStockManagerIsActive, value: data.OverStockManagerDaysToSkip })
      setNumberDaysAdvanceForSuggestionGeneration({ value: data.NumberDaysAdvanceForSuggestionGeneration })
    }
    catch (err) {
      console.error(err)
    }
    finally {
      setIsFetching(false)
    }
  }

  const normalizeAutoPilotPeriod = () => {
    let normalize = [];
    Object.keys(AutoPilotPeriod).forEach((x, i) => {
      normalize.push({
        Name: intl.get(`master-data.general.auto-pilot.autoPilotPeriod.${x}`),
        Id: i
      })
    });
    setAutoPilotPeriod(normalize)
  }

  const updateOverInventoryManagement = (field, value) => {
    const valor = validaValorPorCampo(field, value)

    setCuttingOrdersInOver(prevState => ({
      ...prevState,
      [field]: valor
    }));
  }

  const updateNumberDaysAdvanceForSuggestionGeneration = (field, value) => {
    const valor = validaValorPorCampo(field, value)

    setNumberDaysAdvanceForSuggestionGeneration(prevState => ({
      ...prevState,
      [field]: valor
    }));
  }

  const openConfigAutoPilot = () => {
    setShowConfigAutoPilot(true)
  }

  const closeConfigAutoPilot = () => {
    setShowConfigAutoPilot(false)
  }

  const updateAutoPilot = (value) => {
    setCuttingOrdersInOver(prevState => ({
      ...prevState,
      isChecked: validaValorParaAutoPilot(value, prevState)
    }));
    setIsAutoPilot(value)
  }

  const LineConfigurations = (day, index) =>
  (<LineConfigStyled>
    <Row style={{ paddingRight: '15px' }}>
      <Col xs={3} style={{
        alignSelf: 'center',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
        <Checkbox
          text={intl.get(`weekdays.${day.NameDayOfWeek.toLowerCase()}`)}
          name={`general-${day.NameDayOfWeek}`}
          data-testid={`general-${day.NameDayOfWeek}`}
          checked={daysWeek[index].Selected || optionsRecurrencePattern.optionOne.isChecked}
          onChange={() => updateValue(index, "Selected", !daysWeek[index].Selected)}
          disabled={!(optionsRecurrencePattern.optionTwo.isChecked && isAutoPilot)}
        />
      </Col>
      <Col xs={3}>
        <SelectBox
          style={{ height: '30px' }}
          name={`select-${day.NameDayOfWeek}`}
          placeholder=""
          valueKey='Id'
          labelKey='Name'
          value={day.AutoPilotPeriod}
          options={autoPilotPeriod}
          onChange={value => updateValue(index, "AutoPilotPeriod", value.Id)}
          disabled={!(daysWeek[index].Selected && optionsRecurrencePattern.optionTwo.isChecked && isAutoPilot)}
        />
      </Col>
      <Col xs={3}>
        <Input
          style={{ textAlign: 'center', height: '30px' }}
          value={day.StartTime}
          htmlType="time"
          onChange={(e) => updateValue(index, "StartTime", e.target.value)}
          data-testid={`input-startTime-${day.NameDayOfWeek}`}
          disabled={!(day.AutoPilotPeriod && daysWeek[index].Selected && optionsRecurrencePattern.optionTwo.isChecked && isAutoPilot)}
        />
      </Col>
      <Col xs={3}>
        <Input
          style={{ textAlign: 'center', height: '30px' }}
          value={day.FinishTime}
          htmlType="time"
          onChange={(e) => updateValue(index, "FinishTime", e.target.value)}
          data-testid={`input-finishTime-${day.NameDayOfWeek}`}
          disabled={!(day.AutoPilotPeriod && daysWeek[index].Selected && optionsRecurrencePattern.optionTwo.isChecked && isAutoPilot)}
        />
      </Col>
    </Row>
  </LineConfigStyled>
  )


  const save = () => {
    setIsFetching(true);
    let normalizeScheduleDays = [];

    daysWeek.forEach(x => {
      const startHour = x.StartTime.split(":");
      const finishHour = x.FinishTime.split(":");

      normalizeScheduleDays.push({
        ...x,
        StartHour: startHour[0],
        StartMinute: startHour[1],
        FinishHour: finishHour[0],
        FinishMinute: finishHour[1],
        IdLabeledFamilyCenter: idLabeledFamilyCenter
      })
    });

    const DaysOfWeekEnable = normalizeDaysOfWeek(isSemanal, daysOfWeekEnable)

    const data = {
      IdLabeledFamilyCenter: idLabeledFamilyCenter,
      AutoPilot: isAutoPilot,
      TypeOfAutoPilotScheduling: retornaValorOpcaoRecorrencia(optionsRecurrencePattern.optionTwo.isChecked),
      AutomaticDeliveryAdjustment: automaticAdjustmentDelivery,
      SchedulingAutoPilots: normalizeScheduleDays,
      DaysOfWeekEnable,
      OverStockManagerIsActive: cuttingOrdersInOver.isChecked,
      OverStockManagerDaysToSkip: retornaValorCortePedido(cuttingOrdersInOver),
      NumberDaysAdvanceForSuggestionGeneration: retornaNumberDaysAdvanceForSuggestionGeneration(numberDaysAdvanceForSuggestionGeneration.value)
    }

    const { deveSalvar, mensagemErro } = validarParaSalvar(normalizeScheduleDays, DaysOfWeekEnable)

    if (deveSalvar) {
      saveAutoPiloteSchedule(data)
      setIsFetching(false)
    } else {
      setIsFetching(false)
      showFeedback(intl.get(mensagemErro))
    }
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="lg"
      classes={{
        paper: classes.paper
      }}
    >
      <Loading isLoading={isFetching} fullHeightParent />
      <DialogTitle>
        <Title>
          <img src={icnAutoPilot} width="55px" />
          <h1>{intl.get('master-data.general.auto-pilot.title')}</h1>
        </Title >
      </DialogTitle>
      <DialogContent>
          <Container>
            <ContainerConfiguration>
              <LabelContainer selected={!showConfigAutoPilot}>
                <label
                  data-testid='label-close-autopilot'
                  onClick={closeConfigAutoPilot}>
                  {intl.get('master-data.general.auto-pilot.automaticRoutines')}
                </label>
              </LabelContainer>
              <LabelContainer selected={showConfigAutoPilot}>
                <label
                  data-testid='label-open-autopilot'
                  onClick={openConfigAutoPilot}>
                  {intl.get('master-data.general.auto-pilot.recurrencePattern')}
                </label>
              </LabelContainer>
            </ContainerConfiguration>
            {showConfigAutoPilot ?
              <React.Fragment>
                <ContainerConfigPeriod>
                  <OptionGroup
                    handleSelect={event => updateRecurrencePattern(event.target.value)}
                    options={optionsRecurrencePattern}
                    dataTestId='options-group'
                    disabled={!isAutoPilot}
                  />
                  <Row style={{ paddingBottom: 10, textAlign: 'center', paddingRight: '15px' }}>
                    <Col xs={3} style={{ paddingRight: '0px' }}>
                      {intl.get('master-data.general.auto-pilot.period.daysWeek')}
                    </Col>
                    <Col xs={3}>
                      {intl.get('master-data.general.auto-pilot.period.period')}
                    </Col>
                    <Col xs={3}>
                      {intl.get('master-data.general.auto-pilot.period.start')}
                    </Col>
                    <Col xs={3}>
                      {intl.get('master-data.general.auto-pilot.period.end')}
                    </Col>
                  </Row>
                  {daysWeek.map((value, index) => (
                    LineConfigurations(value, index)
                  ))}
                </ContainerConfigPeriod>
              </React.Fragment> :
              <ConfigAutoPilot>
                <Agrupador> {intl.get('master-data.general.auto-pilot.statusAutomaticPilot')} </Agrupador>
                <Row style={{ marginTop: ".8rem" }}>
                  <Col xs={10} style={{ paddingRight: '90px' }}>
                    <label >{intl.get('master-data.general.auto-pilot.enableAutomaticPilot')}</label>
                  </Col>
                  <Col xs={2} >
                    <Switcher
                      style={{ right: '20px' }}
                      name="auto-pilot"
                      value={isAutoPilot}
                      onChange={value => updateAutoPilot(value.checked)}
                      data-testid="toggle-auto-pilot"
                    />
                  </Col>
                </Row>
                <Agrupador>
                  {intl.get("master-data.general.auto-pilot.orderCutConfiguration")}
                </Agrupador>
                <Row style={{ marginTop: '20px' }}>
                  <Col xs={10}>
                    <label>{intl.get('master-data.general.auto-pilot.overInventoryManagement')}</label>
                  </Col>
                  <Col xs={2} >
                    <Switcher
                      style={{ right: '20px' }}
                      name="over-inventory-management"
                      value={cuttingOrdersInOver.isChecked}
                      onChange={value => updateOverInventoryManagement("isChecked", value.checked)}
                      disabled={!isAutoPilot}
                      data-testid='toogle-over-inventory-management'
                    />
                  </Col>
                </Row>
                <React.Fragment>
                  <Row style={{ marginTop: ".8rem" }}>
                    <Col xs={10} style={{ alignSelf: 'center' }}>
                      <label>{intl.get('master-data.general.auto-pilot.orderCutOver')}</label>
                    </Col>
                    <Col xs={2} style={{ paddingLeft: '0px' }}>
                      <Input
                        maxLength="2"
                        style={{ width: '45px', textAlign: 'center' }}
                        disabled={!cuttingOrdersInOver.isChecked}
                        value={cuttingOrdersInOver.value}
                        onChange={e => updateOverInventoryManagement("value", e.target.value)}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: ".8rem" }}>
                    <Col xs={6} style={{ alignSelf: 'center' }}>
                      <label>{intl.get('master-data.general.auto-pilot.recurrenceOrderCut')}</label>
                    </Col>
                    <Col xs={6} style={{ paddingLeft: '0px' }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: '.8rem' }}>
                          <SelectBox
                            style={{ width: "220px", height: '30px' }}
                            placeholder=""
                            valueKey='Id'
                            labelKey='name'
                            value={recorrenciaCorteValor}
                            options={opcoesRecorrenciaCorteOverPedido}
                            onChange={value => updateValueSelectBoxDiarioSemanal(value)}
                            disabled={!cuttingOrdersInOver.isChecked}
                            data-testid="selectBoxDiario"
                          />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          <SelectBox
                            style={{ width: "220px", height: '30px' }}
                            placeholder="Selecione os dias"
                            valueKey='Id'
                            labelKey='name'
                            value={daysOfWeekEnable}
                            options={opcoesDaysOfWeekEnable}
                            multi
                            clearable
                            disabled={validaAtivarSelectBoxSemanal(isSemanal, cuttingOrdersInOver.isChecked)}
                            onChange={updateValueSelectBoxDiasDaSemana}
                            data-testid="selectBoxSemanal"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Agrupador style={{ marginTop: ".8rem" }}>
                    {intl.get('master-data.general.auto-pilot.arDateAdjustment')}
                  </Agrupador>
                  <Row>
                    <Col xs={10} style={{ paddingRight: '90px' }}>
                      <label>{intl.get('master-data.general.auto-pilot.automaticDeliveryAdjustment')}</label>
                    </Col>
                    <Col xs={2} >
                      <Switcher
                        style={{ right: '20px' }}
                        name="automatic-delivery-adjustment "
                        value={automaticAdjustmentDelivery}
                        onChange={value => setAutomaticAdjustmentDelivery(value.checked)}
                        thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                        trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                        data-testid='automatic-delivery-adjustment'
                      />
                    </Col>
                  </Row>
                  <Agrupador style={{ marginTop: ".8rem" }}>
                    {intl.get('master-data.general.auto-pilot.suggestionGeneration')}
                  </Agrupador>
                  <Row style={{ marginTop: ".8rem" }}>
                    <Col xs={10} style={{ alignSelf: 'center' }}>
                      <label>{intl.get('master-data.general.auto-pilot.suggestionsFromD-')}</label>
                    </Col>
                    <Col xs={2} style={{ paddingLeft: '0px' }}>
                      <Input
                        maxLength="2"
                        style={{ width: '45px', textAlign: 'center' }}
                        value={numberDaysAdvanceForSuggestionGeneration.value}
                        onChange={e => updateNumberDaysAdvanceForSuggestionGeneration("value", e.target.value)}
                        data-testid='number-days-advance-for-suggestion-generation'
                      />
                    </Col>
                  </Row>
                </React.Fragment>
              </ConfigAutoPilot>
            }
          </Container>
      </DialogContent>
      <DialogActions>
        <Footer data-testid="footer-buttons">
          <Button
            value={intl.get("geral.buttonsDefault.close")}
            onClick={handleClose}
            type="default"
          />
          <Button
            value={intl.get("geral.buttonsDefault.save")}
            onClick={save}
            type="primary"
          />
        </Footer>
      </DialogActions>
    </Dialog>
  )
}

export default AutoPilot;

import styled from 'styled-components'

const LinkCode = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #2870B2;
  text-decoration: underline;
  padding: 0px 4px;
`
export default LinkCode;

import React, { useEffect, useState } from 'react'
import Filters from 'components/filter';
import { dateRangeFiltersInitial, filtersStocksDefault, selectsFiltersInitial, statusStockOptions } from './utils';
import { isUseFilterDefault } from 'utils/user';
import { searchBusinessUnit, searchCoordinations, searchFamily, searchLabeledFamily, searchMaterialGroup, searchMaterials, searchSuppliers } from 'services/filters.service';
import { getStatus } from 'pages/stocks/stocks.service';

const FilterStock = ({ searchStoks }) => {
  const useFilterDefault = isUseFilterDefault();

  const initialFilters = () => {
    const filtersStocksStorage = JSON.parse(localStorage.getItem("filtersStocksMulti"));
    return filtersStocksStorage ? filtersStocksStorage : filtersStocksDefault;
  }

  const [selectsFilters, setSelectsFilters] = useState(selectsFiltersInitial());

  const handleClickSearch = (_filters) => {
    searchStoks(_filters)
  }

  const proccessData = (filtersData) => {
    const _filters = selectsFilters.map(filter => ({
      ...filter,
      options: filtersData.find(x => x.name === filter.name).data,
    }));


    setSelectsFilters([..._filters]);
  }

  const loadOptions = async () => {
    const [source, destination, coordination, wallet, family, labeledFamily, activeMaterial] =
      await Promise.all([
        searchSuppliers(useFilterDefault),
        searchBusinessUnit(useFilterDefault),
        searchCoordinations(useFilterDefault),
        searchMaterialGroup(useFilterDefault),
        searchFamily(useFilterDefault),
        searchLabeledFamily(useFilterDefault),
        searchMaterials(useFilterDefault),
        getStatus()
      ]);

    const filtersData = [
      { name: 'SupplierIds', data: source },
      { name: 'BusinessUnitIds', data: destination },
      { name: 'CoordinationIds', data: coordination },
      { name: 'MaterialGroupIds', data: wallet },
      { name: 'FamilyIds', data: family },
      { name: 'LabeledFamilyIds', data: labeledFamily },
      { name: 'MaterialNumbers', data: activeMaterial },
      { name: 'StockStatusIds', data: statusStockOptions }
    ];

    proccessData(filtersData);
  }

  useEffect(() => {
    loadOptions();
  }, [])

  return (
    <div>
      <Filters
        handleClear={() => searchStoks(filtersStocksDefault)}
        handleSearch={handleClickSearch}
        selectsFilters={selectsFilters}
        filtersDefault={initialFilters()}
        dateRangeFilters={dateRangeFiltersInitial()}
      />
    </div>
  )
}

export default FilterStock;

import React from 'react'
import intl from 'react-intl-universal'
import moment from 'moment'
import { formatNumber } from 'utils/format'

const verifyUpdated = (previous, current) => previous !== current

const AceiteAtualizado = ({ data }) => (
  <div style={{ marginLeft: '40px', marginBottom: '10px' }}>
    {
      verifyUpdated(data.QuantidadeAnterior, data.Quantidade) &&
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.previousQty')}</span>
          <span>{formatNumber(data.QuantidadeAnterior)}</span>
        </div>
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.currentQty')}</span>
          <span>{formatNumber(data.Quantidade)}</span>
        </div>
      </div>
    }
    {
      verifyUpdated(data.DataColetaAnterior, data.DataColetaPrevista) &&
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.collectDatePreviou')}</span>
          <span>{moment(data.DataColetaAnterior).format('L')}</span>
        </div>
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.collectDate')}</span>
          <span>{moment(data.DataColetaPrevista).format('L')}</span>
        </div>
      </div>
    }
    {
      verifyUpdated(data.DataEntregaAnterior, data.DataEntregaPrevista) &&
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.deliveryDatePreviou')}</span>
          <span>{moment(data.DataEntregaAnterior).format('L')}</span>
        </div>
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.deliveryDate')}</span>
          <span>{moment(data.DataEntregaPrevista).format('L')}</span>
        </div>
      </div>
    }
    {
      verifyUpdated(data.MaterialDescricaoAnterior, data.MaterialDescricao) &&
      <React.Fragment>
        <div className="negotiation-read-offer">
          <div className="negotiation-read-column">
            <span>{intl.get('stocks.timeline.previousMaterial')}</span>
            <span>{data.MaterialDescricaoAnterior}</span>
          </div>
        </div>
        <div className="negotiation-read-offer">
          <div className="negotiation-read-column">
            <span>{intl.get('stocks.timeline.material')}</span>
            <span>{data.MaterialDescricao}</span>
          </div>
        </div>
      </React.Fragment>
    }
  </div>
)

export default AceiteAtualizado

import styled from 'styled-components'
import { Button as ButtonMUI } from 'components/uikit-adapter/index'

export const Wrapper = styled.div`
  width: 100%;
  height: ${props => props.haveData ? '50px' : '80px'};
  padding-top: ${props => props.haveData ? '0px' : '15px'};
`
export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 15px;

  > * {
    margin: 0px 5px 0px 12px;
  }

  #row-deadline-control {
    position: relative;
    top: -5px;
    min-width: 150px;
    max-width: 400px;

    .columnsSize {
      flex-basis: calc(50% - 30px) !important;
      max-width: calc(50% - 30px) !important;
    }
  }

  .Input-label {
    margin-top: 7px;
  }
`
export const Button = styled(ButtonMUI)`
  margin-top: 15px;
  align-self: center;
  width: 180px;
  max-width: 180px;

  @media (max-width: 1200px) {
    position: absolute;
    top: 180px;
    width: 140px;
  }
`

export const ButtonBack = styled(ButtonMUI)`
  margin-top: 15px;
  align-self: center;
  width: 200px;
  margin: 0px 30px 0px auto;
  background-color: #E7F0F7;
`

import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  background: #FFFFFF;
  border: 1px solid #D1D4D7;
  box-sizing: border-box;
  color: #D1D4D7;
  border-radius: 3px;
  width: 142px;
  margin: 4px 11px;
`
export const Content = styled.div`
  margin-left: 4px;
  span{
    cursor: auto;
    font-size: 12px;
    line-height: 12px;
    display: flex;
  }
`

export const Hora = styled.div`
  border-right: solid 1px;
  span{
    font-weight: 600;
    margin-right: 4px;
    cursor: auto;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #D1D4D7;
  }
`

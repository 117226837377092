import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from "react-flexbox-grid"
import { Button } from '@hbsis.uikit/react'
import Dialog from 'material-ui/Dialog'

import StyledModalErroShipment from './styledModalErroShipment'

class ModalErroShipment extends Component {
  constructor() {
    super()

    this.state = {

    }
  }

  render() {
    const {
      open,
      errors,
      handleClose
    } = this.props

    return (
      <div>
        <Dialog
          title={'Informações'}
          open={open}
          autoScrollBodyContent
          contentStyle={{ maxWidth: "65%" }}
        >
          <StyledModalErroShipment>
            <Row className="rowListHeader">
              <Col xs={10} md={10} lg={10} className="align-center">Erro</Col>
            </Row>
            <Row className="containerList">
              <Col xs={12} md={12} lg={12}>
                {(errors && !!errors.length && errors.map((e, i) => (
                    <Row key={i}  className="rowList">
                      <Col xs={12} md={12} lg={12} className="align-center">{e.Descricao}</Col> 
                    </Row>
                  )))
                }
              </Col>
            </Row>

            <div className='footer-dialog-buttons'>
              <div className='line-buttons'>
                <Button
                  type="default"
                  value='Fechar'
                  onClick={() => handleClose(false)}
                  className="button"
                />
              </div>
            </div>
            
          </StyledModalErroShipment>

        </Dialog>
      </div >
    )
  }
}

ModalErroShipment.propTypes = {
  open: PropTypes.bool.isRequired,
}

export default ModalErroShipment
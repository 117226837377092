import Styled from 'styled-components'

export const Title = Styled.h3`
    font-size: 18px;
    color: #2870b2;
    margin: 10px 0px 10px 10px;
`

export const Description = Styled.h5`
    font-size: 14px;
    margin: 25px 3px 10px 10px;
    font-weight: 600;
`

export const IconTitle = Styled.div`
    margin: 10px 0px 10px 5px;
    display: flex;
`
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ShopSuggestionCard from './shop-suggestion'
import ConfiguracoesIntegracoesCard from './configuracoes-integracoes'
import VisaoTarefasCard from './visao-tarefas'

import './card.css'

class Card extends Component {
  render() {
    return (
      <div>
        {this.props.typeCard === 'SHOP_SUGGESTION' &&
          <ShopSuggestionCard
            userCard={this.props.userCard}
            infoCard={this.props.infoCard}
            dataCard={this.props.dataCard}
            refreshCardDetail={this.props.refreshCardDetail}
            filters={this.props.filters}
        />
        }

        {this.props.typeCard === 'CARD_TASKS' &&
          <VisaoTarefasCard
            dataCard={this.props.dataCard}
            refreshCard={this.props.refreshCard}
          />
        }
        {this.props.typeCard === 'CONFIGURACAO_INTEGRACAO' &&
          <ConfiguracoesIntegracoesCard
            integracao={this.props.dataCard}
            carteiras={this.props.carteiras}
            familiasRotuladas={this.props.familiasRotuladas}
            proccessFeedback={this.props.proccessFeedback}
            obterIntegracoes={this.props.obterIntegracoes}
          />
        }
      </div>
    )
  }
}

Card.propTypes = {
  typeCard: PropTypes.string.isRequired,
  userCard: PropTypes.string,
  infoCard: PropTypes.string,
  refreshCardDetail: PropTypes.func,
}

export default Card

import React, { useEffect, useState } from 'react'
import { Row, Col } from "react-flexbox-grid";
import { Button, Input, Textarea } from "@hbsis.uikit/react";
import { SelectBox } from 'components/uikit-adapter/index'

import intl from 'react-intl-universal';
import { fetchMaterialWithProductionActiveByLabeledFamilyCenter } from '../../../services/material.service';
import { formatErrorMessage } from 'utils/handle-error';
import { aceiteAtualizado } from '../../cards-obsolete/orders-vision.service';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { validatedDto } from './validate';
import { formatNumber } from 'utils/format';
import InputNumber from 'components/input-number';
import { parseFloatByLocale } from 'locales/utils';

const AceiteVolumeParcialFornecedor = ({
  idFamiliaRotuladaCentro,
  data,
  idPedido,
  refreshTimeLine,
  handleCancel,
  startLoading,
  stopLoading
}) => {
  const { showFeedback } = useGlobalContext()

  const [deliveryTime, setDeliveryTime] = useState("")
  const [note, setNote] = useState("")
  const [collectionDate, setCollectionDate] = useState(data.DataColetaPrevista)
  const [deliveryDate, setDeliveryDate] = useState(data.DataEntregaPrevista)
  const [quantity, setQuantity] = useState(formatNumber(data.Quantidade))
  const [materials, setMaterials] = useState([])
  const [material, setMaterial] = useState(data.IdMaterial)

  const getMaterials = async () => {
    try {
      const _materials = await fetchMaterialWithProductionActiveByLabeledFamilyCenter(idFamiliaRotuladaCentro)
      setMaterials([..._materials])
    }
    catch (e) {
      showFeedback(formatErrorMessage(e))
    }
  }

  const handleChangeMaterial = (value) => setMaterial(value ? value.Id : null);

  const handleSave = () => {
    const dto = {
      Id: idPedido,
      VolumeAceito: parseFloatByLocale(quantity),
      Observacao: note,
      DataColetaProposta: collectionDate,
      DataEntregaProposta: deliveryDate,
      HoraEntragaProposta: deliveryTime,
      IdMaterial: material
    }
    const validate = validatedDto(dto);
    if (validate.isValid) {
      save(dto)
    }
    else {
      showFeedback(validate.message)
    }
  }

  const save = async (dto) => {
    startLoading()
    try {
      await aceiteAtualizado(dto)
      showFeedback(intl.get('stocks.timeline.feedback.acceptPartialVolumeSent'))
      refreshTimeLine()
    }
    catch (e) {
      showFeedback(formatErrorMessage(e))
    }
    stopLoading()
  }

  useEffect(() => {
    getMaterials();
  }, [])

  return (
    <div className="negotiation">
      <div className="negotiation-line">
        <Row>
          <Col xs={6}>
            <Input
              label={intl.get('commons.colDate')}
              value={collectionDate}
              onChange={(e) => setCollectionDate(e.target.value)}
              htmlType='date'
              data-testid='input-collection-date'
            />
          </Col>

          <Col xs={6} >
            <Input
              label={intl.get('commons.deliveryDate')}
              value={deliveryDate}
              onChange={(e) => setDeliveryDate(e.target.value)}
              htmlType='date'
              data-testid='input-delivery-date'
            />
          </Col>

          <Col xs={6}>
            <InputNumber
              type='default'
              name="quantidade"
              label={intl.get('commons.amount')}
              value={quantity}
              onChange={_value => setQuantity(_value)}
              data-testid='input-quantity'
            />
          </Col>

          <Col xs={6}>
            <Input
              label={intl.get('commons.deliveryTime')}
              name='HoraEntrega'
              htmlType='time'
              value={deliveryTime}
              onChange={e => setDeliveryTime(e.target.value)}
              className='txtHoraEntrega'
              data-testid='input-hour'
            />
          </Col>
        </Row>
      </div>
      <div className="negotiation-line">
        <Row>
          <Col xs={12}>
            <div>
              <SelectBox
                name="material"
                label={intl.get('commons.material')}
                placeholder={intl.get('commons.material')}
                valueKey="Id"
                labelKey="DescricaoCompleta"
                value={material}
                options={materials}
                onChange={(value) => handleChangeMaterial(value)}
                searchable
                openOnFocus
                style={{ width: '400px' }}
                clearable
                data-testid='selectbox-material'
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="negotiation-line">
        <Row>
          <Col xs={12}>
            <Textarea
              name="observacao"
              label={intl.get('manual-suggestion-order-form.observation')}
              value={note}
              onChange={e => setNote(e.target.value)}
              style={{ width: '400px' }}
              data-testid='input-note'
            />
          </Col>
        </Row>
      </div>

      <div className="negotiation-line">
        <div className="event-action-offer">
          <Row>
            <Col xs={6}>
              <Button
                value={intl.get('geral.buttonsDefault.close')}
                onClick={handleCancel}
                type="default"
                className="button"
              />
            </Col>

            <Col xs={6}>
              <Button
                value={intl.get('commons.confirmChanges')}
                onClick={handleSave}
                type="primary"
                className="button"
              />
            </Col>
          </Row>

        </div>
      </div>
    </div>
  )
}

export default AceiteVolumeParcialFornecedor;

import React from 'react'
import PropTypes from 'prop-types'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

import './message.css'

const Message = ({ show, title, handleClose, text, labelClose = 'Ok' }) => (
  <Dialog
    open={show}
    title={title}
    actions={<FlatButton label={labelClose} onClick={handleClose} />}
    contentStyle={{width: '30%', whiteSpace: 'pre-wrap'}}>
    {text}
  </Dialog>
)

Message.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  labelClose: PropTypes.string
}

export default Message

import Fetch from "utils/fetch";
import { convertToQueryParameters } from "../../../utils/custom-functions"

export const searchStatus = (idUsuario) => {
  return Fetch.get(`/status/${idUsuario}`).then(({ data }) => data)
}

export const searchUnidades = () => {
  return Fetch.get(`/unidadeNegocio/businessUnitsForFilters/`).then(({ data }) => data)
}

export const searchFornecedores = (useFilterDefault, idUsuario) => {
  return Fetch.get(`/fornecedor/obterTodosFornecedoresComPedidos/${useFilterDefault}/${idUsuario}`).then(({ data }) => data)
}

export const searchMateriais = (model) => {
  return Fetch.get(`/materials/for-filters?${convertToQueryParameters(model)}`).then(({ data }) => data)
}

export const searchWallet = (useFilterDefault, idUsuario) => {
  return Fetch.get(`/carteira/todasPorUsuario/${useFilterDefault}/${idUsuario}`).then(({ data }) => data)
}

export const searchOrdersStatus = () => {
  return Fetch.get('/orders/status').then(({ data }) => data)
}

export const searchOrdersVolumeStatus = () => {
  return Fetch.get('/volume-orders/status').then(({ data }) => data)
}

import React from "react"
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useZsagContext } from "../context/zsagContext"
import intl from 'react-intl-universal'
import moment from "moment"
import { Input, SelectBox } from "components/uikit-adapter/index"
import { EditIcon, SaveIcn } from "components/icons/icn-index.icon"
import { autoGenerateSchedulesUpdate } from "../auto-load-optimization.service"
import { errorMessage, validateNewLine, validTime, validDate } from '../utils/validate'
import { shiftValues } from '../utils/models'
import './styled.css'

const EditLine = ({ idx, row }) => {
  const { showFeedback } = useGlobalContext()
  const {
    volumes,
    setVolumes,
    setHaveUnsavedChanges
  } = useZsagContext()

  const processError = (message) => {
    showFeedback(errorMessage(message))
  }

  const editLine = (index, prevValues) => {
    const _volumes = volumes;
    const _prevValues = { ...prevValues }

    _volumes.Schedules[index].QuantityPallets = () => <Input
      id={`QuantityPallets-${index}`}
      defaultValue={_prevValues.QuantityPallets}
      style={{ height: '30px' }}
    />

    _volumes.Schedules[index].Shift = () =>
      <div className="autoLoad__selectbox--wrapper">
        <SelectBox
          id={`edit-shift-${index}`}
          className="shift__selectbox"
          value={parseInt(_prevValues.Shift)}
          options={shiftValues}
          onChange={(e) => changeShift(e, index)}
          valueKey="value"
          labelKey="value"
          searchable
          data-testid='shift__selectbox'
        />
      </div>

    _volumes.Schedules[index].DeliveryDate = () =>
      <Input
        id={`DeliveryDate-${index}`}
        className="drp-input-date drp-nomargin data-de-picker"
        value={validDate(_prevValues.DeliveryDate)}
        htmlType="date"
        minDate={moment().format('YYYY-MM-DD')}
        maxDate="9999-12-31"
        onChange={(e) => changeDeliveryDate(e.target.value, index, _prevValues.DeliveryDate, _volumes)}
        style={{ height: '30px', width: '110px' }}
      />

    _volumes.Schedules[index].Interval = () =>
      <Input
        id={`Interval-${index}`}
        value={_prevValues.Interval}
        mask="time"
        onChange={(e) => changeInterval(e.target.value, index, _volumes)}
        style={{ height: '30px' }}
      />

    _volumes.Schedules[index].edit = () => <span
      style={{ cursor: 'pointer' }}
      onClick={() => saveEditLine(index, _volumes, _prevValues)} data-testid={`save-edit-line-${index}`}>
      <SaveIcn key={index} width="15px" height="15px" />
    </span>

    setVolumes({ ..._volumes });
  }

  const changeShift = (value, index) => {
    const _volumes = volumes;
    _volumes.Schedules[index].ShiftValue = value
    _volumes.Schedules[index].Shift = () =>
      <div className="autoLoad__selectbox--wrapper">
        <SelectBox
          id={`edit-shift-${index}`}
          className="shift__selectbox"
          value={value}
          options={shiftValues}
          onChange={(e) => changeShift(e, index)}
          valueKey="value"
          labelKey="value"
          searchable
          data-testid='shift__selectbox'
        />
      </div>
    setVolumes({ ..._volumes })
  }

  const changeDeliveryDate = (value, index, oldDeliveryDate, volume) => {
    const _volumes = volume;
    _volumes.Schedules[index].OldDeliveryDate = oldDeliveryDate
    _volumes.Schedules[index].DeliveryDate = () =>
      <Input
        id={`DeliveryDate-${index}`}
        className="drp-input-date drp-nomargin data-de-picker"
        value={value}
        htmlType="date"
        minDate={moment().format('YYYY-MM-DD')}
        maxDate="9999-12-31"
        onChange={(e) => changeDeliveryDate(e.target.value, index, value, _volumes)}
        style={{ height: '30px' }}
      />
    setVolumes({ ..._volumes })
  }

  const changeInterval = (value, index, volume) => {
    const _volumes = volume;
    _volumes.Schedules[index].Interval = () =>
      <Input
        id={`Interval-${index}`}
        value={value}
        mask="time"
        onChange={(e) => changeInterval(e.target.value, index, _volumes)}
        style={{ height: '30px' }}
      />
    setVolumes({ ..._volumes })
  }

  const saveEditLine = async (index, prevValues, values) => {
    const prevValue = prevValues.Schedules[index];
    const line = {
      ...prevValue,
      Material: prevValue.MaterialNumber,
      OldDeliveryDate: prevValue.OldDeliveryDate,
      DeliveryDate: document.getElementById(`DeliveryDate-${index}`).value,
      Shift: values.Shift,
      Interval: validTime(document.getElementById(`Interval-${index}`).value),
      QuantityPallets: document.getElementById(`QuantityPallets-${index}`).value,
      Process: prevValue.Process ? prevValue.Process : "U",
      edit: null
    }
    if (!validateNewLine(line, processError)) {
      const editedLine = autoGenerateSchedulesUpdate(line);
      setHaveUnsavedChanges(true)
      processEditedData(editedLine);
    }
  }

  const getShiftValue = (_volumes, line) => _volumes.Schedules[line.Id].ShiftValue ?
    _volumes.Schedules[line.Id].ShiftValue.value : line.Shift

  const processEditedData = (line) => {
    const _volumes = volumes;
    line.Shift = getShiftValue(_volumes, line)
    _volumes.Schedules[line.Id] = line

    setVolumes({ ..._volumes })
    showFeedback(intl.get("feedbacks.savedRegister"))
  }

  return (
    <span
      style={{ cursor: 'pointer' }}
      onClick={() => editLine(idx, row)}
      data-testid={`edit-line-${idx}`}>
      <EditIcon key={idx} width="15px" height="15px" />
    </span>
  )
}

export default EditLine

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from 'material-ui/Dialog'
import { Button } from '@hbsis.uikit/react'
import MaterialSupplierFormContent from './components/material-supplier-form-content'
import StyledMaterialSupplierForm from './material-supplier-form.styled'
import intl from 'react-intl-universal';
import { supplierOrigin, supplierValues } from './supplier-utils';
import { parseFloatByLocale } from 'locales/utils'
import { formatOnlyNumber } from 'utils/format'

const RESPONSAVEL_ENTREGA_CIF = 1
const RESPONSAVEL_ENTREGA_FOB = 2

class MaterialSupplierForm extends Component {
    constructor(props) {
        super()
        this.state = {
            origemMaterial: supplierValues.COMPRA_DE_INSUMOS,
            idLabeledFamilyCenter: props.idLabeledFamilyCenter,
            materialSupplier: {
                IdFornecedor: 0,
                Fornecedor: {},
                Nome: '',
                ResponsavelEntrega: RESPONSAVEL_ENTREGA_CIF,
                QuebraEntrega: 0,
                Arredondamento: 0,
                LoteMinimo: 0,
                SLA: '0',
                TransitTime: '0',
                Leadtime: '0',
                Tipo: 0,
                OrganizacaoCompras: '',
                GrupoCompradores: '',
                CodigoImposto: '',
                CriarComposicaoDeCargaAutomatica: false,
            }
        }
    }

    componentDidMount() {
        const editMode = this.props.editMode
        const supplier = this.props.edit

        if (editMode) {
            this.setState({
                materialSupplier: {
                    ...supplier,
                    QuebraEntrega: supplier.QuebraEntrega,
                    Arredondamento: supplier.Arredondamento,
                    LoteMinimo: supplier.LoteMinimo
                },
                origemMaterial: supplier.Tipo
            })
        }
    }

    updateSelectValue = (prop, value) => {
        this.setState({
            materialSupplier: {
                ...this.state.materialSupplier,
                [prop]: value
            }
        })
    }

    changeValue = (event) => {
        const prop = event.currentTarget.name
        let value = event.currentTarget.value

        if (prop === 'OrganizacaoCompras' || prop === 'GrupoCompradores' || prop === 'CodigoImposto') {
            value = value.toUpperCase()
        }

        if (prop === 'SLA' || prop === 'TransitTime') {
            value = formatOnlyNumber(value)
        }

        this.setState({
            materialSupplier: {
                ...this.state.materialSupplier,
                [prop]: value
            }
        })
    }

    changeDecimalValue = (value, prop) => {
        this.setState({
            materialSupplier: {
                ...this.state.materialSupplier,
                [prop]: value
            }
        })
    }

    save = () => {
        let { materialSupplier, origemMaterial } = this.state
        const { ordem, idUnidadeArredondamento, descricaoUnidadeArredondamento } = this.props

        const editMode = this.props.editMode
        if (!editMode) {
            materialSupplier.Nome = materialSupplier.Fornecedor.Nome
            materialSupplier.Tipo = origemMaterial
        }

        materialSupplier.IdUnidadeArredondamento = idUnidadeArredondamento
        materialSupplier.DescricaoUnidadeArredondamento = descricaoUnidadeArredondamento

        const arredondamento = parseFloatByLocale(materialSupplier.Arredondamento)
        const loteMinimo = parseFloatByLocale(materialSupplier.LoteMinimo)
        const quebraEntrega = parseFloatByLocale(materialSupplier.QuebraEntrega)
        const isValidaDate = this.isValidateDataSave(arredondamento, loteMinimo, quebraEntrega, origemMaterial, materialSupplier);

        if (isValidaDate) {
            if (materialSupplier.ResponsavelEntrega
                && materialSupplier.QuebraEntrega
                && materialSupplier.Arredondamento
                && materialSupplier.LoteMinimo
                && materialSupplier.SLA
                && (!supplierOrigin.IsTransfer(origemMaterial) || materialSupplier.CodigoImposto)) {
                materialSupplier.Id = -1
                materialSupplier.Arredondamento = arredondamento
                materialSupplier.LoteMinimo = loteMinimo
                materialSupplier.QuebraEntrega = quebraEntrega
                this.props.setSupplier(ordem, materialSupplier)
                this.props.handleClose();
            } else {
                this.props.handleFeedback(intl.get('feedbacks.messageMaterialBusinessUnit9'))
            }
        }
    }

    isEmptyInt = (value) => !value || value === 0;

    isValidateDataSave = (arredondamento, loteMinimo, quebraEntrega , origemMaterial, materialSupplier) => {
        const leadtime = parseFloat(materialSupplier.SLA) + parseFloat(materialSupplier.TransitTime)
        const horizonte = parseFloat(this.props.horizonteProgramacao ? this.props.horizonteProgramacao : 0)

        if (arredondamento <= 0) {
            this.props.handleFeedback(intl.get('feedbacks.messageMaterialBusinessUnit1'))
            return false;
        } else if (arredondamento > loteMinimo) {
            this.props.handleFeedback(intl.get('feedbacks.messageMaterialBusinessUnit2'))
            return false;
        } else if (loteMinimo > quebraEntrega) {
            this.props.handleFeedback(intl.get('feedbacks.messageMaterialBusinessUnit3'))
            return false;
        } else if (horizonte < leadtime) {
            this.props.handleFeedback(
                `${intl.get('feedbacks.messageMaterialBusinessUnit4')} ${this.props.horizonteProgramacao} ${intl.get('feedbacks.messageMaterialBusinessUnit5')}`)
            return false;
        } else if (origemMaterial === 2 && this.isEmptyInt(materialSupplier.OrganizacaoCompras)) {
            this.props.handleFeedback(intl.get('feedbacks.messageMaterialBusinessUnit6'))
            return false;
        } else if (origemMaterial === 2 && this.isEmptyInt(materialSupplier.GrupoCompradores)) {
            this.props.handleFeedback(intl.get('feedbacks.messageMaterialBusinessUnit7'))
            return false;
        } else if (origemMaterial === 2 && this.isEmptyInt(materialSupplier.CodigoImposto)) {
            this.props.handleFeedback(intl.get('feedbacks.messageMaterialBusinessUnit8'))
            return false;
        }
        return true;
    }

    changeFornecedor = (supplier) => {

        const {origemMaterial} = this.state
        this.setState({
            materialSupplier: {
                ...this.state.materialSupplier,
                IdFornecedor: supplier.Id,
                Fornecedor: supplier,
                ResponsavelEntrega: supplierOrigin.IsTransfer(origemMaterial) ? RESPONSAVEL_ENTREGA_FOB : supplier.ResponsavelEntrega
            }
        })
    }

    handleSelectOrigemMaterial = (value) => {
        let origemMaterial = supplierValues.COMPRA_DE_INSUMOS

        if (supplierOrigin.IsTransfer(value)) {
            origemMaterial = supplierValues.TRANSFERENCIA
        } else if (supplierOrigin.IsAtgPurchase(value)) {
            origemMaterial = supplierValues.COMPRA_ATG
        }

        const resp = this.state.ResponsavelEntrega

        this.setState({
            origemMaterial,
            materialSupplier: {
                ...this.state.materialSupplier,
                IdFornecedor: 0,
                ResponsavelEntrega: supplierOrigin.IsTransfer(origemMaterial) ? RESPONSAVEL_ENTREGA_FOB : resp
            }
        })
    }

    converterOrdem = (ordem) => {
        switch (ordem) {
            case 0:
                return 'A'
            case 1:
                return 'B'
            case 2:
                return 'C'
            default:
                return 'D'
        }
    }

    render() {
        const { origemMaterial } = this.state
        const { open, editMode, handleClose, suppliersSelecteds } = this.props

        return (
            <StyledMaterialSupplierForm>
                <Dialog
                    title={`${intl.get('commons.supplier')} ${this.converterOrdem(this.props.ordem)}`}
                    contentStyle={{ width: '600px'}}
                    open={open}
                    autoScrollBodyContent
                    actionsContainerClassName="container-action-buttons__material-supplier"
                    actions={[
                        <Button
                            type="default"
                            value={intl.get('geral.buttonsDefault.cancel')}
                            className='button__material_supplier'
                            onClick={handleClose}
                        />,
                        <Button
                            type="primary"
                            value={intl.get('geral.buttonsDefault.save')}
                            className='button__material_supplier'
                            onClick={this.save}
                        />
                    ]}
                >
                    <MaterialSupplierFormContent
                        changeValue={this.changeValue}
                        editMode={editMode}
                        changeSupplier={this.changeFornecedor}
                        materialSupplier={this.state.materialSupplier}
                        suppliersSelecteds={suppliersSelecteds}
                        origemMaterial={origemMaterial}
                        handleSelectOrigemMaterial={this.handleSelectOrigemMaterial}
                        incoterms={this.state.incoterms}
                        supplier={this.props.edit}
                        handleFeedback={this.props.handleFeedback}
                        idLabeledFamilyCenter={this.state.idLabeledFamilyCenter}
                        periodicity={this.props.periodicity}
                        idMaterial={this.props.idMaterial}
                        updateSelectValue={this.updateSelectValue}
                        changeDecimalValue={this.changeDecimalValue}
                    />
                </Dialog>
            </StyledMaterialSupplierForm>
        )
    }
}

MaterialSupplierForm.propTypes = {
    idEdit: PropTypes.number,
    idMaterial: PropTypes.number,
    horizonteProgramacao: PropTypes.number,
    idLabeledFamilyCenter: PropTypes.number,
    ordem: PropTypes.number,
    open: PropTypes.bool.isRequired,
    edit: PropTypes.object.isRequired,
    editMode: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleFeedback: PropTypes.func.isRequired,
    setSupplier: PropTypes.func.isRequired,
    suppliersSelecteds: PropTypes.object.isRequired,
}

export default MaterialSupplierForm

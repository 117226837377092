import PropTypes from 'prop-types';
import styled from "styled-components";
import { LABEL_WIDTH, PADDING_LEFT } from "./../constants";

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 14px;
  color: #3b495e;
  font-weight: bold;
  flex-shrink: 0;
  padding-left: 5px;
  box-sizing: border-box;
  width: ${p => LABEL_WIDTH - p.childLevel * PADDING_LEFT}px;
`;

StyledLabel.propTypes = {
  childLevel: PropTypes.number,
}


import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;

  span {
    cursor: auto;
  }
`
export const Title = styled.span`
  font-size: 30px;
  line-height: 30px;
  display: flex;
  color: #3B495E;
  white-space: break-spaces;
`

export const SubTitle = styled.span`
  font-weight: 600;
  font-size: 17px;
  line-height: 14px;
  color: #2870B2;
`
export const CheckboxsColumn = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;

  .checkbox-item {
    padding: 3px;
  }
`
export const CheckboxColumn = styled.div``

import styled from 'styled-components'

const AceiteReadOnlyRenegociacaoPedidoStyled = styled.div`
    padding: 0px 0px 0px 0px;

    .renegotiation-order-read-offer {
        width: 100%;
        display: flex;
        position: relative;
        justify-content: flex-start;
        margin-left: 10px;
    }

    .renegotiation-order-read-column:last-child{
        margin-left: 20px;
        margin-bottom: 0px;
    }

    .renegotiation-order-read-column:not(:first-child):not(:last-child){
        margin-left: 20px;
        margin-bottom: 0px;
    }

    .renegotiation-order-read-column span:first-child{
        font-size: 11px;
        font-weight: bold;
        line-height: 1.82;
        text-align: left;
        color: #a4a4a4;
        text-transform: uppercase;
    }

    .renegotiation-order-read-column span:last-child{
        font-size: 13px;
        font-weight: 300;
        margin-left: 5px;
        line-height: 1.15;
        color: #434343;
    }

    .renegotiation-order-read-column-first{
        font-size: 11px;
        font-weight: bold;
        line-height: 1.82;
        text-align: left;
        color: #a4a4a4;
        text-transform: uppercase;
    }

    .renegotiation-order-read-column-last{
        font-size: 13px;
        font-weight: 300;
        margin-left: 5px;
        line-height: 1.15;
        color: #434343;
        text-transform: none;
    }
`

export default AceiteReadOnlyRenegociacaoPedidoStyled
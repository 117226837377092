import styled from 'styled-components'

const StyledTitleHeader = styled.h1`
  font-size: 30px;
  font-weight: var(--font-weight-semibold);
  line-height: 0.67;
  text-align: left;
  color: ${props => props.color};
  width: ${props => props.width}px;
  margin-top: auto;
  margin-bottom: auto;
`

StyledTitleHeader.defaultProps = {
  color: '#ffffff',
  width: 355,
}

export default StyledTitleHeader

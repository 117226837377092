import React, { Component } from 'react'
import { Button, Layout } from '@hbsis.uikit/react'

import SnackBar from 'material-ui/Snackbar'
import ConsoleIcon from 'material-ui/svg-icons/hardware/device-hub'

import Filtro from 'components/filters/filter-labeled-family'
import Message from 'components/message'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import DeleteFamily from 'components/delete-dialog'
import ButtonNewItem from 'components/button-new-item'

import IconEmpty from 'images/iconEmpty.icon'
import IconFilterGray from 'images/icn-filter-gray.svg'
import IconFilterBlue from 'images/icn-filter-blue.svg'

import Fetch from 'utils/fetch'
import StorePersist from 'utils/store-persist'

import LabeledFamilyList from './list'
import LabeledFamilyForm from './form'
import './labeled-family.css'


import intl from 'react-intl-universal'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";

class Family extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      labeledFamilies: [],
      total: 0,
      openDelete: false,
      currentPage: 0,
      openForm: false,
      openEditMode: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 50,
      showMsg: false,
      textMsg: '',
      terms: '',
      showFilter: false,
      filters: {
        IdCarteira: 0,
        IdFamilia: 0,
        IdFamiliaRotulada: 0
      }
    }
  }

  UNSAFE_componentWillMount() {
    const filter = StorePersist.getValuesJSON('filtersLabeledFamily')
    if (filter) {
      this.setState({
        filters: filter
      })
    }
  }

  showMessage = (message) => {
    this.setState({
      showMsg: true,
      textMsg: message
    })
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const selected = this.state.labeledFamilies[row]

    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (ex) => {
    this.stopFetching()
    this.showMessage((ex.response.status === 400) ? ex.response.data.Message : intl.get('stocks.timeline.feedback.serverError'))
  }

  refreshSearch = () => {
    this.closeForm()
    this.searchFamilies()
  }

  proccessData = (data) => {
    this.setState({
      labeledFamilies: data.Data,
      total: data.TotalRecordCount
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  deleteFamily = () => {
    const idLabeledFamily = this.state.idSelected

    Fetch.delete(`/familiaRotulada/${idLabeledFamily}`)
      .then(this.closeDelete)
      .then(this.searchFamilies)
      .then(() => this.showFeedback(intl.get('master-data.walletsAndFamilys.labeledFamily.feedback.labeledFamilySuccessfullyDeleted')))
      .catch((e) => this.proccessErro(e))
  }

  searchFamilies = (page) => {
    this.startFetching()
    const nextPage = page == undefined ? this.state.currentPage : page;
    Fetch.post(`/familiaRotulada/${this.state.qtRegsPerPage}/${nextPage}`, this.state.filters)
      .then((response) => this.proccessData(response.data))
      .then(this.stopFetching)
      .then(() => this.setState({ currentPage: nextPage }))
      .catch((e) => this.proccessErro(e))
  }

  showFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
    })
  }

  verifyFilter = () => {
    return this.state.filters.IdCarteira > 0
      || this.state.filters.IdFamilia > 0
      || this.state.filters.IdFamiliaRotulada > 0
  }

  searchFilters = (filters) => {
    StorePersist.setValuesJSON('filtersLabeledFamily', filters);
    this.showFilter()
    this.setState({
      currentPage: 0,
      filters
    }, () => this.searchFamilies(this.state.currentPage))
  }

  render() {
    let {
      openForm,
      openEditMode,
      openDelete,
      idSelected,
      labeledFamilies,
      total,
      showFeedback,
      messageFeedBack,
      isFetching,
      showMsg,
      textMsg,
      showFilter,
      filters
    } = this.state

    return (
      <div className='labeled-family-container'>
        <Loading isLoading={isFetching} />

        <div className="labeled-family-header">
          <HeaderIcon
            title={intl.get('master-data.walletsAndFamilys.labeledFamily.header.title')}
            subtitle={intl.get('master-data.walletsAndFamilys.labeledFamily.header.subtitle')}
            icon={<ConsoleIcon style={{ width: 45, height: 45 }} color='#1d388f' />}
          >

            {profileAdminOrMasterData() &&
              <div title={intl.get('master-data.walletsAndFamilys.labeledFamily.actions.newLabeledFamily')} >
                <ButtonNewItem
                  className="general-button"
                  onClick={this.openNewForm}
                />
              </div>
            }
            <div title={intl.get('master-data.walletsAndFamilys.labeledFamily.actions.filter')} className='filterContainer'>
              <Button
                onClick={this.showFilter}
                type='default'
                value={intl.get('filters.filter')}
                icon={this.verifyFilter() ? IconFilterBlue : IconFilterGray}
                className={"button-filter"}
              />
            </div>
          </HeaderIcon>
        </div>

        <LabeledFamilyList
          data={labeledFamilies}
          countData={total}
          refreshSearch={this.searchFamilies}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          qtRegsPerPage={this.state.qtRegsPerPage}
          profileAdminOrMasterData={profileAdminOrMasterData()}
        />

        {openForm &&
          <LabeledFamilyForm
            idEdit={idSelected}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
            profileAdminOrMasterData={profileAdminOrMasterData()}
          />
        }

        <DeleteFamily
          open={openDelete}
          title={intl.get('master-data.walletsAndFamilys.labeledFamily.deleteModal.title')}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.general.materials.description')}
          handleConfirm={this.deleteFamily}
        />

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

        <Message
          show={showMsg}
          text={textMsg}
          title='Erro!'
          handleClose={() => this.setState({ showMsg: false })}
        />

        <Layout.Sidebar width="450px"
          visible={showFilter}
          triggerClose={this.showFilter}
          background="#FFFFFF"
          offsetTop="96px"
          side="right"
          block
          float
          icon={<IconEmpty />}
        >
          <Filtro
            initialData={filters}
            showFilter={showFilter}
            handleClose={this.searchFilters}
          />
        </Layout.Sidebar>
      </div>
    )
  }
}

export default Family

import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'
import {
  Input
} from '@hbsis.uikit/react'

import {
  getIncotermsByContracts,
  getAllExternalSuppliers,
  getAllInternalSuppliers
} from './material-supplier-form-content.service'

import intl from 'react-intl-universal';
import { formatErrorMessage } from "utils/handle-error";
import StyledMaterialSupplierFormContent from './material-supplier-form-content.styled'
import { supplierOrigin } from '../../supplier-utils';
import RadioGroupMaterialForm from './radio-group-material-form';
import { Switcher } from 'components/toggle';
import { SelectBox } from 'components/uikit-adapter';
import InputNumber from 'components/input-number';

const periodicityMonth = 1;

class MaterialSupplierFormContent extends Component {
  constructor(props) {
    super()
    this.state = {
      externalSuppliers: [],
      internalSuppliers: [],
      suppliers: [],
      incoterms: [
        { Key: 1, Value: 'CIF' },
        { Key: 2, Value: 'FOB' }
      ],
      idLabeledFamilyCenter: props.idLabeledFamilyCenter,
    }
  }

  componentDidMount() {
    const { editMode, supplier } = this.props;

    if (editMode) {
      if (!supplierOrigin.IsTransfer(this.props.origemMaterial)) {
        this.searchIncoterms(supplier.IdFornecedor);
      }
    } else {
      this.searchAllExternalSuppliers();
      this.searchAllInternalSuppliers();
    }
  }

  searchIncoterms = async (idFornecedor) => {
    try {
      const incoterms = await getIncotermsByContracts(idFornecedor);
      this.processIncoterms(incoterms);
    } catch (e) {
      this.proccessErro(e);
    }
  }

  processIncoterms = (incoterms) => {
    this.setState({ incoterms });

    if (this.props.editMode) {
      this.props.updateSelectValue('ResponsavelEntrega', this.props.materialSupplier.ResponsavelEntrega);
    }
    else {
      if (incoterms.length == 1)
        this.props.updateSelectValue('ResponsavelEntrega', incoterms[0].Key);
    }
  }

  searchAllExternalSuppliers = async () => {
    const { idLabeledFamilyCenter, idMaterial } = this.props;

    try {
      const suppliers = await getAllExternalSuppliers(idLabeledFamilyCenter, idMaterial);
      this.processAllExternalSuppliers(suppliers);
    } catch (e) {
      this.proccessErro(e);
    }
  }

  processAllExternalSuppliers = (suppliers) => {
    const externalSuppliers = this.filterSuppliersNoSelected(suppliers)
    this.setState({
      suppliers: externalSuppliers,
      externalSuppliers
    })
  }

  searchAllInternalSuppliers = async () => {
    const { idLabeledFamilyCenter, idMaterial } = this.props;

    try {
      const internalSuppliers = await getAllInternalSuppliers(idLabeledFamilyCenter, idMaterial);
      this.processAllInternalSuppliers(internalSuppliers);
    } catch (e) {
      this.proccessErro(e);
    }
  }

  processAllInternalSuppliers = (suppliers) => {
    const internalSuppliers = this.filterSuppliersNoSelected(suppliers);
    this.setState({ internalSuppliers })
  }

  filterSuppliersNoSelected = (suppliers) => suppliers.filter(s => !this.props.suppliersSelecteds.includes(s.Id));

  proccessErro = (exception) => {
    try {
      const erro = formatErrorMessage(exception);
      this.props.handleFeedback(erro);
    } catch (e) {
      this.props.handleFeedback(intl.get('feedbacks.serverError'))
    }
  }

  changeSupplier = ({ Id }) => {
    const supplier = this.state.suppliers.find(s => s.Id == Id);
    this.props.changeSupplier(supplier);

    if (!supplierOrigin.IsTransfer(this.props.origemMaterial)) {
      this.searchIncoterms(Id);
    }
  }

  handleSelectOrigemMaterial = (event) => {
    let suppliers = this.state.externalSuppliers
    const value = parseInt(event.currentTarget.value)

    if (supplierOrigin.IsTransfer(value)) {
      suppliers = this.state.internalSuppliers
    }

    this.setState({
      suppliers,
      incoterms: [
        { Key: 1, Value: 'CIF' },
        { Key: 2, Value: 'FOB' }
      ],
    })

    this.props.handleSelectOrigemMaterial(value)
  }

  fieldsTransfer = (materialSupplier) => (
    <div>
      <label className='label-style'>{intl.get('stocks.suggestionsListItem.driveTransferInformation')}</label>
      <div className='container'>
        <Row className="grupo-origem-interna">
          <Col xs={4}>
            <Input
              name='OrganizacaoCompras'
              label={intl.get('stocks.suggestionsListItem.purchasingOrganization')}
              value={materialSupplier.OrganizacaoCompras}
              onChange={this.props.changeValue}
              maxLength={4}
            />
          </Col>
          <Col xs={4}>
            <Input
              name='GrupoCompradores'
              label={intl.get('stocks.suggestionsListItem.buyerGroup')}
              value={materialSupplier.GrupoCompradores}
              onChange={this.props.changeValue}
              maxLength={3}
            />
          </Col>
          <Col xs={4}>
            <Input
              name='CodigoImposto'
              label={intl.get('stocks.suggestionsListItem.taxCode')}
              value={materialSupplier.CodigoImposto}
              onChange={this.props.changeValue}
              maxLength={2}
            />
          </Col>
        </Row>
      </div>
    </div>
  )

  render() {
    const { suppliers, incoterms } = this.state;
    const { materialSupplier, origemMaterial, editMode } = this.props;

    let descricaoOrigem = '';
    const sourceMessage = `${intl.get('commons.source')}`;

    if (editMode) {
      descricaoOrigem = supplierOrigin.getName(origemMaterial);
    }

    return (
      <StyledMaterialSupplierFormContent>
        <Row>
          <Col>
            <label className='select-origem-label'>{`${sourceMessage} ${descricaoOrigem}`}</label>
          </Col>
          <Col xs={12}>
            {!editMode &&
              <div className='select-origem'>
                <RadioGroupMaterialForm
                  label={sourceMessage}
                  handleChange={this.handleSelectOrigemMaterial}
                  materialOrigin={origemMaterial}
                />
              </div>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {editMode &&
              <Input
                required
                disabled
                readOnly
                label=''
                name='Fornecedor'
                value={materialSupplier.Nome}
              />
            }

            {!editMode &&
              <SelectBox
                className='selectbox-fornecedor'
                required
                searchable
                name='IdFornecedor'
                placeholder={sourceMessage}
                label={sourceMessage}
                valueKey='Id'
                labelKey='Nome'
                value={materialSupplier.IdFornecedor}
                options={suppliers}
                onChange={this.changeSupplier.bind(null)}
                width="100%"
              />
            }
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputNumber
              type='default'
              name='Arredondamento'
              label={intl.get('master-data.general-configuration.arredondamento')}
              onChange={_value => this.props.changeDecimalValue(_value, "Arredondamento")}
              value={materialSupplier.Arredondamento}
            />
          </Col>
          <Col xs={6}>
            <Input
              disabled
              readOnly
              name='UnidadeArredondamento'
              label={intl.get('master-data.general.table.actions.editProvider.roundedUnity')}
              value={materialSupplier.DescricaoUnidadeArredondamento}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <InputNumber
              type='default'
              name='LoteMinimo'
              label={intl.get('master-data.general-configuration.minimumLot')}
              onChange={_value => this.props.changeDecimalValue(_value, 'LoteMinimo')}
              value={materialSupplier.LoteMinimo}
            />
          </Col>
          <Col xs={6}>
            <InputNumber
              type='default'
              name='QuebraEntrega'
              onChange={_value => this.props.changeDecimalValue(_value, 'QuebraEntrega')}
              value={materialSupplier.QuebraEntrega}
              label={intl.get('master-data.general.table.actions.editProvider.deliveryBreak')}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={3}>
            <Input
              required
              name='SLA'
              label={intl.get('master-data.general-configuration.sla')}
              onChange={this.props.changeValue}
              value={materialSupplier.SLA}
            />
          </Col>
          <Col xs={3}>
            <Input
              name='TransitTime'
              label={intl.get('master-data.general.table.actions.editProvider.transitTime')}
              onChange={this.props.changeValue}
              value={materialSupplier.TransitTime}
              type='nowrap-label'
            />
          </Col>
          <Col xs={3}>
            <Input
              disabled
              readOnly
              name='Leadtime'
              label={intl.get('master-data.general.table.actions.editProvider.leadTime')}
              value={parseFloat(materialSupplier.SLA) + parseFloat(materialSupplier.TransitTime)}
            />
          </Col>
          <Col xs={3}>
            <SelectBox
              data-testid='selectbox-incoterm'
              required
              label={intl.get('master-data.general-configuration.incoterm')}
              placeholder={intl.get('master-data.general-configuration.incoterm')}
              name='Incoterm'
              value={materialSupplier.ResponsavelEntrega}
              valueKey='Key'
              labelKey='Value'
              onChange={value => this.props.updateSelectValue('ResponsavelEntrega', value.Key)}
              openOnFocus={true}
              options={incoterms}
              width="100%"
              disabled={supplierOrigin.IsTransfer(origemMaterial)}
            />
          </Col>
        </Row>
        {(materialSupplier.LeadtimeErp || materialSupplier.LoteMinimoErp) &&
          <Row>
            <Col xs={6}>
              <Input
                disabled
                readOnly
                name='minimumLotErp'
                label={intl.get('master-data.general-configuration.minimumLotErp')}
                value={materialSupplier.LoteMinimoErp}
              />
            </Col>
            <Col xs={6}>
              <Input
                disabled
                readOnly
                name='leadtimeErp'
                label={intl.get('master-data.general-configuration.leadtimeErp')}
                value={materialSupplier.LeadtimeErp}
              />
            </Col>
          </Row>
        }
        {(this.props.periodicity === periodicityMonth) &&
          <div title={intl.get('master-data.general-configuration.tooltipAutomaticCreateLoadComposition')}>
            <label className="label-style">
              <span>
                {intl.get('master-data.general-configuration.configurationMonthlyWallet')}
              </span>
            </label>
            <div className="container__general-form">
              <Row>
                <Col xs={12} lg={12} md={12} className="bulk-pending">
                  <Switcher
                    className={"CriarComposicaoCargaAutomatica"}
                    name={"CriarComposicaoCargaAutomatica"}
                    label={intl.get('master-data.general-configuration.createChargeCompositionAutomatic')}
                    value={this.props.materialSupplier.CriarComposicaoCargaAutomatica}
                    onChange={(val) => this.props.updateSelectValue("CriarComposicaoCargaAutomatica", val.checked)}
                  />
                </Col>
              </Row>
            </div>
          </div>
        }
        {(!supplierOrigin.IsTransfer(origemMaterial)) &&
          <div style={{ height: '100px', backgoudnColor: 'red' }}></div>
        }
        {supplierOrigin.IsTransfer(origemMaterial) && this.fieldsTransfer(materialSupplier)}
      </StyledMaterialSupplierFormContent>
    )
  }
}

MaterialSupplierFormContent.propTypes = {
  idMaterial: PropTypes.number,
  idLabeledFamilyCenter: PropTypes.number,
  supplier: PropTypes.object.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  changeDecimalValue: PropTypes.func.isRequired,
  materialSupplier: PropTypes.object.isRequired,
  changeValue: PropTypes.func.isRequired,
  handleSelectOrigemMaterial: PropTypes.func.isRequired,
  changeSupplier: PropTypes.func.isRequired,
  updateSelectValue: PropTypes.func.isRequired,
  suppliersSelecteds: PropTypes.object.isRequired,
}

export default MaterialSupplierFormContent

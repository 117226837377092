import styled from "styled-components";

const StyledInformAdvanceDelayOrder = styled.div`

.footer-dialog-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  margin-top: 15px !important;
}

.button{
  width: 200px !important;
}

.span {
  line-height: 1.8rem;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #3b495e;
}

.span .content {
  font-size: 15px;
}

.label-style{
  background: white;
  margin-left: 10px;
}

.container{
  width: 97% !important;
  padding: 7px;
  margin-top: -16px;
  border: 1px solid #c5d0e1;
}

.list-pedidos-composicao{
  width: 100%;
  margin-top: 0;
}

.mensagem-modal-atencao {
  margin-bottom: 40px;
  font-weight: bolder;
}
`;

export default StyledInformAdvanceDelayOrder;
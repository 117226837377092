import React from 'react'
import { Col, Row } from 'react-flexbox-grid'
import intl from 'react-intl-universal'
import SuggestionsListHeader from 'components/suggestions-list/suggestions-list-header'

export const ConsolidatedStocksSuggestionListHeader = ({ checked, checkAllItems }) => (
  <SuggestionsListHeader
    isOrder={false}
    checkItem={checkAllItems}
    checked={checked}
  >
    <Row middle="xs">
      <Col xs={2}>
        <div className="header-item">
          {intl.get("commons.status")}
        </div>
      </Col>
      <Col xs={2}>
        <div className="header-item">
          {intl.get("master-data.general-configuration.type")}
        </div>
      </Col>
      <Col xs={2}>
        <div className="header-item">{intl.get("commons.collect")}</div>
      </Col>
      <Col xs={2} >
        <div className="header-item">{intl.get("commons.delivery")}</div>
      </Col>
      <Col xs={2}>
        <div className="header-item">
          {intl.get("stocks.stockDetail.qtde")}
        </div>
      </Col>
      <Col xs={2}>
        <div className="header-item">
          UN
        </div>
      </Col>
    </Row>
  </SuggestionsListHeader>
)




import React from 'react'
import intl from 'react-intl-universal'
import moment from 'moment'
import OrdersVolum from 'components/orders-pending-volumn'
import { Button, Dialog } from "components/uikit-adapter/index"
import { Container, ContainerActions, Header, Title, DateSpan } from 'pages/grid-vision/components/modal/styled'
import { getPropsModel } from './models'
import icnTruckDelivery from 'images/delivery-truck.svg'

export const BottlesViewOrderModal = ({ openModal, labeledFamilyCenterId, date, handleClose }) => {
  const props = getPropsModel(date, labeledFamilyCenterId)

  return (
    <Dialog
      title={
        <Header>
          <img src={icnTruckDelivery} width="55px" />
          <Title>{intl.get('grid-vision.modal.title')}</Title>
          <DateSpan>{moment(date).format("L")}</DateSpan>
        </Header>
      }
      open={openModal}
      autoScrollBodyContent
      actions={
        <ContainerActions data-testid='btn-orders-volum'>
          <Button
            value={intl.get("geral.buttonsDefault.close")}
            onClick={handleClose}
            type="secondary"
            className="buttons"
          />
        </ContainerActions>
      }
    >
      <Container>
        <OrdersVolum {...props} />
      </Container>
    </Dialog>
  )
}


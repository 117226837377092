import React, { createContext, useContext, useState, useEffect } from 'react'
import { sortTable } from '../utils/format';

const ZapmslContext = createContext();

const ZapmslProvider = ({ children }) => {
  const [zapmslData, setZapmslData] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [loading, setLoading] = useState(false);
  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)
  const [lastUpdated, setlastUpdated] = useState()
  const [filters, setFilters] = useState({})
  const [indexForEdit, setIndexForEdit] = useState()
  const [newLineInProgress, setNewLineInProgress] = useState(false)
  const [materials, setMaterials] = useState()

  const getCurrentFilterFormated = () => ({
    Plant: filters.plant.Centro,
    DateRangeFrom: filters.datas.deliveryDateFrom,
    DateRangeTo: filters.datas.deliveryDateTo
  })

  const updateTableWithData = (data) => {
    setZapmslData([...data])
  }

  const clearSelectedLines = () => {
    setSelectedLines([])
    document.getElementById('checkbox-all').checked = false;
  }

  useEffect(() => {
    if (lastUpdated) {
      const _data = [...zapmslData]
      _data[indexForEdit] = lastUpdated
      const sortData = sortTable(_data)
      updateTableWithData([...sortData])
    }
  }, [lastUpdated])

  return (
    <ZapmslContext.Provider
      value={{
        selectedLines,
        setSelectedLines,
        loading,
        setLoading,
        startLoading,
        stopLoading,
        setFilters,
        filters,
        getCurrentFilterFormated,
        zapmslData,
        setZapmslData,
        updateTableWithData,
        setlastUpdated,
        setIndexForEdit,
        newLineInProgress,
        setNewLineInProgress,
        clearSelectedLines,
        materials,
        setMaterials
      }}
    >
      {children}
    </ZapmslContext.Provider>
  )
}

export const useZapmslContext = () => useContext(ZapmslContext);

export { ZapmslProvider, ZapmslContext }

import styled from 'styled-components'

export const Wrapper = styled.div``
export const DialogWrapper = styled.div`
  .btn-ar-out {
    display: none;
  }
`
export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .tooltip {
    position: relative;
    display: inline-block;
    font-size: 11px !important;
  }

  .tooltip .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
`

export const Title = styled.div``
export const Button = styled.div``
export const ActionButton = styled.div`

  display:flex;
  justify-content: flex-end;
  margin-right: 16px;
`

import style from 'styled-components'

export const Button = style.div`
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  color: #477dc0;
  font-weight: 600;
  font-size: 14px;
  background-color: #FFF;
  margin-top: 5px;
  border-radius: 2px;
  box-shadow: inset -1px 0 0 0 #c5d0e1, inset 0 -1px 0 0 #c5d0e1, inset 1px 0 0 0 #c5d0e1, inset 0 2px 0 0 #afcef3;
  cursor: pointer;
`

export const GridButton = style.div`
  color: #2870b2;
  background-color: #e7f0f7;
  border: solid 1px #afcef3;
  width: 48px;
  line-height: 16px;
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  padding: 11px;
  cursor: pointer;
  border-radius: 2px;

  :hover {
    background-color: #e7f0f7;
    border: solid 1px #5596c8;
  }
`
import React, { Component } from "react";
import Fetch from "utils/fetch";
import Session from "utils/user-storage";
import Header from "components/header";
import Loading from "components/center-loading";
import ScreenDefault from "./components/screen-default";
import DateRangerPicker from "components/date-range-picker";
import { Button } from "@hbsis.uikit/react";
import { SelectBox } from "components/uikit-adapter/index"
import { Row, Col } from "react-flexbox-grid";
import intl from "react-intl-universal";
import "./relatorios.css";
import { formatErrorMessage } from "utils/handle-error";
import DadosRelatorios from "./dados-relatorios";
import TipoUsuario from "../../models/usuarios/tipo-usuario";
import moment from "moment";
import { getAllCenters } from "../../services/center.service";
import { downloadFile, generateQueryParams } from "../../utils/custom-functions";
import SnackBar from "material-ui/Snackbar";
import ProductionPlanReportDisclaimer from "../../components/production-plan-report-disclaimer";
import { usesSharedWeeklyForecastWithSupplier, usesTmsIntegration } from "utils/validations-general-settings";

class Relatorios extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      reportSelected: {},
      showMsg: false,
      textMsg: "",
      titleMsg: "",
      isFetching: false,
      centersList: [],
      stagesList: [],
      walletsList: [],
      currentPeriodDatesList: [],
      filterSelected: {
        dataColetaDe: "",
        dataColetaAte: "",
        dataEntregaDe: "",
        dataEntregaAte: "",
        productionDateFrom: "",
        productionDateTo: "",
        centers: [],
        etapa: 0,
        carteiras: [],
        currentPeriodDate: 1,
      },
    };
  }

  componentDidMount() {
    this.mountListOfCurrentPeriodDates();
    this.configureReports();
    this.searchCenters();
    this.searchEtapa();
    this.searchWallets();
  }

  mountListOfCurrentPeriodDates() {
    const lastMonth = intl.get("reports.filters.lastMonth");
    const last = intl.get("reports.filters.last");
    const months = intl.get("reports.filters.months");
    const currentPeriodDatesList = [
      { Id: 1, Description: `${lastMonth}` },
      { Id: 3, Description: `${last} 3 ${months}` }
    ];

    this.setState({ currentPeriodDatesList });
  }

  searchCenters = () => {
    this.startFetching();
    getAllCenters()
      .then((response) => this.processData("centersList", response.data))
      .catch((error) => this.proccessErro(error))
      .finally(this.stopFetching());
  };

  searchEtapa = () => {
    this.startFetching();
    Fetch.get(`/status/getEtapas`)
      .then((response) => this.processData("stagesList", response.data))
      .catch((e) => this.proccessErro(e))
      .finally(this.stopFetching(false));
  };

  searchWallets = () => {
    this.startFetching();
    Fetch.get(`/carteira/todas`)
      .then((response) => this.processData("walletsList", response.data))
      .catch((e) => this.proccessErro(e))
      .finally(this.stopFetching());
  };

  processData = (prop, data) => {
    this.setState({
      [prop]: data,
    });
  };

  selectReport = (reportSelected) => {
    this.setState({ reportSelected });
  };

  updateDataRange = (prop, value, dateType) => {
    let campo = "";
    if (dateType === "pickUpDate") {
      if (prop === "dataDe") {
        campo = "dataColetaDe";
      } else {
        campo = "dataColetaAte";
      }
    } else if (dateType === "deliveryDate") {
      if (prop === "dataDe") {
        campo = "dataEntregaDe";
      } else {
        campo = "dataEntregaAte";
      }
    } else if (dateType === "productionDate") {
      if (prop === "dataDe") {
        campo = "productionDateFrom";
      } else {
        campo = "productionDateTo";
      }
    }

    this.setState({
      filterSelected: {
        ...this.state.filterSelected,
        [campo]: value,
      },
    });
  };

  changeValueFilters = (prop, value) => {
    this.setState({
      filterSelected: {
        ...this.state.filterSelected,
        [prop]: value,
      },
    });
  };

  changeFilterValues = (prop, event) => {
    const value = [];
    event.map((v) => value.push(v));
    this.setState({
      filterSelected: {
        ...this.state.filterSelected,
        [prop]: value,
      },
    });
  };

  cleanFilters = () => {
    this.setState({
      filterSelected: {
        dataColetaDe: "",
        dataColetaAte: "",
        dataEntregaDe: "",
        dataEntregaAte: "",
        carteiras: [],
        centers: [],
        etapa: 0,
        currentPeriodDate: 6,
      },
    });
  };

  downloadReport = () => {
    const report = this.state.reportSelected;
    const datafile = moment(new Date()).format("YYYYMMDD_HHmmss");
    const reportFileName = `${report.ReportName}_${datafile}.csv`;

    if(report.ReportName === 'ORDERS_REPORT'){
      this.downloadOrderReport(report, reportFileName);
    }else{
      this.downloadReports(report, reportFileName);
    }

  };

  downloadOrderReport = (report, reportFileName) => {
    this.startFetching();

    const filter = this.state.filterSelected;

    const params = {
      CurrentPeriodDate: filter.currentPeriodDate,
      PickupDateMin: filter.dataColetaDe,
      PickupDateMax: filter.dataColetaAte,
      DeliveryDateMin: filter.dataEntregaDe,
      DeliveryDateMax: filter.dataEntregaAte,
      OrderStatus: [
        filter.etapa
      ],
      MaterialGroup: []
    };

    filter.carteiras.forEach(function(element) {
      params.MaterialGroup.push(element.Id);
    });

    const url = `${report.Url}?${generateQueryParams(params)}`; 

    Fetch.get(url)
      .then((response) => { downloadFile(reportFileName, "csv", response.data); })
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching());
  };

  downloadReports = (report, reportFileName) => {
    this.startFetching();
    Fetch.post(`${report.Url}`, {
      ...this.state.filterSelected,
      carteiras: this.state.filterSelected.carteiras.map(x => x.Id),
      centers: this.state.filterSelected.centers.map(x => x.Id)
    })
      .then((response) => { downloadFile(reportFileName, "csv", response.data); })
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching());
  };

  startFetching = () => {
    this.setState({
      isFetching: true,
    });
  };

  stopFetching = () => {
    this.setState({
      isFetching: false,
    });
  };

  proccessErro = (exception) => {
    this.setState({
      showMsg: true,
      titleMsg: "Erro",
      textMsg: formatErrorMessage(exception)
    });
  };

  handleClose = () => {
    this.setState({ showMsg: false });
  };

  renderOrderReport = (data) => {
    data.push(DadosRelatorios.Pedidos);
  }

  renderInventoryStatusReport = (data) => {
    if (Session.get().TipoUsuario === TipoUsuario.Ambev) {
      data.push({ ...DadosRelatorios.StatusEstoque, ReportName: intl.get("commons.statusStock") });
    }
  };

  renderVolumeOriginatedOrderReport = (data) => {
    if (Session.get().TipoUsuario === TipoUsuario.Ambev && usesTmsIntegration()) {
      data.push(DadosRelatorios.PedidoOrigemVolume)
    }
  }

  renderProductionPlanReport = (data) => {
    if (Session.get().TipoUsuario === TipoUsuario.Fornecedor && usesSharedWeeklyForecastWithSupplier()) {
      data.push(DadosRelatorios.PlanejamentoProducao)
    }
  };

  configureReports = () => {
    const data = [];

    this.renderOrderReport(data);
    this.renderInventoryStatusReport(data);
    this.renderVolumeOriginatedOrderReport(data);
    this.renderProductionPlanReport(data);

    this.setState({
      data,
      reportSelected: data[0],
    });
  };

  reportName = (name) => {
    let title = "";
    switch (name) {
      case "Pedidos":
        title = intl.get("reports.actions.reportRequest");
        break;
      case "Pedidos Originados Por Volume":
        title = intl.get("reports.actions.requestDeliverySourceVol");
        break;
      case "Status do Estoque":
        title = intl.get("reports.actions.requestSourceVol");
        break;
      case DadosRelatorios.PlanejamentoProducao.Nome:
        title = intl.get("reports.actions.requestProductionPlan");
        break;
      default:
        break;
    }
    return title
  };

  screenLeft = () => {
    const { data, reportSelected } = this.state;

    return (
      <div className="left-content">
        <div className="report-span-text font-title">
          <span>{intl.get("reports.actions.reportList")}</span>
        </div>
        {data.map((v, i) => (
          <div
            className={
              v.ReportName == reportSelected.ReportName
                ? "left-list-report report-selected"
                : "left-list-report"
            }
            key={i}
            onClick={() => this.selectReport(v)}
          >
            <span
              className={`report-span-text font-list-report ${v.ReportName}`}
            >
              {this.reportName(v.Nome)}
            </span>
          </div>
        ))}
      </div>
    );
  };

  screenRight = () => {
    const { reportSelected } = this.state;

    return (
      <div className="right-content">
        <div>
          <div>
            <span className="report-span-text font-title">
              {this.reportName(reportSelected.Nome)}
            </span>
          </div>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <hr className="filter-divisor" />
            </Col>
          </Row>

          {this.mountFilters(reportSelected.ReportName).map((f, i) => (
            <div>
              <Row key={i}>
                <Col xs={12} lg={12} md={12}>
                  {f}
                </Col>
              </Row>
            </div>
          ))}
        </div>

        <Row>
          <Col xs={12} lg={12} md={12}>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <hr className="filter-divisor" />
              </Col>
            </Row>
            <div className="footer-dialog-buttons">
              <Row>
                <Col xs={6} lg={6} md={6}>
                  <div title={intl.get("filters.cleanSearch")}>
                    <Button
                      className="button"
                      onClick={this.cleanFilters}
                      type="secondary"
                      value={intl.get("filters.cleanFilter")}
                    />
                  </div>
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <div
                    title={`${intl.get("reports.actions.downloadToolTip")} ${this.reportName(reportSelected.Nome)}`}
                  >
                    <Button
                      className="button"
                      onClick={this.downloadReport}
                      type="primary"
                      value="Download"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  centerFilter = () => {
    return (
      <SelectBox
        required
        name="Centros"
        label={intl.get("commons.brewery")}
        placeholder={intl.get("reports.actions.selectBrewery")}
        valueKey="Id"
        labelKey="Nome"
        value={this.state.filterSelected.centers}
        options={this.state.centersList}
        onChange={(event) => this.changeFilterValues("centers", event)}
        searchable
        openOnFocus
        multi
        className="brewery-filter"
      />
    );
  };

  stageFilter = () => {
    return (
      <SelectBox
        required
        name="Etapa"
        label={intl.get("requestOrders.step")}
        placeholder={intl.get("reports.actions.selectStage")}
        valueKey="Id"
        labelKey="Descricao"
        value={this.state.filterSelected.etapa}
        options={this.state.stagesList}
        onChange={(event) =>
          this.changeValueFilters("etapa", event ? event.Id : 0)
        }
        searchable
        openOnFocus
        clearable
        className="stage-filter"
      />
    );
  };

  walletFilter = () => {
    return (
      <SelectBox
        required
        name="Carteiras"
        label={intl.get("commons.wallet")}
        placeholder={intl.get("reports.actions.selectWallet")}
        valueKey="Id"
        labelKey="Descricao"
        value={this.state.filterSelected.carteiras}
        options={this.state.walletsList}
        onChange={(event) => this.changeFilterValues("carteiras", event)}
        searchable
        openOnFocus
        multi
        className="wallet-filter"
      />
    );
  };

  currentPeriodDateFilter = () => {
    return (
      <SelectBox
        required
        name="CurrentPeriodDate"
        label={intl.get("reports.filters.currentPeriodDate")}
        valueKey="Id"
        labelKey="Description"
        value={this.state.filterSelected.currentPeriodDate}
        options={this.state.currentPeriodDatesList}
        onChange={(event) =>
          this.changeValueFilters("currentPeriodDate", event ? event.Id : 0)
        }
        searchable
        openOnFocus
      />
    );
  };

  collectDateFilter = () => {
    return (
      <DateRangerPicker
        title={intl.get("commons.colDate")}
        dataDe={this.state.filterSelected.dataColetaDe}
        dataAte={this.state.filterSelected.dataColetaAte}
        handleChange={(x, y) => this.updateDataRange(x, y, "pickUpDate")}
        className="collect-date-filter"
      />
    );
  };

  deliveryDateFilter = () => {
    return (
      <DateRangerPicker
        title={intl.get("commons.deliveryDate")}
        dataDe={this.state.filterSelected.dataEntregaDe}
        dataAte={this.state.filterSelected.dataEntregaAte}
        handleChange={(x, y) => this.updateDataRange(x, y, "deliveryDate")}
        className="delivery-date-filter"
      />
    );
  };

  productionDateFilter = () => {
    return (
      <DateRangerPicker
        title={intl.get("commons.productionDate")}
        dataDe={this.state.filterSelected.productionDateFrom}
        dataAte={this.state.filterSelected.productionDateTo}
        dataMin={moment().format("YYYY-MM-DD")}
        handleChange={(x, y) => this.updateDataRange(x, y, "productionDate")}
        className="production-date-filter"
      />
    );
  };

  noFilters = () => {
    return (
      <div>
        <span className="report-span-text font-list-report">
          {intl.get("filters.withoutFilter")}
        </span>
      </div>
    );
  };

  mountFilters = (reportName) => {
    const filters = [];

    switch (reportName) {
      case DadosRelatorios.Pedidos.ReportName:
        filters.push(this.stageFilter());
        filters.push(this.walletFilter());
        filters.push(this.currentPeriodDateFilter());
        filters.push(this.collectDateFilter());
        filters.push(this.deliveryDateFilter());
        break;
      case DadosRelatorios.PedidoOrigemVolume.ReportName:
        filters.push(this.stageFilter());
        filters.push(this.walletFilter());
        filters.push(this.collectDateFilter());
        filters.push(this.deliveryDateFilter());
        break;
      case DadosRelatorios.PlanejamentoProducao.ReportName:
        filters.push(this.centerFilter());
        filters.push(this.walletFilter());
        filters.push(this.productionDateFilter());
        filters.push(<ProductionPlanReportDisclaimer />);
        break;
      default:
        break;
    }

    if (filters.length <= 0) {
      filters.push(this.noFilters());
    }

    return filters;
  };

  render() {
    const { isFetching } = this.state;

    return (
      <div>
        <Loading isLoading={isFetching} />
        <Header title={intl.get("reports.header.title")} />

        <ScreenDefault
          screenLeft={this.screenLeft()}
          screenRight={this.screenRight()}
        />
        <SnackBar
          message={this.state.textMsg}
          open={this.state.showMsg}
          autoHideDuration={3000}
          onRequestClose={this.handleClose}
        />
      </div>
    );
  }
}

export default Relatorios;

import { useState } from 'react'
import {
  ClbTableTBodyCell,
  ClbButtonIcon,
} from '@celebration/design-system-react'
import { Link } from 'react-router-dom'
import { updateGraphic } from 'pages/stocks-detail/stocks-detail.service'
import moment from 'moment'
import intl from 'react-intl-universal'
import * as S from './styled'
import { ConsolidatedStocksSuggestionsModal } from '../consolidated-stocks-suggestions-modal'

export const ActionsTableBody = ({ labeledFamilyCenterId, lastUpdate, handleLoad, setIsLoading }) => {
  const [openSuggestionModal, setOpenSuggestionModal] = useState(false)

  const handleUpdateProjection = async () => {
    setIsLoading(true);
    await updateGraphic(labeledFamilyCenterId);
    await handleLoad();
    setIsLoading(false);
  }

  return (
    <>
      <div className='border-left flex'>
        <ClbTableTBodyCell className='actions'>
          <S.WrapperActions>
            <Link to={`/stocksDetail/${labeledFamilyCenterId}`}>
              <ClbButtonIcon
                icon="Eye1"
                size="sm"
              />
            </Link>
            <ClbButtonIcon
              icon="Edit"
              size="sm"
              onClbClick={() => setOpenSuggestionModal(!openSuggestionModal)}
            />
            <ClbButtonIcon
              icon="Refresh"
              tooltipLabel={`${intl.get('commons.lastUpdate')}: ${moment(lastUpdate).format("L LT")}`}
              tooltipPosition="top-end"
              onClick={handleUpdateProjection}
              size="sm"
              data-testid='refresh-projection'
            />
          </S.WrapperActions>
        </ClbTableTBodyCell>
      </div>
      <ConsolidatedStocksSuggestionsModal
        labeledFamilyCenterId={labeledFamilyCenterId}
        open={openSuggestionModal}
        close={() => setOpenSuggestionModal(false)}
      />
    </>
  )
}
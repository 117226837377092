import Session from 'utils/user-storage';
import { SituacaoComposicaoCarga } from 'models/composicaoCarga/situacaoComposicaoCarga';
import { isLoadCompositionFlowBrewerySide, usesEmergencyNegotiation } from 'utils/validations-general-settings';
import TipoUsuario from 'models/usuarios/tipo-usuario';

export const validateShowButtonEmergencyNegotiateSupplierSide = (loadComposition, responsavelProximaAcao) => {
  const isSupplierUser = Session.get()?.TipoUsuario === TipoUsuario.Fornecedor
  const isValidStatus = SituacaoComposicaoCarga.Aceito === loadComposition.SituacaoValor ||
    (SituacaoComposicaoCarga.NegociacaoEmergencial == loadComposition.SituacaoValor
      && !responsavelProximaAcao);

  return isLoadCompositionFlowBrewerySide()
    && isSupplierUser
    && isValidStatus
    && usesEmergencyNegotiation();
}

export const validateShowButtonEmergencyNegotiationAmbevSide = (loadComposition) => {
  const isAmbevUser = Session.get()?.TipoUsuario === TipoUsuario.Ambev;
  const isValidStatus = SituacaoComposicaoCarga.Aceito === loadComposition.SituacaoValor

  return isLoadCompositionFlowBrewerySide()
    && isAmbevUser
    && isValidStatus
    && usesEmergencyNegotiation();
} 
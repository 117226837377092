import styled from 'styled-components'

export const GeneralSettingsHeaderWrapper = styled.div`
  display:flex;
  align-items: center;
  justify-content: space-between;
`
export const GeneralSettingsHeader = styled.div`
  display:flex;
  align-items:center;
`
export const GeneralSettingsWrapperImg = styled.div`
  width: 64px;
  heigth: 30px;
  display: flex;
  justify-content: center;
`

export const GeneralSettingsHeaderImg = styled.img``
export const GeneralSettingsTitle = styled.h3`
  color: #808080;
  font-weight: 600;
  font-size: 18px;
  padding-left: 10px;
  margin-top: 0;
  margin-bottom: 0;
`

export const GeneralSettingsContentWrapper = styled.div`
  margin-left: 80px;
  font-size: 12px;

  .content__span {
    color: #4F5C6F;
    font-weight: 700;
    margin-right: 1px;
  }

  .text {
    margin: 0;
  }

  label {
    text-transform: inherit !important;
  }
`

export const GeneralSettingsContentTitle = styled.h4`
  color: #2870B2;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
  margin-top: 3px;
`

export const GeneralSettingsContentText = styled.div`
  display: flex;
`

export const GeneralSettingsContent = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 0;

  .gereral-settings-save-button{
    display: flex;
    justify-content: center;
  }
`

export const GeneralSetting = styled.div`
  padding-bottom: 15px;
`

export const OptionGroupContent = styled.div`
  margin-top: -10px;
`
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import * as S from '../modalLoadInNegotiation/styled'
import moment from 'moment'
import VectorNext from 'images/arrow.svg'
import { Vector } from '../../../../features/bottles/components/bottles-dropdown/vector'


const SupplierInfos = ({ composicao, showDrop }) => {
  const [hideInfos, setHideInfos] = useState(showDrop)
  return (
    <S.ObservacoesWrapper>
      <S.SecondTitleWrapper>
        <S.SecondTitle hideInfos={hideInfos}>{intl.get("loadComposition.supplierNotes")}</S.SecondTitle>
        <S.VectorWrapper
          onClick={() => setHideInfos(!hideInfos)}
          hideInfos={hideInfos}
          showDrop={showDrop}
        >
          <Vector vector={VectorNext} />
        </S.VectorWrapper>
      </S.SecondTitleWrapper>

      {!hideInfos &&
        <S.InfosWrapper>
          <div>
            <div className='content'>
              <span className='title'>{intl.get('modalRenegotiationLoadComposition.suggestedDeliveryDateTime')}</span>
              <span className='description'>
                {moment(composicao.DataEntregaSugerida).format("[DM]")}
                {` ${intl.get('commons.at')} `}
                {moment(composicao.HoraEntregaSugerida, "HH:mm:ss").format("LT")}
              </span>
            </div>
            <div className='second-content-suppliers-info'>
              <span className='title'>{intl.get('modalRenegotiationLoadComposition.negotiationNotes')}</span>
              <span className='description'>{composicao.ObservacaoSugerida}</span>
            </div>
          </div>
        </S.InfosWrapper>
      }
    </S.ObservacoesWrapper>
  )
}

export default SupplierInfos;

import Styled from 'styled-components'

export const Container = Styled.div`
    height:60vh;

    .table-list-body{
        padding: 5px
    }

    #snackbar {
        justify-content: center;
    }
`

export const ContainerActions = Styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: right;
    padding-right: 35px;

    .buttons {
        width: 200px;
        margin: 15px 15px 15px 15px;
    }
`

export const Header = Styled.div`
    display: flex;
`

export const Title = Styled.h1`
    margin: 0;
    align-self: center;
    font-weight: 500;
    padding-left: 25px;
    font-size: 22px;
`

export const DateSpan = Styled.h1`
    background-color: #E7F0F7;
    color: #2870B2;
    border-radius: 50px;
    padding: 1px 50px 0px 50px;
    font-size: 15px;
    margin-left: auto;
    font-weight: 550;
`

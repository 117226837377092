import React, { useState } from 'react';
import SuggestionsListHeader from "components/suggestions-list/suggestions-list-header";
import intl from 'react-intl-universal'
import * as S from './styled'
import { getAllEstoqueATG } from '../../stocks-detail.service';
import { renderData } from './render';
import { depositoEspeciais, estoqueDisponiveis } from './utils';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from 'utils/handle-error'
import { usesReturnableAssets } from 'utils/validations-general-settings';

const EstoqueATG = ({ show, idFamiliaRotuladaCentro }) => {
  const [data, setData] = useState([])
  const [isItemExpanded, setIsItemExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChangeExpandedItem = () => setIsItemExpanded(!isItemExpanded);
  const { showFeedback } = useGlobalContext();

  const expandAllItems = () => {
    if (!isItemExpanded) {
      getData()
    }
    handleChangeExpandedItem()
  }

  const getData = async () => {
    setLoading(true);
    try {
      const _data = await getAllEstoqueATG(idFamiliaRotuladaCentro);
      setData(_data);
    }
    catch (err) {
      showFeedback(formatErrorMessage(err))
    }
    setLoading(false);
  }

  const renderContent = () => (
    data.length > 0 ?
      <React.Fragment>
        {renderData(data.filter(x => estoqueDisponiveis.includes(x.Deposito)), intl.get('commons.availableStock'))}
        {renderData(data.filter(x => depositoEspeciais.includes(x.Deposito)), intl.get('stocks.stockDetail.specialDeposits'))}
      </React.Fragment>
      :
      <S.MessageFeedback>{intl.get('commons.noRecordsFound')}</S.MessageFeedback>
  )

  return (
    <React.Fragment>
      {show && usesReturnableAssets() &&
        <S.Wrapper itemExpanded={isItemExpanded}>
          <SuggestionsListHeader
            isItem
            hasIcon
            isOrder={true}
            itemsExpanded={isItemExpanded}
            checked={false}
            expandAllItems={expandAllItems}
            hideMargin
          >
            <S.TitlesWrapper>
              <S.DepositoTitle>{intl.get('stocks.stockDetail.stockBreakdown')}</S.DepositoTitle>
            </S.TitlesWrapper>
          </SuggestionsListHeader>
          <S.ContentWrapper itemExpanded={isItemExpanded}>
            {loading ? <S.MessageFeedback>{intl.get('commons.loading')}</S.MessageFeedback>
              :
              renderContent()
            }
          </S.ContentWrapper>
        </S.Wrapper>
      }
    </React.Fragment>
  )
}

export default EstoqueATG;

import React, { useState } from 'react'
import ArrowBackIcn from 'images/arrow-back.svg'
import intl from 'react-intl-universal'
import DualList from '../../../gerencial/components/dual';
import Dialog from 'material-ui/Dialog/Dialog';
import { Button } from "@hbsis.uikit/react"
import * as S from './styled'
import { saveConfiguredColumn } from '../../utils/columnConfiguration';
import iconeMais from 'images/adicionar.svg';
import { getConfiguredColumnPrimaryMaterial } from '../../primary-material-schedule.service';

const HeaderCommands = ({ discardChanges, reloadTable }) => {
  const getColumnsVisible = () => getConfiguredColumnPrimaryMaterial().Visible;
  const getColumnsHidden = () => getConfiguredColumnPrimaryMaterial().Hidden;

  const [visibleColumns, setVisibleColumns] = useState(getColumnsVisible());
  const [hiddenColumns, setHiddenColumns] = useState(getColumnsHidden());
  const [openModal, setOpenModal] = useState(false);

  const handleHiddenColumns = col => {
    setHiddenColumns(prevCol => {
      prevCol.push(col);
      return prevCol;
    });
    setVisibleColumns(prevCol =>
      prevCol.filter(item => JSON.stringify(item) !== JSON.stringify(col))
    );
  }

  const handleVisibleColumns = (col) => {
    setVisibleColumns(prevCol => {
      prevCol.push(col);

      return prevCol;
    });
    setHiddenColumns(prevCol =>
      prevCol.filter(item => JSON.stringify(item) !== JSON.stringify(col))
    );
  }

  const salvaColunasSelecionadas = () => {
    const columns = {
      Hidden: hiddenColumns,
      Visible: visibleColumns
    }
    saveConfiguredColumn(columns);
    setOpenModal(false);
    reloadTable();
  };

  return (
    <React.Fragment>
      <Dialog
        contentStyle={{ width: "900px" }}
        open={openModal}
        title={intl.get("management.visibleColumns")}
        autoScrollBodyContent
        onRequestClose={() => setOpenModal(false)}
      >
        <DualList
          itemsList1={hiddenColumns}
          itemsList2={visibleColumns}
          onLinking={handleVisibleColumns}
          onUnlink={handleHiddenColumns}
          title1={intl.get("management.hidden")}
          title2={intl.get("management.visible")}
          checkbox={false}
        />
        <div>
          <Button
            width="100px"
            className="dual-list-button"
            type="primary"
            value={intl.get("geral.buttonsDefault.save")}
            disabled={visibleColumns.length === 0}
            onClick={salvaColunasSelecionadas}
          />
        </div>
      </Dialog>
      <S.WrapperButtons>
        <S.Button
          type="default"
          value={intl.get('management.columns')}
          icon={iconeMais}
          onClick={() => setOpenModal(true)}
        />
        <S.ButtonBack
          type="primary"
          value={intl.get('master-data.general.actions.back')}
          onClick={discardChanges}
          icon={ArrowBackIcn}
        />
      </S.WrapperButtons>
    </React.Fragment>
  )
}
export default HeaderCommands

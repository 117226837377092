import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PADDING_LEFT } from './../constants'

export const StyledRowOptimization = styled.div`
  width: 100%;
  padding-left: ${PADDING_LEFT}px;
  position: relative;
  box-shadow: inset 4px 0 #afcef3;

  :not(:last-child) {
    border-bottom: 1px dashed rgb(152, 193, 220);
  }

  :hover {
    box-shadow: inset 4px 0 #2870b2;
    background: #d9e8f2;
  }

  ${p =>
    p.isExpanded &&
    p.hasChildren &&
    `
      &:before {
        content: '';
        position: absolute;
        left: 12px;
        top: 8px;
        bottom: 0;
      }
    `} .etb-info {
    display: flex;
    box-sizing: border-box;
  }

  .etb-column:not(:last-child), .etb-label {
    border-right: 1px solid #dbdbdb;
  }
  
  .etb-image-alert {
    margin-right: 4px;
  }

  .etb-children {
    display: none;
    flex-direction: column;
    
    &.expanded {
      display: flex;
    }
  }
`;

StyledRowOptimization.propTypes = {
  isExpanded: PropTypes.bool,
  hasChildren: PropTypes.bool
}


import React from 'react'
import PropTypes from 'prop-types'
import { ReactMultiEmail } from 'react-multi-email';
import SelectStyled from './selectStyled'

const InputMultiEmail = ({ labelText, emails, ...rest }) => (
  <SelectStyled>
    <div className="Select Select--multi is-searchable has-value">
      {
        labelText &&
        <label className='label-style-input'>{labelText}</label>
      }
      <div className='Select-control' style={{ width: '100%' }}>
        <ReactMultiEmail
          underlineFocusStyle={{ borderColor: '#2870b2' }}
          emails={emails ? emails : []}
          getLabel={(email, index, removeEmail) => {
            return (
              email &&
              <div className='Select-value' data-tag key={index}>
                <span className='Select-value-icon' data-tag-handle onClick={() => removeEmail(index)}> × </span>
                {email}
              </div>
            )
          }
          }
          {...rest}
        />
      </div>
    </div>
  </SelectStyled>
)

InputMultiEmail.propTypes = {
  labelText: PropTypes.string,
  emails: PropTypes.object,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
}

export default InputMultiEmail

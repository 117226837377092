import React, { Component } from 'react'
import Paper from 'material-ui/Paper'
import { Link } from 'react-router-dom'
import ModalCompleteTask from '../../../../modal-complete-task/index'
import ModalArNotCreated from 'components/modal-task-ar-not-created'
import Card from './components/card'
import intl from 'react-intl-universal';
import Session from 'utils/user-storage'
import SnackBar from 'material-ui/Snackbar'
import Loading from 'components/center-loading'
import { completeTask } from './visao-tarefas-mester.service'
import { confirmCancellationAwaitingCheck } from 'pages/stocks-detail/stocks-detail.service'
import './../mester.css'
import { formatErrorMessage } from 'utils/handle-error';
import { obterUrlTarefa } from '../../../../../services/tarefas/gerador-url-tarefa'
import ActionsCard from '../../../components/actionsCard'
import ComposicaoCargaForm from '../../../../../pages/composicao-carga/form'
import { TIPOS_TAREFA } from '../../../../../pages/tasks-vision'

class VisaoTarefasMesterAmbevCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenModalCompleteTask: false,
      isOpenModalArNotCreated: false,
      isOpenModalDetailingCargoComposition: false,
      requestCount: 0,
      showFeedback: false,
      messageFeedback: '',
    };
  }

  completarTarefa = () => this.setState({ isOpenModalCompleteTask: true })

  handleRequestClose = () => this.setState({ isOpenModalCompleteTask: false })

  showFeedback = (message) => this.setState({ showFeedback: true, messageFeedback: message })

  closeFeedback = () => this.setState({ showFeedback: false })

  handleFetch = (isFetching = true) => this.setState((prevState) => ({ requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1 }))

  openModalArNotCreated = () => this.setState({ isOpenModalArNotCreated: true })

  closeModalArNotCreated = () => this.setState({ isOpenModalArNotCreated: false })

  openModalDetailingCargoComposition = () => this.setState({ isOpenModalDetailingCargoComposition: true })

  handleCompleteTask = async () => {
    try {
      this.handleFetch()
      await completeTask(this.props.dataCard.Id, Session.get().Id)
      this.showFeedback(intl.get('orders.feedback.taskCompletedSuccessfully'))
      await this.props.refreshCard()
      this.handleRequestClose()
    }
    catch (err) {
      this.showFeedback(err.response && err.response.data ? formatErrorMessage(err) : intl.get('commons.errorInTheSystem'))
    }
    finally {
      this.handleFetch(false)
    }
  }

  handleConfirmCancellationAwaitingCheck = async () => {
    try {
      this.handleFetch()
      await confirmCancellationAwaitingCheck(this.props.dataCard.IdOrigem)
      this.showFeedback(intl.get('orders.feedback.taskCompletedSuccessfully'))
      await this.props.refreshCard()
      this.handleRequestClose()
    }
    catch (err) {
      this.showFeedback(err.response && err.response.data ? formatErrorMessage(err) : intl.get('commons.errorInTheSystem'))
    }
    finally {
      this.handleFetch(false)
    }
  }

  handleDetailingCargoComposition = () => {
    try {
      this.handleFetch()
      this.setState({
        isOpenModalDetailingCargoComposition: true
      })
    }
    finally {
      this.handleFetch(false)
    }
  }

  handleCloseModalDetailingCargoComposition = async () => {
    await this.props.refreshCard()
    this.setState({
      isOpenModalDetailingCargoComposition: false
    })
  }

  generateCardTasks = () => {
    const task = this.props.dataCard;
    const user = Session.get();
    const url = obterUrlTarefa(task, user.TipoUsuario);
    switch (task.TipoTarefa) {
      case TIPOS_TAREFA.TAREFA_AR_NAO_CRIADO:
        return (
          <div className='card-ar' onClick={() => this.openModalArNotCreated()}>
            <Card data={task}/>
          </div>
        )
      case TIPOS_TAREFA.TAREFA_COMPOSICAO_CARGA_AGUARDANDO_REVISAO:
        return (
          <div className='card-tarefa-revisao-composicao-carga' onClick={() => this.openModalDetailingCargoComposition()}>
            <Card data={task}/>
          </div>
        )
      default:
        return (
          <Link to={url}>
            <Card data={task} />
          </Link>
        )
    }
  }

  render() {
    const task = this.props.dataCard;
    const user = Session.get();

    return (
      <div>
        <Loading isLoading={this.state.requestCount > 0} />
        <Paper zDepth={1} className='card-shop-mester' style={{ height: `250px` }}>
          {
            this.generateCardTasks()
          }
          {
            <ActionsCard
              task={task}
              completarTarefa={this.completarTarefa}
              handleCompleteTask={this.handleCompleteTask}
              handleConfirmCancellationAwaitingCheck={this.handleConfirmCancellationAwaitingCheck}
              handleDetailingCargoComposition={this.handleDetailingCargoComposition}
              user={user}
              startLoading={this.handleFetch}
              stopLoading={() => this.handleFetch(false)}
              refresh={this.props.refreshCard}
            />
          }
        </Paper>
        <ModalCompleteTask
          taskId={task.Id}
          open={this.state.isOpenModalCompleteTask}
          onCompleteTask={this.handleCompleteTask}
          onRequestClose={this.handleRequestClose}
        />

        <ModalArNotCreated
          tasks={task.TarefaPaymatchInformation}
          open={this.state.isOpenModalArNotCreated}
          close={() => this.closeModalArNotCreated()}
        />
        {this.state.isOpenModalDetailingCargoComposition &&
          <ComposicaoCargaForm
            idEdit={task.IdOrigem}
            idUser={user.Id}
            idPedido={0}
            open={this.state.isOpenModalDetailingCargoComposition}
            handleClose={this.handleCloseModalDetailingCargoComposition}
            modoLeitura={false}
          />
        }
        <SnackBar
          message={this.state.messageFeedback}
          open={this.state.showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

      </div>
    )
  }
}

export default VisaoTarefasMesterAmbevCard

import { isUseFilterDefault } from 'utils/user';

export const ordersFiltersDefault = {
  OrdersId: [],
  WmsTransactionsId: [],
  Status: [],
  SupplierIds: [],
  BusinessUnitIds: [],
  MaterialGroupIds: [],
  MaterialsNumber: [],
  CollectDateMin: '',
  CollectDateMax: '',
  DeliveryDateMin: '',
  DeliveryDateMax: '',
  PendingItems: false,
  UseFilterDefault: isUseFilterDefault(),
  _page: 0,
  _size: 50
}

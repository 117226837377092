import styled from "styled-components"

export const Wrapper = styled.div`
  a{
    text-decoration: initial;
  }

  .text-card{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .row-card{
      margin-top: 5px;
      width: calc(100% - 15px);
      padding-left: 10px;
      padding-right: 5px;
      gap: 5px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  }

  .text-card span:first-child {
      font-size: 11px;
      font-weight: 700;
      line-height: 1.82;
      color: #9aa4ad;
      width: 100%;
      text-transform: uppercase;
      white-space: nowrap;
    }

  .text-card span:last-child {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.07;
    color: #393939;
  }
`
export const StatusWrapper = styled.div`
  .status__column {
    margin-bottom: -3px;
  }
`

import React from 'react'
import intl from 'react-intl-universal'
import moment from "moment"
import * as S from '../../styled'
import SupplierInfos from '../../../supplier-negotiation-infos'
import ButtonCancelLoadComposition from '../../../../../../components/button-cancel-load-composition'

const ModalInfosNegotiationComposition = ({ composicao, handleClose, handleEditLoad, handleRefresh, handleFeedback }) => {
  const handleCallbackConfirmCancel = () => {
    handleRefresh();
    handleClose();
  }
  return (
    <S.InfosNegotiationWrapper>
      <S.Title>{intl.get('commons.negotiationLoadComposition')} - {composicao.IdComposicao}</S.Title>
      <S.InfosContentWrapper>
        <S.TitleWrapper>
          <S.InfosWrapper>
            <div style={{ display: 'flex' }}>
              <div>
                <div className='content'>
                  <span className='title'>{intl.get('stocks.timeline.origin')}</span>
                  <span className='description'>{composicao.Fornecedor}</span>
                </div>
                <div className='second-content'>
                  <span className='title'>{intl.get('modalRenegotiationLoadComposition.originalDeliveryDateTime')}</span>
                  <span className='description'>
                    {moment(composicao.DataEntrega).format("[DM]")}
                    {` ${intl.get('commons.at')} `}
                    {moment(composicao.HoraEntrega, "HH:mm:ss").format("LT")}
                  </span>
                </div>
              </div>
              <div>
                <div className='content content-right'>
                  <span className='title'>{intl.get('commons.destiny')}</span>
                  <span className='description'>{composicao.UnidadeNegocio}</span>
                </div>
              </div>
            </div>
          </S.InfosWrapper>
        </S.TitleWrapper>
        <SupplierInfos
          view
          composicao={composicao}
        />
      </S.InfosContentWrapper>
      <div className="footer-dialog-buttons">
        <S.ButtonCancel
          id='button-close'
          type="default"
          value={intl.get('geral.buttonsDefault.close')}
          className="button"
          onClick={handleClose}
        />
        <ButtonCancelLoadComposition
          idComposicao={composicao.IdComposicao}
          handleCallback={handleCallbackConfirmCancel}
          handleFeedback={handleFeedback}
        />
        <S.ButtonEdit
          id='button-renegociar-composicao-carga'
          type="primary"
          value={intl.get("loadComposition.editLoadComposition")}
          className="button"
          onClick={handleEditLoad}
        />
      </div>
    </S.InfosNegotiationWrapper>
  )
}

export default ModalInfosNegotiationComposition;

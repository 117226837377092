import React, {Component} from 'react'
import PropTypes  from 'prop-types'
import ShopSuggestionMesterAmbevCard from './ambev'
import ShopSuggestionMesterSupplierCard from './supplier'

import './mester.css'

class ShopSuggestionMesterCard extends Component {
  render () {
    return (
      <div style={{height: '100%'}}>
        {this.props.userCard === 'AMBEV' && <ShopSuggestionMesterAmbevCard dataCard={this.props.dataCard} filters={this.props.filters} />}
        {this.props.userCard === 'SUPPLIER' && <ShopSuggestionMesterSupplierCard dataCard={this.props.dataCard} />}
      </div>
    )
  }
}

ShopSuggestionMesterCard.propTypes = {
  userCard: PropTypes.string.isRequired
}

export default ShopSuggestionMesterCard

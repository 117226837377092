import React, { useEffect, useState } from 'react'
import AmChart from 'components/chart'
import { SelectBox } from 'components/uikit-adapter'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getAllLinkedBreweryOnExternalWarehouse, getAllLinkedExternalWarehouseOnBrewery, searchChartData } from 'pages/stocks-detail/stocks-detail.service'
import { getLabelTitle, isBrewery, isExternalWarehouse, mountChartData, getButtonSVG } from './utils'
import * as S from './styled'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { formatErrorMessage } from 'utils/handle-error'

export const ExternalWarehouseBreweryCharts = ({ unitType, businessUnitId, labeledFamilyId, filters, wasUpdateGraph }) => {
  const [searchableData, setSearchableData] = useState([])
  const [selectedData, setSelectedData] = useState(undefined)
  const [chartData, setChartData] = useState(undefined)
  const [isLoadingChartData, setIsLoadingChartData] = useState(false)
  const { showFeedback } = useGlobalContext()

  const isComponentVisible = () => (isExternalWarehouse(unitType) || isBrewery(unitType))
    && searchableData.length > 0

  const getData = async () => {
    try {
      const query = {
        businessUnitId,
        labeledFamilyId
      }

      const data = isExternalWarehouse(unitType) ?
        await getAllLinkedBreweryOnExternalWarehouse(query) :
        await getAllLinkedExternalWarehouseOnBrewery(query)

      setSearchableData(data)
    } catch (error) {
      console.log(error)
    }
  }

  const getChartData = async () => {
    setIsLoadingChartData(true)

    try {
      const data = await searchChartData(selectedData.LabeledFamilyCenterId, filters)
      const _data = mountChartData(data)
      setChartData(_data)

    } catch (error) {
      showFeedback(formatErrorMessage(error))
    }
    setIsLoadingChartData(false)

  }

  const renderChart = () => (
    (selectedData && chartData) && <AmChart
      data={chartData?.Details}
      categoryField={"Categoria"}
      guides={chartData?.guides}
      estoqueVariavel
      transfEntrada
      transfSaida
      pedidoVolume
    />
  )

  useEffect(() => {
    if (selectedData) {
      getChartData()
    }
  }, [selectedData, filters, wasUpdateGraph])

  useEffect(() => {
    getData()
  }, [])

  return (
    isComponentVisible() ?
      <S.Wrapper>
        <S.Title>{getLabelTitle(unitType)}</S.Title>
        <S.Content>
          <S.Input>
            <SelectBox
              dontShowLabel
              label={'doubleChart'}
              clearable
              searchable
              options={searchableData}
              valueKey={'BusinessUnitId'}
              labelKey={'Name'}
              value={selectedData}
              onChange={(value) => setSelectedData(value)}
            />
          </S.Input>
          <S.Button onClick={() => selectedData && window.open(`/stocksDetail/${selectedData.LabeledFamilyCenterId}`)}>
            <S.Icon>
              <S.Image alt='image' src={getButtonSVG(unitType)} />
            </S.Icon>
          </S.Button>
        </S.Content>
        {
          isLoadingChartData ?
            <div className='circular-progress'>
              <CircularProgress style={{ color: '#477dc0' }} />
            </div>
            :
            renderChart()
        }
      </ S.Wrapper>
      : null
  )
}
import React from "react";
import {
    Button as ButtonExterno
} from "@hbsis.uikit/react";

import { IconFilterGray, AddNewIcon } from '../../icons/icn-index.icon'

import StyledButton from './styledButton'

const Button = ({...rest }) => {
    return (
        <ButtonExterno
            {...rest}
        >
        </ButtonExterno>
    )
}
export { Button }

const ButtonOnlyIcon = ({ value, icon, width, ...rest }) => {
    return (
        <StyledButton className="button-only-icon">
            <ButtonExterno width={ width ? width : "45px" } {...rest}>
                {icon}
            </ButtonExterno>
        </StyledButton>
    )
}
export { ButtonOnlyIcon }

const ButtonFilter = ({ value, type, icon, active, ...rest }) => {
    return (
        <StyledButton>
            <div className="button-filter">
                <ButtonExterno
                    type='default'
                    {...rest}>
                    <div className="button-value">
                        {<IconFilterGray width={"30px"} height={"30px"} color={active ? "#55c9d8" : "#95A3B7"} />}
                        <div className="button-text">{"Filtro"}</div>
                    </div>
                </ButtonExterno>
            </div>
        </StyledButton >
    )
}
export { ButtonFilter }

const ButtonNewItem = ({ ...rest }) => {
    return (
        <StyledButton >
            <div className="button-only-icon">
                <ButtonOnlyIcon
                    type='default'
                    icon={<AddNewIcon width={"35px"} height={"35px"} />}
                    {...rest}
                />
            </div>
        </StyledButton>
    )
}
export { ButtonNewItem }

import styled from 'styled-components'
import Dialog from 'material-ui/Dialog'


export const ImportExportModal = styled(Dialog)`
  .import-export__modal {
    overflow-y: visible !important;
  }
`

export const StyledImportExport = styled.div`
  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 220px;
    padding-left: 7px;
    padding-right: 7px;

    .titulo {
      width: 95%;
      text-align: center;
      margin-top: 0px;
      margin-bottom: 15px;
    }

    .last-titulo {
      margin-bottom: 50px;
    }

    .file-ajust {
      width: 89%;
    }
  }

  .dJOgQU {
    height:100%;
  }

  .button-download-content {
    position: absolute;
    bottom: 10px;
  }

  .button-download {
    width: 140px;
  }

  .button-footer {
    width: 80px;
  }

  .content-footer{
    margin-top: 7px;
    display: flex;
    justify-content: space-between;
  }

  .footer-right {
    display: flex;
    justify-content: align-itens;
  }

  .btn-dowload-logs {
    width: 80%;
  }

  .btn-next {
    margin-left: 10px;
  }

  .lista-resultados{
    width: 100%;
  }

  .lista-mensagens {
    max-height: 170px;
    overflow-y: auto;
  }

  .itens-mensagens {
    width: 100%;
  }

  .rowListHeader {
    color: #3b495e;
    font-weight: 600;
    padding: 5px 0 7px 0;
    border-bottom: 1px dashed #c5d0e1;
    padding-left: 4px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
  }

  .rowList {
    background: #FFFFFF;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    border-bottom: 1px dashed #c5d0e1;

    &:hover {
      background: #f4f5f8;
    }
  }

  .green {
    box-shadow: inset 4px 0 #81aa4c;

    &:hover {
      box-shadow: inset 4px 0 #81aa4c;
    }
  }

  .yellow {
    box-shadow: inset 4px 0 #e8b007;

    &:hover {
      box-shadow: inset 4px 0 #e8b007;
    }
  }

  .red {
    box-shadow: inset 4px 0 #ec393d;

    &:hover {
      box-shadow: inset 4px 0 #ec393d;
    }
  }

  .link {
    color: #2870B2;
    cursor: pointer;
  }

  .link:hover {
    color: #1E5281;
    cursor: pointer;
  }
`

import React from 'react'
import IconLabelRight from 'components/icon-label-right'
import pedidoPacoteIcon from 'images/pedido-pacote-yellow.png'
import * as S from './styled'

export const getStage = (name, isAlert) => (
  <S.Container>
    <OrderVolumeListStage label={name} isAlert={isAlert} />
  </S.Container>
)

const OrderVolumeListStage = ({ label, isAlert }) => (
  <React.Fragment>
    {
      isAlert &&
      <S.AlertIcon>
        !
      </S.AlertIcon>
    }
    <IconLabelRight
      src={pedidoPacoteIcon}
      label={label}
      classNameDivImage='yellow-background'
      className={'yellow-border'}
    />
  </React.Fragment>
)

export default OrderVolumeListStage

import Fetch from "utils/fetch";

export const ObterUnidadeNegocioComGarrafa = () => {
  return Fetch.get(`unidadeNegocio/obterUnidadeNegocioComGarrafa`).then(({ data }) => data)
}

export const ObterTodasComGarrafa = () => {
  return Fetch.get(`familiaRotulada/obterTodasComGarrafa`).then(({ data }) => data)
}


import React from 'react'
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
    TableFooter
} from 'components/uikit-adapter/index'
import PaginationFooter from 'components/pagination/components/pagination-footer'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal';

const CriticalItemsList = ({ data, paginationControl, countData, search, handleEdit }) => (
    <Table
        fixedHeader
        fixedFooter
        multiSelectable={false}
        selectable={false}
        wrapperStyle={{ height: '100%' }}
        bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: '#FFFFFF' }}
        onCellClick={handleEdit}
    >
        <TableHeader
            displaySelectAll={false}
            adjustForCheckbox={false}
            style={{ borderBottom: 'none' }}
        >
            <TableRow style={{ borderBottom: 'none' }}>
                <TableHeaderColumn style={{ width: '80%' }}>{intl.get('master-data.configurations.critical-items.table-header.families')}</TableHeaderColumn>
                <TableHeaderColumn style={{ width: '20%' }}>{intl.get('master-data.configurations.critical-items.table-header.situation')}</TableHeaderColumn>
            </TableRow>
        </TableHeader>
        <TableBody
            displayRowCheckbox={false}
            showRowHover
        >
            {data.map((v, i) => (
                <TableRow key={i} style={{ cursor: 'pointer' }}>
                    <TableRowColumn style={{ width: '80%' }}>
                        {v.Nome}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: '20%' }}>
                        {v.RelevanciaItensCriticos ? intl.get('master-data.configurations.critical-items.situation.enabled') : intl.get('master-data.configurations.critical-items.situation.disabled')}
                    </TableRowColumn>
                </TableRow>
            ))}
        </TableBody>
        <TableFooter>
            <PaginationFooter
                countPerPage={paginationControl.quantityPerPage}
                countData={countData}
                refreshSearch={search}
            />
        </TableFooter>
    </Table>
)

CriticalItemsList.propTypes = {
    data: PropTypes.array.isRequired,
    paginationControl: PropTypes.object.isRequired,
    countData: PropTypes.number.isRequired,
    search: PropTypes.func.isRequired,
    handleEdit: PropTypes.func.isRequired
}

export default CriticalItemsList;
import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import HintList from 'components/hint-list'
import MapToHTML from 'components/map-to-html'
import moment from 'moment'
import intl from 'react-intl-universal'
import Pagination from 'components/pagination'
import StyledComposicaoList from './styledComposicaoList'
import { formatNumber } from 'utils/format'
import { getObservationLabel, getObservationValue } from 'pages/load-composition/utils/observation'

const ComposicaoLista = ({ data, handleEdit, refreshSearch, page, take, totalComposicoes }) => (
  <StyledComposicaoList>
    <Row className="rowListHeader">
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.composition')}</Col>
      <Col xs={2} md={2} lg={2} className="align-center">{intl.get('transports.compoCharge.requestBuySAP')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.colDate')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.deliveryDate')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('transports.compoCharge.qtdRequestPortal')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.qtdTotal')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.source')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('master-data.general-configuration.center')}</Col>
      <Col xs={2} md={2} lg={2} className="align-center">{getObservationLabel()}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('commons.situation')}</Col>
    </Row>
    <Row className="containerListaComposicoes">
      <Col xs={12} md={12} lg={12} className="listNotasFiscais pt-0 pb-0">
        {
          (!data || data.length === 0) &&
          <Row className="rowList">
            <Col xs={12} md={12} lg={12} className="align-center">
              <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
            </Col>
          </Row>
        }
        {
          data && !!data.length && data.map((composicao, i) => (
            <Row
              key={i}
              className="rowList"
              onClick={() => handleEdit(composicao)}
              style={{ cursor: 'pointer', borderBottom: '1px dashed #98c1dc', backgroundColor: `#${composicao.ResponsavelProximaAcao ? 'e2f3fe' : 'ffffff'}` }}
            >
              <Col xs={1} md={1} lg={1} className="align-center">{composicao.IdComposicao}</Col>
              <Col xs={2} md={2} lg={2} className="align-center hintList">
                <HintList itens={composicao.PedidosSAP} label={`${composicao.PedidosSAP.length} ${intl.get("menu.menuGeneral.orders")}`}>
                  <MapToHTML itens={composicao.PedidosSAP} />
                </HintList>
              </Col>
              <Col xs={1} md={1} lg={1} className="align-center">{composicao.DataColeta ? moment(composicao.DataColeta).format('L') : '-'}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">{moment(composicao.DataEntrega).format('L')}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">{composicao.QuantidadePedidos}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">
                {composicao.QuantidadeTotal ? formatNumber(composicao.QuantidadeTotal) : '-'}
              </Col>
              <Col xs={1} md={1} lg={1} style={{ width: '10%' }} title={composicao.Fornecedor} className="format-input-space">{composicao.Fornecedor}</Col>
              <Col xs={1} md={1} lg={1} title={`${composicao.Centro} - ${composicao.UnidadeNegocio}`} className="align-center">{`${composicao.Centro} - ${composicao.UnidadeNegocio}`}</Col>
              <Col xs={2} md={2} lg={2} title={getObservationValue(composicao.BreweryNote, composicao.Observacao)} className="format-input-space">{getObservationValue(composicao.BreweryNote, composicao.Observacao)}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">{composicao.Situacao}</Col>
            </Row>
          ))
        }
      </Col>
    </Row>
    <Row className="pagination">
      <Pagination
        page={page}
        pageSize={take}
        amount={totalComposicoes}
        refreshSearch={refreshSearch}
      />
    </Row>
  </StyledComposicaoList>
)

ComposicaoLista.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    DataEntrega: PropTypes.string.isRequired,
    QuantidadePedidos: PropTypes.number.isRequired,
    Fornecedor: PropTypes.string.isRequired,
    Centro: PropTypes.string.isRequired,
    PedidosSAP: PropTypes.arrayOf(PropTypes.string),
    QuantidadeTotal: PropTypes.number,
    DataColeta: PropTypes.string,
    UnidadeNegocio: PropTypes.string.isRequired,
    Observacao: PropTypes.string.isRequired
  })).isRequired,
  handleEdit: PropTypes.func.isRequired,
  refreshSearch: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  take: PropTypes.number.isRequired,
  totalComposicoes: PropTypes.number.isRequired,
}

export default ComposicaoLista

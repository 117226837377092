import ColorPickers from '../../../../../../../colorpickers/index'
import pedidoPacoteIcon from 'images/pedido-pacote.png'
import icnPedidoUrgente from 'images/icn-pedido-urgente.png'
import icnRenegociacao from 'images/icn-renegociacao.svg'
import icnFuroColetaEntrega from 'images/icn-furo-coleta-entrega.png'
import icnCobrarRetorno from 'images/icn-cobrar-retorno.png'
import icnAguardandoDescarga from 'images/icn-aguardando-descarga.png'
import icnPedidoEmOver from 'images/icn-locked.svg'
import icnAguardandoCiencia from 'images/icn-aguardando-ciencia.svg'
import icnArNaoCriado from 'images/ar-not-created.svg'
import icnCancelWhite from 'images/cancel-white.svg'
import icnLoadCompositionToBeReviewed from 'images/icn-load-composition-to-be-reviewed.svg'

import { TIPOS_TAREFA } from 'pages/tasks-vision/index'

export const montarCorStatus = (TipoTarefa) => {
  switch (TipoTarefa) {
    case TIPOS_TAREFA.TAREFA_INCLUSAO_PEDIDO_URGENTE:
      return ColorPickers.cardLaranjaMedio

    case TIPOS_TAREFA.TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA_RECUSADA:
      return ColorPickers.cardLaranjaForte

    case TIPOS_TAREFA.TAREFA_ESTOQUE_EM_OVER:
    case TIPOS_TAREFA.TAREFA_PEDIDO_EM_OVER:
    case TIPOS_TAREFA.TAREFA_AGUARDANDO_CIENCIA_PEDIDO_OVER_RECUSADO:
      return ColorPickers.cardAmareloVivo

    case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_NEGOCIACAO:
    case TIPOS_TAREFA.TAREFA_COMPOSICAO_CARGA_AGUARDANDO_REVISAO:
      return ColorPickers.cardAmarelo

    case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_DESCARGA:
      return ColorPickers.cardAzul

    case TIPOS_TAREFA.TAREFA_FURO_COLETA:
    case TIPOS_TAREFA.TAREFA_FURO_ENTREGA:
      return ColorPickers.cardLilas

    case TIPOS_TAREFA.TAREFA_COBRANCA_ACEITE_FORNECEDOR:
    case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_FORNECEDOR:
    case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_AMBEV:
      return ColorPickers.cardMarron

    case TIPOS_TAREFA.TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA:
      return ColorPickers.cardAzulEscuro;

    case TIPOS_TAREFA.TAREFA_AR_NAO_CRIADO:
    case TIPOS_TAREFA.TAREFA_CANCELAMENTO_AGUARDANDO_CIENCIA:
    case TIPOS_TAREFA.TAREFA_CANCELAMENTO_RECUSADO_AGUARDANDO_CIENCIA:
    case TIPOS_TAREFA.TAREFA_AGUARDANDO_ANALISE_CANCELAMENTO:
    case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_RENEGOCIACAO_EMERGENCIAL:
      return ColorPickers.cardVermelhoAr;

    default:
      return ColorPickers.cardAzulPetroleo
  }
}

export const montarIconeTarefa = (TipoTarefa) => {

  switch (TipoTarefa) {
    case TIPOS_TAREFA.TAREFA_INCLUSAO_PEDIDO_URGENTE:
      return icnPedidoUrgente

    case TIPOS_TAREFA.TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA_RECUSADA:
      return icnRenegociacao

    case TIPOS_TAREFA.TAREFA_AGUARDANDO_CIENCIA_PEDIDO_OVER_RECUSADO:
      return icnAguardandoCiencia

    case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_DESCARGA:
    case TIPOS_TAREFA.TAREFA_RENEGOCIACAO_EMERGENCIAL_DATA:
      return icnAguardandoDescarga

    case TIPOS_TAREFA.TAREFA_FURO_COLETA:
    case TIPOS_TAREFA.TAREFA_FURO_ENTREGA:
      return icnFuroColetaEntrega

    case TIPOS_TAREFA.TAREFA_COBRANCA_ACEITE_FORNECEDOR:
    case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_FORNECEDOR:
    case TIPOS_TAREFA.TAREFA_PEDIDO_AGUARDANDO_COMPOSICAO_CARGA_AMBEV:
      return icnCobrarRetorno

    case TIPOS_TAREFA.TAREFA_ESTOQUE_EM_OVER:
    case TIPOS_TAREFA.TAREFA_PEDIDO_EM_OVER:
      return icnPedidoEmOver

    case TIPOS_TAREFA.TAREFA_AR_NAO_CRIADO:
      return icnArNaoCriado

    case TIPOS_TAREFA.TAREFA_CANCELAMENTO_AGUARDANDO_CIENCIA:
    case TIPOS_TAREFA.TAREFA_CANCELAMENTO_RECUSADO_AGUARDANDO_CIENCIA:
    case TIPOS_TAREFA.TAREFA_AGUARDANDO_ANALISE_CANCELAMENTO:
      return icnCancelWhite

    case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_NEGOCIACAO:
    case TIPOS_TAREFA.TAREFA_COMPOSICAO_CARGA_AGUARDANDO_REVISAO:
    case TIPOS_TAREFA.TAREFA_COMPOSICAO_EM_RENEGOCIACAO_EMERGENCIAL:
      return icnLoadCompositionToBeReviewed

    default:
      return pedidoPacoteIcon
  }
}

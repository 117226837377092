import React, { useEffect, useState } from 'react'
import * as S from './styled'
import Filters from 'components/filter';
import { useSuggestionContext } from '../../context/suggestionsContext';
import { filtersSuggestionDefault } from '../../utils';
import { formatErrorMessage } from '../../../../utils/handle-error';
import { dateRangeFiltersInitial, inputFiltersDefault, selectsFiltersDefault, verifyFilter } from '../../utils/filters';
import * as serviceSuggestions from '../../../../services/suggestion/suggestion.service';
import { isUseFilterDefault } from 'utils/user';
import { searchBusinessUnit, searchMaterialGroup, searchMaterials, searchSuppliers } from 'services/filters.service';
import StorePersist from 'utils/store-persist';

const FilterSuggestion = ({ handleClose, startLoading, stopLoading }) => {
  const { fetchSuggestions } = useSuggestionContext();
  const [selectsFilters, setSelectsFilters] = useState(selectsFiltersDefault());
  const [filtersDefault, setFiltersDefault] = useState(filtersSuggestionDefault);

  const proccessData = (filtersData) => {
    const _filters = selectsFilters.map(filter => ({
      ...filter,
      options: filtersData.find(x => x.name === filter.name).data,
    }));

    setSelectsFilters([..._filters]);
  }

  const loadInitialData = async () => {
    try {
      const myItemsOnly = localStorage.getItem("filterMyItemsOnly")

      const [status, units, suppliers, materiais, materialGroup] = await Promise.all([
        serviceSuggestions.fetchStatusSuggestion(),
        searchBusinessUnit(myItemsOnly),
        searchSuppliers(myItemsOnly),
        searchMaterials(myItemsOnly),
        searchMaterialGroup(myItemsOnly)
      ]);

      const materialFormat = materiais.map((_) => ({ ..._, Id: _.MaterialNumber }))

      const filtersData = [
        { name: 'Status', data: status },
        { name: 'SupplierIds', data: suppliers },
        { name: 'BusinessUnitIds', data: units },
        { name: 'MaterialNumber', data: materialFormat },
        { name: 'MaterialGroupIds', data: materialGroup }
      ];

      proccessData(filtersData);
          
    const filtersSuggestionsMulti= StorePersist.getValuesJSON("filtersSuggestionsMulti");  
    if(filtersSuggestionsMulti !== filtersDefault) {
      const _filters = {
        BusinessUnitIds: units.filter(x => filtersSuggestionsMulti.BusinessUnitIds.includes(x.Id)),
        SupplierIds: suppliers.filter(x => filtersSuggestionsMulti.SupplierIds.includes(x.Id)),
        MaterialNumber: materialFormat.filter(x => filtersSuggestionsMulti.MaterialNumber.includes(x.Id)),
        MaterialGroupIds: materialGroup.filter(x => filtersSuggestionsMulti.MaterialGroupIds.includes(x.Id)),
        Status: status.filter(x => filtersSuggestionsMulti.Status.includes(x.Id)),
        UseFilterDefault: filtersSuggestionsMulti.UseFilterDefault,
        DeliveryDateMax: filtersSuggestionsMulti.DeliveryDateMax,
        DeliveryDateMin: filtersSuggestionsMulti.DeliveryDateMin,
        IsFiltered: filtersSuggestionsMulti.IsFiltered,
        _page: filtersSuggestionsMulti._page,
        _size: filtersSuggestionsMulti._size
      }
      setFiltersDefault(_filters);
    }
    } catch (e) {
      proccessErro(e);
    }
  }

  const proccessErro = (e) => {
    console.error(formatErrorMessage(e));
  }

  const handleSearch = async (filters) => {
    const _newFilters = { ...filters, IsFiltered: verifyFilter(filters)}
    handleClose();
    startLoading();
    StorePersist.setValuesJSON("filtersSuggestionsMulti", _newFilters);
    await fetchSuggestions(_newFilters);
    stopLoading();
  }

  const handleClickSearch = (filters) => {
    console.log(filters)
    const _filters = {
      _page: filters._page,
      _size: filters._size,
      SupplierIds: filters.SupplierIds?.map(x => x.Id),
      MaterialNumber: filters.MaterialNumber?.map(x => x.MaterialNumber),
      BusinessUnitIds: filters.BusinessUnitIds?.map(x => x.Id),
      MaterialGroupIds: filters.MaterialGroupIds?.map(x => x.Id),
      Status: filters.Status?.map(x => x.Id),
      IsFiltered: true,
      UseFilterDefault: isUseFilterDefault(),
      DeliveryDateMin: filters.DeliveryDateMin,
      DeliveryDateMax: filters.DeliveryDateMax
    }

    handleSearch(_filters)
  }

  useEffect(() => {
    loadInitialData()
  }, [])

  return (
    <S.Wrapper>
      <Filters
        handleClear={() => handleSearch(filtersSuggestionDefault)}
        handleSearch={handleClickSearch}
        selectsFilters={selectsFilters}
        filtersDefault={filtersDefault}
        inputFilters={inputFiltersDefault()}
        dateRangeFilters={dateRangeFiltersInitial()}
      />
    </S.Wrapper>
  )
}

export default FilterSuggestion;

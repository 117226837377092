import React, { useEffect, useState } from 'react'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import { SelectBox, Button } from 'components/uikit-adapter/index'
import { Row, Col } from 'react-flexbox-grid'
import IMOManagementList from './list'
import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error.js'
import SnackBar from 'material-ui/Snackbar'
import {
  GestaoIMOIcon,
  FactoryIcon
} from 'components/icons/icn-index.icon'
import { downloadFile } from 'utils/custom-functions';
import IconImobilizado from 'images/icn-imo-export-import.svg'
import { isValidFields } from './validate'
import ImportExport from 'components/import-export'
import moment from 'moment';
import { searchBusinessUnit } from '../../unit-management/grade/component/grade-content/grade-content.service'
import { fetchImo, saveImo, getImobilizadoDownloadFile } from './imo-management.service'

import * as S from './styled'

const IMOManagement = () => {
  const [openImportExportImobilizados, setOpenImportExportImobilizados] = useState(false);
  const [imoData, setImoData] = useState([]);
  const [businessUnit, setBusinessUnit] = useState([]);
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(0);
  const [feedback, setFeedback] = useState({
    messageFeedback: "",
    showFeedback: false
  });
  const [isFetching, setIsFetching] = useState(false);

  const save = async () => {
    if (!isValidFields(imoData, openFeedback)) {
      return
    }
    try {
      startFetching();
      await saveImo(imoData);
      openFeedback(intl.get('master-data.feedback.savedSuccessfully'));
    }
    catch (e) {
      processError(e)
    }
    finally {
      stopFetching();
      getImoData();
    }
  }

  const onChangeBusinnesUnit = value => {
    setSelectedBusinessUnit(value);
  }

  const processError = e => {
    const errorMessage = formatErrorMessage(e);
    openFeedback(errorMessage);
  }

  const getBusinessUnit = async () => {
    try {
      const data = await searchBusinessUnit();
      setBusinessUnit(data)
    }
    catch (e) {
      processError(e)
    }
  }

  const getImoData = async () => {
    try {
      setImoData([]);
      startFetching();
      const data = await fetchImo(selectedBusinessUnit);
      setImoData([...data]);
    }
    catch (e) {
      processError(e)
    }
    finally {
      stopFetching();
    }
  }

  const initalConfigs = async () => {
    await getBusinessUnit();
  }

  const onChangeField = (value, index, field) => {
    const newImoData = imoData;
    newImoData[index][field] = value;

    if (field === 'CodigoImobilizado' && value.length === 10) {
      newImoData[index]['type'] = 'CodigoImobilizado';
    }
    setImoData([...newImoData]);
  }

  const startFetching = () => {
    setIsFetching(true);
  }

  const stopFetching = () => {
    setIsFetching(false);
  }

  const openFeedback = message => {
    setFeedback({
      messageFeedback: message,
      showFeedback: true
    });
  }

  const closeFeedback = () => {
    setFeedback({
      messageFeedback: "",
      showFeedback: false
    });
  }

  useEffect(() => {
    initalConfigs();
  }, [])

  useEffect(() => {
    businessUnit[0] && (
      setSelectedBusinessUnit(businessUnit[0].IdUnidadeNegocio)
    )
  }, [businessUnit])

  useEffect(() => {
    selectedBusinessUnit > 0 && (
      getImoData()
    )
  }, [selectedBusinessUnit])

  const exportarImobilizados = () => {
    setOpenImportExportImobilizados(true);
  }

  const closeImportExportImobilizados = () => {
    setOpenImportExportImobilizados(false);
  }

  const downloadArquivoImobilizados = async () => {
    startFetching()
    try {
      const data = await getImobilizadoDownloadFile();
      const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
      downloadFile(`${datafile}_Imobilizados.csv`, 'csv', data)
    } catch (err) {
      processError(err)
    } finally {
      stopFetching();
    }
  }

  return (
    <div>
      <Loading isLoading={isFetching} />
      <Row style={{ height: '80px' }}>
        <Col xs={6}>
          <HeaderIcon
            title={intl.get('master-data.configurations.imo-management.headers.title')}
            subtitle={intl.get('master-data.configurations.imo-management.headers.subtitle')}
            icon={<GestaoIMOIcon width='45px' height='45px' color='#1d388f' />}
          >
          </HeaderIcon>
        </Col>

        <Col xs={6} className="head-buttons-filter">
          <S.BusinessUnitWrapper>
            <Button
              onClick={exportarImobilizados}
              type='default'
              value={intl.get('master-data.general.imo.title-button-download')}
              icon={IconImobilizado}
              className={"button-img-estoque"}
            />
            <div className='bussinesUnit'>
              <FactoryIcon
                width='33px'
                height='33px'
                className='icon-unit'
              />
              <div className='input-unit'>
                <SelectBox
                  required
                  name='IdUnidadeNegocio'
                  label={intl.get('manual-suggestion-order-form.unit')}
                  valueKey='IdUnidadeNegocio'
                  labelKey='Descricao'
                  value={selectedBusinessUnit}
                  options={businessUnit}
                  onChange={value => onChangeBusinnesUnit(value.IdUnidadeNegocio)}
                  className='select-unidade-negocio'
                  searchable
                  openOnFocus
                />
              </div>
            </div>
          </S.BusinessUnitWrapper>
        </Col>
      </Row>

      {imoData.length > 0 ?
        <React.Fragment>
          <Row>
            <Col xs={12}>
              <IMOManagementList
                data={imoData}
                onChangeField={onChangeField}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} style={{ textAlign: '-webkit-right' }}>
              <S.Button
                data-testid='button-save'
                onClick={save}
                type='primary'
                value={intl.get('geral.buttonsDefault.save')}
                style={{ width: '150px' }}
              />
            </Col>
          </Row>
        </React.Fragment>
        :
        <S.InitialWrapper>
          {intl.get('commons.noRecordsFound')}
        </S.InitialWrapper>
      }
      <SnackBar
        message={feedback.messageFeedback}
        open={feedback.showFeedback}
        autoHideDuration={3000}
        onRequestClose={closeFeedback}
      />
      <ImportExport
        title={intl.get('exportImportModal.imo.title')}
        contextModal='imo'
        open={openImportExportImobilizados}
        handleClose={closeImportExportImobilizados}
        downloadArquivo={downloadArquivoImobilizados}
        importArquivo='imobilizado/import'
        type="CSV"
        noMessageExample
      />
    </div>
  )
}

export default IMOManagement;

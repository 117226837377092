import React from 'react';
import { Container, Banner, Content, Button, TextButton } from "./404.styled";
import ambevHbsisLogo from '../../images/login/ambevHbsis.png';
import abOntimeLogo from '../../images/abOntimeLogo.png'
import slogan from '../../images/login/slogan.png';
import { authProvider } from '../../providers/authProvider';
import intl from 'react-intl-universal'

const ErrorPage = () => {

  return (
    <Container>
      <Banner>
        <img src={abOntimeLogo} id='ontime-logo'></img>
        <img src={slogan} id='slogan'></img>
        <img src={ambevHbsisLogo} id='ambevHbsis-logo'></img>
      </Banner>
      <Content>
        <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'center', margin: '2%', textAlign: 'center' }}>
          {intl.get('errorPage.title')}
          <Button
            className='logout__button'
            style={{ width: '50%' }}
            type='button'
            onClick={() => authProvider().logout()}>
            <TextButton>{intl.get("errorPage.button")}
            </TextButton>
          </Button>
        </div>
      </Content>
    </Container >
  )
}

export default ErrorPage

import React from "react"
import intl from 'react-intl-universal'
import moment from "moment"
import * as S from '../../styled'
import PedidosAprovados from "../../../../../composicao-carga/pedidos-aprovados"
import { Row, Col } from 'react-flexbox-grid';
import { Input } from '@hbsis.uikit/react';
import DateInput from 'components/date-input';
import Loading from "components/center-loading";
import { SelectBox } from "components/uikit-adapter"
import { showDeliveryTime } from "utils/delivery-time"
import { getObservationLabelAbbreviated, getObservationValue } from "pages/load-composition/utils/observation"

const ModalInfosComposicaoCarga = ({
  unidadeNegocio,
  fornecedor,
  pedidos,
  isLoading,
  composicao,
  redirectRoute }) => (
  <S.InfosCompositionWrapper>
    <Row>
      <Col xs={12}>
        <S.SecondTitle>
          {`${intl.get('menu.subMenuGeneral.loadComposition')}: ${composicao.IdComposicao}`}
        </S.SecondTitle>
      </Col>
    </Row>
    <Row className="row-wrapper">
      <Col className="col-select-box">
        <SelectBox
          disabled
          name='unidadeNegocio'
          label={intl.get('commons.destiny')}
          valueKey='Id'
          labelKey='Descricao'
          options={unidadeNegocio}
          value={unidadeNegocio[0]}
        />
      </Col>
      <Col className="col-select-box">
        <SelectBox
          disabled
          name='fornecedor'
          label={intl.get('transports.compoCharge.actions.unityBussines')}
          valueKey='Id'
          labelKey='Descricao'
          options={fornecedor}
          value={fornecedor[0]}
        />
      </Col>
      <Col className="col-date">
        <DateInput
          disabled
          label={intl.get('commons.colDate')}
          value={composicao.DataColeta}
          minDate={moment().format("YYYY-MM-DD")}
          maxDate="9999-12-31"
        />
      </Col>
      <Col className="col-date">
        <DateInput
          disabled
          label={intl.get('commons.deliveryDate')}
          value={composicao.DataEntrega}
          minDate={moment().format("YYYY-MM-DD")}
          maxDate="9999-12-31"
        />
      </Col>
      {
        showDeliveryTime(composicao.HoraEntrega) &&
        <Col className='col-time' style={{ paddingLeft: '2px', paddingRight: '2px' }}>
          <Input
            disabled
            label={intl.get('commons.deliveryTime')}
            name='HoraEntrega'
            htmlType='time'
            value={composicao.HoraEntrega}
          />
        </Col>
      }
      <Col className="col-observation">
        <Input
          disabled
          name="txbObservacao"
          label={getObservationLabelAbbreviated()}
          title={getObservationValue(composicao.BreweryNote, composicao.Observacao)}
          value={getObservationValue(composicao.BreweryNote, composicao.Observacao)}
          width="90%"
          style={{ textOverflow: 'ellipsis' }}
        />
      </Col>
    </Row>
    {pedidos.length > 0 &&
      <div className='list-pedidos'>
        <PedidosAprovados
          pedidos={pedidos}
          enableSelected={false}
          redirectRoute={redirectRoute}
        />
      </div>
    }
    <Loading
      isLoading={isLoading}
      fullHeight
    />
  </S.InfosCompositionWrapper>
)

export default ModalInfosComposicaoCarga;

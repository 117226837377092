const momentEsEs = {
    parentLocale: "es",
    longDateFormat: {
        "[DM]": "DD/MM",
        "[DDMMYY]": "DD/MM/YY",
        "L": "DD/MM/YY",
        'l': "DD/MM/YY"
    }
};

export default momentEsEs;

import React from "react";
import PropTypes from "prop-types";
import { State } from "react-powerplug";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { Row, Col } from "react-flexbox-grid";
import { Textarea } from "@hbsis.uikit/react";

const ModalJustificativa = ({
  selectedItem,
  justificativas = [],
  onSave,
  onClose
}) => (
  <State
    initial={{
      Tipo: selectedItem.Justificativa.Tipo || null,
      Observacao: selectedItem.Justificativa.Observacao || ""
    }}
  >
    {({ state, setState }) => (
      <Dialog
        autoScrollBodyContent
        open
        onRequestClose={onClose}
        actions={[
          <FlatButton label="Cancelar" onClick={onClose} />,
          <FlatButton
            label="Salvar"
            disabled={!state.Observacao && !state.Tipo}
            onClick={() => onSave(state)}
          />
        ]}
      >
        <h3>Adicionar Observação para o Lote: {selectedItem.Label}</h3>

        {/* <Row>
          <Col xs={12}>
            <SelectBox
              clearable
              label="Justificativas pré definidas"
              name="ObservacaoPreDefinida"
              disabled={!!state.Observacao}
              placeholder="Justificativas"
              value={state.Tipo}
              options={justificativas}
              onChange={value =>
                setState({
                  Tipo: value ? value.Id : null
                })
              }
              valueKey="Id"
              labelKey="Descricao"
              searchable
              openOnFocus
            />
          </Col>
        </Row> */}
        <Row>
          <Col xs={12}>
            <Textarea
              label="Justificativa"
              name="Observacao"
              value={state.Observacao || ""}
              disabled={!!state.Tipo}
              onChange={event =>
                setState({
                  Observacao: event.target.value.trimStart()
                })
              }
            />
          </Col>
        </Row>
      </Dialog>
    )}
  </State>
);

ModalJustificativa.propTypes = {
  selectedItem: PropTypes.object,
  justificativas: PropTypes.array,
  onSave: PropTypes.func,
  onClose: PropTypes.func
};

export default ModalJustificativa;

import React, { createContext, useContext, useState } from 'react'
import * as service from '../../../services/suggestion/suggestion.service'

const SuggestionContext = createContext();

const SuggestionContextProvider = ({ children }) => {
  const [suggestions, setSuggestions] = useState({ Data: [], TotalItems: 0, CurrentPage: 0, filters: null });

  const fetchSuggestions = async (newFilters) => {
    const filters = { ...newFilters, UseFilterDefault: JSON.parse(localStorage.getItem("filterMyItemsOnly")) }
    const response = await service.
      fetchSuggestions(filters);

    setSuggestions({
      ...response,
      filters
    });
  }

  const fetchNewPageSuggestions = async (pageFilter) => {
    const _filter = {
      ...suggestions.filters,
      ...pageFilter
    }
    await fetchSuggestions(_filter);
  }

  return (
    <SuggestionContext.Provider value={{
      suggestions,
      fetchSuggestions,
      fetchNewPageSuggestions
    }}>
      {children}
    </SuggestionContext.Provider>
  )
}

const useSuggestionContext = () => useContext(SuggestionContext);

export { SuggestionContextProvider, SuggestionContext, useSuggestionContext }

import styled from 'styled-components'
import { Button } from '@hbsis.uikit/react';

export const ButtonCancel = styled(Button)`
  width: 140px;
  margin: 0;
  margin-left: 12px;

  background-color: #DE4246 !important;
`

import React, { Component } from 'react'
import { Button, Layout } from '@hbsis.uikit/react'
import moment from 'moment'
import intl from 'react-intl-universal'
import Fetch from 'utils/fetch'
import SessionUser from 'utils/user-storage'
import StorePersist from 'utils/store-persist'
import { downloadFile, convertToQueryParameters } from 'utils/custom-functions'
import Header from 'components/header'
import Filter from './filter'
import Loading from 'components/center-loading'
import ImportExport from 'components/import-export'
import IconNew from 'images/icn-add-small-blue.svg'
import IconEmpty from 'images/iconEmpty.icon'
import IconFilterGray from 'images/icn-filter-gray.svg'
import IconFilterBlue from 'images/icn-filter-blue.svg'
import IconUpload from 'images/icn-upload.svg'
import Table from './components/table'
import ComposicaoCargaForm from './form'
import SnackBar from 'material-ui/Snackbar'

import './composicao-carga.css'
import { isLoadCompositionFlowSupplierSide, isScheduleAgreementFlow, usesTmsIntegration } from 'utils/validations-general-settings'

const filtersDefault = {
  IdFornecedor: 0,
  IdUnidadeNegocio: 0,
  DataColetaDe: '',
  DataColetaAte: '',
  DataEntregaDe: '',
  DataEntregaAte: '',
  Situacao: '',
  IdCarteira: '',
  IdFamiliaRotulada: '',
  SchedulingAgreement: '',
}

class ComposicaoCarga extends Component {
  constructor() {
    super()
    this.state = {
      openForm: false,
      showFeedback: false,
      messageFeedBack: '',
      idComposicaoSelecionada: 0,
      composicoes: [],
      isFetching: false,
      idPedido: 0,
      modoLeitura: true,
      showFilterComposition: false,
      useFilterDefault: false,
      filters: filtersDefault,
      takeComposicoes: 50,
      page: 0,
      totalComposicoes: 0,
      openImportExport: false,
    }
  }

  componentDidMount() {
    const { idPedido } = this.props.match.params
    if (idPedido) {
      this.openFormByTimeline(idPedido)
      const index = window.location.href.lastIndexOf('/')
      const url = window.location.href.substring(0, index)
      window.history.replaceState({}, {}, url)
    }
  }

  UNSAFE_componentWillMount() {
    const filterMyItensOnly = StorePersist.getValuesJSON('filterMyItemsOnly')
    const filtersComposition = StorePersist.getValuesJSON('filtersComposition')

    if (filtersComposition) {
      this.setState({
        filters: filtersComposition
      })
    }

    this.setState({
      useFilterDefault: filterMyItensOnly
    }, () => this.buscarComposicoes(0))
  }

  searchFilters = (filters) => {
    StorePersist.setValuesJSON('filtersComposition', filters);
    this.showFilterComposition()
    this.setState({
      filters: filters,
      composicoes: [],
    }, () => this.buscarComposicoes(0))
  }

  verifyFilter = () => {
    return Object.values(this.state.filters).some(x => x);
  }

  showFilterComposition = () => {
    this.setState({
      showFilterComposition: !this.state.showFilterComposition,
    })
  }

  buscarComposicoes = (page = 0) => {
    this.startFetching()

    this.setState({
      page,
    }, () => {
      const user = SessionUser.get().Id
      const model = {
        IdFornecedor: this.state.filters.IdFornecedor && this.state.filters.IdFornecedor >= 0 ? this.state.filters.IdFornecedor : null,
        IdUnidadeNegocio: this.state.filters.IdUnidadeNegocio && this.state.filters.IdUnidadeNegocio >= 0 ? this.state.filters.IdUnidadeNegocio : null,
        DataColetaDe: this.state.filters.DataColetaDe,
        DataColetaAte: this.state.filters.DataColetaAte,
        DataEntregaDe: this.state.filters.DataEntregaDe,
        DataEntregaAte: this.state.filters.DataEntregaAte,
        Situacao: this.state.filters.Situacao,
        IdCarteira: this.state.filters.IdCarteira,
        IdFamiliaRotulada: this.state.filters.IdFamiliaRotulada,
        SchedulingAgreement: this.state.filters.SchedulingAgreement && isScheduleAgreementFlow() ? this.state.filters.SchedulingAgreement : null,
        UseFilterDefault: this.state.useFilterDefault,
        IdUsuario: user,
        Take: this.state.takeComposicoes,
        Page: page
      }

      Fetch.get(`/composicaocarga/listacomposicoesporfiltro?${convertToQueryParameters(model)}`)
        .then((response) => {
          this.setState({
            composicoes: response.data.Data,
            totalComposicoes: response.data.TotalRecordCount,
          })
          this.stopFetching()
        })
        .catch((e) => {
          this.stopFetching()
        })
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  showFeedback = (message) => {
    this.setState({
      messageFeedBack: message,
      showFeedback: true
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openFormByTimeline = (idPedido) => {
    this.setState({ idPedido }, () => this.openForm())
  }

  openEditForm = (composicao) => {
    this.setState({
      idComposicaoSelecionada: composicao.IdComposicao,
      modoLeitura: composicao.ModoLeitura,
      responsavelProximaAcao: composicao.ResponsavelProximaAcao
    }, () => this.openForm())
  }

  openNewForm = () => {
    this.setState({ idPedido: 0, modoLeitura: false }, () => this.openForm())
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  closeForm = () => {
    this.setState({
      openForm: false,
      idComposicaoSelecionada: 0
    }, () => this.buscarComposicoes(this.state.page))
  }

  refreshData = () => {
    this.buscarComposicoes(this.state.page)
    this.closeForm()
  }

  isProvider = () => SessionUser.get().TipoUsuario === 2

  openImportExport = () => {
    this.setState({
      openImportExport: true,
    })
  }

  closeImportExport = () => {
    this.setState({
      openImportExport: false,
    })
  }

  downloadArquivo = () => {
    Fetch.get(`pedidoVolume/exportar/PedidoVolume`)
      .then((response) => {
        const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
        downloadFile(`${datafile}_PedidoVolume.csv`, 'csv', response.data)
      })
      .catch((e) => this.proccessErro(e))
  }

  importationModelDownload = () => {
    Fetch.get(`pedidoVolume/download/DownloadModeloPedidoComposicaoCarga`)
      .then((response) => {
        const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
        downloadFile(`${datafile}_ModeloPedidoComposicaoCarga.csv`, 'csv', response.data)
      })
      .catch((e) => this.proccessErro(e))
  }

  render() {
    let {
      openForm,
      showFeedback,
      messageFeedBack,
      composicoes,
      idComposicaoSelecionada,
      isFetching,
      idPedido,
      modoLeitura,
      showFilterComposition,
      filters,
      openImportExport,
      responsavelProximaAcao
    } = this.state

    const user = SessionUser.get()

    return (
      <div className='container-composicao-carga'>
        <Loading isLoading={isFetching} />
        <Header title={intl.get('transports.compoCharge.header.title')} titleWidth={400}>
          <div className='buttons-header-container'>
            {isLoadCompositionFlowSupplierSide() &&
              <div title={intl.get('transports.compoCharge.actions.newComposition')}>
                <Button
                  onClick={this.openNewForm}
                  type='default'
                  icon={IconNew}
                  className={'button-img-small-square'}
                />
              </div>
            }
            {user.TipoUsuario === 1 && usesTmsIntegration() &&
              <div title={intl.get('transports.compoCharge.actions.requestVolumn')}>
                <Button
                  onClick={this.openImportExport}
                  type='default'
                  icon={IconUpload}
                  className={'button-img-small-square'}
                  title={intl.get('transportInformation.title')}
                />
              </div>
            }
            <div className='filterContainer'>
              <Button onClick={this.showFilterComposition}
                type='default'
                value={intl.get('filters.filter')}
                icon={this.verifyFilter() ? IconFilterBlue : IconFilterGray}
                className={'button-filter'}
              />
            </div>
          </div>
        </Header>

        <Layout.Sidebar width='450px'
          visible={this.state.showFilterComposition}
          triggerClose={this.showFilterComposition}
          background='#FFFFFF'
          offsetTop='96px'
          side='right'
          block
          float
          icon={<IconEmpty />}
        >
          <Filter
            showFilter={showFilterComposition}
            useFilterDefault={this.state.useFilterDefault}
            initialData={filters}
            handleClose={this.searchFilters}
            isProvider={this.isProvider()}
          />
        </Layout.Sidebar>

        <Table
          data={composicoes}
          handleEdit={this.openEditForm}
          refreshSearch={this.buscarComposicoes}
          page={this.state.page}
          take={this.state.takeComposicoes}
          totalComposicoes={this.state.totalComposicoes}
        />

        {openForm &&
          <ComposicaoCargaForm
            idEdit={idComposicaoSelecionada}
            idPedido={idPedido}
            idUser={SessionUser.get().Id}
            open={openForm}
            handleRefresh={this.refreshData}
            handleShowFeedback={this.showFeedback}
            handleClose={this.closeForm}
            modoLeitura={modoLeitura}
            responsavelProximaAcao={responsavelProximaAcao}
          />
        }

        <SnackBar
          onRequestClose={this.closeFeedback}
          open={showFeedback}
          message={messageFeedBack}
          autoHideDuration={3000}
        />

        <ImportExport
          title={intl.get('exportImportModal.transportInformation.title')}
          contextModal='transportInformation'
          open={openImportExport}
          handleClose={this.closeImportExport}
          downloadArquivo={this.downloadArquivo}
          downloadModelo={this.importationModelDownload}
          importArquivo='pedidoVolume/importar/PedidosPorPedidoVolume'
          type="CSV"
        />
      </div>
    )
  }
}

export default ComposicaoCarga

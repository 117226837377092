import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { Input } from 'components/uikit-adapter/index'
import { GRADE_DESCARGA } from '../../../constants'

import GradeListStyled from './grade-list.styled'
import intl from 'react-intl-universal'

const GradeList = ({ data, handlerUpdateList, profileAdminOrMasterData, type }) => {
  const getInputIsDisabled = () => !profileAdminOrMasterData
  const getInputType = (item, prop) => {
    return !item[prop] && item[prop] !== 0 && item.camposNaoPreenchidos < 7 ? 'error' : ''
  }

  return (
    <GradeListStyled>
      <Row className="rowListHeader">
        <Col xs={5} md={5} lg={5} className="align-center">{type === GRADE_DESCARGA ? intl.get('commons.family') : intl.get('commons.source')}</Col>
        <Col xs={1} md={1} lg={1} className="align-center">{intl.get('weekdays.monday')}</Col>
        <Col xs={1} md={1} lg={1} className="align-center">{intl.get('weekdays.tuesday')}</Col>
        <Col xs={1} md={1} lg={1} className="align-center">{intl.get('weekdays.wednesday')}</Col>
        <Col xs={1} md={1} lg={1} className="align-center">{intl.get('weekdays.thursday')}</Col>
        <Col xs={1} md={1} lg={1} className="align-center">{intl.get('weekdays.friday')}</Col>
        <Col xs={1} md={1} lg={1} className="align-center">{intl.get('weekdays.saturday')}</Col>
        <Col xs={1} md={1} lg={1} className="align-center">{intl.get('weekdays.sunday')}</Col>
      </Row>
      <Row className="containerListaGrade">
        <Col xs={12} md={12} lg={12} className="pt-0 pb-0">
          {
            (!data || data.length === 0) &&
            <Row className="rowList">
              <Col xs={12} md={12} lg={12} className="align-center">
                <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
              </Col>
            </Row>
          }
          {
            data && !!data.length && data.map((item, i) => (
              <Row
                key={i}
                className="rowList"
              >
                <Col xs={5} md={5} lg={5}>{type === GRADE_DESCARGA ? item.DescricaoFamilia : item.DescricaoOrigem}</Col>
                <Col xs={1} md={1} lg={1} className="align-center">
                  <Input
                    name="Segunda"
                    htmlType='number'
                    type={getInputType(item, 'Segunda')}
                    value={(item, item.Segunda)}
                    onChange={(event) => handlerUpdateList('Segunda', event.target.value, item)}
                    className="input-segunda"
                    disabled={getInputIsDisabled}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} className="align-center">
                  <Input
                    name="Terca"
                    htmlType='number'
                    type={getInputType(item, 'Terca')}
                    value={(item, item.Terca)}
                    onChange={(event) => handlerUpdateList('Terca', event.target.value, item)}
                    className="input-terca"
                    disabled={getInputIsDisabled}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} className="align-center">
                  <Input
                    name="Quarta"
                    htmlType='number'
                    type={getInputType(item, 'Quarta')}
                    value={(item, item.Quarta)}
                    onChange={(event) => handlerUpdateList('Quarta', event.target.value, item)}
                    className="input-quarta"
                    disabled={getInputIsDisabled}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} className="align-center">
                  <Input
                    name="Quinta"
                    htmlType='number'
                    type={getInputType(item, 'Quinta')}
                    value={(item, item.Quinta)}
                    onChange={(event) => handlerUpdateList('Quinta', event.target.value, item)}
                    className="input-quinta"
                    disabled={getInputIsDisabled}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} className="align-center">
                  <Input
                    name="Sexta"
                    htmlType='number'
                    type={getInputType(item, 'Sexta')}
                    value={(item, item.Sexta)}
                    onChange={(event) => handlerUpdateList('Sexta', event.target.value, item)}
                    className="input-sexta"
                    disabled={getInputIsDisabled}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} className="align-center">
                  <Input
                    name="Sabado"
                    htmlType='number'
                    type={getInputType(item, 'Sabado')}
                    value={(item, item.Sabado)}
                    onChange={(event) => handlerUpdateList('Sabado', event.target.value, item)}
                    className="input-sabado"
                    disabled={getInputIsDisabled}
                  />
                </Col>
                <Col xs={1} md={1} lg={1} className="align-center">
                  <Input
                    name="Domingo"
                    htmlType='number'
                    type={getInputType(item, 'Domingo')}
                    value={(item, item.Domingo)}
                    onChange={(event) => handlerUpdateList('Domingo', event.target.value, item)}
                    className="input-domingo"
                    disabled={getInputIsDisabled}
                  />
                </Col>
              </Row>
            ))
          }
        </Col>
      </Row >
    </GradeListStyled >
  )
}

GradeList.propTypes = {
  handlerUpdateList: PropTypes.func.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
}

export default GradeList

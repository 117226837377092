import React from 'react'
import intl from 'react-intl-universal'
import ButtonOrderAccept from 'components/button-accept-order'
import { useOrdersContext } from '../../context'
import { postAcceptOrders } from 'components/cards-obsolete/orders-vision.service'
import Session from 'utils/user-storage'

const OrdersResend = ({ refresh }) => {
  const {
    items,
    checkbox,
    setCheckbox,
    setLoading
  } = useOrdersContext()

  const acceptableItems =
    items.filter(item => item.CanAcceptRange && checkbox.includes(item.Id))
      .map(item => item.Id)

  const disabled = acceptableItems.length > 0

  const resendOrder = async () => {
    setLoading(true)

    try {
      const orders = {
        Pedidos: acceptableItems,
        PedidosVolume: []
      }

      await postAcceptOrders(Session.get().Id, orders)

      setCheckbox([])
      refresh()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <ButtonOrderAccept
      title={intl.get("orders.resendSAP")}
      checkbox={!disabled}
      accept={resendOrder}
    />
  )
}

export default OrdersResend

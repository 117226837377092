import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StyledTitleSecond from './styledTitleSecond'

class TitleSecond extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <StyledTitleSecond>
        <div>{this.props.children}</div>
      </StyledTitleSecond>
    )
  }
}

TitleSecond.defaultProps = {
  value: 'text',
}

TitleSecond.propTypes = {
  value: PropTypes.string,
}

export default TitleSecond

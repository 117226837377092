import React, { Component } from "react";
import { Row, Col } from 'react-flexbox-grid'
import { SelectBox } from "components/uikit-adapter/index"
import StyledFiltroIntegracaoSugestaoAutomatica from './styledFiltroIntegracaoSugestaoAutomatica'

class FiltroIntegracaoSugestaoAutomatica extends Component {
   constructor() {
     super();


     this.state = {
       checkedAll: false
     };
   }

   changeFamiliasRotuladas = value => {
     this.props.changeValue("filters", value === null ? null : { IdFamiliaRotulada: value.Id });
   };


   render() {
     const { familiasRotuladas, IdFamiliaRotulada } = this.props;

     return (
       <StyledFiltroIntegracaoSugestaoAutomatica>
          <Row>
           <Col xs={12} lg={12} md={12}>
             <SelectBox
               label='Família Rotulada'
               name="familiaRotulada"
               style={{height:'45px'}}
               value={IdFamiliaRotulada}
               options={familiasRotuladas}
               valueKey="Id"
               labelKey="Descricao"
               onChange={this.changeFamiliasRotuladas}
               placeholder={this.state.checkedAll ? "Todas Famílias Rotuladas Selecionadas" : "Selecione a Família Rotulada"}
               searchable
               openOnFocus
               clearable
               disabled={this.state.checkedAll}
             />
           </Col>
         </Row>
       </StyledFiltroIntegracaoSugestaoAutomatica>
     );
   }
 }


export default FiltroIntegracaoSugestaoAutomatica;


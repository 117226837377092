import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Dialog from 'material-ui/Dialog'
import CenterLoading from 'components/center-loading'
import { Button, Input } from '@hbsis.uikit/react'
import intl from 'react-intl-universal'
import { Row, Col } from "react-flexbox-grid";
import './budget-form.css'
import { formatErrorMessage } from 'utils/handle-error';
import { Switcher } from 'components/toggle'
import { usesPrimaryMaterial } from 'utils/validations-general-settings'

class BudgetForm extends Component {
  constructor() {
    super()

    this.state = {
      budget: {},
      managers: [],
      labeledFamilies: [],
      selectedFamilies: [],
      isSaving: false
    }
  }

  componentDidMount() {
    const editMode = this.props.editMode

    if (editMode) {
      this.searchBudgetById()
    }
  }

  startSaving = () => {
    this.setState({
      isSaving: true
    })
  }

  stopSaving = () => {
    this.setState({
      isSaving: false
    })
  }

  proccessErro = (exception) => {
    this.props.handleFeedback(formatErrorMessage(exception))
  }

  searchBudgetById = () => {
    const idFamily = this.props.idEdit

    Fetch.get(`/carteira/${idFamily}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
  }

  proccessData = (data) => {
    this.setState({ budget: data, selectedFamilies: data.CarteiraFamiliasRotuladas })
  }

  changeValue = (event) => {
    const prop = event.target.name
    const value = event.target.value

    this.setState({
      budget: {
        ...this.state.budget,
        [prop]: value
      }
    })
  }

  updateSelectValue = (prop, value) => {
    this.setState({
      budget: {
        ...this.state.budget,
        [prop]: value
      }
    })
  }

  save = () => {
    this.startSaving()

    const editMode = this.props.editMode

    let model = this.state.budget
    model.Id = !!editMode ? this.props.idEdit : undefined
    Fetch.post(`/carteira`, model)
      .then(this.props.handleRefresh)
      .then(() => this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`))
      .catch((e) => this.proccessErro(e))
      .finally(this.stopSaving)
  }

  render() {
    let { budget, isSaving } = this.state
    let { open, editMode, profileAdminOrMasterData} = this.props

    return (
      <div>
        <Dialog
          title={editMode ? intl.get('master-data.walletsAndFamilys.wallets.actions.editWallet') : intl.get('master-data.walletsAndFamilys.wallets.actions.newWallet')}
          contentStyle={{ width: '600px' }}
          bodyClassName='modalBodyClass'
          open={open}
          autoScrollBodyContent
        >
          <CenterLoading isLoading={isSaving} fullHeightParent />

            <div className='line-1-columns'>
              <Input
                required
                name='Descricao'
                onChange={this.changeValue}
                value={budget.Descricao}
                label={intl.get('master-data.general-configuration.name')}
                disabled={!profileAdminOrMasterData}
              />
            </div>
            <label className="label-style">{intl.get('master-data.menus.configuration')}</label>
            <React.Fragment>
              <div className="container">
                {usesPrimaryMaterial() && <Row>
                  <Col xs={4} style={{ marginTop: '5px' }}>
                    <Switcher
                      name="MaterialPrimario"
                      label={intl.get('master-data.general-configuration.primaryMaterial')}
                      className="materialPrimario"
                      value={budget.MaterialPrimario}
                      onChange={val => this.updateSelectValue('MaterialPrimario', val.checked)}
                      thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                      trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                      disabled={!profileAdminOrMasterData}
                    />
                  </Col>
                </Row>
                }
                <Row>
                  <Col xs={4} style={{ marginTop: '5px' }}>
                    <Switcher
                      name="Garrafa"
                      label={intl.get('master-data.general-configuration.bottle')}
                      className="garrafa"
                      value={budget.IsGarrafa}
                      onChange={val => this.updateSelectValue('IsGarrafa', val.checked)}
                      thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                      trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                      disabled={!profileAdminOrMasterData}
                    />
                  </Col>
                </Row>
              </div>
            </React.Fragment>
            <div className='footer-dialog-buttons'>
              <Button
                value={intl.get('geral.buttonsDefault.cancel')}
                type="default"
                onClick={() => { this.props.handleClose() }}
                className="button"
              />
              {profileAdminOrMasterData &&
                <Button
                  value={intl.get('geral.buttonsDefault.save')}
                  type="primary"
                  className="button"
                  onClick={this.save}
                />
              }
            </div>
        </Dialog>
      </div>
    )
  }
}

BudgetForm.propTypes = {
  idEdit: PropTypes.number,
  open: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
}

export default BudgetForm

import React from 'react'
import * as S from './styled'

export const DropdownItem = ({ label, onClick }) => {
  return (
    <S.DropdownItem onClick={onClick}>
      <S.DropdownGroupLabel>
        {label}
      </S.DropdownGroupLabel>
    </S.DropdownItem>
  )
}


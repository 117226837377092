import React from 'react'
import CardPendingReview from './cardPendingReview'
import CardFiled from './cardFiled'
import CardFreeSlot from './cardFreeSlot'
import { SituacaoComposicaoCarga } from '../../../../models/composicaoCarga/situacaoComposicaoCarga'
import * as S from './styled'
import CardInNegotiation from './cardInNegotiation'
import CardEmergencyNegotiation from './cardEmergencyNegotiation'

const Card = ({ data, hour, viewFreeSlot, viewScheduledDelivery,
  handleClickFree, handleOpenLoadForm, viewPendingReview, viewInNegotiation, viewEmergencyNegotiation,
  handleClickInNegotiation, isEditMode, handleSelectEditDate, dia, idCompositionSelected, selectedFreeSlot }) => (
  <S.Wrapper>
    {viewScheduledDelivery &&
      <CardFiled
        handleClick={!isEditMode && handleOpenLoadForm}
        data={data.ComposicoesCarga.filter(x => x.Situacao === SituacaoComposicaoCarga.Aceito)}
        hour={hour}
        dia={dia}
        idCompositionSelected={idCompositionSelected}
      />
    }
    {viewPendingReview &&
      <CardPendingReview
        handleClick={!isEditMode && handleOpenLoadForm}
        data={data.ComposicoesCarga.filter(x => x.Situacao === SituacaoComposicaoCarga.AguardandoRevisaoFornecedor)}
        hour={hour}
        dia={dia}
        idCompositionSelected={idCompositionSelected}
      />
    }
    {viewInNegotiation &&
      <CardInNegotiation
        handleClick={!isEditMode && handleClickInNegotiation}
        data={data.ComposicoesCarga.filter(x => x.Situacao === SituacaoComposicaoCarga.EmNegociacao)}
        hour={hour}
        dia={dia}
        idCompositionSelected={idCompositionSelected}
      />
    }
    {viewFreeSlot &&
      <CardFreeSlot
        handleClick={isEditMode ? handleSelectEditDate : handleClickFree}
        quantity={data.QuantidadeDisponivel ? data.QuantidadeDisponivel : 0}
        hour={hour}
        dia={dia}
        selectedFreeSlot={selectedFreeSlot}
      />
    }
    {viewEmergencyNegotiation &&
    <CardEmergencyNegotiation
      handleClick={!isEditMode && handleClickInNegotiation}
      data={data.ComposicoesCarga.filter(x => x.Situacao === SituacaoComposicaoCarga.NegociacaoEmergencial)}
      hour={hour}
      dia={dia}
      idCompositionSelected={idCompositionSelected}
    />
    }
  </S.Wrapper>
)

export default Card;


import moment from 'moment'
import intl from 'react-intl-universal'
import { getTodayDate } from 'utils/date';

const isoDateFormat = "YYYY-MM-DD";

export const validateDate = (pickUpDate, deliveryDate) => {
  if (moment(pickUpDate).format(isoDateFormat) > moment(deliveryDate).format(isoDateFormat)) {
    return intl.get("requestOrders.invalidDateRange")
  } else if (moment(pickUpDate).format(isoDateFormat) < moment(getTodayDate()).format(isoDateFormat)) {
    return intl.get('stocks.informAdvanceOrder.feedback.collectionDateCannotEarlierThanCurrentDay');
  }
  return null;
}
import styled from 'styled-components'

const StyledSuppliersListItem = styled.div`
  height:50px;

  .header-item {
    flex: 1;
    width: 100%;
  }

  .header-new-item {
    flex: 1;
    width: 100%;
    color: #3b495e;
  }

  .itens-menu {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    padding-left: 15px;
    cursor: pointer;
    height: 50px;
    width: 100%;

    &-actions {
      display: flex;
    }
  }
`

export default StyledSuppliersListItem

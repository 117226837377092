import styled from 'styled-components';

export const Container = styled.div`
    height: calc(100% - 100px);
    position: relative;
`

export const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 100px;
    align-items: center;
    background-color: #f5f6fa;
`
import styled from 'styled-components'

const StyledSuggestionsListItem = styled.div`
  padding: 5px 0;
  box-shadow: 0 1px #e6e6e6;

  .header-actions .header-actions--collapse {
    border: 0;
    color: #fff;
    outline: 0;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #66aedd;
  }

  .suggestion-list-header {
    height: 50px;
    box-sizing: border-box;
  }

  .item-highlighted {
    background-color: #ddeefb;
    color: #2870b2;
    font-weight: bold;
  }

  .item-dimmed {
    background-color: #f2f2f2;
    color: #a4a4a4;
    font-weight: bold;
  }

  .table-list-item-body {
    padding-top: 10px;

    .table-list-subitem-header {
      display: flex;
      color: #fff;
      background-color: #477dc0;

      .header-item {
        flex: 1;
        padding: 10px;
      }
    }

    .table-list-subitem:not(:last-child) {
      box-shadow: 0 1px #ffffff;
    }
  }

  .container{
    width:100% !important;
  }

  .label-style{
    background: white;
    margin-left: 0px;
  }
`

export default StyledSuggestionsListItem
import React from 'react'
import { LoadCompositionBuildingProvider } from './context/load-composition-building-context';
import LoadCompositionBuilding from './loadCompositionBuilding';

const LoadCompositionBuildingIndex = ({ match, history }) => (
  <LoadCompositionBuildingProvider params={match.params} history={history}>
    <LoadCompositionBuilding />
  </LoadCompositionBuildingProvider>
)

export default LoadCompositionBuildingIndex;

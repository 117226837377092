import React from 'react'
import PropTypes  from 'prop-types'

import HeaderContainer from './styledHeaderIcon'

const HeaderIcon = ({ title, children, subtitle, icon, className }) => (
  <HeaderContainer className={className}>
    {icon}
    <div className='desc'>
      <span className='title'>{title}</span><br />
      <span className='subtitle'>{subtitle}</span>
    </div>
    <div className='div-children'>
      {children}
    </div>
  </HeaderContainer>
)

HeaderIcon.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default HeaderIcon

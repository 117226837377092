import React, { createContext, useContext, useState } from 'react'

const ZsagContext = createContext()

const ZsagProvider = ({ children }) => {
  const [data, setData] = useState()
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [newLineInProgress, setNewLineInProgress] = useState(false)
  const [linesForDelete, setLinesForDelete] = useState([])
  const [selectedVolumes, setSelectedVolumes] = useState([])
  const [volumes, setVolumes] = useState()
  const [isOpenModalDelete, setIsOpenModalDelete] = useState(false)
  const [haveUnsavedChanges, setHaveUnsavedChanges] = useState(false);
  const [materials, setMaterials] = useState(undefined)
  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)

  return (
    <ZsagContext.Provider
      value={{
        selectedVolumes,
        setSelectedVolumes,
        linesForDelete,
        setLinesForDelete,
        loading,
        setLoading,
        startLoading,
        stopLoading,
        setFilters,
        filters,
        data,
        setData,
        newLineInProgress,
        setNewLineInProgress,
        volumes,
        setVolumes,
        isOpenModalDelete,
        setIsOpenModalDelete,
        haveUnsavedChanges,
        setHaveUnsavedChanges,
        materials,
        setMaterials
      }}
    >
      {children}
    </ZsagContext.Provider>
  )
}

export const useZsagContext = () => useContext(ZsagContext)
export { ZsagProvider, ZsagContext }


const getConfig = () => require('../config/config.json')

export function getEnvironment() {
  return getConfig().environment;
}

export function getHost() {
  return getConfig().host;
}

export function getHostNPS() {
  return getConfig().hostNPS;
}

export function getGoogleAnalyticKey() {
  return getConfig().google_analytic_key;
}

export function getNPSId() {
  return getConfig().systemId;
}

export function getRedirectUri() {
  return getConfig().redirectUri;
}

export function getLogoutRedirectUri() {
  return getConfig().postLogoutRedirectUri;
}

export function getAuthorityLoginPage() {
  return getConfig().authorityLoginPage
}

export function getAuthorityForgotPasswordPage() {
  return getConfig().authorityForgotPasswordPage
}

export function getClientId() {
  return getConfig().clientId
}

export function getAuthScopes() {
  return getConfig().authScopes
}

export function getFullHistoryId() {
  return getConfig().fullStoryId
}

export function getVersionName() {
  return getConfig().versionName
}

export function getBuildDeployDate() {
  return getConfig().buildDeployDate
}

export function getApimSubscriptionKey() {
  return getConfig().apimSubscriptionKey
}

export function getShowNPS() {
  return getConfig().showNPS === "true"
}

export function getAuthenticationProvider() {
  return getConfig().authenticationProvider
}

export function getDataDogClientToken() {
  return getConfig().dataDogClientToken
}

export function getDataDogApplicationId() {
  return getConfig().dataDogApplicationId
}

export function getSupportEmail() {
  return getConfig().supportEmail
}
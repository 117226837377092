import styled from 'styled-components'

 const StyledFiltroIntegracaoGradeDescarga = styled.div`
  
    padding-bottom: 20px;

    .headerModal {
        margin: 0px;
        padding: 0 0 15px;
        color: rgba(0, 0, 0, 0.87);
        font-size: 22px;
        line-height: 32px;
        font-weight: 400;
    }

    .button {
        margin-left: 5px;
    }

    .colTitulo {
        display: flex;
        align-items: center;
        justify-content: left;
        margin-bottom: 5px;
        line-height: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        color: #3b495e;
        font-size: 14px;
    }

     .colCheckBox {
        display: inline-flex;
        justify-content: flex-end;
    }

    .checkbox-item {
        display: flex !important;
        align-items: center !important;
    }

    .checkbox-item label {
        margin-bottom: 5px !important;
        line-height: 1rem !important;
        font-weight: 600 !important;
        text-transform: uppercase !important;
        color: #3b495e !important;
        font-size: 12px !important;
    }
`

 export default StyledFiltroIntegracaoGradeDescarga

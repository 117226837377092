import intl from 'react-intl-universal'
import IcnGarage from 'images/icn-garage.svg'
import IcnBrewery from 'images/icn-fabrica-blue-semi-light.svg'

const chartDataDefault = {
  Details: [],
  guides: {},
}

export const types = {
  brewery: 1,
  supplierUnit: 2,
  externalWarehouse: 3
}

export const mountChartData = (_chartData) => {
  if (!_chartData) {
    _chartData = chartDataDefault
  }
  return ({
    ..._chartData,
    guides: {
      Over: _chartData ? _chartData.Over : 0,
      Out: _chartData ? _chartData.Out : 0,
    }
  })
}

export const isExternalWarehouse = (unitType) => unitType === types.externalWarehouse
export const isBrewery = (unitType) => unitType === types.brewery
export const getLabelTitle = (unitType) => isExternalWarehouse(unitType) ?
  intl.get('commons.brewery') :
  intl.get('stocks.stockDetail.externalWarehouse')

export const getButtonSVG = (unitType) => isExternalWarehouse(unitType) ?
  IcnBrewery : IcnGarage
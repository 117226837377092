import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from "@hbsis.uikit/react"

import Paper from 'material-ui/Paper'

import StatusCard from './../../../body-card/status-card'
import Divisor from './../../../body-card/card-divisor'
import Alert from './../../../body-card/card-alert'

import IconCalendarRefresh from 'images/icn-calendar-refresh.svg'
import IconTruck from 'images/icn-delivery-truck.svg'

import Session from 'utils/user-storage'
import InformAdvanceDelayOrder from 'components/inform-advance-delay-order'
import intl from 'react-intl-universal';

import './supplier.css'
import moment from 'moment'
import { formatNumber } from 'utils/format'
import { StatusPedido } from '../../../../../models/pedidos/statusPedido'
import { getGeneralSettingValue } from 'services/general-settings/general-settings'
import { formatDeliveryTime } from 'utils/delivery-time'
import { isLoadCompositionFlowSupplierSide } from 'utils/validations-general-settings'
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'
 
const TipoPerfilPcpUnidade = 4;
const TipoNegociacaoPedido = {
  RenegociacaoDePedidoParaAtenderDemanda: 47,
  EmNegociacaoComposicaoCarga: 10
}

export const STATUS_PEDIDO = {
  AGUARDANDO_COMPOSICAO_CARGA: 2,
  EM_NEGOCIACAO_COMPOSICAO_CARGA: 5
};

class ShopSuggestionDetailSupplierCard extends Component {
  constructor() {
    super()

    this.state = {
      openFormOrder: false,
      pedido: {
        NumeroPedido: '',
        NumeroPedidoSap: '',
        NumeroItemPedidoSap: '',
        DesabilitarDataColeta: true,
        DataColeta: '',
        DataEntrega: '',
        PossuiComposicao: false,
        TransitTime: '',
        Quantidade: '',
        Material: '',
        UnidadeMedida: '',
      },
    }
  }

  UNSAFE_componentWillMount() {
    this.mountOrder(this.props.dataCard)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.mountOrder(nextProps.dataCard)
  }


  mountOrder = (task) => {
    this.setState({
      pedido: {
        NumeroPedido: task.Negociacao.NumeroPedido,
        NumeroPedidoSap: task.Negociacao.NumeroPedidoSap,
        NumeroItemPedidoSap: task.Negociacao.NumeroItemPedidoSap,
        DesabilitarDataColeta: task.IntStatus === 8 ? true : false,
        DataColeta: task.Negociacao.DataColetaPropostaFormatada,
        DataEntrega: task.Negociacao.DataEntregaPropostaFormatada,
        HoraEntrega: task.HoraEntrega,
        TransitTime: task.TransitTime,
        Quantidade: task.Quantidade,
        Material: task.Material.Nome,
        UnidadeMedida: task.UnidadeMedida,
      }
    })
  }

  openFormOrder = () => {
    this.setState({
      openFormOrder: true,
    })
  }

  closeFormOrder = () => {
    this.setState({
      openFormOrder: false,
    })
  }

  salvarNovasDatasColetaEntrega = () => {
    this.setState({
      openFormOrder: false,
    })
    this.props.refreshCardDetail()
  }

  goToComposition = () => {
    this.props.history.push(`/composicao/${this.state.pedido.NumeroPedido}`)
  }

  habilitarAntecipacaoAtrasoDoPedido = (possuiComposicao, lastNegociation) => (
    lastNegociation !== TipoNegociacaoPedido.RenegociacaoDePedidoParaAtenderDemanda
    && lastNegociation !== TipoNegociacaoPedido.EmNegociacaoComposicaoCarga
    && possuiComposicao
    && isLoadCompositionFlowSupplierSide());

  isDisableLoadCompositionButton = (intStatus) => {
    return getGeneralSettingValue(GeneralSettingParameter.LoadCompositionNewFlow) ? intStatus === StatusPedido.AguardandoComposicaoCarga
      : this.perfilPcpUnidadeLogado() || intStatus < 5 && intStatus !== StatusPedido.AguardandoComposicaoCarga || intStatus === StatusPedido.AguardandoAprovacaoGestor || intStatus === StatusPedido.AguardandoCiencia
  }

  perfilPcpUnidadeLogado = () => Session.get().PerfilAcesso === TipoPerfilPcpUnidade

  render() {
    const task = this.props.dataCard
    const heightCard = task.Sugestao ? 'calc(100% - 5px)' : '265px'

    return (
      <Paper
        zDepth={1}
        className='card-shop-detail-supplier'
        style={{ height: heightCard }}>

        {task.Tipo.Alerta && <Alert />}

        <div className='card-click-area'>

          <div className='header-card-shop-detail-supplier'>
            <StatusCard
              tipo={task.Tipo}
              material={task.Material.Nome}
            />
          </div>

          <Divisor />

          <div className='body-card'>
            <div className='card-label'>
              <span>{intl.get('stocks.timeline.factory')}</span>
              <span>{task.Fabrica.Nome}</span>
            </div>
            <div className='card-label'>
              <span>{intl.get('stocks.timeline.status')}</span>
              <span>{task.Status}</span>
            </div>
          </div>

          <Divisor />

          <div className='body-card'>
            <div className='card-label'>
              <span>{intl.get('stocks.timeline.origin')}</span>
              <span>{task.Fornecedor}</span>
            </div>
          </div>

          <Divisor />

          {task.Negociacao &&
            <div className='detail-body'>

              <div className='card-row-shop-detail-supplier'>
                <div className='card-label-shop-detail-supplier'>
                  <span> {!!task.PedidoVolume ? intl.get('stocks.volumeCode') : intl.get('stocks.portalCode')}</span>
                  <span><strong>{task.Negociacao.NumeroPedido}</strong></span>
                </div>
              </div>

              <div className='card-row-shop-detail-supplier'>
                <div className='card-label-shop-detail-supplier'>
                  <span>{intl.get('stocks.qty')}</span>
                  <span><strong>{`${formatNumber(task.Negociacao.QuantidadeProposta)} ${task.Negociacao.UnidadeMedidaProposta}`}</strong></span>
                </div>
              </div>

              <div className='card-row-shop-detail-supplier'>
                <div className='card-label-shop-detail-supplier'>
                  <span>{intl.get('stocks.collectionDate')}</span>
                  <span><strong>{moment(task.Negociacao.DataColetaPropostaFormatada, "DD/MM/YYYY").format("L")}</strong></span>
                </div>
              </div>

              <div className='card-row-shop-detail-supplier'>
                <div className='card-label-shop-detail-supplier'>
                  <span>{intl.get('stocks.deliveryDate')}</span>
                  <span>
                    <strong>
                      {`${moment(task.Negociacao.DataEntregaPropostaFormatada, "DD/MM/YYYY").format("L")} ${formatDeliveryTime(task.HoraEntrega)}`}
                    </strong>
                  </span>
                </div>
              </div>
            </div>
          }

          {!task.PedidoVolume &&
            <div>
              <Divisor />
              <div className='timeline-actions'>
                <div title={intl.get("stocks.suggestionsListItem.goLoadComposition")} className="card-detail-button-container">
                  <Button
                    disabled={this.isDisableLoadCompositionButton(task.IntStatus)}
                    type="secondary"
                    icon={IconTruck}
                    onClick={this.goToComposition}
                  />
                </div>
                <div title={intl.get("stocks.suggestionsListItem.InformChangeOrder")} className="card-detail-button-container">
                  <Button
                    disabled={!this.habilitarAntecipacaoAtrasoDoPedido(task.PossuiComposicao,
                      task.CodigoStatus
                    )}
                    type="secondary"
                    icon={IconCalendarRefresh}
                    onClick={this.openFormOrder}
                  />
                </div>
              </div>
            </div>
          }

          {this.state.openFormOrder &&
            <InformAdvanceDelayOrder
              open
              pedido={this.state.pedido}
              onClose={this.closeFormOrder}
              onSave={this.salvarNovasDatasColetaEntrega}
            />
          }
        </div>
      </Paper>
    )
  }
}

export default withRouter(ShopSuggestionDetailSupplierCard)

import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { Button } from '@hbsis.uikit/react'
import { useOrdersContext } from '../../context'
import IconStop from 'images/stop.svg'
import { OrdersCancelModal } from './modal'

const OrdersCancel = () => {
  const {
    items,
    checkbox
  } = useOrdersContext()

  const [openModal, setOpenModal] = useState(false)
  const [cancelableItems, setCancelableItems] = useState([])

  useEffect(() => {
    const cancelableItems = items.filter(item => checkbox.includes(item.Id))
    setCancelableItems(cancelableItems)
  }, [checkbox])

  return (
    <React.Fragment>
      <div
        className="btn-content"
        title={intl.get("requestOrders.cancelOrdersInBulk")}
      >
        <Button
          icon={IconStop}
          className="btnAcept"
          width='166px'
          type="secondary"
          value={intl.get("requestOrders.cancelOrders")}
          onClick={() => setOpenModal(true)}
          disabled={cancelableItems.length === 0}
        />
      </div>
      <OrdersCancelModal
        open={openModal}
        close={() => setOpenModal(!openModal)}
        data={cancelableItems}
      />
    </React.Fragment>
  )
}

export default OrdersCancel




import styled from 'styled-components'

const StyledButton = styled.div`

  .button-value {
    display: flex;
    align-content: center;
  }

  .button-text {
    font-size: 14px;
    margin-top: 7px;
    color: #2870B2;
  }

  .button-only-icon {
    width: 50px;
    height: 50px;
  }

  .button-filter {
    width: 150px;
  }
`

StyledButton.defaultProps = {
}

export default StyledButton

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import { Button, Input, SelectBox, Dialog, Toggle } from 'components/uikit-adapter/index'
import Loading from 'components/center-loading'
import intl from 'react-intl-universal'

import './supplier-form.css'
import { formatErrorMessage } from 'utils/handle-error';
import { getGeneralSettingValue } from 'services/general-settings/general-settings'
import { getSupplierCodeFormated } from 'utils/supplier-code-format';
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'

class SupplierForm extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      supplier: {
        Fornecedor: {
          Apelido: '',
          AutoAcceptLoadComposition: false
        },
        Usuario: {}
      },
      suppliers: [],
      user: {},
      users: []
    }
  }

  componentDidMount() {
    this.loadUsers()

    const editMode = this.props.editMode
    if (editMode) {
      this.searchSupplierGoupById()
    } else {
      this.loadSuppliers()
    }
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  loadSuppliers = () => {
    this.startFetching()
    Fetch.get(`/fornecedor/buscaFornecedores`)
      .then((response) => { this.setState({ suppliers: response.data }) })
      .finally(() => this.stopFetching())
  }

  loadUsers = () => {
    this.startFetching()
    Fetch.get(`/usuario/buscaUsuariosFornecedor`)
      .then((response) => {
        this.setState({ users: response.data })
      })
      .finally(() => this.stopFetching())
  }

  searchSupplierGoupById = () => {
    const id = this.props.idEdit
    this.startFetching()
    Fetch.get(`/fornecedoresUsuarios/${id}`)
      .then((response) => this.proccessData(response.data))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  proccessData = (data) => {
    const { NomePlanta, CodigoSapFornecedor, Cnpj, NomeRazaoFornecedor, NomeUsuario, IdFornecedor, IdUsuario, Id, CodigoFornecedorMdg, AutoAcceptLoadComposition, HasAuroraBusinessUnities } = data
    let codigoFornecedorMdg = CodigoFornecedorMdg;

    if (!CodigoFornecedorMdg) {
      codigoFornecedorMdg = '';
    }

    this.setState({
      supplier: {
        Id: Id,
        Fornecedor: {
          Id: IdFornecedor,
          Apelido: NomePlanta,
          CodigoFornecedor: CodigoSapFornecedor,
          Cnpj: Cnpj,
          RazaoSocial: NomeRazaoFornecedor,
          CodigoFormatado: getSupplierCodeFormated(codigoFornecedorMdg, CodigoSapFornecedor, HasAuroraBusinessUnities),
          AutoAcceptLoadComposition: AutoAcceptLoadComposition
        },
        Usuario: {
          Id: IdUsuario,
          Nome: NomeUsuario
        }
      }
    })
  }

  proccessErro = (exception) => {
    try {
      const erro = formatErrorMessage(exception)
      this.props.handleFeedback(erro)
    } catch (e) {
      this.props.handleFeedback(intl.get('feedbacks.serverError'))
    }
  }

  changeValueNickname = (event) => {
    const value = event.currentTarget.value

    this.setState({
      supplier: {
        ...this.state.supplier,
        Fornecedor: {
          ...this.state.supplier.Fornecedor,
          Apelido: value
        }
      }
    })
  }

  changeAutoAcceptLoadComposition = (prop, value) => {
    this.setState({
      supplier: {
        ...this.state.supplier,
        Fornecedor: {
          ...this.state.supplier.Fornecedor,
          [prop]: value
        }
      }
    })
  }

  save = () => {
    const model = this.state.supplier

    const up = {
      IdUsuario: model.Usuario.Id,
      IdFornecedor: model.Fornecedor.Id,
      Apelido: model.Fornecedor.Apelido,
      Id: model.Id ? model.Id : 0,
      AutoAcceptLoadComposition: model.Fornecedor.AutoAcceptLoadComposition
    };

    this.startFetching()
    Fetch.post(`/fornecedoresUsuarios/salvar`, up)
      .then(this.props.handleRefresh)
      .then(() => this.props.handleFeedback(intl.get('feedbacks.savedRegister')))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  updateUserSelectValue = (prop, value) => {
    this.setState({
      supplier: {
        ...this.state.supplier,
        Usuario: {
          ...this.state.supplier.Usuario,
          [prop]: value
        }
      }
    })
  }

  updateSupplierSelectValue = (prop, value) => {
    this.setState({
      supplier: {
        ...this.state.supplier,
        Fornecedor:{
          ...this.state.supplier.Fornecedor,
          [prop]: value
        }
      }
    })
  }

  render() {
    const { supplier, suppliers, users, isFetching } = this.state
    const { open, editMode, profileAdminOrMasterData } = this.props

    return (
      <div>
        <Loading isLoading={isFetching} />
        <Dialog
          title={editMode ? intl.get('master-data.suppliers.ableSupplier.actions.editSupplier') : intl.get('master-data.suppliers.ableSupplier.actions.newSupplier')}
          contentStyle={{ width: '600px' }}
          open={open}
        >

            <div className='line-1-columns'>
              <SelectBox
                required
                name='Usuario'
                label={intl.get('master-data.suppliers.ableSupplier.actions.supplierGroupName')}
                placeholder={intl.get('master-data.suppliers.ableSupplier.actions.supplierGroupName')}
                valueKey='Id'
                labelKey='Nome'
                value={supplier.Usuario.Id}
                options={users}
                onChange={value => this.updateUserSelectValue('Id', value.Id)}
                disabled={!profileAdminOrMasterData}
                searchable
                openOnFocus
              />
            </div>

            <div className='line-1-columns option-item'>
              {editMode &&
                <Input
                  disabled
                  label={intl.get('commons.supplier')}
                  value={`${supplier.Fornecedor.CodigoFormatado} - ${supplier.Fornecedor.RazaoSocial} - ${supplier.Fornecedor.Cnpj}`}
                />
              }
              {
                !editMode &&
                <SelectBox
                  required
                  name='Fornecedor'
                  label={intl.get('commons.supplier')}
                  placeholder={intl.get('commons.supplier')}
                  valueKey='Id'
                  labelKey='Descricao'
                  value={supplier.Fornecedor.Id}
                  options={suppliers}
                  onChange={value => this.updateSupplierSelectValue('Id', value.Id)}
                  disabled={!profileAdminOrMasterData}
                  searchable
                  openOnFocus
                />
              }
            </div>
            <div className='line-1-columns'>
              <Input
                required
                name='NomeFilial'
                label={intl.get('commons.factory')}
                value={supplier.Fornecedor.Apelido}
                onChange={this.changeValueNickname}
                disabled={!profileAdminOrMasterData}
              />
            </div>
            <div className='line-1-columns'>
              {editMode &&
                getGeneralSettingValue(GeneralSettingParameter.LoadCompositionNewFlow) &&
                profileAdminOrMasterData &&
                <Toggle
                  className='toggle-accept-load-comp'
                  name={"AutoAcceptLoadComposition"}
                  label={intl.get('master-data.general-configuration.acceptChargeCompositionAutomatic')}
                  value={supplier.Fornecedor.AutoAcceptLoadComposition}
                  onChange={value => this.changeAutoAcceptLoadComposition("AutoAcceptLoadComposition", value.checked)}
                  thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                  trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                  labelStyle={{ color: '#3B495E' }}
                  labelPosition="right"
                />
              }
            </div>
            <div className='footer-dialog-buttons'>
              <Button
                type="default"
                value={intl.get('geral.buttonsDefault.cancel')}
                onClick={() => { this.props.handleClose() }}
                className="button"
              />
              {profileAdminOrMasterData &&
                <Button
                  type="primary"
                  value={intl.get('geral.buttonsDefault.save')}
                  className="button"
                  onClick={this.save}
                />
              }
            </div>
        </Dialog>
      </div>
    )
  }
}

SupplierForm.propTypes = {
  idEdit: PropTypes.number,
  open: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
}

export default SupplierForm

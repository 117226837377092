import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { Input } from '@hbsis.uikit/react'
import { SelectBox } from "components/uikit-adapter/index"
import intl from 'react-intl-universal'

import Filter from 'components/filters'
import DateRange from 'components/date-range'
import { formatErrorMessage } from 'utils/handle-error'

import {
  getAllSuppliersService,
  getAllBusinessUnitsService,
  getAllMaterialsService,
  getAllStatusService,
  getAllStepsService,
  getAllFamiliesService,
  getAllLabeledFamiliesService
} from './filter.service'
import { formatOnlyNumber } from 'utils/format'

class FilterProject extends Component {
  constructor(props) {
    super()

    this.state = {
      requestCount: 0,
      rangeInvalidCollection: false,
      rangeInvalidDelivery: false,
      rangeInvalidUpdate: false,

      CodigoPedidoVolume: '',
      IdOrigem: '',
      IdUnidadeNegocio: '',
      NumeroMaterial: '',
      IdStatus: '',
      IdEtapa: '',
      IdFamilia: '',
      IdFamiliaRotulada: '',
      DataColetaDe: '',
      DataColetaAte: '',
      DataEntregaDe: '',
      DataEntregaAte: '',
      DataAtualizacaoDe: '',
      DataAtualizacaoAte: '',

      suppliers: [],
      businessUnits: [],
      materials: [],
      status: [],
      steps: [],
      families: [],
      labeledFamilies: [],
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.initialData !== nextProps.initialData) {
      this.setState({
        initialData: nextProps.initialData,
        CodigoPedidoVolume: nextProps.initialData.CodigoPedidoVolume,
        IdOrigem: nextProps.initialData.IdOrigem,
        IdUnidadeNegocio: nextProps.initialData.IdUnidadeNegocio,
        NumeroMaterial: nextProps.initialData.NumeroMaterial,
        IdStatus: nextProps.initialData.IdStatus,
        IdEtapa: nextProps.initialData.IdEtapa,
        IdFamilia: nextProps.initialData.IdFamilia,
        IdFamiliaRotulada: nextProps.initialData.IdFamiliaRotulada,
        DataColetaDe: nextProps.initialData.DataColetaDe,
        DataColetaAte: nextProps.initialData.DataColetaAte,
        DataEntregaDe: nextProps.initialData.DataEntregaDe,
        DataEntregaAte: nextProps.initialData.DataEntregaAte,
        DataAtualizacaoDe: nextProps.initialData.DataAtualizacaoDe,
        DataAtualizacaoAte: nextProps.initialData.DataAtualizacaoAte,
      }, () => {
        this.getAllSuppliers()
        this.getAllBusinessUnits()
        this.getAllMaterials()
        this.getAllStatus()
        this.getAllSteps()
        this.getAllFamilies()
        this.getAllLabeledFamilies()
      })
    }
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  getAllSuppliers = async () => {
    this.handleFetch()

    try {
      const data = await getAllSuppliersService();
      this.processSuppliers(data);
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  getAllBusinessUnits = async () => {
    this.handleFetch()

    try {
      const data = await getAllBusinessUnitsService();
      this.processBusinessUnits(data);
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  getAllMaterials = async () => {
    this.handleFetch()

    try {
      const data = await getAllMaterialsService();
      this.processMaterials(data);
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  getAllStatus = async () => {
    this.handleFetch()

    try {
      const data = await getAllStatusService();
      this.processStatus(data);
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  getAllSteps = async () => {
    this.handleFetch()

    try {
      const data = await getAllStepsService();
      this.processSteps(data);
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  getAllFamilies = async () => {
    this.handleFetch()

    try {
      const data = await getAllFamiliesService();
      this.processFamilies(data);
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  getAllLabeledFamilies = async () => {
    this.handleFetch()

    try {
      const data = await getAllLabeledFamiliesService();
      this.processLabeledFamilies(data);
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  processSuppliers = (data) => {
    this.setState({
      suppliers: data
    })
  }

  processBusinessUnits = (data) => {
    this.setState({
      businessUnits: data
    })
  }

  processMaterials = (data) => {
    this.setState({
      materials: data
    })
  }

  processStatus = (data) => {
    this.setState({
      status: data
    })
  }

  processSteps = (data) => {
    this.setState({
      steps: data
    })
  }

  processFamilies = (data) => {
    this.setState({
      families: data
    })
  }

  processLabeledFamilies = (data) => {
    this.setState({
      labeledFamilies: data
    })
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  handleCloseSearch = () => {
    const params = {
      CodigoPedidoVolume: this.state.CodigoPedidoVolume,
      IdOrigem: this.state.IdOrigem,
      IdUnidadeNegocio: this.state.IdUnidadeNegocio,
      NumeroMaterial: this.state.NumeroMaterial,
      IdStatus: this.state.IdStatus,
      IdEtapa: this.state.IdEtapa,
      IdFamilia: this.state.IdFamilia,
      IdFamiliaRotulada: this.state.IdFamiliaRotulada,
      DataColetaDe: this.state.DataColetaDe,
      DataColetaAte: this.state.DataColetaAte,
      DataEntregaDe: this.state.DataEntregaDe,
      DataEntregaAte: this.state.DataEntregaAte,
      DataAtualizacaoDe: this.state.DataAtualizacaoDe,
      DataAtualizacaoAte: this.state.DataAtualizacaoAte
    }
    this.props.handleClose(params)
  }

  handleCleanClick = () => {
    const params = {
      CodigoPedidoVolume: '',
      IdOrigem: '',
      IdUnidadeNegocio: '',
      NumeroMaterial: '',
      IdStatus: '',
      IdEtapa: '',
      IdFamilia: '',
      IdFamiliaRotulada: '',
      DataColetaDe: '',
      DataColetaAte: '',
      DataEntregaDe: '',
      DataEntregaAte: '',
      DataAtualizacaoDe: '',
      DataAtualizacaoAte: '',
      rangeInvalidCollection: false,
      rangeInvalidDelivery: false,
      rangeInvalidUpdate: false
    }

    this.setState(params, () => this.props.handleClose(params))
  }

  proccessError = (exception) => {
    console.log('Erro encontrado', formatErrorMessage(exception))
  }

  changeFormInvalido = (inputName, invalido) => {
    this.setState({
      [inputName]: invalido
    })
  }

  isValidRanges = () => {
    const {
      rangeInvalidCollection,
      rangeInvalidDelivery,
      rangeInvalidUpdate
    } = this.state

    return (rangeInvalidDelivery || rangeInvalidCollection || rangeInvalidUpdate)
  }

  render() {
    const {
      CodigoPedidoVolume,
      IdOrigem,
      IdUnidadeNegocio,
      NumeroMaterial,
      IdStatus,
      IdFamilia,
      IdFamiliaRotulada,
      DataColetaDe,
      DataColetaAte,
      DataEntregaDe,
      DataEntregaAte,
      DataAtualizacaoDe,
      DataAtualizacaoAte,
      suppliers,
      businessUnits,
      materials,
      status,
      families,
      labeledFamilies
    } = this.state

    return (
      <Filter
        showFilter={this.props.showFilter}
        handleCleanClick={this.handleCleanClick}
        handleCloseSearch={this.handleCloseSearch}
        triggerClose={this.props.triggerClose}
        disabledSearch={this.isValidRanges()}
      >
        <Row>
          <Col xs={12} lg={12} md={12}>
            <Input
              label={intl.get('loadOptimization.orderCodeVolume')}
              value={CodigoPedidoVolume}
              onChange={event => this.changeValue('CodigoPedidoVolume', event.target.value === null ? '' : formatOnlyNumber(event.target.value))}
            />
          </Col>
        </Row>
        {this.props.showHistory &&
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                name='status'
                label={intl.get('commons.status')}
                value={IdStatus}
                options={status}
                valueKey='Id'
                labelKey='Descricao'
                onChange={value => this.changeValue('IdStatus', value === null ? null : value.Id)}
                placeholder={intl.get('commons.status')}
                searchable
                openOnFocus
                clearable
              />
            </Col>
          </Row>
        }
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              name='origem'
              label={intl.get('commons.source')}
              placeholder={intl.get('commons.source')}
              valueKey='IdFornecedor'
              labelKey='Descricao'
              value={IdOrigem}
              options={suppliers}
              onChange={value => this.changeValue('IdOrigem', value === null ? '' : value.IdFornecedor)}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              name='centro'
              label={intl.get('commons.destiny')}
              placeholder={intl.get('commons.destiny')}
              valueKey='IdUnidadeNegocio'
              labelKey='Descricao'
              value={IdUnidadeNegocio}
              options={businessUnits}
              onChange={value => this.changeValue('IdUnidadeNegocio', value === null ? '' : value.IdUnidadeNegocio)}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              name='familia'
              label={intl.get('commons.family')}
              value={IdFamilia}
              options={families}
              valueKey='Id'
              labelKey='Nome'
              onChange={value => this.changeValue('IdFamilia', value === null ? null : value.Id)}
              placeholder={intl.get('commons.family')}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              name='familiaRotulada'
              label={intl.get('commons.labeledFamily')}
              value={IdFamiliaRotulada}
              options={labeledFamilies}
              valueKey='Id'
              labelKey='Descricao'
              onChange={value => this.changeValue('IdFamiliaRotulada', value === null ? null : value.Id)}
              placeholder={intl.get('commons.labeledFamily')}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              required
              label={intl.get('commons.material')}
              placeholder={intl.get('commons.material')}
              valueKey='Id'
              labelKey='Descricao'
              value={NumeroMaterial}
              options={materials}
              onChange={value => this.changeValue('NumeroMaterial', value === null ? '' : value.Id)}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <DateRange
              labelDe={intl.get('commons.collectDateDE')}
              labelAte={intl.get('commons.collectDateAT')}
              dataDe={DataColetaDe}
              dataAte={DataColetaAte}
              onChangeDataDe={value => this.changeValue('DataColetaDe', value)}
              onChangeDataAte={value => this.changeValue('DataColetaAte', value)}
              onRangeIsValid={value => this.changeFormInvalido('rangeInvalidCollection', value)}
            />

            <DateRange
              labelDe={intl.get('loadOptimization.filter.deliveryDateOf')}
              labelAte={intl.get('loadOptimization.filter.deliveryDateBy')}
              dataDe={DataEntregaDe}
              dataAte={DataEntregaAte}
              onChangeDataDe={value => this.changeValue('DataEntregaDe', value)}
              onChangeDataAte={value => this.changeValue('DataEntregaAte', value)}
              onRangeIsValid={value => this.changeFormInvalido('rangeInvalidDelivery', value)}
            />
          </Col>
        </Row>
        {this.props.showHistory &&
          <Row>
            <Col xs={12} lg={12} md={12}>
              <DateRange
                labelDe={intl.get('loadOptimization.filter.updateDateOf')}
                labelAte={intl.get('loadOptimization.filter.updateDateUntil')}
                dataDe={DataAtualizacaoDe}
                dataAte={DataAtualizacaoAte}
                onChangeDataDe={value => this.changeValue('DataAtualizacaoDe', value)}
                onChangeDataAte={value => this.changeValue('DataAtualizacaoAte', value)}
                onRangeIsValid={value => this.changeFormInvalido('rangeInvalidUpdate', value)}
              />
            </Col>
          </Row>
        }
      </Filter>
    )
  }
}

FilterProject.propTypes = {
  initialData: PropTypes.object,
  showFilter: PropTypes.bool,
  handleClose: PropTypes.func,
  triggerClose: PropTypes.func,
  showHistory: PropTypes.bool,
}

FilterProject.defaultProps = {
  initialData: {},
  showHistory: false,
}

export default FilterProject

import React from 'react'
import * as S from './styled'
import { builderSuggestionCard } from '../../utils';
import ShopSuggestionMesterCard from '../../../../components/card/shop-suggestion/mester';

const CardsSuggestion = ({ data }) => {
  return (
    <S.Wrapper>
      {data.map(suggestion => builderSuggestionCard(suggestion)).map(suggestion => (
        <ShopSuggestionMesterCard
          key={suggestion.Id}
          userCard='AMBEV'
          dataCard={suggestion}
          filters={{}}
        />
      ))}
    </S.Wrapper>
  )
}

export default CardsSuggestion;

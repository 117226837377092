import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import locales from "./locales";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import "./app.css";
import "@hbsis.uikit/react/dist/uikit.css";
import {
  getUser,
  getTokenAuthorization,
  validateErrorSSO,
} from "./providers/authService";
import moment from "moment";
import momentPtBr from "./locales/moment/moment-pt-BR";
import momentEnUs from "./locales/moment/moment-en-US";
import momentEsEs from "./locales/moment/moment-es-ES";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import enUS from "date-fns/locale/en-US";
import es from "date-fns/locale/es";
import Loading from "components/center-loading";
import { initGeneralSettings } from "./services/general-settings/general-settings";
import { getDocumentTypes } from "./utils/document-types";
import AppRoutes from "./config/routes";
import { UserProvider } from "./context/userContext";
import { getLocaleForLanguageSheet } from "utils/userLanguage";
import { getAuthenticationProvider } from "utils/config-environment";
import { loadMonitoringApplications } from "providers/monitoring";
import ErrorPage from "pages/404";
import { OptionsTheme } from "pages/master-data/configurations/general-settings/models/generalSettingsModel";

const App = ({ accountInfo, error }) => {

  const [initDone, setInitDone] = useState(false);

  const initialConfigurations = async () => {
    window.document.title = "Ontime MRP";
    loadMonitoringApplications()
    const user = await validUser();
    await loadLocales(user);
    if (user) {
      await loadUserInfos();
      await loadDocumentTypes();
      await initGeneralSettings();
      setInitDone(true);
    } else {
      setTimeout(() => setInitDone(true), 3000);
    }
  };

  const loadUserInfos = async () => {
    await loadTokenAuthorization();
  };

  const loadLocales = async (user) => {
    const language = getLocaleForLanguageSheet(user.LocaleCode);
    await intl.init({
      currentLocale: language,
      locales,
    });

    setUpLocalizationLibsSettings(language);
  };

  const validUser = () => {
    return getUser();
  };

  const setUpLocalizationLibsSettings = (language) => {
    registerLocale("pt-BR", ptBR);
    registerLocale("es-ES", es);
    registerLocale("en-US", enUS);
    setDefaultLocale(language);

    switch (language) {
      case "pt-BR":
        moment.updateLocale("pt-br", momentPtBr);
        break;
      case "es-ES":
        moment.updateLocale("es", momentEsEs);
        break;
      case "en-US":
      default:
        moment.updateLocale("en", momentEnUs);
        break;
    }
  };

  const loadTokenAuthorization = async () => {
    const newToken = await getTokenAuthorization();
    localStorage.setItem("ValidationToken", newToken.Token);
  };

  const isAuthentication = () =>
    accountInfo &&
    accountInfo.account &&
    accountInfo.account.idToken &&
    accountInfo.account.idToken.emails;

  const loadDocumentTypes = async () => {
    const documentTypes = await getDocumentTypes();
    localStorage.setItem("DocumentTypes", JSON.stringify(documentTypes));
  }

  useEffect(() => {
    const theme = localStorage.getItem("THEME")
    if (!theme || theme == OptionsTheme.Legado) {
      import("./ontime.css")
    }
    if (theme == OptionsTheme.DesignSystem) {
      import("./ds.css")
    }

    if (isAuthentication()) {
      initialConfigurations();
    }
  }, []);

  useEffect(() => {
    if (validateErrorSSO(error)) {
      window.location.href = "/";
    }
  });


  if (initDone && accountInfo && getAuthenticationProvider() === "B2C") {
    return (
      <MuiThemeProvider>
        <UserProvider>
          <AppRoutes />
        </UserProvider>
      </MuiThemeProvider>
    );
  }

  if (initDone && getAuthenticationProvider() !== "B2C") {
    return (
      <MuiThemeProvider>
        <ErrorPage />
      </MuiThemeProvider>
    );
  }

  return (
    <MuiThemeProvider>
      <Loading isLoading={true} fullHeight />
    </MuiThemeProvider>
  );
};

export default App;

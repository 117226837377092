import {
  ClbTableTBodyCell, ClbTooltip
} from '@celebration/design-system-react'
import { StatusStock } from 'components/status-stock-tag';

const materials = (materials) => {
  const descriptions = materials.map(material => material.Description);
  return descriptions.join(', ');
}

export const HeaderTableBody = ({ data }) => (
  <>
    <ClbTableTBodyCell className='materialGroupDescriptionCell'>
      {data.MaterialGroupDescription}
    </ClbTableTBodyCell>
    <ClbTableTBodyCell className='statusStock'>
      <StatusStock status={data.StockProjectionStatus} />
    </ClbTableTBodyCell>
    <ClbTableTBodyCell className='destiny'>
      {data.Destiny}
    </ClbTableTBodyCell>
    <ClbTableTBodyCell className='labeledFamilyDescription'>
      <ClbTooltip label={materials(data?.Materials)} position="top">
        {data.LabeledFamilyDescription}
      </ClbTooltip>
    </ClbTableTBodyCell>
  </>
)
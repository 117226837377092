import styled from 'styled-components'

export const Wrapper = styled.div`
  .suggestion-list-header{
    display: none !important;
  }
`

export const WrapperDialog = styled.div`
  .MuiDialog-paperScrollPaper{
    left: -200px !important;
  }
`

export const WrapperContent = styled.div`
  display: flex;
  position: fixed;
  transform: translateX(-50%) translateY(-50%);
  max-height: 90%;
`

export const ContentSuggestion = styled.div`
  background: #FFFF;
  min-width: 550px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
  .btn-delete-suggestion-item{
    margin-right: 10px;
  }

  ${p => !p.showChart && 'width: 550px;'}
`

export const ContentChart = styled.div`
  background: #FFFF;
  padding: 10px;
  left: 40px;
  position: relative;
  min-width: 500px;
`

export const ArrowDetail = styled.span`
  position: absolute;
  left: -23px;
  background: #FFFF;
  height: 50px;
  width: 50px;
  transform: rotate(45deg);
  top: 25px;
  z-index: 999;
  cursor: auto;
`

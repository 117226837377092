import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Checkbox } from '@hbsis.uikit/react'
import { Row, Col } from 'react-flexbox-grid'

import Session from 'utils/user-storage'

import StatusSpan from 'components/status-span'
import intl from 'react-intl-universal'

import StyledComposicaoList from './styledComposicaoList'
import { formatNumber, formatValueByCurrency, formatWithoutLeadingZeros } from 'utils/format'
import Feature from 'components/feature';
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'
import { TextSpan } from 'components/text-label'

class PedidosAprovados extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pedidos: props.pedidos,
      checkedAll: false,
      withBorder: true,
      withHover: true,
      borderColor: '#afcef3',
      borderColorHover: '#2870b2'
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const pedidosReprojetados = nextProps.pedidos
    if (pedidosReprojetados) {
      this.atualizarPrioridadeProjecao(pedidosReprojetados)
    }
  }

  atualizarPrioridadeProjecao = (pedidosReprojetados) => {
    const pedidos = this.state.pedidos.map((pedido, index) => {
      const p = pedidosReprojetados.find(pedidoReprojetado => pedidoReprojetado.IdPedido === pedido.IdPedido);
      return {
        ...pedido,
        Prioridade: p ? p.Prioridade : -1
      }
    })

    this.setState({ pedidos })
  }

  onRowSelect = (row) => {
    let pedidos = this.state.pedidos.map((pedido, index) => {
      if (pedido.IdPedido === row.IdPedido) {
        pedido.Selecionado = !pedido.Selecionado
      }
      return pedido
    })
    this.handleSelectPedidos(pedidos)
  }

  checkedAll = () => {
    let pedidos = this.state.pedidos.map((pedido, index) => {
      pedido.Selecionado = !this.state.checkedAll
      return pedido
    })
    this.setState({
      checkedAll: !this.state.checkedAll
    })
    this.handleSelectPedidos(pedidos)
  }

  handleSelectPedidos = (pedidos) => {
    const selecteds = pedidos.filter((pedido) => { return pedido.Selecionado }).map((v, i) => v.IdPedido)
    const checkedAll = selecteds.length == pedidos.length
    this.setState({
      pedidos,
      checkedAll
    }, () => { this.props.handleSelectRequest(selecteds) })
  }

  handleRedirect = (pedido) => {
    let url = '';

    if (this.getTypeUserCard() == 'AMBEV')
      url = `/stocksDetail/${pedido.IdFamiliaRotuladaCentro}/${pedido.IdPedido}/P`
    else
      url = pedido.PedidoVolume ? `/ordersVisionDetail/${pedido.IdPedido}/V` : `/ordersVisionDetail/${pedido.IdPedido}/P`;

    if (this.props.history) {
      this.props.history.push(url)
    } else if (this.props.redirectRoute) {
      this.props.redirectRoute(url)
    }
  }

  getTypeUserCard = () => {
    const user = Session.get()
    return user.TipoUsuario === 1 ? 'AMBEV' : 'SUPPLIER'
  }

  getColorName = (possuiComposicao, pedidoEmNegociacao) => {
    if (possuiComposicao)
      return "ebf6fe";
    if (pedidoEmNegociacao)
      return "e6e6e6";
    else
      return "ffffff";
  }

  renderTaskIndicator = (pedido) => {
    return (
      <div
        title={`${this.searchTaskType(pedido.PossuiComposicao, pedido.EmNegociacaoEmergencial)}`}
        onClick={() => this.handleRedirect(pedido)}
      >
        <span
          className={"column-codigo-link"}>
          {pedido.IdPedido}
        </span>
        {pedido.PossuiComposicao &&
          <span className="column-indicator">!</span>
        }
        {pedido.EmNegociacaoEmergencial &&
          <span className="column-indicator">?</span>
        }
      </div>
    )
  }

  searchTaskType = (possuiComposicao, emNegociacaoEmergencial) => {
    if (possuiComposicao) {
      return intl.get('loadComposition.message2');
    } else if (emNegociacaoEmergencial) {
      return intl.get('loadComposition.message3');
    } else {
      return intl.get('loadComposition.message1');
    }
  }

  render() {
    let { pedidos } = this.state
    let { enableSelected } = this.props
    let usuarioAdmin = Session.get().TipoUsuario === 1

    return (
      <StyledComposicaoList
        withBorder={this.state.withBorder}
        withHover={this.state.withHover}
        borderColor={this.state.borderColor}
        borderColorHover={this.state.borderColorHover}
      >
        <Row className="rowListHeader">
          <Col className="align-center column-checkbox">
            <Checkbox
              className="align-check"
              name="checkbox-all"
              text=" "
              checked={this.state.checkedAll}
              onChange={this.checkedAll}
              disabled={!enableSelected}
            />
          </Col>
          <Col className="align-center column-task">{intl.get('commons.requestBuy')}</Col>
          <Col className="align-center column-item">{intl.get('commons.item')}</Col>
          <Col className="align-center column-codigo">{intl.get('commons.portalCode')}</Col>
          <Col className="align-center column-material">{intl.get('commons.material')}</Col>
          <Feature flags={GeneralSettingParameter.ViewPriceColumnsOnLoadCompositionView}>
            <Col className="align-center column-liquidPrice">{intl.get('commons.netPrice')}</Col>
            <Col className="align-center column-unitPrice">{intl.get('commons.unitPrice')}</Col>
          </Feature>
          <Feature flags={GeneralSettingParameter.OrderSettings}>
            <Col className="align-center column-sa">{intl.get('commons.schedulingAgreement')}</Col>
          </Feature>
          <Col className="align-center column-time">{intl.get('commons.deliveryTime')}</Col>
          <Col className="align-center column-lote">{intl.get('commons.lot')}</Col>
          <Col className="align-center column-quantidade">{intl.get('commons.amount')}</Col>
          <Col className="align-center column-um">UM</Col>
          <Col className="align-center column-date">{intl.get('commons.colPrevision')}</Col>
          <Col className="align-center column-date">{intl.get('commons.deliveryPrevision')}</Col>
        </Row>
        <Row className="containerLista">
          <Col xs={12} md={12} lg={12} className="pt-0 pb-0">
            {(!pedidos || pedidos.length === 0) &&
              <Row className="rowList">
                <Col xs={12} md={12} lg={12} className="align-center">
                  <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
                </Col>
              </Row>
            }
            {pedidos && !!pedidos.length && pedidos.map((pedido, i) => (
              <Row
                key={i}
                className={`${pedido.Selecionado ? " rowListSelected" : "rowList"}`}
                onClick={() => enableSelected ? this.onRowSelect(pedido) : null}
                style={{
                  width: "100%",
                  cursor: 'pointer',
                  borderBottom: '1px dashed #98c1dc',
                  backgroundColor: `#${this.getColorName(pedido.PossuiComposicao, pedido.EmNegociacaoEmergencial)}`
                }}
              >
                <Col className="align-center column-checkbox">
                  <Checkbox
                    className="align-check"
                    name={`checkbox-${i}`}
                    text=" "
                    checked={pedido.Selecionado}
                    onClick={() => this.onRowSelect(pedido)}
                    disabled={!enableSelected}
                  />
                </Col>
                <Col className="align-center column-task">{pedido.IdPedidoSap}</Col>
                <Col className="align-center column-item">{pedido.IdItemPedidoSap}</Col>
                <Col className="align-center column-codigo">
                  {this.renderTaskIndicator(pedido)}
                </Col>
                <Col className="align-center column-material">
                  <TextSpan>
                    {`${formatWithoutLeadingZeros(pedido.NumeroMaterial)} - ${pedido.Material}`}
                  </TextSpan>
                </Col>
                <Feature flags={GeneralSettingParameter.ViewPriceColumnsOnLoadCompositionView}>
                  <Col className="align-center column-liquidPrice">{pedido.PrecoLiquido == null ? "-" : formatValueByCurrency(pedido.PrecoLiquido, pedido.Moeda)}</Col>
                  <Col className="align-center column-unitPrice">{pedido.UnidadePreco == null ? "-" : formatNumber(pedido.UnidadePreco)}</Col>
                </Feature>
                <Feature flags={GeneralSettingParameter.OrderSettings}>
                  <Col className="align-center column-sa">{pedido.SchedulingAgreement ? pedido.SchedulingAgreement : '-'}</Col>
                </Feature>
                <Col className="align-center column-time">{pedido.HoraEntrega ? moment(pedido.HoraEntrega, "HH:mm:ss").format("LT") : ''}</Col>
                <Col className="align-center column-lote">{pedido.Lote}</Col>
                <Col className="align-center column-quantidade">
                  {usuarioAdmin &&
                    <StatusSpan
                      priority={pedido.Prioridade}
                      label={formatNumber(pedido.Quantidade)}
                    />
                  }
                  {!usuarioAdmin &&
                    formatNumber(pedido.Quantidade)
                  }
                </Col>
                <Col className="align-center column-um">
                  {usuarioAdmin &&
                    <StatusSpan
                      priority={pedido.Prioridade}
                      label={pedido.UnidadeMedida}
                    />
                  }
                  {!usuarioAdmin &&
                    pedido.UnidadeMedida
                  }
                </Col>
                <Col className="align-center column-date">
                  {usuarioAdmin &&
                    <StatusSpan
                      priority={pedido.Prioridade}
                      label={moment(pedido.DataColeta).format('L')}
                    />
                  }
                  {!usuarioAdmin &&
                    moment(pedido.DataColeta).format('L')
                  }
                </Col>
                <Col className="align-center column-date">
                  {usuarioAdmin &&
                    <StatusSpan
                      priority={pedido.Prioridade}
                      label={moment(pedido.DataEntrega).format('L')}
                    />
                  }
                  {!usuarioAdmin &&
                    moment(pedido.DataEntrega).format('L')
                  }
                </Col>
              </Row>
            ))
            }
          </Col>
        </Row>
      </StyledComposicaoList>
    )
  }
}

PedidosAprovados.propTypes = {
  handleSelectRequest: PropTypes.func.isRequired,
  QuantidadeTotal: PropTypes.number.isRequired,
}

PedidosAprovados.defaultProps = {
  enableSelected: true,
  QuantidadeTotal: 0,
}

export default PedidosAprovados

import Fetch from 'utils/fetch'

export const searchWallets = () => {
    return Fetch.get(`/carteira/usuariocoordenacao`).then(({ data }) => data)
}

export const searchCoordinations = () => {
    return Fetch.get(`/coordenacao/usuariocoordenacao`).then(({ data }) => data)
}

export const searchUsers = () => {
    return Fetch.get(`/usuario/buscaUsuariosAmbev`).then(({ data }) => data)
}
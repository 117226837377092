import React, { useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core'
import intl from 'react-intl-universal'
import { ConsolidatedStocksSuggestionListHeader } from '../consolidated-stocks-suggestion-list/suggestion-list-header'
import { ConsolidatedStocksSuggestionListItem } from '../consolidated-stocks-suggestion-list/suggestion-list-item'
import { acceptSuggestions, getSuggestionsList } from 'features/consolidated-stocks/consolidated-stocks.service'
import StyledSuggestionList from 'components/suggestions-list/suggestions-list.styled'
import * as S from './styled'
import { Button } from 'components/uikit-adapter'
import Session from 'utils/user-storage'
import { ArrowDetail } from 'pages/load-composition-building/components/modal-create-suggestion/styled'
import { ContentChart } from '../consolidated-stocks-suggestions-modal/styled'
import ChartStocks from 'components/chart-stocks'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { proccessErro } from './utils'

export const ConsolidatedStocksSuggestionsModal = ({ open, close, labeledFamilyCenterId }) => {
  const { showFeedback } = useGlobalContext()
  const [updateChart, setUpdateChart] = useState(labeledFamilyCenterId)

  const StyledDialogContent = withStyles(() => ({
    root: {
      zIndex: 800,
      borderBottom: 'none'
    }
  }))(DialogContent)

  const [data, setData] = useState([])
  const [checkAll, setCheckAll] = useState(false)

  const getModalData = async () => {
    const { Data } = await getSuggestionsList({
      LabeledFamilyCenterId: [labeledFamilyCenterId]
    })
    setData(Data)
  }

  const handleUpdateGraphic = (message) => {
    setUpdateChart(prevState => ({...prevState}))
    showFeedback(message)
  }

  const handleAcceptSuggestion = async () => {
    try {
      const items = data.filter((item) => item.checked).map((_) => _.Id)
      await acceptSuggestions({
        UserId: Session.get().Id,
        LabeledFamilyCenterId: labeledFamilyCenterId,
        Suggestions: items
      })

      handleUpdateGraphic(intl.get('stocks.timeline.feedback.successfullyAcceptedSuggestions'))
    } catch (error) {
      proccessErro(showFeedback, error)
    }
  }

  const handleCheckAllItems = () => updateData()

  const updateData = (newItem, index) => {
    const newData = data.map((item, i) => {
      if (!newItem) {
        setCheckAll(!checkAll)
        return {
          ...item,
          checked: !checkAll
        }
      }
      if (index === i) {
        return {
          ...newItem
        }
      }
      else {
        return {
          ...item
        }
      }
    })
    setData(newData)
  }

  useEffect(() => {
    if (open) {
      getModalData()
    }
  }, [open])

  return (
    <Dialog
      PaperProps={{
        style: {
          overflow: 'visible',
          width: "560px",
          maxWidth: '720px',
          position: "relative",
          right: "250px",
          minHeight: "840px",
          maxHeight: "840px",
          zIndex: 999
        }
      }}
      open={open}
    >
      <S.DialogTitle>
        <S.DialogTitleText>
          {intl.get("stocks.stockDetail.accSuggestion")}
        </S.DialogTitleText>
        <S.DialogTitleIcon title='Fechar' onClick={() => close()} />
      </S.DialogTitle>
      <StyledDialogContent style={{ overflowY: 'scroll' }}>
        <StyledSuggestionList>
          <ConsolidatedStocksSuggestionListHeader
            checked={checkAll}
            checkAllItems={handleCheckAllItems}
          />
          {data.map((item, index) => (
            <ConsolidatedStocksSuggestionListItem
              key={item.Id}
              item={item}
              index={index}
              checked={item.checked}
              onSaveItem={updateData}
              updateGraphic={handleUpdateGraphic}
              showFeedback={showFeedback}
            />
          ))}
        </StyledSuggestionList>
      </StyledDialogContent>
      <S.DialogActions>
        <Button
          width="165px"
          type="primary"
          value={intl.get("stocks.stockDetail.accSuggestion")}
          onClick={() => handleAcceptSuggestion()}
        />
      </S.DialogActions>
      <ContentChart>
        <ArrowDetail />
        <ChartStocks
          idFamiliaRotuladaCentro={labeledFamilyCenterId}
          updateChart={updateChart}
          categoryField="Categoria"
          pedidoVolume={false}
          estoqueVariavel={false}
          transfSaida={false}
          transfEntrada={false}
          hideLastUpdate
        />
      </ContentChart>
    </Dialog>
  )
}
import React, { Component } from "react";
import PropTypes from "prop-types";
import Dialog from "material-ui/Dialog";
import SessionUser from "utils/user-storage";
import { Button, Textarea, Input } from "@hbsis.uikit/react";
import { Row, Col } from 'react-flexbox-grid'
import DeadlineControl from 'components/deadline-control'
import SnackBar from 'material-ui/Snackbar'
import Loading from 'components/center-loading'
import moment from 'moment'
import { compareDates } from 'utils/date'
import CompositionOrders from "../compositon-orders"
import InformationOrder from "./information-orders"
import { anteciparAtrasarPedido } from "./inform-advance-delay-order.service"
import intl from 'react-intl-universal'

import StyledInformAdvanceDelayOrder from './inform-advance-delay-order.styled'
import InputNumber from "components/input-number";
import { parseFloatByLocale } from "locales/utils";

class InformAdvanceDelayOrder extends Component {
  constructor() {
    super();

    this.state = {
      novaDataColeta: '',
      novaDataEntrega: '',
      horaEntrega: null,
      textMsg: '',
      observacao: '',
      showMsg: false,
      isFetching: false,
      showCompositionOrders: false,
      quantidade: ''
    };
  }

  changeValue = (event) => {
    const prop = event.target.name
    const value = event.target.value

    this.setState({
      [prop]: value
    })
  }

  proccessError = (message) => {
    this.setState({
      showMsg: true,
      textMsg: message
    })
  }

  finalizeProccessError = () => {
    this.setState({
      showMsg: false,
      textMsg: ''
    })
  }

  startFetching() {
    this.setState({
      isFetching: true
    })
  }

  stopFetching() {
    this.setState({
      isFetching: false
    })
  }

  save = () => {
    const { novaDataColeta, novaDataEntrega, observacao, quantidade } = this.state
    const { pedido } = this.props
    const dataColetaAtual = moment(pedido.DataColeta, "DD/MM/YYYY").format("YYYY-MM-DD")
    const dataEntregaAtual = moment(pedido.DataEntrega, "DD/MM/YYYY").format("YYYY-MM-DD")
    const dataColeta = !!novaDataColeta ? novaDataColeta : dataColetaAtual
    const dataEntrega = !!novaDataEntrega ? novaDataEntrega : dataEntregaAtual

    if ((!novaDataColeta && !novaDataEntrega) && !quantidade ) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.enterLeastOneDate'))
      return false
    } else if (!!novaDataColeta && compareDates(novaDataColeta, moment().format("YYYY-MM-DD")) < 0) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.collectionDateCannotEarlierThanCurrentDay'))
      return false
    } else if (!!novaDataEntrega && compareDates(novaDataEntrega, moment().format("YYYY-MM-DD")) < 0) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.deliverynDateCannotEarlierThanCurrentDay'))
      return false
    } else if (compareDates(dataColeta, dataEntrega) > 0) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.collectionDateCannotBegreaterThanDeliveryDate'))
      return false
    } else if (compareDates(novaDataColeta, dataColetaAtual) === 0 && compareDates(novaDataEntrega, dataEntregaAtual) === 0) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.collectionAndDeliveryDateAreSamePrevious'))
      return false
    } else if (compareDates(novaDataColeta, dataColetaAtual) === 0 && !novaDataEntrega) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.collectionDateIsSameAsPrevious'))
      return false
    } else if (compareDates(novaDataEntrega, dataEntregaAtual) === 0 && !novaDataColeta) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.deliveryDateIsSameAsPrevious'))
      return false
    } else if (!observacao) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.observationFieldIsMandatory'))
      return false
    } else if (parseFloatByLocale(quantidade) === 0) {
      this.proccessError(intl.get('stocks.informAdvanceOrder.feedback.quantityCannotBeZero'))
      return false
    }
    this.setState({
      showCompositionOrders: true
    })
  }

  handleConfirm = async () => {

    try {

      this.startFetching()

      const user = SessionUser.get().Id;
      const { novaDataColeta, novaDataEntrega, observacao, horaEntrega, quantidade } = this.state

      const body = {
        IdUsuario: user,
        IdPedido: this.props.pedido.NumeroPedido,
        DataColeta: novaDataColeta,
        DataEntrega: novaDataEntrega,
        Observacao: observacao,
        HoraEntrega: horaEntrega,
        Quantidade: parseFloatByLocale(quantidade),
      }

      await anteciparAtrasarPedido(body)

      this.props.onSave()
    } catch (error) {
      this.proccessError(`Erro: ${error.response && error.response.data ? error.response.data.Message : ''}`)
    }
    finally {
      this.stopFetching()
    }
  }

  changeValueDeadline = (coleta, entrega, item) => {
    this.setState({
      novaDataColeta: coleta,
      novaDataEntrega: entrega
    })
  }

  changeValueHoraEntrega = (event) => {
    this.setState({
      horaEntrega: event.target.value
    })
  }

  changeValueQuantity = (value) => {
    this.setState({
      quantidade: value
    })
  }

  render() {
    const { open, onClose, pedido } = this.props
    const { isFetching, showMsg, textMsg, novaDataColeta, novaDataEntrega, observacao, showCompositionOrders, horaEntrega, quantidade } = this.state

    return (
      <div with="100%" height="100%">
        <Loading isLoading={isFetching} />

        <Dialog
          title={intl.get('stocks.informAdvanceOrder.informAnticipationDelay')}
          contentStyle={{ width: "650px" }}
          open={open}
          onRequestClose={onClose}
          autoScrollBodyContent
        >
          <StyledInformAdvanceDelayOrder>
            <label className='label-style'>{intl.get('stocks.informAdvanceOrder.orderInformation')}</label>
            <div className='container'>
              <InformationOrder pedido={pedido} />
            </div>

            <Row>
              <Col xs={8}>
                <span className='span'>
                  {intl.get('commons.material')}
                  <p> {pedido.Material} </p>
                </span>
              </Col>
              <Col xs={4} >
                <InputNumber
                  type='default'
                  label={intl.get('stocks.informAdvanceOrder.quantity')}
                  value={quantidade}
                  onChange={value => this.changeValueQuantity(value)}
                  allowDecimals
                  decimalsLimit={3}
                  className='inform-advance-order-quantity'
                  disabled={pedido.DesabilitarQuantidade}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={8}>
                <DeadlineControl
                  TransitTime={pedido.TransitTime}
                  OnChange={(coleta, entrega) => this.changeValueDeadline(coleta, entrega, pedido)}
                  Coleta={novaDataColeta}
                  DataEntregaDe={novaDataEntrega}
                  TituloColeta={intl.get('stocks.informAdvanceOrder.newCollectionDate')}
                  TituloEntrega={intl.get('stocks.informAdvanceOrder.newDeliveryDate')}
                  ColetaDisabled={pedido.DesabilitarDataColeta}
                  CadeadoDisabled={pedido.DesabilitarDataColeta}
                  Identificador={`antecipacao-atraso-pedido-` + pedido.NumeroPedido}
                />
              </Col>
              <Col xs={4} style={{ paddingTop: '5px' }}>
                <Input
                  label={intl.get('stocks.informAdvanceOrder.newDeliveryTime')}
                  name='HoraEntrega'
                  htmlType='time'
                  value={horaEntrega}
                  onChange={event => this.changeValueHoraEntrega(event)}
                  className='txtHoraEntrega'
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <Textarea
                  name='observacao'
                  label={intl.get('commons.observation')}
                  value={observacao || ''}
                  onChange={this.changeValue}
                  className="txtObservacao"
                />
              </Col>
            </Row>

            <div className="footer-dialog-buttons">
              <Button
                type="default"
                htmlType="button"
                value={intl.get('geral.buttonsDefault.close')}
                onClick={onClose}
                className="button btn-close"
              />
              <Button
                type="primary"
                value={intl.get('geral.buttonsDefault.save')}
                className="button btn-save"
                disabled={isFetching}
                onClick={this.save}
              />
            </div>

            <SnackBar
              message={textMsg}
              open={showMsg}
              autoHideDuration={4000}
              onRequestClose={this.finalizeProccessError}
              style={{ width: '100%', textAlign: 'center', display: 'block' }}
            />

          </StyledInformAdvanceDelayOrder>
        </Dialog>

        {
          showCompositionOrders &&
          <CompositionOrders
            pedido={pedido}
            onClose={onClose}
            handleConfirm={this.handleConfirm}
            message={intl.get('stocks.timeline.feedback.messageCompositionOrders2')}
          />
        }

      </div >
    );
  }
}

InformAdvanceDelayOrder.propTypes = {
  open: PropTypes.bool.isRequired,
  pedido: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

export default InformAdvanceDelayOrder

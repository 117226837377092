const momentPtBr = {
    parentLocale: "pt-br",
    longDateFormat: {
        "[DM]": "DD/MM",
        "[DDMMYY]": "DD/MM/YY",
        'L': "DD/MM/YYYY",
        'l': "DD/MM/YYYY"
    }
};

export default momentPtBr;

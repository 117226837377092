import styled from "styled-components"

export const ButtonsList = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    margin-left: 10px;
  }
}

`
export const LoadCompositionButtonAlert = styled.button`
  border: 1px solid #ff8880;
  outline: 0;
  cursor: pointer;
  width: 50px;
  height: 40px;
  background-color: #ffcfcc;
  font-weight: 600;
  transition: background-color 0.1s linear,border-color 0.1s linear;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #fb8486;
  }

`

export const ContentButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  img {
    padding: 0px 4px;
    width: 24px;
    height: 24px;
  }

`

import intl from 'react-intl-universal'
import { perfilPcpUnidadeLogado } from 'services/permissions/getPermissions'

const selectPropsDefault = {
  valueKey: 'Id',
  labelKey: 'Descricao',
  value: [],
  options: [],
  searchable: true,
  clearable: true,
  multi: true,
  hidden: false
}

export const inputFiltersDefault = () => ([])

export const dateRangeFiltersInitial = () => ([
  {
    labelDe: intl.get('requestOrders.deliveryDateDE'),
    labelAte: intl.get('requestOrders.deliveryDateAT'),
    dataDe: 'DeliveryDateMin',
    dataAte: 'DeliveryDateMax',
    noMinDate: true,
  }
])

export const selectsFiltersDefault = () => ([
  {
    ...selectPropsDefault,
    name: 'Status',
    label: intl.get('requestOrders.step'),
    placeholder: intl.get('requestOrders.step'),
    labelKey: 'Description'
  },
  {
    ...selectPropsDefault,
    name: 'SupplierIds',
    label: intl.get('commons.source'),
    placeholder: intl.get('commons.source'),
    labelKey: 'Descricao'
  },
  {
    ...selectPropsDefault,
    name: 'BusinessUnitIds',
    label: intl.get('requestOrders.destiny'),
    placeholder: intl.get('transports.compoCharge.actions.unityBussines'),
    labelKey: 'Description',
    disabled: perfilPcpUnidadeLogado()
  },
  {
    ...selectPropsDefault,
    name: 'MaterialNumber',
    label: intl.get("commons.material"),
    placeholder: intl.get("commons.material"),
    labelKey: 'Description',
  },
  {
    ...selectPropsDefault,
    name: 'MaterialGroupIds',
    label: intl.get('commons.wallet'),
    placeholder: intl.get('commons.wallet'),
    labelKey:'Description'
  }
]);

export const verifyFilter = (filters) => { 
  const _filtersTemp = { ...filters, _page: null, _size: null, UseFilterDefault: false, IsFiltered: false };
  return Object.values(_filtersTemp).some(x => Array.isArray(x) ? x.length : x);
};


import React, { Component } from "react";
import { withRouter } from "react-router";

import Card from "./components/cards";
import Icon from "components/icon";
import Header from "components/header";
import Option from "components/select-option/option";
import Loading from "components/center-loading";
import Message from "components/message";
import SelectOption from "components/select-option";
import FiltroProgramacao from "components/filters/filter-programacao";
import Session from "utils/user-storage";
import StorePersist from "utils/store-persist";

import ImgGrid from "images/icn-squares.svg";
import ImgLista from "images/icn-list.svg";
import IconEmpty from "images/iconEmpty.icon";
import IconCalendarSchedule from "images/icn-calendar-schedule.svg";
import IconFilterGray from "images/icn-filter-gray.svg";
import IconFilterBlue from "images/icn-filter-blue.svg";

import { Button, Layout } from "@hbsis.uikit/react";

import intl from 'react-intl-universal'

import "./stocks.css";
import ListStocks from "./components/list-stocks";
import Pagination from "components/pagination";

import * as service from './stocks.service';
import { formatErrorMessage } from 'utils/handle-error';
import FilterStock from "./components/filter";
import { filtersStocksDefault } from "./components/filter/utils";
import { mapDtoRequestStocks, mapDtoResponseStocks } from "./utils/index";

export const PERFIL_PCP_UNIDADE = { value: 4 };
export const TIPO_VISUALIZACAO_GRID = 'Grid'

export const scrollToRef = ref =>
  ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

class Stocks extends Component {
  constructor() {
    super();

    this.contentScroll = React.createRef();

    this.state = {
      requestCounter: 0,
      showFilter: false,
      showFilterProgramacao: false,
      loadRegs: 0,
      regsPerRequest: 50,
      totalStocks: 0,
      stocks: [],
      useFilterDefault: false,
      filters: filtersStocksDefault,
      filtersProgramacao: {
        idUsuarioProgramado: "",
        usuarioSelecionado: {
          Descricao: intl.get('stocks.header.actions.userNotSelected')
        },
        programados: true,
        naoProgramados: true
      },
      usuariosProgramacao: [],
      etapas: [],
      status: [],
      unidadesNegocio: [],
      familiasRotuladas: [],
      carteiras: [],
      materiaisAtivos: [],
      familias: [],
      coordenacoes: [],
      suppliers: [],
      tipoVisualizacaoEstoque: "Grid",
      showMsg: false,
      titleMsg: "",
      textMsg: "",
    };
  }

  componentDidMount() {
    this.loadPage();
  }

  loadPage = async () => {
    const tipoVisualizacaoEstoque = StorePersist.getValuesJSON("tipoVisualizacaoEstoque");

    this.setState({
      tipoVisualizacaoEstoque: tipoVisualizacaoEstoque
        ? tipoVisualizacaoEstoque
        : this.state.tipoVisualizacaoEstoque
    });

    const filterMyItensOnly = StorePersist.getValuesJSON("filterMyItemsOnly")

    this.setState({
      useFilterDefault: filterMyItensOnly
    });

    const filterSchedule = StorePersist.getValuesJSON("filterSchedule")
    
    if (filterSchedule) {
      this.setState({
        filtersProgramacao: filterSchedule
      });

    }

    const filters = JSON.parse(localStorage.getItem(("filtersStocksMulti")))
    this.setState({
      showFilter: true,
      filters: filters ? filters : this.state.filters
    }, () => {
      this.searchFilters(this.state.filters);
    })

    this.searchStockData();
  }

  proccessStocks = data => {
    this.setState({
      stocks: data.Data.map(x => mapDtoResponseStocks(x)),
      totalStocks: data.TotalItems
    });
  };

  processData = (property, data) => {
    this.setState({
      [property]: data
    });
  };

  showFilterProgramacao = () => {
    this.setState({
      showFilterProgramacao: !this.state.showFilterProgramacao,
      showFilter: false
    });
  };

  showFilter = () => {
    this.setState({
      showFilter: !this.state.showFilter,
      showFilterProgramacao: false
    });
  };

  closeFilter = filter => {
    this.setState({
      [filter]: false
    });
  };

  verifyFilter = () => Object.values(this.state.filters).some(x => Array.isArray(x) ? x.length : x);

  searchFiltersProgramacao = filters => {
    this.showFilterProgramacao();
    StorePersist.setValuesJSON("filterSchedule", filters)
    this.setState(
      {
        stocks: [],
        loadRegs: 0,
        filtersProgramacao: filters
      },
      () => {
        this.searchStock();
      }
    );
  };

  handleSearchStocksFilter = filters => {
    this.searchFilters(filters)
  }

  searchFilters = filters => {
    this.showFilter();
    StorePersist.setValuesJSON("filtersStocksMulti", filters)
    this.setState(
      {
        filters: filters,
        stocks: [],
        loadRegs: 0
      },
      () => this.searchStock(0)
    );
  };

  searchStockData = async () => {
    this.handleFetch();
    try {
      const user = Session.get();
      const data = await service.getStockData(this.state.useFilterDefault, user.Id)
      this.processData("usuariosProgramacao", data);
    } catch (err) {
      this.proccessError(err);
    }
    finally {
      this.handleFetch(false);
    }
  };

  redirectToDetail = (event, id) => {
    this.props.history.push(`stocksDetail/${id}`);
  };

  searchStock = async (loadRegs) => {
    this.setState({
      loadRegs
    }, async () => {
      try {
        this.handleFetch();
        const { filtersProgramacao, filters } = this.state;
        const user = Session.get();

        const _filters = {
          ...mapDtoRequestStocks(filters, filtersProgramacao, user),
          _page: this.state.loadRegs,
          _size: this.state.regsPerRequest
        }
        const data = await service.getStock(_filters);

        this.setState({
          stocks: []
        }, () => this.proccessStocks(data));
      } catch (err) {
        this.proccessError(err);
      } finally {
        this.handleFetch(false);
      }
    })
  };

  iniciarProgramacao = async (params) => {
    this.handleFetch();
    try {
      this.closeFilter("showFilterProgramacao");
      const user = Session.get();

      await service.startProgramming(params.idUsuarioProgramado, user.Id)

      await this.searchStockData();
      this.searchFiltersProgramacao(params);
    } catch (err) {
      this.proccessError(err);
    } finally {
      this.handleFetch(false);
    }
  };

  loadMore = (page) => {
    this.handleFetch();
    scrollToRef(this.contentScroll);

    this.setState(({
      loadRegs: page,
    }), () => this.searchStock(this.state.loadRegs))
    this.handleFetch(false);
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCounter: isFetching ? prevState.requestCounter + 1 : prevState.requestCounter - 1,
    }));
  };

  changeValue = value => {
    StorePersist.setValuesJSON("tipoVisualizacaoEstoque", value);
    this.setState({
      tipoVisualizacaoEstoque: value
    });
  };

  proccessError = (exception) => {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
      textMsg: formatErrorMessage(exception)
    });
  };

  render() {
    const { requestCounter, tipoVisualizacaoEstoque, stocks } = this.state;

    return (
      <div className="container-stocks" ref={this.contentScroll}>
        <Loading isLoading={requestCounter > 0} />
        <Header title={intl.get('stocks.header.title')}>
          <div className="buttons-header-container">
            <div
              title={`${intl.get('stocks.header.actions.programation')}${this.state.filtersProgramacao.usuarioSelecionado
                ? this.state.filtersProgramacao.usuarioSelecionado.Descricao
                : ""
                }`}
            >
              <Button
                width="50px"
                icon={IconCalendarSchedule}
                onClick={this.showFilterProgramacao}
              />
            </div>
            <div className="filterContainer">
              <Button
                onClick={this.showFilter}
                type="default"
                value={intl.get('filters.filter')}
                icon={this.verifyFilter() ? IconFilterBlue : IconFilterGray}
                className={"button-filter"}
              />
            </div>
          </div>
        </Header>

        <Layout.Sidebar
          width="450px"
          visible={this.state.showFilter}
          triggerClose={() => this.closeFilter("showFilter")}
          background="#FFFFFF"
          offsetTop="96px"
          side="right"
          block
          float
          icon={IconEmpty}
        >
          <FilterStock
            searchStoks={this.handleSearchStocksFilter}
          />
        </Layout.Sidebar>

        <Layout.Sidebar
          width="450px"
          visible={this.state.showFilterProgramacao}
          triggerClose={() => this.closeFilter("showFilterProgramacao")}
          background="#FFFFFF"
          offsetTop="96px"
          side="right"
          block
          float
          icon={IconEmpty}
        >
          <FiltroProgramacao
            usuarios={this.state.usuariosProgramacao}
            showFilter={this.state.showFilterProgramacao}
            handleClose={this.searchFiltersProgramacao}
            initialData={this.state.filtersProgramacao}
            handleIniciarProgramacao={this.iniciarProgramacao}
          />
        </Layout.Sidebar>

        <div className="btn-tipo-visualizacao">
          <div className={`div-filters align-tipo-visualizacao`}>
            <div className="selectOptions">
              <SelectOption>
                <div title={intl.get('commons.vision.listView')}>
                  <Option
                    active={tipoVisualizacaoEstoque === "Lista" ? "active" : ""}
                    value="Lista"
                    handleClickIcon={() => this.changeValue("Lista")}
                    style={{ padding: "6px" }}
                  >
                    <Icon src={ImgLista} label="Lista" />
                  </Option>
                </div>
                <div title={intl.get('commons.vision.cardView')}>
                  <Option
                    active={tipoVisualizacaoEstoque === "Grid" ? "active" : ""}
                    value="Grid"
                    handleClickIcon={() => this.changeValue("Grid")}
                    style={{ padding: "6px" }}
                  >
                    <Icon src={ImgGrid} label="Grid" />
                  </Option>
                </div>
              </SelectOption>
            </div>
          </div>
        </div>
        {(stocks && stocks.length > 0) ?
          <React.Fragment>
            <div className="stocks-wrapper">
              {tipoVisualizacaoEstoque === "Grid" &&
                <div className="cards-container-stock">
                  {stocks.map((v, i) => (
                    <Card key={i} dataCard={v} />
                  ))}
                </div>
              }
              {tipoVisualizacaoEstoque !== "Grid" &&
                <div className="card-container-task">
                  <ListStocks
                    data={stocks}
                    onClick={this.redirectToDetail}
                    contentListScroll={this.contentListScroll} />
                </div>
              }
            </div>
            <div className="paginationStock">
              <Pagination
                refreshSearch={this.loadMore}
                page={this.state.loadRegs}
                pageSize={this.state.regsPerRequest}
                amount={this.state.totalStocks}
              />
            </div>
          </React.Fragment>
          :
          <div className='no-records-message'>{intl.get('commons.noRecordsFound')}</div>
        }
        <Message
          show={this.state.showMsg}
          text={this.state.textMsg}
          title={this.state.titleMsg}
          handleClose={() => {
            this.setState({ showMsg: false });
          }}
        />
      </div>
    );
  }
}

export default withRouter(Stocks);

import Fetch from 'utils/fetch';

export const consultarConfiguracaoGeral = (parametro) => {
  return Fetch.get(`/configuracaogeral/consultar/${parametro}`).then(({ data }) => data)
}

export const buscarUnidades = (idUser, modo) => {
  return Fetch.get(`/pedido/listaunidadenegocioporpedidodeacordocomusuario/${idUser}/${modo}`).then(({ data }) => data)
}

export const buscarFornecedores = (idUser, idUnidadeNegocio, modo) => {
  return Fetch.get(`/pedido/listafornecedoresporpedidodeacordocomusuariocentro/${idUser}/${idUnidadeNegocio}/${modo}`).then(({ data }) => data)
}

export const buscarComposicaoPorPedido = (idUser, idPedido) => {
  return Fetch.get(`/composicaocarga/porpedido/${idUser}/${idPedido}`).then(({ data }) => data)
}

export const searchComposition = (id) => {
  return Fetch.get(`/composicaocarga/${id}`).then(({ data }) => data)
}

export const generateGRN = (model) => (
  Fetch.post('/composicaocarga/CriarGoodsReceiptNote', model).then(({ data }) => data)
)

import React from "react";
import {
  Input as InputExterno
} from "@hbsis.uikit/react";
import TimeField from 'react-simple-timefield';

const Input = ({ mask, onChange, value, ...rest }) => {
  if (mask === "time") {
    return (
      <TimeField
        onChange={onChange}
        value={value}
        input={<InputExterno {...rest} />}
      />
    )
  }
  else {
    return (
      <InputExterno
        onChange={onChange}
        value={value}
        {...rest}
      />
    )
  }
}

export { Input }

import React, { useState } from 'react'
import ComposicaoCargaForm from 'pages/composicao-carga/form'
import SessionUser from 'utils/user-storage'
import { useGlobalContext } from 'hooks/useGlobalContext';

const initialLoadProps = {
  isOpen: false,
  idComposicao: 0,
}

const LinkLoadCompositionForm = ({ idComposicao, children, ...rest }) => {
  const [loadCompositionForm, setLoadCompositionForm] = useState(initialLoadProps);
  const { showFeedback } = useGlobalContext();

  const handleOpenLoadForm = () => {
    setLoadCompositionForm({
      isOpen: true,
      idComposicao: idComposicao
    })
  }

  return (
    <React.Fragment>
      <span onClick={handleOpenLoadForm}>
        {children}
      </span>
      {loadCompositionForm.isOpen &&
        <ComposicaoCargaForm
          idEdit={loadCompositionForm.idComposicao}
          idPedido={0}
          idUser={SessionUser.get().Id}
          open={loadCompositionForm.isOpen}
          handleRefresh={() => setLoadCompositionForm(initialLoadProps)}
          handleShowFeedback={showFeedback}
          handleClose={() => setLoadCompositionForm(initialLoadProps)}
          modoLeitura
          {...rest}
        />
      }
    </React.Fragment>
  )
}

export default LinkLoadCompositionForm;

import styled from 'styled-components'
import ColorPickers from 'colorpickers/index'

const StyledTitleSecond = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: ${ColorPickers.blueSecond};
  padding-left: 0.5rem;
`

export default StyledTitleSecond

import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { createEmergencyNegotiation, criarComposicao, editarComposicao, fetchDesvincularPedidos, fetchUpdateDate } from '../../../../load-composition-building.service'
import * as S from '../../styled'
import Session from 'utils/user-storage';
import ComposicaoCargaForm from '../../../../../composicao-carga/form'
import ModalAcceptReturn from '../../../../../primary-material-schedule/components/modalReturnSapList'
import moment from 'moment'
import { useLoadCompositionBuildingContext } from '../../../../context/load-composition-building-context';
import { ISO_DATE_FORMAT } from 'utils/format-date';
import { getEmergencyNegotiationModel } from './utils';

const ButtonAccept = ({ isEditDateMode, data, setShowLoad, infosRoute, proccessError, pushPath, history, composicao, disabled, queryParams }) => {
  const isEdit = !!infosRoute.composicaoId;
  const [modalAcceptReturnData, setModalAcceptReturnData] = useState({
    isOpen: false,
    data: [],
    title: intl.get('commons.composition')
  });
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [idPedidoRetorno, setIdPedidoRetorno] = useState()
  const [showLoadCompositionModal, setShowLoadCompositionModal] = useState(false)

  const {
    loadPage,
    isFullTruckLoad,
    breweryNote,
    ordersMetadata,
    isEmergencyNegotiation
  } = useLoadCompositionBuildingContext();

  const handleCallbackEdit = () => {
    loadPage()
    setShowAcceptModal(false)
  }

  const handleEditDate = async () => {
    if (moment(composicao.DataEntrega).format(ISO_DATE_FORMAT) !== moment(infosRoute.dia).format(ISO_DATE_FORMAT)
      || moment(composicao.HoraEntrega, "hh:mm").format("hh:mm") !== moment(infosRoute.hora, "hh:mm").format("hh:mm")) {
      const dto = {
        IdComposicaoCarga: parseInt(infosRoute.composicaoId),
        NovaDataEntregaComposicao: infosRoute.dia,
        NovaHoraEntregaComposicao: infosRoute.hora,
      }
      await fetchUpdateDate(dto);
    }
  }

  const closeModalAssign = () => {
    setModalAcceptReturnData({
      data: [],
      isOpen: false
    });

    if (showLoadCompositionModal) {
      setShowAcceptModal(true);
    } else {
      history.push(pushPath);
    }
  }

  const openModalAssign = (model) => {
    setModalAcceptReturnData({
      data: model,
      isOpen: true,
      title: intl.get('commons.composition')
    });
  }

  const generateModelAccept = () => {
    const idsCards = data.map(card => card.IdCard)
    const format = { idsSugestoes: [], idsPedidos: [] }

    idsCards.filter(id => id.includes('S') ?
      format.idsSugestoes.push(parseInt(id)) :
      format.idsPedidos.push(parseInt(id)))

    return {
      idsPedidos: format.idsPedidos,
      idsSugestoes: format.idsSugestoes,
      dataEntregaComposicao: infosRoute.dia,
      horaEntregaComposicao: infosRoute.hora,
      idFornecedor: data[0] ? data[0].IdFornecedor : infosRoute.fornecedorId,
      idUnidadeNegocio: parseInt(infosRoute.destinoId),
      doorDescription: queryParams.get('doorDescription'),
      breweryNote: breweryNote
    }
  }

  const atualizarPedidosComposicao = async (idsPedidos) => {
    const dto = {
      IdsPedidos: idsPedidos,
      IdComposicaoCarga: parseInt(infosRoute.composicaoId)
    }
    await fetchDesvincularPedidos(dto);
  }

  const accept = async (model) => {
    try {
      setShowLoad(true);
      let result;

      if (isEmergencyNegotiation) {
        const dataDeColeta = moment(model.dataEntregaComposicao).add(-composicao.TransitTime, 'day').format(ISO_DATE_FORMAT);
        const modelEmergencyNegotiation = getEmergencyNegotiationModel(infosRoute, model, dataDeColeta, ordersMetadata);

        await createEmergencyNegotiation(modelEmergencyNegotiation);
        setShowLoad(false);
        openModalAssign([intl.get('commons.renegotiationLoadCompositionSent')]);
        return;
      } else if (isEdit) {
        await atualizarPedidosComposicao(model.idsPedidos)
        result = await editarComposicao({ ...model, IdComposicaoCarga: parseInt(infosRoute.composicaoId) });
        await handleEditDate()
      } else {
        result = await criarComposicao(model);
      }
      const pedidosComErro = result.filter(x => !x.Success) ?? [];

      if (pedidosComErro.length === 0) {
        const pedidosComSucesso = result.filter(x => x.Success);
        const idsPedidosComSucesso = pedidosComSucesso.map(x => x.IdPedidoCriado);
        setIdPedidoRetorno(idsPedidosComSucesso[0]);
        setShowLoadCompositionModal(true);
      }
      openModalAssign(result.map(x => x.Message));
    }
    catch (e) {
      proccessError(e);
    }
    setShowLoad(false);
  }

  const validateDisableButton = () => !data[0] || disabled || (isEditDateMode && !isFullTruckLoad)

  return (
    <React.Fragment>
      <S.Button
        onClick={() => accept(generateModelAccept())}
        type='primary'
        value={intl.get("geral.buttonsDefault.accept")}
        disabled={validateDisableButton()}
      />
      {modalAcceptReturnData.isOpen &&
        <ModalAcceptReturn
          open={modalAcceptReturnData.isOpen}
          data={modalAcceptReturnData.data}
          closeModalAssign={closeModalAssign}
          title={modalAcceptReturnData.title}
        />
      }
      {(idPedidoRetorno && showAcceptModal) &&
        <ComposicaoCargaForm
          idPedido={idPedidoRetorno}
          idUser={Session.get().Id}
          open={showAcceptModal}
          handleClose={() => {
            setShowAcceptModal(false);
            history.push(pushPath);
          }}
          modoLeitura={true}
          handleCallbackEdit={handleCallbackEdit}
        />
      }
    </React.Fragment>
  )
}

export default ButtonAccept;

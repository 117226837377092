import { useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import Feature from 'components/feature';
import IcnListWhite from "images/icn-list-white.svg";
import intl from 'react-intl-universal'
import * as S from '../navbar-user-actions/styled'
import * as permissions from "services/permissions/getPermissions";
import MenuItem from "@material-ui/core/MenuItem";
import { dataUploads } from '../../data/upload-data';
import { DialogsNavbarUploads } from './dialogs-navbar-uploads';

export const NavbarUploads = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);

  const refs = useRef(null)

  const handleCloseMenu = () => setOpenMenu(null);
  const handleCloseDialog = () => setOpenDialog(null);

  const handleOpenDialog = (option) => {
    setOpenDialog(option);
    handleCloseMenu()
  }

  return (
    <>
      <Feature validation={permissions.userAmbev() && dataUploads().some(x => x.visible)}>
        <S.ContainerButton>
          <S.MenuList>
            <Button
              ref={_ref => refs.current = _ref}
              title={intl.get("master-data.menus.userAction")}
              onClick={() => setOpenMenu('action-uploads')}
            >
              <img alt="userActions" src={IcnListWhite} />
            </Button>
            <S.Menu
              anchorEl={refs.current}
              keepMounted
              open={openMenu === 'action-uploads'}
              onClose={handleCloseMenu}
            >
              {dataUploads().map(item => (
                <>
                  <Feature validation={item.visible}>
                    <MenuItem onClick={() => handleOpenDialog(item.dialogType)}>
                      {item.label}
                    </MenuItem>
                  </Feature>
                </>
              ))}
            </S.Menu>
          </S.MenuList>
        </S.ContainerButton>
        <DialogsNavbarUploads open={openDialog} handleClose={handleCloseDialog} />
      </Feature>
    </>
  )
}

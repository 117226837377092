import { typesSuggestion } from "../context/utils";

export const validatedReturnLoadOrigin = (filter, _selectedLoad) =>
  !!((filter.typeSuggestionSelected === typesSuggestion.AwaitLoadComposition &&
      _selectedLoad.DescricaoCard === 5 &&
      _selectedLoad.IdSugestao) ||
    (filter.typeSuggestionSelected === typesSuggestion.Suggestions &&
      _selectedLoad.DescricaoCard !== 5 &&
      _selectedLoad.DescricaoCard !== 2 &&
      _selectedLoad.IdSugestao) ||
    (filter.typeSuggestionSelected === typesSuggestion.Liberado &&
      _selectedLoad.DescricaoCard === 2) ||
    (filter.typeSuggestionSelected === typesSuggestion.Liberado &&
      (_selectedLoad.DescricaoCard === 5 ||
        _selectedLoad.DescricaoCard === 1) &&
      _selectedLoad.IdPedido)
  );

import React, { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import HomeIcon from 'material-ui/svg-icons/action/home'
import Fetch from 'utils/fetch'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import Message from 'components/message'
import BusinessUnitList from './business-unit-list'
import BusinessUnitForm from './business-unit-form'
import ButtonNewItem from 'components/button-new-item'
import intl from 'react-intl-universal'

import './business-unit.css'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";


class BusinessUnit extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      openEditMode: false,
      unities: [],
      total: 0,
      currentPage: 0,
      openForm: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 50,
      showMsg: false,
      textMsg: ''
    }
  }

  showMessage = (message) => {
    this.setState({
      showMsg: true,
      textMsg: message
    })
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false,
      idSelected: 0,
      openForm: true
    })
  }

  openEditForm = (row) => {
    const selectedItem = this.state.unities[row]

    this.setState({
      openEditMode: true,
      idSelected: selectedItem.Id,
      openForm: true
    })
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (ex) => {
    this.showMessage((ex.response.status === 400) ? ex.response.data.Message : intl.get('stocks.timeline.feedback.serverError'))
  }

  refreshSearch = () => {
    this.closeForm()
    this.searchUnities(this.state.currentPage)
  }

  searchUnities = (page) => {
    this.startFetching()

    Fetch.get(`/unidadeNegocio/${this.state.qtRegsPerPage}/${page}`)
      .then((response) => this.proccessData(response.data))
      .then(() => this.setState({ currentPage: page }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
  }

  proccessData = (data) => {
    this.setState({
      unities: data.Data,
      total: data.TotalRecordCount
    })
  }

  render() {
    let {
      openForm,
      idSelected,
      unities,
      total,
      showFeedback,
      messageFeedBack,
      isFetching,
      showMsg,
      openEditMode,
      textMsg
    } = this.state

    return (
      <div className='unities-list-container'>
        <Loading isLoading={isFetching} />

        <div className="unities-header">
          <HeaderIcon
            title={intl.get('master-data.managementUnity.unitys.header.title')}
            subtitle={intl.get("master-data.managementUnity.unitys.header.subtitle")}
            icon={<HomeIcon style={{ width: 45, height: 45 }} color='#1d388f' />}
          />

          {profileAdminOrMasterData() &&
            <div title={intl.get('master-data.managementUnity.unitys.actions.newManagementUnity')}>
              <ButtonNewItem
                className="business-unit-button-new"
                onClick={this.openNewForm}
              />
            </div>
          }
        </div>

        <BusinessUnitList
          data={unities}
          countData={total}
          refreshSearch={this.searchUnities}
          handleEdit={this.openEditForm}
          qtRegsPerPage={this.state.qtRegsPerPage}
        />

        {openForm &&
          <BusinessUnitForm
            idEdit={idSelected}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
          />
        }

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

        <Message
          show={showMsg}
          text={textMsg}
          title='Erro!'
          handleClose={() => this.setState({ showMsg: false })}
        />
      </div>
    )
  }
}

export default BusinessUnit

import React from 'react'
import HeaderCardStocks from './card-header-stocks'
import HeaderCardStocksDetail from './card-header-stocks-detail'
import Divisor from './card-divisor'
import PropTypes from 'prop-types'
import StyledCard from './styled-card'
import cx from 'classnames'

const Card = ({
  type,
  onClick,
  title,
  subtitle,
  showIcon,
  programado,
  children,
  cardRef,
  status,
  customOptionsHeader,
  cursor,
  criticidade
}) => (
  <StyledCard
    innerRef={cardRef}
    onClick={onClick}
    className="Card"
    cursor={cursor}
  >
    <StyledCard className={cx('headerCard', { headerCardNoIconHeight: showIcon })}>
      {type === 'Stocks' &&
        <HeaderCardStocks
          title={title}
          subtitle={subtitle}
          showIcon={showIcon}
          programado={programado}
          status={status}
          customOptions={customOptionsHeader}
        />
      }
      {type === 'StocksDetail' &&
        <HeaderCardStocksDetail
          title={title}
          subtitle={subtitle}
          showIcon={showIcon}
          programado={programado}
          criticidade={criticidade}
          status={status}
          customOptions={customOptionsHeader}
        />
      }
    </StyledCard>
    <Divisor />
    <div className="card-content">
      {children}
    </div>
  </StyledCard>
)

Card.propTypes = {
  cardRef: PropTypes.func,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  showIcon: PropTypes.bool,
  programado: PropTypes.bool,
  onClick: PropTypes.func,
  customOptionsHeader: PropTypes.element,
}

Card.defaultProps = {
  titleColor: '#477dc0',
}

export default Card

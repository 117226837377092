import Fetch from "utils/fetch";

export const searchMessages = () => {
    return Fetch.get(`/familiaRotuladaCentroMaterial/listaJustificativasDescontinuado`).then(({ data }) => data)
}

export const searchMaterialById = (idMaterial) => {
    return Fetch.get(`/familiaRotuladaCentroMaterial/${idMaterial}`).then(({ data }) => data)
}

export const searchAllMaterials = (idFamiliaRotuladaCentro) => {
    return Fetch.get(`/material/obterPorFamiliaRotuladaCentro/${idFamiliaRotuladaCentro}`).then(({ data }) => data)
}

export const save = (materialUnidade) => {
    return Fetch.post(`/familiaRotuladaCentroMaterial/salvar/`, materialUnidade)
}

export const buscaUsuariosAmbev = () => {
    return Fetch.get(`/usuario/buscaUsuariosHabilitadoAmbev`).then(({ data }) => data)
}
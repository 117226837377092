import styled from 'styled-components';

const CounterCardsStyled = styled.div`
  display:flex;
  justify-content: space-between;
  margin: 16px;

  .card {
    width: 30%;
    padding: 16px;
    display: flex;
    background-color: #FFFFFF;
    justify-content: space-between;
    align-items: center;
    border-top: 2px solid white;
  }

  .text {
    width: 85%;
    justify-content: center;
    flex-flow: column;
    
    p {
      margin: 0;
    }

    .counter {
      font-size: 28px;
      color: #3B495E;
    }

    .title {
      font-size: 16px;
      font-weight: 600;
      color: #95A3B7;
      text-transform: uppercase;
    }
  }

  .svg {
    width: 60px;
    height: 60px;
  }

  .stella-artois {
    border-top-color: #769C09; 
  }

  .brahma {
    border-top-color: #FC5457;
  }

`;

export default CounterCardsStyled;
import React, { useEffect, useState } from 'react'
import Header from 'components/header';
import intl from 'react-intl-universal'
import ButtonFilter from './components/button-filter-suggestion';
import { useSuggestionContext } from './context/suggestionsContext';
import Loading from "components/center-loading";
import Cards from './components/cards';
import VisionOption, { visionTypes } from '../../components/vision-option';
import * as S from './styled'
import { filtersSuggestionDefault } from './utils';
import List from './components/list';
import Pagination from './components/pagination';
import { validatesArrayHasValue } from 'utils/custom-functions';
import StorePersist from 'utils/store-persist';
import { verifyFilter } from './utils/filters';

const Suggestions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [visionType, setVisionType] =
    useState(localStorage.getItem("visionTypeSuggestions") || visionTypes.Grid);
  const {
    suggestions: { Data: suggestions, filters },
    fetchSuggestions
  } = useSuggestionContext();

  const startLoading = () => setIsLoading(true);
  const stopLoading = () => setIsLoading(false);

  const handleVisionType = (newVisionType) => {
    setVisionType(newVisionType)
    localStorage.setItem("visionTypeSuggestions", newVisionType)
  }

  const loadInfos = async () => {
    startLoading();
    const filtersSuggestionsMulti = StorePersist.getValuesJSON("filtersSuggestionsMulti");
    const _filter = filtersSuggestionsMulti !== filtersSuggestionDefault ? filtersSuggestionsMulti : filtersSuggestionDefault;
    await fetchSuggestions(_filter);
    stopLoading();
  }

  useEffect(() => {
    loadInfos()
  }, [])

  return (
    <React.Fragment>
      <Header title={intl.get('commons.suggestions')}>
        <ButtonFilter
          isFiltered={verifyFilter(filters)}
          stopLoading={stopLoading}
          startLoading={startLoading}
        />
      </Header>
      <VisionOption
        visionType={visionType}
        changeVision={newVisionType => handleVisionType(newVisionType)}
      />
      <Loading isLoading={isLoading} />
      {!validatesArrayHasValue(suggestions) ? <div className='no-records-message'>{intl.get('commons.noRecordsFound')}</div> :
        <S.WrapperContent>
          {visionTypes.Grid === visionType && <Cards data={suggestions} />}
          {visionTypes.List === visionType && <List data={suggestions} />}
        </S.WrapperContent>
      }
      <Pagination
        stopLoading={stopLoading}
        startLoading={startLoading}
      />
    </React.Fragment>
  )
}

export default Suggestions;

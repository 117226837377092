import React from 'react'
import ListStock from './list-stock'
import StyledListStocks from './styled'

const ListStocks = ({ onClick, data }) => (
  <StyledListStocks>
    <ListStock
      data={data}
      onClick={onClick}
    />
  </StyledListStocks>
)
export default ListStocks

import Select from 'react-dropdown-select'
import styled from 'styled-components'

export const SelectWrapper = styled.div`
  label {
    line-height: 1.8rem;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--color-contrast-brand);
  }

  .react-dropdown-select {
    border-top: 2px solid var(--color-brand-100);
    
    &:hover {
      border-top-color: var(--color-brand-500) !important;
      border-color: var(--color-brand-500);
    }

    &:focus {
      border: none;
    }
  }
`

export const StyledSelect = styled(Select)`
  .react-dropdown-select-dropdown {
    overflow: initial;
    width: max-content;
  }
`;

export const SearchAndToggle = styled.div`
  display: flex;
  flex-direction: column;
  input {
    margin: 10px 10px 0;
    line-height: 30px;
    padding: 0px 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    :focus {
      outline: none;
    }
  }
`;

export const Items = styled.div`
  overflow: auto;
  min-height: 10px;
  max-height: 200px;
`;

export const Item = styled.div`
  display: flex;
  margin: 10px;
  align-items: baseline;
  ${({ disabled }) => disabled && 'text-decoration: line-through;'}
`;

export const ItemLabel = styled.div`
  margin: 5px 10px;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  & div {
    margin: 10px 0 0 10px;
    font-weight: 600;
  }
`;

export const Button = styled.button`
  background: none;
  border: 1px solid #555;
  color: #555;
  border-radius: 3px;
  margin: 10px 10px 0;
  padding: 3px 5px;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  &.clear {
    color: tomato;
    border: 1px solid tomato;
  }
  :hover {
    border: 1px solid var(--color-brand-100);
    color: var(--color-brand-100);
  }
`;
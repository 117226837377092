import styled from "styled-components"

export const Alert = styled.span`
  width: 29px;
  text-align: center;
  margin: auto 0;
`
export const AlertContent = styled.p`
  font-size: 32px;
  font-weight: 600;
  color : #eb1a21;
  margin: auto 0;
`

import { isUseFilterDefault } from "../../../utils/user"

export const volumeFiltersDefault = {
  VolumeOrderId: '',
  BusinessUnitIds: [],
  SupplierIds: [],
  MaterialsNumber: [],
  PendingItems: false,
  CoordinationId: '',
  MaterialGroupIds: [],
  CollectDateMin: '',
  CollectDateMax: '',
  DeliveryDateMin: '',
  DeliveryDateMax: '',
  Status: [],
  UseFilterDefault: isUseFilterDefault(),
  _page: 0,
  _size: 50
}

import React from 'react'
import Dialog from 'material-ui/Dialog'
import { Button } from '@hbsis.uikit/react'
import Table from './components/table'
import * as S from './styled'
import intl from 'react-intl-universal'

const Modal = ({ tasks, open, close }) => {

  const copyToClipboard = () => {
    const text = tasks.map((task) => task.ChaveAcesso).join(' ');
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        const tooltip = document.getElementById("myTooltip")
        tooltip.innerHTML = intl.get('accessKeyModal.copied')
      })
    }
  }

  const onMouseOutTooltip = () => {
    const tooltip = document.getElementById("myTooltip")
    tooltip.innerHTML = intl.get('accessKeyModal.copy')
  }

  return (
    <S.DialogWrapper>
      <Dialog
        open={open}
        title={
          <S.WrapperHeader>
            <S.Title>
              {intl.get('accessKeyModal.title')}
            </S.Title>
            <div className='tooltip'>
              <S.Button onMouseOut={() => onMouseOutTooltip()}>
                <div className="tooltiptext" id="myTooltip">{intl.get('accessKeyModal.copy')}</div>
                <Button
                  value={intl.get('accessKeyModal.copyAll')}
                  type="primary"
                  onClick={() => copyToClipboard()}
                />
              </S.Button>
            </div>
          </S.WrapperHeader>
        }
        actions={
          <S.ActionButton>
            <Button
              type="primary"
              value={intl.get('geral.buttonsDefault.close')}
              width='200px'
              onClick={() => close()}
            />
          </S.ActionButton>
        }
        contentStyle={
          {
            maxWidth: '90%',
            width: '90%'
          }}>
        <S.Wrapper>
          <Table data={tasks} />
        </S.Wrapper>
      </Dialog>
      <div className='btn-ar-out' onClick={() => copyToClipboard()} />
    </S.DialogWrapper>
  )
}

export default Modal

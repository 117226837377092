const momentEnUs = {
    parentLocale: "en",
    longDateFormat: {
        "[DM]": "MM/DD",
        "[DDMMYY]": "MM/DD/YY",
        "L": "MM/DD/YY",
        'l': "MM/DD/YY"
    }
};

export default momentEnUs;

import intl from 'react-intl-universal'
import * as permissions from 'services/permissions/getPermissions'
import { isScheduleAgreementFlow } from 'utils/validations-general-settings'


export const Columns = () => ([
  {
    prop: 'Checkbox',
    name: '',
    visible: true
  },
  {
    prop: 'Stage',
    name: intl.get("commons.stage"),
    visible: true
  },
  {
    prop: 'WmsTransactionId',
    name: intl.get("commons.WmsTransactionId"),
    visible: isScheduleAgreementFlow()
  },
  {
    prop: 'Id',
    name: intl.get("commons.orderCode"),
    visible: true
  },
  {
    prop: 'LoadCompositionId',
    name: intl.get("commons.loadComp"),
    visible: true,
    hasAction: true
  },
  {
    prop: 'SupplierName',
    name: intl.get("commons.source"),
    visible: true
  },
  {
    prop: 'BusinessUnit',
    name: intl.get("commons.destiny"),
    visible: true
  },
  {
    prop: 'Material',
    name: intl.get("commons.material"),
    visible: true
  },
  {
    prop: 'OrderCollectDate',
    name: intl.get('commons.colDate'),
    visible: true
  },
  {
    prop: 'OrderDeliveryDate',
    name: intl.get("commons.deliveryDate"),
    visible: true
  },
  {
    prop: 'OrderDeliveryTime',
    name: intl.get("commons.deliveryTime"),
    visible: true
  },
  {
    prop: 'StockUnitCurrent',
    name: intl.get("requestOrders.accStock"),
    visible: permissions.userAmbev()
  },
  {
    prop: 'Quantity',
    name: intl.get("commons.quantity"),
    visible: true
  },
  {
    prop: 'Lot',
    name: intl.get("commons.lot"),
    visible: true
  },
  {
    prop: 'OrderStatusDescription',
    name: intl.get("commons.status"),
    visible: true
  }
])

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import moment from "moment";
import { Button } from "@hbsis.uikit/react";
import intl from "react-intl-universal";
import SnackBar from "material-ui/Snackbar";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import ExpandableTable from "components/expandable-table";
import { IconAlert } from "components/icons/icn-alert.icon";
import {
  HourglassIcon,
  TransferIcon,
  FactoryIcon
} from "components/icons/icn-index.icon";

import Loading from "components/center-loading";
import HeaderIcon from "components/header-icon";
import Fetch from "utils/fetch";
import ModalJustificativa from "./modal-justificativa";
import StyledModalShelfLife from "./styledModalShelfLife";
import { formatNumber } from "utils/format";

const Labels = () => [
  {
    name: intl.get('shelfLife.utilization'),
    key: "UtilizacaoLivre",
    customStyle: {
      maxWidth: "110px"
    }
  },
  {
    name: intl.get('shelfLife.risk'),
    key: "QuantidadeTransferencia",
    customStyle: {
      maxWidth: "110px"
    }
  },
  {
    name: intl.get('shelfLife.qualityControl'),
    key: "ControleQualidade",
    customStyle: {
      maxWidth: "110px"
    }
  },
  {
    name: intl.get('shelfLife.blocked'),
    key: "Bloqueado",
    customStyle: {
      maxWidth: "110px"
    }
  },
  {
    name: intl.get('shelfLife.consigned'),
    key: "Consignado",
    customStyle: {
      maxWidth: "110px"
    }
  },
  {
    name: intl.get('shelfLife.val'),
    key: "Validade",
    customStyle: {
      maxWidth: "90px",
      justifyContent: "center"
    }
  },
  {
    name: intl.get('shelfLife.descontinousDate'),
    key: "DataDescontinuado",
    customStyle: {
      maxWidth: "90px",
      justifyContent: "center"
    }
  },
  {
    name: intl.get('shelfLife.days'),
    key: "DiasPassados",
    customStyle: {
      maxWidth: "90px"
    }
  },
  {
    name: intl.get('shelfLife.vencDays'),
    key: "DiasVencimento",
    customStyle: {
      maxWidth: "90px"
    }
  },
  {
    name: intl.get('shelfLife.pe-hp'),
    key: "ProjecaoEstoqueHorizonte",
    customStyle: {
      maxWidth: "50px"
    }
  },
  {
    name: intl.get('shelfLife.active'),
    key: "Ativo",
    customStyle: {
      maxWidth: "50px",
      justifyContent: "center"
    }
  },
  {
    name: intl.get('shelfLife.production'),
    key: "Producao",
    customStyle: {
      maxWidth: "80px",
      justifyContent: "center"
    }
  },
  {
    name: intl.get('shelfLife.justification'),
    key: "Justificativa"
  }
];


const FILTROS = {
  MINHA_UNIDADE: "Minha Unidade",
  SUGESTOES: "Sugestões"
};

class ModalShelfLife extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      requestCounter: 0,
      familiasRotuladas: [],
      tiposJustificativas: [],
      openedItems: [],
      selectedItems: [],
      openModalJustificativa: false,
      selectedFilter: null,
      selectedItem: null,
      showFeedback: false,
      messageFeedback: ""
    };
  }

  get isSugestoesSelected() { return this.state.selectedFilter === FILTROS.SUGESTOES; }
  get isUnidadeSelected() { return this.state.selectedFilter === FILTROS.MINHA_UNIDADE; }

  async componentDidMount() {
    this.handleFetch()

    try {
      this.onlyMyCenterClick()
      const responseTiposJustificativa = await this.fetchTiposJustificativa();

      this.setState({ tiposJustificativas: responseTiposJustificativa.data })
    } catch (err) {
      console.log("err", err)
      this.showFeedback(intl.get('loadOptimization.thereErrorLoadingData'));
    } finally {
      this.handleFetch(false)
    }
  }

  fetchFamiliaRotulada = () => Fetch.get(`shelfLife/consultarEstoquePorFamiliaRotulada/${this.props.idFamiliaRotuladaCentro}`);

  fetchEstoqueSugestao = async () => {
    this.handleFetch();
    try {
      const responseEstoqueSugestao = await Fetch.get(`shelfLife/consultarEstoqueSomenteSugestao/${this.props.idFamiliaRotuladaCentro}`);

      this.setState({ familiasRotuladas: responseEstoqueSugestao.data });
    } catch (err) {
      console.log("err", err)
      this.showFeedback(intl.get('loadOptimization.thereErrorLoadingData'))
    } finally {
      this.handleFetch(false)
    }
  };

  fetchFamiliaRotuladaUnidadeNegocio = async () => {
    const { idFamiliaRotuladaCentro, idUnidadeNegocio } = this.props;

    this.handleFetch();
    try {
      const responseFamiliaRotulada = await Fetch.get(`shelfLife/consultarEstoquePorFamiliaRotuladaUnidadeNegocio/${idFamiliaRotuladaCentro}/${idUnidadeNegocio}`);

      this.setState({ familiasRotuladas: responseFamiliaRotulada.data });
    } catch (err) {
      console.log("err", err);
      this.showFeedback(intl.get('loadOptimization.thereErrorLoadingData'));
    } finally {
      this.handleFetch(false)
    }
  };

  salvarJustificativa = justificativas => {
    return Fetch.post("shelfLife/salvarJustificativa", {
      ...this.state.selectedItem.Justificativa,
      ...justificativas
    });
  };

  fetchFamiliasRotuladas = async () => {
    this.handleFetch();
    try {
      const responseFamiliaRotulada = await this.fetchFamiliaRotulada();

      this.setState({ familiasRotuladas: responseFamiliaRotulada.data });
    } catch (err) {
      console.log("err", err);
      this.showFeedback(intl.get('loadOptimization.thereErrorLoadingData'));
    } finally {
      this.handleFetch(false)
    }
  };

  fetchTiposJustificativa = () => Fetch.get("shelfLife/listaTiposJustificativa");

  handleCriarSugestao = async () => {
    try {
      await Fetch.post(
        `shelfLife/criarSugestaoViaShelfLife/${this.props.idFamiliaRotuladaCentro}`,
        this.state.selectedItems
      );

      this.props.handleCloseReload()
    } catch (err) {
      console.log("err", err);
    }
  };

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCounter: isFetching ? prevState.requestCounter + 1 : prevState.requestCounter - 1,
    }))
  }

  handleResolveValue = (key, item) => {
    if ((key === "Ativo" || key === "Producao") && item.hasOwnProperty(key)) {
      return item[key] ? "Sim" : "Não";
    }

    if (key === "Validade") {
      return item[key] ? moment(item[key]).format("L") : '-';
    }

    if (!item.Children && key === "Justificativa") {
      const { Observacao, Tipo } = item[key];

      if (Observacao) {
        return Observacao;
      } else {
        const justificativaPreDefinida = this.state.tiposJustificativas.find(
          j => j.Id === Tipo
        );

        return justificativaPreDefinida ? justificativaPreDefinida.Descricao : "-";
      }
    }

    if (key === "UtilizacaoLivre" || key === "ControleQualidade" || key === "Bloqueado" || key === "Consignado") {
      return item[key] ? formatNumber(item[key]) : '-';
    }

    return item[key] ? item[key] : "-";
  };

  handleSelectFilter = (filter, callback) => {
    this.setState(
      prevState => ({
        selectedFilter: filter === prevState.selectedFilter ? "" : filter
      }),
      callback ? () => callback(this.state.selectedFilter) : undefined
    );
  };

  handleClick = item => {
    this.setState({
      selectedItem: item
    });
    this.toggleModalJustificativa();
  };

  handleSelectItem = (selected, item) => {
    let items = [...this.state.selectedItems];

    if (selected) {
      items.push(item);
    } else {
      items = items.filter(i => i.Id !== item.Id);
    }

    this.setState({
      selectedItems: items
    });
  };

  handleSalvarJustificativa = async justificativa => {
    try {
      await this.salvarJustificativa(justificativa);
      this.fetchFamiliasRotuladas();
      this.showFeedback(intl.get('orders.feedback.justificationSuccessfullySaved'));
      this.toggleModalJustificativa();
    } catch (err) {
      this.showFeedback(intl.get('orders.feedback.thereWasAnErrorSavingJustification'));
    }
  };

  toggleModalJustificativa = () => {
    this.setState(prevState => ({
      openModalJustificativa: !prevState.openModalJustificativa
    }));
  };

  showCheckbox = (item) => item.TransferenciaPorVencimento || item.TransferenciaPorDescontinuacao;

  showShelfLifeImage = (item) => item.TransferenciaPorVencimento;

  showDiscontinuedImage = (item) => item.TransferenciaPorDescontinuacao;

  getIconAlert = item => {
    if (item.Bloqueado) {
      return !item.Justificativa.Observacao && !item.Justificativa.Tipo ? (
        <span title={intl.get('orders.feedback.blockedUnjustifiedInventory')}>
          <IconAlert color="#F9575B" />
        </span>
      ) : (
          <span title={intl.get('orders.feedback.justifiedBlockedInventory')}>
            <IconAlert />
          </span>
        );
    }
    return null;
  };

  onlyMyCenterClick = () => {
    const fetchFamilias = state => {
      if (this.isUnidadeSelected) {
        this.fetchFamiliaRotuladaUnidadeNegocio();
      } else {
        this.fetchFamiliasRotuladas();
      }
    };

    this.handleSelectFilter(FILTROS.MINHA_UNIDADE, fetchFamilias);
  };

  onlySuggestionClick = () => {
    const fetchSuggestions = state => {
      if (this.isSugestoesSelected) {
        this.fetchEstoqueSugestao();
      } else {
        this.fetchFamiliasRotuladas();
      }
    };

    this.handleSelectFilter(FILTROS.SUGESTOES, fetchSuggestions);
  };

  showFeedback = message => {
    this.setState({
      showFeedback: true,
      messageFeedback: message
    });
  };

  closeFeedback = () => {
    this.setState({ showFeedback: false });
  };

  render() {
    const {
      requestCounter,
      familiasRotuladas,
      openModalJustificativa,
      selectedItem,
      tiposJustificativas,
      messageFeedback,
      showFeedback
    } = this.state;

    const { open, subtitle, handleClose } = this.props;

    return (
      <Dialog
        autoScrollBodyContent
        contentStyle={{ width: "100%", maxWidth: "95vw" }}
        open={open}
        onRequestClose={handleClose}
        actions={[
          <FlatButton label={intl.get('geral.buttonsDefault.cancel')} onClick={handleClose} />,
          <FlatButton
            disabled={!this.state.selectedItems.length}
            label={intl.get('orders.createSuggestion')}
            onClick={this.handleCriarSugestao}
          />
        ]}
      >
        <StyledModalShelfLife>
          <Loading isLoading={requestCounter > 0} />

          <div className="header">
            <HeaderIcon
              title={intl.get("modalShelfLife.shelfLife")}
              subtitle={subtitle}
              icon={<HourglassIcon width="45px" height="45px" />}
              className="header-icon"
            />

            <div className="button-container">
              <div title={intl.get('orders.unit')}>
                <Button
                  type="default"
                  value={<FactoryIcon width="25px" height="25px" />}
                  className={cx({
                    "button-selected": this.isUnidadeSelected
                  })}
                  onClick={this.onlyMyCenterClick}
                  width={"45px"}
                />
              </div>
              <div title={intl.get('orders.transferSuggestions')}>
                <Button
                  type="default"
                  value={
                    <TransferIcon width="25px" height="25px" color="#1d388f" />
                  }
                  className={cx({
                    "button-selected": this.isSugestoesSelected
                  })}
                  onClick={this.onlySuggestionClick}
                  width={"45px"}
                />
              </div>
            </div>
          </div>

          <div className="content">
            {familiasRotuladas.length ? (
              <ExpandableTable
                data={familiasRotuladas}
                labelsList={Labels()}
                labelKey="Label"
                childrenKey="Children"
                resolverValue={this.handleResolveValue}
                showCheckbox={this.showCheckbox}
                showShelfLifeImage={this.showShelfLifeImage}
                showDiscontinuedImage={this.showDiscontinuedImage}
                renderCustomAlert={this.getIconAlert}
                onClickItem={this.handleClick}
                onSelectItem={this.handleSelectItem}
              />
            ) : (<div>{intl.get('commons.noRecordsFound')}</div>)}
          </div>

          {openModalJustificativa && (
            <ModalJustificativa
              selectedItem={selectedItem}
              justificativas={tiposJustificativas}
              onSave={this.handleSalvarJustificativa}
              onClose={this.toggleModalJustificativa}
            />
          )}
        </StyledModalShelfLife>

        <SnackBar
          message={messageFeedback}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />
      </Dialog>
    );
  }
}

ModalShelfLife.propTypes = {
  open: PropTypes.bool,
  idFamiliaRotuladaCentro: PropTypes.number.isRequired,
  idUnidadeNegocio: PropTypes.number.isRequired,
  subtitle: PropTypes.string,
  handleClose: PropTypes.func,
  myCenter: PropTypes.bool,
  suggestion: PropTypes.bool,
  suggestionClick: PropTypes.func,
  myCenterClick: PropTypes.func,
  handleCloseReload: PropTypes.func,
};

export default ModalShelfLife;

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ShopSuggestionMesterCard from './mester'
import ShopSuggestionDetailCard from './detail'

class ShopSuggestionCard extends Component {
  render() {
    return (
      <div style={{ height: 'calc(100% - 5px)' }}>
        {this.props.infoCard === 'MESTER' &&
          <ShopSuggestionMesterCard
            userCard={this.props.userCard}
            dataCard={this.props.dataCard}
            filters={this.props.filters}
          />
        }
        {this.props.infoCard === 'DETAIL' &&
          <ShopSuggestionDetailCard
            dataCard={this.props.dataCard}
            refreshCardDetail={this.props.refreshCardDetail}
          />
        }
      </div>
    )
  }
}

ShopSuggestionCard.propTypes = {
  userCard: PropTypes.string.isRequired,
  infoCard: PropTypes.string.isRequired,
  refreshCardDetail: PropTypes.func,
}

export default ShopSuggestionCard

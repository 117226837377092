import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loading from 'components/center-loading'
import { Row, Col } from 'react-flexbox-grid'
import { Button, SelectBox } from 'components/uikit-adapter/index'

import {
  searchWallets,
  searchCoordinations,
  searchUsers
} from './filtro-usuario.service'

import StyledFilter from './StyledFilter'
import intl from 'react-intl-universal'
import { Switcher } from 'components/toggle'

class FiltroUsuario extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      wallets: [],
      coordinations: [],
      users: [],
      IdCarteira: null,
      IdCoordenacao: null,
      IdUsuario: null,
      SomenteDesabilitados: false
    }

    this.processWalletsData = this.processWalletsData.bind(this)
    this.processCoordinationsData = this.processCoordinationsData.bind(this)
    this.processUsersData = this.processUsersData.bind(this)

    this.proccessErro = this.proccessErro.bind(this)
    this.startFetching = this.startFetching.bind(this)
    this.stopFetching = this.stopFetching.bind(this)

    this.changeValue = this.changeValue.bind(this)
    this.handleCloseSearch = this.handleCloseSearch.bind(this)
    this.handleCleanClick = this.handleCleanClick.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const hidden = !nextProps.showFilter

    this.setState({
      showFilter: nextProps.showFilter,
      hidden,
    })

    if (this.state.initialData !== nextProps.initialData && nextProps.showFilter) {
      this.setState({
        initialData: nextProps.initialData,
        IdCarteira: nextProps.initialData.IdCarteira,
        IdCoordenacao: nextProps.initialData.IdCoordenacao,
        IdUsuario: nextProps.initialData.IdUsuario,
        SomenteDesabilitados: nextProps.initialData.SomenteDesabilitados
      }, () => {
        this.searchWallets()
        this.searchCoordinations()
        this.searchUsers()
      })
    }
  }

  proccessErro(exception) {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
      textMsg: intl.get('stocks.timeline.feedback.serverErrorFamily')
    })
  }

  searchWallets = async () => {
    try {
      this.startFetching()
      const data = await searchWallets()
      this.processWalletsData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processWalletsData(data) {
    this.setState({
      wallets: data
    })
  }

  searchCoordinations = async () => {
    try {
      this.startFetching()
      const data = await searchCoordinations()
      this.processCoordinationsData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processCoordinationsData(data) {
    this.setState({
      coordinations: data.filter(Boolean)
    })
  }

  searchUsers = async () => {
    try {
      this.startFetching()
      const data = await searchUsers()
      this.processUsersData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.stopFetching()
    }
  }

  processUsersData(data) {
    this.setState({
      users: data
    })
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value,
    })
  }

  startFetching() {
    this.setState({
      isFetching: true
    })
  }

  stopFetching() {
    this.setState({
      isFetching: false
    })
  }

  handleCleanClick() {
    const params = {
      IdCarteira: null,
      IdCoordenacao: null,
      IdUsuario: null,
      SomenteDesabilitados: false
    }
    this.setState(params, () => this.props.handleClose(params))
  }

  handleCloseSearch() {
    const params = {
      IdCarteira: this.state.IdCarteira,
      IdCoordenacao: this.state.IdCoordenacao,
      IdUsuario: this.state.IdUsuario,
      SomenteDesabilitados: this.state.SomenteDesabilitados
    }
    this.props.handleClose(params)
  }

  render() {
    const {
      wallets,
      coordinations,
      users,
      IdCarteira,
      IdCoordenacao,
      IdUsuario,
      SomenteDesabilitados,
      isFetching
    } = this.state

    return (
      <StyledFilter>
        <Loading isLoading={isFetching} />
        <div className="filter-container">
          <div className="sideFilterFields">

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='wallet'
                  label={intl.get('master-data.general-configuration.coordination')}
                  placeholder={intl.get('master-data.general-configuration.coordination')}
                  valueKey="Id"
                  labelKey="Descricao"
                  value={IdCoordenacao}
                  onChange={value => this.changeValue('IdCoordenacao', value === null ? null : value.Id)}
                  options={coordinations}
                  className='select-coordenacao'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='wallet'
                  label={intl.get('commons.wallet')}
                  placeholder={intl.get('commons.wallet')}
                  valueKey="Id"
                  labelKey="Descricao"
                  value={IdCarteira}
                  onChange={value => this.changeValue('IdCarteira', value === null ? null : value.Id)}
                  options={wallets}
                  className='select-carteira'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  name='user'
                  label={intl.get('commons.user')}
                  placeholder={intl.get('commons.user')}
                  valueKey="Id"
                  labelKey="IdentificacaoUsuario"
                  value={IdUsuario}
                  onChange={value => this.changeValue('IdUsuario', value === null ? null : value.Id)}
                  options={users}
                  className='select-usuario'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={12} md={12}>
                <Switcher
                  name="SomenteDesabilitados"
                  label={intl.get('master-data.coordinationsAndUsers.users.actions.disabled')}
                  value={SomenteDesabilitados}
                  onChange={value => this.changeValue('SomenteDesabilitados', value.checked)}
                  thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                  trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                  className='toogle-somente-desabilitados'
                />
              </Col>
            </Row>
          </div>

          <Row className="footerFilterContainer no-margin">
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <hr className="filter-divisor" />
                </Col>
              </Row>
              <Row className="button-container">
                <Col xs={6} lg={6} md={6}>
                  <Button type="primary" onClick={this.handleCloseSearch} value={intl.get('filters.search')} className="btn-close-search" />
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <Button type="secondary" onClick={this.handleCleanClick} value={intl.get('filters.cleanSearch')} className="btn-clean" />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </StyledFilter>
    )
  }
}

FiltroUsuario.propTypes = {
  initialData: PropTypes.object,
  transitionTime: PropTypes.number,
  handleClose: PropTypes.func
}

FiltroUsuario.defaultProps = {
  transitionTime: 500,
  initialData: {}
}

export default FiltroUsuario


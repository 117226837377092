import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px;

  span{
    cursor: auto;
  }

  .col-description{
    padding: 5px;
    padding-left: 15px;
  }

`

export const Title = styled.span`
  font-size: 30px;
  line-height: 30px;
  display: flex;
  color: #3B495E;
  white-space: break-spaces;
`

export const SubTitle = styled.span`
  font-weight: 600;
  font-size: 17px;
  line-height: 14px;
  color: #2870B2;
`

export const DescriptionTitle = styled.span`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #4F5C6F;
`
export const Description = styled.span`
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #4F5C6F;
  white-space: break-spaces;
`

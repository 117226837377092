import React, { useState } from 'react'
import Dialog from 'material-ui/Dialog';
import intl from 'react-intl-universal'
import { Button } from "components/uikit-adapter/index"
import icnClose from 'images/close-icone.svg'
import { useGlobalContext } from 'hooks/useGlobalContext';
import ModalFeedback from 'components/modal-feedback'
import Loading from "components/center-loading";
import HeaderForm from 'pages/composicao-carga/components/header-form';
import { formatErrorMessage } from 'utils/handle-error';
import PedidosAprovados from 'pages/composicao-carga/pedidos-aprovados';
import { buildEmergencyRenegotiateDto } from './utils';
import { ModalReviewEmergencyRenegotiate } from './modal-review-emergency-renegotiate';
import { TitleModalFeedback } from './title-modal-feedback';
import { createEmergencyNegotiation } from 'pages/load-composition-building/load-composition-building.service';
import { validateDate } from './validations';

export const EmergencyRenegotiate = ({
  open,
  composicao,
  unidadesNegocio,
  fornecedores,
  SituacaoValor,
  handleClose,
  pedidos,
  handleCloseAll
}) => {
  const [loadComposition, setLoadComposition] = useState(composicao)
  const [isLoading, setIsLoading] = useState(false);
  const [modalFeedback, setModalFeedback] = useState({
    isOpen: false,
    title: <TitleModalFeedback idLoadComposition={loadComposition.IdComposicao} />,
    message: intl.get("composition.actions.successfullySendEmergencyNegotiate")
  })
  const [openModalReviewEmergencyRenegotiate, setOpenModalReviewEmergencyRenegotiate] = useState(false)
  const { showFeedback } = useGlobalContext()

  const handleCloseModalFeedback = () => {
    setModalFeedback(prevState => ({
      ...prevState,
      isOpen: false
    }));
    handleCloseAll()
  }

  const handleChange = (field, value) => {
    setLoadComposition(prevState => ({
      ...prevState,
      [field]: value
    }))
  }

  const handleSend = async () => {
    setIsLoading(true);
    try {
      const dto = buildEmergencyRenegotiateDto(loadComposition, pedidos);
      await createEmergencyNegotiation(dto);
      setModalFeedback(prevState => ({ ...prevState, isOpen: true }))
    }
    catch (err) {
      showFeedback(formatErrorMessage(err))
    }
    setIsLoading(false);
  }

  const handleOpenModalReviewEmergencyRenegotiate = () => {
    const validated = validateDate(loadComposition.DataColeta, loadComposition.DataEntrega)
    if (validated) {
      showFeedback(validated)
      return
    }

    setOpenModalReviewEmergencyRenegotiate(true);
  }

  return (
    <>
      <Dialog
        title={`${intl.get('menu.subMenuGeneral.loadComposition')} ${loadComposition.IdComposicao}`}
        contentStyle={{ width: '98%', maxWidth: 'none', position: 'relative', height: '900px' }}
        open={open}
        onRequestClose={handleClose}
        actionsContainerClassName="container-action-buttons__composition"
        actions={
          <>
            <span
              className='btn-close-modal'
              onClick={handleCloseAll}
            >
              <img src={icnClose} />
            </span>
            <Button
              type="primary"
              value={intl.get("composition.actions.sendEmergencyNegotiate")}
              width={"250px"}
              onClick={handleOpenModalReviewEmergencyRenegotiate}
              className="button__composicao-carga_emergency_negotiation"
            />
            <Button
              type="default"
              value={intl.get("composition.actions.cancelEmergencyNegotiate")}
              width={"250px"}
              onClick={handleClose}
              className="button__composicao-carga_emergency_negotiation"
            />
          </>
        }
      >
        <HeaderForm
          composicao={loadComposition}
          unidadesNegocio={unidadesNegocio}
          fornecedores={fornecedores}
          SituacaoValor={SituacaoValor}
          handleChangeDate={handleChange}
          handleChangeNote={e => handleChange("Observacao", e.target.value)}
          modoEdicao
          modoLeitura
          allowChangingDates
          showNote
          hideObs
        />

        {/* refatorar essa parte quando os pedidos puderem ser editaveis */}
        {pedidos.length > 0 &&
          <div className='list-pedidos'>
            <PedidosAprovados
              pedidos={pedidos}
              enableSelected={false}
            />
          </div>
        }
        {/* refatorar essa parte quando os pedidos puderem ser editaveis */}

        <Loading isLoading={isLoading} fullHeightParent />
      </Dialog>
      <ModalReviewEmergencyRenegotiate
        open={openModalReviewEmergencyRenegotiate}
        loadComposition={loadComposition}
        handleSend={handleSend}
        handleClose={() => setOpenModalReviewEmergencyRenegotiate(false)}
        isLoading={isLoading}
      />
      <ModalFeedback
        message={modalFeedback.message}
        open={modalFeedback.isOpen}
        title={modalFeedback.title}
        handleClose={handleCloseModalFeedback}
      />
    </>
  )
}

export const getEmergencyNegotiationModel = (_infosRoute, _model, _dataDeColeta, _ordersMetadata) => {
    return {
        IdComposicaoCarga: parseInt(_infosRoute.composicaoId),
        DataColetaProposta: _dataDeColeta,
        DataEntregaProposta: _model.dataEntregaComposicao,
        HoraEntregaProposta: _model.horaEntregaComposicao,
        Observacao: _model.breweryNote,
        PedidosMetaData: formatEmergencyNegotiationMetadata(_ordersMetadata, _dataDeColeta, _infosRoute)
    }
};

const formatEmergencyNegotiationMetadata = (_ordersMetadata, _dataDeColeta, _infosRoute) => {
    const _renegociacaoAlteracaoPedidos = _ordersMetadata.AlteracaoPedidos?.map(x => ({
      IdPedido: x.IdPedido,
      DataColeta: _dataDeColeta,
      DataEntrega: _infosRoute.dia,
      HoraEntrega: _infosRoute.hora,
      Quantidade: x.Quantidade,
      Observacao: x.Observacao,
    }));

    const _renegociacaoAdicaoSugestao = _ordersMetadata.AdicaoSugestao?.map(x => ({
      IdSugestao: x.IdSugestao,
      IdMaterial: x.IdMaterial,
      IdUnidadeNegocio: x.IdUnidadeNegocio,
      IdFornecedor: x.IdFornecedor,
      DataColeta: _dataDeColeta,
      DataEntrega: _infosRoute.dia,
      HoraEntrega: _infosRoute.hora,
      Quantidade: x.Quantidade,
      Observacao: x.Observacao,
    }));

    const _renegociacaoAdicaoPedido = _ordersMetadata.AdicaoPedidos?.map(x => ({ IdPedido: x }));
    const _renegociacaoRemocaoPedido = _ordersMetadata.RemocaoPedidos?.map(x => ({ IdPedido: x }));

    return {
      AdicaoPedidos:_renegociacaoAdicaoPedido,
      AlteracaoPedidos:_renegociacaoAlteracaoPedidos,
      RemocaoPedidos: _renegociacaoRemocaoPedido,
      AdicaoSugestoes: _renegociacaoAdicaoSugestao
    }
}
import styled from 'styled-components'

 const StyledConfiguracoesIntegracoes = styled.div`

    .card-area{
        width: 100%;
        height: 100%;
        position: absolute;
    }
 
    .header-card{
        position: relative;
        margin-top: 15px;
        height: 45px;
      }

    .card-integracoes {
        width: 340px;
        margin-top: 15px;
        margin-left: 15px;
        position: relative;
    }

    .body-card {
        &:not(:last-child) {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          position: relative;
          margin-top: 2px;
          margin-bottom: 0px;
          width: 100%;
          padding-bottom: 0px;
        }
    }

    .description-label {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
      font-size: 15px;
      font-weight: 700;
      line-height: 1.82;
      position: relative;
      width: 100%;
      text-transform: uppercase;
      color: #2870b2;
      margin-top: 8px;
    }

    .status-label span:first-child {
      font-size: 12px;
      font-weight: 700;
      line-height: 1.82;
      position: relative;
      width: 100%;
      text-transform: uppercase;
      color: #9aa4ad;
    }

    .status-label span:last-child {
      font-size: 12px;
      font-weight: 700;
      line-height: 1.82;
      position: relative;
      width: 100%;
      text-transform: uppercase;      
      color: ${props => props.corStatus.ColorStatusArrow}
    }

    .card-label {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .card-label span:first-child {
      font-size: 11px;
      font-weight: 700;
      line-height: 1.82;
      color: #9aa4ad;
      width: 100%;
      text-transform: uppercase;
    }

    .card-label span:last-child {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.07;
      color: #393939;
    }

    .card-actions {      
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .card-actions .icon-button {
      width: 50px;
      margin-right: 8px;
    }

    .card-actions .execute-button {
      width: 100px;
    }
`

 export default StyledConfiguracoesIntegracoes

import React, { Component } from 'react';
import Dialog from 'material-ui/Dialog/Dialog';
import PropTypes from 'prop-types';
import { Button } from '@hbsis.uikit/react';
import { Row, Col } from 'react-flexbox-grid';
import GradeExcedidalistItem from './grade-excedida-list-item'
import intl from 'react-intl-universal'

import StyledGradeExcedidaList from './grade-excedida-list.styled';

class ModalGradeExcedida extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const gradeData = this.props.gradeData;
    const msgAlert = gradeData[0].Sugestoes.length
    return (
      <Dialog
        title={intl.get('grid-vision.gradeExcedida.title')}
        open={this.props.showModalAlertaEstouroDaGrade}
        contentStyle={{ maxWidth: '900px' }}
        actionsContainerClassName="container-action-buttons"
        autoScrollBodyContent
      >
        {!!gradeData && (
          <StyledGradeExcedidaList>
            <div className='grade-excedida-list-header'>
              <Row>
                <Col xs={3}>
                  <div className='header-item'>{intl.get('grid-vision.gradeExcedida.deliveryCapacityExceeded')}</div>
                </Col>
                <Col xs={3}>
        <div className='header-item'>{intl.get('grid-vision.gradeExcedida.supplierFamily')}</div>
                </Col>
                <Col xs={2}>
                  <div className='header-item'>{intl.get('commons.colDate')}</div>
                </Col>
                <Col xs={2}>
                  <div className='header-item'>{intl.get('commons.deliveryDate')}</div>
                </Col>
                <Col xs={2}>
                  <div className='header-item item-align'>{intl.get('grid-vision.gradeExcedida.carsGrid')}</div>
                </Col>
              </Row>
            </div>

            <div>
              {gradeData.map((item, index) => (
                <GradeExcedidalistItem key={index} gradeData={item} />
              ))}
            </div>

            { msgAlert === 1 ?
              <Row>
                <Col>
                  <div className='alert-message'>{intl.get('grid-vision.gradeExcedida.suggestionMessage')}</div>
                </Col>
              </Row>
              :
              <Row>
                <Col>
                  <div className='alert-message'>{intl.get('grid-vision.gradeExcedida.suggestionsMessage')}</div>
                </Col>
              </Row>
            }
            <div>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <Row>
                    <Col xs={12} lg={12} md={12}>
                      <hr className='filter-divisor' />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} lg={6} md={6} />
                    <Col xs={3} lg={3} md={3}>
                      <Button
                        type="primary"
                        htmlType="button"
                        className="button"
                        value={intl.get("geral.buttonsDefault.cancel")}
                        onClick={this.props.handleShowModalEstouroDaGrade}
                      />
                    </Col>
                    <Col xs={3} lg={3} md={3}>
                      <Button
                        type='danger'
                        htmlType="button"
                        className="button"
                        value={intl.get("geral.buttonsDefault.accept")}
                        onClick={this.props.estouroDeGradeAceito}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </StyledGradeExcedidaList>
        )}
      </Dialog>
    );
  }
}

ModalGradeExcedida.propTypes = {
  handleShowModalEstouroDaGrade: PropTypes.func.isRequired
};

export default ModalGradeExcedida;

import React from 'react'
import { Button, Input, SelectBox } from 'components/uikit-adapter/index'
import { useZapmslContext } from '../../../../../context/zapmslContext';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { errorMessage, formatNewDataId, sortTable } from '../../../../../utils/format';
import { formatWithId, getSupplierScheduleAgreementByMaterial, insertAdvancedPackagingMaterialSchedule } from '../../../../../primary-material-schedule.service';
import intl from 'react-intl-universal'
import { validarDadosInsert } from '../../../../../utils/validate';
import BtnMore from 'images/btn-more.svg'
import moment from "moment"
import { SaveIcn } from "components/icons/icn-index.icon";
import '../../../../../../auto-load-optimization/components/styled.css'

const BtnMoreLine = () => {
  const {
    startLoading,
    stopLoading,
    zapmslData,
    updateTableWithData,
    filters,
    newLineInProgress,
    setNewLineInProgress,
    materials
  } = useZapmslContext();

  const {
    showFeedback
  } = useGlobalContext();

  const newLineData = {
    Plant: filters.plant.Centro,
    Line: undefined,
    DeliveryTime: undefined,
    DeliveryDate: undefined,
    PalletQuantity: undefined,
    Door: undefined,
    Comments: undefined,
    Material: undefined,
    SchedulingAgreement: undefined,
    Supplier: undefined
  }

  const newVolume = {
    Line: () => <Input
      id='new-line'
      placeholder={intl.get('primaryMaterialScheduleList.list.columns.line')}
      style={{ width: '40px', height: '30px', position: 'absolute', top: '-18px' }}
    />,
    MaterialNumber: () =>
      <div className="primaryMaterial__selectbox--wrapper">
        <SelectBox
          id="new-material"
          className="primaryMaterial__selectbox"
          value={newLineData.Material}
          placeholder={intl.get('commons.material')}
          label={intl.get("commons.material")}
          options={materials}
          onChange={(e) => updateSupplierListAndChangeMaterial(e)}
          valueKey="CodigoMaterial"
          labelKey="DescricaoCompleta"
          searchable
          data-testid='primaryMaterial__selectbox'
          dontShowLabel={true}
          width={"250px"}
          isPrimaryMaterial={true}
        />
      </div>,
    DeliveryTime: () =>
      <Input
        htmlType="time"
        id="new-delivery-time"
        style={{ height: '30px', position: 'absolute', top: '-18px' }}
        data-testid="inputInterval"
        className='new-delivery-time'
      />,
    DeliveryDate: () => <Input
      id='new-delivery-date'
      minDate={moment().format('YYYY-MM-DD')}
      maxDate="9999-12-31"
      className="drp-input-date drp-nomargin data-de-picker new-delivery-date"
      htmlType="date"
      style={{ height: '30px', position: 'absolute', top: '-18px' }}
    />,
    Pallets: () => <Input
      id='new-quantity'
      placeholder={intl.get('primaryMaterialScheduleList.list.columns.pallets')}
      style={{ width: '60px', height: '30px', position: 'absolute', top: '-18px' }}
      htmlType="number"
    />,
    Door: () => <Input
      id='new-door'
      placeholder={intl.get('primaryMaterialScheduleList.list.columns.door')}
      style={{ width: '60px', height: '30px', position: 'absolute', top: '-18px' }}
    />,
    Comments: () => <Input
      id='new-comments'
      placeholder={intl.get('primaryMaterialScheduleList.list.columns.comments')}
      style={{ width: '60px', height: '30px', position: 'absolute', top: '-18px' }}
    />,
    edit: () =>
      <span data-testid='handle-save-new-line' style={{ cursor: 'pointer', paddingRight: '5px' }}
        onClick={saveNewLine}><SaveIcn width="15px" height="15px" />
      </span>,
    SupplierCode: () => supplierCode([]),
    isNewLine: true
  }

  const handleNewLine = (volume) => {
    let _data = zapmslData
    _data = [
      volume,
      ..._data
    ]
    updateTableWithData(formatWithId(_data));
  }

  const newLine = () => {
    handleNewLine(newVolume);
    setNewLineInProgress(true)
  }

  const processError = (message) => {
    showFeedback(errorMessage(message))
  }

  const updateSupplierListAndChangeMaterial = async (e) => {
    const suppliers = await getSupplierScheduleAgreementByMaterial(e.Id);
    supplierCode(suppliers)
    changePrimaryMaterial(e)
    handleNewLine(newVolume)
  }

  const supplierCode = (options, value) => {
    newLineData.Supplier = value
    newVolume.ScheduleAgreement = value ? value.ScheduleAgreement : ""
    newVolume.SupplierName = value ? value.NomeFornecedor : ""
    newVolume.SupplierCode = () =>
      <div className="primaryMaterial__selectbox--wrapper">
        <SelectBox
          id="new-supplier"
          className="supplier__selectbox"
          value={value}
          placeholder={intl.get('commons.supplier')}
          options={options}
          onChange={(e) => supplierCode(options, e)}
          valueKey="Id"
          labelKey="Nome"
          searchable
          data-testid='supplier__selectbox'
          label='supplier'
          dontShowLabel={true}
          width={"250px"}
          isPrimaryMaterial={true}
        />
      </div>
    handleNewLine(newVolume)
  }

  const changePrimaryMaterial = (value) => {
    newLineData.Material = value
    newVolume.MaterialNumber = () =>
      <div className="primaryMaterial__selectbox--wrapper">
        <SelectBox
          id="new-material"
          className="primaryMaterial__selectbox"
          value={newLineData.Material}
          placeholder={intl.get('commons.material')}
          label={intl.get("commons.material")}
          options={materials}
          onChange={(e) => updateSupplierListAndChangeMaterial(e)}
          valueKey="CodigoMaterial"
          labelKey="DescricaoCompleta"
          searchable
          data-testid='primaryMaterial__selectbox'
          dontShowLabel={true}
          width={"250px"}
          isPrimaryMaterial={true}
        />
      </div>
    handleNewLine(newVolume)
  }

  const saveNewLine = async () => {
    try {
      startLoading()
      newLineData.Plant = filters.plant.Centro
      newLineData.Line = document.getElementById('new-line').value
      newLineData.DeliveryDate = document.getElementById('new-delivery-date').value
      newLineData.PalletQuantity = document.getElementById('new-quantity').value
      newLineData.Door = document.getElementById('new-door').value
      newLineData.Comments = document.getElementById('new-comments').value
      newLineData.DeliveryTime = document.getElementById('new-delivery-time').value
      newLineData.Material = newLineData.Material ? newLineData.Material.CodigoMaterial : ""
      newLineData.SchedulingAgreement = newLineData.Supplier ? newLineData.Supplier.ScheduleAgreement : ""

      if (!validarDadosInsert(newLineData, processError)) {
        const response = await insertAdvancedPackagingMaterialSchedule(newLineData);
        processNewData(response);
      }
    }
    catch (e) {
      processError(e)
    }
    stopLoading()
  }

  const processNewData = (line) => {
    const _data = [...zapmslData]
    line.Id = -1
    _data.unshift(line)
    const sortData = sortTable(_data)
    updateTableWithData([...formatNewDataId(sortData)])
    showFeedback(intl.get("feedbacks.savedRegister"))
    setNewLineInProgress(false)
  }

  return (
    <div className='btn-wrapper' data-testid='btn-more-new-line' title={intl.get('geral.buttonsDefault.add')}>
      <Button
        type="secondary"
        icon={BtnMore}
        disabled={newLineInProgress}
        className='btn-icon'
        onClick={newLine}
      />
    </div>
  )
}

export default BtnMoreLine

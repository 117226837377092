import styled from 'styled-components'

const ReleaseVersionFormStyled = styled.div`

.footer-dialog-buttons-release-version{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: space-between;
  padding-top: 35px;
}

.label-input {
  text-transform: uppercase;
}

.button{
  margin: 2px;
  width: 100%;
}

.container {
    width: 97% !important;
    padding: 7px;
    margin-top: -16px;
    border: 1px solid #c5d0e1 !important;
  }
`
export default ReleaseVersionFormStyled
import React, { useEffect, useState, useImperativeHandle, forwardRef } from 'react'
import moment from 'moment';
import { Grid, Button } from '@hbsis.uikit/react';
import Chart from 'components/chart';
import TitleSecond from 'components/title-second';
import LastUpdate from 'components/last-update';
import IconFilterGray from 'images/icn-filter-gray.svg';
import intl from 'react-intl-universal';
import DateInput from 'components/date-input';
import { Row, Col } from "react-flexbox-grid";
import { compareDates } from 'utils/date';
import { searchChartData } from '../../pages/stocks-detail/stocks-detail.service';
import { formatErrorMessage } from '../../utils/handle-error';
import Loading from "components/center-loading";
import { useGlobalContext } from 'hooks/useGlobalContext';
import * as S from './styled'
import { loadFilter } from './utils';
import { ISO_DATE_FORMAT } from 'utils/format-date';

const chartDataDefault = {
  Details: [],
  guides: {},
}

const ChartStocks = forwardRef(({
  lastUpdateDate,
  idFamiliaRotuladaCentro,
  categoryField,
  pedidoVolume,
  estoqueVariavel,
  transfSaida,
  transfEntrada,
  hideLastUpdate,
  hideLoading,
  sendInfosToSecondChart,
  updateChart
}, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(chartDataDefault);
  const [filtroGrafico, setFiltroGrafico] = useState(loadFilter());
  const { showFeedback } = useGlobalContext();

  const mountChartData = (_chartData) => {
    if (!_chartData) {
      _chartData = chartDataDefault
    }
    return ({
      ..._chartData,
      guides: {
        Over: _chartData ? _chartData.Over : 0,
        Out: _chartData ? _chartData.Out : 0,
      }
    })
  }

  const changeValueFilter = (name, value) => {
    const dateGreaterThanToday = compareDates(value, filtroGrafico.dataDe)
    if (name === 'dataDe' && !value || !dateGreaterThanToday) {
      return
    }

    setFiltroGrafico(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFilter = () => {
    localStorage.setItem("filterChart", JSON.stringify(filtroGrafico));
    loadChart();
  }


  const loadChart = async () => {
    setIsLoading(true);
    try {
      const model = {
        DataDe: filtroGrafico.dataDe,
        DataAte: filtroGrafico.dataAte
      }
      const _chartData = await searchChartData(idFamiliaRotuladaCentro, model);
      const _data = mountChartData(_chartData);
      setChartData(_data);
    }
    catch (e) {
      showFeedback(formatErrorMessage(e));
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (sendInfosToSecondChart) {
      sendInfosToSecondChart(filtroGrafico)
    }
  }, [filtroGrafico])

  useImperativeHandle(ref, () => ({
    async loadChart() {
      await loadChart();
    }
  }));

  useEffect(() => {
    loadChart();
  }, [])

  useEffect(() => {
    loadChart()
  }, [updateChart])

  return (
    <S.Wrapper>
      <Grid fluid>
        <Row className="chart-title-row">
          <Col xs={4}>
            <TitleSecond>{intl.get('commons.stock')}</TitleSecond>
          </Col>
          <Col xs={8}>
            {!hideLastUpdate && <LastUpdate
              title={intl.get('stocks.lastUpdate')}
              dataText={moment(lastUpdateDate, 'DD/MM HH:mm').format('[DM] LT')}
            />
            }
          </Col>
        </Row>
        <Row bottom="xs row-filter">
          <Col xs={4}>
            <DateInput
              label={intl.get('stocks.from')}
              value={filtroGrafico.dataDe}
              minDate={moment().format(ISO_DATE_FORMAT)}
              onChange={(date) => changeValueFilter('dataDe', date)}
              maxDate="9999-12-31"
            />
          </Col>
          <Col xs={4}>
            <DateInput
              label={intl.get('stocks.until')}
              value={filtroGrafico.dataAte}
              onChange={(date) => changeValueFilter('dataAte', date)}
              minDate={moment().format(ISO_DATE_FORMAT)}
              maxDate="9999-12-31"
            />
          </Col>
          <Col xs={4}>
            <Button
              value={intl.get('filters.filter')}
              type='secondary'
              onClick={handleFilter}
              icon={IconFilterGray}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {chartData.Details && (chartData.Details.length > 0) &&
              <Chart
                data={chartData.Details}
                categoryField={categoryField}
                guides={chartData.guides}
                pedidoVolume={pedidoVolume}
                estoqueVariavel={estoqueVariavel}
                transfSaida={transfSaida}
                transfEntrada={transfEntrada}
              />
            }
            <Loading
              isLoading={isLoading && !hideLoading}
              fullHeightParent
            />
          </Col>
        </Row>
      </Grid>
    </S.Wrapper>
  )
})

export default ChartStocks;

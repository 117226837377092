import { withStyles } from "@material-ui/core"
import styled from "styled-components"
import MenuMui from "@material-ui/core/Menu";

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display:flex;
  align-items: center;
  height: 100%;
`
export const ContainerButton = styled.div`
  background-color: var(--color-brand-300);
  height: 100%;
`
export const Username = styled.span`
  @media (max-width: 1152px) {
    visibility: hidden;
    width: 0px;
  }

  display: flex;
  align-items: center;
  margin-right: 16px;
  height: 45px;
  font-size: 13px;
  color: #F4F5F8;
  font-style: var;
  font-weight: var(--font-weight-semibold);
  line-height: 150%; /* 24px */
`
export const MenuList = styled.div`
  height: 100%;

  button {
    height: 100% !important;
  }

  .acoes__icon:hover {
    background-color: var(--color-brand-300);
  }

  .btn-import-export-order{
    height: 40px;
  }
`

export const Menu = withStyles({})((props) => (
  <MenuMui
    style={{ fontWeight: "600", fontSize: "14px" }}
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

import React, { Component } from 'react'
import PropTypes  from 'prop-types'
import Dialog from 'material-ui/Dialog'
import { Button } from '@hbsis.uikit/react'
import './delete-dialog.css'
import intl from 'react-intl-universal'
class DeleteDialog extends Component {
  render() {
    return (
      <Dialog
        title={this.props.title}
        contentStyle={{ width: '400px' }}
        open={this.props.open}
        onRequestClose={this.props.handleClose}
        actionsContainerClassName="container-action-buttons__deleteModal"
        actions={
          [
            <Button
              value={intl.get('master-data.general.materials.delete')}
              type='danger'
              onClick={() => { this.props.handleConfirm() }}
              className='container-action-buttons__deleteModal--buttons'
            />,
            <Button
              value={intl.get('master-data.general.materials.cancel')}
              data-testid="buttonCancel"
              type='default'
              onClick={() => { this.props.handleClose() }}
              className='container-action-buttons__deleteModal--buttons'
            />
          ]
        } >
        <div className='container-message'>
          {this.props.warningMessage}
        </div>
      </Dialog>
    )
  }
}

DeleteDialog.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  warningMessage: PropTypes.string.isRequired
}

export default DeleteDialog

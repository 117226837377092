import React from 'react'
import { Checkbox } from '@hbsis.uikit/react'
import { Link } from 'react-router-dom'
import LinkCode from 'components/link-code-span'
import { rulesForCheckbox, rulesForCheckboxAll } from './rules'
import { getValue } from './utils'
import * as S from './styled'

const TableList = ({
  data,
  columns,
  onChange,
  borderColor,
  selected,
  action,
  scope
}) => {

  const renderItem = (item, record) => (
    <S.TableData key={item.prop}>
      {record[item.prop] && item.hasAction ?
        <Link to='#' style={{ display: 'contents' }}>
          <LinkCode onClick={() => action(record)}>
            {record[item.prop]}
          </LinkCode>
        </Link>
        :
        getValue(record[item.prop])
      }
    </S.TableData>
  )

  return (
    < S.Table borderColor={borderColor} >
      <S.TableRow>
        {columns.map(column => (
          column.visible &&
          <S.TableHeader key={column.prop}>
            {
              column.prop === 'Checkbox' ?
                <S.Container>
                  <Checkbox
                    role="checkbox-all"
                    name={`checkbox-all`}
                    checked={rulesForCheckboxAll(data, selected, scope)}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                </S.Container>
                :
                <span>{column.name}</span>
            }
          </S.TableHeader>
        ))}
      </S.TableRow>
      {
        data.map((record) => (
          <Link
            key={record.Id}
            to={record.UrlRedirect}
            className='table-row-data'
          >
            {columns.map(column => (
              column.prop === 'Checkbox' ?
                <Link key={record.Id}
                  to='#'
                  style={{ display: 'contents' }}
                >
                  <S.TableData
                    displayTableCell
                    onClick={() => onChange(null, record.Id)}
                  >
                    <S.Container>
                      <Checkbox
                        role={`checkbox-${record.Id}`}
                        name={`checkbox-${record.Id}`}
                        checked={rulesForCheckbox(record, selected, scope)}
                      />
                    </S.Container>
                  </S.TableData>
                </Link>
                : column.visible && renderItem(column, record)
            ))}
          </Link>
        ))
      }
    </S.Table >
  )
}

export default TableList

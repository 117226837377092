import styled from 'styled-components'

const StyledModalConfirmacaoExecucaoIntegracao = styled.div`

  .mensagem-modal-integracao-mensagem {
    margin-top: 10px;
    margin-bottom: 28px;
    font-weight: 600;
    color: #b63f43;
    font-size: 14px;
    text-align: left;
  }

  .botoes-modal-integracao {
    display: flex;
    justify-content: flex-end;
  }

  .divisor-modal-integracao {
    width: 100%;
    height: 1px;
    background-color: #ebecec;
    margin-bottom: 20px;
}
`

export default StyledModalConfirmacaoExecucaoIntegracao

import React from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'
import moment from 'moment'

const CardInNegotiation = ({ data, handleClick, idCompositionSelected }) => (
  <>
    {data.map(composicao => (
      <S.Wrapper
        key={composicao.Id}
        onClick={() => handleClick(composicao)}
        isSelected={composicao.Id === idCompositionSelected}
        data-testid='in-negotiation-slot-wrapper'>
        <S.Hora isSelected={composicao.Id === idCompositionSelected}>
          {composicao && <span> {moment(composicao.Hora, "HH:mm:ss").format("HH:mm")}</span>}
        </S.Hora>
        <S.Content>
          <span>{intl.get("commons.delivery")} {composicao.Id} {intl.get("commons.inNegotiation")}</span>
        </S.Content>
      </S.Wrapper>
    ))}
  </>
)

export default CardInNegotiation;

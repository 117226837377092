import React, { createContext, useContext, useState } from 'react'
import Loading from 'components/center-loading'
import { volumeFiltersDefault } from './types/filter'
const OrderVolumeContext = createContext()

const OrderVolumeProvider = ({ children }) => {
  const [pagination, setPagination] = useState({
    CurrentPage: 0,
    TotalItems: 0,
    TotalPages: 0,
    size: 50
  })
  const [items, setItems] = useState([])
  const [checkbox, setCheckbox] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({
    ...volumeFiltersDefault,
    UseFilterDefault: JSON.parse(localStorage.getItem("filterMyItemsOnly"))
  })

  return (
    <OrderVolumeContext.Provider value={{
      items,
      setItems,
      pagination,
      setPagination,
      filter,
      setFilter,
      checkbox,
      setCheckbox,
      loading,
      setLoading
    }}>
      {children}
      <Loading isLoading={loading} />
    </OrderVolumeContext.Provider>
  )
}

const useOrderVolumeContext = () => useContext(OrderVolumeContext)

export { OrderVolumeProvider, OrderVolumeContext, useOrderVolumeContext }

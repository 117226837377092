import Fetch from "utils/fetch"
import { convertToArrayQuery } from '../gerencial/gerencial.service'
import { parseFloatByLocale } from "locales/utils"

export const buscaEstoqueCompartilhado = async (filter) => {

  const fetchedFilters = await filter ? filter : {}
  let query = undefined

  if (fetchedFilters) {
    query = convertToArrayQuery(
      [
        fetchedFilters.GrupoFornecedor,
        fetchedFilters.Familias,
        fetchedFilters.FamiliasRotuladas,
        fetchedFilters.Carteiras,
      ],
      [
        'GrupoFornecedor',
        'Familias',
        'FamiliasRotuladas',
        'Carteiras',
      ]
    )
  }

  const url =
    query
      ? `/EstoqueCompartilhado/fornecedores?${query}`
      : `/EstoqueCompartilhado/fornecedores`


  return Fetch.get(url).then(({ data }) => {

    return {
      Anotacoes: data.Anotacoes,
      Fornecedores: data.Fornecedores,
      Carregou: data.Fornecedores.length > 0 ? true : false
    }
  })
}

export const salvarMateriaisPorFornecedor = (idFornecedor, materiais) => {
  const materiaisConversaoQuantidade = materiais.map(m => {
    return {
      NumeroMaterial: m.NumeroMaterial,
      Quantidade: parseFloatByLocale(m.Quantidade)
    }
  })
  return Fetch.post(`/EstoqueCompartilhado/${idFornecedor}/materiais`, materiaisConversaoQuantidade).then(({ data }) => data)
}

export const getDownloadSupplierStock = () => {
  return Fetch.get("supplierStock/export").then(({ data }) => data)
}

export const getDownloadSupplierStockModel = () => {
  return Fetch.get("supplierStock/export-model").then(({ data }) => data)
}

export const salvarAnotacoes = (anotacoes) => {
  return Fetch.post(`/EstoqueCompartilhado/anotacoes`, anotacoes).then(({ data }) => data)
}


import { useEffect, useState } from 'react';
import { useConsolidatedStocksContext } from 'features/consolidated-stocks/context'
import { getConsolidatedStocks } from 'features/consolidated-stocks/consolidated-stocks.service';
import Loading from "components/center-loading";
import intl from 'react-intl-universal'
import {
  ClbTable, ClbTableTHeadRow,
  ClbTableTBodyRow,
  ClbTableTHead,
  ClbTableTBody,
} from '@celebration/design-system-react'
import { ActionsTableBody } from './actions-table-body';
import { MainTableBody } from './main-table-body';
import { HeaderTableBody } from './header-table-body';
import { HeaderTableHead } from './header-table-head';
import { MainTableHead } from './main-table-head';
import { ActionsTableHead } from './actions-table-head';

import '../../../../ds.css'
import * as S from './styled'

export const ConsolidatedStocksTable = () => {
  const { filter, setQuantityRegisters } = useConsolidatedStocksContext();
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState();

  const handleScroll = () => {
    const scrollers = document.querySelectorAll('.scroller');

    const scrollAll = (e) => {
      scrollers.forEach((element) => {
        element.scrollLeft = e.target.scrollLeft;
      });
    }

    scrollers.forEach((element) => {
      element.addEventListener('scroll', scrollAll);
    });
  }

  const loadConsolidatedStocks = async () => {
    setIsLoading(true);
    const _data = await getConsolidatedStocks(filter);
    setQuantityRegisters(_data.TotalItems)
    setData(_data);
    setIsLoading(false);
  }

  useEffect(() => {
    handleScroll();
  })

  useEffect(() => {
    loadConsolidatedStocks();
  }, [filter])

  if (!data?.TotalItems && !isLoading) {
    return <S.WrapperNoContent>
      <span className='noRecordsFound'>{intl.get('commons.noRecordsFound')}</span>
    </S.WrapperNoContent>
  }

  if (!data?.TotalItems && isLoading) {
    return <S.WrapperNoContent>
      <Loading isLoading />
    </S.WrapperNoContent>
  }

  return (
    <S.Wrapper>
      <ClbTable>
        <ClbTableTHead>
          <ClbTableTHeadRow>
            <HeaderTableHead />
            <div className='flex'>
              <MainTableHead data={data?.Data[0]} />
              <ActionsTableHead />
            </div>
          </ClbTableTHeadRow>
        </ClbTableTHead>
        <ClbTableTBody>
          {data?.Data?.map(x => (
            <ClbTableTBodyRow key={`${x.MaterialGroupId}-${x.BusinessUnitId}-${x.LabeledFamilyCenterId}`}>
              <HeaderTableBody data={x} />
              <div className='flex'>
                <MainTableBody
                  data={x}
                  labeledFamilyCenterId={x.LabeledFamilyCenterId}
                />
                <ActionsTableBody
                  lastUpdate={x.LastUpdate}
                  labeledFamilyCenterId={x.LabeledFamilyCenterId}
                  handleLoad={loadConsolidatedStocks}
                  setIsLoading={setIsLoading}
                />
              </div>
            </ClbTableTBodyRow >
          ))}
        </ClbTableTBody>
      </ClbTable>
      <Loading isLoading={isLoading} />
    </S.Wrapper >
  )
}
import React, { useEffect, useState } from "react";
import * as S from '../../styled'
import moment from 'moment';
import intl from 'react-intl-universal';
import ButtonEditDateTimeDoor from "../buttonEditDateTimeDoor";
import { useLoadCompositionBuildingContext } from "../../../../context/load-composition-building-context";
import ModalAddNotes from "../modalAddNotes";
import editIcon from 'images/edit.svg';
import { isScheduleAgreementFlow } from "utils/validations-general-settings";

const DestinationBooking = ({ isValidDay, queryParams }) => {
  const [oldParams, setOldParams] = useState({
    dia: null,
    hora: null,
    destinoId: null,
    destinoName: null,
    door: null
  });
  const {
    isEditDateMode,
    setIsEditDateMode,
    params,
    breweryNote,
    isEmergencyNegotiation
  } = useLoadCompositionBuildingContext();
  const [showAddNoteModal, setShowAddNoteModal] = useState(false);
  const [notes, setNotes] = useState();

  useEffect(() => {
    if (breweryNote !== notes) {
      setNotes(breweryNote);
    }
  }, [breweryNote]);

  const handleEditDate = () => {
    setOldParams({
      ...params,
      door: doorDescription
    });
    setIsEditDateMode(true);
  }

  const doorDescription = queryParams.get('doorDescription');
  const isNewSlotSelected =
    oldParams.dia !== params.dia ||
    oldParams.hora !== params.hora ||
    oldParams.door != queryParams.get('doorDescription');
  const disabled = !(isValidDay && isNewSlotSelected);

  const formatDestiny = (destiny) => {
    if (destiny !== undefined){
      const splitedString = destiny.split("-");
      const destinyDescription = isScheduleAgreementFlow() 
        ? splitedString[1]?.replace(/([A-Z])/g, ' $1') 
        : splitedString[1];
      return `${splitedString[0]} - ${destinyDescription}`;
    }
  }

  return (
    <React.Fragment>
      <S.InfosWrapper isLast={!breweryNote} isCentered>
        <div className="destiny-door-content">
          <div className="destiny-content">
            <span className="title">{intl.get("commons.destiny")}</span>
            <span className="description">{formatDestiny(params.destinoName)}</span>
          </div>
          <div className="door-content">
            <span className="title">{intl.get("commons.door")}</span>
            {isEmergencyNegotiation ?
              <span className="description">{doorDescription ?? "-"}</span>
              :
              <span className="description-door" onClick={handleEditDate}>{doorDescription ?? "-"}</span>              
            }
          </div>
        </div>
        <div className="content-date-time">
          <div>
            <span className="title">
              {intl.get("loadComposition.building.deliveryDateAndTime")}
            </span>
            {isValidDay ? (
              <span className="description-date-time" onClick={handleEditDate}>
                {moment(params.dia).format("[DM]")}
                {` ${intl.get("commons.at")} `}
                {moment(params.hora, "HH:mm:ss").format("LT")}
              </span>
            ) : (
              <span className="description">-</span>
            )}
          </div>
          <ButtonEditDateTimeDoor
            disabled={disabled}
            oldParams={oldParams}
            setOldParams={setOldParams}
            queryParams={queryParams}
          />
          {!isEditDateMode && !breweryNote && (
            <S.ButtonAddNotes
              onClick={() => setShowAddNoteModal(true)}
              type="default"
              value={intl.get("loadComposition.breweryNotes.addNotes")}
            />
          )}
        </div>
      </S.InfosWrapper>

      {breweryNote && (
        <S.InfosWrapper isLast>
          <div className="attached-notes">
            <span className="title">
              {intl.get("loadComposition.breweryNotes.attachedNotes")}
              <div
                id="edit"
                aria-label="Edit"
                title={intl.get("geral.buttonsDefault.edit")}
              >
                <img
                  className="btn-edit"
                  src={editIcon}
                  onClick={() => setShowAddNoteModal(true)}
                  style={{ width: '14px', height: '14px', padding: '0px 4px' }}
                />
              </div>
            </span>
            <span className="description-notes" onClick={() => setShowAddNoteModal(true)}>{breweryNote}</span>
          </div>
        </S.InfosWrapper>
      )}

      <ModalAddNotes
        notes={notes}
        setNotes={setNotes}
        showAddNoteModal={showAddNoteModal}
        setShowAddNoteModal={setShowAddNoteModal}
      />
    </React.Fragment>
  );
}

export default DestinationBooking;

import React from 'react';
import { Container, Content, CredentialsBox, CredentialsSection, Divider, Footer, LogoSection } from './login.styled';
import intl from 'react-intl-universal';
import ambevHbsisLogoDark from 'images/login/ambevHbsis-dark.png';
import ontimeMrpLogo from 'images/login/OntimeMRP-logo.png';
import { ClbTooltip, ClbButton, ClbLink, ClbButtonIcon } from '@celebration/design-system-react';

const LoginComponent = () => {
  return (
    <Container>
      <Content>
        <LogoSection>
          <img src={ontimeMrpLogo} id='ontimeMrp-logo'></img>
        </LogoSection>
        <CredentialsSection>
          <CredentialsBox>
            <p id="credentials-box-title">
              {intl.get('login.login')}
              <ClbTooltip label={intl.get('login.tooltipMesage')} position='top-start' id="credentials-box-tooltip-info">
                <ClbButtonIcon size={"sm"} icon={"Info"} baseIcon={false} />
              </ClbTooltip>
            </p>
            <p id='credentials-box-subtitle'>{intl.get('login.accessWithYourCredentials')}</p>
            <ClbButton size='md' styleType='primary' blockedWidth={true} id='button-sso-ambev'> {intl.get('login.ssoAmbev')} </ClbButton>
            <ClbButton size='md' styleType='secondary' blockedWidth={true} id='button-sso-terceiros'> {intl.get('login.ssoThirdPart')} </ClbButton>
            <Divider />
            <ClbLink
              href="/sign-up"
              target={"_self"}
              id='credentials-box-link'
            >
              {intl.get('login.iDontHaveARegistry')}
            </ClbLink>
          </CredentialsBox>
        </CredentialsSection>
      </Content>
      <Footer>
        <span>{intl.get('login.allRightsReserved')}</span>
        <img src={ambevHbsisLogoDark} id='ambevHbsis-logo' />
      </Footer>
    </Container>
  )
}

export default LoginComponent;
import React from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'
import moment from 'moment'

const CardFiled = ({ data, handleClick, idCompositionSelected }) => (
  <>
    {data.map(composicao => (
      <S.Wrapper
        isSelected={composicao.Id === idCompositionSelected}
        onClick={() => handleClick(composicao)}>
        <S.Hora>
          {composicao && <span> {moment(composicao.Hora, "HH:mm:ss").format("HH:mm")}</span>}
        </S.Hora>
        <S.Content>
          <span>{intl.get("commons.delivery")} {composicao.Id} {intl.get("commons.scheduled")}</span>
        </S.Content>
      </S.Wrapper>
    ))}
  </>
)

export default CardFiled;

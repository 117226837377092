import styled from 'styled-components'

export const Transition = styled.div`
  transition: opacity .5s linear;
  opacity: ${props => props.transition ? 0 : 1};
`

export const Wrapper = styled.div`
  padding: 6px;
  border: 1px solid white;
  border-radius: 7px;
  position: fixed;
  right: 250px;
  line-height: 1px;
  top: 3px;
  cursor: pointer;
  ${props => props.finished && 'background-color:white;'};
  transition: all .5s;

  @media (max-width: 1260px) {
    right: 150px;
  }
`

export const Container = styled.div`
  background-color: rgba(43, 55, 71, 0.95);
  color: white;
  position: fixed;
  right: 248px;
  top: 55px;
  padding: 8px;
  transition: all .5s linear;
  border-radius: 4px;
  opacity: ${props => props.active ? 1 : 0};
  transition: opacity .5s linear;

  @media (max-width: 1260px) {
    right: 148px;
  }

  p {
    margin: 4px;
    font-weight: 700;
    font-size: 14px;
  }

  .text__container {
    width: 220px;
    max-width: 220px;
    padding-right: 25px;
  }

  .error-label {
    font-weight: 400;
    font-size: 14px;
    display:flex;
    justify-content: flex-end;
    text-decoration: underline;
    cursor: pointer;
  }
`
export const BeakBottom = styled.div`
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-top: 0;
  border-bottom: 8px solid rgba(43, 55, 71, 0.95);
  position: relative;
  top: -15px;
  left: 228px;
`

export const DialogTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px;
  font-size: 18px;
`
export const DialogActions = styled.div`
  display: flex;
  padding: 16px 20px;
`

export const DialogTitleIcon = styled.span`
  font-weight: 700;

  ::before {
    content:'X';
  }
`

export const DialogContent = styled.div`
  &:nth-of-type(odd) {
    background: #EDEDED;
  }
`

export const DialogContentHeader = styled.div`
  display:flex;
  padding: 4px;
  background-color: #2870B2;
  color: white;
  font-weight: 600;
  font-size: 12px;

  div {
    width: 50%
  }
`
export const DialogContentDetail = styled.div`
  display:flex;
  padding: 6px;
  font-weight: 400;
  font-size: 14px;
  color: #313131;

  div {
    width: 50%;
  }
`


import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Textarea } from '@hbsis.uikit/react'
import { Row, Col } from 'react-flexbox-grid'
import intl from 'react-intl-universal'

import './alterar-pedido-fornecedor.css'

class AlterarPedidoFornecedor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      observacao: ''
    };
  }

  onChangeValue = (prop, value) => {
    this.setState({
      [prop]: value      
    });
  };

  render() {
    const { observacao } = this.state
    const {
      idMaterialAnterior,
      descricaoMaterialAnterior,
      idMaterialNovo,
      descricaoMaterialNovo,
      idPedido,
      handleAccept,
      handleRefuse
    } = this.props
    return (
      <div className="alterar-pedido-fornecedor">
        <div className="alterar-pedido-fornecedor-line">
            <Row>
              <Col>
                <div className="alterar-pedido-fornecedor-read-column">
                  <span>{intl.get('stocks.timeline.previous')}</span>
                  <span>{descricaoMaterialAnterior}</span>
                </div>
              </Col>
            </Row>
        </div>
        <div className="alterar-pedido-fornecedor-line">
          <Row>
            <Col>
              <div className="alterar-pedido-fornecedor-read-column">
                <span>{intl.get('stocks.timeline.new')}</span>
                <span>{descricaoMaterialNovo}</span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="alterar-pedido-fornecedor-line">
          <Row>
            <Col>
              <Textarea
                name="txbObservacao"
                label={intl.get('commons.observation')}
                value={observacao || ''}
                onChange={(event) => this.onChangeValue('observacao', event.currentTarget.value)}
                style={{ width: '400px' }}
              />
            </Col>
          </Row>
        </div>
        <div className="alterar-pedido-fornecedor-line">
          <Row>
              <Col xl={6}>
                <Button
                    value={intl.get('stocks.timeline.refuse')}
                    onClick={() => handleRefuse(idPedido, observacao)}
                    type="danger"
                    className="button"
                  />
              </Col>
              <Col xl={6}>
                <Button
                  value={intl.get('stocks.timeline.accept')}
                  onClick={() => handleAccept(idPedido, idMaterialAnterior, idMaterialNovo, observacao)}
                  type="primary"
                  className="button"
                />
              </Col>
            </Row>
        </div>
      </div>
    )
  }
}

AlterarPedidoFornecedor.propTypes = {
  accept: PropTypes.func,
  refuse: PropTypes.func,
  idPedido: PropTypes.number.isRequired,
  materialAnterior: PropTypes.string,
  materialNovo: PropTypes.string
}

export default AlterarPedidoFornecedor
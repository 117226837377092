import styled from "styled-components"

export const Dropdown = styled.div`
  position: absolute;
  z-index: 30;
  background-color: white;
  color: #475055;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  white-space: nowrap;
`
export const DropdownGroup = styled.div`
  text-align: justify;
  padding: 3px;
  
  ${props => props.disabled && 'display: none !important'};

  &:hover {
    background-color: #AFCEF3;
    border-radius: 2px;
    margin: 1px;
  }
`
export const DropdownGroupLabel = styled.div`
  font-weight: 400;
  font-size: 14px;
  padding: 5px;
  
  &:hover {
    background-color: #AFCEF3;
  }
  
`

export const DropdownItem = styled.div`
  padding: 3px;
`
export const DropdownItemWrapper = styled.div`
  position: absolute;
  margin-left: 100px;
  background-color: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  white-space: nowrap;
 
`
export const DownWrapper = styled.div`
  padding: 0px;
  width: 22px;
  height: 17px;
  position: static;
`
export const Vector = styled.img`
  padding: 10px;
  top: -3px;
  position: relative;
  display: ${props => props.isVisible ? 'none' : 'block'};
`

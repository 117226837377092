import styled from "styled-components"

export const Wrapper = styled.div`
  position: absolute;
  left: 26px;
  height: 45px;
  display: flex;
  align-items: center;
`

export const Logo = styled.img``
import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { RowSimulated, RowTable } from './render'
import { useBottlesContext } from '../../context'
import { getKeys, shiftElement } from '../bottles-tabs/utils'
import { EditingModes } from '../../models/editingModeModel'
import DownVector from 'images/Down-Vector.png'
import * as S from './styled'
import { getCountByType, getKeysByType, stageOfWeek } from './utils'
import { calculateProjection, generateProjectionFactory, generateProjectionSupplier } from './calculate-projection'

let timeout = undefined;
let lastInputChange = ''

export const BottleTable = ({ type, data, simulado, tabIndex, supplier }) => {
  const { editMode, data: dataBottles, setData } = useBottlesContext();
  const isAdvancedMode = () => editMode === EditingModes.Advanced
  const keys = getKeys(type)
  const [collapsed, setCollapsed] = useState(false)

  const getKeysForCollapse = (key) =>
  (key === 'OutgoingMerchandiseSupplier'
    || key === 'BreweryMerchandiseEntry')


  const renderHeaderCollapsed = () => {
    const keys = getKeysByType(type)
    return data[0][keys.key].map((props) => (
      <div key={props.BusinessUnitId}
        title={props[keys.prop]}
        className='brewery-label'>
        {props[keys.prop]}
      </div>
    ))
  }

  const shippingQuantity = getCountByType(data, getKeysByType(type).key);

  const handleRecalculateValues = (businessUnitId, date, value, outgoingValues) => {
    const inputId = `${businessUnitId}-${date}`
    if (lastInputChange === inputId) { clearTimeout(timeout) }
    timeout = setTimeout(() => {
      lastInputChange = inputId
      const recalculatedData = calculateProjection(
        dataBottles,
        date,
        value ? value : 0,
        businessUnitId,
        outgoingValues,
        tabIndex,
        supplier.Name
      )
      setData(recalculatedData)
    }, 300)
  }

  const handleChangeAdvancedMode = (d, value, field) => {
    let _data = dataBottles
    if (field === 'WeeklyPlan') {
      const plant = _data.factories.filter((Data) => Data.Name === d.BusinessUnitPlant)
      const plantData = plant.map(({ Data }) => Data)[0]
      const day = plantData.find((day) => day.Date === d.Date)
      const weekToApplyData = stageOfWeek(day.Date).arr

      _data.factories =  _data.factories.map((factorie) => ({
        ...factorie,
        Data: factorie.Data.map((_) => ({
          ..._,
          WeeklyPlan: _.BusinessUnitId === d.BusinessUnitId &&
            weekToApplyData.includes(new Date(_.Date).toLocaleDateString()) ? value : _.WeeklyPlan
        }))
      }))

    }

    if (type === 'supplier') {
      generateProjectionSupplier(
        _data['suppliers'][tabIndex].Data,
        value ? parseFloat(value) : 0,
        null,
        d.Date,
        field
      )
    }
    if (type === 'factorie') {
      generateProjectionFactory(
        _data['factories'],
        value ? parseFloat(value) : 0,
        null,
        d.Date,
        d.BusinessUnitPlant,
        field
      )
    }
    setData({ ..._data })
  }

  return (
    <S.Table>
      <S.TableHeading>
        {
          keys.map((key, i) => (
            getKeysForCollapse(key) ?
              <S.TableHeadingTitle
                key={i}
                onClick={() => shippingQuantity ? setCollapsed(!collapsed) : null}
              >
                {intl.get(`bottles.keys.${key}`)}
                <div className={`dropdown ${key}`} >
                  <span>{shippingQuantity}</span>
                  <S.Vector collapsed={collapsed} src={DownVector} />
                </div>
                {collapsed && renderHeaderCollapsed()}
              </S.TableHeadingTitle>
              :
              <S.TableHeadingTitle key={i}>
                {intl.get(`bottles.keys.${key}`)}
              </S.TableHeadingTitle>
          ))
        }
      </S.TableHeading>
      <S.TableBody ignoreElements="input">
        {data.map((d, index) => (
          <S.TableRow key={index}>
            {simulado ?
              <RowSimulated
                d={d}
                keys={shiftElement(getKeys(type))}
              /> :
              <RowTable
                d={d}
                isAdvancedMode={isAdvancedMode()}
                keysForCollapse={getKeysForCollapse}
                collapsed={collapsed}
                type={type}
                handleRecalculateValues={handleRecalculateValues}
                handleChangeAdvancedMode={handleChangeAdvancedMode}
              />
            }
          </S.TableRow>
        ))}
      </S.TableBody>
    </S.Table>
  )
}

import React from 'react'
import ReactDOM from 'react-dom'
import App from './app'
import reportWebVitals from './reportWebVitals'
import { AzureAD } from "react-aad-msal"
import { authProvider, authorityForgotPasswordPage, authorityLoginPage } from "./providers/authProvider"
import { BrowserRouter } from "react-router-dom"
import { getAuthenticationProvider } from "./utils/config-environment";
import RoutesLogin from 'config/routes-login'

const initialize = () => {
  const authenticationProvider = getAuthenticationProvider();
  const errorAuthProvider = authProvider().getError()
  const isForgotPasswordPage = errorAuthProvider && errorAuthProvider.errorMessage.search("AADB2C90118") !== -1
  const authority = isForgotPasswordPage ? authorityForgotPasswordPage : authorityLoginPage

  if (authenticationProvider !== "B2C") {
    ReactDOM.render(
        <BrowserRouter>
          <RoutesLogin />
        </BrowserRouter>
      ,
      document.getElementById('root')
    )
  } else {
    ReactDOM.render(
      <AzureAD provider={authProvider(authority)} forceLogin={true}>
        {({ accountInfo, error }) => {
          return (
            <BrowserRouter>
              <App accountInfo={accountInfo} error={error} />
            </BrowserRouter>
          )
        }}
      </AzureAD>,
      document.getElementById('root')
    )
  }
}

initialize()
reportWebVitals()

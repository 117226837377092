import React, { useRef, useState } from 'react'
import useOutsideClick from 'hooks/useOutsideClick'
import intl from 'react-intl-universal'
import { ModalConfirm } from '../bottles-modal'
import { DropdownGroup } from '../bottles-dropdown/dropdown-group'
import { deletePlanning, replicatePlanning } from 'features/bottles/services/bottles-plan.service'
import { useBottlesContext } from 'features/bottles/context'
import { getPeriod, getTextForAlert, getTextForDeleteModal, getTextForReplicateModal, transformOptions } from './utils'
import DownVector from 'images/Down-Vector.png'
import * as S from '../bottles-dropdown/styled'

const BottlesGhostSupplierDropdown = ({ suppliers, selectedSupplier }) => {
  const [openModalReplicateSimulation, setOpenModalReplicateSimulation] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)
  const wrapperRef = useRef(null);
  const { filter, setFilter } = useBottlesContext()
  const [isVisible, setIsVisible] = useState(false)
  const isGhostSupplier = () => selectedSupplier.Id === null
  const hasRegisteredSupplier = () => suppliers.filter((supplier) => supplier.Data[0].SupplierId).length > 0
  const [nameOfSupplierToReplicate, setNameOfSupplierToReplicate] = useState('')

  useOutsideClick(wrapperRef, () => {
    setIsVisible(false)
  });

  const options = [
    {
      label: intl.get('bottles.delete'),
      visible: true
    },
    {
      label: intl.get('bottles.replicateSimulation'),
      isGroup: true,
      options: transformOptions(suppliers),
      visible: isGhostSupplier() && hasRegisteredSupplier()
    }
  ]

  const confirmDelete = async () => {
    try {
      const params = {
        routeName: isGhostSupplier() ? 'ghost-supplier' : 'supplier',
        routeParam: isGhostSupplier() ? selectedSupplier.Name : selectedSupplier.Id
      }

      await deletePlanning(params, getPeriod(filter))
      setFilter(prevState => ({ ...prevState }))
      setOpenModalDelete(false)

    } catch (error) {
      console.error(error)
    }

  }

  const confirmReplicate = async () => {
    setOpenModalReplicateSimulation(true)
    try {
      const period = getPeriod(filter)
      const params = {
        ghostSupplierName: selectedSupplier.Name,
        supplierId: suppliers.find(_ => _.Name === nameOfSupplierToReplicate).Data[0].SupplierId,
        startPeriod: period.startDate,
        endPeriod: period.endDate
      }

      await replicatePlanning(params)
      setOpenModalReplicateSimulation(false)
      setFilter(prevState => ({ ...prevState }))

    } catch (error) {
      console.log(error)
    }

  }

  const handleDropdownClick = (action) => {
    if (action === 'Deletar') {
      setOpenModalDelete(true)
    } else if (action !== undefined) {
      setNameOfSupplierToReplicate(action)
      setOpenModalReplicateSimulation(true)
    }
  }

  return (
    <>
      <S.DownWrapper
        onClick={() => setIsVisible(true)}
        >
        <S.Vector
          alt='Dropdown'
          data-testid="Dropdown"
          innerRef={wrapperRef}
          src={DownVector}
          isVisible={isVisible}
        />
        {isVisible &&
          <S.Dropdown style={{ marginTop: '30px' }}>
            {options.map(group =>
              group.visible &&
              <DropdownGroup
                key={group.label}
                handleClick={handleDropdownClick}
                group={group}
              />
            )}
          </S.Dropdown>
        }
      </S.DownWrapper>
      <ModalConfirm
        open={openModalDelete}
        confirm={confirmDelete}
        close={() => setOpenModalDelete(!openModalDelete)}
        title={intl.get(`bottles.modal.delete.title`)}
        text={getTextForDeleteModal(selectedSupplier)}
      />
      <ModalConfirm
        open={openModalReplicateSimulation}
        confirm={confirmReplicate}
        close={() => setOpenModalReplicateSimulation(!openModalReplicateSimulation)}
        title={intl.get(`bottles.modal.replicateSimulation.title`)}
        text={getTextForReplicateModal(selectedSupplier, nameOfSupplierToReplicate)}
        warning={getTextForAlert(selectedSupplier, nameOfSupplierToReplicate)}
      />
    </>
  )
}

export default BottlesGhostSupplierDropdown
import Styled from 'styled-components'
import { Row } from "react-flexbox-grid"
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({
  paper: { width: "900px"},
}));

export const Container = Styled.div`
    display: grid;
    grid-template-columns: 28fr 72fr;
    padding: 15px 0px 15px 0px;
    min-height: 50vh;

    .table-list-body{
        padding: 5px
    }
`

export const ContainerConfiguration = Styled.div`
    display:flex;
    flex-direction: column;
    border-right: solid;
    border-width: 1px;
    border-color: #C5D0E1;
    width: 100%;
    padding-top: 5px;

    > label {
        margin-top:15px;
        width: 100%;

        &:first-child {
            margin-top:0px;
        }
    }
}
`

export const LabelContainer = Styled.div`
    border-bottom: solid;
    color: #bababa;
    border-bottom-width: 1px;
    padding-bottom: 20px;
    width: 90%;

    &:last-child{
        padding-top: 20px;
    }

    > label {
        color:  ${ props => props.selected ? '#2870b2' : '#BDBDBD' };
        font-weight: 600;
        cursor: pointer;
    }
`

export const LineConfigStyled = Styled.div`
    padding-bottom:10px;

    .Select-value{
        padding-right: 1px !important;
        padding-left: 10px !important;
        top: -5px !important;
    }

    .Select-input{
        height: 30px
    }
`

export const ContainerConfigPeriod = Styled.div`
    padding-left: 16px;
    width: 100%;

    .select-origem-label {
        margin-right: 0px;
    }

    .select-origem-row {
        margin-top: 0px;
    }
`

export const ConfigAutoPilot = Styled.div`
    padding: 0px 15px 0px 20px;
`

export const Footer = Styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: right;
    width: 100%;
    padding-right: 35px;

    > button {
        width: 200px;
        margin: 15px 15px 15px 0px;
    }
`

export const Header = Styled.div`
    display: flex;
`

export const Title = Styled.div`
    display:flex;
    padding: 7px 0px 7px 15px !important;

    > h1 {
        margin: 0;
        align-self: center;
        padding-left: 25px;
        font-size: 22px;
        font-weight: 500;
    }
`
export const Agrupador = Styled(Row)`
  margin: .5rem auto;
  border-bottom: solid;
  border-color: #bababa;
  color: #2870b2;
  border-bottom-width: 1px;
  padding-bottom: .3rem;
  font-size: 12px;
`

import { getDefaultLocale } from "react-datepicker"

export const compareDates = (shouldBeBiggerDate, shouldBeLesserDate) => {
  if (!shouldBeBiggerDate || !shouldBeLesserDate)
    return undefined

  const date1 = new Date(shouldBeBiggerDate).getTime()
  const date2 = new Date(shouldBeLesserDate).getTime()

  if (date1 > date2) {
    return 1
  }
  if (date1 === date2) {
    return 0
  }
  return -1
}

export const getDateFormat = () => {
  switch (getDefaultLocale()) {
    case "pt-BR":
    case "es-ES":
      return "dd/MM/yyyy";
    case "en-US":
    default:
      return "MM/dd/yyyy";
  }
}

export const getDatePlaceholder = () => {
  switch (getDefaultLocale()) {
    case "pt-BR":
    case "es-ES":
      return "dd/mm/yyyy";
    case "en-US":
    default:
      return "mm/dd/yyyy";
  }
}

export const getTodayDate = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
}

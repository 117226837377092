import PropTypes from 'prop-types'
import styled from 'styled-components'

import { LABEL_WIDTH, PADDING_LEFT } from './../constants'

export const StyledLabel = styled.div`
  width: ${p => LABEL_WIDTH - p.childLevel * PADDING_LEFT}px;

  font-size: 0.7rem;
  color: #9ba2ac;
  background: #FFF;
  padding: 10px 0;
  border-bottom: 1px dashed #c5d0e1;
  padding-left: 4px;
  box-shadow: inset 4px 0 #afcef3;
`;

StyledLabel.propTypes = {
  childLevel: PropTypes.number
}


import React from 'react'
import PropTypes from 'prop-types'
import intl from 'react-intl-universal'

import './aceite-readonly.css'
import { formatNumber } from 'utils/format'
import { formatDeliveryTime, showDeliveryTime } from 'utils/delivery-time'
import { TextSpan } from 'components/text-label'

const AceiteReadOnly = ({
  qtde,
  data,
  dataColeta,
  unidadeNegocio,
  unidadeNegocioAnterior,
  nomeFornecedor,
  nomeFornecedorAnterior,
  material,
  materialAnterior,
  horaEntrega,
  linhaDeProducao,
  portaWMS,
  comentarios
}) => (
  <div>
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.collect')}</span>
        <span>{dataColeta}</span>
      </div>
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.delivery')}</span>
        <span>{data}</span>
      </div>
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.qty')}</span>
        <TextSpan>{`${formatNumber(qtde)}`}</TextSpan>
      </div>
    </div>
    <div className="negotiation-read-offer">
      <div className="negotiation-read-column">
        <span>{intl.get('stocks.timeline.supplier')}</span>
        <span>{nomeFornecedor}</span>
      </div>
      <div className="negotiation-read-column">
        <span />
        <span />
      </div>
    </div>
    {
      showDeliveryTime(horaEntrega) &&
      <React.Fragment>
        <div className="negotiation-read-offer">
          <div className="negotiation-read-column">
            <span>{intl.get('commons.deliveryTime')}:</span>
            <span>{formatDeliveryTime(horaEntrega)}</span>
          </div>
          <div className="negotiation-read-column">
            <span />
            <span />
          </div>
        </div>

        <div className="negotiation-read-offer">
         {(linhaDeProducao && linhaDeProducao !== "NULL") &&
            <div className="negotiation-read-column">
              <span>{intl.get("orders.productionLine")}:</span>
              <span>{linhaDeProducao}</span>
            </div>
          }
          {(portaWMS && portaWMS !== "NULL") &&
            <div className="negotiation-read-column">
              <span>{intl.get("commons.door")}:</span>
              <span>{portaWMS}</span>
            </div>
          }
        </div>
        {(comentarios && comentarios !== "NULL") &&
          <div className="negotiation-read-offer">
            <div className="negotiation-read-column">
              <span>{intl.get('orders.comments')}:</span>
              <span>{comentarios}</span>
            </div>
          </div>
        }
      </React.Fragment>
    }

    {nomeFornecedorAnterior &&
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.previousSupplier')}</span>
          <span>{nomeFornecedorAnterior}</span>
        </div>
        <div className="negotiation-read-column">
          <span />
          <span />
        </div>
      </div>
    }
    {unidadeNegocio &&
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.destination')}</span>
          <span>{unidadeNegocio}</span>
        </div>
        <div className="negotiation-read-column">
          <span />
          <span />
        </div>
      </div>
    }
    {unidadeNegocioAnterior &&
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.previousDestination')}</span>
          <span>{unidadeNegocioAnterior}</span>
        </div>
        <div className="negotiation-read-column">
          <span />
          <span />
        </div>
      </div>
    }
    {material &&
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.material')}</span>
          <span>{material}</span>
        </div>
        <div className="negotiation-read-column">
          <span />
          <span />
        </div>
      </div>
    }
    {materialAnterior &&
      <div className="negotiation-read-offer">
        <div className="negotiation-read-column">
          <span>{intl.get('stocks.timeline.previousMaterial')}</span>
          <span>{materialAnterior}</span>
        </div>
        <div className="negotiation-read-column">
          <span />
          <span />
        </div>
      </div>
    }
  </div>
);

AceiteReadOnly.propTypes = {
  qtde: PropTypes.number.isRequired,
  data: PropTypes.string.isRequired,
  dataColeta: PropTypes.string.isRequired,
  unidadeMedida: PropTypes.string
}

export default AceiteReadOnly

import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import {
  CheckIcon,
  UnCheckIcon,
  GeneralIcon,
  SiloIcon
} from 'components/icons/icn-index.icon'
import intl from 'react-intl-universal'
import StyledSiloManagementList from './StyledSiloManagementList'
import { formatNumber } from 'utils/format'

const SiloManagementList = ({ data, handleOpenForm, handleRedirecionarGeral, idUnidadeNegocio }) => (
  <StyledSiloManagementList>
    <Row className="rowListHeader">
      <Col xs={2} md={2} lg={2} className="align-center">{intl.get('master-data.general-configuration.silo')}</Col>
      <Col xs={3} md={3} lg={3} className="align-center">{intl.get('master-data.general-configuration.material')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('master-data.general-configuration.capacity')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('master-data.general-configuration.stock')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('master-data.general-configuration.ocupation')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('master-data.general-configuration.replenishment%')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">STATUS</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('master-data.general-configuration.situation')}</Col>
      <Col xs={1} md={1} lg={1} className="align-center">{intl.get('master-data.general-configuration.general')}</Col>
    </Row>
    <Row className="containerListaGradeDescarga">
      <Col xs={12} md={12} lg={12} className="pt-0 pb-0">
        {
          (!data || data.length === 0) &&
          <Row className="rowList">
            <Col xs={12} md={12} lg={12} className="align-center">
              <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
            </Col>
          </Row>
        }
        {
          data && !!data.length && data.map((item, i) => (
            <Row key={i} className="rowList" onClick={() => handleOpenForm(item.Id, true)}>
              <Col xs={2} md={2} lg={2} className="align-center">{item.Silo}</Col>
              <Col xs={3} md={3} lg={3} className="align-center">{item.Material}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">{formatNumber(item.Capacidade)}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">{formatNumber(item.Estoque)}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">{item.Ocupacao ? `${formatNumber(item.Ocupacao)}%` : ''}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">{item.PontoReabastecimento ? `${formatNumber(item.PontoReabastecimento)}%` : ''}</Col>
              <Col xs={1} md={1} lg={1} className="align-center">
                {
                  <div title={item.Status == 1 ? intl.get('master-data.managementUnity.grid.silos.actions.activeSilo') : intl.get('master-data.silos.actions.disabledSilo')}>
                    {item.Status == 1 ? <CheckIcon /> : <UnCheckIcon />}
                  </div>
                }
              </Col>

              <Col xs={1} md={1} lg={1} className="align-center">
                {
                  item.Situacao == 1 ?
                    <div title={intl.get('master-data.managementUnity.grid.silos.actions.sit1')} >
                      <SiloIcon color="#b3b300" />
                    </div>
                    : item.Situacao == 2 ?
                      <div title={intl.get('master-data.managementUnity.grid.silos.actions.sit2')}>
                        <SiloIcon color="#2d862d" />
                      </div>
                      : <div title={intl.get('master-data.managementUnity.grid.silos.actions.disabledSilo')} >
                        <SiloIcon color="#E21B1B" />
                      </div>
                }
              </Col>

              <Col xs={1} md={1} lg={1}
                className="align-center"
                onClick={e => {
                  e.stopPropagation()
                  handleRedirecionarGeral(item.IdFamiliaRotulada, idUnidadeNegocio)
                }
                }
              >
                {
                  <div title={item.IdFamiliaRotulada ? intl.get('master-data.managementUnity.grid.silos.actions.goTo') : intl.get('master-data.managementUnity.grid.silos.actions.message')}>
                    {<GeneralIcon
                      color={item.IdFamiliaRotulada ? '#2870B2' : '#95A3B7'}
                    />}
                  </div>
                }
              </Col>

            </Row>
          ))
        }
      </Col>
    </Row>
  </StyledSiloManagementList>
)

SiloManagementList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Silo: PropTypes.string.isRequired,
    Capacidade: PropTypes.number.isRequired,
    PontoReabastecimento: PropTypes.number.isRequired,
    Situacao: PropTypes.number.isRequired,
    Status: PropTypes.number.isRequired,
    Material: PropTypes.string.isRequired,
    IdFamiliaRotulada: PropTypes.number,
  })).isRequired,
  idUnidadeNegocio: PropTypes.number.isRequired,
  handleOpenForm: PropTypes.func.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  handleRedirecionarGeral: PropTypes.func.isRequired,
}

export default SiloManagementList

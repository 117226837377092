import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import * as S from '../../styled';

export const NavbarMenuItem = ({ label, redirectPath, baseUrl, dataTestId }) => (
  <S.ContainerMenu isCurrentPage={redirectPath?.split('/')[1] === baseUrl}>
    <Link
      to={`${redirectPath}`}
      style={S.styleLink}
    >
      <Button style={S.style} data-testid={dataTestId}>
        {label}
      </Button>
    </Link>
  </S.ContainerMenu>
)

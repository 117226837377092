import React from 'react'
import intl from 'react-intl-universal'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Checkbox } from '@hbsis.uikit/react'
import { LABEL_WIDTH } from './constants'

const StyledTableLabel = styled.div`
  display: flex;
  box-sizing: border-box;

  .etb-label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    min-width: ${LABEL_WIDTH}px;
    background-color: #477dc0;
    border-right: 1px solid #fff;
  }

  .etb-values {
    width: 100%;
    display: flex;

    .etb-column {
      color: #fff;
      border-right: 1px solid #fff;
      border-bottom: 1px solid #dbdbdb;
      background-color: #477dc0;

      &:last-child {
        border-right: none;
      }
    }
  }

  .etb-column-optimization {
    text-transform: uppercase;
  }

  .components {
    min-width: 40px;
    max-width: 40px;
  }

  .check-style{
    margin-left: 6px;
  }
`;

const LABEL_NAMES = {
  Tipo : "Tipo",
  CodPortal : "Cód.Portal",
  CodSAP : "Cód.SAP",
  Origem : "Origem",
  Destino : "Destino",
  Coleta : "Coleta",
  Entrega : "Entrega",
  Material : "Material",
  Quantidade : "Quantidade",
  Pendente : "Pendente",
  Cancelado : "Cancelado",
  Situacao : "Situacao",
  Atualizacao : "Atualização"
}

export const labelName = (name) => {

  let title = '';

  switch(name) {
    case LABEL_NAMES.Tipo:
      title = intl.get('master-data.general-configuration.type')
      break;
    case LABEL_NAMES.CodPortal:
      title = intl.get('commons.codPortal')
      break;
    case LABEL_NAMES.CodSAP:
      title = intl.get('commons.codSAP')
      break;
    case LABEL_NAMES.Origem:
      title = intl.get('commons.source')
      break;
    case LABEL_NAMES.Destino:
      title = intl.get('commons.destiny')
      break;
    case LABEL_NAMES.Coleta:
      title = intl.get('commons.collect')
      break;
    case LABEL_NAMES.Entrega:
      title = intl.get('commons.delivery')
      break;
    case LABEL_NAMES.Material:
      title = intl.get('commons.material')
      break;
    case LABEL_NAMES.Quantidade:
      title = intl.get('commons.amount')
      break;
    case LABEL_NAMES.Pendente:
      title = intl.get('commons.pendent')
      break;
    case LABEL_NAMES.Cancelado:
      title = intl.get('commons.cancelled')
      break;
    case LABEL_NAMES.Situacao:
      title = intl.get('commons.situation')
      break;
    case LABEL_NAMES.Atualizacao:
      title = intl.get('commons.att')
      break;
  }
  return title;
}

const TableLabel = ({ labelsList, allChecked, onSelectAllItens, showCheckbox }) => (
  <StyledTableLabel className='etb-row-labels'>
    <div className='etb-values'>
      <div key={-1} className='etb-column-optimization components'>
        {showCheckbox() && (
          <div className="check-style">
            <Checkbox
              name="allCheckedOptimization"
              checked={allChecked}
              onChange={() => onSelectAllItens(!allChecked)}
            />
          </div>
        )}
      </div>
      {labelsList.map((label, index) => (
        <div key={index} className='etb-column-optimization-label' style={label.customLabelStyle}>
          {labelName(label.name)}
        </div>
      ))}
    </div>
  </StyledTableLabel>
);

TableLabel.propTypes = {
  labelsList: PropTypes.array,
  allChecked: PropTypes.bool.isRequired,
  onSelectAllItens: PropTypes.func.isRequired,
  showCheckbox: PropTypes.func.isRequired,
};

export default TableLabel ;

import styled from 'styled-components'

 const StyledIntegracoes = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    max-height: calc(100% - 92px);

    .integracoes-header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 100px;
        align-items: center;
        background-color: #f5f6fa ;
    }
`

export default StyledIntegracoes
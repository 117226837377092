import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { Button, Layout } from '@hbsis.uikit/react'
import intl from 'react-intl-universal'

import IconEmpty from 'images/iconEmpty.icon'

import StyledFilter from './StyledFilter'

class Filter extends Component {
  constructor() {
    super()

    this.state = {}
  }

  render() {
    const { children, showFilter } = this.props

    return (
      <Layout.Sidebar
        width='450px'
        visible={showFilter}
        triggerClose={this.props.triggerClose}
        background='#FFFFFF'
        offsetTop='96px'
        side='right'
        block
        float
        icon={<IconEmpty />}
      >
        <StyledFilter>
          <div className="filter-container">
            <div className="sideFilterFields">
              {children}
              <Row style={{ width: '100%', height: '102px' }}>
                {/*Esse é um espaço em branco por causa dos botões fixos no filtro*/}
              </Row>
            </div>
            <div className='filter-container'>
              <Row className="footerFilterContainer no-margin">
                <Col xs={12}>
                  <Row>
                    <Col xs={12} lg={12} md={12}>
                      <hr className="filter-divisor" />
                    </Col>
                  </Row>
                  <Row className="button-container-filter">
                    <Col xs={6} lg={6} md={6}>
                      <Button type="primary" onClick={this.props.handleCloseSearch} value={intl.get('filters.search')} disabled={this.props.disabledSearch} />
                    </Col>
                    <Col xs={6} lg={6} md={6}>
                      <Button type="secondary" onClick={this.props.handleCleanClick} value={intl.get('filters.cleanSearch')} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </StyledFilter>
      </Layout.Sidebar>
    )
  }
}

Filter.propTypes = {
  showFilter: PropTypes.bool,
  transitionTime: PropTypes.number,
  handleClose: PropTypes.func,
  triggerClose: PropTypes.func,
  handleCloseSearch: PropTypes.func,
  disabledSearch: PropTypes.bool,
}

Filter.defaultProps = {
  showFilter: false,
  transitionTime: 500,
  disabledSearch: false
}

export default Filter;

export const FiltersFamiliaRotuladaCentroClean = () => {
  return {
    IdCarteira: null,
    IdFamilia: null,
    IdFamiliaRotulada: null,
    IdCentro: null,
    IdCoordenacao: null,
  }
}

import { formatOnlyNumber } from "utils/format"

export const isSemanalByDays = (dias) => {
  let contadorDiasFaltantes = 0
  if (dias) {
    dias.forEach(d => {
      if (d === false) {
        contadorDiasFaltantes += 1
      }
    })
  }
  return contadorDiasFaltantes === 0 ? false : true
}

export const normalizeDaysOfWeek = (semanal, diasCorte) => {
  let diasProgramacaoCorte = [true, true, true, true, true, true, true]
  if (semanal) {
    diasProgramacaoCorte = []
    const totalDias = 7
    const ids = diasCorte.map(d => d ? d.Id : '')

    for (let dia = 0; dia < totalDias; dia++) {
      if (ids.includes(dia)) {
        diasProgramacaoCorte.push(true)
      } else {
        diasProgramacaoCorte.push(false)
      }
    }
  }

  return diasProgramacaoCorte
}

export const isValidDate = (data) => {
  let isValid = true;
  data.forEach(x => {
    if (x.StartHour > x.FinishHour || (x.StartHour === x.FinishHour && x.StartMinute > x.FinishMinute)) {
      isValid = false
    }
  });
  return isValid
}

export const isValidDaysOfWeekEnabled = (dias) => {
  return dias.find((dia) => dia === true)
}

export const validaAtivarSelectBoxSemanal = (isSemanal, isChecked) => {
  return !isSemanal || !isChecked
}

export const validarParaSalvar = (normalizeScheduledDays, DaysOfWeekEnable) => {
  const validacaoInicial = isValidDate(normalizeScheduledDays)
  const validacaoSecundaria = isValidDaysOfWeekEnabled(DaysOfWeekEnable)

  const deveSalvar = validacaoInicial && validacaoSecundaria
  const mensagemErroInicial = !validacaoInicial ? 'master-data.general.auto-pilot.errorDate' : ''
  const mensagemErroSecundaria = !validacaoSecundaria ? 'feedbacks.messageAutoPilotNoOverDays' : ''

  return {
    deveSalvar,
    mensagemErro: mensagemErroInicial || mensagemErroSecundaria
  }
}

export const reverseDataOfWeekDays = (dias, opcoes) => {
  return opcoes.map((opcao, posicao) => dias[posicao] ? {...opcao, isSelected: dias[posicao]} : null)
}

export const retornaRecorrenciaCorteValor = (semanal) => {
  return semanal ? 1 : 0
}

export const retornaSemanalParaSelectBox = (Id) => {
  return Id === 0 ? false : true
}

export const validaValorPorCampo = (field, value) => {
  if (field === "value") {
    value = formatOnlyNumber(value)
  }
  return value
}

export const validaValorParaAutoPilot = (value, statusAnterior) => {
  return value === false ? value : statusAnterior.isChecked
}

export const retornaValorOpcaoRecorrencia = (isChecked) => {
  return isChecked ? 1 : 0
}

export const retornaValorCortePedido = (cortePedido) => {
  return cortePedido.isChecked ? cortePedido.value : 0
}

export const retornaNumberDaysAdvanceForSuggestionGeneration = (numberDays) => {
  return numberDays ? numberDays : 0
}

import React, { Component } from 'react'
import Message from 'components/message'
import SessionUser from 'utils/user-storage'
import { Row, Col } from "react-flexbox-grid"
import intl from 'react-intl-universal'

import { Button, Textarea } from "@hbsis.uikit/react"

import {
  aceitarRenegociacaoPedidoAtenderDemanda,
  recusarRenegociacaoPedidoAtenderDemanda
} from './renegociacao-pedido-atender-demanda.service'

import RenegociacaoPedidoAtenderDemandaStyled from './renegociacao-pedido-atender-demanda.styled';
import { formatErrorMessage } from 'utils/handle-error';

class RenegociacaoPedidoAtenderDemanda extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showMsg: false,
      textMsg: '',
      titleMsg: '',
      observacao: '',
      isReload: true
    }
  }

  componentDidMount() {
    this.setState({ observacao: '' })
  }

  changeValue = (event) => {
    const prop = event.target.name
    let value = event.target.value

    this.setState({
      [prop]: value
    })
  }

  aceitarRenegociacaoPedidoAtenderDemanda = async () => {

    try {
      let model = {
        IdPedido: this.props.idPedido,
        IdOrigem: this.props.idOrigem,
        IdDestino: this.props.idDestino,
        IdMaterial: this.props.idMaterial,
        DataColeta: this.props.dataColeta,
        DataEntrega: this.props.dataEntrega,
        HoraEntrega: this.props.horaEntrega,
        Quantidade: this.props.quantidade,
        Observacao: !!this.state.observacao ? this.state.observacao : this.props.observacao,
        IdUsuario:  SessionUser.get().Id
      }

      await aceitarRenegociacaoPedidoAtenderDemanda(model);

      this.showMessageAceiteRenegociacao();
    } catch (error) {
      this.proccessError(error);
    }
  }

  recusarRenegociacaoPedidoAtenderDemanda = async () => {

    try {
        const model = {
          IdPedido: this.props.idPedido,
          IdUsuario: SessionUser.get().Id,
          DataColeta: this.props.dataColeta,
          DataEntrega: this.props.dataEntrega,
          HoraEntrega: this.props.horaEntrega,
          Observacao: this.state.observacao,
          Quantidade: this.props.quantidade
        }

      await recusarRenegociacaoPedidoAtenderDemanda(model);
      this.showMessageRecusaRenegociacao();
    } catch (error) {
      this.proccessError(error);
    }
  }

  showMessageAceiteRenegociacao = () => {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.renegotiationOrder'),
      textMsg: intl.get('stocks.timeline.feedback.renegotiationAccept'),
      isReload: true
    })
  }

  showMessageRecusaRenegociacao (data) {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.renegotiationOrder'),
      textMsg: intl.get('stocks.timeline.feedback.renegotiationRefuse'),
      isReload: true
    })
  }

  proccessError = (exception) => {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
      textMsg: (exception.response != null && exception.response.status === 400) ? formatErrorMessage(exception) : intl.get('stocks.timeline.feedback.serverError'),
      isReload: false
    })
  }

  handleClose = () => {
    this.setState({ showMsg: false })

    if(this.state.isReload) {
      window.location.reload()
    }
  }

  render () {

    let { observacao } = this.state;

    return (
      <RenegociacaoPedidoAtenderDemandaStyled>
        <div style={{marginLeft:'50px'}}>
         <Row>
            <Col xl={12}>
              <Textarea
                required
                name="observacao"
                label={intl.get('stocks.commons.observation')}
                value={observacao|| ''}
                onChange={this.changeValue}
                style={{ width: '400px' }}
                className="txtarea-observacao"
              />
            </Col>
          </Row>
        </div>
        <div className='renegociacao-pedido-line-buttons'>
          <Button
            value={intl.get('stocks.timeline.refuse')}
            onClick={this.recusarRenegociacaoPedidoAtenderDemanda}
            type="danger"
            className="button btn-recusar"
          />
          <Button
            value={intl.get('stocks.timeline.accept')}
            onClick={this.aceitarRenegociacaoPedidoAtenderDemanda}
            type="primary"
            className="button btn-aceitar"
          />
        </div>
        <Message
          show={this.state.showMsg}
          text={this.state.textMsg}
          title={this.state.titleMsg}
          handleClose={this.handleClose} />
     </RenegociacaoPedidoAtenderDemandaStyled>
    )
  }
}

export default RenegociacaoPedidoAtenderDemanda

import styled from 'styled-components'
import Dialog from 'material-ui/Dialog'

export const CancelDialogWrapper = styled(Dialog)`
  h3 {
    color: #DE4246 !important;
    font-weight: 700 !important;
    text-transform: uppercase;
  }

  .container-message {
    color: #3B495E;
    font-weight: 600;
    font-size: 14px;
  }

`

import styled from 'styled-components'

export const Wrapper = styled.div`
  text-transform: uppercase;
  color: rgb(155, 162, 172);
  font-size: 0.7rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  box-shadow: rgb(175 206 243) 4px 0px inset;
  height: 40px;
  border-top: 1px solid rgb(197, 208, 225);
  border-bottom: 1px solid rgb(197, 208, 225);
  border-right: 1px solid rgb(197, 208, 225);
  background-color: rgb(250, 250, 252);
  padding-left: 10px;

  > div {
    margin: 0px 15px 0px 5px;
  }

  h5{
    font-size: 0.775rem;
  }

  .btn-action{
    width: 30px;
    height: 30px;
  }

  .btn-wrapper{
    display: flex;
    align-items: center;

    span{
      cursor: pointer;
    }

    img{
      width: 18px !important;
    }
  }

  .btn-generate{
    width: 150px;
    height: 30px;
  }

`

export const Info = styled.div`
  display: flex;
  align-items: center;

  > span {
    margin-left: 2px;
  }
`

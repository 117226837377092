import Fetch from 'utils/fetch'

export const searchFamily = (useFilterDefault) => {
  return Fetch.get(`/families/for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchLabeledFamily = (useFilterDefault) => {
  return Fetch.get(`/labeled-families/for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchMaterialGroup = (useFilterDefault) => {
  return Fetch.get(`/materials-group/for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchBusinessUnit = (useFilterDefault) => {
  return Fetch.get(`/business-units/for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchSuppliers = (useFilterDefault) => {
  return Fetch.get(`/suppliers/for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchCoordinations = (useFilterDefault) => {
  return Fetch.get(`/coordinations/for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}

export const searchMaterials = (useFilterDefault) => {
  return Fetch.get(`/materials/for-filters?UseFilterDefault=${useFilterDefault}`)
    .then(({ data }) => data)
}
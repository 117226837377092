import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from "@hbsis.uikit/react"

import Fetch from 'utils/fetch'
import SessionUser from 'utils/user-storage'
import intl from 'react-intl-universal'

import './pedidos.css'
import { Switcher } from 'components/toggle'

const PedidoForm = ({ handleShowFeedback, handleClose }) => {
  const [config, setUserConfig] = useState({})
  const [automaticAcceptance, setAutomaticAcceptance] = useState(false)

  const obterDadosConfiguracoes = async () => {
    const currentUser = SessionUser.get().Id
    const { data } = await Fetch.get(`/usuario/consultarConfiguracoes/${currentUser}`)
    
    setUserConfig(data)
    
    if (data?.UsaAceiteAutomaticoPedido) {
      setAutomaticAcceptance(data.UsaAceiteAutomaticoPedido)
    }
  }

  useEffect(() => {
    obterDadosConfiguracoes()
  }, [])


  const save = () => {
    const newConfig = {
      ...config,
      UsaAceiteAutomaticoPedido: automaticAcceptance
    }
    Fetch.post(`/usuario/salvarConfiguracoes`, newConfig)
      .then(() => {
        handleShowFeedback('Configração alterada com sucesso!')
        handleClose()
      })
  }


  return (
    <div>
      <Switcher
        name="aceiteAutomatico"
        label={intl.get('commons.accOrder')}
        value={automaticAcceptance}
        onChange={() => setAutomaticAcceptance(!automaticAcceptance)}
      />
      <div className='line-buttons-aceite'>
        <Button
          type="default"
          value={intl.get('geral.buttonsDefault.cancel')}
          onClick={handleClose}
          className="button"
        />
        <Button
          type="primary"
          value={intl.get('geral.buttonsDefault.save')}
          className="button"
          onClick={save}
        />
      </div>
    </div>
  )
}

PedidoForm.propTypes = {
  handleShowFeedback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default PedidoForm

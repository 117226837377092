import React from 'react'
import intl from 'react-intl-universal'
import Dialog from 'material-ui/Dialog';
import { Button } from "components/uikit-adapter/index"
import * as S from './styled'

const ModalFeedback = ({ title, message, open, handleClose }) => (
  <Dialog
    open={open}
    onRequestClose={handleClose}
    contentStyle={{ width: '400px' }}
    bodyStyle={{ padding: '0px' }}
  >
    <S.Wrapper>
      <S.Title>{title}</S.Title>
      <S.Message>{message}</S.Message>
      <S.Actions>
        <Button
          value={intl.get('geral.buttonsDefault.ok')}
          type='default'
          onClick={handleClose}
          className="button"
        />
      </S.Actions>
    </S.Wrapper>
  </Dialog>
)

export default ModalFeedback;

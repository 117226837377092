import React from 'react'
import intl from 'react-intl-universal'
import ButtonOrderAccept from 'components/button-accept-order'
import { useOrderVolumeContext } from '../../context'
import { accept } from '../../services/order-volume.service'

const OrderVolumeAccept = ({ refresh, checkbox }) => {
  const { setCheckbox, setLoading } = useOrderVolumeContext()

  const acceptOrder = async () => {
    setLoading(true)
    try {
      await accept(checkbox)
      setCheckbox([])
      refresh()
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  return (
    <ButtonOrderAccept
      title={intl.get("requestOrders.acceptOrders")}
      checkbox={checkbox.length === 0}
      accept={acceptOrder}
    />
  )
}

export default OrderVolumeAccept

import styled from "styled-components";
import ontimeImg from '../../images/login/OnTime.png';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background-color: #FFFFFF;
`

export const Banner = styled.div`
    width: 60%;
    height: 100%;
    background-image: url(${ontimeImg});
    background-size: cover;
    display: grid;

    #ontime-logo {
        margin-left: 1%;
    }

    #slogan{
        margin:auto;
        justify-self: center;
        padding-top:15%;
    }

    #ambevHbsis-logo{
        margin-top:auto;
        width:22%;
        justify-self: center;
        padding-bottom: 1%;
    }
`

export const Content = styled.div`
    display: flex;
    width: 40%;
    height: 100%;
    align-items: center;
    justify-content: center;
`

export const Card = styled.div`
    width: 80%;
    text-align: center;
    @media(min-width: 1500px){
        width:465px;
    }
`

export const Button = styled.button`
    margin-top: 39px;
    width: 100%;
    height: 45px;
    border: none;
    background: transparent linear-gradient(90deg, #2870B2 0%, #6197C6 100%) 0% 0%;
    box-shadow: 0px 6px 8px #2870B266;
    cursor: pointer;
    :hover{
        border:none;
        opacity: 95%;
    }
    :focus {
        outline: 0;
    }
`

export const TextButton = styled.p`
    margin:0;
    color: #FFFFFF;
    font-size: 16px;
    font-family: ProximaNova;
`

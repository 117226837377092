import React, { Component } from 'react'
import Fetch from 'utils/fetch'
import Message from 'components/message'
import SessionUser from 'utils/user-storage'

import { Button } from '@hbsis.uikit/react'
import intl from 'react-intl-universal'

import './aceite-contraproposta-emergencial.css'
import { formatErrorMessage } from 'utils/handle-error';

class AceiteContraPropostaEmergencial extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showMsg: false,
      textMsg: '',
      titleMsg: '',
      handleClose: () => { this.setState({ showMsg: false }) }
    }

    this.recusarContrapropostaEmergencial = this.recusarContrapropostaEmergencial.bind(this)
    this.aceitarContrapropostaEmergencial = this.aceitarContrapropostaEmergencial.bind(this)
    this.proccessErro = this.proccessErro.bind(this)
    this.proccessDataAceite = this.proccessDataAceite.bind(this)
    this.proccessDataRecusada = this.proccessDataRecusada.bind(this)
  }

  recusarContrapropostaEmergencial () {
    const user = SessionUser.get()

    let model = {
      IdPedido: this.props.idPedido,
      IdUsuario: user.Id
    }

    Fetch.post('/timeline/recusar/emergencial', model)
            .then(() => this.proccessDataRecusada())
            .catch((e) => this.proccessErro(e))
  }

  aceitarContrapropostaEmergencial () {
    const user = SessionUser.get()

    const model = {
      IdPedido: this.props.idPedido,
      IdUsuario: user.Id
    }

    const endPoint = `/timeline/aceite/emergencial/${user.TipoUsuario === 1 ? 'comprador' : 'fornecedor'}`

    Fetch.post(endPoint, model)
            .then((response) => this.proccessDataAceite(response.data))
            .catch((e) => this.proccessErro(e))
  }

  proccessDataRecusada () {
    this.setState({
      showMsg: true,
      titleMsg: 'Info',
      textMsg: intl.get('stocks.timeline.feedback.counterproposalRefused'),
      handleClose: () => {
        this.setState({ showMsg: false })
        window.location.reload()
      }
    })
  }

  proccessDataAceite (data) {
    this.setState({
      showMsg: true,
      titleMsg: 'Info',
      textMsg: intl.get('stocks.timeline.feedback.counterproposalAccept'),
      handleClose: () => {
        this.setState({ showMsg: false })
        window.location.reload()
      }
    })
  }

  proccessErro (exception) {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
      textMsg: (exception.response.status === 400) ? formatErrorMessage(exception) : intl.get('stocks.timeline.feedback.serverError'),
      handleClose: () => { this.setState({ showMsg: false }) }
    })
  }

  render () {
    return (
      <div>
        <div className='event-action'>
          <Button
            value={intl.get('stocks.timeline.refuse')}
            onClick={this.recusarContrapropostaEmergencial}
            type="danger"
            className="button"
          />
          <Button
            value={intl.get('stocks.timeline.accept')}
            onClick={this.aceitarContrapropostaEmergencial}
            type="primary"
            className="button"
          />
        </div>
        <Message
          show={this.state.showMsg}
          text={this.state.textMsg}
          title={this.state.titleMsg}
          handleClose={this.state.handleClose} />
      </div>
    )
  }
}

export default AceiteContraPropostaEmergencial

import intl from 'react-intl-universal';

export const getDoorsOptions = (doors) => {
  return doors?.length > 0 ? doors : [{ Name: 'N/A' }]
}

export const getDoorObjectByName = (doors, doorName) => {
  return doorName === 'N/A' ? { Name: 'N/A' } : doors?.find(d => d.Name === doorName);
}

export const isDoorsDisabled = (doors, doorName) => (!doors || doors?.length <= 0 || (doors?.length > 0 && doorName === 'N/A'));

export const getDoorName = (doors, doorName) => {
  const door = doors?.find(d => d.Name === doorName);

  if(!doors || doors?.length <= 0 || (doorName && !door)) return 'N/A';
  
  if(!doorName && !door) return intl.get('commons.undefined');

  return door.Name;
}

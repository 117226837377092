import React from 'react'
import CardDivisor from '../../../../../body-card/card-divisor'
import StatusCardGenerico from '../../../../../body-card/status-card-generico'
import * as Content from './content'
import intl from 'react-intl-universal'
import moment from 'moment'

import * as S from './styled'
import { TextSpan } from 'components/text-label'

const Card = ({ data: { Titulo, SubTitulo, TipoTarefa, Situacao, Acao, NomeFornecedor, DeliveryTime, DeliveryDate } }) => (
  <S.Wrapper className='card-click-area'>
    <S.Header className='header-card'>
      <StatusCardGenerico
        Titulo={Titulo}
        SubTitulo={SubTitulo}
        Icon={Content.montarIconeTarefa(TipoTarefa)}
        Status={Content.montarCorStatus(TipoTarefa)}
      />
    </S.Header>
    <CardDivisor />
    <S.Body>
      <S.Label className='card-label card-label-status'>
        <TextSpan>{intl.get('commons.situation')}</TextSpan>
        <TextSpan>{Situacao}</TextSpan>
      </S.Label>
      {
        DeliveryDate &&
        <S.Label className='card-label card-label-status'>
          <TextSpan>{intl.get('loadComposition.building.deliveryDateAndTime')}</TextSpan>
          <TextSpan>
            {moment(DeliveryDate).format("L")}
            {` ${intl.get('commons.at')} `}
            {moment(DeliveryTime, "HH:mm:ss").format("LT")}
          </TextSpan>
        </S.Label>
      }
    </S.Body>
    <div>
      <CardDivisor />
      <S.Body>
        <S.Label className='card-label'>
          <span>{intl.get('commons.action')}</span>
          <span>{Acao}</span>
        </S.Label>
      </S.Body>
    </div>
    {TipoTarefa === 13 &&
      <div>
        <CardDivisor />
        <S.Body>
          <S.Label className='card-label'>
            <span>{intl.get('commons.supplier')}</span>
            <span>{NomeFornecedor}</span>
          </S.Label>
        </S.Body>
      </div>
    }
  </S.Wrapper>
)
export default Card

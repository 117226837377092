import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

export const defaultBorderStyle = '1px solid #6197C6'
export const borderRadius = '4px'
export const margin = '4px'

export const styles = {
  firstElement: {
    borderLeft: defaultBorderStyle,
    borderTop: defaultBorderStyle,
    borderBottom: defaultBorderStyle,
    borderRadius
  },
  lastElement: {
    borderRight: defaultBorderStyle,
    borderTop: defaultBorderStyle,
    borderBottom: defaultBorderStyle,
    borderRadius,
  },
  emptyElement: {
    borderTop: defaultBorderStyle,
    borderBottom: defaultBorderStyle
  }
}

export const Vector = styled.img`
  width: 9px;
  height: 7px;
  rotate: ${props => props.collapsed ? "0deg" : "-90deg"};
`

export const Table = styled.div`
  display:flex;
`
export const TableBody = styled(ScrollContainer)`
  display:flex;
`
export const TableRow = styled.div`
  display: flex;
  flex-flow: column;

  div:first-child {
    background-color: #6197C6;
  }

  .collapsed-cell {
    padding-bottom: 5px;
  }

  .bold {
    font-weight: 600;
  }

`
export const TableCell = styled.div`
  width: 120px;
  line-height: 1.5;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  text-transform: capitalize;
  border-right: 1px dashed #C5D0E1;
  font-size: 14px;
  
  &:not(:first-child) {
    line-height: 2.3;
  }

  span {
    color: white;
    font-weight: 600;
  }

`

export const TableHeading = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: #6197C6;
  color: white;
  font-weight: bold;
  font-size: 14px;
  width: 120px;
  box-shadow:5px 0px 16px -11px rgb(0 0 0 / 65%);

  div:last-child {
    border-bottom: none;
  }

`
export const TableHeadingTitle = styled.div`
  width: 98px;
  line-height: 1.5;
  padding: 5px;
  margin-left: 6px;
  border-bottom: 1px solid white;
  display:flex;
  flex-flow: column;
  justify-content: center;
  transition: all .5s ease-out;
  
  &:first-child {
    line-height: 2.3;
  }

  .brewery-label {
    margin: 6px 0px;
    padding: 0px 0px 5px 0px;
    line-height: 2.3;
    font-size: 12px;
    font-weight: 400;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    height: 20px;
  }

  .dropdown {
    position: absolute;
    left: 77px;
    font-size: 13px;
    padding: 3px;
    cursor: pointer;

    span {
      margin-right: 3px;
    }

  }

  .BreweryMerchandiseEntry {
    top: 80px;
  }

  .OutgoingMerchandiseSupplier {
    top:110px;
  }

`

export const TableCellEditable = styled.input`
  background: #E7F0F7;
  border: 1px solid #C5D0E1;
  border-radius: 2px;
  text-align: center;
  width: 85px;
  margin: 6px auto;


  &:focus {
    border-color: #6197C6;
    outline: none;
  }
`

export const WeeklyPlanEditableCell = styled.input`
  background: #E7F0F7;
  border: 1px solid #C5D0E1;
  border-radius: 2px;
  text-align: center;
  width: 115px;
  height: 30px;

  &:focus {
    border-color: #6197C6;
    outline: none;
  }
`
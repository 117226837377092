import styled from 'styled-components'

const StyledTable = styled.div`

  .pagination-label {
    font-size: 11px;
    font-family: ProximaNova
  }

  .pagination-pages {
    font-size: 11px
  }

  .pagination-pages li {
    border: none;
    background: none;
  }

  .pagination-container {
    padding: 0 !important
  }

  h4 {
    text-align:center;
    font-weight: 500
    font-size: 13px;
  }

  .page-items {
    color: #1E5181;
    font-size: 11px;
  }

  .bCYTjq {
    overflow: visible !important;
  }

  .fwZIoa  {
    padding-left: 0 !important;
  }

  .content-button > img.icon {
    width: 16px !important;
    height: 16px !important;
}

`
export default StyledTable
import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router'
import HeaderComponent from '../load-composition/components/header'
import Sidebar from './components/sidebar'
import { useLoadCompositionBuildingContext } from './context/load-composition-building-context'
import { DragDropContext } from 'react-beautiful-dnd'
import * as S from './styled'
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from '../../utils/handle-error'
import Loading from 'components/center-loading'
import LoadComposition from '../load-composition/loadComposition'
import { validateMode, getDataEntrega, getQuantidade, initialStateModal, getCenterByParams } from './utils'
import ModalCreateSuggestions from './components/modal-create-suggestion'
import Main from './components/main'
import { useQuery } from 'hooks/useQuery'

const LoadCompositionBuilding = ({ history }) => {
  const [identificador, setIdentificador] = useState({ name: '', value: '' })
  const { showFeedback } = useGlobalContext()
  const [modalSuggestions, setModalSuggestions] = useState(initialStateModal())

  const {
    addSelectedLoads,
    selectedLoads,
    loads,
    removeSelectedLoads,
    isEditDateMode,
    setSelectedLoads,
    handleReloadDataCards,
    params,
    loadPage,
    isLoading,
    ordersMetadata,
    setOrdersMetadata,
    isEmergencyNegotiation
  } = useLoadCompositionBuildingContext();

  const proccessError = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  const Header = useMemo(() => <HeaderComponent />, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination || result.destination.droppableId === result.source.droppableId) {
      return;
    }
    else if (result.destination.droppableId === 'loads-suggestions-selecteds') {
      const idCard = loads[result.source.index].IdCard
      addSelectedLoads(idCard)
    }
    else if (result.destination.droppableId === 'loads-suggestions') {
      const idCard = selectedLoads[result.source.index].IdCard
      removeSelectedLoads(idCard)
    }
  }

  const handleModalCreateSuggestions = (data, _isEditMode, _isSelectedCard) => {
    setIdentificador(validateMode(_isEditMode, data))
    setModalSuggestions({
      isOpen: true,
      isEditMode: _isEditMode,
      isSelectedCard: _isSelectedCard
    });
  }

  const handleCloseModalSuggestions = () => setModalSuggestions(initialStateModal());

  const refreshCardSidebar = (model, prop) => {
    const _selectedLoads = [...selectedLoads];
    const loadIdx = selectedLoads.findIndex(x => x[prop] === model[prop]);
    _selectedLoads[loadIdx] = {
      ..._selectedLoads[loadIdx],
      DataEntrega: getDataEntrega(model),
      Quantidade: getQuantidade(model),
      TotalPallets: model.TotalPallets
    }
    setSelectedLoads([..._selectedLoads]);

    if (prop == 'IdPedido') {
      const orders = ordersMetadata.AlteracaoPedidos.filter(x => x.IdPedido !== model.IdPedido);
      setOrdersMetadata(prevData => ({ ...prevData, AlteracaoPedidos: [...orders, model] }));
    } else {
      const suggestions = ordersMetadata.AdicaoSugestao.filter(x => x.IdSugestao !== model.IdSugestao);
      setOrdersMetadata(prevData => ({ ...prevData, AdicaoSugestao: [...suggestions, model] }));
    }
  }

  useEffect(() => {
    loadPage();
  }, [])

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      {Header}
      <Loading isLoading={isLoading} />
      <S.Wrapper>
        {!isEditDateMode ?
          <Main
            openModal={handleModalCreateSuggestions}
            businessUnitId={params.destinoId}
          /> :
          <S.WrapperTable>
            <LoadComposition
              history={history}
              params={params}
              center={getCenterByParams(params)}
              isEditMode
            />
          </S.WrapperTable>
        }
        <Sidebar
          data={selectedLoads}
          history={history}
          openModal={handleModalCreateSuggestions}
          queryParams={useQuery()}
        />
        {
          modalSuggestions.isOpen &&
          <ModalCreateSuggestions
            open={modalSuggestions.isOpen}
            isEditMode={modalSuggestions.isEditMode}
            identificador={identificador}
            closeModal={handleCloseModalSuggestions}
            unidadeNegocio={params}
            proccessError={proccessError}
            refreshCardSidebar={refreshCardSidebar}
            isSelectedCard={modalSuggestions.isSelectedCard}
            refreshPage={() => handleReloadDataCards({})}
            removeSelectedLoads={removeSelectedLoads}
            isEmergencyNegotiation={isEmergencyNegotiation}
          />
        }
      </S.Wrapper>
    </DragDropContext>
  )
}

export default withRouter(LoadCompositionBuilding)

import React, { Component } from 'react'
import SupervisorIcon from 'material-ui/svg-icons/action/supervisor-account'
import SnackBar from 'material-ui/Snackbar'
import Fetch from 'utils/fetch'
import CoordinationList from './coordination-list'
import CoordinationForm from './coordination-form'
import DeleteCoordination from 'components/delete-dialog'
import HeaderIcon from 'components/header-icon'
import Loading from 'components/center-loading'
import Message from 'components/message'
import ButtonNewItem from 'components/button-new-item'
import intl from 'react-intl-universal'
import './coordination.css'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";

class Coordination extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      coordination: [],
      total: 0,
      openDelete: false,
      currentPage: 0,
      openForm: false,
      openEditMode: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 50,
      showMsg: false,
      textMsg: ''
    }
  }

  showMessage = (message) => {
    this.setState({
      showMsg: true,
      textMsg: message
    })
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (ex) => {
    this.closeDelete()
    this.showMessage((ex.response.status === 400) ? ex.response.data.Message : intl.get('stocks.timeline.feedback.serverError'))
  }

  searchCoordination = (page) => {
    this.startFetching()

    Fetch.get(`/coordenacao/consultaporpaginacao/${this.state.qtRegsPerPage}/${page}`)
      .then((response) => this.proccessData(response.data))
      .then(this.stopFetching)
      .then(() => this.setState({ currentPage: page }))
      .catch((e) => this.proccessErro(e))
  }

  proccessData = (data) => {
    this.setState({
      coordination: data.Data,
      total: data.TotalRecordCount
    })
  }

  refreshSearch = () => {
    this.closeForm()
    this.searchCoordination(this.state.currentPage)
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openEditForm = (row) => {
    const selected = this.state.coordination[row]

    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  deleteCoordination = () => {
    const idCoordination = this.state.idSelected

    Fetch.delete(`/coordenacao/${idCoordination}`)
      .then(this.closeDelete)
      .then(() => this.searchCoordination(this.state.currentPage))
      .then(() => this.showFeedback(intl.get('master-data.feedback.coordinationSuccessfullyDeleted')))
      .catch((e) => this.proccessErro(e))
  }

  render() {
    let {
      openForm,
      openEditMode,
      openDelete,
      idSelected,
      coordination,
      total,
      showFeedback,
      messageFeedBack,
      isFetching,
      showMsg,
      textMsg
    } = this.state

    return (
      <div className='coordination-container'>
        <Loading isLoading={isFetching} />

        <div className="coordination-header">
          <HeaderIcon
            title={intl.get('master-data.general.coordinationAndUsers.coordinations')}
            subtitle={intl.get('master-data.general.coordinationAndUsers.coordinationTitle')}
            icon={<SupervisorIcon style={{ width: 45, height: 45 }} color='#1d388f' />}
          >

            {profileAdminOrMasterData() &&
              <div title={intl.get('master-data.general.coordinationAndUsers.newCoordinationModal')}>
                <ButtonNewItem
                  className="user-button"
                  onClick={this.openNewForm}
                />
              </div>
            }
          </HeaderIcon>
        </div>

        <CoordinationList
          data={coordination}
          countData={total}
          refreshSearch={this.searchCoordination}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          qtRegsPerPage={this.state.qtRegsPerPage}
          profileAdminOrMasterData={profileAdminOrMasterData()}
        />

        {openForm && <CoordinationForm
          idEdit={idSelected}
          open={openForm}
          editMode={openEditMode}
          handleClose={this.closeForm}
          handleRefresh={this.refreshSearch}
          handleFeedback={this.showFeedback}
          profileAdminOrMasterData={profileAdminOrMasterData()}
        />}

        <DeleteCoordination
          open={openDelete}
          title={intl.get('master-data.general.coordinationAndUsers.deleteCoordinationModal')}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.general.materials.description')}
          handleConfirm={this.deleteCoordination}
        />

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

        <Message
          show={showMsg}
          text={textMsg}
          title='Erro!'
          handleClose={() => this.setState({ showMsg: false })}
        />
      </div>
    )
  }
}

export default Coordination

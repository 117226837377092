import Fetch from "utils/fetch";

export const searchWallets = () => {
    return Fetch.get(`/carteira/todas`).then(({ data }) => data)
}

export const searchFamilies = (value) => {
    return Fetch.get(`/familia/porCarteira/${value}`).then(({ data }) => data)
}

export const searchLabeledFamilies = (value) => {
    return Fetch.get(`/familiaRotulada/filterByFamily/${value}`).then(({ data }) => data)
}

export const searchCenters = (value) => {
    return Fetch.get(`/unidadeNegocio/obterTodasAsUnidadesPorFamilia/${value}`).then(({ data }) => data)
}

export const searchCoordination = () => {
    return Fetch.get(`/coordenacao`).then(({ data }) => data)
}

export const searchPeriodicidadeList = () => {
    return Fetch.get(`/familiaRotulada/periodicidades`).then(({ data }) => data)
}

export const searchTipoAlgoritmoList = () => {
    return Fetch.get(`/familiaRotuladaCentro/tiposAlgoritmo`).then(({ data }) => data)
}

export const searchPeriodicidade = (id) => {
    return Fetch.get(`/familiaRotulada/periodicidade/${id}`).then(({ data }) => data)
}

export const searchSaneamentoVolumesPendentes = (id) => {
    return Fetch.get(`/familiaRotulada/saneamentoVolumesPendentes/${id}`).then(({ data }) => data)
}

export const searchPilotoAutomatico = (id) => {
    return Fetch.get(`/familiaRotulada/pilotoAutomatico/${id}`).then(({ data }) => data)
}

export const searchRelevanciaOOR = (id) => {
    return Fetch.get(`/familiaRotulada/relevanciaOOR/${id}`).then(({ data }) => data)
}

export const searchAcionamentoTransporteCIF = (id) => {
    return Fetch.get(`/familiaRotulada/acionamentoTransporteCIF/${id}`).then(({ data }) => data)
}

export const buscarFamiliaRotuladaCentro = (id) => {
    return Fetch.get(`/familiaRotuladaCentro/${id}`).then(({ data }) => data)
}
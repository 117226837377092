export const buildEmergencyRenegotiateDto = (loadComposition, orders) => ({
  IdComposicaoCarga: loadComposition.IdComposicao,
  DataColetaProposta: loadComposition.DataColeta,
  DataEntregaProposta: loadComposition.DataEntrega,
  HoraEntregaProposta: loadComposition.HoraEntrega,
  Observacao: loadComposition.Observacao,
  PedidosMetaData: {
    AlteracaoPedidos: orders.filter(x => x.Id).map(x => ({
      Id: x.IdPedido,
      DataColeta: loadComposition.DataColeta,
      DataEntrega: loadComposition.DataEntrega,
      HoraEntrega: loadComposition.HoraEntrega,
      Quantidade: x.Quantidade
    }))
  }
});

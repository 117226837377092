import styled from 'styled-components'
  
const StyledImportExporter = styled.div`
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    padding-left: 7px;
    padding-right: 7px;

    .titulo {
      width: 89%;
      text-align: center;
      margin-bottom: 25px;
    }

    .file-ajust {
      width: 89%;
    }
  }

  .button-download {
    margin-top: 15px;
    width: 140px;
  }

  .button-footer {
    width: 80px;
  }

  .content-footer{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }

  .btn-next-prev {
    display: flex;
    justify-content: end;
  }

  .btn-next {
    margin-left: 10px;
  }

  .lista-resultados{
    width: 100%;
  }

  .lista-mensagens {
    max-height: 200px;
    overflow-y: auto;
  }

  .itens-mensagens {
    width: 100%;
  }

  .rowListHeader {
    color: #3b495e;
    font-weight: 600;
    padding: 5px 0 7px 0;
    border-bottom: 1px dashed #c5d0e1;
    padding-left: 4px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
  }

  .rowList {
    background: #FFFFFF;
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
    border-bottom: 1px dashed #c5d0e1;
    
    &:hover {
      background: #f4f5f8;
    }
  }

  .green {
    box-shadow: inset 4px 0 #81aa4c;

    &:hover {
      box-shadow: inset 4px 0 #81aa4c;
    }
  }

  .yellow {
    box-shadow: inset 4px 0 #e8b007;

    &:hover {
      box-shadow: inset 4px 0 #e8b007;
    }
  }

  .red {
    box-shadow: inset 4px 0 #ec393d;

    &:hover {
      box-shadow: inset 4px 0 #ec393d;
    }
  }
`

export default StyledImportExporter
import { createContext, useContext, useState } from "react";
import { initialFilterValues } from "./components/consolidated-stocks-filter/utils";

const ConsolidatedStocksContext = createContext();

const ConsolidatedStocksProvider = ({ children }) => {
  const [filter, setFilter] = useState(initialFilterValues);
  const [quantityRegisters, setQuantityRegisters] = useState(0);
  const [checkedItems, setCheckedItems] = useState([])
  const [checkAll, setCheckAll] = useState()

  return (
    <ConsolidatedStocksContext.Provider
      value={{
        filter,
        setFilter,
        quantityRegisters,
        setQuantityRegisters,
        checkedItems,
        setCheckedItems,
        checkAll,
        setCheckAll
      }}
    >
      {children}
    </ConsolidatedStocksContext.Provider>
  )
}

export const useConsolidatedStocksContext = () => useContext(ConsolidatedStocksContext);

export { ConsolidatedStocksProvider, ConsolidatedStocksContext };
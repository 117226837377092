import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import DeleteIcon from 'images/icn-trash-bin.svg'
import IconButton from 'material-ui/IconButton'
import ButtonsReposition from '../suppliers-buttons'
import StyledSuppliersListItem from './styledSuppliersListItem'
import intl from 'react-intl-universal'

class SuppliersListItem extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { item, index } = this.props
    const itemClass=item.IdFornecedor > 0 ?  "header-item": "header-new-item"

    return (
      <StyledSuppliersListItem>
        <Row className="itens-menu">
          <Col xs={10}>
            <div className={itemClass} onClick={()=> this.props.openForm(index)}>{item.Nome}</div>
          </Col>
          <Col xs={2} className="itens-menu-actions">
            {(item.IdFornecedor > 0) &&
            <IconButton
              onClick={() => (item.IdFornecedor > 0) ? this.props.deleteItem(index): null }
              title={intl.get('master-data.general.materials.supplier.title')}
            >
              <img src={DeleteIcon} />
            </IconButton>
            }
            {(item.IdFornecedor > 0) &&
            <ButtonsReposition
              toup={() => this.props.upItem(index)}
              todown={() => this.props.downItem(index)}
              toupDisabled={this.props.toupDisabled}
              todownDisabled={this.props.todownDisabled}
            />
            }
          </Col>
        </Row>
      </StyledSuppliersListItem>
    )
  }
}

SuppliersListItem.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number,
  upItem: PropTypes.func.isRequired,
  downItem: PropTypes.func.isRequired,
  openForm: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  toupDisabled: PropTypes.bool,
  todownDisabled: PropTypes.bool
}

export default SuppliersListItem

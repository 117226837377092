import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StylesInputFileLabel from './styledInputLabel'
import intl from 'react-intl-universal'

import ImageFile from 'images/icn-file-code.svg'

class InputFile extends Component {

  constructor(props) {
    super(props)

    this.state = {
      filename: '',
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.filename) {
      this.setState({
        filename: nextProps.filename,
      })
    }
  }

  render() {

    const {
      label,
      name,
      type,
      message,
      className,
    } = this.props

    return (
      <StylesInputFileLabel className={`InputFile ${type} ${className}`}>
        {label && <label htmlFor={name}>{label}</label>}
        <div className="elementContainer">
          <div className="filename">{this.props.filename ? this.props.filename : intl.get('master-data.general.stockPolicyModal.searchArchive')}</div>
          <div className="button-upload">
            <img src={ImageFile} height="24px" alt="Upload" />
            <span className="label-button">{intl.get('master-data.general.stockPolicyModal.select')}</span>
          </div>
          <input
            name={name}
            id={name}
            type="file"
            onChange={this.props.onChange}
            accept={this.props.accept}
          />
        </div>
        {type === 'error' && <span className="message error">{message}</span> ||
          (type === 'alert' && <span className="message alert">{message}</span>)}
      </StylesInputFileLabel>
    )
  }
}

InputFile.defaultProps = {
  filename: '',
  message: '',
  type: 'default',
  className: '',
}

InputFile.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  filename: PropTypes.string,
  label: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

export default InputFile

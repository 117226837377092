import React, { useState } from 'react'
import intl from 'react-intl-universal'
import './styled.css'
import NumeroSap from '../numero-sap'
import Loading from "components/center-loading";
import ActionsInCancellation from './actionsInCancellation'
import WaitingCheckRefusedButton from './waitingCheckRefusedButton'

const EmCancelamentoEvent = ({
  showActionsCancellation,
  refreshTimeLine,
  codigoPedidoSapItem,
  codigoPedidoSap,
  responseDeadline,
  idPedido,
  awaitingCheckRefused
}) => {
  const [loading, setLoading] = useState(false)
  const startLoading = () => setLoading(true)
  const stopLoading = () => setLoading(false)

  return (
    <div>
      <NumeroSap
        codigoPedidoSapItem={codigoPedidoSapItem}
        codigoPedidoSap={codigoPedidoSap}
      />
      <div className='ajuste-margin'>
        <div className='negotiation-read-offer'>
          <div className='negotiation-read-column'>
            <span>{intl.get('commons.responseDeadline')}:</span>
            <span>{responseDeadline}</span>
          </div>
        </div>
      </div>
      {
        showActionsCancellation &&
        <div className="dialog-danger-button-container">
          <ActionsInCancellation
            startLoading={startLoading}
            stopLoading={stopLoading}
            idPedido={idPedido}
            refresh={refreshTimeLine}
          />
        </div>
      }
      {
        awaitingCheckRefused &&
        <div className="dialog-danger-button-container">
          <WaitingCheckRefusedButton
            startLoading={startLoading}
            stopLoading={stopLoading}
            idPedido={idPedido}
            refresh={refreshTimeLine}
          />
        </div>
      }
      <Loading isLoading={loading} />
    </div>
  )
}

export default EmCancelamentoEvent;

import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { StyledDatePicker } from './styled';
import moment from 'moment';
import { getDateFormat, getDatePlaceholder } from '../../utils/date';

const toIsoDate = (date) => {
  if (date.includes("T")) {
    return new Date(date)
  } else {
    return new Date(date + "T00:00:00");
  }
};

const DateInput = ({ value, onChange, minDate, maxDate, label, canClear, disabled, id, onChangeInternalValue, lastInput }) => {
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    setCurrentDate(value ? toIsoDate(value) : null);
  }, [value]);

  const handleChangeInternalValue = (date) => {
    setCurrentDate(toIsoDate(moment(date).format("YYYY-MM-DD")));
  }

  const validateHandleDate = (date) => date ? moment(date).format("YYYY-MM-DD") : date

  return (
    <StyledDatePicker disabled={disabled} lastInput={lastInput}>
      <label htmlFor={label}>{label}</label>
      <div className="picker-container">
        <DatePicker
          id={label}
          className="date-picker"
          selected={currentDate}
          onChange={date => onChangeInternalValue ? handleChangeInternalValue(date) : onChange(validateHandleDate(date))}
          minDate={minDate ? toIsoDate(minDate) : null}
          maxDate={maxDate ? toIsoDate(maxDate) : null}
          isClearable={canClear}
          disabled={disabled}
          placeholderText={getDatePlaceholder()}
          dateFormat={getDateFormat()}
        />
      </div>
    </StyledDatePicker>
  );
};

DateInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  onChange: PropTypes.func,
  canClear: PropTypes.bool,
  disabled: PropTypes.bool
};

export default DateInput;

import React, { useState } from "react";
import intl from "react-intl-universal";
import { Dialog } from "@material-ui/core";
import * as T from "./styled";
import { useLoadCompositionBuildingContext } from "pages/load-composition-building/context/load-composition-building-context";

const ModalAddNotes = ({ notes, setNotes, showAddNoteModal, setShowAddNoteModal }) => {
  const { params, breweryNote, setBreweryNote } = useLoadCompositionBuildingContext();
  const [breweryNotesLength, setBreweryNotesLength] = useState(() => notes ? notes.length : 0);
  const maxLength = 300;

  const handleClose = () => setShowAddNoteModal(false);

  const handleChange = (value) => {
    setBreweryNotesLength(value.length);
    setNotes(value);
  };

  const handleCancel = () => {
    if (notes !== breweryNote) {
      setNotes(breweryNote);
      setBreweryNotesLength(breweryNote.length);
    }
    handleClose();
  };

  const handleAdd = () => {
    setBreweryNote(notes);
    handleClose();
  };

  return (
      <Dialog
        open={showAddNoteModal}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <T.TitleWrapper>
          <span>
            {intl.get("loadComposition.breweryNotes.notesModal.title")}{" "}
            {params.composicaoId && `${params.composicaoId}`}
          </span>
        </T.TitleWrapper>
        <T.ContentWrapper>
          <T.NotesTextarea
            label={intl.get("loadComposition.breweryNotes.notesModal.negotiationNotes")}
            value={notes}
            onChange={(ev) => handleChange(ev.target.value)}
            maxLength={maxLength}
            rows={8}
            data-testid="negotiation-notes"
          />
          <span>
            {breweryNotesLength}/{maxLength}
          </span>
          <T.ActionsWrapper>
            <T.ButtonCancel
              type="default"
              value={intl.get("loadComposition.breweryNotes.notesModal.cancel")}
              onClick={handleCancel}
            />
            <T.ButtonAdd
              type="default"
              value={intl.get("loadComposition.breweryNotes.notesModal.add")}
              className="tertiary"
              onClick={handleAdd}
            />
          </T.ActionsWrapper>
        </T.ContentWrapper>
      </Dialog>
  );
};

export default ModalAddNotes;

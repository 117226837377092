import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;

  ${p =>
    p.showCheckbox &&
    `
    position: relative;
    padding-left: 25px;
  `};
  
  .etb-btn {
    position: absolute;
    left: 2.5px;
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
    background-color: Transparent;
  }
  
  .etb-btn:active{
    border: none;
  }
  
  .etb-btn:focus {outline:0;}

  .etb-values {
    width: 100%;
    display: flex;

    .etb-column {
      color: #3b495e;
    }
  }
`;

StyledHeader.propTypes = {
  showCheckbox: PropTypes.bool,
}

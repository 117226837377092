/**
 * Retorna a linguagem atual do navegador
 * com base no objeto de navegação informado.
 *
 * @param {*} navigator Navigator
 */
const withLanguage = (navigator) => {
  if (!navigator.language){
    return 'pt-BR'
  }

  if (navigator.language === 'pt'){
    return 'pt-BR'
  }

  if (navigator.language === 'en' || navigator.language === 'en-US'){
    return 'en-US'
  }

  if (navigator.language === 'es' || navigator.language === 'es-ES'){
    return 'es-ES'
  }

  return 'pt-BR'
}

export default withLanguage

import React, { useState, useEffect } from 'react'
import Loading from 'components/center-loading'
import { SelectBox, Button } from 'components/uikit-adapter/index'
import DateRangerPicker from 'components/date-range-picker'
import intl from 'react-intl-universal'
import Session from 'utils/user-storage'
import * as S from './styled'
import {
  getBusinessUnitByLabeledFamily,
  getLabeledFamilyByBottle,
  getAllLabeledFamilies,
  getSuppliersByLabeledFamilyAndBusinessUnit
} from '../../data-upload.service'
import Feature from 'components/feature'

const FilterDataUpload = ({
  onClick,
  isBottles,
  useLabeledFamilies,
  useSuppliers,
  useBusinessUnit,
  useDateRange,
  limitLabeledFamilyOptions,
}) => {
  const [isLoading, setIsLoading] = useState(false); 

  const [options, setOptions] = useState({
    labeledFamily: [],
    center: [],
    supplier: []
  })

  const [data, setData] = useState({
    labeledFamily: [],
    center: [],
    supplier: []
  })

  const [period, setPeriod] = useState({
    dataDe: undefined,
    dataAte: undefined
  })

  const handleOptions = (option, name) =>
    setOptions(prevState => ({ ...prevState, [name]: option }))

  const handleData = (info, name) => {
    setData(prevState => ({ ...prevState, [name]: info }))
  }

  const handlePeriod = (prop, value) =>
    setPeriod(prevState => ({ ...prevState, [prop]: value }))

  useEffect(() => {
    if (data.labeledFamily.length === 0) {
      handleData([], 'supplier')
      handleData([], 'center')
    }

    const centers = async (labeledFamily) => {
      const response = await getBusinessUnitByLabeledFamily(labeledFamily)
      handleOptions(response, 'center')
    }

    if (data.labeledFamily.length > 0 || data.labeledFamily) {
      centers(data.labeledFamily)
    }
  }, [data.labeledFamily])


  useEffect(() => {
    handleData([], 'supplier')

    const suppliers = async (params) => {
      const response = await getSuppliersByLabeledFamilyAndBusinessUnit(params)
      handleOptions(response, 'supplier')
    }

    if (data.center.length > 0 || data.center) {
      const params = {
        userId: Session.get().Id,
        useFilterDefault: JSON.parse(localStorage.getItem('filterMyItemsOnly')),
        labeledFamily: data.labeledFamily,
        businessUnit: data.center
      }
      suppliers(params)
    }
  }, [data.center])

  const loadLabeledFamilies = async () => {
    const response = await (isBottles ? getLabeledFamilyByBottle() : getAllLabeledFamilies());
    handleOptions(response, 'labeledFamily')
  }
  useEffect(() => {
    loadLabeledFamilies()
  }, [])

  const handleClick = () => {
    const filters = {
      labeledFamily: data.labeledFamily.map(family => family.Id),
      center: data.center.map(center => center.Id),
      supplier: data.supplier.map(supplier => supplier.Id),
      ...period
    }
    setIsLoading(true)
    onClick(filters).finally(() => setIsLoading(false))
  }

  return (
    <S.Container>
      <Loading isLoading={isLoading} fullHeightParent />
      <Feature validation={useLabeledFamilies}>
        <SelectBox
          required
          name='FamiliaRotulada'
          label={intl.get('commons.labeledFamily')}
          placeholder={'-'}
          valueKey='Id'
          labelKey='Descricao'
          value={data.labeledFamily}
          options={options.labeledFamily}
          onChange={(family) => handleData(family, 'labeledFamily')}
          searchable
          openOnFocus
          clearable
          data-testid="labeledFamily"
          multi
          isOptionDisabled={() => limitLabeledFamilyOptions && data.labeledFamily?.length >= limitLabeledFamilyOptions}
        />
      </Feature>
      <Feature validation={useBusinessUnit}>
        <SelectBox
          required
          name='Center'
          label={intl.get('commons.center')}
          placeholder={'-'}
          valueKey='Id'
          labelKey='Description'
          value={data.center}
          options={options.center}
          onChange={(center) => handleData(center, 'center')}
          searchable
          clearable
          multi
          disabled={data.labeledFamily.length === 0}
          data-testid="center"
        />
      </Feature>
      <Feature validation={useSuppliers}>
        <SelectBox
          required
          name='Supplier'
          label={intl.get('commons.supplier')}
          placeholder={'-'}
          valueKey='Id'
          labelKey='Descricao'
          value={data.supplier}
          options={options.supplier}
          onChange={(supplier) => handleData(supplier, 'supplier')}
          searchable
          clearable
          multi
          disabled={data.center.length === 0}
          data-testid="supplier"
        />
      </Feature>
      <Feature validation={useDateRange}>
        <DateRangerPicker
          title={intl.get('commons.period')}
          dataDe={period.dataDe}
          dataAte={period.dataAte}
          handleChange={handlePeriod}
        />
      </Feature>
      <S.ButtonContainer>
        <Button
          width='120px'
          type='secondary'
          value={intl.get('exportImportModal.butttons.download')}
          onClick={handleClick}
        />
      </S.ButtonContainer>
    </S.Container>
  )
}

FilterDataUpload.defaultProps = {
  useLabeledFamilies: false,
  useSuppliers: false,
  useBusinessUnit: false,
  useDateRange: false,
}

export { FilterDataUpload };

import React from 'react'

import StyledTitle from './styledTitleHeader'

const TitleHeader = ({ children, className, color, width }) => {
  return (
    <StyledTitle className={className} color={color} width={width}>
      {children}
    </StyledTitle>
  )
}

export default TitleHeader

import * as FullStory from '@fullstory/browser';
import { getDataDogApplicationId, getDataDogClientToken, getFullHistoryId } from 'utils/config-environment';
import { datadogRum } from '@datadog/browser-rum'

export const loadMonitoringApplications = () => {
  loadFullStory();
  loadDataDogRum();
}

const loadFullStory = () => {
  if (getFullHistoryId()) {
    FullStory.init({ orgId: getFullHistoryId() });
  }
}

const loadDataDogRum = () => {
  if (!(getDataDogApplicationId() && getDataDogClientToken()))
    return;

  datadogRum.init({
    applicationId: getDataDogApplicationId(),
    clientToken: getDataDogClientToken(),
    site: 'datadoghq.com',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
    defaultPrivacyLevel: 'mask-user-input'
  });

  datadogRum.startSessionReplayRecording();
}
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Fetch from 'utils/fetch'
import Loading from 'components/center-loading'
import { Row, Col } from 'react-flexbox-grid'
import { Button } from '@hbsis.uikit/react'
import { SelectBox } from "components/uikit-adapter/index"

import intl from 'react-intl-universal'

import StyledFilterLabeledFamily from './StyledFilterLabeledFamily'
import { formatErrorMessage } from 'utils/handle-error';

class FiltroFamiliaRotulada extends Component {
  constructor(props) {
    super(props)

    this.state = {
      IdCarteira: 0,
      carteiras: [],
      IdFamilia: 0,
      familias: [],
      IdFamiliaRotulada: 0,
      familiasRotuladas: [],
      showFilter: props.showFilter,
      hidden: true,
      isFetching: false
    }

    this.searchWallets = this.searchWallets.bind(this)
    this.searchFamily = this.searchFamily.bind(this)
    this.searchLabeledFamily = this.searchLabeledFamily.bind(this)
    this.processWalletsData = this.processWalletsData.bind(this)
    this.processFamiliesData = this.processFamiliesData.bind(this)
    this.processLabeledFamiliesData = this.processLabeledFamiliesData.bind(this)
    this.changeValue = this.changeValue.bind(this)
    this.startFetching = this.startFetching.bind(this)
    this.stopFetching = this.stopFetching.bind(this)
    this.handleCloseSearch = this.handleCloseSearch.bind(this)
    this.handleCleanClick = this.handleCleanClick.bind(this)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const hidden = !nextProps.showFilter

    this.setState({
      showFilter: nextProps.showFilter,
      hidden,
    })

    if (this.state.initialData !== nextProps.initialData && nextProps.showFilter) {
      this.setState({
        initialData: nextProps.initialData,
        IdCarteira: nextProps.initialData.IdCarteira,
        IdFamilia: nextProps.initialData.IdFamilia,
        IdFamiliaRotulada: nextProps.initialData.IdFamiliaRotulada,
      }, () => {
        this.searchWallets()
        this.searchFamily()
        this.searchLabeledFamily()
      })
    }
  }

  startFetching() {
    this.setState({
      isFetching: true
    })
  }

  stopFetching() {
    this.setState({
      isFetching: false
    })
  }

  proccessErro(exception) {
    this.setState({
      showMsg: true,
      titleMsg: intl.get('stocks.timeline.feedback.errorFound'),
      textMsg: (exception.response && exception.response.status === 400)
        ? formatErrorMessage(exception)
        : intl.get('stocks.timeline.feedback.serverErrorOrder'),
      handleClose: () => {
        this.setState({ showMsg: false })
      }
    })
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value,
    }, () => {
      this.searchFamily()
      this.searchLabeledFamily()
      if (name === 'IdCarteira' && value != null) {
        this.setState({
          IdFamilia: 0,
          IdFamiliaRotulada: 0,
        });
      } else if (name === 'IdFamilia' && value != null) {
        this.setState({
          IdFamiliaRotulada: 0,
        });
      }
    })
  }

  handleCleanClick = () => {
    const params = {
      IdCarteira: 0,
      IdFamilia: 0,
      IdFamiliaRotulada: 0,
    }
    this.setState(params, () => this.props.handleClose(params))
  }

  searchWallets() {
    this.startFetching()
    Fetch.get(`/carteira/todas`)
      .then((response) => this.processWalletsData(response.data))
      .then(this.stopFetching())
      .catch((e) => this.proccessErro(e))
  }

  searchFamily() {
    this.startFetching()
    Fetch.get(`/familia/emFamiliaRotuladaParaFiltro`)
      .then((response) => this.processFamiliesData(response.data))
      .then(this.stopFetching())
      .catch((e) => this.proccessErro(e))
  }

  searchLabeledFamily() {
    this.startFetching()
    Fetch.get(`/familiaRotulada/getAllForFilter`)
      .then((response) => this.processLabeledFamiliesData(response.data))
      .then(this.stopFetching())
      .catch((e) => this.proccessErro(e))
  }

  processWalletsData(data) {
    this.setState({
      carteiras: data
    })
  }

  processFamiliesData(data) {
    this.setState({
      familias: data
    })
  }

  processLabeledFamiliesData(data) {
    this.setState({
      familiasRotuladas: data
    })
  }

  handleCloseSearch() {
    const params = {
      IdCarteira: this.state.IdCarteira,
      IdFamilia: this.state.IdFamilia,
      IdFamiliaRotulada: this.state.IdFamiliaRotulada,
    }
    this.props.handleClose(params)
  }

  render() {
    const { carteiras, familias, familiasRotuladas, isFetching } = this.state

    return (
      <StyledFilterLabeledFamily>
        <Loading isLoading={isFetching} />
        <div className="filter-container">
          <div className="sideFilterFields">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  className="carteiraSelectBox"
                  name='carteira'
                  label={intl.get('commons.wallet')}
                  value={this.state.IdCarteira}
                  options={carteiras}
                  valueKey='Id'
                  labelKey='Descricao'
                  onChange={value => this.changeValue('IdCarteira', value === null ? 0 : value.Id)}
                  placeholder={intl.get('commons.wallet')}
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  className="familiaSelectBox"
                  name='familia'
                  label={intl.get('commons.family')}
                  value={this.state.IdFamilia}
                  options={familias}
                  valueKey='Id'
                  labelKey='Nome'
                  onChange={value => this.changeValue('IdFamilia', value === null ? 0 : value.Id)}
                  placeholder={intl.get('commons.family')}
                  clearValueText='Limpar'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  className="familiaRotuladaSelectBox"
                  name='familiaRotulada'
                  label={intl.get('commons.labeledFamily')}
                  value={this.state.IdFamiliaRotulada}
                  options={familiasRotuladas}
                  valueKey='Id'
                  labelKey='Descricao'
                  onChange={value => this.changeValue('IdFamiliaRotulada', value === null ? 0 : value.Id)}
                  placeholder={intl.get('commons.labeledFamily')}
                  clearValueText='Limpar'
                  searchable
                  openOnFocus
                  clearable
                />
              </Col>
            </Row>
          </div>

          <Row className="footerFilterContainer no-margin">
            <Col xs={12}>
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <hr className="filter-divisor" />
                </Col>
              </Row>
              <Row>
                <Col xs={6} lg={6} md={6}>
                  <Button type="primary" onClick={this.handleCloseSearch} value={intl.get('filters.search')} />
                </Col>
                <Col xs={6} lg={6} md={6}>
                  <Button type="secondary" onClick={this.handleCleanClick} value={intl.get('filters.cleanSearch')} />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </StyledFilterLabeledFamily>
    )
  }
}

FiltroFamiliaRotulada.propTypes = {
  showFilter: PropTypes.bool,
  initialData: PropTypes.object,
  transitionTime: PropTypes.number,
  handleClose: PropTypes.func
}

FiltroFamiliaRotulada.defaultProps = {
  showFilter: false,
  transitionTime: 500,
  initialData: {}
}

export default FiltroFamiliaRotulada

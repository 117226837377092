import styled from "styled-components";

const StyledForm = styled.div`
  position: relative;

  .sideFilterFields {
    overflow-y: scroll;
    background: #ffffff;
    height: calc(100vh - 231px);
    padding: 15px 20px 0;
  }

  .footerFilterContainer {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background: #ffffff;
    padding: 15px 5px 15px 20px;

    .filter-divisor {
      background-color: rgba(197, 208, 225, 0.6);
      height: 1px;
      width: 100%;
      color: rgba(197, 208, 225, 0.6);
      border: none;
    }
  }

  .container__general-form {
    width: 98% !important;
    padding: 7px;
    margin-top: -16px;
    border: 1px solid #c5d0e1 !important;
  }

  .auto-complete {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .auto-complete-configuracoes {
    width: 100%;
    margin-top: 6px;
    margin-bottom: 6px;
  }

  ::-webkit-inner-spin-button {
    display: none;
  }

  .span-message {
    display: contents;
    align-content: left;
    font-size: 14px;
    float: right;
    color: #e8b007;
  }

  .span-message-container {
    margin-top: -8px;
  }

  .toogleVolumesPendentes {
    padding-top: 8px;
    padding-bottom: 10px;
  }

  .daily-wallet {
    display: flex;
    align-items: flex-end;
  }

  .bulk-pending {
    margin-bottom: 8px;
  }

  .input-legend {
    padding-top: 2px;
    color: #3b495e;
    font-size: 10px;
    display: flex;
    justify-content: end;

  }
`;
export default StyledForm;

import React, { useState } from 'react'
import Dialog from 'material-ui/Dialog';
import intl from 'react-intl-universal'
import HeaderForm from '../components/header-form';
import { Input, Button } from "components/uikit-adapter/index"
import { Row, Col } from 'react-flexbox-grid';
import Pedidos from './components/pedidos';
import icnClose from 'images/close-icone.svg'
import { generateGRN } from '../form/composicao-carga-form.service';
import { formatErrorMessage } from '../../../utils/handle-error';
import { useGlobalContext } from 'hooks/useGlobalContext';
import ModalFeedback from 'components/modal-feedback'
import Loading from "components/center-loading";
import { validate } from './utils';

export const DELIVERY_OPTION = {
  FullDelivery: 1,
  PartialDelivery: 2
}

const FormCreateGRN = ({
  modoEdicao,
  composicao,
  unidadesNegocio,
  changeSelected,
  changeSupplierSelected,
  fornecedores,
  modoLeitura,
  SituacaoValor,
  handleChangeDate,
  handleChangeObservacao,
  handleClose,
  open,
  pedidosFiltrados,
  renderTotal,
  handleCloseAll
}) => {
  const [note, setNote] = useState('');
  const [pedidos, setPedidos] = useState(pedidosFiltrados)
  const [isLoading, setIsLoading] = useState(false);
  const { showFeedback } = useGlobalContext()
  const [modalFeedback, setModalFeedback] = useState({
    isOpen: false,
    title: intl.get("composition.grn.grnCreated"),
    message: ''
  })

  const handleCloseModalFeedback = () => {
    setModalFeedback(prevState => ({
      ...prevState,
      isOpen: false
    }));
    handleCloseAll()
  }

  const handleChangePedido = (id, field, value) => {
    const _pedidos = [...pedidos];
    const _pedidoIdx = pedidos.findIndex(x => x.IdPedido === id);
    _pedidos[_pedidoIdx] = {
      ..._pedidos[_pedidoIdx],
      [field]: value
    }
    setPedidos([..._pedidos]);
  }

  const handleSaveGRN = async () => {
    const validated = validate(pedidos, note);
    if (!validated.isValid) {
      showFeedback(validated.message);
      return
    }
    setIsLoading(true);
    try {
      const _pedidos = pedidos.map(x => ({
        UnidadeMedida: x.UnidadeMedida,
        CodigoPedidoSap: x.IdPedidoSap,
        CodigoItemPedidoSap: x.IdItemPedidoSap,
        IdPedido: x.IdPedido,
        Quantidade: x.deliveryOption === DELIVERY_OPTION.PartialDelivery ? parseFloat(x.quantidadeGRN) : x.Quantidade,
      }))
      const dto = {
        LoadCompositionId: composicao.IdComposicao,
        Orders: _pedidos,
        DeliveryNotes: note
      }
      const _response = await generateGRN(dto);
      setModalFeedback(prevState => ({ ...prevState, isOpen: true, message: _response.Message }))
    }
    catch (err) {
      showFeedback(formatErrorMessage(err))
    }
    setIsLoading(false);
  }

  return (
    <React.Fragment>
      <Dialog
        title={`${intl.get("composition.grn.createGRNLoadComposition")} : ${composicao.IdComposicao}`}
        contentStyle={{ width: '98%', maxWidth: 'none', position: 'relative', height: '900px' }}
        open={open}
        onRequestClose={handleClose}
        actionsContainerClassName="container-action-buttons__composition"
        actions={
          <React.Fragment>
            <span
              className='btn-close-modal'
              onClick={handleCloseAll}
            >
              <img src={icnClose} />
            </span>
            {renderTotal(composicao.QuantidadeTotal)}
            <Button
              type="default"
              value={intl.get("geral.buttonsDefault.accept")}
              width={"166px"}
              onClick={handleSaveGRN}
              className="button__composicao-carga"
            />
            <Button
              type="danger"
              value={intl.get("composition.grn.cancel")}
              width={"166px"}
              onClick={handleClose}
              className="button__composicao-carga"
            />
          </React.Fragment>
        }
      >
        <HeaderForm
          modoEdicao={modoEdicao}
          composicao={composicao}
          unidadesNegocio={unidadesNegocio}
          changeSelected={changeSelected}
          changeSupplierSelected={changeSupplierSelected}
          fornecedores={fornecedores}
          modoLeitura={modoLeitura}
          SituacaoValor={SituacaoValor}
          handleChangeDate={handleChangeDate}
          handleChangeObservacao={handleChangeObservacao}
        />
        <Row>
          <Col className="col-observation" xs={3} style={{ padding: '0px' }}>
            <Input
              name="txbObservacao"
              label={intl.get("composition.form.deliveryNote")}
              value={note}
              onChange={e => setNote(e.target.value)}
              maxLength={14}
            />
          </Col>
        </Row>
        <Pedidos
          pedidos={pedidos}
          handleChangePedido={handleChangePedido}
        />
        <Loading isLoading={isLoading} fullHeightParent />
      </Dialog>
      <ModalFeedback
        message={modalFeedback.message}
        open={modalFeedback.isOpen}
        title={modalFeedback.title}
        handleClose={handleCloseModalFeedback}
      />
    </React.Fragment>
  )
}

export default FormCreateGRN;

import styled from 'styled-components'

const StyledGradeExcedidaList = styled.div`

  .grade-excedida-list-header {
    padding: 5px 0;

    .header-item {
        font-size: 12px;
        color: #95a3b7;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 0;
    }

    &:not(.isSubItem) {      
      border-bottom: 1px dashed #c5d0e1;
    }
  }  

  .spotlight-header-row {
    font-weight: 600;
    padding-top: 3px;
  }

  .table-list-item-body {
    padding-bottom: 5px;
  }

  .alert-message {
    width: 100%;
    padding-left: 8px;
    padding-top: 30px;
    color: #ed393d;
    font-weight: 600;
  }

.container-action-buttons .button {
  margin: 2px;
  display: inline-block;
  width: 30% !important;
}

.item-align {
  text-align: center;
}
`

export default StyledGradeExcedidaList
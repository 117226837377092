import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-flexbox-grid'
import { SelectBox } from "components/uikit-adapter/index"

import Filter from 'components/filters'
import DateRange from 'components/date-range'

import Session from 'utils/user-storage'
import Feature from 'components/feature';

import {
  getAllSuppliersService,
  getAllBusinessUnitsService,
  getAllSituationsService,
  getAllWalletsService,
  getAllLabeledFamiliesService,
  getAllScheduleAgreementsService,
} from './filter.service'

import intl from 'react-intl-universal'
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'

class FilterProject extends Component {
  constructor(props) {
    super()

    this.state = {
      rangeInvalidCollection: false,
      rangeInvalidDelivery: false,

      IdFornecedor: '',
      IdUnidadeNegocio: '',
      Situacao: '',
      IdCarteira: '',
      IdFamiliaRotulada: '',
      SchedulingAgreement: '',
      DataColetaDe: '',
      DataColetaAte: '',
      DataEntregaDe: '',
      DataEntregaAte: '',

      suppliers: [],
      businessUnits: [],
      situations: [],
      wallets: [],
      labeledFamilies: [],
      scheduleAgreements: [],
    }

    this.user = Session.get()
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { isProvider } = this.props

    if (this.state.initialData !== nextProps.initialData) {
      this.setState({
        initialData: nextProps.initialData,
        IdFornecedor: nextProps.initialData.IdFornecedor,
        IdUnidadeNegocio: nextProps.initialData.IdUnidadeNegocio,
        DataColetaDe: nextProps.initialData.DataColetaDe,
        DataColetaAte: nextProps.initialData.DataColetaAte,
        DataEntregaDe: nextProps.initialData.DataEntregaDe,
        DataEntregaAte: nextProps.initialData.DataEntregaAte,
        Situacao: nextProps.initialData.Situacao,
        IdCarteira: nextProps.initialData.IdCarteira,
        IdFamiliaRotulada: nextProps.initialData.IdFamiliaRotulada,
        SchedulingAgreement: nextProps.initialData.SchedulingAgreement,
      }, () => {
        if (!isProvider) {
          this.getAllSuppliers()
        }
        this.getAllBusinessUnits()
        this.getAllSituations()
        this.getAllWallets()
        this.getAllLabeledFamilies()
        this.getAllScheduleAgreements()
      })
    }
  }

  getAllSuppliers = async () => {
    try {
      const { useFilterDefault } = this.props

      const data = await getAllSuppliersService(useFilterDefault, this.user.Id);

      this.processSuppliers(data);
    } catch (error) {
      this.proccessError(error);
    }
  }

  getAllBusinessUnits = async () => {
    try {
      const data = await getAllBusinessUnitsService(this.user.Id);
      this.processBusinessUnits(data);
    } catch (error) {
      this.proccessError(error);
    }
  }

  getAllSituations = async () => {
    try {
      const data = await getAllSituationsService();
      this.processSituations(data);
    } catch (error) {
      this.proccessError(error);
    }
  }

  getAllWallets = async () => {
    try {
      const { useFilterDefault } = this.props
      const data = await getAllWalletsService(useFilterDefault, this.user.Id);
      this.processWallets(data);
    } catch (error) {
      this.proccessError(error);
    }
  }

  getAllLabeledFamilies = async () => {
    try {
      const { useFilterDefault } = this.props
      const data = await getAllLabeledFamiliesService(useFilterDefault, this.user.Id);
      this.processLabeledFamilies(data);
    } catch (error) {
      this.proccessError(error);
    }
  }

  getAllScheduleAgreements = async () => {
    try {
      const data = await getAllScheduleAgreementsService();
      this.processScheduleAgreements(data);
    } catch (error) {
      this.proccessError(error);
    }
  }

  processSuppliers = (data) => {
    this.setState({
      suppliers: data
    })
  }

  processBusinessUnits = (data) => {
    this.setState({
      businessUnits: data
    })
  }

  processSituations = (data) => {
    this.setState({
      situations: data
    })
  }

  processWallets = (data) => {
    this.setState({
      wallets: data
    })
  }

  processLabeledFamilies = (data) => {
    this.setState({
      labeledFamilies: data
    })
  }

  processScheduleAgreements = (data) => {
    this.setState({
      scheduleAgreements: data,
    })
  }

  changeValue = (name, value) => {
    this.setState({
      [name]: value
    })
  }

  handleCloseSearch = () => {
    const params = {
      IdFornecedor: this.state.IdFornecedor,
      IdUnidadeNegocio: this.state.IdUnidadeNegocio,
      DataColetaDe: this.state.DataColetaDe,
      DataColetaAte: this.state.DataColetaAte,
      DataEntregaDe: this.state.DataEntregaDe,
      DataEntregaAte: this.state.DataEntregaAte,
      Situacao: this.state.Situacao,
      IdCarteira: this.state.IdCarteira,
      IdFamiliaRotulada: this.state.IdFamiliaRotulada,
      SchedulingAgreement: this.state.SchedulingAgreement
    }

    this.props.handleClose(params)
  }

  handleCleanClick = () => {
    const params = {
      IdFornecedor: '',
      IdUnidadeNegocio: '',
      DataColetaDe: '',
      DataColetaAte: '',
      DataEntregaDe: '',
      DataEntregaAte: '',
      Situacao: '',
      IdCarteira: '',
      IdFamiliaRotulada: '',
      SchedulingAgreement: '',

      rangeInvalidCollection: false,
      rangeInvalidDelivery: false,
      rangeInvalidUpdate: false
    }

    this.setState(params, () => this.props.handleClose(params))
  }

  proccessError = (err) => {
    console.log(err)
  }

  changeFormInvalido = (inputName, invalido) => {
    this.setState({
      [inputName]: invalido
    })
  }

  isValidRanges = () => {
    const {
      rangeInvalidCollection,
      rangeInvalidDelivery
    } = this.state

    return (rangeInvalidDelivery || rangeInvalidCollection)
  }

  render() {
    const { isProvider, showFilter } = this.props

    const {
      IdFornecedor,
      IdUnidadeNegocio,
      Situacao,
      IdCarteira,
      IdFamiliaRotulada,
      SchedulingAgreement,
      DataColetaDe,
      DataColetaAte,
      DataEntregaDe,
      DataEntregaAte,

      suppliers,
      businessUnits,
      situations,
      wallets,
      labeledFamilies,
      scheduleAgreements,
    } = this.state

    return (
      <Filter
        showFilter={showFilter}
        handleCleanClick={this.handleCleanClick}
        handleCloseSearch={this.handleCloseSearch}
        disabledSearch={this.isValidRanges()}
      >
        {!isProvider &&
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                className='origemSelectBox'
                name='origem'
                label={intl.get('commons.source')}
                placeholder={intl.get('commons.source')}
                valueKey='IdFornecedor'
                labelKey='Descricao'
                value={IdFornecedor}
                options={suppliers}
                onChange={value => this.changeValue('IdFornecedor', value === null ? '' : value.IdFornecedor)}
                searchable
                openOnFocus
                clearable
              />
            </Col>
          </Row>
        }
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              className='destinoSelectBox'
              name='centro'
              label={intl.get('commons.destiny')}
              placeholder={intl.get('commons.destiny')}
              valueKey='IdUnidadeNegocio'
              labelKey='Descricao'
              value={IdUnidadeNegocio}
              options={businessUnits}
              onChange={value => this.changeValue('IdUnidadeNegocio', value === null ? '' : value.IdUnidadeNegocio)}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              className='situacaoSelectBox'
              name='situacao'
              label={intl.get('commons.situation')}
              placeholder={intl.get('commons.situation')}
              valueKey='Id'
              labelKey='Descricao'
              value={Situacao}
              options={situations}
              onChange={value => this.changeValue('Situacao', value === null ? '' : value.Id)}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              className='carteiraSelectBox'
              name='carteira'
              label={intl.get('commons.wallet')}
              placeholder={intl.get('commons.wallet')}
              valueKey='Id'
              labelKey='Descricao'
              value={IdCarteira}
              options={wallets}
              onChange={value => this.changeValue('IdCarteira', value === null ? '' : value.Id)}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <SelectBox
              className='familiaRotuladaSelectBox'
              name='familiaRotulada'
              label={intl.get('commons.labeledFamily')}
              placeholder={intl.get('commons.labeledFamily')}
              valueKey='Id'
              labelKey='Descricao'
              value={IdFamiliaRotulada}
              options={labeledFamilies}
              onChange={value => this.changeValue('IdFamiliaRotulada', value === null ? '' : value.Id)}
              searchable
              openOnFocus
              clearable
            />
          </Col>
        </Row>
        <Feature flags={GeneralSettingParameter.OrderSettings}>
          <Row>
            <Col xs={12} lg={12} md={12}>
              <SelectBox
                className='scheduleAgreementsSelectBox'
                name='scheduleAgreements'
                label={intl.get('commons.schedulingAgreement')}
                placeholder={intl.get('commons.schedulingAgreement')}
                valueKey='SchedulingAgreement'
                labelKey='SchedulingAgreement'
                value={SchedulingAgreement}
                options={scheduleAgreements}
                onChange={value => this.changeValue('SchedulingAgreement', value === null ? '' : value.SchedulingAgreement)}
                searchable
                openOnFocus
                clearable
              />
            </Col>
          </Row>
        </Feature>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <DateRange
              labelDe={intl.get('commons.collectDateDE')}
              labelAte={intl.get('commons.collectDateAT')}
              dataDe={DataColetaDe}
              dataAte={DataColetaAte}
              onChangeDataDe={value => this.changeValue('DataColetaDe', value)}
              onChangeDataAte={value => this.changeValue('DataColetaAte', value)}
              onRangeIsValid={value => this.changeFormInvalido('rangeInvalidCollection', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={12} md={12}>
            <DateRange
              labelDe={intl.get('loadOptimization.filter.deliveryDateOf')}
              labelAte={intl.get('loadOptimization.filter.deliveryDateBy')}
              dataDe={DataEntregaDe}
              dataAte={DataEntregaAte}
              onChangeDataDe={value => this.changeValue('DataEntregaDe', value)}
              onChangeDataAte={value => this.changeValue('DataEntregaAte', value)}
              onRangeIsValid={value => this.changeFormInvalido('rangeInvalidDelivery', value)}
            />
          </Col>
        </Row>
      </Filter>
    )
  }
}

FilterProject.propTypes = {
  initialData: PropTypes.object,
  showFilter: PropTypes.bool,
  handleClose: PropTypes.func,
}

FilterProject.defaultProps = {
  initialData: {},
}

export default FilterProject

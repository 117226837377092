import React from 'react'
import Paper from 'material-ui/Paper'
import Divisor from '../../../../components/card/body-card/card-divisor'
import intl from 'react-intl-universal'
import StatusCard from '../../../../components/card/body-card/status-card'
import * as S from './styled'
import { formatNumber, formatWithoutLeadingZeros } from 'utils/format'
import { getCardName } from '../../utils'

const CardOrderSuggestionIntoLoadComposition = ({ data, handleClick, openModal, codeDescription }) => {
  const tipo = {
    Etapa: 1,
    Prioridade: data.IdPedido ? 1 : 0,
    Titulo: getCardName(data)
  }

  return (
    <S.Wrapper hasOrderCode={data.WmsTransactionId}>
      <Paper zDepth={1} className='card-shop-mester' style={{ height: `250px` }}>
        <div className='card-click-area'>
          <div className='header-card'>
            <StatusCard
              tipo={tipo}
              material={formatWithoutLeadingZeros(data.Material)}
            />
          </div>
          <Divisor />
          <div className='body-card'>
            <div className='card-label'>
              <span>{intl.get('loadComposition.building.orderAmmount')}</span>
              <span
                className='description-bold'>
                {formatNumber(data.Quantidade)}{data.QuantidadeUnidadeMedida} {` - `}
                <span className='description-bold' style={{ color: '#2870B2' }}>
                  {data.TotalPallets}{data.LotesUnidadeMedida}
                </span>
              </span>
            </div>
            <div className='card-label'>
              <span>{intl.get('stocks.timeline.currentInventory')}</span>
              <span className='description-bold'> {formatNumber(data.EstoqueAtual)}{data.EstoqueAtualUnidadeMedida}</span>
            </div>
          </div>
          <div>
            {data.WmsTransactionId && <Divisor />}
            <div className='body-card'>
              {data.WmsTransactionId &&
                <div className='card-label'>
                  <span>{codeDescription}</span>
                  <span className='description-bold'>{data.WmsTransactionId}</span>
                </div>
              }
              <S.ActionsWrapper className='card-label' hasOrderCode={data.WmsTransactionId}>
                <S.ButtonEdit
                  type='primary'
                  value={intl.get('stocks.timeline.editOrder')}
                  onClick={openModal}
                />
                <S.Button
                  type='default'
                  value={intl.get('loadComposition.building.removeLoad')}
                  onClick={handleClick}
                />
              </S.ActionsWrapper>
            </div>
          </div>
        </div>
      </Paper>
    </S.Wrapper >
  )
}

export default CardOrderSuggestionIntoLoadComposition

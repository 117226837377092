import React, { useState, useEffect } from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'
import { Droppable } from 'react-beautiful-dnd';
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from '../../../../utils/handle-error'
import CancelDialog from '../cancel-modal'
import { useLoadCompositionBuildingContext } from '../../context/load-composition-building-context'
import Loading from 'components/center-loading'
import SupplierInfos from '../../../load-composition/components/supplier-negotiation-infos'
import { searchComposition } from '../../../composicao-carga/form/composicao-carga-form.service'
import ButtonAccept from './components/buttonAccept'
import { SituacaoComposicaoCarga } from '../../../../models/composicaoCarga/situacaoComposicaoCarga'
import CardsContent from './components/cardsContent'
import DestinationBooking from './components/destinationBooking';
import { isScheduleAgreementFlow, usesEmergencyNegotiation } from 'utils/validations-general-settings';
import { cancelLoadComposition } from 'pages/load-composition-building/load-composition-building.service';
import { isSimpleCompositionCancellation } from 'pages/load-composition-building/utils';

const Sidebar = ({ data, history, openModal, queryParams }) => {
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showLoad, setShowLoad] = useState(false)
  const [composicao, setComposicao] = useState({});

  const {
    isEditDateMode,
    params,
    pushPath,
    setBreweryNote,
    selectedLoads,
    isEmergencyNegotiation,
    setIsEmergencyNegotiation
  } = useLoadCompositionBuildingContext();
  const { showFeedback } = useGlobalContext();

  const isEditLoadComposition = !!params.composicaoId;

  const getQuantidadeLotes = () => {
    const quantidade = data.map(x => parseInt(x.TotalPallets)).reduce((x, y) => x + y);
    return `${quantidade} ${data[0].LotesUnidadeMedida}`
  }

  const cancel = () => {
    isEditLoadComposition
      ? setShowCancelModal(true)
      : cancelModalValidations()
  }

  const cancelModalValidations = () => {
    if (data && data.length > 0) {
      setShowCancelModal(true)
    } else {
      history.push(pushPath)
    }
  }

  const loadCompositionsData = () => {
    searchComposition(params.composicaoId).then(_composicao => {
      setComposicao(_composicao)
      setBreweryNote(_composicao.BreweryNote)
      if(usesEmergencyNegotiation()) setIsEmergencyNegotiation(_composicao.SituacaoValor === SituacaoComposicaoCarga.Aceito)
    })
  }

  const inicializaInputs = () => {
    if (params.composicaoId) {
      loadCompositionsData();
    }
  }

  const proccessError = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  const getOriginLabel = () => data[0] ? data[0].Origem : ' - ';

  const isShowSuppliersNotes = (compositionStatus) => {
    return isEditLoadComposition && compositionStatus !== SituacaoComposicaoCarga.AguardandoRevisaoFornecedor;
  }

  const isSimpleCompositionCancellationMessage = () => isSimpleCompositionCancellation(selectedLoads)
    ? intl.get("loadComposition.cancelModal.messageEmptyComposition")
    : intl.get("loadComposition.cancelModal.message");

  const isSimpleCompositionCancellationAction = async () => {
    if (isSimpleCompositionCancellation(selectedLoads)) {
      const url = isScheduleAgreementFlow() ? `/load-compositions/cancel-by-schedule-agreement` : `load-compositions/cancel-by-purchase-order`
      await cancelLoadComposition(url, { IdComposicao: composicao.IdComposicao });
    }

    history.push(pushPath)
  }

  useEffect(() => {
    inicializaInputs()
  }, [isEditLoadComposition]);

  const isValidDay = () => params.dia !== '0000-00-00'

  return (
    <React.Fragment>
      <Loading isLoading={showLoad} />
      <Droppable droppableId="loads-suggestions-selecteds">
        {(provided) => (
          <S.Wrapper className="loads-suggestions-selecteds" {...provided.droppableProps} innerRef={provided.innerRef}>
            <S.WrapperHeader>
              <S.Title>{intl.get('commons.composition')}{isEditLoadComposition && ` - ${params.composicaoId}`}</S.Title>
              {isEmergencyNegotiation && <S.Subtitle>{intl.get('commons.emergencyNegotiation')}</S.Subtitle>}
              <S.InfosWrapper>
                <div className='last-content'>
                  <span className='title'>{intl.get("stocks.suggestionsListItem.origin")}</span>
                  <span className='description'>{getOriginLabel()}</span>
                </div>
              </S.InfosWrapper>
              <DestinationBooking
                isValidDay={isValidDay()}
                queryParams={queryParams}
              />
            </S.WrapperHeader>
            {isShowSuppliersNotes(composicao.SituacaoValor) &&
              <S.SupplierInfosWrapper>
                <SupplierInfos
                  composicao={composicao}
                  showDrop
                />
              </S.SupplierInfosWrapper>
            }
            <CardsContent
              isEditLoadComposition={isShowSuppliersNotes(composicao.SituacaoValor)}
              providedDragPlaceholder={provided.placeholder}
              openModal={openModal}
              data={data}
            />
            <S.WrapperFooter>
              <S.InfosWrapper isFooter>
                <div className='content'>
                  <span className='title'>{intl.get('loadComposition.building.totalPalletCount')}</span>
                  <span className='description'>{data.length > 0 ? getQuantidadeLotes() : '-'}</span>
                </div>
              </S.InfosWrapper>
              <S.ActionsWrapper className='card-label'>
                <S.ButtonEdit
                  onClick={cancel}
                  type='default'
                  value={intl.get('geral.buttonsDefault.cancel')}
                />
                <ButtonAccept
                  isEditDateMode={isEditDateMode}
                  data={data}
                  setShowLoad={setShowLoad}
                  infosRoute={params}
                  proccessError={proccessError}
                  pushPath={pushPath}
                  history={history}
                  composicao={composicao}
                  disabled={!isValidDay()}
                  queryParams={queryParams}
                />
              </S.ActionsWrapper>
            </S.WrapperFooter>
          </S.Wrapper >
        )}
      </Droppable>
      {showCancelModal &&
        <CancelDialog
          title={intl.get("loadComposition.cancelModal.title")}
          open={showCancelModal}
          confirm={() => isSimpleCompositionCancellationAction()}
          close={() => setShowCancelModal(false)}
          message={isSimpleCompositionCancellationMessage()}
        />
      }
    </React.Fragment>
  )
}

export default Sidebar;

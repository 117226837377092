import { Route, Switch } from 'react-router-dom';
import Login from 'pages/login';
import SignUp from 'pages/sign-up';
import { useEffect, useState } from 'react';
import { getLocaleForLanguageSheet } from 'utils/userLanguage';
import withLanguage from 'utils/withLanguage';
import locales from 'locales';
import intl from 'react-intl-universal';

const RoutesLogin = () => {
  const [initDone, setInitDone] = useState(false);

  const loadLocales = async () => {
    const language = getLocaleForLanguageSheet(withLanguage(navigator));
    await intl.init({
      currentLocale: language,
      locales,
    });
    setInitDone(true);
  };

  useEffect(() => {
    loadLocales();
  }, []);

  if(!initDone){
    return null;
  }

  return (
    <Switch>
      <Route exact path={`/`} component={Login} /> 
      <Route path={`/sign-up`} component={SignUp} />
    </Switch>
  )
}

export default RoutesLogin

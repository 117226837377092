import React from 'react';
import intl from 'react-intl-universal';
import {
    Button, 
    Input,
    Dialog, 
    SelectBox,
    Table,
    TableBody,
    TableRow,
    TableRowColumn,
} from 'components/uikit-adapter/index'
import PropTypes from 'prop-types'
import { Title, SubTitle, Header, ContainerTitles, ContainerSelect, ContainerActions } from './form-styled'

const CriticalItemsForm = ({ open, handleClose, data, save, handleSufficiencyDays, handleSituation, profileAdminOrMasterData }) => {

    const status = [
        {
            Key: 0,
            Value: intl.get('master-data.configurations.critical-items.situation.enabled')
        },
        {
            Key: 1,
            Value: intl.get('master-data.configurations.critical-items.situation.disabled')
        }
    ];

    return (
        <Dialog
            title={<Title>{intl.get('master-data.configurations.critical-items.form-header.title')}</Title>}
            open={open}
            autoScrollBodyContent
            actions={
                <ContainerActions>
                    <Button
                        value={intl.get("geral.buttonsDefault.close")}
                        onClick={handleClose}
                        type="default"
                        className="buttons"
                    />
                    <Button
                        disabled={!profileAdminOrMasterData}
                        value={intl.get("geral.buttonsDefault.save")}
                        onClick={save}
                        className="buttons last-button"
                        type="primary"
                    />
                </ContainerActions>
            }
        >
            <Header>
                <ContainerTitles>
                    <SubTitle>{data.Familia}</SubTitle>
                </ContainerTitles>
                <ContainerSelect>
                    <SelectBox
                        required
                        label='Status'
                        placeholder='Status'
                        name='Status'
                        value={data.RelevanciaItensCritico ? status[0] : status[1]}
                        valueKey='Key'
                        labelKey='Value'
                        onChange={value => handleSituation(value.Key)}
                        options={status}
                        width="100%"
                        disabled={!(data.ConfiguracaoItensCritico.length && profileAdminOrMasterData)}
                    />
                </ContainerSelect>
            </Header>
            <Table
                fixedHeader
                fixedFooter
                multiSelectable={false}
                selectable={false}
                wrapperStyle={{ height: '100%' }}
                bodyStyle={{
                    height: 'calc(100% - 110px)',
                    borderTop: '1px solid rgb(224, 224, 224)',
                    backgroundColor: '#FFFFFF',
                    minHeight: '80px',
                    maxHeight: '40vh'
                }}
            >
                <TableBody
                    displayRowCheckbox={false}
                    showRowHover
                >
                    {!!data.ConfiguracaoItensCritico.length ? data.ConfiguracaoItensCritico.map((v, i) => (
                        <TableRow key={i} style={{
                            border: 'solid',
                            borderColor: '#C5D0E1',
                            borderWidth: '0.1px'
                        }} >
                            <TableRowColumn style={{ width: '80%' }}>
                                {v.CentroUnidadeNegocio} - {v.DescricaoUnidadeNegocio}
                            </TableRowColumn>
                            <TableRowColumn style={{ width: '20%', textAlign: 'right' }}>
                                <Input
                                    style={{ textAlign: 'center' }}
                                    value={v.DiasSuficiencia}
                                    htmlType="number"
                                    onChange={(e) => handleSufficiencyDays(e.target.value, i)}
                                    disabled={!(data.RelevanciaItensCritico && profileAdminOrMasterData)}
                                />
                            </TableRowColumn>
                        </TableRow>
                    )) :
                        <div style={{ marginTop: '15px' }}>
                            {intl.get('master-data.configurations.critical-items.feedbacks.no-registry')}
                        </div>
                    }
                </TableBody>
            </Table>
        </Dialog>
    )
}

CriticalItemsForm.propTypes = {
    data: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    handleSufficiencyDays: PropTypes.func.isRequired,
    handleSituation: PropTypes.func.isRequired
}

export default CriticalItemsForm;
import React from "react"
import intl from "react-intl-universal"
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useZsagContext } from "../context/zsagContext"
import { SaveIcn } from "components/icons/icn-index.icon"
import { Input, Button, SelectBox } from 'components/uikit-adapter/index'
import BtnMore from 'images/btn-more.svg'
import { autoGenerateSchedulesInsert, retornaObjetoComId } from "../auto-load-optimization.service"
import { errorMessage, validateNewLine } from "../utils/validate"
import { shiftValues } from "../utils/models"
import './styled.css'

const NewLine = () => {

  const { showFeedback } = useGlobalContext()
  const {
    volumes,
    setVolumes,
    setLoading,
    filters,
    newLineInProgress,
    setNewLineInProgress,
    setHaveUnsavedChanges,
    materials
  } = useZsagContext()


  const newLine = () => {

    const newVolume = {
      MaterialNumber: () =>
        <div className="autoLoad__selectbox--wrapper">
          <SelectBox
            className="primaryMaterial__selectbox"
            value={""}
            placeholder={intl.get('commons.material')}
            options={materials}
            onChange={(e) => changePrimaryMaterial(e)}
            valueKey="CodigoMaterial"
            labelKey="DescricaoCompleta"
            searchable
            data-testid='primaryMaterial__selectbox'
            dontShowLabel={true}
            width={"250px"}
          />
        </div>
      ,
      QuantityPallets: () => <Input id='new-quantity-pallets' htmlType="number" />,
      DeliveryDate: () =>
        <Input
          className="drp-input-date drp-nomargin data-de-picker"
          value={""}
          htmlType="date"
          id="new-delivery-date"
          onChange={(e) => changeDeliveryDate(e.target.value)}
          data-testid="inputDeliveryDate"
        />
      ,
      Interval: () =>
        <Input
          value={""}
          mask="time"
          id="new-interval"
          onChange={(e) => changeInterval(e.target.value)}
          data-testid="inputInterval"
        />,
      Shift: () =>
        <div className="autoLoad__selectbox--wrapper">
          <SelectBox
            className="shift__selectbox"
            value={""}
            options={shiftValues}
            onChange={(e) => changeShift(e)}
            valueKey="value"
            labelKey="value"
            searchable
            data-testid='shift__selectbox'
            dontShowLabel={true}
            width={"90px"}
          />
        </div>,
      edit: () => <span data-testid='handle-save-new-line' style={{ cursor: 'pointer' }} onClick={saveNewLine}> <SaveIcn width="15px" height="15px" /> </span>,
      Status: { Code: 1, Message: "" },
      Process: "I"
    }

    handleNewLine(newVolume)
    setNewLineInProgress(true)
  }

  const saveNewLine = async () => {
    const newLineData = {
      Material: volumes.Schedules[0].MaterialValue,
      QuantityPallets: document.getElementById('new-quantity-pallets').value,
      DeliveryDate: document.getElementById('new-delivery-date').value,
      NewShift: volumes.Schedules[0].ShiftValue,
      Interval: document.getElementById('new-interval').value,
      Process: "I"
    }

    const filtersRequest = {
      Plant: filters.plant.Centro,
      ProductionVersion: filters.productionVersion.productionLine,
      DeliveryDateFrom: filters.date.deliveryDateFrom,
      DeliveryDateTo: filters.date.deliveryDateTo,
      Shift: filters.shift,
      Recalculate: false
    }

    const insertDto = {
      AutoGenerateSchedulesFilterDto: filtersRequest,
      NewLine: newLineData
    }


    if (!validateNewLine(newLineData, processError)) {
      setLoading(true)
      await autoGenerateSchedulesInsert(insertDto)
        .then((response) => {
          processNewData(response)
          setHaveUnsavedChanges(true)
        })
        .catch((e) => processError(e));
      setLoading(false)
    }
  }

  const changePrimaryMaterial = (value) => {
    const _volumes = volumes
    _volumes.Schedules[0].MaterialValue = value.CodigoMaterial
    _volumes.Schedules[0].MaterialNumber = () =>
      <div>
        <SelectBox
          className="primaryMaterial__selectbox"
          placeholder={intl.get('commons.material')}
          value={value}
          options={materials}
          onChange={(e) => changePrimaryMaterial(e)}
          valueKey="CodigoMaterial"
          labelKey="DescricaoCompleta"
          searchable
          data-testid='primaryMaterial__selectbox'
          dontShowLabel={true}
          width={"250px"}
        />
      </div>

    setVolumes({ ..._volumes })
  }

  const changeShift = (value) => {
    const _volumes = volumes
    _volumes.Schedules[0].ShiftValue = value.value
    _volumes.Schedules[0].Shift = () =>
      <div className="autoLoad__selectbox--wrapper">
        <SelectBox
          className="shift__selectbox"
          value={value}
          options={shiftValues}
          onChange={(e) => changeShift(e)}
          valueKey="value"
          labelKey="value"
          searchable
          data-testid='shift__selectbox'
          dontShowLabel={true}
          width={"90px"}
        />
      </div>
    setVolumes({ ..._volumes })
  }

  const changeDeliveryDate = (value) => {
    const _volumes = volumes;
    _volumes.Schedules[0].DeliveryDate = () =>
      <Input
        required
        value={value}
        id="new-delivery-date"
        onChange={e => changeDeliveryDate(e.target.value)}
        data-testid='input'
        htmlType="date"
      />

    setVolumes({ ..._volumes })
  }

  const changeInterval = (value) => {
    const _volumes = volumes;
    _volumes.Schedules[0].Interval = () =>
      <Input
        required
        id="new-interval"
        value={value}
        onChange={e => changeInterval(e.target.value)}
        data-testid='input'
        mask="time"
      />

    setVolumes({ ..._volumes })
  }

  const processNewData = (line) => {
    const schedules = [...volumes.Schedules];
    schedules[0] = line;

    const _volumes = {
      ...volumes,
      Schedules: schedules.map((value, index) => retornaObjetoComId(value, index))
    }

    setVolumes({ ..._volumes })
    showFeedback(intl.get("feedbacks.savedRegister"))
    setNewLineInProgress(false)
  }

  const handleNewLine = (newVolume) => {
    const _volumes = volumes;

    _volumes.Schedules = [
      newVolume,
      ..._volumes.Schedules
    ]
    setVolumes({ ..._volumes })
  }

  const processError = e => {
    showFeedback(errorMessage(e));
  }

  return (
    <div className='btn-wrapper' data-testid='btn-more-new-line'>
      <Button
        type="secondary"
        icon={BtnMore}
        className='btn-action'
        onClick={newLine}
        disabled={newLineInProgress}
      />
    </div>
  )
}

export default NewLine

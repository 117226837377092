import React from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'

const CardFreeSlot = ({ quantity, hour, handleClick, dia, selectedFreeSlot }) => (
  <>
    {[...Array(quantity).keys()].map((x, i) => (
      <S.Wrapper
        onClick={() => handleClick({ Hora: hour }, i)}
        data-testid='free-slot-wrapper'
        isSelected={selectedFreeSlot === `${dia}-${hour}-${i}`}
      >
        <S.Hora>
          {hour && <span> {hour}</span>}
        </S.Hora>
        <S.Content>
          <span>{intl.get("loadComposition.filter.freeSlot")}</span>
        </S.Content>
      </S.Wrapper>
    ))}
  </>
)

export default CardFreeSlot;

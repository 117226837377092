import React, { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import PersonIcon from 'material-ui/svg-icons/social/person'
import Fetch from 'utils/fetch'
import StorePersist from 'utils/store-persist'
import Filtro from 'components/filters/filter-grupo-fornecedor'
import Loading from 'components/center-loading'
import Message from 'components/message'
import DeleteUser from 'components/delete-dialog'
import HeaderIcon from 'components/header-icon'
import ButtonNewItem from 'components/button-new-item'
import IconFilterGray from 'images/icn-filter-gray.svg'
import IconFilterBlue from 'images/icn-filter-blue.svg'
import IconEmpty from 'images/iconEmpty.icon'
import { Button, Layout } from 'components/uikit-adapter/index'
import SupplierGroupList from './supplier-group-list'
import SupplierGroupForm from './supplier-group-form'
import intl from 'react-intl-universal'
import './supplier.css'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";

class SupplierGroup extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      Users: [],
      total: 0,
      openDelete: false,
      currentPage: 0,
      openForm: false,
      openEditMode: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 50,
      showMsg: false,
      textMsg: '',
      showFilterUsuario: false,
      filters: {
        IdUsuario: null
      }
    }
  }

  UNSAFE_componentWillMount() {
    const filter = StorePersist.getValuesJSON('filtersUsuario')
    this.setState({
      filters: filter ? filter : this.state.filters
    })
  }


  searchFilters = (filters) => {
    StorePersist.setValuesJSON('filtersUsuario', filters);
    this.showFilterUsuario()
    this.setState({
      currentPage: 0,
      filters
    }, () => this.searchUser(this.state.currentPage))
  }

  showFilterUsuario = () => {
    this.setState({
      showFilterUsuario: !this.state.showFilterUsuario,
    })
  }

  verifyFilter = () => {
    return this.state.filters.IdUsuario > 0
  }

  showMessage = (message) => {
    this.setState({
      showMsg: true,
      textMsg: message
    })
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const selected = this.state.Users[row]

    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (ex) => {
    this.closeDelete()
    this.showMessage((ex.response.status === 400) ? ex.response.data.Message : intl.get('stocks.timeline.feedback.serverError'))
  }

  refreshSearch = () => {
    this.closeForm()
    this.searchUser(this.state.currentPage)
  }

  searchUser = (page) => {
    this.startFetching()
    const nextPage = page == undefined ? this.state.currentPage : page;
    Fetch.post(`/usuario/fornecedores/${this.state.qtRegsPerPage}/${nextPage}`, this.state.filters)
      .then((response) => this.proccessData(response.data))
      .then(this.stopFetching)
      .then(() => this.setState({ currentPage: nextPage }))
      .catch((e) => this.proccessErro(e))
  }

  proccessData = (data) => {
    this.setState({
      Users: data.Data,
      total: data.TotalRecordCount
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  deleteUser = () => {
    const idUser = this.state.idSelected

    Fetch.delete(`/usuario/${idUser}`)
      .then(this.closeDelete)
      .then(() => this.searchUser(this.state.currentPage))
      .then(() => this.showFeedback(intl.get('master-data.feedback.userDeletedSuccessfully')))
      .catch((e) => this.proccessErro(e))
  }

  render() {
    let {
      openForm,
      openEditMode,
      openDelete,
      idSelected,
      Users,
      total,
      showFeedback,
      messageFeedBack,
      isFetching,
      showMsg,
      textMsg,
      showFilterUsuario,
      filters
    } = this.state

    return (
      <div className='supplier-list-container'>
        <Loading isLoading={isFetching} />

        <div className="supplier-header">
          <HeaderIcon
            title={intl.get('master-data.suppliers.usersGroupSupplier.header.title')}
            subtitle={intl.get('master-data.suppliers.usersGroupSupplier.header.subtitle')}
            icon={<PersonIcon style={{ width: 45, height: 45 }} color='#1d388f' />}
          >
            {profileAdminOrMasterData &&
              <div title={intl.get('master-data.suppliers.usersGroupSupplier.actions.newSupplierGroup')}>
                <ButtonNewItem
                  className="general-button"
                  onClick={this.openNewForm}
                />
              </div>
            }
            <div title={intl.get('master-data.suppliers.usersGroupSupplier.actions.filterSupplierGroup')} className='filterContainer'>
              <Button
                onClick={this.showFilterUsuario}
                type='default'
                value={intl.get('filters.filter')}
                icon={this.verifyFilter() ? IconFilterBlue : IconFilterGray}
                className={"button-filter"}
              />
            </div>
          </HeaderIcon>
        </div>

        <Layout.Sidebar width="450px"
          visible={showFilterUsuario}
          triggerClose={this.showFilterUsuario}
          background="#FFFFFF"
          offsetTop="96px"
          side="right"
          block
          float
          icon={<IconEmpty />}
        >
          <Filtro
            showFilter={showFilterUsuario}
            initialData={filters}
            handleClose={this.searchFilters}
          />

        </Layout.Sidebar>

        <SupplierGroupList
          data={Users}
          countData={total}
          refreshSearch={this.searchUser}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          qtRegsPerPage={this.state.qtRegsPerPage}
          profileAdminOrMasterData={profileAdminOrMasterData}
        />

        {openForm &&
          <SupplierGroupForm
            idEdit={idSelected}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
            profileAdminOrMasterData={profileAdminOrMasterData}
          />
        }

        <DeleteUser
          open={openDelete}
          title={intl.get('master-data.suppliers.usersGroupSupplier.actions.deleteUser')}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.general.materials.description')}
          handleConfirm={this.deleteUser}
        />

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

        <Message
          show={showMsg}
          text={textMsg}
          title='Erro!'
          handleClose={() => this.setState({ showMsg: false })}
        />
      </div>
    )
  }
}

export default SupplierGroup

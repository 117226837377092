import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Input } from '@hbsis.uikit/react'
import { SelectBox } from "components/uikit-adapter/index"
import Fetch from 'utils/fetch'
import { Row, Col } from "react-flexbox-grid";

import Dialog from 'material-ui/Dialog'
import SnackBar from 'material-ui/Snackbar'

import './labeled-family-edit.css'

import {
  searchPeriodicidades,
  searchWallets,
  searchLabeledFamilyById
} from "./labeled-family-form.service";

import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error';
import { Switcher } from 'components/toggle';
import { hideSomeSettingsLabeledFamily, usesOORRelevance, usesTmsIntegration } from 'utils/validations-general-settings';
const PERIODICIDADE_DIARIA = 2;

class LabeledFamilyForm extends Component {
  constructor() {
    super()
    this._isMounted = false;
    this.state = {
      labeledFamily: {},
      families: [],
      periodicidades: [],
      wallets: [],
      selectedWallet: null,
      selectedFamily: null,
      selectedPeriodicidade: null,
      selectedSaneamentoVolumesPendentes: true,
      selectedPilotoAutomatico: false,
      selectedAcionamentoTransporteCIF: false,
      selectedOORRelevance: true,
      isFetching: false,
      showMsg: false,
      textMsg: ''
    }
  }

  componentDidMount = () => {
    this._isMounted = true;
    const editMode = this.props.editMode

    if (editMode) {
      this.searchLabeledFamilyById()
    }

    this.searchPeriodicidades();
    this.searchWallets()
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  searchPeriodicidades = async () => {
    try {
      const data = await searchPeriodicidades()
      this.proccessPeriodicidades(data)
    }
    catch (e) {
      this.proccessErro(e)
    }
  }

  searchWallets = async () => {
    try {
      const data = await searchWallets();
      this.proccessWalletData(data);
    } catch (e) {
      console.error(e)
    }
  }

  proccessErro = (exception) => {
    this.props.handleFeedback(formatErrorMessage(exception))
  }

  searchFamilies = (value) => {
    Fetch.get(`/familia/porCarteira/${value}`)
      .then((response) => this.proccessFamilyData(response.data))
      .catch((e) => this.proccessErro(e))
  }

  searchLabeledFamilyById = async () => {
    const idFamily = this.props.idEdit
    try {
      const data = await searchLabeledFamilyById(idFamily);
      this.proccessData(data);
    } catch (e) {
      this.proccessErro(e)
    }
  }

  proccessEstoqueError = (mensagem) => {
    this.setState({
      showMsg: true,
      textMsg: mensagem
    })
  }

  proccessData = (data) => {

    this.setState({
      labeledFamily: data,
      selectedFamily: data.IdFamilia,
      selectedWallet: data.IdCarteira,
      selectedPeriodicidade: data.IdPeriodicidade,
      selectedPilotoAutomatico: data.PilotoAutomatico,
      selectedAcionamentoTransporteCIF: data.AcionamentoTransporteCIF,
      selectedSaneamentoVolumesPendentes: data.SaneamentoVolumesPendentes,
      selectedOORRelevance: data.RelevanciaOOR
    })

    this.searchFamilies(data.IdCarteira)
  }

  proccessWalletData = (data) => {
    if (this._isMounted) {
      this.setState({ wallets: data })
    }
  }

  proccessFamilyData = (data) => {
    if (this._isMounted) {
      this.setState({ families: data })
    }
  }

  proccessPeriodicidades = (data) => {
    if (this._isMounted) {
      this.setState({ periodicidades: data })
    }
  }

  changeValue = (event) => {
    const prop = event.target.name
    const value = event.target.value

    this.setState({
      labeledFamily: {
        ...this.state.labeledFamily,
        [prop]: value
      }
    })
  }

  finalizeProccessEstoqueError = () => {
    if (this._isMounted) {
      this.setState({
        showMsg: false,
        textMsg: ''
      })
    }

  }

  verifyIsGarrafa = (idCarteira) => {
    const carteira = this.state.wallets.find(x => x.Id === idCarteira);
    return carteira ? carteira.IsGarrafa : false;
  }

  save = () => {
    if (!this.state.selectedPeriodicidade) {
      this.proccessEstoqueError(intl.get('master-data.walletsAndFamilys.labeledFamily.feedback.selectPeriodicity'))
      return;
    }
    const editMode = this.props.editMode;
    const familyId = this.props.idEdit;
    let model = this.state.labeledFamily;

    model = {
      ...model,
      IdCarteira: this.state.selectedWallet || 0,
      IdFamilia: this.state.selectedFamily || 0,
      IdPeriodicidade: this.state.selectedPeriodicidade,
      PilotoAutomatico: this.state.selectedPilotoAutomatico,
      AcionamentoTransporteCIF: this.state.selectedAcionamentoTransporteCIF,
      RelevanciaOOR: this.state.selectedOORRelevance,
      SaneamentoVolumesPendentes:
        this.state.selectedPeriodicidade === PERIODICIDADE_DIARIA
          ? this.state.selectedSaneamentoVolumesPendentes : false
    }

    const promisse = !editMode
      ? Fetch.post(`/familiaRotulada`, model)
      : Fetch.put(`/familiaRotulada/${familyId}`, model)

    promisse.then(this.props.handleRefresh)
      .then(() => this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`))
      .catch((e) => this.proccessErro(e))
  }

  updateSelectValue = (prop, value) => {
    this.setState({
      [prop]: value
    }, () => {
      if (prop === 'selectedWallet') {
        this.searchFamilies(value)
      }
    })
  }

  updateSelectValuePeriodicidade = (value) => {
    this.setState({
      selectedPeriodicidade: value,
      selectedSaneamentoVolumesPendentes: value === PERIODICIDADE_DIARIA
    })
  }

  render() {
    let {
      labeledFamily,
      families,
      wallets,
      selectedWallet,
      selectedFamily,
      selectedPeriodicidade,
      selectedSaneamentoVolumesPendentes,
      selectedPilotoAutomatico,
      selectedAcionamentoTransporteCIF,
      selectedOORRelevance,
      periodicidades,
      showMsg,
      textMsg
    } = this.state

    let { open, editMode, handleClose, profileAdminOrMasterData } = this.props

    return (
      <div>
        <Dialog
          title={editMode ? intl.get('master-data.walletsAndFamilys.labeledFamily.actions.editLabeledFamily') : intl.get('master-data.walletsAndFamilys.labeledFamily.actions.newLabeledFamily')}
          contentStyle={{ width: '600px' }}
          open={open}
          autoScrollBodyContent
        >

          <div className='line-1-columns'>
            <SelectBox
              required
              label={intl.get('commons.wallet')}
              valueKey='Id'
              labelKey='Descricao'
              placeholder={intl.get('commons.wallet')}
              value={selectedWallet}
              options={wallets}
              onChange={value => this.updateSelectValue('selectedWallet', value.Id)}
              disabled={!profileAdminOrMasterData}
              searchable
              openOnFocus
            />
          </div>

          <div>
            <SelectBox
              required
              label={intl.get('commons.family')}
              placeholder={intl.get('commons.family')}
              valueKey='Id'
              labelKey='Nome'
              value={selectedFamily}
              options={families}
              onChange={value => this.updateSelectValue('selectedFamily', value.Id)}
              disabled={!profileAdminOrMasterData}
              searchable
              openOnFocus
            />
          </div>

          <div className='auto-complete'>
            <SelectBox
              required
              name='Periodicidade'
              label={intl.get('master-data.general-configuration.frequency')}
              placeholder={intl.get('master-data.general-configuration.frequency')}
              valueKey='Id'
              labelKey='Nome'
              value={selectedPeriodicidade}
              options={periodicidades}
              onChange={value => this.updateSelectValuePeriodicidade(value.Id)}
              disabled={!profileAdminOrMasterData}
              searchable
              openOnFocus
            />
          </div>

          <div className='line-1-columns'>
            <Input
              required
              name='Descricao'
              label={intl.get('commons.description')}
              value={labeledFamily.Descricao}
              onChange={this.changeValue}
              disabled={!profileAdminOrMasterData}
            />
          </div>

          {this.verifyIsGarrafa(this.state.selectedWallet) && <div>
            <Input
              label={intl.get('commons.hectolitersThousand')}
              name='HectolitrosMilheiro'
              htmlType="number"
              value={labeledFamily.HectolitrosMilheiro}
              onChange={this.changeValue}
            />
          </div>
          }

          {(selectedPeriodicidade === PERIODICIDADE_DIARIA || !hideSomeSettingsLabeledFamily()) &&
            <>
              <label className="label-style">{intl.get('master-data.menus.configuration')}</label>
              <div className="container">
                  <Row>
                    <Col xs={5} style={{ marginTop: '5px' }}>
                      <Switcher
                        name="PilotoAutomatico"
                        label="Auto Pilot"
                        className="autoPilot"
                        value={selectedPilotoAutomatico}
                        onChange={val => this.updateSelectValue('selectedPilotoAutomatico', val.checked)}
                        thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                        trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                        disabled={!profileAdminOrMasterData}
                      />
                    </Col>
                    <Col xs={7} style={{ marginTop: '5px' }}>
                      <Switcher
                        name="AcionamentoTransporteCIF"
                        label={intl.get('master-data.general-configuration.triggerTransportCif')}
                        className="acionamentoCIF"
                        value={selectedAcionamentoTransporteCIF}
                        onChange={val => this.updateSelectValue('selectedAcionamentoTransporteCIF', val.checked)}
                        thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                        trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                        disabled={!profileAdminOrMasterData}
                      />
                    </Col>
                  </Row>

                <Row>
                  {usesOORRelevance() &&
                    <Col xs={5} style={{ marginTop: '5px' }}>
                      <Switcher
                        name="RelevanciaOOR"
                        label={intl.get('master-data.general-configuration.relevanceOOR')}
                        value={selectedOORRelevance}
                        className="relevanciaOOR"
                        onChange={val => this.updateSelectValue('selectedOORRelevance', val.checked)}
                        thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                        trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                        disabled={!profileAdminOrMasterData}
                      />
                    </Col>
                  }
                  <Col xs={7} style={{ marginTop: '5px' }}>
                    {usesTmsIntegration() &&
                      <Switcher
                        name="SaneamentoVolumesPendentes"
                        label={intl.get('master-data.general-configuration.cleanseBulkPending')}
                        value={selectedSaneamentoVolumesPendentes}
                        onChange={val => this.updateSelectValue('selectedSaneamentoVolumesPendentes', val.checked)}
                        thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                        trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                        disabled={!profileAdminOrMasterData}
                      />
                    }
                  </Col>
                </Row>
              </div>
            </>
          }
          <div className='footer-dialog-buttons__edit-labeled-family'>
            <Button
              value={intl.get('geral.buttonsDefault.cancel')}
              onClick={handleClose}
              type="default"
              className="buttons__edit-labeled-family"
            />
            {profileAdminOrMasterData &&
              <Button
                value={intl.get('geral.buttonsDefault.save')}
                type="primary"
                className='buttons__edit-labeled-family btn__save'
                onClick={this.save}
              />
            }
          </div>
          <SnackBar
            message={textMsg}
            open={showMsg}
            autoHideDuration={3000}
            onRequestClose={this.finalizeProccessEstoqueError}
            style={{ width: '100%', textAlign: 'center', display: 'block' }}
          />
        </Dialog>
      </div>
    )
  }
}

LabeledFamilyForm.propTypes = {
  idEdit: PropTypes.number,
  open: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  profileAdminOrMasterData: PropTypes.func.isRequired,
}

export default LabeledFamilyForm

import React, { useEffect, useState } from 'react'
import { SelectBox } from 'components/uikit-adapter/index'
import intl from 'react-intl-universal'
import { getCenters } from '../../../master-data/general-configuration/general/general-master-data.service'
import DeadlineControl from "components/deadline-control"
import * as S from './styled'
import IconFilterGray from 'images/icn-filter-gray.svg'
import { useLoadCompositionContext } from '../../context/loadCompositionContext'
import SideFilter from '../side-filter/index'
import { formatDateFromTo, getDateCacheFilter } from './utils'
import { useQuery } from '../../../../hooks/useQuery'
import { searchComposition } from '../../../composicao-carga/form/composicao-carga-form.service'
import { getDoorOptions, getValueDoor } from 'pages/master-data/unit-management/delivery-schedule-configuration/components/filter/utils'
import { initialValueDoor } from 'pages/master-data/unit-management/delivery-schedule-configuration/utils'

const LoadCompositionFilter = ({ searchLoadComposition, proccessErro, isEditMode, params }) => {
  const [filterSelected, setFilterSelected] = useState({
    dates: getDateCacheFilter(),
    center: null,
    door: null,
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [doors, setDoors] = useState([]);
  const [centers, setCenters] = useState([]);
  const handleChangeDates = (de, ate) => setFilterSelected(prevFilter => ({ ...prevFilter, dates: { de, ate } }));
  const handleChangeFilter = (prop, value) => setFilterSelected(prevFilter => ({ ...prevFilter, [prop]: value }));
  const query = useQuery();

  const {
    setFilters,
  } = useLoadCompositionContext();

  const openCloseFilter = () => setOpenFilter(prev => !prev);
  const applyFilter = (data) => {
    setFilters(prevState => ({
      ...prevState,
      viewColumns: [...data]
    }))
  }

  const loadCacheFilter = async () => {
    const idLoadComposition = query.get("code");
    if (idLoadComposition) {
      const loadComposition = await searchComposition(idLoadComposition);
      const generateDates = formatDateFromTo(loadComposition.DataEntrega, 7);
      handleChangeDates(generateDates.de, generateDates.ate);
      handleChangeFilter("center", { IdUnidadeNegocio: loadComposition.IdUnidadeNegocio, Descricao: `${loadComposition.Centro} - ${loadComposition.UnidadeNegocio}` })
    }
    else if (!isEditMode) {
      const infos = getStorageFilter();
      if (infos) {
        setFilterSelected({
          dates: { de: infos.dataInicial, ate: infos.dataFinal },
          center: infos.Center,
          door: infos.Door,
        })
        const _doors = getDoorOptions(infos.Center);
        setDoors(_doors);
      }
    }
  }

  const getStorageFilter = () => {
    return JSON.parse(localStorage.getItem("loadCompositionFilter"));
  }

  const loadFilters = async () => {
    await loadInfos();
    loadCacheFilter();
  }

  useEffect(() => {
    loadFilters();
  }, [])

  useEffect(() => {
    if (filterSelected.center) {
      searchLoadComposition(filterSelected.dates, filterSelected.center, filterSelected.door)
    }
  }, [filterSelected])

  const loadInfos = async () => {
    try {
      const _centers = await getCenters()
      setCenters(_centers)
      if (isEditMode) {
        loadFiltersEditMode(_centers);
      }
    }
    catch (e) {
      proccessErro(e)
    }
  }

  const loadFiltersEditMode = (_centers) => {
    const _selectedCenter = _centers.find(c => c.IdUnidadeNegocio == params.destinoId);
    const _doorsOptions = getDoorOptions(_selectedCenter);
    const filter = {
      dates: formatDateFromTo(params.dia, 7),
      center: _selectedCenter,
      door: _doorsOptions.find(x => x.label == query.get('doorDescription')),
    };
    setDoors(_doorsOptions);
    setFilterSelected(filter);
  }

  const loadDoors = (_centerSelected) => {
    const _doors = getDoorOptions(_centerSelected ?? filterSelected.center);
    setDoors(_doors);
  }

  const handleChangeCenter = (value) => {
    handleChangeFilter("center", value)
    handleChangeFilter("door", initialValueDoor)
    loadDoors(value)
  }

  const handleChangeDoor = (value) => {
    handleChangeFilter("door", value);
  }

  return (
    <S.Wrapper>
      <SelectBox
        name='brewery'
        label={intl.get('commons.brewery')}
        placeholder={intl.get('commons.brewery')}
        valueKey="IdUnidadeNegocio"
        labelKey="Descricao"
        value={filterSelected.center}
        onChange={value => handleChangeCenter(value)}
        options={centers}
        disabled={isEditMode}
        searchable
        openOnFocus
        data-testid='selectbox-center'
        width='225px'
      />
      <S.DeadlineControlWrapper>
        <SelectBox
          name='door'
          label={intl.get('commons.door')}
          valueKey="value"
          labelKey="label"
          value={getValueDoor(filterSelected)}
          onChange={value => handleChangeDoor(value)}
          options={doors}
          disabled={doors.length <= 1}
          searchable
          openOnFocus
          data-testid='selectbox-door'
          width='225px'
        />
      </S.DeadlineControlWrapper>
      <S.DeadlineControlWrapper>
        <span className="label">{intl.get("commons.period")}</span>
        <DeadlineControl
          TituloColeta=''
          TituloEntrega=''
          OnChange={(coleta, entrega) =>
            handleChangeDates(coleta, entrega)
          }
          Coleta={filterSelected.dates.de}
          DataEntregaDe={filterSelected.dates.ate}
          CadeadoDisabled
          className='dateDeadLineControl'
          noLockOption
          TransitTime={1}
          showSeparator
        />
      </S.DeadlineControlWrapper>
      <S.ButtonFilter
        onClick={openCloseFilter}
        type="default"
        icon={IconFilterGray}
        className='button-filter-icon'
      />
      <S.Button
        type='default'
        value={intl.get('loadComposition.filter.buttonTitle')}
      />
      <SideFilter
        open={openFilter}
        close={openCloseFilter}
        handleFilter={applyFilter}
      />
    </S.Wrapper>
  )
}

export default LoadCompositionFilter;

import React from 'react'
import PropTypes from 'prop-types'
import StylesLastUpdate from './stylesLastUpdate'

import intl from 'react-intl-universal'

const LastUpdate = ({ icon, title, text, dataText }) => (
  <StylesLastUpdate title={title}>
    <span className='text'>{intl.get('commons.lastUpdate') + ':'} <span style={{ paddingLeft: '5px' }} className="data">{dataText}</span></span>
  </StylesLastUpdate>
)

LastUpdate.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  dataText: PropTypes.string
}

LastUpdate.defaultProps = {
  title: '',
}

export default LastUpdate

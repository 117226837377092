import React, { Component } from 'react'
import Loading from 'components/center-loading'
import Session from 'utils/user-storage'
import { Dialog, Button, Input, SelectBox } from 'components/uikit-adapter/index'
import {
  searchUserById,
  searchAllBudgets,
  searchAllCoordinations,
  searchAllCountries,
  searchAllPerfilAcessos,
  searchAllUnidadeNegocio,
  searchStatusTypes,
  save
} from './user-form.service'

import intl from 'react-intl-universal'
import './user-form.css'
import { formatErrorMessage } from 'utils/handle-error';
import { UserLanguage } from 'pages/master-data/suppliers/supplier-group/supplier-group-form/utils/config-language'
import OptionGroup from 'components/option-group'
import TipoUsuario from 'models/usuarios/tipo-usuario'
const TipoPerfilPcpUnidade = 4;


class UserForm extends Component {
  constructor() {
    super()

    this.state = {
      user: {
        Coordination: {
          Id: "",
          Descricao: ""
        },
        Pais: 0,
        Idioma: 0,
      },
      listaPerfilAcessos: [],
      listaPaises: [],
      centers: [],
      budgets: [],
      coordinations: [],
      requestCount: 0,
      errors: {
        type: '',
      },
      optionsUserType: {
        id: 0,
        optionOne: {
          name: intl.get('UserType.types.Ambev'),
          value: TipoUsuario.Ambev,
          isChecked: true,          
        },
        optionTwo: {
          name: intl.get('UserType.types.System'),
          value: TipoUsuario.Sistema,
          isChecked: false,          
        }
      },
      typeUserSystem: 1
    }
  }
  changeOptionsUserType (valueChecked) {
    const typeUser = valueChecked;
  
    const optionsUserType = this.state.optionsUserType;
    optionsUserType.optionOne.isChecked = optionsUserType.optionOne.value == valueChecked;
    optionsUserType.optionTwo.isChecked = optionsUserType.optionTwo.value == valueChecked;
  
  
    this.setState({
      typeUserSystem: typeUser,
      optionsUserType
    })
  }
  componentDidMount() {
    const editMode = this.props.editMode

    if (editMode) {
      this.searchUserById()
    }
    this.searchAllBudgets()
    this.searchAllCoordinations()
    this.searchAllCountries()
    this.searchAllPerfilAcessos()
    this.searchAllUnidadeNegocio();
    this.searchStatusTypes();
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  searchUserById = async () => {
    try {
      this.handleFetch()
      const userId = this.props.idEdit
      const data = await searchUserById(userId)
      this.proccessData(data)
    } catch (error) {
      console.error(error);
    } finally {
      this.handleFetch(false);
    }
  }

  searchAllBudgets = async () => {
    try {
      this.handleFetch()
      const data = await searchAllBudgets()
      this.setState({ budgets: data })
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  searchAllCoordinations = async () => {
    try {
      this.handleFetch()
      const data = await searchAllCoordinations()
      this.setState({ coordinations: data })
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  searchAllCountries = async () => {
    try {
      this.handleFetch()
      const data = await searchAllCountries()
      this.setState({ listaPaises: data })
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  searchAllPerfilAcessos = async () => {
    try {
      this.handleFetch()
      let listaPerfilAcessos = await searchAllPerfilAcessos();
      this.setState({ listaPerfilAcessos });
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  searchAllUnidadeNegocio = async () => {
    try {
      this.handleFetch()
      const data = await searchAllUnidadeNegocio()
      this.setState({ centers: data })
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  searchStatusTypes = async () => {
    try {
      this.handleFetch()
      const data = await searchStatusTypes()
      this.setState({ listaStatusUsuario: data })
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  proccessError = (exception) => {
    try {
      const erro = formatErrorMessage(exception)
      this.props.handleFeedback(erro)
    } catch (e) {
      this.props.handleFeedback(intl.get('feedbacks.serverError'))
    }
  }

  proccessData = (data) => {
    const { Id, Nome, Email, PerfilAcesso, Pais, Idioma, Centros, Carteiras, IdCoordenacao, NomeCoordenacao, Status } = data

    this.setState({
      user: {
        Id: Id,
        Nome: Nome,
        Email: Email,
        PerfilAcesso: PerfilAcesso,
        Pais: Pais,
        Idioma: Idioma,
        Centers: Centros,
        Status: Status,
        Budget: Carteiras,
        Coordination: {
          Id: IdCoordenacao,
          Descricao: NomeCoordenacao
        }
      }
    })
  }

  changeValue = (event) => {
    const prop = event.currentTarget.name
    const value = event.currentTarget.value

    this.setState({
      user: {
        ...this.state.user,
        [prop]: value
      }
    })
  }

  onCloseClick = () => { this.props.handleClose() }

  updateBudgetSelectValue = (event) => {
    this.setState({
      user: {
        ...this.state.user,
        Budget: [...event]
      }
    })
  }

  updateCoordinationSelectValue = (value) => {
    this.setState({
      user: {
        ...this.state.user,
        Coordination: {
          ...this.state.user.Coordination,
          Id: value
        }
      }
    })
  }

  updatePerfilAcessosSelectValue(value) {
    this.setState({
      user: {
        ...this.state.user,
        PerfilAcesso: value
      }
    })
  }

  updatePaisSelectValue(value) {
    this.setState({
      user: {
        ...this.state.user,
        Pais: value
      }
    })
  }

  updateIdiomaSelectValue(value) {
    this.setState({
      user: {
        ...this.state.user,
        Idioma: value
      }
    })
  }

  updateCentersSelectValue = event => {
    this.setState({
      user: {
        ...this.state.user,
        Centers: [...event]
      }
    })
  };

  updateSelectValue = (prop, value) => {
    this.setState({
      user: {
        ...this.state.user,
        [prop]: value
      }
    })
  }

  save = async () => {
    const editMode = this.props.editMode
    const user = Session.get()
    const usuario = this.state.user

    const model = {
      Id: usuario.Id ? usuario.Id : 0,
      Nome: usuario.Nome,
      Email: Array.isArray(usuario.Email) ? usuario.Email : [usuario.Email],
      PerfilAcesso: usuario.PerfilAcesso,
      Pais: usuario.Pais,
      Idioma: usuario.Idioma,
      Centros: usuario.Centers ? usuario.Centers.map(x => ({ Id: x.Id })) : null,
      Carteiras: usuario.Budget ? usuario.Budget.map(x => ({ Id: x.Id })) : null,
      IdCoordenacao: usuario.Coordination.Id,
      Status: usuario.Status,
      Perfil: this.state.typeUserSystem
    };

    try {
      if (this.validateForm(model)) {
        this.handleFetch()
        await save(user.Id, model)

        localStorage.removeItem("settings")

        this.props.handleRefresh()
        this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`)
      }
    } catch (error) {
      this.proccessError(error);
    } finally {
      this.handleFetch(false);
    }
  }

  validateForm = (model) => {
    const regexEmail = /\S+@\S+\.\S+/;
    const regexGuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (!model.Nome) {
      this.validateFields(intl.get('feedbacks.messageUserForm4'))
      return false
    } else if (!model.Email[0]) {
      this.validateFields(intl.get('feedbacks.messageUserForm5'))
      return false
    } else if (!model.Pais) {
      this.validateFields(intl.get('feedbacks.messageUserForm6'))
      return false
    } else if (!model.Idioma) {
      this.validateFields(intl.get('feedbacks.messageUserForm7'))
      return false
    } else if (model.PerfilAcesso === TipoPerfilPcpUnidade && (!model.Centros || model.Centros.length === 0)) {
      this.validateFields(intl.get('feedbacks.messageUserForm8'))
      return false
    }else if(model.Perfil != TipoUsuario.Sistema && !regexEmail.test(model.Email)){      
      this.validateFields(intl.get('feedbacks.messageUserForm10'))
      return false      
    }else if(model.Perfil == TipoUsuario.Sistema && !regexGuid.test(model.Email)){
      this.validateFields(intl.get('feedbacks.messageUserForm11'))
      return false
    }      
    else {
      return true
    }
  }

  validateFields = (message) => {
    this.handleFetch(false)
    this.props.handleFeedback(message)
  }

  render() {
    const { user, requestCount, budgets, listaPerfilAcessos, centers, listaStatusUsuario, coordinations, listaPaises } = this.state
    const { open, editMode, profileAdminOrMasterData } = this.props


    return (
      <div>
        <Loading isLoading={requestCount > 0} />

        <Dialog
          title={editMode ? intl.get('master-data.coordinationsAndUsers.users.actions.editUser') : intl.get('master-data.coordinationsAndUsers.users.actions.newUser')}
          contentStyle={{ width: '600px' }}
          autoScrollBodyContent={true}
          open={open}
        >
          
            <OptionGroup 
              title={intl.get('UserType.title')}
              options={this.state.optionsUserType}
              disabled={false}
              handleSelect={(ev)=>this.changeOptionsUserType(ev.currentTarget.value)}/>
          
            <div className='line-1-columns'>
              <Input
                required
                name='Nome'
                label={intl.get('master-data.general-configuration.name')}
                value={user.Nome}
                onChange={this.changeValue}
                disabled={!profileAdminOrMasterData}
              />
            </div>

            <div className='line-1-columns'>
              <Input
                required
                name='Email'
                label={intl.get('commons.email')}
                value={user.Email}
                onChange={this.changeValue}
                disabled={!profileAdminOrMasterData}
              />
            </div>

            {profileAdminOrMasterData &&
              <SelectBox
                required
                label={intl.get('commons.accessProfile')}
                placeholder={intl.get('commons.accessProfile')}
                valueKey='Id'
                labelKey='Descricao'
                value={user.PerfilAcesso}
                options={listaPerfilAcessos}
                onChange={value => this.updatePerfilAcessosSelectValue(value === null ? '' : value.Id)}
                searchable
                openOnFocus
                clearable
              />
            }

            <SelectBox
              required
              className='country__selectbox'
              label={intl.get('commons.country')}
              placeholder={intl.get('commons.country')}
              valueKey='Key'
              labelKey='Value'
              value={user.Pais}
              options={listaPaises}
              onChange={value => this.updatePaisSelectValue(value === null ? '' : value.Key)}
              searchable
              openOnFocus
              clearable
            />

            <SelectBox
              required
              className='language__selectbox'
              label={intl.get('commons.language')}
              placeholder={intl.get('commons.language')}
              valueKey='Key'
              labelKey='Value'
              value={user.Idioma}
              options={UserLanguage}
              onChange={value => this.updateIdiomaSelectValue(value === null ? '' : value.Key)}
              searchable
              openOnFocus
              clearable
            />

            <SelectBox
              required
              label={intl.get(`manual-suggestion-order-form.unit`)}
              placeholder={intl.get(`manual-suggestion-order-form.unit`)}
              valueKey="Id"
              labelKey="Nome"
              value={user.Centers}
              options={centers}
              onChange={this.updateCentersSelectValue}
              searchable
              openOnFocus
              multi
            />

            <SelectBox
              required
              label={intl.get('master-data.general-configuration.coordination')}
              placeholder={intl.get('master-data.general-configuration.coordination')}
              valueKey='Id'
              labelKey='Descricao'
              value={user.Coordination.Id}
              options={coordinations}
              onChange={value => this.updateCoordinationSelectValue(value === null ? '' : value.Id)}
              disabled={!profileAdminOrMasterData}
              searchable
              openOnFocus
              clearable
            />

            <SelectBox
              required
              label={intl.get('commons.wallet')}
              placeholder={intl.get('commons.wallet')}
              valueKey='Id'
              labelKey='Descricao'
              value={user.Budget}
              options={budgets}
              onChange={this.updateBudgetSelectValue}
              disabled={!profileAdminOrMasterData}
              searchable
              openOnFocus
              multi
            />

            <SelectBox
              required
              label={intl.get('commons.status')}
              placeholder={intl.get('commons.status')}
              name='Status'
              value={user.Status}
              valueKey='Key'
              labelKey='Value'
              onChange={value => this.updateSelectValue('Status', value.Key)}
              openOnFocus={true}
              options={listaStatusUsuario}
              width="100%"
              disabled={!profileAdminOrMasterData}
              className="select-status"
            />

            <div className='footer-dialog-buttons'>
              <div className='line-buttons'>
                <Button
                  type="default"
                  value={intl.get('geral.buttonsDefault.cancel')}
                  onClick={this.onCloseClick}
                  className="button"
                />
                {profileAdminOrMasterData &&
                  <Button
                    type="primary"
                    value={intl.get('geral.buttonsDefault.save')}
                    className="button btn-save-user"
                    onClick={this.save}
                  />
                }
              </div>
            </div>
        </Dialog>
      </div>
    )
  }
}

export default UserForm

import React, { Component } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Input } from '@hbsis.uikit/react';
import { SelectBox } from "components/uikit-adapter/index"
import Dialog from 'material-ui/Dialog';
import SnackBar from 'material-ui/Snackbar';
import SuppliersList from './components/suppliers-list';
import MaterialSupplierForm from '../material-supplier-form';
import Loading from 'components/center-loading';
import DeleteModal from 'components/delete-dialog';
import intl from 'react-intl-universal';
import MaterialBusinessUnitFormStyled from "./material-business-unit-form.styled"
import {
  searchMessages,
  searchMaterialById,
  searchAllMaterials,
  save,
  buscaUsuariosAmbev
} from "./material-business-unit-form.service";
import DateInput from '../../../../../components/date-input';
import { formatErrorMessage } from 'utils/handle-error';
import Feature from '../../../../../components/feature';
import { getValueOrDefault } from 'utils/custom-functions';
import { Switcher } from 'components/toggle';
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting';
import TipoPerfilAcesso from 'models/usuarios/tipo-perfil-acesso';
import SessionUser from 'utils/user-storage'

class MaterialForm extends Component {
  constructor(props) {
    const NEW_SUPPLIER = { Id: 0, Nome: intl.get('master-data.general.table.actions.editLabeledFamilyMaterial.addProvider'), isModel: true };
    super()
    this.state = {
      labelNewSupplier: NEW_SUPPLIER,
      maxChessItems: 26,
      requestCounter: 0,
      showValidationMsg: false,
      textValidationMsg: '',
      openForm: false,
      openEditMode: false,
      openDeleteSupplier: false,
      deleteSupplierOrder: '',
      ordem: 0,
      edit: {},
      materials: [],
      usuarios: [],
      justificationMessages: [],
      materialUnidade: {
        MaterialAtivo: false,
        MaterialEstoque: false,
        DataDescontinuado: null,
        IdMaterial: 0,
        NomeMaterial: '',
        IdFamiliaRotuladaCentro: props.idLabeledFamilyCenter,
        IdUnidadeArredondamento: null,
        DescricaoUnidadeArredondamento: '',
        Fornecedores: [NEW_SUPPLIER],
        IdResponsavel: null,
        DeflationPercentage: 1,
        Deflator: false
      }
    }
  }

  componentDidMount() {
    const editMode = this.props.editMode
    if (editMode) {
      this.searchMaterialById()
    } else {
      this.searchAllMaterials()
    }

    this.buscaUsuariosAmbev()
    this.searchMessages()
  }

  userCanEditDeflation = () => {
    const editableUserProfiles = [
      TipoPerfilAcesso.Gestor,
      TipoPerfilAcesso.MasterData,
      TipoPerfilAcesso.Administrador
    ]
    return editableUserProfiles.includes(SessionUser.get().PerfilAcesso);
  }

  proccessMessageError = (mensagem) => {
    this.setState({
      showValidationMsg: true,
      textValidationMsg: mensagem
    })
  }

  finalizeProccessError = () => {
    this.setState({
      showValidationMsg: false,
      textValidationMsg: ''
    })
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCounter: isFetching ? prevState.requestCounter + 1 : prevState.requestCounter - 1,
    }))
  }

  updateSelectedMaterial = (material) => {
    this.updateSelectValue('IdMaterial', material.Id);
    this.updateSelectValue('IdUnidadeArredondamento', material.IdUnidadeArredondamento);
    this.updateSelectValue('DescricaoUnidadeArredondamento', material.DescricaoUnidadeArredondamento);
  }

  updateSelectValue = (prop, value) => {
    let { materialUnidade } = this.state
    materialUnidade[prop] = value;
    this.setState({
      materialUnidade
    }, () => this.validaMaterialAtivoEstoque(prop, value))
  }


  validaMaterialAtivoEstoque = (prop, value) => {
    if (prop == 'MaterialAtivo' && value) {
      this.updateSelectValue('MaterialEstoque', true)
    }
    if (prop == 'MaterialEstoque' && !value) {
      this.updateSelectValue('MaterialAtivo', false)
    }
  }

  searchAllMaterials = async () => {
    try {
      this.handleFetch()
      const { IdFamiliaRotuladaCentro } = this.state.materialUnidade
      const data = await searchAllMaterials(IdFamiliaRotuladaCentro)
      this.setState({ materials: data })
    } catch (e) {
      this.proccessErro(e)
    } finally {
      this.handleFetch(false)
    }
  }

  searchMaterialById = async () => {
    try {
      this.handleFetch()
      const idMaterial = this.props.idEdit
      const data = await searchMaterialById(idMaterial)
      this.proccessData(data);
    } catch (e) {
      this.proccessErro(e)
    } finally {
      this.handleFetch(false)
    }
  }

  buscaUsuariosAmbev = async () => {
    try {
      this.handleFetch()
      const data = await buscaUsuariosAmbev()
      this.setState({ usuarios: data })
    } catch (e) {
      this.proccessErro(e)
    } finally {
      this.handleFetch(false)
    }
  }

  proccessData = (data) => {

    if (data.Fornecedores.length < this.state.maxChessItems) {
      data.Fornecedores = [...data.Fornecedores, this.state.labelNewSupplier]
    }
    else {
      data.Fornecedores = [...data.Fornecedores]
    }

    this.setState({
      materialUnidade: data
    })
  }

  openForm = () => {
    this.setState({
      openForm: true
    })
  }

  openEditForm = (row) => {
    const selected = this.state.materialUnidade.Fornecedores[row]
    this.setState({
      openEditMode: !!selected.Id,
      edit: selected,
      ordem: row
    }, () => this.openForm())
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDeleteSupplier = (index) => {
    this.setState({
      openDeleteSupplier: true,
      deleteSupplierOrder: index
    })
  }

  closeDeleteSupplier = () => {
    this.setState({
      openDeleteSupplier: false
    })
  }

  setSupplier = (index, supplier) => {
    let { materialUnidade } = this.state
    materialUnidade.Fornecedores[index] = supplier;
    if (materialUnidade.Fornecedores.length < this.state.maxChessItems) {
      materialUnidade.Fornecedores = Array.from(new Set([...materialUnidade.Fornecedores, this.state.labelNewSupplier]))
    }
    else {
      materialUnidade.Fornecedores = [...materialUnidade.Fornecedores]
    }

    this.setState({
      materialUnidade
    })
  }

  confirmDeleteSupplier = () => {
    let { materialUnidade, deleteSupplierOrder } = this.state

    let showAdd = materialUnidade.Fornecedores[materialUnidade.Fornecedores.length - 1].IdFornecedor > 0;
    materialUnidade.Fornecedores.splice(parseInt(deleteSupplierOrder), 1)

    if (showAdd)
      materialUnidade.Fornecedores = Array.from(new Set([...materialUnidade.Fornecedores, this.state.labelNewSupplier]))
    else
      materialUnidade.Fornecedores = [...materialUnidade.Fornecedores]

    this.setState({
      deleteSupplierOrder: '',
      materialUnidade
    }, this.closeDeleteSupplier)
  }

  save = async () => {
    const { editMode } = this.props
    let { materialUnidade } = this.state

    if (this.validateFields()) {
      try {
        this.handleFetch()
        await save({
          ...materialUnidade,
          Fornecedores: materialUnidade.Fornecedores.filter(x => !x.isModel),
          DeflationPercentage: getValueOrDefault(materialUnidade.DeflationPercentage, 1)
        })
        this.props.handleRefresh()
        this.props.handleFeedback(`${intl.get('feedbacks.register')} ${!editMode ? intl.get('feedbacks.saved') : intl.get('feedbacks.updated')} ${intl.get('feedbacks.withSuccess')}`)
      } catch (e) {
        this.proccessErro(e)
      } finally {
        this.handleFetch(false);
      }
    } else {
      this.handleFetch(false)
    }
  }

  validateFields = () => {
    let isValid = true
    const { materialUnidade } = this.state
    if (!materialUnidade.IdMaterial) {
      this.proccessMessageError(intl.get('feedbacks.messageMaterialBusiness1'))
      isValid = false
    } else if (materialUnidade.DataDescontinuado && materialUnidade.DataDescontinuado < Moment().format("YYYY-MM-DD")) {
      this.proccessMessageError(intl.get('feedbacks.messageMaterialBusiness2'))
      isValid = false
    } else if (!!materialUnidade.DataDescontinuado && !materialUnidade.JustificativaDescontinuado) {
      this.proccessMessageError(intl.get('feedbacks.messageMaterialBusiness3'))
      isValid = false
    } else if (materialUnidade.Fornecedores.length <= 1) {
      this.proccessMessageError(intl.get('feedbacks.messageMaterialBusiness4'))
      isValid = false
    }
    return isValid
  }

  proccessErro = (exception) => {
    const erro = formatErrorMessage(exception)
    this.props.handleFeedback(erro)
  }

  updateSuppliers = (Fornecedores) => {
    let { materialUnidade } = this.state
    materialUnidade.Fornecedores = Fornecedores

    this.setState({
      materialUnidade
    })
  }

  searchMessages = async () => {
    try {
      this.handleFetch()
      const data = await searchMessages()
      this.proccessDataMessages(data)
    } catch (e) {
      this.proccessErro(e)
    } finally {
      this.handleFetch(false)
    }
  }

  proccessDataMessages = (data) => {
    this.setState({
      justificationMessages: data
    })
  }

  handleChangePercentageValue = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
    const regexMaxValue100 = /^[1-9]\d?$|^100$/;
    if (value === '' || regexMaxValue100.test(value)) {
      this.updateSelectValue('DeflationPercentage', parseInt(value))
    }
  }

  render() {
    const { materialUnidade, materials, openForm, openEditMode, edit, requestCounter, justificationMessages, usuarios } = this.state
    const { open, editMode, handleClose } = this.props
    const Fornecedores = materialUnidade.Fornecedores;
    const itemsCount = Fornecedores.length - 1
    const suppliersSelecteds = Fornecedores.map(f => f.IdFornecedor)

    return (
      <div>
        <SnackBar
          message={this.state.textValidationMsg}
          open={this.state.showValidationMsg}
          autoHideDuration={3000}
          onRequestClose={this.finalizeProccessError}
        />
        <Dialog
          title={editMode ? intl.get('master-data.general.table.actions.updateLabeledFamilyCenterMaterial')
            : intl.get('master-data.general.table.actions.newLabeledFamilyCenterMaterial')}
          contentStyle={{ width: '600px' }}
          open={open}
          autoScrollBodyContent
        >
          <MaterialBusinessUnitFormStyled>
            {editMode &&
              <Input
                name={intl.get('master-data.general-configuration.activeMaterial')}
                label={intl.get('master-data.general-configuration.activeMaterial')}
                required
                value={materialUnidade.NomeMaterial}
                disabled
              />
            }
            {!editMode &&
              <SelectBox
                required
                searchable
                label="Material"
                placeholder={intl.get('master-data.general-configuration.activeMaterial')}
                valueKey='Id'
                labelKey='MaterialPesquisa'
                name='IdMaterial'
                value={materialUnidade.IdMaterial}
                openOnFocus={true}
                options={materials}
                autoload={false}
                onChange={val => this.updateSelectedMaterial(val)}
                width="100%"
                className='selected-box-material'
              />
            }

            <SelectBox
              required
              searchable
              label={intl.get('commons.responsible')}
              placeholder={intl.get('master-data.general.table.actions.editMaterial.table.header.responsible')}
              valueKey='Id'
              labelKey='Nome'
              name='IdResponsavel'
              value={materialUnidade.IdResponsavel}
              openOnFocus={true}
              options={usuarios}
              autoload={false}
              onChange={val => this.updateSelectValue('IdResponsavel', val === null ? null : val.Id)}
              width="100%"
              className="select-box-responsavel"
              clearable
            />

            <div className="line-2-columns-form">
              <div className="column">
                <Switcher
                  name="Ativos"
                  label={intl.get('master-data.general.table.actions.editMaterial.table.header.active')}
                  value={materialUnidade.MaterialAtivo}
                  onChange={val => this.updateSelectValue('MaterialAtivo', val.checked)}
                  thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                  trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                  className='toggle-material-ativo'
                />
              </div>
              <div className="column">
                <Switcher
                  name="Estoque"
                  label={intl.get('master-data.general.table.actions.editMaterial.table.header.production')}
                  value={materialUnidade.MaterialEstoque}
                  onChange={val => this.updateSelectValue('MaterialEstoque', val.checked)}
                  width="48%"
                  thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                  trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                  className='toggle-material-estoque'
                />
              </div>
            </div>
            <div className="line-2-columns-form">
              <div className="column">
                <Switcher
                  name="deflation"
                  label={intl.get('commons.deflator')}
                  value={materialUnidade.Deflator}
                  onChange={val => this.updateSelectValue('Deflator', val.checked)}
                  width="48%"
                  thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                  trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                  className='toggle-material-Deflator'
                  disabled={!this.userCanEditDeflation()}
                />
              </div>
              <Feature hideFlags={GeneralSettingParameter.LoadCompositionNewFlow}>
                <div className="column">
                  <Switcher
                    name="OnlyTransferOrder"
                    label={intl.get('master-data.general.table.actions.editMaterial.table.header.onlyTransferOrder')}
                    value={materialUnidade.OnlyTransferOrder}
                    onChange={val => this.updateSelectValue('OnlyTransferOrder', val.checked)}
                    width="48%"
                    thumbSwitchedStyle={{ backgroundColor: '#2870b2' }}
                    trackSwitchedStyle={{ backgroundColor: '#e7f0f7' }}
                    className='toggle-material-OnlyTransferOrder'
                  />
                </div>
              </Feature>
            </div>
            <div className="line-2-columns-form">
              <div className="column">
                <Input
                  name={intl.get('commons.deflationPercentage')}
                  label={intl.get('commons.deflationPercentage')}
                  value={materialUnidade.DeflationPercentage}
                  onChange={this.handleChangePercentageValue}
                  htmlType='number'
                  disabled={(!materialUnidade.Deflator) || !this.userCanEditDeflation()}
                />
              </div>
            </div>
            <div className="line-2-columns-form">
              <div className="column">
                <DateInput
                  label={intl.get('master-data.general.table.actions.editLabeledFamilyMaterial.discontinuedIn')}
                  value={materialUnidade.DataDescontinuado
                    ? Moment(materialUnidade.DataDescontinuado).format('YYYY-MM-DD')
                    : null}
                  onChange={(date) => this.updateSelectValue('DataDescontinuado', date)}
                  minDate={Moment().format("YYYY-MM-DD")}
                  canClear
                  className="data-descontinuado-picker"
                />
              </div>
              <div className="column">
                <SelectBox
                  clearable
                  searchable
                  openOnFocus
                  label={intl.get('master-data.general.table.actions.editLabeledFamilyMaterial.reason')}
                  placeholder={intl.get('master-data.general.table.actions.editLabeledFamilyMaterial.reason')}
                  valueKey='Id'
                  labelKey='Descricao'
                  name='JustificativaDescontinuado'
                  value={materialUnidade.JustificativaDescontinuado}
                  options={justificationMessages}
                  onChange={val => this.updateSelectValue('JustificativaDescontinuado', val === null ? null : val.Id)}
                  width="100%"
                  className="select-box-justificativa"
                />
              </div>
            </div>

            <label className="label-table">{intl.get('master-data.general.table.actions.editLabeledFamilyMaterial.chessProvider')} {itemsCount > 0 ? "(" + itemsCount + ")" : ""}</label>

            <div className="suppliers-list-container">
              <SuppliersList
                Fornecedores={Fornecedores}
                updateSuppliers={this.updateSuppliers}
                openForm={this.openEditForm}
                deleteItem={this.openDeleteSupplier}
              />
            </div>
            <hr className="line-separator" />
            <div className="footer-dialog-buttons">
              <Button
                type="default"
                value={intl.get('geral.buttonsDefault.cancel')}
                onClick={() => { handleClose() }}
                className="button btn-close"
              />
              <Button
                type="primary"
                value={intl.get('geral.buttonsDefault.save')}
                className="button btn-save"
                onClick={this.save}
              />
            </div>
          </MaterialBusinessUnitFormStyled>
          <Loading isLoading={requestCounter > 0} fullHeightParent />
        </Dialog>

        {openForm &&
          <MaterialSupplierForm
            idMaterial={this.state.materialUnidade.IdMaterial}
            horizonteProgramacao={this.props.horizonteProgramacao}
            possuiQuebraEmEntregas={this.props.possuiQuebraEmEntregas}
            idUnidadeArredondamento={this.state.materialUnidade.IdUnidadeArredondamento}
            descricaoUnidadeArredondamento={this.state.materialUnidade.DescricaoUnidadeArredondamento}
            suppliersSelecteds={suppliersSelecteds}
            ordem={this.state.ordem}
            edit={edit}
            open={openForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            setSupplier={this.setSupplier}
            idLabeledFamilyCenter={materialUnidade.IdFamiliaRotuladaCentro}
            periodicity={materialUnidade.Periodicity}
            handleFeedback={this.props.handleFeedback}
          />
        }

        <DeleteModal
          open={this.state.openDeleteSupplier}
          title={intl.get('master-data.general.materials.supplier.title')}
          handleClose={this.closeDeleteSupplier}
          warningMessage={intl.get('master-data.general.materials.description')}
          handleConfirm={this.confirmDeleteSupplier}
        />
      </div >
    )
  }
}

MaterialForm.propTypes = {
  idLabeledFamilyCenter: PropTypes.number,
  idEdit: PropTypes.number,
  horizonteProgramacao: PropTypes.number.isRequired,
  possuiQuebraEmEntregas: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  editMode: PropTypes.bool.isRequired,
  handleFeedback: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default MaterialForm

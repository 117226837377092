import React, { useEffect, useState } from 'react'
import intl from 'react-intl-universal'
import { withStyles } from '@material-ui/styles'
import { Button, Input } from '@hbsis.uikit/react'
import DateRange from 'components/date-range'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { OrdersChangeDateTable } from './table'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { ISO_DATE_FORMAT } from 'utils/format-date'
import moment from 'moment'
import * as S from './styled'
import { parseFloatByLocale } from 'locales/utils'

const StyledDialogContent = withStyles(() => ({
  root: {
    borderBottom: 'none'
  }
}))(DialogContent)

export const OrdersChangeDateModal = ({ open, close, data }) => {
  const { startOperation, showFeedback } = useGlobalContext()

  const [alterableItems, setAlterableItems] = useState([])
  const [dates, setDates] = useState({
    collect: null,
    delivery: null
  })

  const [notes, setNotes] = useState('')
  const [updateQuantity, setUpdateQuantity] = useState(false);

  const handleNewCollectDate = (collect) =>
    setAlterableItems(alterableItems.map((row) =>
    ({
      ...row,
      newCollectDate: collect,
      newDeliveryDate: moment(collect).add(row.TransitTime, 'd')
    })))

  const handleNewDeliveryDate = (delivery) =>
    setAlterableItems(alterableItems.map((row) =>
    ({
      ...row,
      newCollectDate: moment(delivery).subtract(row.TransitTime, 'd'),
      newDeliveryDate: delivery
    })))

  const handleNewQuantity = (newOrderQuantity, index) => {
    setAlterableItems(alterableItems.map((row, _index) => ({
      ...row,
      newOrderQuantity: index === _index ? newOrderQuantity : alterableItems[_index].newOrderQuantity 
    })))
  }

  const cleanDates = () =>
    setAlterableItems(alterableItems.map(row => ({ ...row, newCollectDate: null, newDeliveryDate: null })))


  const clearInputs = () => {
    setDates({ collect: null, delivery: null })
    setNotes('')
    setUpdateQuantity(false)
  }

  const handleClose = () => {
    clearInputs()
    close()
  }

  const validateNewData = () => {
    const isPickUpDateValid = !alterableItems.find((x) => moment(x.newCollectDate).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD"));
    const isQuantityValid = !alterableItems.find((x) => parseFloatByLocale(x.newOrderQuantity) === 0);

    let _errorMessage = '';

    if(!isPickUpDateValid) {
      _errorMessage = intl.get("feedbacks.messageNewPickupDateValidation2");
    } else if (!isQuantityValid) {
      _errorMessage = intl.get("feedbacks.messageNewQuantityValidation");
    }

    return {
      errorMessage: _errorMessage,
      result: isPickUpDateValid && isQuantityValid
    }
  }

  const handleSave = () => {
    const arePickUpDateAndQuantityValids = validateNewData();
    
    if(arePickUpDateAndQuantityValids.result) {
      let requests = alterableItems
        .map(item => {
          let _body = { OrderId: item.Id };
  
          if(item.newCollectDate || item.newDeliveryDate) _body = { ..._body, PickupDate: moment(item.newCollectDate).format(), DeliveryDate: moment(item.newDeliveryDate).format() };
          if(notes) _body = { ..._body, Notes: notes };
          if(item.newOrderQuantity) _body = { ..._body, Quantity: parseFloatByLocale(item.newOrderQuantity) };
  
          if(Object.keys(_body).length === 1) return;
          
          return {
          API: `orders/${item.Id}:update-dates`,
          method: 'patch',
          body: _body,
          errorId: item.Id
          }
        });
  
      requests = requests.filter(item => item);
      const text = intl.get('orders.operationText01')
      startOperation(requests, text)
      clearInputs()
      close()
    } else {
      showFeedback(arePickUpDateAndQuantityValids.errorMessage);
    }
  }

  useEffect(() => {
    if (dates.collect) {
      handleNewCollectDate(dates.collect)
    }
    else if (dates.delivery) {
      handleNewDeliveryDate(dates.delivery)
    } else {
      cleanDates()
    }
  }, [dates])

  useEffect(() => {
    setAlterableItems(data)
  }, [data])

  useEffect(() => {
    const hasQuantityChanged = alterableItems.find(x => x.newOrderQuantity && parseFloatByLocale(x.newOrderQuantity) !== 0);
    setUpdateQuantity(!!hasQuantityChanged)
  }, [alterableItems])

  return (
    <Dialog
      PaperProps={{
        style: { overflow: 'visible' }
      }}
      fullWidth
      maxWidth='lg' //previous value: md
      open={open}
    >
      <S.DialogTitle>
        <S.DialogTitleText>
          {intl.get('orders.changeOrder')}
        </S.DialogTitleText>
        <S.DialogTitleIcon onClick={handleClose} />
      </S.DialogTitle>
      <StyledDialogContent style={{ overflow: 'visible' }} dividers>
        <S.DialogContentText>{intl.get('orders.selectedOrders')}:</S.DialogContentText>
        <OrdersChangeDateTable
          data={alterableItems}
          handleNewQuantity={handleNewQuantity}
        />
        <S.DialogContentSection>
          <div className='dialog__content--text'>
            <h4>{intl.get('orders.operationHeader')}</h4>
            <p>{intl.get('orders.operationContent')}</p>
          </div>
          <div className='dialog__content--action'>
            <DateRange
              labelDe={intl.get('stocks.informAdvanceOrder.newCollectionDate')}
              labelAte={intl.get('stocks.informAdvanceOrder.newDeliveryDate')}
              dataDe={dates.collect}
              dataAte={dates.delivery}
              onChangeDataDe={(date) => setDates({ collect: date, delivery: null })}
              onChangeDataAte={(date) => setDates({ collect: null, delivery: date })}
              minDate={moment().format(ISO_DATE_FORMAT)}
            />
            <Input
              label={intl.get('commons.observation')}
              value={notes}
              onChange={(event) => setNotes(event.target.value)}
              data-testid='input-note'
            />
          </div>
        </S.DialogContentSection>
      </StyledDialogContent>
      <S.DialogActions>
        <Button
          type='default'
          value={intl.get('geral.buttonsDefault.cancel')}
          onClick={handleClose}
        />
        <Button
          type='primary'
          value={intl.get('commons.confirmUpdate')}
          onClick={handleSave}
          disabled={(!dates.collect && !dates.delivery && !updateQuantity)}
        />
      </S.DialogActions>
    </Dialog>
  )
}

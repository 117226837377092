import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;

  span{
    cursor: auto;
  }

  .table-row-data {
    box-shadow: inset 4px 0 ${props => props.borderColor ? props.borderColor : '#f9575b'};
    height: 55px;
    font-size: 0.8rem;
    background: #FFFFFF;
    padding: 5px 0;
    cursor: pointer;
    min-height: 60px;
    max-height: 60px;
    padding-left: 4px;
    border-bottom: 1px dashed #c5d0e1;
    text-decoration: none;
    color: inherit;
    display: table-row-group;
    &:hover{
      background-color: #F4F5F8;
    }
  }

  .icon-label-list-vision{
    justify-content: center;
  }
`

export const TableRow = styled.tr`
  box-shadow: inset 4px 0 #f9575b;
  height: 55px;
  font-size: 0.8rem;
  background: #FFFFFF;
  padding: 5px 0;
  min-height: 60px;
  max-height: 60px;
  padding-left: 4px;
  border-bottom: 1px dashed #c5d0e1;
  position: sticky;
  top: -1px;
  z-index: 1;
  &:hover{
    background-color: #F4F5F8;
  }
`

export const TableHeader = styled.th`
  padding: 8px;
  text-align: center;
  margin: 0px;
  font-size: 0.7rem;
  color: #9ba2ac;
  background: #FFF;
  border-bottom: 1px dashed #c5d0e1;
  text-transform: uppercase;
  font-weight: 500;

  .firstHead{
    margin-left: 20px;
  }
`

export const TableData = styled.td`
  padding: 3px;
  text-align: center;
  vertical-align: middle;

  .red-background{
   background-color : #fcdfdf;
  }

  .yellow-background {
    background-color: #fff2d2;
  }

  ${props => props.displayTableCell && 'display: table-cell;'}
  ${props => props.disabled && 'pointer-events: none;'}
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
`

import React from 'react'
import ShopSuggestionMesterCard from 'components/card/shop-suggestion/mester'
import { Grid } from './styled'
import { userFornecedor } from 'services/permissions/getPermissions'
import { buildCard } from '../../utils'

const OrdersCards = ({ data }) => (
  <Grid>
    {
      data.map(item => buildCard(item)).map((order) => (
        <ShopSuggestionMesterCard
          key={order.Id}
          userCard={userFornecedor() ? 'SUPPLIER' : 'AMBEV'}
          dataCard={order}
        />
      ))
    }
  </Grid>
)

export default OrdersCards

import React from 'react'
import InputMultiEmail from 'components/Adapters/input-multi-email'
import { isValidValue } from './validate'

export const InputMulti = ({ value, ...rest }) => (
  <InputMultiEmail
    emails={value}
    validateEmail={(_value) => !!isValidValue(_value)}
    {...rest}
  />
);

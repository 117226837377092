import React from 'react'
import TableList from '../../../../components/table-list';
import { builderSuggestionList } from '../../utils';
import { Columns } from './columns';
import * as S from './styled'

const ListSuggestion = ({ data }) => (
  <S.Wrapper>
    <TableList
      selected={[{}]}
      columns={Columns()}
      data={data.map(x => builderSuggestionList(x))}
    />
  </S.Wrapper>
)

export default ListSuggestion;

import { useRef, useState } from 'react';
import { Button } from '@material-ui/core';
import { Menu } from '../navbar/menu';
import { Link } from 'react-router-dom';
import MenuItem from "@material-ui/core/MenuItem";

import * as S from '../../styled';

export const NavbarSubmenus = ({ label, submenus, idx, baseUrl }) => {
  const [open, setOpen] = useState(null);
  const refsMenu = useRef([]);
  const handleClose = () => setOpen(null);
  const validateIsCurrentePage = () => submenus.some(x => x.path === baseUrl);

  return (
    <>
      <S.ContainerMenu onClick={() => setOpen(label)} isCurrentPage={validateIsCurrentePage()}>
        <Button ref={_ref => refsMenu.current[idx] = _ref} style={S.style}>
          {label}
        </Button>
      </S.ContainerMenu>
      <Menu
        anchorEl={refsMenu.current[idx]}
        open={open === label}
        onClose={handleClose}
        keepMounted
      >
        {submenus.map((subMenus, idxSubMenus) =>
          subMenus.visible && (
            <Link
              to={`/${subMenus.path}`}
              style={{ textDecoration: "none" }}
              key={idxSubMenus}
            >
              <MenuItem
                style={{ color: "#FFF", fontSize: "13px" }}
                onClick={handleClose}
              >
                {subMenus.label}
              </MenuItem>
            </Link>
          )
        )}
      </Menu>
    </>
  )
}

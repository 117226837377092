import styled from 'styled-components'
import { Button } from 'components/uikit-adapter/index'

export const WrapperHeader = styled.div`
  display: flex;
  align-items: center;
  box-shadow: rgb(175 206 243) 4px 0px inset;
  height: 40px;
  border-top: 1px solid rgb(197,208,225);
  border-bottom: 1px solid rgb(197,208,225);
  border-right: 1px solid rgb(197,208,225);
  background-color: rgb(250,250,252);
  padding-left: 10px;

  > div {
    margin: 0px 15px 0px 5px;
  }

  h1{
    font-size: 14px;
  }

  .btn-icon{
    width: 30px;
    height: 30px;
  }

  .btn-wrapper{
    display: flex;
    align-items: center;

    span{
      cursor: pointer;
    }

    img{
      width: 20px !important;
    }
  }

  .separatordiv{
    font-size: 20px;
    margin: 0px 10px 0px 10px!important;
  }
`

export const Info = styled.span`
padding: 5px;
left: 0%;
right: 90.99%;
top: 26.67%;
bottom: 26.67%;

font-family: Proxima Nova;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 100%;

display: flex;
align-items: center;
text-transform: capitalize;

color: #2870B2;
`

export const ButtonGenerate = styled(Button)`
  height: 90%;
  margin-right: 10px;
`

import styled from 'styled-components'

const SubMenuIndicator = styled.svg`
  width: 9px;
  height: 6px;

  >polygon {
    fill: transparent;
    stroke: ${p => p.open ? "#6197c6" : "#c5d0e1"};
    stroke-width: 1px;
    transition: stroke 300ms ease-in-out;
  }
`
export default SubMenuIndicator

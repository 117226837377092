import styled from 'styled-components'

const StyledTextInput = styled.div`

.select-origem-row {
  margin: 10px 25px 6px 8px;
}
.select-origem-label {
  color: #3b495e;
  font-size: 12px; 
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 10px;
}

.select-origem .radio-items-list .radio-item {
  margin: 0;
}

.select-origem .radio-items-list .radio-item input[type="radio"] + label {
  color: #3b495e;
  font-size: 12px; 
  font-weight: 600;
  text-transform: uppercase;
  margin-top:3px;
}

.select-origem .radio-items-list .radio-item input[type="radio"] + label:before {
  width: 5px;
  height: 5px;
}

#Options0_0{
  display: none;
}
`

export default StyledTextInput

import React from "react"
import PropTypes from 'prop-types'
import { Row, Col } from "react-flexbox-grid";
import Dialog from "material-ui/Dialog"
import { Button } from "@hbsis.uikit/react"
import TitleHeader from 'components/title-header'
import LogTable from './log-table'
import intl from 'react-intl-universal'

import './modal-log-integracao.css'

const ModalLogIntegracao = ({ openModalLogIntegracao, toggleOpenModalLogIntegracao, integracao }) => {

    return (
      <Dialog
        contentStyle={{ maxWidth: "85%" }}
        open={openModalLogIntegracao}
        onRequestClose={toggleOpenModalLogIntegracao}
        
      >
        <TitleHeader 
          className="headerModal" color="black">{integracao.DescricaoTipoIntegracao}
        </TitleHeader>

        <LogTable
          logs={integracao.Logs}
        />
        <Row>
          <Col xsOffset={9} xs={3} className="footer-dialog-buttons">
            <Button
              type="primary"
              htmlType="button"
              value={intl.get('geral.buttonsDefault.close')}
              onClick={() => toggleOpenModalLogIntegracao(false)}
              className="button"
            />
          </Col>
        </Row>
      </Dialog>
    )
}


ModalLogIntegracao.propTypes = {
  openModalLogIntegracao: PropTypes.bool.isRequired,
  toggleOpenModalLogIntegracao: PropTypes.func.isRequired,
  integracao: PropTypes.object.isRequired
}

export default ModalLogIntegracao;

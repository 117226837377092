import Fetch from "utils/fetch";

export const getIntegracoes = async () => {
    const response = await Fetch.get(`/integracao/obterIntegracao`);
    return response.data;
};

export const getWallets = async () => {
    const response = await Fetch.get(`/carteira/todas`);
    return response.data;
};

export const getLabeledFamilies = async () => {
    const response = await Fetch.get(`/familiaRotulada/obterFamiliasRotuladasCadastradasNoGeral`);
    return response.data;
};





import React from 'react'
import {
  Table as T,
  TableBody,
  TableHeader,
  TableRow,
  TableRowColumn
} from 'material-ui/Table'
import intl from 'react-intl-universal'
import './style.css'
import moment from 'moment'

const Table = ({ data }) => {

  return (
    <T
      height='900px'
      wrapperStyle={{ height: '600px' }}
      bodyStyle={{ height: 'calc(100% - 60px)' }}
      fixedHeader
      selectable={false}
      multiSelectable={false}
    >
      <TableHeader
        displaySelectAll={false}
        adjustForCheckbox={false}
        enableSelectAll={false}
      >
        <TableRow>
          <th style={{ textAlign: 'center', fontWeight: 'normal', fontSize: '12px', color: 'rgb(158, 158, 158)' }}>
            {intl.get('commons.source')}
          </th>
          <th style={{ textAlign: 'center', fontWeight: 'normal', fontSize: '12px', color: 'rgb(158, 158, 158)' }}>
            <span>{intl.get('commons.destiny')}</span>
          </th>
          <th style={{ textAlign: 'center', fontWeight: 'normal', fontSize: '12px', color: 'rgb(158, 158, 158)' }}>
            {intl.get('commons.material')}
          </th>
          <th style={{ textAlign: 'center', fontWeight: 'normal', fontSize: '12px', color: 'rgb(158, 158, 158)' }}>
            {intl.get('commons.status')}
          </th>
          <th className='headerColumn'>
            {intl.get('commons.rule')}
          </th>
          <th className='headerColumn'>
            {intl.get('commons.stage')}
          </th>
          <th className='headerColumn'>
            {intl.get('commons.documentNumber')}
          </th>
          <th className='headerColumn'>
            {intl.get('commons.emitDate')}
          </th>
          <th className='headerColumn'>
            {intl.get('commons.accessKey')}
          </th>
        </TableRow>
      </TableHeader>
      <TableBody
        displayRowCheckbox={false}
        deselectOnClickaway={false}
        showRowHover
        stripedRows={false}
      >
        {
          data.map((row, index) => (
            <TableRow key={index}>
              <TableRowColumn className='tableRow__infos' title={`${Number(row.CodigoFornecedor).toString()} - ${row.Apelido}`}>
                {`${Number(row.CodigoFornecedor).toString()} - ${row.Apelido}`}
              </TableRowColumn>
              <TableRowColumn className='tableRow__infos' title={row.Destino}>{row.Destino}</TableRowColumn>
              <TableRowColumn className='tableRow__infos' title={`${Number(row.CodigoMaterial).toString()} - ${row.NomeMaterial}`}>
                {`${Number(row.CodigoMaterial).toString()} - ${row.NomeMaterial}`}
              </TableRowColumn>
              <TableRowColumn className='tableRow__infos' title={row.Status}>{row.Status}</TableRowColumn>
              <TableRowColumn className='tableRow__infos' title={row.Regra}>{row.Regra}</TableRowColumn>
              <TableRowColumn className='tableRow__infos' title={row.Etapa}>{row.Etapa}</TableRowColumn>
              <TableRowColumn className='tableRow__infos' title={row.NumeroDocumento} >{row.NumeroDocumento}</TableRowColumn>
              <TableRowColumn className='tableRow__infos' title={moment(row.DataEmisao).format('L')}>{moment(row.DataEmisao).format('L')}</TableRowColumn>
              <TableRowColumn className='tableRow__infos' title={row.ChaveAcesso}>{row.ChaveAcesso}</TableRowColumn>
            </TableRow>
          ))}
      </TableBody>
    </T>
  )
}

export default Table

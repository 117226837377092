import styled from "styled-components";

export const DialogHeader = styled.div`
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    color: #3B495E;
    font-size: 24px;
    font-weight: 700;
  }

  .content div {
    display: flex;
    align-items: center;
  }

  .content div:first-child {
    margin-right: 8px;
  }
`;

export const DialogContent = styled.div`
  margin-top: 40px;

  .column-row {
    display:flex;
  }

  .horizontal {
    display: flex;
    margin-top: 8px;
  }

  .column-flow {
    display: flex;
    flex-flow: column;
    margin-bottom: 15px;
    margin-right: 10px;
  }

  .column-header {
    color: #95A3B7;
    font-size:16px;
  }

  .column-text {
    color: #3B495E;
    font-weight: 500;
    font-size: 16x;
  }

  .main-header {
    color: #2770B1;
    font-size: 21px;
    font-weight: 600;
  }

  .separator {
    width: 2px;
    height: 35px;
    background: #C1D0E2 0% 0% no-repeat padding-box;
    opacity: 1;
    margin-right: 10px;
  }

  hr {
    width: 100%;
    background-color: #95A3B7;
    opacity: .3;
    height: 1px;
    display: block;
    margin-top: 10px;
  }

`;

export const Badge = styled.div`  
  padding: 8px;
  border-radius: 10px;
  background-color: #E7F0F7;

  .text {
    font-size: 14px;
    color: #6197C6;
    font-weight: 700
  }
`;

export const Divider = styled.div`  
  width: 100%;
  background-color: #95A3B7;
  opacity: .3;
  height: 2px;
  display: block;
  margin-top: 20px;
  position: absolute;
  left: 0;

`;

export const BottomDivider = styled.div`
  width: 100%;
  background-color: #95A3B7;
  opacity: .3;
  height: 2px;
  display: block;
  margin-bottom: 4px;
  position: absolute;
  left: 0;
  bottom: 80px;
`
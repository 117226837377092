import React, { useState } from 'react'
import { Button } from '@hbsis.uikit/react'
import IconAccept from 'images/check-circle.svg'
import OrdersAcceptModal from './modal'

const ButtonOrderAccept = ({ checkbox, accept, title }) => {
  const [showAlert, setShowAlert] = useState(false)

  const handleAccept = () => {
    setShowAlert(false)
    accept()
  }

  return (
    <React.Fragment>
      <div className="btn-content" title={title}>
        <Button
          icon={IconAccept}
          className="btnAcept"
          width='166px'
          type="secondary"
          value={title}
          disabled={checkbox}
          onClick={() => setShowAlert(true)}
        />
      </div>
      <OrdersAcceptModal
        onClick={handleAccept}
        open={showAlert}
        close={() => setShowAlert(false)}
      />
    </React.Fragment >
  )
}

export default ButtonOrderAccept

import Fetch from 'utils/fetch'
import { generateQueryList } from 'utils/custom-functions'

export const getBottlesPlan = (filter) => {
  const date = new Date(filter.periodDate.year, filter.periodDate.month, 1)
  const period = `&PeriodDate=${date.toISOString()}`
  return Fetch.get(`bottles-plan?LabeledFamilyId=${filter.labeledFamilyId}${period}`).then(({ data }) => data)
}

export const getBottlesPlanViewMode = (filters) => {
  const viewMode = filters.viewMode ? "monthly" : "daily"

  const config = {
    "daily": {
      start: filters.periodDate.month,
      end: filters.periodDate.month + 1
    },
    "monthly": {
      start: new Date().getMonth() - 2,
      end: new Date().getMonth() + 2
    }
  }
  const startDate = new Date(filters.periodDate.year, config[viewMode].start, 1).toISOString()
  const endDate = new Date(filters.periodDate.year, config[viewMode].end, 0).toISOString()

  const labeledFamilys = generateQueryList(filters.labeledFamilysId, 'LabeledFamilyIds')
  const businessUnits = generateQueryList(filters.destinyId, 'BusinessUnitIds')

  const query = `bottles-plan/view-mode?StartDate=${startDate}
  &EndDate=${endDate}&${labeledFamilys}${businessUnits}IsMonthlyView=${filters.viewMode}`.replace(/\s/g, "")

  return Fetch.get(query).then(({ data }) => data)
}

export const getSuppliers = (params) => {
  const defaultFilter = `UseDefaultFilter=${params.useDefaultFilter}&`
  const userId = `UserId=${params.userId}&`
  const labeledFamilyId = `LabeledFamilyId=${params.labeledFamilyId}`

  const query = `${defaultFilter}${userId}${labeledFamilyId}`.replace(/\s/g, "")
  return Fetch.get(`suppliers/for-filters?${query}`).then(({ data }) => data)
}

export const addSupplierBottles = (params) => Fetch.post('/bottles-plan/add-supplier', params).then(({ data }) => data)

export const savePlanning = (params) => Fetch.post('/bottles-plan', params).then(({ data }) => data)

export const reprojectPlanning = (params) => Fetch.put('/bottles-plan:redesign', params).then(({ data }) => data)

export const replyPlanning = (params) => Fetch.post('/bottles-plan:reply-plan', params).then(({ data }) => data)

export const deletePlanning = (params, period) =>
  Fetch.delete(`/bottles-plan:${params.routeName}/${params.routeParam}`, period).then(({ data }) => data)

export const replicatePlanning = (params) => Fetch.post('/bottles-plans:replicate', params).then(({ data }) => data)
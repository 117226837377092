import styled from 'styled-components'
import * as Compoonents from 'components/uikit-adapter/index'

export const Wrapper = styled.div`

  .body-card{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .divisor-container {
    margin-top: 6px;
  }

  .card-label{
    align-content: flex-start;
    span:first-child{
      line-height: 11px;
    }
    span:last-child{
      line-height: 14px;
      margin-top: 4px;
    }
  }

  .insumo-desc {
    top: -3px;
  }

  .description-bold{
    font-weight: 700 !important;
    white-space: nowrap;
  }

  .card-shop-mester{
    height: ${p => p.hasOrderCode ? '190px' : '149px'} !important;
    margin: 10px 45px 0px 53px;
  }

  span {
    cursor: pointer;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  position: absolute;
  top: ${p => p.hasOrderCode? '38px' : '4px'} !important;
  width: calc(100% - 16px);
  justify-content: end;
`

export const Button = styled(Compoonents.Button)`
  width: 112px;
  height: 26px;
  border: 1px solid #2870B2 !important;
  margin-left: 6px;
  span{
    color: #2870B2 !important;
    font-size: 10px !important;
  }
`

export const ButtonEdit = styled(Compoonents.Button)`
  width: 112px;
  height: 26px;
  span{
    color: #FFFFFF !important;
    font-size: 10px !important;
  }
`

import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog';
import FlatButton from "material-ui/FlatButton";
import intl from 'react-intl-universal'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from 'components/uikit-adapter/index';

const ModalRegisterBolNumber = ({ open, close, save }) => {
  const [bolNumber, setBolNumber] = useState()

  return (
    <Dialog
      open={open}
      onClose={close}
    >
      <DialogTitle>{'Insert Bol Number'}</DialogTitle>
      <DialogContent style={{ overflowY: 'auto', maxHeight: '65vh', minWidth: '350px' }}>
        <Input
          style={{ height: '30px' }}
          onChange={e => setBolNumber(e.target.value)}
          value={bolNumber}
          htmlType="number"
          data-testid='register-bol-number-input'
        />
      </DialogContent>
      <DialogActions>
        <FlatButton label={intl.get('geral.buttonsDefault.close')} onClick={close} />
        <FlatButton label={intl.get('geral.buttonsDefault.save')} onClick={() => save(bolNumber)} style={{ backgroundColor: '#2870b2', color: '#FFFFFF' }} />
      </DialogActions>
    </Dialog>
  )
}

export default ModalRegisterBolNumber;

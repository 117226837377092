import React from 'react'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'components/uikit-adapter/index'
import PropTypes from 'prop-types'
import IconButton from 'material-ui/IconButton'
import icnDataUpload from 'images/data-upload.svg'
import intl from 'react-intl-universal';

const DataUploadList = ({ data, openDataUpload }) => (
  <Table
    fixedHeader
    fixedFooter
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%' }}
    bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid #E0E0E0', backgroundColor: '#FFFFFF' }}
  >
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      style={{ borderBottom: 'none' }}
    >
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn style={{ width: '100%' }}>{intl.get('master-data.data-upload.headers.description')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '100px' }}>{intl.get('master-data.data-upload.headers.user-update')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '120px', textAlign: 'right' }}>{intl.get('master-data.data-upload.headers.last-update-date')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '50px' }} />
      </TableRow>
    </TableHeader>
    <TableBody
      displayRowCheckbox={false}
      showRowHover
    >
      {data.filter(x => !x.hide).map((v, i) => (
        <TableRow key={i}>
          <TableRowColumn style={{ width: '100%' }}>
            {v.description}
          </TableRowColumn>
          <TableRowColumn style={{ width: '100px' }}>
            {v.userUpdate}
          </TableRowColumn>
          <TableRowColumn style={{ width: '120px', textAlign: 'right' }}>
            {v.lastUpdateDate}
          </TableRowColumn>
          <TableRowColumn style={{ width: '50px', textAlign: 'right' }}>
            <IconButton title='Upload' data-testid={`button-upload-data-${i}`} onClick={() => openDataUpload(v.id)}>
              <img src={icnDataUpload} style={{ width: '30px', height: '30px' }} />
            </IconButton>
          </TableRowColumn>
        </TableRow>
      ))}
    </TableBody>
  </Table>
)

DataUploadList.propTypes = {
  data: PropTypes.array.isRequired,
}

export default DataUploadList;

import React from 'react'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from 'components/uikit-adapter/index'
import PaginationFooter from 'components/pagination/components/pagination-footer'
import {
  CheckIcon,
  UnCheckIcon,
} from 'components/icons/icn-index.icon'
import intl from 'react-intl-universal'

const UserList = ({ data, countData, refreshSearch, handleEdit, qtRegsPerPage }) => (
  <Table
    fixedHeader
    fixedFooter
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%' }}
    bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: '#FFFFFF' }}
    onCellClick={handleEdit}>
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      style={{ borderBottom: 'none' }}>
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn className='table-header__column'>{intl.get('master-data.general-configuration.name')}</TableHeaderColumn>
        <TableHeaderColumn>Email</TableHeaderColumn>
        <TableHeaderColumn>{intl.get('commons.wallet')}</TableHeaderColumn>
        <TableHeaderColumn>{intl.get('master-data.general-configuration.coordination')}</TableHeaderColumn>
        <TableHeaderColumn>Status</TableHeaderColumn>
      </TableRow>
    </TableHeader>

    <TableBody displayRowCheckbox={false} showRowHover>
      {data.map((v, i) => (
        <TableRow key={i} style={{ cursor: 'pointer' }}>
          <TableRowColumn>
            {v.Nome}
          </TableRowColumn>
          <TableRowColumn>
            {v.Email}
          </TableRowColumn>

          <TableRowColumn>
            {!!v.Carteiras.length && v.Carteiras[0].Descricao}
          </TableRowColumn>

          <TableRowColumn>
            {v.NomeCoordenacao}
          </TableRowColumn>
          <TableRowColumn>
            {
              <div title={v.Status == 0 ? intl.get('master-data.coordinationsAndUsers.users.activeUser') : intl.get('master-data.coordinationsAndUsers.users.disabledUser')}>
                {v.Status == 0 ? <CheckIcon /> : <UnCheckIcon />}
              </div>
            }
          </TableRowColumn>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <PaginationFooter
        countPerPage={qtRegsPerPage}
        countData={countData}
        refreshSearch={refreshSearch}
      />
    </TableFooter>
  </Table >
)

export default UserList

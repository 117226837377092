import styled from 'styled-components'

const StyledIndicator = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    color: #ffffff;
    font-weight: 100;
    line-height: 0.80;
    text-align: left;

    .description{
      font-size: 20px;
    
      &.Default {
        font-size: 20px;
      }

      &.Small {
        font-size: 14px;
      }
    }

    .value-description {
      &.Default {
        font-weight: var(--font-weight-regular);
        font-size: 20px;
      }

      &.Small {
        font-weight: var(--font-weight-regular);
        font-size: 14px;
      }
    }

    .description-taks {
      &.Default {
        font-size: 14px;
      }

      &.Small {
        font-size: 14px;
      }
    }
`

export default StyledIndicator

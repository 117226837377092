import React, { useEffect, useState } from "react"
import Dialog from "material-ui/Dialog"
import * as S from './styled'
import Session from "utils/user-storage";
import { getPedidos } from "../../load-composition.service"
import { useGlobalContext } from 'hooks/useGlobalContext';
import { formatErrorMessage } from "../../../../utils/handle-error"
import ModalInfosNegotiationComposition from "./components/modal-infos-negotiation-compositition"
import ModalInfosComposicaoCarga from "./components/modal-infos-composition";
import { formatWithoutSpaces } from "utils/format";

const ModalRenegociacaoComposicaoCarga = ({ isOpen, handleClose, composicao, filters, redirectRoute, handleRefresh }) => {
  const fornecedor = [{ Id: composicao.IdFornecedor, Descricao: composicao.Fornecedor }]
  const unidadeNegocio = [{ Id: composicao.IdUnidadeNegocio, Descricao: composicao.UnidadeNegocio }]
  const [pedidos, setPedidos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { showFeedback } = useGlobalContext();

  const loadPedidos = async () => {
    setIsLoading(true);
    try {
      const dtoRequest = {
        IdUnidadeNegocio: composicao.IdUnidadeNegocio,
        IdFornecedor: composicao.IdFornecedor,
        IdComposicao: composicao.IdComposicao,
        idUsuario: Session.get().Id
      }
      const _pedidos = await getPedidos(dtoRequest)
      setPedidos([..._pedidos])
    }
    catch (e) {
      proccessError(e)
    }
    setIsLoading(false);
  }

  const handleEditLoad = () => {
    const filtersRoute = `${filters.center.IdUnidadeNegocio}/${formatWithoutSpaces(filters.center.Descricao)}`
    const filtersRouteDates = `/date/${composicao.date}/${composicao.hour}/${composicao.IdComposicao}/${composicao.IdFornecedor}`
    redirectRoute(`/loadCompositionbuilding/destino/${filtersRoute}${filtersRouteDates}`)
  }

  const proccessError = (e) => {
    showFeedback(formatErrorMessage(e))
  }

  useEffect(() => {
    loadPedidos();
  }, [])

  return (
    <S.WrapperDialog>
      <Dialog
        open={isOpen}
        onRequestClose={handleClose}
        contentStyle={{
          position: 'fixed', width: '0px !important', height: '0px !important',
          margin: '0px important', padding: '0px important', left: '-300px', top: '40px'
        }}
      >
        <S.Wrapper>
          <ModalInfosComposicaoCarga
            fornecedor={fornecedor}
            unidadeNegocio={unidadeNegocio}
            pedidos={pedidos}
            isLoading={isLoading}
            composicao={composicao}
            redirectRoute={redirectRoute}
          />
          <ModalInfosNegotiationComposition
            composicao={composicao}
            handleClose={handleClose}
            handleEditLoad={handleEditLoad}
            handleRefresh={handleRefresh}
            handleFeedback={showFeedback}
          />
        </S.Wrapper>
      </Dialog >
    </S.WrapperDialog>
  )
}

export default ModalRenegociacaoComposicaoCarga;

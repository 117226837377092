import React, { Component } from 'react'
import { Button, Layout } from 'components/uikit-adapter/index'
import Fetch from 'utils/fetch'
import GeneralList from './list'
import GeneralForm from './form'
import Filtro from 'components/filters/filter-general'
import Delete from 'components/delete-dialog'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import SnackBar from 'material-ui/Snackbar'
import { GeneralIcon } from 'components/icons/icn-index.icon'
import IconRefresh from 'images/icn-refresh.svg'
import IconEmpty from 'images/iconEmpty.icon'
import IconFilterGray from 'images/icn-filter-gray.svg'
import IconFilterBlue from 'images/icn-filter-blue.svg'
import AutoPilot from './components/autopilot'
import ButtonNewItem from 'components/button-new-item'
import StorePersist from 'utils/store-persist'
import { formatErrorMessage } from 'utils/handle-error'

import ImportExport from './importer-exporter'
import * as services from './general-master-data.service';
import intl from 'react-intl-universal';

import './general.css'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";

class General extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      generalData: [],
      total: 0,
      currentPage: 0,
      qtRegsPerPage: 50,
      openDelete: false,
      openEditMode: false,
      openModalForm: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      textMsg: '',
      terms: '',
      showFilter: false,
      filters: {
        IdCarteira: null,
        IdFamilia: null,
        IdFamiliaRotulada: null,
        IdCentro: null,
        IdCoordenacao: null
      },
      filterOptions: null,
      openImportExport: false,
      openImportExportGeneric: false,
      modalAutoPilot: false
    }
  }

  UNSAFE_componentWillMount() {
    const filter = StorePersist.getValuesJSON('filtersFamiliaRotuladaCentro')

    if (filter) {
      this.setState({
        filters: filter
      })
    }
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openForm = () => {
    this.setState({
      openModalForm: true
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false
    }, () => this.openForm())
  }

  openEditForm = (row) => {
    const selected = this.state.generalData[row]

    this.setState({
      openEditMode: true,
      idSelected: selected.Id
    }, () => this.openForm())
  }

  redirect = (generalMaterial) => {
    const filter = {
      IdFornecedor: null,
      IdFamiliaRotuladaCentro: generalMaterial.Id
    }

    StorePersist.setValuesJSON('filtersFamiliaRotuladaCentroMaterial', filter)
    this.props.history.push(`/master-data/general-material`)
  }

  closeForm = () => {
    this.setState({
      openModalForm: false,
      idSelected: 0
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (ex) => {
    this.stopFetching()
    this.showFeedback(formatErrorMessage(ex))
  }

  refreshSearch = () => {
    this.closeForm()
    this.search()
  }

  searchFilters = (filters) => {
    StorePersist.setValuesJSON('filtersFamiliaRotuladaCentro', filters);
    this.showFilter()

    this.setState({
      currentPage: 0,
      filters
    }, () => this.search(this.state.currentPage))
  }

  showFilter = async () => {
    const { filterOptions } = this.state;
    if (!filterOptions) await this.loadFilters();
    this.setState({
      showFilter: !this.state.showFilter,
    })
  }

  loadFilters = async () => {
    this.startFetching();
    const wallets = await services.getWallets();
    const families = await services.getFamilies();
    const labeledFamilies = await services.getLabeledFamilies();
    const centers = await services.getCenters();
    const coordinations = await services.getCoordinations();
    this.setState({
      filterOptions: {
        wallets,
        families,
        labeledFamilies,
        centers,
        coordinations,
        isFetching: false,
      }
    })
    this.stopFetching();
  }

  verifyFilter = () => {
    return this.state.filters.IdCarteira > 0
      || this.state.filters.IdFamilia > 0
      || this.state.filters.IdFamiliaRotulada > 0
      || this.state.filters.IdCentro > 0
      || this.state.filters.IdCoordenacao > 0
  }

  proccessData = (data) => {
    this.setState({
      generalData: data.Data,
      total: data.TotalRecordCount
    })
  }

  delete = () => {
    const idLabeledFamily = this.state.idSelected
    this.startFetching()
    Fetch.delete(`/familiaRotuladaCentro/${idLabeledFamily}`)
      .then(this.closeDelete)
      .then(this.search)
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching());
  }

  search = (page) => {
    this.startFetching()
    const filters = StorePersist.getValuesJSON('filtersFamiliaRotuladaCentro')
    if (filters) {
      this.setState({ filters })
    }

    const nextPage = page == undefined ? this.state.currentPage : page;
    Fetch.post(`/familiaRotuladaCentro/${this.state.qtRegsPerPage}/${nextPage}`, this.state.filters)
      .then((response) => this.proccessData(response.data))
      .then(() => this.setState({ currentPage: nextPage }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching());

    
    this.setState({
      showFilter: false
    })

  }

  exportarPoliticaEstoque = () => {
    this.setState({
      openImportExport: true,
    })
  }

  closeImportExport = () => {
    this.setState({
      openImportExport: false,
    })
  }

  openImportExportGeneric = () => {
    this.setState({
      openImportExportGeneric: true,
    })
  }

  closeImportExportGeneric = () => {
    this.setState({
      openImportExportGeneric: false,
    })
  }

  openAutoPilot = (id) => {
    this.setState({
      modalAutoPilot: true,
      idSelected: id
    });
  }

  closeAutoPilot = () => {
    this.setState({
      modalAutoPilot: false,
      idSelected: 0
    });
  }

  saveAutoPiloteSchedule = async (data) => {
    try {
      this.startFetching();
      await services.saveSchedulingAutoPilot(data).then(() => {
        this.closeAutoPilot()
        this.showFeedback(intl.get('master-data.feedback.savedSuccessfully'))
      })
    }
    catch (err) {
      this.showFeedback(formatErrorMessage(err))
      this.closeAutoPilot()
    }
    finally {
      this.stopFetching();
    }
  }

  render() {
    const {
      openModalForm,
      openEditMode,
      openDelete,
      idSelected,
      generalData,
      total,
      messageFeedBack,
      isFetching,
      showFeedback,
      showFilter,
      filters,
      openImportExport,
      openImportExportGeneric,
      filterOptions,
    } = this.state
    return (
      <div className='general-container'>
        <Loading isLoading={isFetching} />
        <div className='general-header-container'>
          <HeaderIcon
            title={intl.get('master-data.general.header.title')}
            subtitle={intl.get('master-data.general.header.subtitle')}
            icon={<GeneralIcon width='40px' height='40px' color='#1d388f' />}
          >
            {profileAdminOrMasterData() &&
              <React.Fragment>
                <div title={intl.get('master-data.general.actions.updateStockPolitic')}>
                  <Button
                    onClick={this.exportarPoliticaEstoque}
                    type='default'
                    value={intl.get('master-data.general.actions.stockPolitic')}
                    icon={IconRefresh}
                    className={"button-img-estoque"}
                  />
                </div>
              </React.Fragment>
            }

            <div title={intl.get('master-data.general.actions.newRotuledFamily')}>
              <ButtonNewItem
                className="general-button"
                onClick={this.openNewForm}
              />
            </div>

            <div title={intl.get('master-data.general.actions.filter')} className='filterContainer'>
              <Button
                onClick={this.showFilter}
                type='default'
                value={intl.get('filters.filter')}
                icon={this.verifyFilter() ? IconFilterBlue : IconFilterGray}
                className={"button-filter"}
              />
            </div>

          </HeaderIcon>
        </div>

        <GeneralList
          data={generalData}
          countData={total}
          refreshSearch={this.search}
          handleEdit={this.openEditForm}
          handleDelete={this.openDelete}
          qtRegsPerPage={this.state.qtRegsPerPage}
          handleRedirect={this.redirect}
          openConfgAutoPilot={this.openAutoPilot}
        />

        {openModalForm &&
          <GeneralForm
            idEdit={idSelected}
            open={openModalForm}
            editMode={openEditMode}
            handleClose={this.closeForm}
            handleRefresh={this.refreshSearch}
            handleFeedback={this.showFeedback}
            profileAdminOrMasterData={profileAdminOrMasterData()}
          />
        }

        <Delete
          open={openDelete}
          title={intl.get('master-data.general.deleteLabeledFamilyModal.tooltipDescription')}
          handleClose={this.closeDelete}
          warningMessage={intl.get('master-data.general.materials.description')}
          handleConfirm={this.delete}
        />

        <Layout.Sidebar width="450px"
          visible={showFilter}
          triggerClose={this.showFilter}
          background="#FFFFFF"
          offsetTop="96px"
          side="right"
          block
          float
          icon={IconEmpty}
        >
          {showFilter &&
            <Filtro
              initialData={filters}
              showFilter={showFilter}
              handleClose={this.searchFilters}
              filterOptions={filterOptions}
            />
          }
        </Layout.Sidebar>

        <SnackBar
          message={messageFeedBack}
          open={showFeedback}
          autoHideDuration={3000}
          onRequestClose={this.closeFeedback}
        />

        {openImportExport &&
          <ImportExport
            open={openImportExport}
            handleClose={this.closeImportExport}
          />
        }
        {this.state.modalAutoPilot &&
          <AutoPilot
            handleClose={this.closeAutoPilot}
            isOpen={this.state.modalAutoPilot}
            idLabeledFamilyCenter={this.state.idSelected}
            saveAutoPiloteSchedule={this.saveAutoPiloteSchedule}
            showFeedback={this.showFeedback}
          />
        }
      </div>
    )
  }
}

export default General

import styled from 'styled-components'

const StyledCounter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 250px;
    max-width: 600px;

    &.TextRed {
        text-transform: uppercase;
        color: #f16666;
    }

    .description-type-taks {
        color: #ffffff;
        font-weight: var(--font-weight-semibold);
        font-size: 14px;

        &.Default {
            font-size: 16px;
        }

        &.Small {
            font-size: 14px;
        }
    }
`

export default StyledCounter

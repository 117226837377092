import React, { useState } from "react"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import intl from "react-intl-universal"
import moment from "moment"
import * as S from "../styled"
import { formataNumeroMaterial } from "../utils"
import CurrencyInput from 'react-currency-input-field'
import "./accordion-estoque-fornecedor.css"
import Session from 'utils/user-storage'


export const AccordionWrapper = ({ dados, salvar, valorInput, coluna }) => {

  const [usuarioAmbev] = useState(() => Session.get().TipoUsuario === 1)
  const classes = S.useStyles()

  const materiaisRetorno = (materiais, c) => (
    materiais.map((material, p) => (
      <S.InputContainer>
        <p>{`${(formataNumeroMaterial(material.NumeroMaterial))} - ${material.NomeMaterial}`}</p>
        <CurrencyInput
          disabled={usuarioAmbev}
          allowNegativeValue={false}
          intlConfig={{ locale: 'pt-BR' }}
          id="accordion__input"
          className={ usuarioAmbev ? "accordion__input accordion__input--disabled": "accordion__input"}
          data-testid="accordion__input"
          decimalSeparator=","
          name="accordion__input"
          defaultValue={material.Quantidade ? material.Quantidade : 0}
          decimalsLimit={3}
          onValueChange={(value) => valorInput(value, material.IdFornecedor, p, c)}
        />
      </S.InputContainer>
    ))
  )

  return (
    dados.map((item, i) => (
      <Accordion key={i}
        data-testid="container"
        TransitionProps={{ unmountOnExit: false }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`row-${i}`}
          id={`header-${i}`}
          className="expansion-panel"
        >
          <p>{`${item.NomeFornecedor} - ${item.UF}`}</p>
          <div className="info-separator">
            <S.AccordionHeaderInfoWrapper>
              <h5>{intl.get("master-data.data-upload.headers.user-update")}</h5>
              <span>{item.UsuarioAtualizacao ? item.UsuarioAtualizacao : '-'}</span>
            </S.AccordionHeaderInfoWrapper>
            <S.AccordionHeaderInfoWrapper>
              <h5>{intl.get("master-data.data-upload.headers.last-update-date")}</h5>
              <span>{item.UltimaAtualizacao ? `${moment(item.UltimaAtualizacao).format("L")} ${moment(item.UltimaAtualizacao).format("LT")}` : '-'}</span>
            </S.AccordionHeaderInfoWrapper>
            <div>
              <Button
                data-testid="btnSalvar-primeira"
                title={intl.get('geral.buttonsDefault.save')}
                className={classes.root}
                disabled={usuarioAmbev}
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                onClick={e => salvar(item.IdFornecedor, e, i, coluna)}
                onFocus={(event) => event.stopPropagation()}
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails className="accordion">
          <S.FornecedorMateriaisFirstAccordion>
            {
              item.Salvando ?
                <div className="messages">{intl.get("commons.saving")}</div>
                :
                materiaisRetorno(item.Materiais, coluna)
            }
          </S.FornecedorMateriaisFirstAccordion>
        </AccordionDetails>
      </Accordion>
    ))
  )
}

export default AccordionWrapper

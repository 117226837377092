import React, { useState } from 'react'
import Select from 'react-select';
import { customStyle, OntimeSelectWrapper, OntimeSelectLabel } from './styled'
import { normalizeOptions, normalizeValue } from './utils/normalize'
import intl from 'react-intl-universal';

const SelectBox = (
  {
    dontShowLabel,
    label,
    placeholder,
    options,
    onChange,
    clearable,
    searchable,
    multi,
    disabled,
    valueKey,
    labelKey,
    isLoading,
    width,
    name,
    value,
    isPrimaryMaterial,
    limitOptions = 100,
    menuListStyle = {},
    ...rest
  }
) => {
  const [searchFilterSelect, setSearchFilterSelect] = useState('')
  const filterOptions = (list, label) => {
    if (!list) {
      return []
    }
    let _list = list
    if (searchFilterSelect) {
      _list = list.filter(x => x[label].toLowerCase().includes(searchFilterSelect))
    }
    if (_list.length > limitOptions) {
      return [..._list?.slice(0, limitOptions), { [label]: intl.get("commons.typeToFind"), isDisabled: true }]
    }
    return [..._list?.slice(0, limitOptions)]
  }

  return (
    <OntimeSelectWrapper isPrimaryMaterial={isPrimaryMaterial} dontShowLabel={dontShowLabel} width={width}>
      <OntimeSelectLabel dontShowLabel={dontShowLabel} htmlFor={label} >{label}</OntimeSelectLabel>
      <Select
        inputId={label}
        name={name}
        placeholder={placeholder}
        styles={customStyle(menuListStyle)}
        isClearable={clearable}
        isSearchable={searchable}
        isMulti={multi}
        isDisabled={disabled}
        isLoading={isLoading}
        onChange={onChange}
        value={multi && !value ? [] : (normalizeValue(value, labelKey, valueKey, options, multi))}
        options={normalizeOptions(filterOptions(options, labelKey), labelKey, valueKey)}
        onInputChange={value => setSearchFilterSelect(value.toLowerCase())}
        {...rest}
      />
    </OntimeSelectWrapper>
  )
}

export { SelectBox }

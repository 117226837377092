import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px;
  background: #FFFFFF;
  border: 1px solid #FBBA49;
  box-sizing: border-box;
  border-radius: 3px;
  width: 142px;
  margin: 4px 11px;
  cursor: pointer;
  ${p => p.isSelected && 'background: #FBBA49; span{color: #FFFFFF !important;}'}
`

export const Hora = styled.div`
  border-right: solid 1px #D1D4D7;
  ${p => p.isSelected && 'border-right: solid 1px #FFFFFF;'}
  span{
    font-weight: 600;
    margin-right: 4px;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #3B495E;
  }
`

export const Content = styled.div`
  margin-left: 4px;
  span{
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #FBBA49;
  }
`

import Fetch from 'utils/fetch';

export const saveSchedulingAutoPilot = (data) => {
  return Fetch.post(`familiaRotuladaCentro/saveSchedulingAutoPilot`, data)
}

export const getSchedulingAutoPilot = (id) => {
  return Fetch.get(`familiaRotuladaCentro/getSchedulingAutoPilot/${id}`).then(({ data }) => data)
}

export const getWallets = async () => {
  const request = await Fetch.get(`/carteira/carteirasgeral`);
  return request.data;
}

export const getFamilies = async () => {
  const request = await Fetch.get(`/familia/familiageral`);
  return request.data;
}

export const getLabeledFamilies = async () => {
  const request = await Fetch.get(`/familiaRotulada/familiarotuladageral`);
  return request.data;
}

export const getCenters = async () => {
  const request = await Fetch.get(`/unidadeNegocio/unidadegeral`);
  return request.data;
}

export const getCoordinations = async () => {
  const request = await Fetch.get(`/coordenacao/coordenacaogeral`);
  return request.data;
}
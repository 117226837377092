import moment from 'moment'
import { userFornecedor } from 'services/permissions/getPermissions'
import { getCurrentStockForOrdersOrSuggestions } from 'components/table-list/utils'
import { formatNumber, formatWithoutLeadingZeros } from 'utils/format'
import { getStage } from '../order-volume/components/order-volume-list-stage'

export const buildCard = (item) => ({
  Tipo: { Titulo: item.Title, Etapa: 0, Prioridade: 1, Alerta: item.Alert },
  Material: {
    Nome: `${formatWithoutLeadingZeros(item.MaterialNumber)} - ${item.MaterialName}`,
    Estoque: {
      Prioridade: item.StockUnitPriority,
      Suficiencia: moment(item.StockUnitSufficiency).format("L"),
      QuantidadeAtual: item.StockUnitCurrentQuantity,
      UnidadeMedida: item.MaterialUnitOfMeasure
    }
  },
  Fabrica: {
    Codigo: item.BusinessUnitPlant,
    Nome: item.BusinessUnitName
  },
  Fornecedor: item.SupplierName,
  Status: item.OrderNegotationTypeStatusDescription,
  IdFamiliaRotuladaCentro: item.LabeledFamilyCenterId,
  Id: item.Id,
  IdComposicao: item.LoadCompositionId,
  IsReadModeLoadComposition: item.IsLoadCompositionReadMode,
  Negociacao: {
    DataEntregaPropostaFormatada: moment(item.OrderDeliveryDate).format("DD/MM/YYYY"),
    DataColetaPropostaFormatada: moment(item.OrderCollectDate).format("DD/MM/YYYY"),
    QuantidadeProposta: item.OrderQuantity
  },
  StatusInt: item.OrderStatus
})

export const buildListItem = (item) => ({
  ...item,
  IdComposicao: item.LoadCompositionId,
  IsReadModeLoadComposition: item.IsLoadCompositionReadMode,
  Stage: getStage(item.OrderStatusDescription, item.Alert),
  OrderStatusDescription: item.OrderNegotationTypeStatusDescription,
  OrderCollectDate: item.OrderCollectDate ? moment(item.OrderCollectDate).format("L") : '-',
  OrderDeliveryDate: item.OrderDeliveryDate ? moment(item.OrderDeliveryDate).format("L") : '-',
  BusinessUnit: `${item.BusinessUnitPlant} - ${item.BusinessUnitName}`,
  StockUnitCurrent: getCurrentStockForOrdersOrSuggestions(
    item.StockUnitPriority,
    item.StockUnitCurrentQuantity, item.MaterialUnitOfMeasure),
  Material: `${formatWithoutLeadingZeros(item.MaterialNumber)} - ${item.MaterialName}`,
  UrlRedirect: userFornecedor() ? `/ordersVisionDetail/${item.Id}/P` :
    `/stocksDetail/${item.LabeledFamilyCenterId}/${item.Id}/P`,
  Quantity: `${formatNumber(item.OrderQuantity)} ${item.MaterialUnitOfMeasure}`,
  CanAccept: item.CanAcceptRange,
  CanCancel: item.CanCancelRange
})

import React, { useState } from 'react'
import intl from 'react-intl-universal'
import { Button } from 'components/uikit-adapter/index'
import { Layout } from '@hbsis.uikit/react'
import IconFilterGray from 'images/icn-filter-gray.svg'
import IconFilterBlue from 'images/icn-filter-blue.svg';
import { useOrdersContext } from '../../context'
import { OrdersFilter as Filter } from '../../../../components/orders-filter'
import { verifyIsFiltered } from 'utils/custom-functions'
import * as S from './styled'
import StorePersist from 'utils/store-persist'

const OrdersFilter = () => {
  const { setFilter, filter } = useOrdersContext()
  const [openFilter, setOpenFilter] = useState(false)
  const handleClose = () => setOpenFilter(false)

  const refresh = (filters) => {
    setFilter({ ...filters })
    StorePersist.setValuesJSON("filtersOrdersMulti", filters);
    handleClose()
  }

  const getIconFilter = () => verifyIsFiltered(filter) ? IconFilterBlue : IconFilterGray;

  return (
    <S.Wrapper>
      <Button
        onClick={() => setOpenFilter(prevState => !prevState)}
        type='default'
        value={intl.get('filters.filter')}
        icon={getIconFilter()}
      />
      <Layout.Sidebar width="450px"
        visible={openFilter}
        triggerClose={() => setOpenFilter(false)}
        background="#FFFFFF"
        offsetTop="96px"
        side="right"
        block
        float
      >
        <Filter
          handleSearch={refresh}
          filtersDefault={filter}
        />
      </Layout.Sidebar>
    </S.Wrapper>
  )
}

export default OrdersFilter

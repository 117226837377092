import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  background-color: #FFFFFF;
`

export const Content = styled.div`
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  justify-content: space-around;
`

export const LogoSection = styled.div`
  width: 50%;
  height: -webkit-fill-available;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  #ontimeMrp-logo {
    width: 40vw;
  }
`

export const CredentialsSection = styled.div`
  width: 50%;
  height: -webkit-fill-available;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
`

export const CredentialsBox = styled.div`
  display: flex;
  width: 45%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background: var(--Contrast-White, #FFF);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
  @media(max-width: 1300px){
    width: 60%;
  }

  #credentials-box-title {
    margin: 0px 0px 4px 0px;
    color: var(--Neutral-600, #0E0E0E);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 36px */
    display: flex;
  }

  #credentials-box-text {
    color: #0E0E0E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 185%;
    margin: 2px;

    a {
      color: #0E0E0E;
    }
  }

  #credentials-box-link {
    margin-top: 32px;
    align-self:center;
  }
}
`

export const Footer = styled.div`
  width: 100%;
  height: 56px;
  background-color: #A755F7;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;

  span {
    margin-left: 25px;
    color: #292929;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; 
  }

  #ambevHbsis-logo {
    margin-right: 25px;
    width: 120px;
    height: 16px;
  }
`
import React from 'react';
import { Container, Content, CredentialsBox, CredentialsSection, Footer, LogoSection } from './sign-up.styled';
import intl from 'react-intl-universal';
import ambevHbsisLogoDark from 'images/login/ambevHbsis-dark.png';
import ontimeMrpLogo from 'images/login/OntimeMRP-logo.png';
import { ClbLink } from '@celebration/design-system-react';
import { getSupportEmail } from 'utils/config-environment';

const SignUpComponent = () => {
  return (
    <Container>
      <Content>
        <LogoSection>
          <img src={ontimeMrpLogo} id='ontimeMrp-logo'></img>
        </LogoSection>
        <CredentialsSection>
          <CredentialsBox>
            <p id="credentials-box-title">
              {intl.get('signUp.title')}
            </p>

            <p id="credentials-box-text"> {intl.get('signUp.text.paragraphOne')} </p>
            <p id="credentials-box-text">
              {intl.get('signUp.text.paragraphTwoPartOne')} <a href={`mailto:${getSupportEmail()}`}>{getSupportEmail()}</a> {intl.get('signUp.text.paragraphTwoPartTwo')}
            </p>
            <p id="credentials-box-text"> {intl.get('signUp.text.paragraphThree')} </p>
            <p id="credentials-box-text"> {intl.get('signUp.text.paragraphFour')} </p>

            <ClbLink
              href="/"
              target={"_self"}
              id='credentials-box-link'
            >
              {intl.get('signUp.back')}
            </ClbLink>
          </CredentialsBox>
        </CredentialsSection>
      </Content>
      <Footer>
        <span>{intl.get('login.allRightsReserved')}</span>
        <img src={ambevHbsisLogoDark} id='ambevHbsis-logo' />
      </Footer>
    </Container>
  )
}

export default SignUpComponent;
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledGrid = styled.div`  
  .align-center-full {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .title-secondary {
    font-size: 24px;
    font-weight: 100;
    line-height: 1;
    text-align: left;
    color: #3b495e;
  }
  
  table {
    font-size: 15px;
    border-collapse: collapse;
    width: 100%;
  }
  
  .rowListHeader {
    font-size: 0.7rem;
    color: #9ba2ac;
    background: #FFF;
    padding: 10px 0;
    border-bottom: 1px dashed #c5d0e1;
    padding-left: 4px;
    ${props => props.withBorder && `
      box-shadow: inset 4px 0 ${props.borderColor};
    `}
  }
 
  .rowList {
    font-size: 0.8rem;
    background: #FFFFFF;
    padding: 5px 0;
    
    ${props => props.withHover && `
      cursor: pointer;
    `}
    
    min-height: 60px;
    max-height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    padding-left: 4px;
    border-bottom: 1px dashed #c5d0e1;
    ${props => props.withBorder && `
      box-shadow: inset 4px 0 ${props.borderColor};
    `}
    
    ${props => props.withHover && `
      &:hover {
        background: #f4f5f8;
        ${props.withBorder && `
          box-shadow: inset 4px 0 ${props.borderColorHover};
        `}
      }
    `}
  }

  .icon-label.active {
    border: 1px solid #000;
  }
  
  .hintList:hover .hintListComponent {
    display: block;
  }
`

StyledGrid.defaultProps = {
  withBorder: true,
  withHover: true,
  borderColor: '#afcef3',
  borderColorHover: '#2870b2',
}

StyledGrid.propTypes = {
  withBorder: PropTypes.bool,
  withHover: PropTypes.bool,
  borderColor: PropTypes.string,
  borderColorHover: PropTypes.string,
}

export default StyledGrid

import React, { useState } from 'react'
import intl from 'react-intl-universal'
import ImportExport from 'components/import-export'
import IconCalendarRefresh from 'images/icn-calendar-refresh.svg'
import { Button } from '@hbsis.uikit/react'
import { downloadFile } from 'utils/custom-functions'
import { formatErrorMessage } from 'utils/handle-error'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { getRevisionDownloadFile } from 'components/cards-obsolete/orders-vision.service'
import { GlobalProvider } from '../../../../context/globalContext'
import moment from 'moment'

const OrdersReview = () => {
  const { showFeedback } = useGlobalContext()
  const [open, setOpen] = useState(false)

  const downloadOrders = async () => {
    try {
      const data = await getRevisionDownloadFile()
      const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
      downloadFile(`${datafile}_${intl.get("orders.ordersReview")}.csv`, 'csv', data)
    }
    catch (err) {
      showFeedback(formatErrorMessage(err))
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <GlobalProvider>
      <div title={intl.get('orders.orderReviewRequest')}>
        <Button
          onClick={() => setOpen(true)}
          type='default'
          icon={IconCalendarRefresh}
        />
      </div>
      <ImportExport
        title={intl.get('orders.orderReviewRequest')}
        contextModal="reviewTrading"
        open={open}
        handleClose={() => handleClose()}
        downloadArquivo={() => downloadOrders()}
        importArquivo="orders:import-order-review"
        type='csv'
        noMessageExample
      />
    </GlobalProvider >
  )
}

export default OrdersReview

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import StylesHintList from './styledHintList'

class HintList extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    return (
      <StylesHintList applyHover={this.props.applyHover} >
        {
          (!this.props.itens || this.props.itens.length === 0) &&
          <div className="hintListComponent notHover">-</div>
        }
        {
          !!this.props.itens && this.props.itens.length === 1 &&
          <div className="hintListComponent notHover">{this.props.children}</div>
        }
        {
          !!this.props.itens && this.props.itens.length > 1 &&
          <div className="hintListComponent">
            <span className="hintListLabelComponent">{this.props.label}</span>
            <div className="hintListItensComponent">
              {this.props.children}
            </div>
          </div>
        }
      </StylesHintList>
    )
  }
}

HintList.defaultProps = {
  label: 'text',
  applyHover: true,
}

HintList.propTypes = {
  label: PropTypes.string,
  applyHover: PropTypes.bool,
}

export default HintList

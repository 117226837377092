import React, { createContext, useContext, useState } from 'react'
import { typesSlots } from '../utils'

const LoadCompositionContext = createContext()

const LoadCompositionProvider = ({ children }) => {
  const [startDate, setStartDate] = useState('2021-12-15 00:00')
  const [endDate, setEndDate] = useState('2021-12-25 00:00')
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState({
    dateFrom: '',
    dateTo: '',
    center: '',
    viewColumns: typesSlots
  });

  return (
    <LoadCompositionContext.Provider
      value={{
        startDate,
        endDate,
        data,
        setData,
        setStartDate,
        setEndDate,
        setFilters,
        filters
      }}
    >
      {children}
    </LoadCompositionContext.Provider>
  )
}

export const useLoadCompositionContext = () => useContext(LoadCompositionContext)
export { LoadCompositionContext, LoadCompositionProvider }

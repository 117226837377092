import React from 'react'
import { Button } from 'components/uikit-adapter/index'
import EditIcon from "images/edit.svg";
import intl from 'react-intl-universal'
import { useGlobalContext } from 'hooks/useGlobalContext';
import { useZapmslContext } from '../../../../../context/zapmslContext';

const BtnEditRange = ({ openModal }) => {
  const { selectedLines } = useZapmslContext();
  const { showFeedback } = useGlobalContext();

  const handleOpenModal = () => {
    if (selectedLines.length > 0) {
      openModal()
    }
    else {
      showFeedback(intl.get('primaryMaterialScheduleList.feedbacks.mandatoryOneRecord'))
    }
  }

  return (
    <div className='btn-wrapper' title={intl.get('primaryMaterialScheduleList.modal.editRange.title')}>
      <Button
        type="secondary"
        icon={EditIcon}
        className='btn-icon'
        onClick={handleOpenModal}
      />
    </div>
  )
}

export default BtnEditRange;

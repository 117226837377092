import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import intl from 'react-intl-universal'
import 'amcharts3/amcharts/amcharts.js'
import 'amcharts3/amcharts/serial.js'
import 'amcharts3/amcharts/themes/light.js'

import AmCharts from '@amcharts/amcharts3-react'

import { getDecimalSeparator, getGroupSeparator } from "locales";
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting'
import { getGeneralSettingValue } from 'services/general-settings/general-settings'

const StyledChart = styled.div`
  a[title="JavaScript charts"] {
    display: none !important;
  }
`

const formatLabelValue = (graph, valueText) => {
  return graph.values.value ? valueText : ''
}

const AmChart = ({
  data,
  categoryField,
  guides,
  title,
  pedidoVolume,
  estoqueVariavel,
  transfSaida,
  transfEntrada
}) => {
  const ballonText = `[[title]], [[category]]<br><span style='font-size:14px;'><b>[[value]]</b>
  ${intl.get('commons.table.of')} [[total]]</span>`
  const ballonText2 = "[[title]], [[category]]<br><span style='font-size:14px;'><b>[[value]]</b></span>"

  const options = {
    "type": "serial",
    "marginRight": 50,
    "startDuration": 0,
    "columnWidth": 1.0,
    "sequencedAnimation": false,
    "precision": getGeneralSettingValue(GeneralSettingParameter.QuantityDecimalPlaces),
    "thousandsSeparator": getGroupSeparator(),
    "decimalSeparator": getDecimalSeparator(),
    "categoryAxis": {
      "gridPosition": "start",
      "minHorizontalGap": 72,
      "gridThickness": 0,
      "minorTickLength": 1,
    },
    "trendLines": [],
    "graphs": [
      {//sugestão
        "balloonText": ballonText,
        "fillAlphas": 0.8,
        "id": "AmGraph-1",
        "labelText": "",
        "lineColor": "#92CDDC",
        "lineThickness": 0,
        "tabIndex": 1,
        "type": "column",
        "labelFunction": formatLabelValue,
      },
      {//volume
        "balloonText": ballonText,
        "fillAlphas": 0.8,
        "id": "AmGraph-2",
        "labelText": "",
        "lineColor": "#31869B",
        "lineThickness": 0,
        "tabIndex": 1,
        "type": "column",
        "labelFunction": formatLabelValue,
      },
      {//pedidos
        "balloonText": ballonText,
        "fillAlphas": 0.8,
        "id": "AmGraph-3",
        "labelText": "",
        "lineColor": "#006978",
        "lineThickness": 0,
        "tabIndex": 1,
        "type": "column",
        "labelFunction": formatLabelValue,
      },
      {//pedido transferencia
        "balloonText": ballonText,
        "fillAlphas": 0.8,
        "id": "AmGraph-4",
        "labelText": "",
        "lineColor": "#C5D0E1",
        "lineThickness": 0,
        "tabIndex": 1,
        "type": "column",
        "labelFunction": formatLabelValue,
      },
      {//transferencia
        "balloonText": ballonText,
        "fillAlphas": 0.8,
        "id": "AmGraph-5",
        "labelText": "",
        "lineColor": "#C5D0E1",
        "lineThickness": 0,
        "tabIndex": 1,
        "type": "column",
        "labelFunction": formatLabelValue,
      },
      {//Sugestão transferencia saida
        "balloonText": ballonText,
        "fillAlphas": 0.8,
        "id": "AmGraph-6",
        "labelText": "",
        "lineColor": "#6197C6",
        "lineThickness": 0,
        "tabIndex": 1,
        "type": "column",
        "labelFunction": formatLabelValue,
      },
      //ATENÇÃO: prop "stackable" garante que um gráfico não influencie no outro.
      {//necessidade
        "balloonText": ballonText2,
        "bullet": "round",
        "id": "AmGraph-7",
        "lineColor": "#1E5281",
        "type": "smoothedLine",
        "stackable": false,
      },
      {//Projeção de estoque
        "balloonText": ballonText2,
        "bullet": "round",
        "id": "AmGraph-8",
        "lineColor": "#00BFFF",
        "type": "smoothedLine",
        "stackable": false,
      },
      {//estoque máximo variável
        "balloonText": ballonText2,
        "bullet": "round",
        "id": "AmGraph-9",
        "lineColor": "#141f1f",
        "type": "smoothedLine",
        "stackable": false,
        "visibleInLegend": false
      }
    ],
    "guides": [

    ],
    "valueAxes": [
      {
        "id": "ValueAxis-1",
        "autoGridCount": false,
        "stackType": "regular",
        "strictMinMax": true,
        "gridThickness": 0,
        "guides": [
          {
            "fillAlpha": 0.7,
            "fillColor": "#E5EDF4",
            "lineAlpha": 0,
            "label": "OVER",
            "boldLabel": true,
            "position": "right"
          },
          {
            "fillAlpha": 0.7,
            "fillColor": "#E5EDF4",
            "lineAlpha": 0,
            "label": "OUT",
            "boldLabel": true,
            "position": "right",
            "color": "#f9575b"
          }
        ],
        "title": "",
      }
    ],
    "allLabels": [],
    "balloon": {},
    "legend": {
      "enabled": true,
      "autoMargins": false,
      "marginLeft": 90,
      "marginRight": 0,
      "spacing": 0,
      "useGraphSettings": true,
      "valueWidth": 5
    },
    "titles": [
      {
        "id": "Title-1",
        "size": 15,
        "text": ""
      }
    ],
    "dataProvider": []
  }

  const prepareData = (d) => {
    const newData = d.reduce((prev, currValue) => {
      // array apenas com as chaves desejadas
      // as chaves que contém a descrição formatada possuem Label no nome
      const valueKeys = Object.keys(currValue).filter(key => key !== categoryField && !key.includes('Label'))

      // novo objeto contendo apenas os valores das chaves filtradas
      const newValue = valueKeys.reduce((prevValue, currKey) => ({
        ...prevValue,
        [currValue[`${currKey}Label`]]: currValue[currKey],
      }), {})

      return [
        ...prev,
        {
          ...newValue,
        }
      ]
    }, [])

    const dataProvider = newData.map((value, index) => ({
      ...value,
      [categoryField]: moment(data[index][categoryField]).format("[DM]"),
    }))

    const graphs = options.graphs.map((graph, index) => {
      const key = Object.keys(newData[0])[index]
      let titleLegend = '';
      let visibleInLegend = true
      switch (key) {
        case "Volumes":
          titleLegend = intl.get('commons.volumns');
          visibleInLegend = pedidoVolume
          break;
        case "PE-Max Variável":
          titleLegend = intl.get('commons.peMaxVar');
          visibleInLegend = estoqueVariavel
          break;
        case "Transf. Saída":
          titleLegend = intl.get('commons.transfOut');
          visibleInLegend = transfSaida
          break;
        case "Transf. Entrada":
          titleLegend = intl.get('commons.transfIn')
          visibleInLegend = transfEntrada
          break;
        case "Pedidos":
          titleLegend = intl.get('requestOrders.header.title');
          break;
        case "Sugestões":
          titleLegend = intl.get('modalShelfLife.suggestions')
          break;
        case "Sugestão Transf. Saída":
          titleLegend = intl.get('commons.suggestedExits');
          visibleInLegend = newData.some(x => x["Sugestão Transf. Saída"] !== 0);
          break;
        case "Projeção de Estoque":
          titleLegend = intl.get('stocks.stockDetail.stockProjection')
          break;
        case "Nec. SAP":
          titleLegend = intl.get('stocks.stockDetail.necSAP')
          break;
        default:
          break;
      }

      return ({
        ...graph,
        visibleInLegend,
        showBalloon: visibleInLegend,
        valueField: key,
        title: titleLegend,
      })
    })

    const newOptions = {
      ...options,
      categoryField,
      graphs,
      valueAxes: [
        {
          ...options.valueAxes[0],
          maximum: guides.Over.Ate,
          guides: [
            {
              ...options.valueAxes[0].guides[0],
              toValue: guides.Over.Ate,
              value: guides.Over.De,
            },
            {
              ...options.valueAxes[0].guides[1],
              toValue: guides.Out.Ate,
              value: guides.Out.De,
            }
          ]
        }
      ],
      titles: [
        {
          ...options.valueAxes[0],
          text: title,
        }
      ],
      dataProvider,
    }
    return newOptions
  }

  return (
    <StyledChart>
      <AmCharts.React
        style={{
          width: "100%",
          height: "300px",
          animation: 'none'
        }}
        options={prepareData(data)}
      />
    </StyledChart>
  )
}

AmChart.defaultProps = {
  categoryField: 'category',
  title: '',
}

AmChart.propTypes = {
  data: PropTypes.array.isRequired,
  categoryField: PropTypes.string,
  guides: PropTypes.object,
  title: PropTypes.string,
}

export default AmChart

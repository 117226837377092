import React, { useState } from 'react'
import intl from 'react-intl-universal'
import ComposicaoLista from '../../list';
import * as S from './styled'

const Table = ({ data, handleEdit, refreshSearch, page, take, totalComposicoes }) => {
  const [filter, setFilter] = useState('');
  const getData = () => data
    .filter(x => !filter || x.PedidosSAP.some(y => y.toLowerCase().includes(filter.toLowerCase())) || String(x.IdComposicao).includes(filter.toLowerCase()))

  return (
    <React.Fragment>
      <S.WrapperFilter>
        <S.Input
          label={intl.get('filters.search')}
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          data-testid='filter-table-composicao'
        />
      </S.WrapperFilter>
      <div className='body-composicao-carga'>
        <ComposicaoLista
          data={getData()}
          handleEdit={handleEdit}
          refreshSearch={refreshSearch}
          page={page}
          take={take}
          totalComposicoes={totalComposicoes}
        />
      </div>
    </React.Fragment>
  )
}

export default Table;

import React, { useState } from 'react'
import { Button } from 'components/uikit-adapter/index'
import { useBottlesContext } from '../../context'
import { mapperDataDtoRequestSave } from './mapper'
import intl from 'react-intl-universal'
import { getBottlesPlan, savePlanning } from '../../services/bottles-plan.service'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { formatErrorMessage } from 'utils/handle-error'
import { validatesArrayHasValue } from 'utils/custom-functions'
import moment from 'moment'
import { dataMapper } from '../bottles-edit-screen/utils'
import * as S from './styled'

export const ButtonSavePlanning = ({ hide, ...rest }) => {
  const { data, filter, lastUpdated, setLastUpdated, setData } = useBottlesContext();
  const { showFeedback } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);

  const updateTable = async () => {
    const response = await getBottlesPlan(filter)
    setData(dataMapper(response.Items))
    setLastUpdated(response.LastUpdated)
  }

  const handleSave = async () => {
    setIsLoading(true)
    try {
      const _data = mapperDataDtoRequestSave(data, filter.labeledFamilyId[0]);
      await savePlanning(_data);
      await updateTable()
      showFeedback(intl.get('master-data.feedback.savedSuccessfully'))
    } catch (e) {
      showFeedback(formatErrorMessage(e))
    }
    setIsLoading(false)
  }

  const isDisabled = () =>
    isLoading || !validatesArrayHasValue(data ? data.factories : [])

  const isValidDate = () => lastUpdated && lastUpdated !== "0001-01-01T00:00:00"

  if (hide) {
    return null
  }

  return (
    <S.Wrapper>
      {isValidDate() &&
        <S.Label>
          {`${intl.get('commons.lastUpdate')}:`}
          < br />
          {`${moment(lastUpdated).format("L")} ${intl.get('commons.at')} ${moment(lastUpdated).format("LT")}`}
        </S.Label>
      }
      <Button
        width='166px'
        type="primary"
        value={intl.get('commons.savePlanning')}
        onClick={handleSave}
        disabled={isDisabled()}
        {...rest}
      />
    </S.Wrapper>
  )
}

import * as S from './styled'
import Session from "utils/user-storage";
import { NavbarUploads } from '../navbar-upload-actions';
import { NavbarUserSettings } from '../navbar-user-settings';

export const NavbarUserActions = () => {
  return (
    <>
      <S.Container>
        <S.Username>{Session.get()?.Nome}</S.Username>
        <NavbarUploads />
        <NavbarUserSettings />
      </S.Container>
    </>
  )
}

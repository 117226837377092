import styled from 'styled-components'
import StyledGrid from 'components/styled-grid'

const Styled = styled(StyledGrid)`
  width: 100%;
  padding: 0 33px 0 33px;

  .containerListaStock {
    height: calc(100vh - 260px);
    overflow-y: auto;
  }

  .etapa-color {
    font-weight: 800;
    font-size: 11px;
  }

  .hint {
    background: #fcdfdf;
    width: 100%;
    border-radius: 23px;
    margin: 0 auto;
    padding: 5px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .containerHeader {
    font-size: 0.7rem;
    color: #9ba2ac;
    background: #FFF;
    padding: 10px 0;
    border-bottom: 1px dashed #c5d0e1;
    padding-left: 4px;
    align-items: center;
    justify-content: space-around;
  }

  .red-background {
    background: #fcdfdf;
  }

  .yellow-background {
    background: #fff2d2;
  }

  .green-background {
    background: #dcecc9;
  }

  .red-color {
    color: #de4246;
  }

  .yellow-color {
    color: #fbba49;
  }

  .red-stock {
    color: #de4246;
  }

  .green-stock {
    color: #6f9e32;
  }

  .red-border {
    box-shadow: inset 4px 0 #f9575b;

    &:hover {
      box-shadow: inset 4px 0 #b63f43;
    }
  }

  .green-border {
    box-shadow: inset 4px 0 #6f9e32;

    &:hover {
      box-shadow: inset 4px 0 #557c23;
    }
  }

  .yellow-border {
    box-shadow: inset 4px 0 #FFC107;

    &:hover {
      box-shadow: inset 4px 0 #b98b1f;
    }
  }

  .rowStocks {
    font-size: 12px!important;
    min-height: 50px;
    max-height: 50px;
    align-items: center;
    background-color: white;
  }

  .programado {
    margin-top: 5px;
    text-align: center;
  }
`

export default Styled

import styled from 'styled-components'

const SuggestionsListStyled = styled.div`

  .suggestion-list-header {
    padding: 5px 0;

    &:not(.isItem) {
      padding: 5px 0;
      border-bottom: 1px dashed #c5d0e1;

      .header-item {
        font-size: 12px;
        color: #95a3b7;
        font-weight: 600;
        text-transform: uppercase;
      }

      .header-actions .header-actions--collapse {
        border: 0;
        outline: 0;
        padding: 5px 10px;
        cursor: pointer;
        box-shadow: 0 0 0 1px #fff;
        background-color: transparent;
      }
    }
  }

  .table-list-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      margin-left: 10px;
    }
  }

  .etb-image-alert-suggestion-list {
    margin-right: 4px;
  }

  .no-data{
    margin-top: 10px;
    margin-bottom: 10px;
  }
`

export default SuggestionsListStyled

import styled from "styled-components"

export const PrimaryMaterialScheduleWrapper = styled.div`
  input[type="date"] {
    padding:3px;
    margin: 0px;
    & ::-webkit-inner-spin-button,
    & ::-webkit-calendar-picker-indicator {
        margin: 0px;
        margin-right: 1px;
        cursor: pointer;
      }
    }
`
export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
  margin: 100px auto;

  h2{
    font-weight: 400;
  }

  img{
    width: 200px;
  }

  @media(max-height: 600px){
    .no-data-image{
      display: none;
    }
  }
`

import Fetch from "utils/fetch";

export const getData = ({ dataDe, dataAte, idUnidadeNegocio, doorDescription }) =>
  Fetch.get(
    `/cronogramaEntrega/com-composicoes-carga?idunidadenegocio=${idUnidadeNegocio}&datade=${dataDe}&dataate=${dataAte}&doordescription=${doorDescription}`
  ).then(({ data }) => data);

export const getPedidos = ({ IdUnidadeNegocio, IdFornecedor, IdComposicao, idUsuario }) =>
  Fetch.get(`/pedido/listapedidosmesmocontratoporunidadenegocio/${IdUnidadeNegocio}/${idUsuario}/${IdFornecedor}/${0}/${IdComposicao}`)
    .then((response) => response.data);

import React, { useState } from 'react'
import LoadCompositionModal from './load-composition-modal'
import TableList from 'components/table-list'
import { Columns } from './columns'
import { useOrdersContext } from '../../context'
import { buildListItem } from '../../utils'
import { Grid } from './styled'

const OrdersList = ({ data }) => {
  const {
    checkbox,
    setCheckbox
  } = useOrdersContext()

  const [modal, setShowModal] = useState(false)
  const [order, setOrder] = useState({})

  const handleAction = (record) => {
    setOrder(record)
    setShowModal(true)
  }

  const handleChange = (checked, id) => {
    if (id) {
      if (!checkbox.includes(id)) {
        setCheckbox(prevState => [...prevState, id])
      } else {
        const selecteds = checkbox.filter(orderCheckbox => orderCheckbox !== id)
        setCheckbox([...selecteds])
      }
    }

    if (checked && !id) {
      setCheckbox([...data.map(i => i.Id)])
    }

    if (!checked && !id) {
      setCheckbox([])
    }
  }

  return (
    <React.Fragment>
      <Grid>
        <TableList
          scope='Orders'
          columns={Columns()}
          data={data.map(item => buildListItem(item))}
          onChange={handleChange}
          selected={checkbox}
          borderColor={'#ffc107'}
          action={handleAction}
        />
      </Grid>
      <LoadCompositionModal
        idComposicao={order.LoadCompositionId}
        readMode={order.IsReadModeLoadComposition}
        open={modal}
        close={() => setShowModal(false)}
      />
    </React.Fragment>
  )
}

export default OrdersList



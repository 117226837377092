import styled from 'styled-components'

export const Container = styled.div`
  margin: 0 auto;
  padding-bottom: 12px;
  max-height: 50vh;
  overflow: hidden auto;
  padding: 0px 10px;
  
  .row {
    width: 620px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: -1px;
  margin-top: 10px;
  background: #F5F6FA;
  padding: 3px;

  button {
    margin-top: 5px;
  }

`

import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

export const TableWrapper = styled.div`
  width: ${p => p.width ? p.width : '100%'};
  height: ${p => p.height ? p.height : 'auto'};
  background: #FFFFFF;
  position: relative;
  margin: 0 auto;

  .wrapper__row {
    margin-bottom: 3px;
  }

`

export const TableScrollWrapper = styled(ScrollContainer)`
  display:flex;
  position: static;
  flex-direction: column;
`

export const Title = styled.div`
  position: absolute;
  background: white;
  display: flex;
  align-items: center;
  margin-top: ${p => p.isFirst ? '53px' : '0px'};
  margin-left: ${p => p.isHeader ? '100px' : '0px'};
  justify-content: center;
  border-left: 1px dotted #C5D0E1;
  border-right: 1px dotted #C5D0E1;
  box-shadow: 0 4px 2px -2px #C5D0E1;
  width: ${p => p.isHeader ? '125px' : '100px'};
  height: ${p => p.qnt ? getSizeTitleHeader(p.qnt) : '45px'};
  z-index: 11;
  ${p => (p.isHeader && p.qnt) && getStringSizeForSecondHeader(p.qnt)};
  text-align: center;
`

export const DestinoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 125px;
  justify-content: center;
  background: white;
`

export const TableCell = styled.div`
  width: 80px;
  height: 40px;
  margin: 3px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  border-right: 1px dashed #C5D0E1;
  background: ${p => p.background ? p.background : '#DCECC9'};
  border-radius: 3px;
  align-items: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;


  span {
    color: ${p => p.color ? p.color : '#557C23'};
    font-weight: 400;
    font-size: 12px;
    cursor: auto;
    font-style: normal;
    line-height: 18px;
    align-items: center;
    text-transform: uppercase;
    margin-left: 3px;
  }
  .title{
    font-weight: 600;
  }

  .entry-title {
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 110px;
    cursor: pointer;
  }
`

export const TableCellEffect = styled.div`
  span{
    align-self: baseline;
    font-weight: 400;
  }
  #detail{
    display: none;
  }
  :hover{
    #detail{
      position: relative;
      display: flex;
      min-width: 180px;
      min-height: 80px;
      height: auto;
      width: auto;
      z-index: 10;
    }
  #resumo{
    display: none;
    }
  }
`

export const TableRow = styled.div`
  display: flex;
  flex-flow: row;
  ${p => p.isTotal && getShadowTotalColumn()}
`

export const TableContent = styled.div`
  display: flex;
  flex-flow: row;
  margin-left: 225px;
  justify-content: center;
`

export const LastColumnSpacing = styled.div`
  width: 178px;
  position: relative;
  right: 0;
`
export const TableHeaders = styled.div`
  display: flex;
  flex-flow: column;
`

export const TableColumn = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .monthly-title {
    text-transform: capitalize;
  }
`

export const TableHeading = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  background-color: #6197C6;
  color: white;
  font-weight: bold;
  font-size: 14px;
  box-shadow:5px 0px 16px -11px rgb(0 0 0 / 65%);
  border-right: 1px dashed #FFFFFF;
  height: 52px;
  align-items: center;
  justify-content: center;
  margin-left: ${p => p.second ? '100px' : '0px'};
  width: ${p => p.width ? p.width : 'calc(100% - 1px)'};
  position: ${p => p.isHeader ? 'absolute' : 'relative'};
  z-index: ${p => p.isHeader ? '10' : '2'};
  span{
    cursor: auto;
  }
`

export const GridWrapper = styled.div`
	display: grid;
  grid-template-columns: repeat(2, 88px);
  grid-template-rows: ${p => p.qnt ? getTemplatesRows(p.qnt) : 'repeat(2, 46px)'};
  box-shadow: 0 4px 2px -2px ${p => p.isTotal ? '#FFFFFF' : '#C5D0E1'};
  border-left: 1px dotted #C5D0E1;
  border-right: 1px dotted #C5D0E1;
  background: ${p => p.isTotal ? '#AFCEF3' : '#FFFFFF'};
  cursor: pointer;
`

export const TableDataWrapper = styled.div``

export const TotalColumnWrapper = styled.div`
  position: absolute;
  right: 0;
  z-index: 11;
`

export const TableHeaderLabeledFamily = styled.div `
  display: flex;
  flex-direction: column;
  padding: 3px;
  
  .stock__checked {
    font-size: 12px;
    color: #FFAA00;
    display: flex;

    img{
      position: relative;
      left: 5px;
    }
  }

`

const getTemplatesRows = (qnt) => `repeat(${qnt}, 48px)`
const getSizeTitleHeader = (qnt) => `${qnt * 48}px`
const getStringSizeForSecondHeader = (qnt) => `height: ${qnt ? getSizeTitleHeader(qnt) : '0px'}`;
const getShadowTotalColumn = () => `filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));`

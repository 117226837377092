import {builderLoadsAwaintingLoadDto, builderLoadsReleasedDto, builderLoadsSuggestionsDto } from "../utils/builder"

export const typesSuggestion = {
  AwaitLoadComposition: 1,
  Suggestions: 2,
  Liberado: 3
}

export const filterDefault = {
  pagination: {
    page: 1,
    take: 20,
    total: 0
  },
  idFornecedor: null,
  carteira: null,
  material: null,
  typeSuggestionSelected: typesSuggestion.Suggestions
}

export const getParamsForRequestByCardType = (filter, params) => {
  let requestData = { dto: undefined, isReleased: false }
  switch (filter.typeSuggestionSelected) {
    case typesSuggestion.AwaitLoadComposition:
      requestData = { dto: builderLoadsAwaintingLoadDto(params, filter), isReleased: false }
      break;
    case typesSuggestion.Suggestions:
      requestData = { dto: builderLoadsSuggestionsDto(params, filter), isReleased: false }
      break;
    case typesSuggestion.Liberado:
      requestData = { dto: builderLoadsReleasedDto(params, filter), isReleased: true }
      break;
    default:
      break;
  }
  return requestData;
}

import React, { Component } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { withRouter } from 'react-router-dom'

import { Textarea, Input, Button } from '@hbsis.uikit/react'
import { SelectBox } from "components/uikit-adapter/index"

import IconTrash from 'images/icn-trash-bin-white.svg'
import IconSaveBlue from 'images/icn-save-blue.svg'

import PropTypes from 'prop-types'
import SuggestionsListHeader from './../suggestions-list-header'
import OptionGroup from 'components/option-group'
import DeadlineControl from 'components/deadline-control'
import StyledSuggestionsListItem from './suggestions-list-item.styled'
import { getDataFormat, DATA_FORMAT_TYPES } from 'utils/format-date'
import intl from 'react-intl-universal'
import IcnCancelWhite from "images/cancel-white.svg";

import './suggestions-list-item.css'
import { formatNumber } from 'utils/format'
import { ButtonLoadCompositionPlus } from '../../button-load-composition-plus'
import { mapperItemDataForLoadCompositionModal, shouldDisableTransferInputs } from './utils'
import InputNumber from 'components/input-number';
import { validatesArrayHasValue } from 'utils/custom-functions'
import { getDefaultDocumentType, getDocumentTypeOptions } from 'pages/stocks-detail/utils'
import { usesJustificationValidationAcceptingSuggestion, usesTransferOrder } from 'utils/validations-general-settings'
import { getDoorObjectByName, getDoorsOptions, isDoorsDisabled } from 'utils/doors'

const ORIGEM_XADREZ = { value: 1 }
const ORIGEM_UNIDADES = { value: 2 }
const TIPO_UNIDADE_FORNECEDORA = { value: 2 }
const SUGESTAO_MANUAL = 3
const SUGESTAO_ALTERADA = 4
const isUnidadeFornecedora = (tipoUnidade) =>  tipoUnidade === TIPO_UNIDADE_FORNECEDORA.value

class SuggestionsListItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      checked: false,
      optionsTypesSuppliers: {
        id: 0,
        optionOne: {
          name: intl.get('stocks.suggestionsListItem.chess'),
          value: 1,
          isChecked: true
        },
        optionTwo: {
          name: intl.get('stocks.suggestionsListItem.transferUnits'),
          value: 2,
          isChecked: false,
          hideOption: !usesTransferOrder()
        }
      },
      fornecedores: [],
      origem: 1,
      transiTimeErroMessage: false,
      incotermOptions: [
        { Key: 1, Value: 'CIF' },
        { Key: 2, Value: 'FOB' }
      ],
      tipoUnidade: 0,
      transitTime: props.item.TransitTime
    }
  }

  componentDidMount() {
    const { item } = this.props

    this.loadSuppliersListAndOrigin(item.OrigemFornecedor, item.IdMaterial, item);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      JSON.stringify(this.props) !== JSON.stringify(nextProps) ||
      JSON.stringify(this.state) !== JSON.stringify(nextState)
    )
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (nextProps.checked !== undefined && nextProps.checked !== this.props.checked) {
      this.setState({
        checked: nextProps.checked
      })
    }

    if (nextProps.unCheckItem !== this.props.unCheckItem && nextProps.unCheckItem) {
      this.setState({
        checked: false
      })
    }
  }

  checkItem = (event) => {
    this.setState({ checked: !this.state.checked }, () => { this.props.checkItem(this.props.item, this.state.checked) })
  }

  expandItems = (eventTarget) => {
    this.props.expandItem(
      eventTarget,
      this.props.item,
      this.props.item.itemsExpanded,
      false,
      false,
      this.props.itemIndex
    )
  }

  changeMaterial = (prop, idMaterial, item) => {
    item.IdMaterial = idMaterial;

    this.loadSuppliersListAndOrigin(this.state.origem, idMaterial, item).then(
      () => {
        item.IdFornecedor = this.state.fornecedores[0].Id;
        this.props.changeValueSugestao(prop, idMaterial, item)
        this.changeFornecedor(item.IdFornecedor, item)
      }
    )
  }

  changeFornecedor = (idFornecedor, item) => {

    const material = item.Materiais.find(m => m.Id == item.IdMaterial)
    const fornecedor = material.Fornecedores.find(f => f.Id == idFornecedor)

    this.props.changeValueSugestao('IdFornecedor', idFornecedor, item)

    const newDefaultDocumentType = getDefaultDocumentType(this.props.documentTypes, item.Materiais, item.IdMaterial, idFornecedor);
    this.props.changeValueSugestao("DocumentType", newDefaultDocumentType, { ...item, IdFornecedor: idFornecedor })

    this.setState({
      tipoUnidade: fornecedor ? fornecedor.TipoUnidade : 0,
      transitTime: fornecedor ? fornecedor.TransitTime : 2
    })
  }

  selectOptionTypeSuppliers = (origem, idMaterial, item) => {
    item.GrupoCompradores = null
    item.OrganizacaoCompras = null
    item.CodigoImposto = null

    this.loadSuppliersListAndOrigin(origem, idMaterial, item).then(() => {
      let idFornecedor = this.state.fornecedores.length > 0 ? this.state.fornecedores[0].Id : null;
      this.changeFornecedor(idFornecedor, item)
    })
  }

  loadSuppliersListAndOrigin = (origem, idMaterial, item) => {
    return new Promise(resolve => {
      const material = item.Materiais.find(m => m.Id === idMaterial)

      let optionsTypesSuppliers = this.state.optionsTypesSuppliers
      optionsTypesSuppliers.id = item.Id

      let fornecedores = []

      if (origem == ORIGEM_UNIDADES.value) {
        fornecedores = material.Fornecedores.filter(f => f.Origem == ORIGEM_UNIDADES.value)
        optionsTypesSuppliers.optionOne.isChecked = false
        optionsTypesSuppliers.optionTwo.isChecked = true
      } else {
        fornecedores = material.Fornecedores.filter(f => f.Origem == ORIGEM_XADREZ.value)
        optionsTypesSuppliers.optionOne.isChecked = true
        optionsTypesSuppliers.optionTwo.isChecked = false
      }
      this.setState({
        fornecedores,
        origem,
        optionsTypesSuppliers
      }, () => resolve())
    })
  }

  salvarSugestao(sugestao) {
    if (this.state.origem == ORIGEM_UNIDADES.value && (!sugestao.CodigoImposto || !sugestao.GrupoCompradores || !sugestao.OrganizacaoCompras))
      this.props.handleFeedback(intl.get('stocks.timeline.feedback.transferInformationMandatory'))
    else if ((sugestao.StatusSigla === 'SM' || sugestao.StatusSigla === 'SA') && sugestao.TipoJustificativaSugestaoManual === null && usesJustificationValidationAcceptingSuggestion())
      this.props.handleFeedback(intl.get('stocks.timeline.feedback.providerJustification'))
    else if (((sugestao.StatusSigla !== 'SM' && sugestao.StatusSigla !== 'SA') && sugestao.itemAlterado) && sugestao.TipoJustificativaSugestaoManual === null && usesJustificationValidationAcceptingSuggestion())
      this.props.handleFeedback(intl.get('stocks.timeline.feedback.providerJustification'))
    else {
      this.props.salvarSugestao({
        ...sugestao
      })
    }
  }

  addRowOriginSaz = (item, optionsTypesSuppliers) => {
    return (
      <Row>
        <Col xs={12}>
          <OptionGroup
            title={intl.get('stocks.suggestionsListItem.origin')}
            handleSelect={event => this.selectOptionTypeSuppliers(event.currentTarget.value, item.IdMaterial, item)}
            options={optionsTypesSuppliers}
            className="optionGroup-origin"
          />
        </Col>
      </Row>
    )
  }

  addColDeliveryHour = (item) => {
    return (
      <Col xs={6}>
        <Input
          label={intl.get('commons.deliveryTime')}
          name='HoraEntrega'
          className='input-hora-entrega'
          htmlType='time'
          value={item.HoraEntrega}
          onChange={event => this.props.changeHoraEntrega('HoraEntrega', event.target.value, item)}
        />
      </Col>
    )
  }

  addRowJustificationSuggestionManual = (item, listaJustificativasSugestaoManual) => {
    return (
      usesJustificationValidationAcceptingSuggestion() &&
      <Row>
        <Col xs={12}>
          <SelectBox
            name='tipoJustificativaSugestaoManual'
            label={intl.get('stocks.suggestionsListItem.justificationChangingSuggestion')}
            placeholder={intl.get('stocks.suggestionsListItem.justification')}
            value={item.TipoJustificativaSugestaoManual}
            options={listaJustificativasSugestaoManual}
            onChange={value => this.props.changeValueSugestao('TipoJustificativaSugestaoManual', value.Key, item)}
            valueKey='Key'
            labelKey='Value'
            className='select-justificativa-sugestao'
            searchable
            openOnFocus
          />
        </Col>
      </Row>
    )
  }

  suggestionsListHeaderIsChecked = (checked, item) => checked && item.Coleta && item.DataEntregaDe && !item.naoSalvo;

  suggestionsListHeaderEnableCheckedValidate = item => item.naoSalvo || !item.Coleta || !item.DataEntregaDe;

  disabledSelectBoxIncoterm = (origem, tipoUnidade) => origem === ORIGEM_UNIDADES.value || tipoUnidade === TIPO_UNIDADE_FORNECEDORA.value;

  getClassNameWrapper = () => this.props.item.itemsExpanded ? 'item-expanded' : ''

  showDocumentTypesSelectBox = () => validatesArrayHasValue(this.props.documentTypes);

  render() {
    const {
      listaJustificativasSugestaoManual,
      excluirSugestao,
      item,
      highlighted,
      itemDimmed,
      showBtnCancel,
      linhaProducaoCentro,
      documentTypes,
      doors
    } = this.props

    const {
      checked,
      optionsTypesSuppliers,
      fornecedores,
      origem,
      incotermOptions,
      tipoUnidade,
      transitTime
    } = this.state

    const selectedSupplier = fornecedores.find((supplier) => supplier.Id === item.IdFornecedor)

    return (
      <StyledSuggestionsListItem className={this.getClassNameWrapper()}>
        <SuggestionsListHeader
          isItem
          hasIcon
          isOrder={false}
          itemsExpanded={item.itemsExpanded}
          checked={this.suggestionsListHeaderIsChecked(checked, item)}
          enableCheck={this.suggestionsListHeaderEnableCheckedValidate(item)}
          idCheckbox={item.Id}
          checkItem={this.checkItem}
          highlighted={highlighted}
          itemDimmed={itemDimmed}
          expandAllItems={this.expandItems}
        >
          <Row middle='xs'>
            <Col xs={2}>
              <div title={item.Status} className='header-item'>
                {item.StatusSigla}
              </div>
            </Col>
            <Col xs={2}>
              <div title={item.TipoDescricao} className='header-item'>
                {item.Tipo}
              </div>
            </Col>
            <Col xs={2}>
              <div title={getDataFormat(item.Coleta, DATA_FORMAT_TYPES.WITH_YEAR)} className='header-item'>
                {getDataFormat(item.Coleta, DATA_FORMAT_TYPES.WITHOUT_YEAR)}
              </div>
            </Col>
            <Col xs={2}>
              <div title={getDataFormat(item.DataEntregaDe, DATA_FORMAT_TYPES.WITH_YEAR)} className='header-item'>
                {getDataFormat(item.DataEntregaDe, DATA_FORMAT_TYPES.WITHOUT_YEAR)}
              </div>
            </Col>
            <Col xs={2}>
              <div className='header-item'>
                {formatNumber(item.Total)}
              </div>
            </Col>
            <Col xs={2}>
              <div className='header-item'>{item.UnidadeMedida}</div>
            </Col>
          </Row>
        </SuggestionsListHeader>
        <div
          className='table-list-item-body'
          style={{ display: item.itemsExpanded ? 'block' : 'none' }}
        >
          <Row>
            <Col xs={12}>
              <SelectBox
                name='material'
                label={intl.get("commons.material")}
                placeholder={intl.get('commons.material')}
                value={item.IdMaterial}
                options={item.Materiais}
                onChange={value => this.changeMaterial('IdMaterial', value.Id, item)}
                valueKey='Id'
                labelKey='MaterialPesquisa'
                searchable
                openOnFocus
              />
            </Col>
          </Row>

          {this.addRowOriginSaz(item, optionsTypesSuppliers)}

          <Row>
            <Col xs={12}>
              <SelectBox
                name='fornecedor'
                label={intl.get('commons.supplier')}
                placeholder={intl.get('stocks.suggestionsListItem.supplier')}
                value={item.IdFornecedor}
                options={fornecedores}
                onChange={value => this.changeFornecedor(value.Id, item)}
                valueKey='Id'
                labelKey='Descricao'
                searchable
                openOnFocus
              />
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <DeadlineControl
                TituloColeta={intl.get('stocks.suggestionsListItem.collect')}
                TituloEntrega={intl.get('stocks.suggestionsListItem.issue')}
                TransitTime={transitTime}
                OnChange={(coleta, entrega) => this.props.changeValueDeadline(coleta, entrega, item)}
                Coleta={item.Coleta}
                DataEntregaDe={item.DataEntregaDe}
                Identificador={`sugestao-` + item.Id}
              />
            </Col>
          </Row>

          <Row>
            {this.addColDeliveryHour(item)}

            <Col xs={this.showDocumentTypesSelectBox() ? 6 : 12}>
              <SelectBox
                required
                label={intl.get('stocks.suggestionsListItem.incoterm')}
                placeholder={intl.get('stocks.suggestionsListItem.incoterm')}
                name='Incoterm'
                value={item.ResponsavelEntrega}
                valueKey='Key'
                labelKey='Value'
                onChange={value => this.props.changeValueSugestao('ResponsavelEntrega', value.Key, item)}
                openOnFocus={true}
                options={incotermOptions}
                width="100%"
                disabled={this.disabledSelectBoxIncoterm(origem, tipoUnidade)}
              />
            </Col>

            {this.showDocumentTypesSelectBox() && (
              <Col xs={6}>
                <SelectBox
                  required
                  label={intl.get("stocks.suggestionsListItem.documentType")}
                  placeholder={intl.get("stocks.suggestionsListItem.documentType")}
                  name="DocumentType"
                  value={item.DocumentType}
                  valueKey="DocumentTypeEnumValue"
                  labelKey="DocumentTypeEnumDescription"
                  onChange={(value) => this.props.changeValueSugestao("DocumentType", value.DocumentTypeEnumValue, item)}
                  openOnFocus={true}
                  options={getDocumentTypeOptions(documentTypes, item.Materiais, item.IdMaterial, item.IdFornecedor)}
                  width="100%"
                />
              </Col>
            )}
          </Row>

          {item.ShowTransitTimeDateWarn && (
            <Row>
              <Col>
                <label className='transit-time-date-warn'>
                  {intl.get('stocks.suggestionsListItem.transitTime')}
                </label>
              </Col>
            </Row>
          )}

          <Row>
            <Col xs={3}>
              <Input
                label={intl.get('stocks.suggestionsListItem.lotSize')}
                name='tamLote'
                htmlType='text'
                value={formatNumber(item.QuantidadeLote)}
                disabled
              />
            </Col>
            <Col xs={3}>
              <InputNumber
                value={item.Quantidade}
                onChange={value => this.props.changeValueSugestao('Quantidade', value, item)}
                className='input-received-quantity'
                label={intl.get('stocks.suggestionsListItem.quantity')}
                type='default'
                allowDecimals
                decimalsLimit={3}
              />
            </Col>
            <Col xs={3}>
              <InputNumber
                type='default'
                label={intl.get('stocks.suggestionsListItem.cars')}
                name='carros'
                value={item.QuantidadeCarros}
                allowDecimals
                decimalsLimit={3}
                disabled
              />
            </Col>
            <Col xs={3}>
              <InputNumber
                type='default'
                label={intl.get('stocks.suggestionsListItem.total')}
                name='total'
                value={item.Total}
                onChange={value => this.props.changeValueSugestao('Total', value, item)}
                allowDecimals
                decimalsLimit={3}
              />
            </Col>
            <React.Fragment>
              <Col xs={4}>
                <SelectBox
                  name='Door'
                  label={intl.get("commons.door")}
                  value={getDoorObjectByName(doors, item.PortaWMS)}
                  valueKey='Id'
                  labelKey='Name'
                  options={getDoorsOptions(doors)}
                  onChange={event => this.props.changeValueSugestao('PortaWMS', event.Name, item)}
                  className="input-door-wms"
                  disabled={isDoorsDisabled(doors, item.PortaWMS)}
                />
              </Col>
              <Col xs={4}>
                <SelectBox
                  name='ProductionLine'
                  label={intl.get("orders.productionLine")}
                  placeholder={intl.get("orders.productionLine")}
                  valueKey='key'
                  labelKey='productionLine'
                  value={item.LinhaDeProducao ? { productionLine: item.LinhaDeProducao } : undefined}
                  options={linhaProducaoCentro}
                  onChange={event => this.props.changeValueSugestao('LinhaDeProducao', event.productionLine, item)}
                  className='selectBoxProductionLine'
                  clearable
                />
              </Col>
              <Col xs={4}>
                <Input
                  label={intl.get('orders.comments')}
                  name="comments"
                  value={item.Comentarios}
                  onChange={event => this.props.changeValueSugestao('Comentarios', event.target.value, item)}
                  maxLength='40'
                  className='textAreaComments'
                />
              </Col>
            </React.Fragment>
          </Row>

          {(isUnidadeFornecedora(tipoUnidade) || origem == ORIGEM_UNIDADES.value) &&
            <div>
              <label className='label-style'>{intl.get('stocks.suggestionsListItem.driveTransferInformation')}</label>
              <div className='container'>
                <Row>
                  <Col xs={4}>
                    <Input
                      label={intl.get('stocks.suggestionsListItem.purchasingOrganization')}
                      name='organizacaoCompras'
                      htmlType='text'
                      value={origem == ORIGEM_UNIDADES.value ? item.OrganizacaoCompras : selectedSupplier?.OrganizacaoCompras}
                      onChange={event => this.props.changeValueSugestao('OrganizacaoCompras', event.target.value.toUpperCase(), item)}
                      maxLength={4}
                      disabled={shouldDisableTransferInputs(origem, tipoUnidade)}
                    />
                  </Col>
                  <Col xs={4}>
                    <Input
                      label={intl.get('stocks.suggestionsListItem.buyerGroup')}
                      name='grupoCompradores'
                      value={origem == ORIGEM_UNIDADES.value ? item.GrupoCompradores : selectedSupplier?.GrupoCompradores}
                      onChange={event => this.props.changeValueSugestao('GrupoCompradores', event.target.value.toUpperCase(), item)}
                      maxLength={3}
                      disabled={shouldDisableTransferInputs(origem, tipoUnidade)}
                    />
                  </Col>
                  <Col xs={4}>
                    <Input
                      label={intl.get('stocks.suggestionsListItem.taxCode')}
                      name='codigoImposto'
                      value={origem == ORIGEM_UNIDADES.value ? item.CodigoImposto : selectedSupplier?.CodigoImposto}
                      onChange={event => this.props.changeValueSugestao('CodigoImposto', event.target.value.toUpperCase(), item)}
                      maxLength={2}
                      disabled={shouldDisableTransferInputs(origem, tipoUnidade)}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          }

          {((item.IntStatus === SUGESTAO_MANUAL || item.IntStatus === SUGESTAO_ALTERADA) || item.itemAlterado) &&
            this.addRowJustificationSuggestionManual(item, listaJustificativasSugestaoManual)
          }

          <Row>
            <Col xs={12}>
              <Textarea
                label={intl.get('stocks.suggestionsListItem.observation')}
                name='observacao'
                value={item.Observacao || ''}
                onChange={event => this.props.changeValueSugestao('Observacao', event.target.value, item)}
              />
            </Col>
          </Row>

          <Row end='xs'>
            <Col xs={12} className='table-list-footer'>
              <div>
                <ButtonLoadCompositionPlus
                  isFullTruckLoad={item.Total === item.QuebraEntrega}
                  item={mapperItemDataForLoadCompositionModal(item, this.props.idUnidadeNegocio, false, item.IdFornecedor)}
                  history={this.props.history}
                  hidden={this.suggestionsListHeaderEnableCheckedValidate(item)}
                />
              </div>
              <div title={intl.get('stocks.suggestionsListItem.deleteSuggestion')}>
                <Button
                  icon={showBtnCancel ? IcnCancelWhite : IconTrash}
                  width='50px'
                  value=''
                  type='danger'
                  onClick={() => excluirSugestao(item)}
                  className="btn-delete-suggestion-item"
                />
              </div>
              <div title={intl.get('stocks.suggestionsListItem.saveSuggestion')} data-testid='wrapper-btn-save-suggestion-item'>
                <Button
                  icon={IconSaveBlue}
                  width='50px'
                  value=''
                  type='secondary'
                  onClick={() => this.salvarSugestao(item)}
                />
              </div>
            </Col>
          </Row>
        </div>
      </StyledSuggestionsListItem >
    )
  }
}

SuggestionsListItem.propTypes = {
  item: PropTypes.object.isRequired,
  checkItem: PropTypes.func,
  expandItem: PropTypes.func,
  checked: PropTypes.bool,
  unCheckItem: PropTypes.bool,
  salvarSugestao: PropTypes.func,
  excluirSugestao: PropTypes.func,
  changeValueSugestao: PropTypes.func,
  obterListaJustificativasSugestaoManual: PropTypes.func,
  changeValueDeadline: PropTypes.func,
  materiais: PropTypes.array,
  expanded: PropTypes.bool,
  highlighted: PropTypes.bool,
  itemDimmed: PropTypes.bool,
  itemIndex: PropTypes.number,
  handleFeedback: PropTypes.func,
  changeHoraEntrega: PropTypes.func
}

export default withRouter(SuggestionsListItem)

import Moment from 'moment';
import { extendMoment } from 'moment-range';

export const getFormattedHours = (period) => {
  const moment = extendMoment(Moment);
  const range = moment.range(`2022-01-01 ${period.horaInicial}`, `2022-01-01 ${period.horaFinal}`);
  const hours = Array.from(range.by('minutes', { step: 30 }));
  return hours.map(m => m.format('HH:mm'))
}

export const getFormattedWeekday = () => {
  const moment = extendMoment(Moment);
  const range = moment.range('2021-12-13 00:00', '2021-12-19 00:00');
  const days = Array.from(range.by('day'));
  return days.map(m => moment(m).weekday())
}

export const optionsHours = () => [
  {
    hora: `${Moment('00:00', "HH:mm").format("LT")} - ${Moment('11:30', "HH:mm").format("LT")}`,
    key: 1 ,
    horaInicial: '00:00',
    horaFinal: '11:30'
  },
  { hora:
    `${Moment('12:00', "HH:mm").format("LT")} - ${Moment('23:30', "HH:mm").format("LT")}`,
    key: 2,
    horaInicial: '12:00',
    horaFinal: '23:30'
  }
]

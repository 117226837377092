import styled from 'styled-components'

export const Wrapper = styled.div`
  .suggestion-list-header {
    box-sizing: border-box;
    padding-bottom: 5px;
  }

  .stock-title{
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    color: #9AA4AD;
    text-transform: uppercase;
  }

  .stock-content{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 15px;
    color: #3B495E;
  }

  border-bottom: ${props => !props.itemExpanded ? '1px solid #EDEEEE' : '1px none #EDEEEE'};
  margin-bottom: 10px;
`

export const TitlesWrapper = styled.div`
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;

  padding: 0px 5px;

  span:last-child{
    padding-top: 5px;
  }
`

export const ContentWrapper = styled.div`
  display: ${props => props.itemExpanded ? 'flex' : 'none'};
  padding: 0 5px;

  .content-row{
    border-top: 1px solid #EDEEEE;
    width: 100%;
    padding-bottom: 10px;
  }

  .content-col{
    border-bottom: 1px solid #EDEEEE;
    padding-bottom: 5px;
  }

  .supplier-title{
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 13px;
    color: #2870B2;

    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .last-update{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 10px;
    color: #2870B2;
  }
`

export const InfosWrapper = styled.div`
  display: flex;
  flex-direction: column;

  span{
    margin: 0px;
    padding: 10px 5px 0px 0px;
  }
`

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from "react-flexbox-grid"
import { Button } from '@hbsis.uikit/react'
import { SelectBox } from "components/uikit-adapter/index"
import Dialog from 'material-ui/Dialog'
import intl from 'react-intl-universal'
import './priorityForm-form.css'
import Loading from 'components/center-loading'

class PriorityForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showLoading: false
    }
  }

  legendName = (legend) => {
   let title = '';
   switch (legend) {
     case "1":
       title = intl.get('transports.otimizationCharge.actions.priority1');
       break;
     case "2":
       title = intl.get('transports.otimizationCharge.actions.priority2');
       break;
     case "3":
       title = intl.get('transports.otimizationCharge.actions.priority3');
       break;
     case "4":
       title = intl.get('transports.otimizationCharge.actions.priority4');
       break;
   }
   return title;
 }

 onSend = async () => {
   this.setState({showLoading: true});
   try {
    await this.props.handleSend();
   } finally {
    this.setState({showLoading: false});
   }
 }

 render() {
    const {
      open,
      systemPlanning,
      systemPlanningTypes,
      shipmentPriority,
      shipmentPriorityTypes,
      shipmentPriorityLegends
    } = this.props

    return (
      <div>
        <Dialog
          title={intl.get('transports.otimizationCharge.actions.chooseOtimizationPriority')}
          contentStyle={{ width: '600px' }}
          open={open}
          autoScrollBodyContent
        >
          <Loading isLoading={this.state.showLoading} />
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  required
                  label={intl.get('transports.otimizationCharge.actions.otimizationFlow')}
                  name='systemPlanning'
                  value={systemPlanning}
                  valueKey='Key'
                  labelKey='Value'
                  onChange={value => this.props.changeValue('systemPlanning', value.Key)}
                  openOnFocus={true}
                  options={systemPlanningTypes}
                  width="100%"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={12} md={12}>
                <SelectBox
                  required
                  label={intl.get('transports.otimizationCharge.actions.programmationPriority')}
                  name='shipmentPriority'
                  value={shipmentPriority}
                  valueKey='Key'
                  labelKey='Value'
                  openOnFocus={true}
                  options={shipmentPriorityTypes}
                  width="100%"
                  disabled
                />
              </Col>
            </Row>

            <div className='informacoes-prioridade'>
              <fieldset>
                <legend>{intl.get('transports.otimizationCharge.actions.priorityProgrammationInfo')}</legend>
                <Row>
                  <div>
                    {shipmentPriorityLegends.map((legend) => (
                      <Col xs={12}>
                        <h5>{legend.Title} = {this.legendName(legend['Title'])}</h5>
                      </Col>
                    ))}
                  </div>
                </Row>
              </fieldset>
            </div>

            <div className='footer-dialog-buttons'>
              <div className='line-buttons'>
                <Button
                  type="default"
                  value={intl.get('geral.buttonsDefault.cancel')}
                  onClick={this.props.handleClose}
                  className="button-priority-tms-form"
                />
                <Button
                  type="primary"
                  value={intl.get('geral.buttonsDefault.send')}
                  className="button-priority-tms-form"
                  onClick={this.onSend}
                />
              </div>
            </div>
        </Dialog>
      </div>
    )
  }
}

PriorityForm.propTypes = {
  open: PropTypes.bool.isRequired,
  systemPlanning: PropTypes.number.isRequired,
  systemPlanningTypes: PropTypes.array.isRequired,
  shipmentPriority: PropTypes.number.isRequired,
  shipmentPriorityTypes: PropTypes.array.isRequired,
  shipmentPriorityLegends: PropTypes.array.isRequired,
  changeValue: PropTypes.func.isRequired,
  handleSend: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default PriorityForm

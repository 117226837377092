import { Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import intl from "react-intl-universal";
import { Button } from '@hbsis.uikit/react';
import PropTypes from 'prop-types';
import PedidosAprovados from './../pedidos-aprovados';
import Fetch from 'utils/fetch';
import Session from 'utils/user-storage';
import { formatErrorMessage } from 'utils/handle-error';
import Moment from 'moment';
import Dialog from 'material-ui/Dialog';
import SnackBar from 'material-ui/Snackbar';
import Message from 'components/message';
import Loading from 'components/center-loading';
import HintList from 'components/hint-list';
import MapToHTML from 'components/map-to-html';
import DangerDialog from 'components/danger-dialog';
import './composicao-carga-form.css';
import ModalRenegociacaoComposicaoCarga from "./modal-renegociacao-composicao-carga"
import {
  buscarUnidades,
  buscarFornecedores,
  buscarComposicaoPorPedido,
  searchComposition
} from './composicao-carga-form.service'
import HeaderForm from '../components/header-form';
import Feature from '../../../components/feature';
import FormCreateGRN from '../form-create-grn';
import icnClose from 'images/close-icone.svg'
import TipoUsuario from '../../../models/usuarios/tipo-usuario'
import { formatNumber, formatWithoutSpaces } from 'utils/format';
import { EmergencyRenegotiate } from './emergency-renegotiate';
import { isLoadCompositionFlowBrewerySide, isScheduleAgreementFlow, usesCreateGRN, usesEmergencyNegotiation } from 'utils/validations-general-settings';
import { validateShowButtonEmergencyNegotiateSupplierSide, validateShowButtonEmergencyNegotiationAmbevSide } from './rules-actions';
import { SituacaoComposicaoCarga } from 'models/composicaoCarga/situacaoComposicaoCarga';

const SituacaoValor = {
  Negociacao: 1,
  Criado: 4,
  AguardandoPCSap: 5,
  AguardandoRevisaoFornecedor: 6
}

class ComposicaoCargaForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      composicaoInicial: {},
      composicao: {
        DataColeta: '',
        DataEntrega: '',
        QuantidadeTotal: 0,
        Observacao: '',
        usuarioPossuiPermissaoParaExcluirPedido: false,
        HoraEntrega: '',
        DataEntregaSugerida: '',
        HoraEntregaSugerida: '',
        ObservacaoSugerida: '',
        Door: ''
      },
      modoEdicao: !!props.idEdit,
      modoLeitura: props.modoLeitura,
      responsavelProximaAcao: props.responsavelProximaAcao,
      pedidos: [],
      pedidosFiltrados: [],
      unidadesNegocio: [],
      requestCount: 0,
      messageError: '',
      textMsg: '',
      textValidationMsg: '',
      titleMsg: '',
      showCancelConfirm: false,
      showModalConfirmacao: false,
      mostrarNotificacaoMaterialDuplicado: false,
      justificationMessages: [],
      suppliers: [],
      showMsg: false,
      handleClose: () => { this.setState({ showMsg: false }) },
      showModalRenegociacaoComposicaoCarga: false,
      modalCreateGRN: {
        isOpen: false,
      },
      emergencyRenegotiate: {
        isOpen: false,
      },
      initialCancelmentLoadsProps: {
        url: '',
        buttonLabel: ''
      }
    }
  }

  componentDidMount() {

    const { idEdit, idPedido } = this.props

    this.buscarUnidades()

    if (this.state.composicao !== null && this.state.composicao.IdFornecedor)
      this.searchSuppliers()

    const { modoEdicao } = this.state

    if (modoEdicao) {
      this.searchComposition(idEdit)
    } else if (idPedido) {
      this.buscarComposicaoPorPedido(idPedido)
    }
    this.searchMessages()
    this.setPermissaoParaExcluirPedido()
    this.cancelCompositionFlow()
  }

  setPermissaoParaExcluirPedido = () => {
    let location = this.props.location.state
    if ((typeof location !== 'undefined') && (typeof location.usuarioPossuiPermissaoParaExcluirPedido !== 'undefined')) {
      this.setState({
        usuarioPossuiPermissaoParaExcluirPedido: this.props.location.state.usuarioPossuiPermissaoParaExcluirPedido
      })
    }
  }

  buscarComposicaoPorPedido = async (idPedido) => {
    try {
      const { idUser } = this.props
      this.handleFetch();

      const data = await buscarComposicaoPorPedido(idUser, idPedido)
      const composicao = {
        ...data,
        DataColeta: data.DataColeta ? Moment(data.DataColeta).format('YYYY-MM-DD') : '',
        DataEntrega: data.DataEntrega ? Moment(data.DataEntrega).format('YYYY-MM-DD') : ''
      }

      this.setState({
        composicao,
        composicaoInicial: composicao,
        modoLeitura: data.ModoLeitura,
        modoEdicao: !!data.IdComposicao
      }, () => {
        this.searchSuppliers()
        this.buscarPedidos(data)
      })
    } finally {
      this.handleFetch(false)
    }
  }

  buscarPedidos = (composicao) => {
    if (composicao.ModoLeitura && composicao.SituacaoValor > 0) {
      this.searchComposition(composicao.IdComposicao)
    } else {
      this.searchRequests(composicao.IdUnidadeNegocio, composicao.IdFornecedor)
    }
  }

  searchComposition = async (id) => {
    try {
      this.handleFetch()

      const data = await searchComposition(id)

      const composicao = {
        ...data,
        DataColeta: data.DataColeta ? Moment(data.DataColeta).format('YYYY-MM-DD') : '',
        DataEntrega: data.DataEntrega ? Moment(data.DataEntrega).format('YYYY-MM-DD') : ''
      }
      this.setState({
        composicao,
        composicaoInicial: composicao
      })

      this.searchSuppliers()
      this.searchRequests()
    } catch (error) {
      this.handleShowFeedback(formatErrorMessage(error))
    }
    finally {
      this.handleFetch(false)
    }
  }

  updateRequestSelecteds = (selecteds) => {
    this.setState({
      composicao: {
        ...this.state.composicao,
        Pedidos: selecteds
      }
    }, () => {
      this.controlDates(this.state.pedidosFiltrados.filter(p => selecteds.includes(p.IdPedido)))
      this.controlTotalValue()
    })
  }

  validateFields = () => {
    let isValid = true
    const { DataEntrega, DataColeta, Pedidos } = this.state.composicao
    if (!DataEntrega) {
      this.handleShowFeedback(intl.get('feedbacks.messageLoadComposition1'))
      isValid = false
    }
    else if (!DataColeta) {
      this.handleShowFeedback(intl.get('feedbacks.messageLoadComposition2'))
      isValid = false
    }
    else if (DataColeta > DataEntrega) {
      this.handleShowFeedback(intl.get('feedbacks.messageLoadComposition3'))
      isValid = false
    }
    else if (DataColeta < Moment().format("YYYY-MM-DD")) {
      this.handleShowFeedback(intl.get('feedbacks.messageLoadComposition4'))
      isValid = false
    }

    else if (!Pedidos || Pedidos.length === 0) {
      this.handleShowFeedback(intl.get('feedbacks.messageLoadComposition6'))
      isValid = false
    }

    return isValid
  }

  controlTotalValue = () => {
    let { composicao, pedidosFiltrados } = this.state
    let total = 0

    let ped = pedidosFiltrados.filter(p => (composicao.Pedidos != null || composicao.Pedidos != undefined) && composicao.Pedidos.includes(p.IdPedido))
    for (let p in ped) {
      total += ped[p].Quantidade
    }

    composicao.QuantidadeTotal = total

    this.setState({ composicao })
  }

  handleShowFeedback = (mensagem) => {
    this.setState({
      showValidationMsg: true,
      textValidationMsg: mensagem
    })
  }

  closeFeedback = () => {
    this.setState({
      showValidationMsg: false,
      textValidationMsg: ''
    })
  }

  handleChangeDate = (name, value) => {
    this.setState({
      composicao: {
        ...this.state.composicao,
        [name]: value
      }
    })
  }

  changeSelected = (value) => {
    this.setState({
      composicao: {
        ...this.state.composicao,
        IdUnidadeNegocio: value,
        IdFornecedor: null
      },
      fornecedores: [],
      pedidos: [],
      pedidosFiltrados: []
    }, () => this.searchSuppliers())
  }

  changeSupplierSelected = (value) => {
    this.setState({
      composicao: {
        ...this.state.composicao,
        IdFornecedor: value
      }
    }, () => this.searchRequests(this.state.composicao.IdUnidadeNegocio, value, null))
  }

  verifyPedidos = () => {
    let { composicao, pedidosFiltrados } = this.state
    let possuiOutraComposicao = false

    let pedidosSelecionados = pedidosFiltrados.filter(p => composicao.Pedidos.includes(p.IdPedido))

    for (let i in pedidosSelecionados) {
      if (pedidosSelecionados.filter(p => p.NumeroMaterial === pedidosSelecionados[i].NumeroMaterial).length > 1) {
        this.notificarMateriaisDuplicados(true)
        return
      }
    }

    for (let i in pedidosSelecionados) {
      if (pedidosSelecionados[i].PossuiComposicao) {
        possuiOutraComposicao = true
        break
      }
    }

    if (!possuiOutraComposicao) {
      this.salvarComposicao()
    }
    else {
      this.handleShowModal()
    }
  }

  salvarComposicao = () => {
    this.handleFetch()
    const { composicao, modoLeitura, pedidosFiltrados } = this.state
    const { idUser, idEdit } = this.props
    const user = Session.get()
    const usuarioAmbev = user.TipoUsuario === 1

    const pedidosSelected = pedidosFiltrados.filter(x => x.Selecionado);
    const idsFamiliaRotuladaCentro = pedidosSelected ? pedidosSelected.map(x => x.IdFamiliaRotuladaCentro) : [];

    const model = {
      ...composicao,
      Observacao: composicao.Observacao,
      IdUsuario: idUser,
      IdComposicao: idEdit || composicao.IdComposicao,
      IdUsuarioAtualizacao: user.Id,
      IdUnidadeNegocio: composicao.IdUnidadeNegocio,
      UnidadeNegocio: "",
      Centro: "",
      IdFornecedor: composicao.IdFornecedor,
      Fornecedor: "",
      ModoLeitura: modoLeitura,
      Situacao: "",
      SituacaoValor: 1,
      DataColeta: composicao.DataColeta,
      DataEntrega: composicao.DataEntrega,
      IdsFamiliaRotuladaCentro: idsFamiliaRotuladaCentro,
      HoraEntrega: composicao.HoraEntrega
    }
    if (this.validateFields()) {
      if (usuarioAmbev) {
        Fetch.post('/composicaocarga/salvar', model)
          .then(() => this.showMessageRetorno(intl.get('feedbacks.messageLoadComposition7')))
          .catch((e) => this.showMessageRetorno(formatErrorMessage(e)))
          .finally(() => this.handleFetch(false))
      }
      else {
        Fetch.post('/composicaocarga/salvar', model)
          .then(this.props.handleRefresh)
          .then(() => this.props.handleShowFeedback(intl.get('feedbacks.messageLoadComposition7')))
          .catch((e) => this.handleShowFeedback(formatErrorMessage(e)))
          .finally(() => {
            this.handleFetch(false)
            this.props.handleClose()
          })
      }
    } else {
      this.handleFetch(false)
    }
  }

  verifyEquals = () => {
    const { composicao, composicaoInicial } = this.state

    return composicao.DataColeta === composicaoInicial.DataColeta &&
      composicao.DataEntrega === composicaoInicial.DataEntrega &&
      this.verifyArraysEquals(composicao.Pedidos, composicaoInicial.Pedidos) &&
      composicao.Observacao === composicaoInicial.Observacao
  }

  verifyArraysEquals = (pedidosNovos, pedidosIniciais) => {
    if (pedidosNovos === pedidosIniciais) return true;
    if (pedidosNovos == null || pedidosIniciais == null) return false;
    if (pedidosNovos.length != pedidosIniciais.length) return false;

    for (let i = 0; i < pedidosNovos.length; ++i) {
      if (pedidosNovos[i] !== pedidosIniciais[i]) return false;
    }

    return true;
  }

  controlOne = (composicao, modoLeitura, data) => !composicao.IdComposicao && !modoLeitura && data.length == 0
  controlTwo = (composicao, modoLeitura, data) => !composicao.IdComposicao && !modoLeitura && data.length > 0
  getDataLength = (data) => data.length > 1
  controlCollectDate = (dataColetaIgual, data) => dataColetaIgual && data[0].DataColeta
  controlDeliveryDate = (dataEntregaIgual, data) => dataEntregaIgual && data[0].DataEntrega

  compareDates = (data) => {
    let dataColetaIgual = true
    let dataEntregaIgual = true

    if (this.getDataLength(data)) {
      for (let i = 1; i < data.length; i++) {
        if (data[0].DataColeta !== data[i].DataColeta) {
          dataColetaIgual = false
        }
        if (data[0].DataEntrega !== data[i].DataEntrega) {
          dataEntregaIgual = false
        }
      }
    }
    return { dataColetaIgual, dataEntregaIgual }
  }

  controlDates = (data) => {
    const { composicao, modoLeitura } = this.state

    if (this.controlOne(composicao, modoLeitura, data)) {
      composicao.DataColeta = ''
      composicao.DataEntrega = ''
      this.setState({ composicao })
    }

    if (this.controlTwo(composicao, modoLeitura, data)) {
      const { dataColetaIgual, dataEntregaIgual } = this.compareDates(data)

      if (this.controlCollectDate(dataColetaIgual, data)) {
        composicao.DataColeta = Moment(data[0].DataColeta).format('YYYY-MM-DD')
      } else {
        composicao.DataColeta = ''
      }

      if (this.controlDeliveryDate(dataEntregaIgual, data)) {
        composicao.DataEntrega = Moment(data[0].DataEntrega).format('YYYY-MM-DD')
      } else {
        composicao.DataEntrega = ''
      }

      this.setState({ composicao })
    }
  }

  searchRequests = (idUnidadeNegocio, idFornecedor) => {
    this.handleFetch()

    this.setState({
      pedidos: [],
      pedidosFiltrados: []
    })

    const { composicao, modoLeitura } = this.state

    if (modoLeitura && composicao.SituacaoValor > 0) {
      Fetch.get(`/pedido/listapedidosporcomposicaocarga/${composicao.IdComposicao}`)
        .then((response) => response.data)
        .then((data) => {
          this.setState({
            pedidos: data,
            pedidosFiltrados: data
          }, () => {
            this.controlDates(data)
            this.handleFetch(false)
          })
        })
        .catch((e) => this.handleShowFeedback(formatErrorMessage(e)))
    } else {
      const { idUser, idPedido } = this.props
      const unidade = idUnidadeNegocio || composicao.IdUnidadeNegocio
      const fornecedor = idFornecedor || composicao.IdFornecedor

      Fetch.get(`/pedido/listapedidosmesmocontratoporunidadenegocio/${unidade}/${idUser}/${fornecedor}/${idPedido}/${composicao.IdComposicao ? composicao.IdComposicao : 0}`)
        .then((response) => response.data)
        .then((data) => this.atualizarListaPedidos(data))
        .then(() => this.handleFetch(false))
        .catch((e) => this.handleShowFeedback(formatErrorMessage(e)))
    }
  }

  buscarUnidades = async () => {
    try {
      this.handleFetch()
      const { idUser } = this.props
      const { modoEdicao, modoLeitura } = this.state

      let modo = modoEdicao || modoLeitura

      const unidades = await buscarUnidades(idUser, modo)

      this.setState({
        unidadesNegocio: unidades
      })
    } catch (error) {
      this.handleShowFeedback(formatErrorMessage(error))
    }
    finally {
      this.handleFetch(false)
    }
  }

  searchSuppliers = async () => {
    try {
      this.handleFetch()
      const { idUser } = this.props
      const { composicao, modoEdicao, modoLeitura } = this.state

      let modo = modoEdicao || modoLeitura

      const fornecedores = await buscarFornecedores(idUser, composicao.IdUnidadeNegocio, modo)

      this.setState({
        fornecedores: fornecedores
      })
    } catch (error) {
      this.handleShowFeedback(formatErrorMessage(error))
    }
    finally {
      this.handleFetch(false)
    }
  }

  aceiteComposicaoCarga = () => {
    this.handleFetch()
    const { composicao } = this.state
    const { idUser } = this.props
    if (this.validateFields()) {
      Fetch.post(`/composicaocarga/aceite/${composicao.IdComposicao}/${idUser}`)
        .then((response) => this.showMessageRetorno(response.data))
        .catch((e) => this.showMessageRetorno(formatErrorMessage(e)))
        .finally(() => this.handleFetch(false))
    } else {
      this.handleFetch(false)
    }
  }

  showMessageRetorno = (data) => {

    this.setState({
      showMsg: true,
      titleMsg: intl.get("composition.form.note"),
      textMsg: data,
      handleClose: () => {
        this.setState({ showMsg: false })
        this.props.handleClose()
      }
    })
  }

  atualizarListaPedidos = (data) => {
    this.setState({
      pedidos: data,
      pedidosFiltrados: data
    }, () => {
      let dados = data.filter(d => d.Selecionado === true)
      this.controlTotalValue()
      this.controlDates(dados)
    })
  }

  showCancelConfirm = () => {
    this.setState({
      showCancelConfirm: true
    })
  }

  closeCancelConfirm = () => {
    this.setState({
      showCancelConfirm: false
    })
  }

  searchMessages = async () => {
    try {
      this.handleFetch()

      const data = JSON.parse(localStorage.getItem('enumerators'));
      this.proccessDataMessages(data.OrderCancellationJustifications)
    } catch (error) {
      this.handleShowFeedback(formatErrorMessage(error))
    }
    finally {
      this.handleFetch(false)
    }
  }

  proccessDataMessages = (data) => {
    this.setState({
      justificationMessages: data
    })
  }

  cancelCompositionFlow = () => {
    if (isLoadCompositionFlowBrewerySide() && isScheduleAgreementFlow()) {
      this.setState({
        initialCancelmentLoadsProps: {
          url: `/load-compositions/cancel-by-schedule-agreement`,
          buttonLabel: intl.get("composition.actions.cancelCompositionNewFlow")
        }
      })
    }
    else if (isLoadCompositionFlowBrewerySide() && !isScheduleAgreementFlow()) {
      this.setState({
        initialCancelmentLoadsProps: {
          url: `load-compositions/cancel-by-purchase-order`,
          buttonLabel: intl.get("composition.actions.cancelCompositionPurchaseOrderFlow")
        }
      })
    }
    else {
      this.setState({
        initialCancelmentLoadsProps: {
          url: `/composicaocarga/cancelar`,
          buttonLabel: intl.get("composition.actions.cancelComposition")
        }
      })
    }
  }

  cancelarComposicao = (selectedMessage) => {
    const user = Session.get()
    const cancelamento = { IdComposicao: this.state.composicao.IdComposicao, IdJustificativa: selectedMessage, IdUsuario: user.Id }
    const urlCancel = this.state.initialCancelmentLoadsProps.url
    this.handleFetch()
    Fetch.put(urlCancel, cancelamento)
      .then(() => this.props.handleClose())
      .catch((e) => this.handleShowFeedback(formatErrorMessage(e)))
      .finally(() => this.handleFetch(false))
  }

  handleFetch = (isFetching = true) => {
    this.setState(prevState => ({
      requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
    }))
  }

  renderTotal = (quantidade) => {
    const quantidadeFormatada = formatNumber(quantidade)
    return (
      <div className="div-quantidade">
        <HintList applyHover={false} itens={[`${intl.get('commons.qtdTotal')}: ${quantidadeFormatada}`]} label={`${intl.get('commons.qtdTotal')}: ${quantidadeFormatada}`}>
          <MapToHTML itens={[`${intl.get('commons.qtdTotal')}: ${quantidadeFormatada}`]} />
        </HintList>
      </div>
    )
  }

  notificarMateriaisDuplicados = (mostrarNotificacao) => {
    this.setState({
      mostrarNotificacaoMaterialDuplicado: mostrarNotificacao
    })
  }

  handleShowModal = () => {
    this.setState(prevState => ({
      showModalConfirmacao: !prevState.showModalConfirmacao
    }))
  }

  handleConfirm = () => {
    this.handleShowModal()
    this.salvarComposicao()
  }

  editLoadComposition = async () => {
    const { composicao } = this.state;
    const mountUnit = `${composicao.Centro}-${formatWithoutSpaces(composicao.UnidadeNegocio)}`
    const filtersRoute = `${composicao.IdUnidadeNegocio}/${mountUnit}`
    const filtersRouteDates = `/date/${composicao.DataEntrega}/${composicao.HoraEntrega}/${composicao.IdComposicao}/${composicao.IdFornecedor}`
    const queryParams = !!composicao.Door ? `?doorDescription=${composicao.Door}` : ''
    await this.props.history.push(`/loadCompositionbuilding/destino/${filtersRoute}${filtersRouteDates}${queryParams}`)
    this.props.handleCallbackEdit && this.props.handleCallbackEdit()
  }

  toggleOpenModalRenegociacaoComposicaoCarga = () => {
    const { composicao } = this.state;

    this.setState(prevState => ({
      composicao: {
        ...composicao,
        DataEntregaSugerida: !prevState.showModalRenegociacaoComposicaoCarga ? composicao.DataEntrega : composicao.DataEntregaSugerida,
        HoraEntregaSugerida: !prevState.showModalRenegociacaoComposicaoCarga ? composicao.HoraEntrega : composicao.HoraEntregaSugerida,
        ObservacaoSugerida: !prevState.showModalRenegociacaoComposicaoCarga ? composicao.Observacao : composicao.ObservacaoSugerida
      },
      showModalRenegociacaoComposicaoCarga: !prevState.showModalRenegociacaoComposicaoCarga
    }))
  }

  addButtonCancelamento = (actions) => {
    const {
      composicao,
      modoEdicao,
      modoLeitura,
      usuarioPossuiPermissaoParaExcluirPedido,
      initialCancelmentLoadsProps
    } = this.state

    const user = Session.get()
    const usuarioAmbev = user.TipoUsuario === 1
    const situacaoAceito = composicao.SituacaoValor === SituacaoValor.Criado
    const situacaoAguardandoPcSap = composicao.SituacaoValor === SituacaoValor.AguardandoPCSap
    const situacaoAguardandoRevisaoFornecedor = composicao.SituacaoValor === SituacaoValor.AguardandoRevisaoFornecedor
    const situacaoEmNegociacao = composicao.SituacaoValor === SituacaoValor.Negociacao
      && isLoadCompositionFlowBrewerySide();

    const exibirCancelamento = (usuarioAmbev &&
      ((!modoLeitura && modoEdicao)
        || (modoLeitura && (situacaoAceito || situacaoAguardandoPcSap || situacaoAguardandoRevisaoFornecedor || situacaoEmNegociacao)))
      || usuarioPossuiPermissaoParaExcluirPedido)

    if (exibirCancelamento) {
      actions.push(
        <Button
          type="danger"
          value={initialCancelmentLoadsProps.buttonLabel}
          width={"166px"}
          onClick={this.showCancelConfirm}
          className="button__composicao-carga"
        />
      )
    }
  }

  addButtonEdit = (actions) => {
    const { modoEdicao } = this.state

    const user = Session.get()
    const isAmbevUser = user.TipoUsuario === TipoUsuario.Ambev

    if (modoEdicao && isAmbevUser && isLoadCompositionFlowBrewerySide() && !usesEmergencyNegotiation()) {
      actions.push(
        <Button
          type="primary"
          value={intl.get("loadComposition.editLoadComposition")}
          width={"166px"}
          onClick={this.editLoadComposition}
          className="button__composicao-carga"
        />
      )
    }
  }

  addButtonRenegotiate = (actions) => {
    const user = Session.get()
    const usuarioFornecedor = user.TipoUsuario === 2

    const exibirBotaoRenegociar = isLoadCompositionFlowBrewerySide()
      && usuarioFornecedor && !usesEmergencyNegotiation()

    if (exibirBotaoRenegociar) {
      actions.push(
        <Button
          type="primary"
          value={intl.get("commons.renegotiate")}
          onClick={this.toggleOpenModalRenegociacaoComposicaoCarga}
          className="button__composicao-carga"
        />
      )
    }
  }

  addButtonEmergencyNegotiateSupplierSide = (actions) => {
    const { composicao, responsavelProximaAcao } = this.state;
    if (validateShowButtonEmergencyNegotiateSupplierSide(composicao, responsavelProximaAcao)) {
      actions.push(
        <Button
          type="danger"
          value={intl.get("composition.actions.startEmergencyNegotiate")}
          onClick={this.handleOpenEmergencyRenegotiate}
          className="button__composicao-carga_emergency_negotiation"
        />
      )
    }
  }

  addButtonEmergencyNegotiation = (actions) => {
    const { composicao } = this.state;
    if (validateShowButtonEmergencyNegotiationAmbevSide(composicao)) {
      actions.push(
        <Button
          type="secondary"
          variant="danger"
          value={intl.get("commons.emergencyNegotiation")}
          onClick={this.editLoadComposition}
          className="button__composicao-carga"
        />
      )
    }
  }

  addButtonCriarPcSap = (actions) => {
    const {
      composicao,
    } = this.state

    const user = Session.get()
    const usuarioAmbev = user.TipoUsuario === 1
    const exibirCriarPcSap = usuarioAmbev && composicao.SituacaoValor === SituacaoValor.AguardandoPCSap

    if (exibirCriarPcSap) {
      actions.push(
        <Button
          type="primary"
          value={intl.get("composition.actions.createPcSap")}
          onClick={this.aceiteComposicaoCarga}
          className="button__composicao-carga"
        />
      )
    }
  }

  addButtonCriarComposicao = (actions) => {
    const {
      composicao,
      modoEdicao,
    } = this.state

    const user = Session.get()
    const usuarioAmbev = user.TipoUsuario === 1
    const exibirCriarComposicao = (!modoEdicao)

    if (exibirCriarComposicao) {
      actions.push(
        <Button
          type="primary"
          value={intl.get(`composition.actions.${usuarioAmbev ? "sendSAP" : "createComposition"}`)}
          width={"166px"}
          style={{ marginLeft: '5px' }}
          onClick={this.verifyPedidos}
          disabled={!composicao.Pedidos || composicao.Pedidos.length === 0}
          className="button__composicao-carga"
        />
      )
    }
  }

  addButtonClose = (actions) => {
    const { handleClose } = this.props
    actions.push(
      <span
        className='btn-close-modal'
        onClick={handleClose}
        data-testid='btn-close-composicao-carga-form'
      >
        <img src={icnClose} />
      </span>
    )
  }

  addBtnGenerateGRN = (actions) => {
    actions.push(
      <Feature validation={usesCreateGRN()} >
        <Button
          type="default"
          value={intl.get("composition.actions.createGRN")}
          width={"166px"}
          className="button__composicao-carga"
          onClick={this.handleOpenCreateGRN}
        />
      </Feature>
    )
  }

  addButtonAceitarModificar = (actions) => {
    const {
      modoEdicao,
      modoLeitura,
      composicao
    } = this.state

    const exibirAceitarModificar = (!modoLeitura && modoEdicao)
    const condicoesNaoPermitidas = isLoadCompositionFlowBrewerySide()
      && composicao.SituacaoValor === SituacaoValor.Negociacao

    if (exibirAceitarModificar && !condicoesNaoPermitidas) {
      let buttonAcceptModify = this.verifyEquals()
      actions.push(
        buttonAcceptModify ?
          <Button
            type="primary"
            value={intl.get("composition.actions.accept")}
            onClick={this.aceiteComposicaoCarga}
            className="button__composicao-carga"
          /> :
          <Button
            type="secondary"
            value={intl.get("composition.actions.modify")}
            onClick={this.verifyPedidos}
            className="button__composicao-carga"
          />
      )
    }
  }

  createModalRenegociacaoComposicaoCarga(showModalRenegociacaoComposicaoCarga, composicao) {
    return showModalRenegociacaoComposicaoCarga &&
      <ModalRenegociacaoComposicaoCarga
        openModalRenegociacaoComposicaoCarga={showModalRenegociacaoComposicaoCarga}
        toggleOpenModalRenegociacaoComposicaoCarga={this.toggleOpenModalRenegociacaoComposicaoCarga}
        handleShowFeedback={this.handleShowFeedback}
        closeModalDetalhamentoComposicao={this.props.handleClose}
        composicaoCarga={composicao} />
  }

  createModalNotificacaoMaterialDuplicado(mostrarNotificacaoMaterialDuplicado) {
    return mostrarNotificacaoMaterialDuplicado && <Dialog
      open={mostrarNotificacaoMaterialDuplicado}
      contentStyle={{ width: "600px" }}>
      <p className="mensagem-modal-confirmacao">{intl.get("warningMessages.sameMaterialsInTheOrder")}</p>
      <div className="botoes-modal">
        <Button
          width="150px"
          type="primary"
          value="OK"
          onClick={() => this.notificarMateriaisDuplicados(false)}
          className="btn margin-botao" />
      </div>
    </Dialog>
  }

  createModalConfirmacao(showModalConfirmacao) {
    return showModalConfirmacao && <Dialog
      open={showModalConfirmacao}
      contentStyle={{ width: "600px" }}>
      <p className="mensagem-modal-confirmacao">{intl.get('composition.actions.message')}</p>
      <div className="botoes-modal">
        <Button
          width="150px"
          value={intl.get('geral.modalConfirm.no')}
          onClick={() => this.handleShowModal()}
          className="btn" />
        <Button
          width="150px"
          type="primary"
          value={intl.get('geral.modalConfirm.yes')}
          onClick={() => this.handleConfirm()}
          className="btn margin-botao" />
      </div>
    </Dialog>
  }

  handleChangeObservacao = (event) => {
    this.setState({
      composicao: {
        ...this.state.composicao,
        Observacao: event.target.value
      }
    })
  }

  handleOpenCreateGRN = () => {
    this.setState({
      modalCreateGRN: {
        isOpen: true,
      }
    })
  }

  handleCloseCreateGRN = () => {
    this.setState({
      modalCreateGRN: {
        isOpen: false,
      }
    })
  }

  handleOpenEmergencyRenegotiate = () => {
    this.setState({
      emergencyRenegotiate: {
        isOpen: true,
      }
    })
  }

  handleCloseEmergencyRenegotiate = () => {
    this.setState({
      emergencyRenegotiate: {
        isOpen: false,
      }
    })
  }

  getTitle = () => {
    const { composicao, responsavelProximaAcao } = this.state;
    const showWarningEmergencyStatus =
      SituacaoComposicaoCarga.NegociacaoEmergencial == composicao.SituacaoValor
      && !responsavelProximaAcao;
    return <h3>
      {intl.get('menu.subMenuGeneral.loadComposition')}{composicao.IdComposicao ? `: ${composicao.IdComposicao}` : ""}
      {showWarningEmergencyStatus &&
        <label className='warning-waiting-return'>{intl.get("commons.emergencyNegotiation")} - {intl.get("commons.waitingReturn")}</label>
      }
    </h3>
  }

  render() {
    const { open, handleClose } = this.props
    const {
      composicao,
      modoEdicao,
      modoLeitura,
      pedidosFiltrados,
      unidadesNegocio,
      fornecedores,
      requestCount,
      mostrarNotificacaoMaterialDuplicado,
      showModalConfirmacao,
      showModalRenegociacaoComposicaoCarga,
    } = this.state

    const actions = [this.renderTotal(composicao.QuantidadeTotal)]

    const pedidoSapCriado = pedidosFiltrados.some(function (p) {
      return p.IdPedidoSap !== undefined && p.IdPedidoSap !== null && p.IdPedidoSap.length > 0;
    })

    { !composicao.DataCriacaoGrn && pedidoSapCriado && this.addBtnGenerateGRN(actions) }
    this.addButtonClose(actions)
    this.addButtonCancelamento(actions)
    this.addButtonEdit(actions)
    this.addButtonCriarComposicao(actions)
    this.addButtonAceitarModificar(actions)
    this.addButtonCriarPcSap(actions)
    this.addButtonRenegotiate(actions)
    this.addButtonEmergencyNegotiateSupplierSide(actions)
    this.addButtonEmergencyNegotiation(actions)

    return (
      <div>
        <Dialog
          title={this.getTitle()}
          contentStyle={{ width: '98%', maxWidth: 'none', position: 'relative', height: '900px' }}
          open={open}
          onRequestClose={handleClose}
          actionsContainerClassName="container-action-buttons__composition"
          actions={actions}
        >
          <Loading
            isLoading={requestCount > 0}
            fullHeightParent
          />

          <HeaderForm
            modoEdicao={modoEdicao}
            composicao={composicao}
            unidadesNegocio={unidadesNegocio}
            changeSelected={this.changeSelected}
            changeSupplierSelected={this.changeSupplierSelected}
            fornecedores={fornecedores}
            modoLeitura={modoLeitura}
            SituacaoValor={SituacaoValor}
            handleChangeDate={this.handleChangeDate}
            handleChangeObservacao={this.handleChangeObservacao}
          />

          {pedidosFiltrados.length == 0 &&
            <Row className="rowListHeader">
              <Col xs={12} md={12} lg={12} className="msg-empty-pedidos">
                <span>{intl.get('transports.compoCharge.actions.selectUnity')}</span>
              </Col>
            </Row>
          }

          {pedidosFiltrados.length > 0 &&
            <div className='list-pedidos'>
              <PedidosAprovados
                {...this.props}
                handleSelectRequest={this.updateRequestSelecteds}
                pedidos={pedidosFiltrados}
                enableSelected={!modoLeitura && !isLoadCompositionFlowBrewerySide()}
              />
            </div>
          }

          <Message
            show={this.state.showMsg}
            text={this.state.textMsg}
            title={this.state.titleMsg}
            handleClose={this.state.handleClose}
          />

          <DangerDialog
            show={this.state.showCancelConfirm}
            title={intl.get('transports.compoCharge.actions.cancelComposition')}
            labelButton={intl.get("commons.confirm")}
            labelButtonClose={intl.get('composition.actions.close')}
            handleConfirm={this.cancelarComposicao}
            handleClose={this.closeCancelConfirm}
            justificationMessages={this.state.justificationMessages}
            isLoading={requestCount > 0}
          />

          <SnackBar
            message={this.state.textValidationMsg}
            open={this.state.showValidationMsg}
            autoHideDuration={3000}
            onRequestClose={this.closeFeedback}
          />
        </Dialog>
        {this.state.modalCreateGRN.isOpen &&
          <FormCreateGRN
            modoEdicao={modoEdicao}
            composicao={composicao}
            unidadesNegocio={unidadesNegocio}
            changeSelected={this.changeSelected}
            changeSupplierSelected={this.changeSupplierSelected}
            fornecedores={fornecedores}
            modoLeitura={modoLeitura}
            SituacaoValor={SituacaoValor}
            handleChangeDate={this.handleChangeDate}
            handleChangeObservacao={this.handleChangeObservacao}
            handleClose={this.handleCloseCreateGRN}
            open={this.state.modalCreateGRN.isOpen}
            pedidosFiltrados={pedidosFiltrados}
            renderTotal={this.renderTotal}
            handleCloseAll={handleClose}
          />
        }
        {this.state.emergencyRenegotiate.isOpen &&
          <EmergencyRenegotiate
            open={this.state.emergencyRenegotiate.isOpen}
            composicao={composicao}
            unidadesNegocio={unidadesNegocio}
            fornecedores={fornecedores}
            SituacaoValor={SituacaoValor}
            handleClose={this.handleCloseEmergencyRenegotiate}
            pedidos={pedidosFiltrados}
            handleCloseAll={handleClose}
          />
        }
        {this.createModalConfirmacao(showModalConfirmacao)}
        {this.createModalNotificacaoMaterialDuplicado(mostrarNotificacaoMaterialDuplicado)}
        {this.createModalRenegociacaoComposicaoCarga(showModalRenegociacaoComposicaoCarga, composicao)}
      </div>
    )
  }
}

ComposicaoCargaForm.propTypes = {
  idEdit: PropTypes.number,
  idUser: PropTypes.number,
  open: PropTypes.bool.isRequired,
  handleRefresh: PropTypes.func.isRequired,
  handleShowFeedback: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default withRouter(ComposicaoCargaForm)

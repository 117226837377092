import styled from 'styled-components'

export const Title = styled.span`
  font-weight: bold;
  font-size: 17px;
  line-height: 19px;
  text-transform: uppercase;
  color: #2870B2;
`
export const Text = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #3B495E;
`

export const Alert = styled.p`
  font-weight: normal;
  font-size: 17px;
  color: #FB8486;
  cursor: auto;
  > * {
    margin-right: 5px ;
}
`

import styled from 'styled-components'

export const Wrapper = styled.div`
  button{
    margin-top: 0;
  }

`

export const Title = styled.div`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #2870B2;
  padding: 20px 20px 14px 20px;
  border-bottom: 1px solid #E5EDF4;
`
export const Message = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: #3B495E;
  padding: 12px 20px;
  border-bottom: 1px solid #E5EDF4;
`

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0px;
`

import styled from "styled-components";
import ScrollContainer from 'react-indiana-drag-scroll'

export const Wrapper = styled.div`
  display: flex;
  width: max-content;
  max-height: calc(100vh - 310px);
  overflow-y: auto;

  .border-left{
    border-left: 1px solid var(--neutral-500,#95A3B7);
  }

  .flex{
    display: flex;
  }

  clb-text span {
    font-weight: 600;
    cursor: inherit;
  }

  .noRecordsFound{
    justify-content: center;
    position: relative;
    top: 40px;
  }

  .actions{
    align-self: center;
  }

  .action-head{
    width: 156px;
    text-align: center;
  }

  .materialGroupDescriptionCell{
    width: 100px;
  }

  .statusStock{
    width: 100px;
  }

  .destiny{
    width: 100px;
  }

  .labeledFamilyDescription{
    width: 200px;
  }

  clb-table-tbody-cell{
    height: 100px;
  }
`

export const SlotScrollableCell = styled.div`
  width: 186px;
`
export const WrapperNoContent = styled.div`
  display: flex;
  justify-content: center; 
  width: 100%; 
  overflow: hidden; 
  height: 100px;
`

export const SlotDateCell = styled.div`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  color: var(--contrast-black);
  cursor: grab;
`

export const GridWrapper = styled.div`
	display: grid;
  padding: 10px;
  grid-template-columns: repeat(2, 88px);
  grid-template-rows: ${p => p.qnt ? getTemplatesRows(p.qnt) : 'repeat(2, 46px)'};
  border-left: 1px solid var(--neutral-500, #95A3B7);
  cursor: grab;
`

export const TableCell = styled.div`
  width: 80px;
  height: 40px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  text-align: center;
  border-right: 1px dashed #C5D0E1;
  background: ${p => p.background ? p.background : '#DCECC9'};
  border-radius: 3px;
  align-items: baseline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: grab;

  span {
    cursor: inherit;
    color: ${p => p.color ? p.color : '#557C23'};
    align-items: center;
    margin-left: 3px;
    font-size: var(--font-size-xs);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .title{
    font-weight: var(--font-bold);
  }
`

export const WrapperScrollableTable = styled(ScrollContainer)`
  display: block;
  width: calc(100vw - 888px);
  overflow-x: auto;
  border-left: 1px solid var(--neutral-500, #95A3B7);
  border-right: 1px solid var(--neutral-500, #95A3B7);

  clb-table-thead-cell{
    padding: 0px;
    border-left: 1px solid var(--neutral-500, #95A3B7);
    cursor: grab;
  }

  clb-table-tbody-cell{
    padding: 0;
    padding-right: 1.12px;
  }

// Hide Scrollbar
  ::-webkit-scrollbar {
  display: none;
}
  -ms-overflow-style: none;
  scrollbar-width: none;

// Hide Scrollbar
`

export const WrapperActions = styled.div`
  display: flex;
  justify-content: space-around;
  width: 156px;
  height: 100%;
  align-items: center;
`
import Fetch from 'utils/fetch'
import { generateQueryList } from '../../../../utils/custom-functions';

export const downloadData = (route) => {
  return Fetch.get(`${route}`);
}

export const downloadDataWithFilters = (filters, route) => {
  const startDate = filters.dataDe ? `StartDate=${filters.dataDe}&` : ''
  const endDate = filters.dataAte ? `EndDate=${filters.dataAte}&` : ''
  const labeledFamilyId = `${generateQueryList(filters.labeledFamily, "LabeledFamilyIds")}`
  const businessUnitId = `${generateQueryList(filters.center, "BusinessUnitIds")}`
  const supplierIds = `${generateQueryList(filters.supplier, "SupplierIds")}`

  const query = `${startDate}${endDate}${labeledFamilyId}${businessUnitId}${supplierIds}`
    .replace(/\s/g, "")

  return Fetch.get(`${route}?${query}`)
}

export const getLastUpdates = () => {
  return Fetch.get('datauploadinfo/lastupdates').then(x => x.data);
}

export const getLabeledFamilyByBottle = () =>
  Fetch.get(`familiaRotulada/obterTodasComGarrafa`).then(({ data }) => data)

export const getAllLabeledFamilies = () =>
  Fetch.get(`/familiaRotulada/getAllForFilter`).then(({ data }) => data)

export const getBusinessUnitByLabeledFamily = (labeledFamilys) => {
  const filter = labeledFamilys.map(family => `LabeledFamilyId=${family.Id}`)
  const query = filter.length > 1 ? filter.join("&") : filter.join('')

  return Fetch.get(`business-units/for-filters?${query}`).then(({ data }) => data)
}

export const getSuppliersByLabeledFamilyAndBusinessUnit = (params) => {
  const defaultFilter = `UseDefaultFilter=${params.useDefaultFilter}&`
  const userId = `UserId=${params.userId}&`
  const labeledFamilyId = `${generateQueryList(params.labeledFamily.map(family => family.Id), "LabeledFamilyId")}`
  const businessUnitId = `${generateQueryList(params.businessUnit.map(center => center.Id), "BusinessUnitId")}`

  const query = `${defaultFilter}${userId}${labeledFamilyId}${businessUnitId}`.replace(/\s/g, "")
  return Fetch.get(`suppliers/for-filters?${query}`).then(({ data }) => data)
}

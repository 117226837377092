import React, { useState } from 'react'
import intl from 'react-intl-universal'
import ImportExport from 'components/import-export'
import IconNegotiation from 'images/icn-hands-negociation.svg'
import { Button } from '@hbsis.uikit/react'
import { downloadFile } from 'utils/custom-functions'
import { formatErrorMessage } from 'utils/handle-error'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { downloadPedidoVolumesEmNegociacao } from 'components/cards-obsolete/orders-vision.service'
import { GlobalProvider } from '../../../../context/globalContext'
import moment from 'moment'

const OrderVolumeImport = ({ refresh, items, checkbox }) => {
  const { showFeedback } = useGlobalContext()
  const [open, setOpen] = useState(false)

  const downloadOrderVolume = async () => {
    try {
      const canAccept = items.filter(orderVolume => orderVolume.CanAccept)
      const canAcceptIds = canAccept.filter((item) => checkbox.includes(item.Id))
        .map((item) => item.Id)

      const data = await downloadPedidoVolumesEmNegociacao(canAcceptIds)
      const datafile = moment(new Date()).format('YYYYMMDD_HHmmss')
      downloadFile(`${datafile}PedidoVolumeEmNegociacao.csv`, 'csv', data)
    }
    catch (err) {
      showFeedback(formatErrorMessage(err))
    }
  }

  const handleClose = () => {
    setOpen(false)
    refresh()
  }

  return (
    <GlobalProvider>
      <div title={intl.get("orderVolume.importTitle")}>
        <Button
          type="default"
          icon={IconNegotiation}
          onClick={() => setOpen(true)}
          className='btn-import-export-order'
        />
      </div>
      <ImportExport
        title={intl.get('exportImportModal.stockViewNegotiation.title')}
        contextModal="stockViewNegotiation"
        open={open}
        handleClose={() => handleClose()}
        downloadModelo={() => downloadOrderVolume()}
        importArquivo="pedidoVolume/import/pedidoVolumeNegociacao"
        type='csv'
        stepSelected={1}
        hiddenButtonExport
      />
    </GlobalProvider>
  )
}

export default OrderVolumeImport

import React from 'react'
import CardOrderSuggestionAwaitLoadComposition from '../card-await-load-composition';
import * as S from './styled'
import { Draggable } from 'react-beautiful-dnd';
import intl from 'react-intl-universal'
import { getGeneralSettingValue } from '../../../../services/general-settings/general-settings';
import { loadCompositionBrewerySideActive } from 'services/general-settings/get-general-settings';
import { GeneralSettingParameter } from 'models/generalSetting/generalSetting';

const Cards = ({ data, addSelectedLoads, openModal }) => {
  const isScheduleAgreementFlow = () => getGeneralSettingValue(GeneralSettingParameter.OrderSettings);
  const codeDescription = loadCompositionBrewerySideActive() && !isScheduleAgreementFlow() ? intl.get('requestOrders.PoNumber') : intl.get('requestOrders.WmsTransactionId');

  return(
  <S.Wrapper>
    {(data && data.length > 0) ?
      <S.Content>
        {
          data.map((x, index) => (
            <Draggable key={x.IdCard} draggableId={x.IdCard} index={index}>
              {(provided) => (
                <span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                  <CardOrderSuggestionAwaitLoadComposition
                    data={x}
                    handleClick={() => addSelectedLoads(x.IdCard)}
                    openModal={() => openModal(x, true)}
                    codeDescription ={codeDescription}
                  />
                </span>
              )}
            </Draggable>
          ))}
      </S.Content>
      : <div className='no-records-message'>{intl.get('commons.noRecordsFound')}</div>
    }
  </S.Wrapper >
  )
}

export default Cards;

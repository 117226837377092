import React from 'react'
import intl from 'react-intl-universal'
import Dialog from 'material-ui/Dialog';
import { Button } from "components/uikit-adapter/index"
import moment from 'moment';
import { formatDeliveryTime } from 'utils/delivery-time';
import { TextSpan } from 'components/text-label';
import Loading from "components/center-loading";

import * as S from './styled'

export const ModalReviewEmergencyRenegotiate = ({ loadComposition, handleSend, open, handleClose, isLoading }) => (
  <Dialog
    open={open}
    onRequestClose={handleClose}
    contentStyle={{ width: '500px' }}
  >
    <S.Wrapper>
      <S.Title>{intl.get('commons.emergencyNegotiation')}</S.Title>
      <S.Subtitle>{intl.get("commons.composition")} {loadComposition.IdComposicao} - {intl.get("commons.review")}</S.Subtitle>
      <S.WrapperContent>
        <S.Row>
          <TextSpan>{intl.get('commons.colDate')}:</TextSpan>
          <TextSpan>{moment(loadComposition.DataColeta).format("L")}</TextSpan>
        </S.Row>
        <S.Row>
          <TextSpan>{intl.get('commons.deliveryDate')}:</TextSpan>
          <TextSpan>{moment(loadComposition.DataEntrega).format("L")}</TextSpan>
        </S.Row>
        <S.Row>
          <TextSpan>{intl.get('commons.deliveryTime')}:</TextSpan>
          <TextSpan>{formatDeliveryTime(loadComposition.HoraEntrega)}</TextSpan>
        </S.Row>
        <S.Row>
          <TextSpan>{intl.get("commons.Observation")}:</TextSpan>
          <TextSpan>{loadComposition.Observacao ? loadComposition.Observacao : " - "}</TextSpan>
        </S.Row>
      </S.WrapperContent >
      <S.Warning>
        <TextSpan>
          {intl.get("composition.actions.confirmEmergencyNegotiateWarning")}
        </TextSpan>
      </S.Warning>
      <S.Actions>
        <Button
          type="default"
          value={intl.get('geral.buttonsDefault.cancel')}
          width={"166px"}
          onClick={handleClose}
          className="button__composicao-carga"
        />
        <Button
          type="primary"
          value={intl.get('geral.buttonsDefault.send')}
          width={"166px"}
          onClick={handleSend}
          className="button__composicao-carga"
        />
      </S.Actions>
      <Loading isLoading={isLoading} fullHeightParent />
    </S.Wrapper >
  </Dialog >
)

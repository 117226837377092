import styled from 'styled-components'

const ReleaseVersionStyled = styled.div`
  height: calc(100vh - 310px);
  position: relative;

.unidade-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  background-color: #f5f6fa;
}

.footer-dialog-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: right;
  padding-top: 30px;
}

.label-input {
  text-transform: uppercase;
  color: red;
}

.button {
  margin: 2px;
  width: 170px;
  background-color: red !important;
}


`
 export default ReleaseVersionStyled
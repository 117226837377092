import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ShopSuggestionDetailSupplierCard from './supplier'

import './detail.css'

class ShopSuggestionDetailCard extends Component {
  render() {
    return (
      <div style={{ height: '100%' }}>
        <ShopSuggestionDetailSupplierCard
          dataCard={this.props.dataCard}
          refreshCardDetail={this.props.refreshCardDetail}
        />
      </div>
    )
  }
}

ShopSuggestionDetailCard.propTypes = {
  refreshCardDetail: PropTypes.func,
}

export default ShopSuggestionDetailCard

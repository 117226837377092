import React from 'react';
import intl from 'react-intl-universal';
import CounterCardsStyled from './counter-cards.styled.js';
import FirstCardIcon from 'images/card-01.svg';
import SecondCardIcon from 'images/card-02.svg';
import ThirdCardIcon from 'images/card-03.svg';

const CounterCards = ({data}) => {
  return (
    <div className='content'>
      <CounterCardsStyled>
        <div className='card stella-artois'>
          <div>
            <img src={FirstCardIcon} className='svg' />
          </div>
          <div className='text'>
            <p className='counter'>{data.InTransit}</p>
            <p className='title'>{intl.get('orders.ordersInTransit')}</p>
          </div>
        </div>
        <div className='card stella-artois'>
          <div>
            <img src={SecondCardIcon} className='svg' />
          </div>
          <div className='text'>
            <p className='counter'> {data.WaitingEntranceMerchandise} </p>
            <p className='title'>{intl.get('orders.awaitingDischarge')}</p>
          </div>
        </div>
        <div className='card brahma'>
          <div>
            <img src={ThirdCardIcon} className='svg' />
          </div>
          <div className='text'>
            <p className='counter'> {data.AwaitingPcSap} </p>
            <p className='title'>{intl.get('orders.awaitingPCSap')}</p>
          </div>
        </div>
      </CounterCardsStyled>
    </div>
  )
}

export default CounterCards;

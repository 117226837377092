import Moment from 'moment';
import { extendMoment } from 'moment-range';

export const getFormattedWeekday = (dataInicio, dataFim) => {
  const moment = extendMoment(Moment);
  const range = moment.range(dataInicio, dataFim);
  const days = Array.from(range.by('day'));
  return days.map(m => moment(m).format("YYYY-MM-DD"))
}

export const timeRange = {
  horaInicial: '00:00',
  horaFinal: '23:30'
}

export const typesSlots = ["FreeSlot", "ScheduledDelivery", "InNegotiation", "PendingReview", "EmergencyNegotiation"]

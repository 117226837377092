import React, { createContext, useContext, useState } from 'react'
import { EditingModes } from './models/editingModeModel'
import { OptionsMedidas, OptionsPeriodFormat, OptionsPeriodTotal } from './models/viewModeModel'

const BottlesContext = createContext()
const BottlesProvider = ({ children }) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [editMode, setEditMode] = useState(EditingModes.Normal)
  const [viewMode, setViewMode] = useState(OptionsPeriodFormat.Daily)
  const [periodTotal, setPeriodTotal] = useState(OptionsPeriodTotal.Show)
  const [optionsMedida, setOptionsMedida] = useState(OptionsMedidas.Milheiros)
  const [openFilter, setOpenFilter] = useState(false)
  const [lastUpdated, setLastUpdated] = useState()
  const [data, setData] = useState(undefined)
  const [suppliers, setSuppliers] = useState([])
  const [selectedSupplier, setSelectedSupplier] = useState(undefined)
  const [updateFilterForReplyPlanning, setUpdateFilterForReplyPlanning] = useState(false)
  
  const [filter, setFilter] = useState({
    labeledFamilyId: [],
    labeledFamilysId: [],
    destinyId: [],
    periodDate: undefined,
    viewMode: viewMode === OptionsPeriodFormat.Daily ? false : true
  })

  const [checkboxs, setCheckboxs] = useState({
    PlannedEntry: true,
    RealEntry: true,
    Consumption: true,
    Transfer: true,
    InitialStock: true,
    FinalStock: false
  })

  return (
    <BottlesContext.Provider
      value={{
        isEditMode,
        setIsEditMode,
        openFilter,
        setOpenFilter,
        setEditMode,
        editMode,
        setFilter,
        filter,
        setData,
        data,
        suppliers,
        setSuppliers,
        setViewMode,
        viewMode,
        periodTotal,
        setPeriodTotal,
        optionsMedida,
        setOptionsMedida,
        checkboxs,
        setCheckboxs,
        lastUpdated,
        setLastUpdated,
        setSelectedSupplier,
        selectedSupplier,
        setUpdateFilterForReplyPlanning,
        updateFilterForReplyPlanning
      }}
    >
      {children}
    </BottlesContext.Provider>
  )
}

export const useBottlesContext = () => useContext(BottlesContext);
export { BottlesProvider, BottlesContext }

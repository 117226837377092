import React from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'
import moment from 'moment'
import DeleteLineContext from '../../components/deleteLine'
import NewLineContext from '../../components/newLine'
import GenerateZsagContext from '../../components/generateZsag'

const HeaderList = ({
  filters
}) => (
  <S.Wrapper>
    <S.Info>
      <h5>
        {intl.get('commons.plant')}:
      </h5>
      <span>
        {filters.plant.Centro}
      </span>
    </S.Info>

    <S.Info>
      <h5>
        {intl.get('commons.productionLine')}:
      </h5>
      <span>
        {filters.productionVersion.productionLine}
      </span>
    </S.Info>

    <S.Info>
      <h5>
        {intl.get('commons.date')}:
      </h5>
      <span>
        {moment(filters.date.deliveryDateFrom).format("L")} {intl.get('stocks.until')} {moment(filters.date.deliveryDateTo).format("L")}
      </span>
    </S.Info>

    <S.Info>
      <h5>
        {intl.get('commons.shift')}:
      </h5>
      <span>
        {filters.shift}
      </span>
    </S.Info>
    <NewLineContext />
    <DeleteLineContext />
    <GenerateZsagContext />
  </S.Wrapper>
)

export default HeaderList;

import React, { createContext, useContext, useState } from 'react'
import { initialValueDoor } from '../utils';

const DeliveryScheduleConfigurationContext = createContext()

const DeliveryScheduleConfigurationProvider = ({ children }) => {
  const [changes, setChanges] = useState([]);
  const [filter, setFilter] = useState({
    center: null,
    period: null,
    door: initialValueDoor
  })
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([])

  const clearChanges = () => setChanges([]);
  const stopLoading = () => setIsLoading(false);
  const startLoading = () => setIsLoading(true);

  const handleChange = (value, key, day) => {
    const _changes = [...changes]
    const indexChange = _changes.findIndex(x => x.hora === key && x.dia === day);
    const values = {
      quantidade: value,
      hora: key,
      dia: day
    }

    if (indexChange >= 0) {
      _changes[indexChange] = values;
      setChanges(_changes);
    }
    else {
      setChanges(prevState => [...prevState, values])
    }
  }

  return (
    <DeliveryScheduleConfigurationContext.Provider
      value={{
        changes,
        handleChange,
        filter,
        setFilter,
        clearChanges,
        startLoading,
        stopLoading,
        isLoading,
        data,
        setData
      }}
    >
      {children}
    </DeliveryScheduleConfigurationContext.Provider>
  )
}

export const useDeliveryScheduleConfigurationContext = () => useContext(DeliveryScheduleConfigurationContext)
export { DeliveryScheduleConfigurationContext, DeliveryScheduleConfigurationProvider }

import Toggle from 'react-toggle'
import './styles.css'

export const Switcher = ({ label, value, name, onChange, ...props }) => {
  return <div style={{ display: 'flex', justifyContent: 'space-between' }}>
    <label htmlFor={name}>{label}</label>
    <Toggle
      {...props}
      id={name}
      defaultChecked={value}
      checked={value}
      onChange={(e) => onChange(e?.target)}
    />
  </ div>
}

import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import PropTypes from 'prop-types'
import { IconCalendarSchedule } from 'components/icons/icn-index.icon'
import intl from 'react-intl-universal'
import './list-stock.css'
import moment from 'moment'
import { formatNumber } from 'utils/format'

const ListStock = ({ data, onClick }) => {
  const getEstoque = (stock) => {
    const prioridadeEstoque = stock.StatusEstoque
    const event = {
      0: ({
        colorClass: 'green-stock',
        titulo: 'Ok'
      }),
      1: ({
        titulo: 'Over'
      }),
      2: ({
        colorClass: 'red-stock',
        titulo: 'Out'
      }),
      4: ({
        titulo: 'None'
      }),
    }

    const { colorClass, titulo } = event[prioridadeEstoque]

    return stock.EstoqueValor ? (
      <div className={colorClass}>
        <strong>{titulo}</strong>
        <br />
        <span>
          {`${formatNumber(stock.EstoqueValor)}${stock.EstoqueUnidadeMedida}`}
        </span>
      </div>
    ) : <span>-</span>
  }

  const getNextDate = (stock) => (
    <strong>
      {stock.ProximaDataEntregaPedido ?
        moment(stock.ProximaDataEntregaPedido, "DD/MM").format("[DM]")
        : "-"}
    </strong>
  )

  const getDate = (date) => date ? moment(date, "DD/MM").format("[DM]") : '-'

  const getDataOut = (stock) =>
    (stock.DataOut || stock.Suficiencia) ?
      (
        <div>
          <strong>{getDate(stock.DataOut)}</strong>
          <br />
          <span>{getDate(stock.Suficiencia)}</span>
        </div>
      ) : <span>-</span>

  return (
    <Col xs={12} md={12} lg={12}>
      <Row className="containerHeader">
        <Col xs={1} className="align-center"></Col>
        <Col xs={1} className="align-center">{intl.get('stocks.table.factory')}</Col>
        <Col xs={2} >{intl.get('stocks.table.labeledFamily')}</Col>
        <Col xs={4} >{intl.get('stocks.table.activeMaterial')}</Col>
        <Col xs={1} className="align-center">{intl.get('stocks.table.stock')}</Col>
        <Col xs={2} className="align-center">{intl.get('stocks.table.sufficiencyDateOut')}</Col>
        <Col xs={1} className="align-center">{intl.get('stocks.table.nextdelivery')}</Col>
      </Row>
      <Row className="containerListaStock">
        <Col xs={12} md={12} lg={12} className="listNotasFiscais pt-0 pb-0">
          {
            (!data || data.length === 0) &&
            <Row className="rowStocks">
              <Col xs={12} md={12} lg={12} className="align-center">
                <span className="no-results">{intl.get('commons.noRecordsFound')}</span>
              </Col>
            </Row>
          }
          {
            data && !!data.length && data.map((stock, i) => (
              <Row
                key={i}
                className={`rowStocks`}
                onClick={(event) => onClick(event, stock.Id)}
                style={{ cursor: 'pointer', borderBottom: '1px dashed #98c1dc' }}
              >
                <Col xs={1} className="align-center">
                  {stock.Programado ?
                    <div title={intl.get('stocks.table.scheduledInventory')} className='programado'>
                      <IconCalendarSchedule />
                    </div>
                    : <div></div>
                  }
                </Col>
                <Col xs={1} className="align-center">
                  <span>{!!stock.Fabrica.Codigo ? stock.Fabrica.Codigo : '-'}</span>
                </Col>
                <Col xs={2} >{!!stock.LabeledFamily ? stock.LabeledFamily : '-'}</Col>
                <Col xs={4} >{!!stock.MaterialAtivo ? stock.MaterialAtivo : '-'}</Col>
                <Col xs={1} className="align-center">{getEstoque(stock)}</Col>
                <Col xs={2} className="align-center">{getDataOut(stock)}</Col>
                <Col xs={1} className="align-center">{getNextDate(stock)}</Col>
              </Row>
            ))
          }
        </Col>
      </Row >
    </Col >
  )
}


ListStock.propTypes = {
  data: PropTypes.array.isRequired,
  onClick: PropTypes.func
}

export default ListStock

import React, { Component } from 'react'
import SnackBar from 'material-ui/Snackbar'
import PersonIcon from 'material-ui/svg-icons/social/person'
import Fetch from 'utils/fetch'
import StorePersist from 'utils/store-persist'
import Message from 'components/message'
import Filtro from 'components/filters/filter-fornecedor'
import Loading from 'components/center-loading'
import HeaderIcon from 'components/header-icon'
import ButtonNewItem from 'components/button-new-item'
import DeleteSupplier from 'components/delete-dialog'
import { Button, Layout } from 'components/uikit-adapter/index'
import IconFilterGray from 'images/icn-filter-gray.svg'
import IconFilterBlue from 'images/icn-filter-blue.svg'
import IconEmpty from 'images/iconEmpty.icon'
import intl from 'react-intl-universal'
import { formatErrorMessage } from 'utils/handle-error.js'
import SupplierList from './supplier-list'
import SupplierForm from './supplier-form'
import './supplier.css'
import { profileAdminOrMasterData } from "services/permissions/getPermissions";

class Supplier extends Component {
  constructor() {
    super()

    this.state = {
      isFetching: false,
      openEditMode: false,
      suppliers: [],
      total: 0,
      currentPage: 0,
      openForm: false,
      idSelected: 0,
      showFeedback: false,
      messageFeedBack: '',
      qtRegsPerPage: 50,
      showMsg: false,
      textMsg: '',
      showFilterFornecedor: false,
      filters: {
        IdFornecedor: null,
        IdUsuario: null
      },
      openDelete: false,
    }
  }

  UNSAFE_componentWillMount() {
    const filter = StorePersist.getValuesJSON('filtersFornecedor')
    if (filter) {
      this.setState({
        filters: filter
      })

    }
  }

  searchFilters = (filters) => {
    StorePersist.setValuesJSON('filtersFornecedor', filters);
    this.showFilterFornecedor()
    this.setState({
      currentPage: 0,
      filters
    }, () => this.searchSupplier(this.state.currentPage))
  }

  showFilterFornecedor = () => {
    this.setState({
      showFilterFornecedor: !this.state.showFilterFornecedor,
    })
  }

  verifyFilter = () => {
    return this.state.filters.IdFornecedor > 0
      || this.state.filters.IdUsuario > 0
  }

  showFeedback = (message) => {
    this.setState({
      showFeedback: true,
      messageFeedBack: message
    })
  }

  closeFeedback = () => {
    this.setState({
      showFeedback: false
    })
  }

  openNewForm = () => {
    this.setState({
      openEditMode: false,
      idSelected: 0,
      openForm: true
    })
  }

  openEditForm = (row) => {
    const selectedItem = this.state.suppliers[row]

    this.setState({
      openEditMode: true,
      idSelected: selectedItem.Id,
      openForm: true
    })
  }

  closeForm = () => {
    this.setState({
      openForm: false
    })
  }

  openDelete = (id) => {
    this.setState({
      openDelete: true,
      idSelected: id
    })
  }

  closeDelete = () => {
    this.setState({
      openDelete: false
    })
  }

  startFetching = () => {
    this.setState({
      isFetching: true
    })
  }

  stopFetching = () => {
    this.setState({
      isFetching: false
    })
  }

  proccessErro = (ex) => {
    const errorMessage = formatErrorMessage(ex)
    this.showFeedback(errorMessage)
  }

  refreshSearch = () => {
    this.closeForm()
    this.searchSupplier(this.state.currentPage)
  }

  searchSupplier = (page) => {
    this.startFetching()
    const nextPage = page == undefined ? this.state.currentPage : page;
    Fetch.post(`/fornecedoresUsuarios/${this.state.qtRegsPerPage}/${nextPage}`, this.state.filters)
      .then((response) => this.proccessData(response.data))
      .then(() => this.setState({ currentPage: nextPage }))
      .catch((e) => this.proccessErro(e))
      .finally(() => this.stopFetching())
}

deleteSupplier = () => {
  const idFornecedor = this.state.idSelected
  this.startFetching()
  Fetch.post(`/fornecedor/Desabilitar/${idFornecedor}`)
    .then(this.closeDelete)
    .then(() => this.searchSupplier(this.state.currentPage))
    .then(() => this.showFeedback(intl.get('master-data.feedback.supplierSuccessfullyDisabled')))
    .catch((e) => this.proccessErro(e))
    .finally(() => this.stopFetching())
}

proccessData = (data) => {
  this.setState({
    suppliers: data.Data,
    total: data.TotalRecordCount
  })
}

render() {
  const {
    openForm,
    idSelected,
    suppliers,
    total,
    showFeedback,
    messageFeedBack,
    isFetching,
    showMsg,
    openEditMode,
    openDelete,
    textMsg,
    showFilterFornecedor,
    filters
  } = this.state

  return (
    <div className='supplier-list-container'>
      <Loading isLoading={isFetching} />

      <div className="supplier-header">
        <HeaderIcon
          title={intl.get('master-data.suppliers.ableSupplier.header.title')}
          subtitle={intl.get('master-data.suppliers.ableSupplier.header.subtitle')}
          icon={<PersonIcon style={{ width: 45, height: 45 }} color='#1d388f' />}
        >
          {profileAdminOrMasterData &&
            <div title={intl.get('master-data.suppliers.ableSupplier.actions.newSupplier')} >
              <ButtonNewItem
                className="general-button"
                onClick={this.openNewForm}
              />
            </div>
          }

          <div title={intl.get('master-data.suppliers.ableSupplier.actions.filterSupplier')} className='filterContainer'>
            <Button
              onClick={this.showFilterFornecedor}
              type='default'
              value={intl.get('filters.filter')}
              icon={this.verifyFilter() ? IconFilterBlue : IconFilterGray}
              className={"button-filter"}
            />
          </div>
        </HeaderIcon>
      </div>

      <Layout.Sidebar width="450px"
        visible={showFilterFornecedor}
        triggerClose={this.showFilterFornecedor}
        background="#FFFFFF"
        offsetTop="96px"
        side="right"
        block
        float
        icon={<IconEmpty />}
      >
        <Filtro
          showFilter={showFilterFornecedor}
          initialData={filters}
          handleClose={this.searchFilters}
        />
      </Layout.Sidebar>

      <SupplierList
        data={suppliers}
        countData={total}
        refreshSearch={this.searchSupplier}
        handleEdit={this.openEditForm}
        qtRegsPerPage={this.state.qtRegsPerPage}
        handleDelete={this.openDelete}
        profileAdminOrMasterData={profileAdminOrMasterData}
      />

      <DeleteSupplier
        open={openDelete}
        title={intl.get('master-data.suppliers.ableSupplier.actions.disabledSupplier')}
        handleClose={this.closeDelete}
        warningMessage={intl.get('master-data.suppliers.ableSupplier.actions.disabledMessage')}
        handleConfirm={this.deleteSupplier}
      />

      {openForm &&
        <SupplierForm
          idEdit={idSelected}
          open={openForm}
          editMode={openEditMode}
          handleClose={this.closeForm}
          handleRefresh={this.refreshSearch}
          handleFeedback={this.showFeedback}
          profileAdminOrMasterData={profileAdminOrMasterData}
        />
      }

      <SnackBar
        message={messageFeedBack}
        open={showFeedback}
        autoHideDuration={3000}
        onRequestClose={this.closeFeedback}
      />

      <Message
        show={showMsg}
        text={textMsg}
        title='Erro!'
        handleClose={() => this.setState({ showMsg: false })}
      />
    </div>
  )
}
}

export default Supplier

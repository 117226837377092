import React, { useState } from 'react'
import Dialog from 'material-ui/Dialog'
import SnackBar from 'material-ui/Snackbar'
import intl from 'react-intl-universal'

import PedidosForm from './pedidos-form'

const ModalConfiguration = ({open, handleClose}) => {
  const [feedback, setFeedback] = useState({
    showFeedback: false,
    messageFeedBack: '',
  })
    
  

  const showFeedback = (message) => {
    setFeedback({
      messageFeedBack: message,
      showFeedback: true
    })
  }

  const closeFeedback = () => {
    setFeedback({
      messageFeedBack: '',
      showFeedback: false
    })
  }

    return (
      <div>
        <Dialog
          title={intl.get('master-data.menus.configuration')}
          contentStyle={{ width: '600px' }}
          open={open}
          onRequestClose={handleClose}
          autoScrollBodyContent
        >
          <PedidosForm
            open
            handleClose={() => handleClose()}
            handleShowFeedback={showFeedback}
          />

          <SnackBar
            onRequestClose={closeFeedback}
            open={showFeedback}
            message={feedback.messageFeedBack}
            autoHideDuration={3000}
          />
        </Dialog>
      </div>
    )
}

export default ModalConfiguration

import Fetch from "utils/fetch";

export const searchPeriodicidades = () => {
    return Fetch.get(`/familiaRotulada/periodicidades`).then(({ data }) => data)
}

export const searchWallets = () => {
    return Fetch.get(`/carteira/todas`).then(({ data }) => data)
}

export const searchLabeledFamilyById = (idFamily) => {
    return Fetch.get(`/familiaRotulada/${idFamily}`).then(({ data }) => data)
}

import React from 'react'
import intl from 'react-intl-universal'
import { Button } from '@hbsis.uikit/react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/styles'
import * as S from './styled'

const StyledDialogContent = withStyles(() => ({
  root: {
    height: 400
  }
}))(DialogContent)

export const LogModal = ({ open, close, logs }) => (
  <Dialog
    fullWidth
    maxWidth='md'
    open={open}
  >
    <S.DialogTitle>
      {intl.get('commons.logs')}
      <S.DialogTitleIcon onClick={close} />
    </S.DialogTitle>
    <StyledDialogContent dividers>
      <S.DialogContentHeader>
        <div>{intl.get('commons.id')}</div>
        <div>{intl.get('commons.message')}</div>
      </S.DialogContentHeader>
      {logs.map(log => (
        <S.DialogContent key={log.id}>
          <S.DialogContentDetail>
            <div>{log.id}</div>
            <div>{log.message}</div>
          </S.DialogContentDetail>
        </S.DialogContent>
      ))}
    </StyledDialogContent>
    <S.DialogActions style={{ justifyContent: 'flex-end' }}>
      <div>
        <Button
          width={'200px'}
          type='primary'
          value={intl.get('geral.buttonsDefault.cancel')}
          onClick={close}
        />
      </div>
    </S.DialogActions>
  </Dialog>
)

import React from 'react'
import ShopSuggestionMesterCard from 'components/card/shop-suggestion/mester'
import { buildCard } from '../../utils'
import { Grid } from './styled'
import { userFornecedor } from 'services/permissions/getPermissions'

const OrderVolumeCards = ({ data }) => (
  <Grid>
    {
      data.map(item => buildCard(item)).map((orderVolume) => (
        <ShopSuggestionMesterCard
          key={orderVolume.Id}
          userCard={userFornecedor() ? 'SUPPLIER' : 'AMBEV'}
          dataCard={orderVolume}
          filters={{}}
        />
      ))
    }
  </Grid>
)


export default OrderVolumeCards

import React from 'react'
import intl from 'react-intl-universal'
import RowDataPedido from '../row-data-pedido'
import * as S from './styled'

const Pedidos = ({ pedidos, handleChangePedido }) => (
  <S.Wrapper>
    <S.Content
      withBorder
      withHover
      borderColor='#afcef3'
      borderColorHover='#2870b2'
    >
      <S.Table>
        <tr className="rowListHeader header-row">
          <td>{intl.get('commons.requestBuy')}</td>
          <td>{intl.get('commons.item')}</td>
          <td>{intl.get('commons.portalCode')}</td>
          <td>{intl.get('commons.material')}</td>
          <td>{intl.get('commons.lot')}</td>
          <td>{intl.get('commons.expectedQuantity')}</td>
          <td></td>
          <td>{intl.get('commons.receivedQuantity')}</td>
        </tr>

        {pedidos.map(x => (
          <RowDataPedido
            key={x.IdPedido}
            data={x}
            handleChangePedido={handleChangePedido}
          />
        ))}
      </S.Table>
    </S.Content>
  </S.Wrapper>
)


export default Pedidos

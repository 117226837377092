import React from 'react'
import { Droppable } from 'react-beautiful-dnd';
import HeaderCards from '../header-cards';
import * as S from '../../styled'
import { useLoadCompositionBuildingContext } from '../../context/load-composition-building-context';
import PaginationCards from '../pagination';
import Cards from '../cards';
import { typesSuggestion } from '../../context/utils';

function Main({ openModal, businessUnitId }) {
  const {
    addSelectedLoads,
    filter,
    loads
  } = useLoadCompositionBuildingContext();
  return (
    <Droppable droppableId="loads-suggestions">
      {(provided) => (
        <S.WrapperCards className="loads-suggestions" {...provided.droppableProps} innerRef={provided.innerRef}>
          <HeaderCards
            openModal={openModal}
            businessUnitId={businessUnitId}
          />
          <div />
          <Cards
            data={loads}
            addSelectedLoads={addSelectedLoads}
            openModal={openModal}
            hideEdit={filter.typeSuggestionSelected === typesSuggestion.Liberado}
          />
          {provided.placeholder}
          <PaginationCards />
        </S.WrapperCards>
      )}
    </Droppable>
  )
}

export default Main;

import React, { useState } from 'react'
import { Button } from "components/uikit-adapter/index";
import IconTruckPlus from 'images/icn-truck-plus.svg'
import * as S from './style'
import { ModalFullTruckLoad } from '../modal-full-truck-load';
import { generateUrlRedirect } from './utils';
import intl from 'react-intl-universal'
import { loadCompositionBrewerySideActive } from 'services/general-settings/get-general-settings';

export const ButtonLoadCompositionPlus = ({ isFullTruckLoad, hidden, item, history, ...rest }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    if (isFullTruckLoad) {
      setOpenModal(true)
    } else {
      goToLoadCompositionBuilding(false)
    }
  }

  if (hidden || !loadCompositionBrewerySideActive()) {
    return null;
  }

  const goToLoadCompositionBuilding = (fullTruckLoad) => history.push(generateUrlRedirect(item, fullTruckLoad))

  return (
    <S.Wrapper title={intl.get('commons.addLoadComposition')}>
      <Button
        type="secondary"
        icon={IconTruckPlus}
        onClick={handleClick}
        {...rest}
      />
      {openModal &&
        <ModalFullTruckLoad
          open={openModal}
          handleClose={() => setOpenModal(false)}
          item={item}
          goToLoadCompositionBuilding={goToLoadCompositionBuilding}
        />
      }
    </S.Wrapper>
  )
}

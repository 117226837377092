
import React from 'react'
import StyledButtonNewItem from './styledButtonNewItem'
import AddIcon from 'material-ui/svg-icons/content/add'

import { Button } from '@hbsis.uikit/react'

const ButtonNewItem = props => (
  <StyledButtonNewItem>
    <Button
      type="default"
      value={<AddIcon style={{ width: 39, height: 39 }} color='#1d388f' />}
      width={"40px"}
      height={"40px"}
      className="button-new-item"
      {...props}
    />
  </StyledButtonNewItem>
)

export default ButtonNewItem

import React from 'react'
import * as S from './styled'
import intl from 'react-intl-universal'

const CardUnavailable = ({ hour, view }) => (
  <>
    {view &&
      <S.Wrapper>
        <S.Hora>
          {hour && <span> {hour}</span>}
        </S.Hora>
        <S.Content>
          <span>{intl.get("loadComposition.filter.unavailable")}</span>
        </S.Content>
      </S.Wrapper>
    }
  </>
)

export default CardUnavailable;

import React, { useMemo, useState } from 'react'
import { getFormattedHours } from '../../../master-data/unit-management/delivery-schedule-configuration/components/utils';
import { useLoadCompositionContext } from '../../context/loadCompositionContext';
import { getFormattedWeekday, timeRange } from '../../utils';
import Card from '../card';
import CardUnavailable from '../card/cardUnavailable';
import intl from 'react-intl-universal'
import moment from 'moment'
import SessionUser from 'utils/user-storage'
import ComposicaoCargaForm from '../../../composicao-carga/form'
import * as S from './styled'
import ModalRenegociacaoComposicaoCarga from '../modalLoadInNegotiation';
import { searchComposition } from '../../../composicao-carga/form/composicao-carga-form.service';
import { useQuery } from 'hooks/useQuery';
import { formatWithoutSpaces } from 'utils/format';

const Table = ({ searchLoadComposition, showFeedback, isEditMode, params, redirectRoute, replaceRoute }) => {
  const { startDate, endDate, data, filters } = useLoadCompositionContext();
  const query = useQuery()
  const [hours] = useState(getFormattedHours(timeRange))
  const [dates] = useState(getFormattedWeekday(startDate, endDate));
  const idCompositionSelected = useMemo(() => query.get("code") || params?.composicaoId, []);
  const [loadCompositionForm, setLoadCompositionForm] = useState({
    isOpen: false,
    idComposicao: 0,
    modoLeitura: true
  });
  const [compositionInNegotiationModal, setCompositionInNegotiationModal] = useState({
    isOpen: false,
    idComposicao: 0,
  });

  const [selectedFreeSlot, setSelectedFreeSlot] = useState();

  const validateDaysInData = (date) =>
    data.filter(_data => moment(_data.Dia).format("YYYY-MM-DD") === date).length > 0;

  const validateHoursInData = (_data, hour) =>
    _data.filter(x => moment(x.Hora, "HH:mm:ss").format("HH:mm") === hour).length > 0;

  const handleRefreshTable = () => {
    const _dates = {
      de: filters.dateFrom,
      ate: filters.dateTo
    }
    searchLoadComposition(_dates, filters.center, filters.door)
  }

  const handleCloseLoadForm = () => {
    setLoadCompositionForm(prev => ({
      ...prev,
      isOpen: false
    }))
    handleRefreshTable()
  }

  const handleOpenLoadForm = (composicao) => {
    updateCacheFilters();
    setLoadCompositionForm({
      isOpen: true,
      idComposicao: composicao.Id,
      modoLeitura: composicao.ModoLeitura
    })
  }

  const updateCacheFilters = () => {
    const filter = {
      Center: filters.center,
      dataInicial: startDate,
      dataFinal: endDate,
      Door: filters.door,
    }

    localStorage.setItem("loadCompositionFilter", JSON.stringify(filter))
  }

  const handleClickFree = (hour, date) => {
    updateCacheFilters();
    const queryParams = !!filters.door?.value ? `?doorDescription=${filters.door?.label}` : ''
    const filtersRoute = `/loadCompositionbuilding/destino/${filters.center.IdUnidadeNegocio}/${formatWithoutSpaces(filters.center.Descricao)}/date/${date}/${hour}${queryParams}`
    redirectRoute(filtersRoute)
  }

  const handleClickInNegotiation = async (composicao, date, hour) => {
    updateCacheFilters();
    const _composicao = await searchComposition(composicao.Id);
    setCompositionInNegotiationModal({
      ..._composicao,
      isOpen: true,
      hour: hour,
      date: date
    });
  }

  const handleCloseModalRenegotiation = () => {
    setCompositionInNegotiationModal({
      isOpen: false,
      idComposicao: 0
    });
  }

  const handleSelectEditDate = (dia, hour, i) => {
    const filtersRoute = `${filters.center.IdUnidadeNegocio}/${formatWithoutSpaces(filters.center.Descricao)}/date/${dia}/${hour}`
    const queryParams = !!filters.door?.value ? `?doorDescription=${filters.door?.label}` : ''
    const editCompositionInfosRoute = !!params?.composicaoId ? `/${params.composicaoId}/${params.fornecedorId}` : '';
    if (isEditMode) {
      setSelectedFreeSlot(`${dia}-${hour}-${i}`)
    }
    replaceRoute(`/loadCompositionbuilding/destino/${filtersRoute}${editCompositionInfosRoute}${queryParams}`)
  }

  return (
    <S.Wrapper
      vertical={false}
    >
      <S.WrapperContent>
        {
          dates.map(date => (
            validateDaysInData(date) ?
              data.filter(_data => moment(_data.Dia).format("YYYY-MM-DD") === date).map(_data => (
                <S.WrapperColumn>
                  <S.TableHeading>
                    {intl.get(`weekdays.${moment(date).weekday()}`)}
                    <br />
                    {moment(date).format("L")}
                  </S.TableHeading>
                  <S.Row>
                    {
                      hours.map(hour => (
                        <React.Fragment>
                          {validateHoursInData(_data.Data, hour) ?
                            _data.Data.filter(x => moment(x.Hora, "HH:mm:ss").format("HH:mm") === hour).map((x) => (
                              <Card
                                isEditMode={isEditMode}
                                data={x}
                                hour={hour}
                                dia={date}
                                viewScheduledDelivery={filters.viewColumns.includes("ScheduledDelivery")}
                                viewFreeSlot={filters.viewColumns.includes("FreeSlot")}
                                viewPendingReview={filters.viewColumns.includes("PendingReview")}
                                viewInNegotiation={filters.viewColumns.includes("InNegotiation")}
                                viewEmergencyNegotiation={filters.viewColumns.includes("EmergencyNegotiation")}
                                handleClickFree={() => handleClickFree(hour, date)}
                                handleOpenLoadForm={handleOpenLoadForm}
                                handleClickInNegotiation={(comp) => handleClickInNegotiation(comp, date, hour)}
                                handleSelectEditDate={(comp, i) => handleSelectEditDate(date, hour, i)}
                                idCompositionSelected={idCompositionSelected ? parseInt(idCompositionSelected) : null}
                                selectedFreeSlot={selectedFreeSlot}
                              />
                            ))
                            :
                            <CardUnavailable view={filters.viewColumns.includes("Unavailable")} hour={hour} />
                          }
                        </React.Fragment>
                      ))
                    }
                  </S.Row>
                </S.WrapperColumn>
              )
              ) :
              <S.WrapperColumn>
                <S.TableHeading>
                  {intl.get(`weekdays.${moment(date).weekday()}`)}
                  <br />
                  {moment(date).format("L")}
                </S.TableHeading>
                {hours.map(hour =>
                  <CardUnavailable view={filters.viewColumns.includes("Unavailable")} hour={hour} />
                )}
              </S.WrapperColumn>
          ))
        }
      </S.WrapperContent>
      {loadCompositionForm.isOpen &&
        <ComposicaoCargaForm
          idEdit={loadCompositionForm.idComposicao}
          idPedido={0}
          idUser={SessionUser.get().Id}
          open={loadCompositionForm.isOpen}
          handleRefresh={handleRefreshTable}
          handleShowFeedback={showFeedback}
          handleClose={handleCloseLoadForm}
          modoLeitura={loadCompositionForm.modoLeitura}
        />
      }
      {compositionInNegotiationModal.isOpen &&
        <ModalRenegociacaoComposicaoCarga
          isOpen={compositionInNegotiationModal.isOpen}
          handleClose={handleCloseModalRenegotiation}
          composicao={compositionInNegotiationModal}
          filters={filters}
          redirectRoute={redirectRoute}
          handleRefresh={handleRefreshTable}
        />
      }
    </S.Wrapper>
  )
}

export default Table;

import React from 'react'
import { TableRowColumn } from 'material-ui/Table'

const TableRownColumnUnclickable = ({ style, children }) => (
  <TableRowColumn style={style}>
    <div style={{ width: '100%' }} onClick={e => e.stopPropagation()}>
      {children}
    </div>
  </TableRowColumn>
)

export default TableRownColumnUnclickable

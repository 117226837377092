import Fetch from 'utils/fetch';
import { generateQueryParams } from 'utils/custom-functions';

export const getStockData = (useFilterDefault, userId) => {
  return Fetch.get(`/usuario/buscarUsuarioAmbevProgramacao/${useFilterDefault}/${userId}`).then(({ data }) => data)
}

export const getFamily = (useFilterDefault) => {
  return Fetch.get(`/familia/porfiltrodefault/${useFilterDefault}`).then(({ data }) => data)
}

export const getCoordinations = (useFilterDefault) => {
  return Fetch.get(`/coordenacao/porfiltrodefault/${useFilterDefault}`).then(({ data }) => data)
}

export const getStock = (filterQueryParameters) => {
  const queryParameters = generateQueryParams(filterQueryParameters);
  return Fetch.get(`/stocks:cards-paginated?${queryParameters}`).then(({ data }) => data);
}

export const getSteps = (useFilterDefault, userId) => {
  return Fetch.get(`/visaoEstoque/etapasDoEstoque/${useFilterDefault}/${userId}`).then(({ data }) => data)
}

export const getStatus = () => {
  return Fetch.get(`/stocks/status`).then(({ data }) => data)
}

export const getBusinessUnit = (userId) => {
  return Fetch.get(`/unidadeNegocio/unidadesDoEstoque/${userId}`).then(({ data }) => data)
}

export const getLabeledFamily = (useFilterDefault, userId) => {
  return Fetch.get(`/familiaRotulada/familiaRotuladaDoEstoque/${useFilterDefault}/${userId}`).then(({ data }) => data)
}

export const getActiveMaterial = (useFilterDefault, userId) => {
  return Fetch.get(`/material/materialAtivosDoEstoque/${useFilterDefault}/${userId}`).then(({ data }) => data)
}

export const getWallet = (useFilterDefault, userId) => {
  return Fetch.get(`/carteira/todasPorUsuario/${useFilterDefault}/${userId}`).then(({ data }) => data)
}

export const startProgramming = (idUsuarioProgramado, userId) => {
  return Fetch.post(`/visaoEstoque/iniciarProgramacao/${idUsuarioProgramado}/${userId}`).then(({ data }) => data)
}

export const getUsersAmbev = () => {
  return Fetch.get(`/usuario/buscaUsuariosHabilitadoAmbev`).then(({ data }) => data)
}

export const getSuppliersForFilters = (useFilterDefault, userId) => {
  return Fetch.get(`/suppliers/for-filters?useFilterDefault=${useFilterDefault}&userId=${userId}`).then(({ data }) => data)
}

import React from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
  TableFooter
} from 'components/uikit-adapter/index'
import DeleteIcon from 'images/icn-trash-bin.svg'
import ActionShoppingCart from 'images/icn-shopping-cart.svg'
import IconButton from 'material-ui/IconButton'
import icnAutoPilot from 'images/icn-auto-pilot.svg'

import PaginationFooter from 'components/pagination/components/pagination-footer'
import TableRownColumnUnclickable from 'components/table-row-column-unclickable'

import intl from 'react-intl-universal';

import './list.css'

const GeneralList = ({ data, countData, refreshSearch, handleEdit, handleDelete, handleRedirect, qtRegsPerPage, openConfgAutoPilot }) => (
  <Table
    fixedHeader
    fixedFooter
    multiSelectable={false}
    selectable={false}
    wrapperStyle={{ height: '100%' }}
    bodyStyle={{ height: 'calc(100% - 110px)', borderTop: '1px solid rgb(224, 224, 224)', backgroundColor: '#FFFFFF' }}
    onCellClick={handleEdit}>
    <TableHeader
      displaySelectAll={false}
      adjustForCheckbox={false}
      style={{ borderBottom: 'none' }}>
      <TableRow style={{ borderBottom: 'none' }}>
        <TableHeaderColumn style={{ width: '10%' }}>{intl.get('master-data.general-configuration.center')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '20%' }}>{intl.get('master-data.general-configuration.labeledFamily')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '30%' }}>{intl.get('master-data.general-configuration.activeMaterial')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '10%' }}>{intl.get('master-data.general-configuration.wallet')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '10%' }}>{intl.get('master-data.general-configuration.coordination')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '10%' }}>{intl.get('master-data.general-configuration.hp')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }} >{intl.get('master-data.general-configuration.autopilot')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }} >{intl.get('master-data.general-configuration.materials')}</TableHeaderColumn>
        <TableHeaderColumn style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }} />
      </TableRow>
    </TableHeader>

    <TableBody
      displayRowCheckbox={false}
      showRowHover
    >
      {data.map((v, i) => (
        <TableRow key={i} style={{ cursor: 'pointer' }}>
          <TableRowColumn style={{ width: '10%' }}>
            {v.Centro}
          </TableRowColumn>
          <TableRowColumn style={{ width: '20%' }}>
            {v.FamiliaRotulada}
          </TableRowColumn>
          <TableRowColumn style={{ width: '30%' }}>
            {v.MaterialAtivo}
          </TableRowColumn>
          <TableRowColumn style={{ width: '10%' }}>
            {v.Carteira}
          </TableRowColumn>
          <TableRowColumn style={{ width: '10%' }}>
            {
              !!v.Coordenacoes.length && v.Coordenacoes[0].Descricao
            }
          </TableRowColumn>
          <TableRowColumn style={{ width: '10%' }}>
            {v.HorizonteProgramacao}
          </TableRowColumn>
          <TableRownColumnUnclickable style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }}>
            <div title={intl.get('master-data.general.autoPilot.tooltipDescription')} className={"button-img"}>
              <IconButton onClick={() => openConfgAutoPilot(v.Id)}>
                <img src={icnAutoPilot} style={{ width: '20px', height: '20px' }} />
              </IconButton>
            </div>
          </TableRownColumnUnclickable>
          <TableRownColumnUnclickable style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }}>
            <div title={intl.get('master-data.general.materials.tooltipDescription')} className={"button-img"}>
              <IconButton onClick={() => handleRedirect(v)} >
                <img src={ActionShoppingCart} style={{ width: '20px', height: '20px' }} />
              </IconButton>
            </div>
          </TableRownColumnUnclickable>
          <TableRownColumnUnclickable style={{ width: '5%', positon: 'relative', textAlign: 'right', paddingRight: '0px', paddingLeft: '10px' }}>
            <div title={intl.get('master-data.general.deleteLabeledFamilyModal.tooltipDescription')}>
              <IconButton onClick={() => handleDelete(v.Id)} >
                <img src={DeleteIcon} />
              </IconButton>
            </div>
          </TableRownColumnUnclickable>
        </TableRow>
      ))}
    </TableBody>
    <TableFooter>
      <PaginationFooter
        countPerPage={qtRegsPerPage}
        countData={countData}
        refreshSearch={refreshSearch}
      />
    </TableFooter>
  </Table>
)

GeneralList.propTypes = {
  countData: PropTypes.number.isRequired,
  refreshSearch: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleRedirect: PropTypes.func.isRequired,
  qtRegsPerPage: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    Descricao: PropTypes.string,
    Familia: PropTypes.string
  })).isRequired
}

export default GeneralList

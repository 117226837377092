import { useEffect, useState } from "react"
import { SelectWithSearchAndSelectAll } from "components/select-with-search-select-all"
import { useConsolidatedStocksContext } from "features/consolidated-stocks/context"
import { searchBusinessUnit, searchLabeledFamily, searchMaterialGroup } from "services/filters.service"
import { isUseFilterDefault } from "utils/user"
import { Button } from 'components/uikit-adapter/index'
import IconFilterGray from 'images/icn-filter-gray.svg'
import IconFilterBlue from 'images/icn-filter-blue.svg';
import DeleteIcon from 'images/icn-trash-bin.svg'
import intl from 'react-intl-universal'
import { verifyIsFiltered } from "utils/custom-functions"
import { initialFilterValues } from "./utils"
import { ConsolidatedStocksTooltip } from "../consolidated-stocks-tooltip"

import * as S from './styled'

export const ConsolidatedStocksFilter = () => {
  const { filter: filterContext, setFilter: setFilterContext } = useConsolidatedStocksContext();
  const [filter, setFilter] = useState({ ...filterContext })
  const [businessUnits, setBusinessUnits] = useState([]);
  const [labeledFamilies, setLabeledFamilies] = useState([]);
  const [materialGroup, setMaterialGroup] = useState([]);
  const useFilterDefault = isUseFilterDefault();

  const updateFilter = (prop, value) => {
    setFilter(prevState => ({
      ...prevState,
      [prop]: value
    }))
  }

  const loadFilters = async () => {
    const [_businessUnits, _labeledFamilies, _materialGroup] = await Promise.all([
      searchBusinessUnit(useFilterDefault),
      searchLabeledFamily(useFilterDefault),
      searchMaterialGroup(useFilterDefault)
    ]);

    setBusinessUnits(_businessUnits);
    setLabeledFamilies(_labeledFamilies);
    setMaterialGroup(_materialGroup)
  }

  const handleFilter = () => {
    setFilterContext(prevState => ({
      ...filter,
      _page: 0,
      _size: prevState._size
    }))
  }

  const clearFilters = () => {
    setFilter({ ...initialFilterValues })
    setFilterContext(initialFilterValues)
  }

  useEffect(() => {
    loadFilters();
  }, [])

  const getIconFilter = () =>
    verifyIsFiltered(filterContext) ? IconFilterBlue : IconFilterGray;

  return (
    <S.Wrapper>
      <SelectWithSearchAndSelectAll
        label={intl.get('commons.destiny')}
        options={businessUnits}
        onChange={value => updateFilter("BusinessUnitId", value)}
        values={filter.BusinessUnitId}
        valueField='Id'
        labelField='Description'
        style={{ background: '#FFFFFF' }}
        hideSelectAll
      />
      <SelectWithSearchAndSelectAll
        label={intl.get('commons.wallet')}
        options={materialGroup}
        onChange={value => updateFilter("MaterialGroupId", value)}
        values={filter.MaterialGroupId}
        valueField='Id'
        labelField='Description'
        style={{ background: '#FFFFFF' }}
        hideSelectAll
      />
      <SelectWithSearchAndSelectAll
        label={intl.get('commons.labeledFamily')}
        options={labeledFamilies}
        onChange={value => updateFilter("LabeledFamilyId", value)}
        values={filter.LabeledFamilyId}
        valueField='Id'
        labelField='Description'
        style={{ background: '#FFFFFF' }}
        hideSelectAll
      />

      <S.ButtonWrapper>
        <Button
          width='166px'
          type='default'
          value={intl.get("filters.cleanFilter")}
          onClick={clearFilters}
          icon={DeleteIcon}
          className='btn-filter'
        />
        <Button
          width='166px'
          type="primary"
          value={intl.get('filters.generalFilter')}
          onClick={handleFilter}
          icon={getIconFilter()}
          className='btn-filter'
        />
        <ConsolidatedStocksTooltip />
      </S.ButtonWrapper>
    </S.Wrapper >
  )
}

import React, { Component } from "react";
import Session from "utils/user-storage";
import Dialog from "material-ui/Dialog";
import { Button } from "components/uikit-adapter";
import { getNPSId } from "../../utils/config-environment";
import intl from "react-intl-universal";
import { getLocaleCode } from "locales";
import { npsDefaultTexts } from "./utils";
import { getEnumProfileTypesAccess } from "pages/master-data/configurations/permissions-settings/permissions-settings.service";
import { Nps } from "./components/nps";
import moment from 'moment'

class SurveyNps extends Component {

  constructor(p) {
    super(p);

    this.state = {
      showFeedback: false,
      messageFeedBack: "",
      show: false,
      title: "",
      text: intl.get("survey.notification"),
      listaPerfilAcessos: [],
      listaPaises: [
        { Key: 1, Value: 'BRA' },
        { Key: 2, Value: 'USA' },
        { Key: 3, Value: 'ZA' },
      ],
      perfilAcessoStr: "",
      paisStr: ""
    };
  }

  componentDidMount() {
    this.searchAllPerfilAcessos();
    this.setCountryState();
  }

  searchAllPerfilAcessos = async () => {
    try {
      const listaPerfilAcessos = await getEnumProfileTypesAccess();
      const perfilNumber = Session.get().PerfilAcesso;

      const ret = listaPerfilAcessos.filter(function (x) {
        return x.Id === perfilNumber;
      })[0];

      if (ret !== undefined) {
        this.setState({
          perfilAcessoStr: ret.Descricao,
          listaPerfilAcessos: listaPerfilAcessos
        });
      }
    } catch (error) {
      console.error(error)
    }
  }

  setCountryState = () => {

    const { listaPaises } = this.state;
    const paisNumber = Session.get().Pais;

    const ret = listaPaises.filter(function (x) {
      return x.Key === paisNumber;
    })[0];

    if (ret !== undefined) {
      this.setState({
        paisStr: ret.Value
      });
    }
  }

  updateResponseSurvey = () => {
    this.setState({
      show: true
    }, () => {
      const tomorrow = moment().add('days', 1).set({hours: '00', minutes: '00', seconds: '00'}).valueOf()
      localStorage.setItem("NO_RESPONSE_SURVEY", tomorrow);
    })

  };

  onFinish = () => {
    this.showFeedback(
      intl.get("master-data.menus.feedback.thankYouParticipation")
    );
  }

  onClose = (isFinish) => {
    if (!isFinish) {
      this.setState({
        show: true,
      });
    }
  };

  onCloseConfirmModal = () => {
    this.setState(
      {
        show: false,
      }
    );
  };

  showFeedback = (message) => {
    this.setState({
      messageFeedBack: message,
      showFeedback: true,
    });
  };
  
  render() {
    const parametros = { id: Session.get().Id, sistema: Session.get().Sistema };
    const { show, title, text, perfilAcessoStr, paisStr } = this.state;
    const { id } = parametros;
  
    const rememberLaterDate = localStorage.getItem('NO_RESPONSE_SURVEY')
    const today = moment().valueOf()

    if(rememberLaterDate >= today) {
      return null
    }

    return (
      <>
        <Nps
          checkOnLoad={true}
          user={id.toString()}
          systemId={getNPSId()}
          customParams={{
            group: `${perfilAcessoStr} - ${paisStr}`
          }}
          onFinish={this.onFinish}
          onClose={this.onClose}
          rememberLater={this.updateResponseSurvey}
          acceptLanguage={getLocaleCode()}
          texts={npsDefaultTexts()}
        />
        <Dialog
          open={show}
          title={title}
          actions={
            <Button
              value={intl.get("survey.confirm")}
              onClick={this.onCloseConfirmModal}
            />
          }
          contentStyle={{ width: "30%" }}
        >
          {text}
        </Dialog>
      </>
    );
  }
}
export default SurveyNps;

import React, { useState, useEffect, useCallback } from "react";
import { Layout, Button } from "@hbsis.uikit/react";
import Filtro from "components/filters/filter-gerencial/gerencial";
import DateRangerPicker from "components/date-range-picker";
import Header from "../../components/header";
import Loading from "components/center-loading";
import intl from "react-intl-universal";
import CounterCards from "./components/counter-cards";
import Table from "./components/table";
import { getGridVision } from "./grid-vision.service";
import moment from "moment";
import Modal from "./components/modal";
import IconEmpty from "images/iconEmpty.icon";
import IconFilterGray from "images/icn-filter-gray.svg";
import IconFilterBlue from "images/icn-filter-blue.svg";
import StorePersist from "utils/store-persist";
import { validBetweenDates } from "./utils/date";
import * as S from "./grid-vision.styled";
import "./grid-vision.css";
import { filtroPlanoDiarioDefault } from "./utils/filtro";
import { verifyIsFiltered } from "utils/custom-functions";

const typeInfo = {
  Coleta: 1,
  Entrega: 2,
};

const getDefaultDays = () => {
  let finalDate = new Date();
  finalDate.setDate(finalDate.getDate() + 6);

  return {
    InitialDate: moment(new Date()).format("YYYY-MM-DD"),
    FinalDate: moment(finalDate).format("YYYY-MM-DD"),
  };
};

const GridVision = (props) => {
  const [gridVision, setGridVision] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [dataDe, setDataDe] = useState(() => getDefaultDays().InitialDate);
  const [dataAte, setDataAte] = useState(() => getDefaultDays().FinalDate);
  const [mostrarFiltro, setMostrarFiltro] = useState(false);
  const [daysRange, setDaysRange] = useState({});
  const [coleta, setColeta] = useState(true);
  const [entrega, setEntrega] = useState(false);
  const [filters, setFilters] = useState(() =>
    "filtroPlanoDiario" in localStorage
      ? StorePersist.getValuesJSON("filtroPlanoDiario")
      : filtroPlanoDiarioDefault
  );

  const getGrid = useCallback(
    async (de, ate, coletaAtiva, entregaAtiva, possibleFilters) => {
      setLoading(true);
      const IsFilterDefault = await StorePersist.getValuesJSON(
        "filterMyItemsOnly"
      );
      let daysToFilter = {};

      if (coletaAtiva)
        daysToFilter = {
          InitialCollectDate: de,
          FinalCollectDate: ate,
        };
      if (entregaAtiva)
        daysToFilter = {
          InitialDeliveryDate: de,
          FinalDeliveryDate: ate,
        };

      const response = await getGridVision(
        daysToFilter,
        IsFilterDefault,
        possibleFilters
      );

      setDaysRange(daysToFilter);
      setGridVision(response);
      setLoading(false);
    },
    []
  );

  const manipulaFiltros = (data) => {
    setFilters(data);
    setMostrarFiltro(false);
    setLoading(true);
    StorePersist.setValuesJSON("filtroPlanoDiario", data);
  };

  const getFilterIcon = () => verifyIsFiltered(filters) ? IconFilterBlue : IconFilterGray;

  useEffect(() => {
    getGrid(dataDe, dataAte, coleta, entrega, filters);
  }, [filters]);

  useEffect(() => {
    getGrid(dataDe, dataAte, coleta, entrega, filters);
  }, [coleta, entrega]);

  useEffect(() => {
    setDaysRange({
      InitialDate: dataDe,
      FinalDate: dataAte,
    });
    getGrid(dataDe, dataAte, coleta, entrega, filters);
  }, [getGrid]);

  return (
    <div style={{ height: "calc(100% - 45px)" }}>
      <Header
        title={intl.get("menu.subMenuGeneral.grid-vision")}
        titleWidth={350}
      >
        <div
          style={{
            position: "absolute",
            right: "0",
          }}
        >
          <Button
            onClick={() => setMostrarFiltro(!mostrarFiltro)}
            type="default"
            value={intl.get("filters.filter")}
            className="button-filter"
            icon={getFilterIcon()}
            disabled={validBetweenDates(dataDe, dataAte)}
          />
        </div>
        <Layout.Sidebar
          width="450px"
          visible={mostrarFiltro}
          className="sideBar"
          triggerClose={() => setMostrarFiltro(false)}
          background="#FFFFFF"
          offsetTop="96px"
          side="right"
          block
          float
          icon={IconEmpty}
        >
          <Filtro
            filterDefault={filters}
            isPlanoDiario={true}
            setFilters={manipulaFiltros}
          />
        </Layout.Sidebar>
      </Header>
      <div style={{ height: "calc(100% - 100px)" }}>
        {gridVision && gridVision.length > 0 && (
          <CounterCards data={gridVision} />
        )}
        <div
          style={{
            padding: "16px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginRight: "10px",
              marginTop: "4px",
              width: "85px",
            }}
          >
            <div
              className={
                validBetweenDates(dataDe, dataAte)
                  ? "disabled__grid-vision "
                  : ""
              }
              title={intl.get("planningDaily.visionPickup")}
            >
              <S.Button
                className={coleta ? "active__grid-vision" : "button__Collect"}
                onClick={() => {
                  setColeta(true);
                  setEntrega(false);
                }}
              >
                {intl.get("gridVision.button1")}
              </S.Button>
            </div>
            <div
              className={
                validBetweenDates(dataDe, dataAte)
                  ? "disabled__grid-vision "
                  : ""
              }
              title={intl.get("planningDaily.visionDelivery")}
            >
              <S.Button
                className={entrega ? "active__grid-vision" : "button__Delivery"}
                onClick={() => {
                  setEntrega(true);
                  setColeta(false);
                }}
              >
                {intl.get("gridVision.button2")}
              </S.Button>
            </div>
          </div>
          <DateRangerPicker
            title=""
            dataDe={dataDe}
            dataAte={dataAte}
            handleChange={(prop, value) =>
              prop === "dataDe" ? setDataDe(value) : setDataAte(value)
            }
          />
          <div
            style={{
              marginTop: "9px",
              marginLeft: "8px",
              width: "80px",
            }}
          >
            <S.GridButton
              className={
                validBetweenDates(dataDe, dataAte)
                  ? "grid__button disabled__grid-vision"
                  : "grid__button"
              }
              onClick={() => {
                getGrid(dataDe, dataAte, coleta, entrega, filters);
              }}
            >
              {intl.get("filters.search")}
            </S.GridButton>
          </div>
        </div>
        {gridVision && gridVision.length > 0 && (
          <Table
            data={gridVision}
            days={daysRange}
            type={coleta ? typeInfo.Coleta : typeInfo.Entrega}
          />
        )}
        {gridVision && gridVision.length === 0 && !loading && (
          <div
            style={{
              position: "relative",
              textAlign: "center",
            }}
          >
            <p
              style={{
                color: "#1E5281",
              }}
            >
              {intl.get("commons.noRecordsFound")}
            </p>
          </div>
        )}
      </div>
      <Loading isLoading={loading} />
      <Modal
        {...props}
        getGrid={() => {
          getGrid(dataDe, dataAte, coleta, entrega, filters);
        }}
      />
    </div>
  );
};
export default GridVision;
